import { Directive, EventEmitter, HostListener, Input, NgModule, Output } from '@angular/core';
import { CommonModule }                                                   from '@angular/common';
import { debounce }                                                       from '../common/decorators';

export type ScrollEvent = {
  isReachingBottom : boolean,
  isReachingTop : boolean,
  originalEvent : Event,
  isWindowEvent : boolean
};

@Directive( {
  selector : '[detectScroll], [detect-scroll], [data-detect-scroll]'
} )
export class DetectScrollDirective {
  @Output() onScroll              = new EventEmitter<ScrollEvent>();
  @Input() bottomOffset : number  = 100;
  @Input() topOffset : number     = 100;
  @Input() debounceDelay : number = 500;

  // handle element scroll
  @HostListener( 'scroll', [ '$event' ] )
  @debounce()
  scrolled ( $event : Event ) {
    this.elementScrollEvent( $event );
  }

  // handle window scroll
  @HostListener( 'window:scroll', [ '$event' ] )
  @debounce()
  windowScrolled ( $event : Event ) {
    this.windowScrollEvent( $event );
  }

  private emitOnScroll ( emitValue : ScrollEvent ) {
    this.onScroll.emit( emitValue );
  }

  protected windowScrollEvent ( $event : Event ) {
    let target           = <Document>$event.target;
    let scrollTop        = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    let isReachingTop    = scrollTop < this.topOffset;
    let isReachingBottom = ( target.body.offsetHeight - ( window.innerHeight + scrollTop ) ) < this.bottomOffset;

    this.emitOnScroll( { isReachingBottom, isReachingTop, originalEvent : $event, isWindowEvent : true } );
  }

  protected elementScrollEvent ( $event : Event ) {
    let target           = <HTMLElement>$event.target;
    let scrollPosition   = target.scrollHeight - target.scrollTop;
    let offsetHeight     = target.offsetHeight;
    let isReachingTop    = target.scrollTop < this.topOffset;
    let isReachingBottom = ( scrollPosition - offsetHeight ) < this.bottomOffset;

    this.emitOnScroll( { isReachingBottom, isReachingTop, originalEvent : $event, isWindowEvent : false } );
  }
}

@NgModule( {
  imports      : [ CommonModule ],
  exports      : [ DetectScrollDirective ],
  declarations : [ DetectScrollDirective ]
} )
export class DetectScrollModule {
}
