import { NgModule, Component, Input, OnInit } from '@angular/core';
import { CommonModule }                       from '@angular/common';

@Component({
    selector: 'sym-widgetLoader',
    template: `
        <svg width="0" height="0" xmlns="http://www.w3.org/2000/svg" version="1.1">
          <defs>
            <filter id="goo">
              <feGaussianBlur in="SourceGraphic" stdDeviation="4" result="blur" />
              <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
              <feBlend in="SourceGraphic" in2="goo" />
            </filter>
          </defs> 
        </svg>    

        <div class="sym-widget-loader" [ngStyle]="style" [ngClass]="styleClass">
          <svg viewBox="0 0 142 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>Widget Loader</title>
              <style>
                @keyframes single { 50% { transform: translateX( 97px ) } }
                @keyframes set { 50% { transform: translateX( -32px ) } }
                #c1 { animation: single 2.3s infinite; }
                #set { animation: set 2.3s infinite; }
              </style>
              <g id="widget-loader" stroke="none" fill="none" fill-rule="evenodd" filter="url(#goo)">
                  <circle id="c1" cx="22" cy="20" r="8" fill="#0198C7"></circle>
                  <g id="set">
                    <circle id="c2" cx="54" cy="20" r="8" fill="#0198C7"></circle>
                    <circle id="c3" cx="87" cy="20" r="8" fill="#0198C7"></circle>
                    <circle id="c4" cx="119" cy="20" r="8" fill="#0198C7"></circle>
                  </g>
              </g>
          </svg>
        </div>
    `
})
export class WidgetLoader {
    @Input() style: any;
    @Input() styleClass: string;
}

@NgModule({
    imports      : [ CommonModule ],
    exports      : [ WidgetLoader ],
    declarations : [ WidgetLoader ]
})

export class WidgetLoaderModule { }