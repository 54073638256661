// Angular
import { Injectable } from '@angular/core';

// Project
import { Utils } from '../common/utils';

export interface menuTopParams {
  appendTo : HTMLElement;
  appendTop : number;
  elementDimensionsHeight : number;
  element : HTMLElement;
  offsetTop : number;
  targetHeight : number;
  targetOffsetTop : number;
  top : number;
  viewportHeight : number;
}

@Injectable()
export class DomHandler {

  public static zindex : number = 1000;

  private calculatedScrollbarWidth : number = null;

  private calculatedScrollbarHeight : number = null;

  private browser : any;

  private calculateOffset ( currentPosition, isFlipped, minVal, offset? ) {
    let result = currentPosition;

    if ( isFlipped ) {
      result -= offset;
    } else {
      result += offset;
    }

    if ( result < 0 ) {
      result = minVal;
    }

    return result;
  }

  public addClass ( element : any, className : string ) : void {
    if ( element.classList )
      element.classList.add( className );
    else
      element.className += ' ' + className;
  }

  public addMultipleClasses ( element : any, className : string ) : void {
    let styles : string[], i : number;
    if ( element.classList ) {
      styles = className.split( ' ' );
      for ( i = 0; i < styles.length; i++ ) {
        element.classList.add( styles[ i ] );
      }

    }
    else {
      styles = className.split( ' ' );
      for ( i = 0; i < styles.length; i++ ) {
        element.className += ' ' + styles[ i ];
      }
    }
  }

  public removeClass ( element : any, className : string ) : void {
    if ( !element ) {
      return;
    }

    if ( element.classList )
      element.classList.remove( className );
    else
      element.className = element.className.replace( new RegExp( '(^|\\b)' + className.split( ' ' ).join( '|' ) + '(\\b|$)', 'gi' ), ' ' );
  }

  public hasClass ( element : any, className : string ) : boolean {
    if ( element.classList )
      return element.classList.contains( className );
    else
      return new RegExp( '(^| )' + className + '( |$)', 'gi' ).test( element.className );
  }

  public siblings ( element : any ) : any {
    return Array.prototype.filter.call( element.parentNode.children, function ( child ) {
      return child !== element;
    } );
  }

  public find ( element : any, selector : string ) : any[] {
    return Array.from( element.querySelectorAll( selector ) );
  }

  public findScrollElement ( element : any ) : HTMLElement | null {
    while ( element ) {
      ///nodeType 9 (Document node) creates error, so check nodeType to be Element
      if ( !element.nodeType || element.nodeType !== 1 ) {
        break;
      }

      const css = window.getComputedStyle( element, null );

      if ( /(auto|scroll)/.test( css.getPropertyValue( 'overflow' ) + css.getPropertyValue( 'overflow-x' ) + css.getPropertyValue( 'overflow-y' ) ) ) {
        return element;
      }

      element = element.parentNode;
    }

    return null;
  }

  public findSingle ( element : any, selector : string ) : any {
    return element.querySelector( selector );
  }

  private getMenuTop ( params : menuTopParams ) : number {
    const scrollElm          = this.findScrollElement( params.appendTo );
    const clsNameBottomArrow = 'ui-menu-bottom-arrow';

    let result = params.top;

    if ( scrollElm ) {
      let scrollMax = this.getScrollTopMax( scrollElm );

      if ( scrollMax > 0 && ( ( params.targetOffsetTop + params.offsetTop + params.elementDimensionsHeight ) > params.viewportHeight ) ) {
        this.addClass( params.element, clsNameBottomArrow );
        result = params.targetOffsetTop - params.appendTop - params.elementDimensionsHeight - params.targetHeight - params.offsetTop;
      } else {
        if ( this.hasClass( params.element, clsNameBottomArrow ) ) {
          this.removeClass( params.element, clsNameBottomArrow );
        }
      }
    }

    return result;
  }

  public getScrollTopMax ( element : HTMLElement ) : number {
    return element.scrollHeight - element.clientHeight;
  };

  public index ( element : any ) : number {
    let children = element.parentNode.childNodes;
    let num      = 0;
    for ( var i = 0; i < children.length; i++ ) {
      if ( children[ i ] == element ) return num;
      if ( children[ i ].nodeType == 1 ) num++;
    }
    return -1;
  }

  public indexWithinGroup ( element : any, attributeName : string ) : number {
    let children = element.parentNode.childNodes;
    let num      = 0;
    for ( var i = 0; i < children.length; i++ ) {
      if ( children[ i ] == element ) return num;
      if ( children[ i ].attributes && children[ i ].attributes[ attributeName ] && children[ i ].nodeType == 1 ) num++;
    }
    return -1;
  }

  public relativePosition ( element : any, target : any, offset? : { top? : number, left? : number, right? : number }, appendTo? : any, componentName? : string ) : void {
    let elementDimensions = element.offsetParent ? { width : element.offsetWidth, height : element.offsetHeight } : this.getHiddenElementDimensions( element );
    let targetHeight      = target.offsetHeight;
    let targetWidth       = target.offsetWidth;
    let targetOffset      = target.getBoundingClientRect();
    let viewport          = this.getViewport();
    let appendToOffset    = appendTo ? Utils.getOffsetRect( appendTo ) : null;
    let top, left, right;
    let appendTop         = 0;
    let appendLeft        = 0;
    let appendRight       = 0;
    let appendWidth       = 0;
    if ( appendToOffset ) {
      appendTop   = appendToOffset.top;
      appendLeft  = appendToOffset.left;
      appendWidth = appendToOffset.width;
    }

    let offsetTop = offset && !isNaN( offset.top ) ? offset.top : 0;

    if ( ( targetOffset.top + targetHeight + elementDimensions.height ) > viewport.height ) {
      top = -1 * ( elementDimensions.height );
      if ( targetOffset.top + top < 0 ) {
        top = 0;
      }
    } else {
      top = targetHeight;
    }

    if ( appendTop ) {
      top = targetOffset.top - appendTop + offsetTop;

      if ( componentName === 'MENU' ) {
        top = this.getMenuTop( {
          appendTo                : appendTo,
          appendTop               : appendTop,
          elementDimensionsHeight : elementDimensions.height,
          element                 : element,
          offsetTop               : offsetTop,
          targetHeight            : targetHeight,
          targetOffsetTop         : targetOffset.top,
          top                     : top,
          viewportHeight          : viewport.height
        } );
      }
    }

    if ( ( targetOffset.left + elementDimensions.width ) > viewport.width ) {
      left = targetWidth - elementDimensions.width;
    } else {
      left = 0;
    }

    if ( appendLeft ) {
      left = targetOffset.left + left - appendLeft;
    }

    if ( offset && !isNaN( offset.left ) ) {
      left = left + offset.left;
    } else if ( offset && !isNaN( offset.right ) ) {
      right = offset.right;

      if ( appendWidth ) {
        right = Math.round( right + appendWidth - targetOffset.right + appendLeft );
      }
    }

    element.style.top = top + 'px';

    if ( !isNaN( right ) ) {
      element.style.left  = 'auto';
      element.style.right = right + 'px';
    }
    else {
      element.style.left  = left + 'px';
      element.style.right = 'auto';
    }
  }

  public absolutePosition ( element : any, target : any, offset? : { top? : number, left? : number }, addClass? : boolean ) : void {
    let elementDimensions  = element.offsetParent ? { width : element.offsetWidth, height : element.offsetHeight } : this.getHiddenElementDimensions( element );
    let elementOuterHeight = elementDimensions.height;
    let elementOuterWidth  = elementDimensions.width;
    let targetOuterHeight  = target.offsetHeight;
    let targetOuterWidth   = target.offsetWidth;
    let targetOffset       = target.getBoundingClientRect();
    let windowScrollTop    = this.getWindowScrollTop();
    let windowScrollLeft   = this.getWindowScrollLeft();
    let viewport           = this.getViewport();
    let isFlippedTop       = false;
    let isFlippedLeft      = false;
    let top, left;

    if ( targetOffset.top + targetOuterHeight + elementOuterHeight > viewport.height ) {
      top = targetOffset.top + windowScrollTop - elementOuterHeight;

      if ( addClass ) {
        this.addClass( element, 'ui-menu-bottom-arrow' );
      }

      if ( top < 0 ) {
        top          = windowScrollTop;
        isFlippedTop = true;
      }
    } else {
      top = targetOuterHeight + targetOffset.top + windowScrollTop;

      if ( addClass ) {
        this.addClass( element, 'ui-menu-top-arrow' );
      }
    }

    if ( targetOffset.left + targetOuterWidth + elementOuterWidth > viewport.width ) {
      left          = targetOffset.left + windowScrollLeft + targetOuterWidth - elementOuterWidth;
      isFlippedLeft = true;
    } else {
      left = targetOffset.left + windowScrollLeft;
    }

    if ( offset ) {

      if ( offset.top ) {
        top = this.calculateOffset( top, isFlippedTop, ( windowScrollTop ), offset.top );
      }

      if ( offset.left ) {
        left = this.calculateOffset( left, isFlippedLeft, ( targetOffset.left + windowScrollLeft + targetOuterWidth - elementOuterWidth ), offset.left );
      }
    }

    element.style.top  = top + 'px';
    element.style.left = left + 'px';
  }

  public getHiddenElementOuterHeight ( element : any ) : number {
    element.style.visibility = 'hidden';
    element.style.display    = 'block';
    let elementHeight        = element.offsetHeight;
    element.style.display    = 'none';
    element.style.visibility = 'visible';

    return elementHeight;
  }

  public getHiddenElementOuterWidth ( element : any ) : number {
    element.style.visibility = 'hidden';
    element.style.display    = 'block';
    let elementWidth         = element.offsetWidth;
    element.style.display    = 'none';
    element.style.visibility = 'visible';

    return elementWidth;
  }

  public getHiddenElementDimensions ( element : any ) : any {
    let dimensions : any     = {};
    element.style.visibility = 'hidden';
    element.style.display    = 'block';
    dimensions.width         = element.offsetWidth;
    dimensions.height        = element.offsetHeight;
    element.style.display    = 'none';
    element.style.visibility = 'visible';

    return dimensions;
  }

  public scrollInView ( container, item ) {
    let borderTopValue : string  = getComputedStyle( container ).getPropertyValue( 'borderTopWidth' );
    let borderTop : number       = borderTopValue ? parseFloat( borderTopValue ) : 0;
    let paddingTopValue : string = getComputedStyle( container ).getPropertyValue( 'paddingTop' );
    let paddingTop : number      = paddingTopValue ? parseFloat( paddingTopValue ) : 0;
    let containerRect            = container.getBoundingClientRect();
    let itemRect                 = item.getBoundingClientRect();
    let offset                   = ( itemRect.top + document.body.scrollTop ) - ( containerRect.top + document.body.scrollTop ) - borderTop - paddingTop;
    let scroll                   = container.scrollTop;
    let elementHeight            = container.clientHeight;
    let itemHeight               = this.getOuterHeight( item );

    if ( offset < 0 ) {
      container.scrollTop = scroll + offset;
    }
    else if ( ( offset + itemHeight ) > elementHeight ) {
      container.scrollTop = scroll + offset - elementHeight + itemHeight;
    }
  }

  public fadeIn ( element, duration : number ) : void {
    element.style.opacity = 0;

    let last    = +new Date();
    let opacity = 0;
    let tick    = function () {
      opacity               = +element.style.opacity.replace( ',', '.' ) + ( new Date().getTime() - last ) / duration;
      element.style.opacity = opacity;
      last                  = +new Date();

      if ( +opacity < 1 ) {
        ( window.requestAnimationFrame && requestAnimationFrame( tick ) ) || setTimeout( tick, 16 );
      }
    };

    tick();
  }

  public fadeOut ( element, ms ) {
    var opacity  = 1,
        interval = 50,
        duration = ms,
        gap      = interval / duration;

    let fading = setInterval( () => {
      opacity = opacity - gap;

      if ( opacity <= 0 ) {
        opacity = 0;
        clearInterval( fading );
      }

      element.style.opacity = opacity;
    }, interval );
  }

  public getWindowScrollTop () : number {
    let doc = document.documentElement;
    return ( window.pageYOffset || doc.scrollTop ) - ( doc.clientTop || 0 );
  }

  public getWindowScrollLeft () : number {
    let doc = document.documentElement;
    return ( window.pageXOffset || doc.scrollLeft ) - ( doc.clientLeft || 0 );
  }

  public matches ( element, selector : string ) : boolean {
    var p = Element.prototype;
    var f = p[ 'matches' ] || p.webkitMatchesSelector || p[ 'mozMatchesSelector' ] || p[ 'msMatchesSelector' ] || function ( s ) {
      return [].indexOf.call( document.querySelectorAll( s ), this ) !== -1;
    };
    return f.call( element, selector );
  }

  public getOuterWidth ( el, margin? ) {
    let width = el.offsetWidth;

    if ( margin ) {
      let style = getComputedStyle( el );
      width += parseFloat( style.marginLeft ) + parseFloat( style.marginRight );
    }

    return width;
  }

  public getHorizontalPadding ( el ) {
    let style = getComputedStyle( el );
    return parseFloat( style.paddingLeft ) + parseFloat( style.paddingRight );
  }

  public getHorizontalMargin ( el ) {
    let style = getComputedStyle( el );
    return parseFloat( style.marginLeft ) + parseFloat( style.marginRight );
  }

  public innerWidth ( el ) {
    let width = el.offsetWidth;
    let style = getComputedStyle( el );

    width += parseFloat( style.paddingLeft ) + parseFloat( style.paddingRight );
    return width;
  }

  public width ( el ) {
    let width = el.offsetWidth;
    let style = getComputedStyle( el );

    width -= parseFloat( style.paddingLeft ) + parseFloat( style.paddingRight );
    return width;
  }

  public getInnerHeight ( el ) {
    let height = el.offsetHeight;
    let style  = getComputedStyle( el );

    height += parseFloat( style.paddingTop ) + parseFloat( style.paddingBottom );
    return height;
  }

  public getOuterHeight ( el, margin? ) {
    let height = el.offsetHeight;

    if ( margin ) {
      let style = getComputedStyle( el );
      height += parseFloat( style.marginTop ) + parseFloat( style.marginBottom );
    }

    return height;
  }

  public getHeight ( el ) : number {
    let height = el.offsetHeight;
    let style  = getComputedStyle( el );

    height -= parseFloat( style.paddingTop ) + parseFloat( style.paddingBottom ) + parseFloat( style.borderTopWidth ) + parseFloat( style.borderBottomWidth );

    return height;
  }

  public getWidth ( el ) : number {
    let width = el.offsetWidth;
    let style = getComputedStyle( el );

    width -= parseFloat( style.paddingLeft ) + parseFloat( style.paddingRight ) + parseFloat( style.borderLeftWidth ) + parseFloat( style.borderRightWidth );

    return width;
  }

  public getViewport () : any {
    let win = window,
        d   = document,
        e   = d.documentElement,
        g   = d.getElementsByTagName( 'body' )[ 0 ],
        w   = win.innerWidth || e.clientWidth || g.clientWidth,
        h   = win.innerHeight || e.clientHeight || g.clientHeight;

    return { width : w, height : h };
  }

  public getOffset ( el ) {
    let rect = el.getBoundingClientRect();

    return {
      top  : rect.top + document.body.scrollTop,
      left : rect.left + document.body.scrollLeft
    };
  }

  public replaceElementWith ( element : any, replacementElement : any ) : any {
    let parentNode = element.parentNode;
    if ( !parentNode )
      throw `Can't replace element`;
    return parentNode.replaceChild( replacementElement, element );
  }

  getUserAgent () : string {
    return navigator.userAgent;
  }

  isIE () {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf( 'MSIE ' );
    if ( msie > 0 ) {
      // IE 10 or older => return version number
      return true;
    }

    var trident = ua.indexOf( 'Trident/' );
    if ( trident > 0 ) {
      // IE 11 => return version number
      return true;
    }

    var edge = ua.indexOf( 'Edge/' );
    if ( edge > 0 ) {
      // Edge (IE 12+) => return version number
      return true;
    }

    // other browser
    return false;
  }

  appendChild ( element : any, target : any ) {
    if ( this.isElement( target ) )
      target.appendChild( element );
    else if ( target.el && target.el.nativeElement )
      target.el.nativeElement.appendChild( element );
    else
      throw 'Cannot append ' + target + ' to ' + element;
  }

  removeChild ( element : any, target : any ) {
    if ( this.isElement( target ) )
      target.removeChild( element );
    else if ( target.el && target.el.nativeElement )
      target.el.nativeElement.removeChild( element );
    else
      throw 'Cannot remove ' + element + ' from ' + target;
  }

  isElement ( obj : any ) {
    return ( typeof HTMLElement === 'object' ? obj instanceof HTMLElement :
        obj && typeof obj === 'object' && obj !== null && obj.nodeType === 1 && typeof obj.nodeName === 'string'
    );
  }

  calculateScrollbarWidth () : number {
    if ( this.calculatedScrollbarWidth !== null )
      return this.calculatedScrollbarWidth;

    let scrollDiv       = document.createElement( 'div' );
    scrollDiv.className = 'ui-scrollbar-measure';
    document.body.appendChild( scrollDiv );

    let scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    document.body.removeChild( scrollDiv );

    this.calculatedScrollbarWidth = scrollbarWidth;

    return scrollbarWidth;
  }

  calculateScrollbarHeight () : number {
    if ( this.calculatedScrollbarHeight !== null )
      return this.calculatedScrollbarHeight;

    let scrollDiv       = document.createElement( 'div' );
    scrollDiv.className = 'ui-scrollbar-measure';
    document.body.appendChild( scrollDiv );

    let scrollbarHeight = scrollDiv.offsetHeight - scrollDiv.clientHeight;
    document.body.removeChild( scrollDiv );

    this.calculatedScrollbarWidth = scrollbarHeight;

    return scrollbarHeight;
  }

  invokeElementMethod ( element : any, methodName : string, args? : any[] ) : void {
    ( element as any )[ methodName ].apply( element, args );
  }

  clearSelection () : void {
    if ( window.getSelection ) {
      if ( window.getSelection().empty ) {
        window.getSelection().empty();
      } else if ( window.getSelection().removeAllRanges && window.getSelection().rangeCount > 0 && window.getSelection().getRangeAt( 0 ).getClientRects().length > 0 ) {
        window.getSelection().removeAllRanges();
      }
    }
    else if ( document[ 'selection' ] && document[ 'selection' ].empty ) {
      try {
        document[ 'selection' ].empty();
      } catch ( error ) {
        //ignore IE bug
      }
    }
  }

  getBrowser () {
    if ( !this.browser ) {
      let matched  = this.resolveUserAgent();
      this.browser = {};

      if ( matched.browser ) {
        this.browser[ matched.browser ] = true;
        this.browser[ 'version' ]       = matched.version;
      }

      if ( this.browser[ 'chrome' ] ) {
        this.browser[ 'webkit' ] = true;
      } else if ( this.browser[ 'webkit' ] ) {
        this.browser[ 'safari' ] = true;
      }
    }

    return this.browser;
  }

  resolveUserAgent () {
    let ua    = navigator.userAgent.toLowerCase();
    let match = /(chrome)[ \/]([\w.]+)/.exec( ua ) ||
      /(webkit)[ \/]([\w.]+)/.exec( ua ) ||
      /(opera)(?:.*version|)[ \/]([\w.]+)/.exec( ua ) ||
      /(msie) ([\w.]+)/.exec( ua ) ||
      ua.indexOf( 'compatible' ) < 0 && /(mozilla)(?:.*? rv:([\w.]+)|)/.exec( ua ) ||
      [];

    return {
      browser : match[ 1 ] || '',
      version : match[ 2 ] || '0'
    };
  }

  isInteger ( value ) : boolean {
    if ( Number.isInteger ) {
      return Number.isInteger( value );
    }
    else {
      return typeof value === 'number' && isFinite( value ) && Math.floor( value ) === value;
    }
  }

  isHidden ( element : HTMLElement ) : boolean {
    return element.offsetParent === null;
  }
}
