import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SymSvg } from '@sym-ui/toolkit';

@Component( {
  selector    : 'app-root',
  templateUrl : './app.component.html',
  styleUrls   : [ './app.component.css' ]
} )
export class AppComponent implements OnInit {

  constructor(private translate: TranslateService) {
      translate.setDefaultLang('en');
  }

  ngOnInit () {
  }
}
