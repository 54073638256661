import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { 
    AutoCompleteModule, 
    SymSvgModule, 
    ObjectUtils, 
    DynamicDialogRef, 
    DynamicDialogConfig, 
    DialogService 
} from '@sym-ui/toolkit';

import { AppRoutingModule } from '@showcase/app-routing.module';
import { AppComponent } from '@showcase/app.component';
import { ToolkitComponent } from '@showcase/components/toolkit/toolkit.component';
import { HomeComponent } from '@showcase/components/home/home.component';

import { CarService } from '@showcase/service/car-service';
import { CountryService } from '@showcase/service/country-service';
import { EventService } from '@showcase/service/event-service';
import { NodeService } from '@showcase/service/node-service';
import { IconService } from '@showcase/service/icon-service';
import { SymIconService } from '@showcase/service/sym-icon-service';
import { ThemeService } from '@showcase/service/theme-service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'src/locales/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        ToolkitComponent,
        HomeComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AutoCompleteModule,
        SymSvgModule,
        // ngx-translate and the loader module
        TranslateModule.forRoot({
          loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
          }
        })
    ],
    providers: [
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        CarService,
        CountryService,
        EventService,
        NodeService,
        IconService,
        SymIconService,
        ThemeService,
        ObjectUtils,
        DynamicDialogRef,
        DynamicDialogConfig
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
