import {
  NgModule,
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  AfterViewInit,
  OnDestroy
} from '@angular/core';

import { CommonModule }             from '@angular/common';
import { SharedModule }             from '../common/shared';
import { FormsModule }              from '@angular/forms';
import { InputTextModule }          from '../input-text/input-text';
import { InputTextareaModule }      from '../input-textarea/input-textarea';
import { EditableItem }             from '../common/editable.interface';
import { SymInputTextHelperModule } from '../input-text-helper/input-text-helper';
import { Utils }                    from '../common/utils';
import { InlineEditService }        from './inline-edit.service';

@Component( {
  selector : 'sym-inline-edit',
  template : `
    <div class="sym-inline-edit" id="sym-inline-edit__id__{{id}}">
      <div class="editable__area-edit-mode sym__flex-container" [ngStyle]="style"
           [ngClass]="styleClass"
           [hidden]="!editMode">
        <div class="editable__field-container sym__flex-fill">
          <sym-input-text-helper *ngIf="type==='text' || type==='number'"
                                 [value]="value" (onClear)="clearValue()"
                                 [errorMsg]="errorMsg" [isValid]="isValid"
                                 >
            <input pInputText
                   type="{{type}}"
                   id="sym-inline-edit__field-{{id}}"
                   placeholder="{{placeholder}}"
                   class="sym-inline-edit__field-input is--text-input"
                   name="{{name}}"
                   [attr.maxlength]="maxlength"
                   autocomplete="on" [(ngModel)]="value"
                   (input)="onChange()" (keyup.enter)="save()"/>
          </sym-input-text-helper>

          <textarea pInputTextArea *ngIf="type==='textarea'"
                    id="sym-inline-edit__field-{{id}}"
                    name="{{name}}"
                    class="sym-inline-edit__field-input"
                    [(ngModel)]="value"
                    [attr.maxlength]="maxlength"
                    (change)="onChange()"
                    (keyup.enter)="save()">{{ value }}</textarea>
          <label *ngIf="label" class="type__item-description" for="sym-inline-edit__field-{{id}}">
            {{ label }}
          </label>
        </div>

        <div class="edit__buttons-container">
          <div class="edit__buttons" [hidden]="isProcessing">
          <span class="sym-inline-edit__save-button-container">
            <input type="submit" value=""
                   class="sym-inline-edit__save-button icon__app-checkmark is--white"
                   [disabled]="!isValid"
                   (click)="save()">
          </span>

            <input type="button" value=""
                   class="sym-inline-edit__close-button icon__inline-form-close"
                   (click)="cancel()">
          </div>
          <div class="is--proccessing" [hidden]="!isProcessing">
            <svg class="sym-smbl__progress-spinner sym-smbl--small"><use href="#sym-smbl__progress-spinner"></use></svg>
          </div>
        </div>
      </div>
      <div class="editable__area-hover" [ngClass]="{ 'is--invisible' : editMode, 'ui-state-disabled' : disabled}" (click)="edit()">
        <div class="sym__flex-container">
          <div class="editable__content sym__flex-fill">
            <ng-content></ng-content>
          </div>
          <div [hidden]="disabled" class="icon__inline-edit is--white sym__icon is--link" ></div>
        </div>
      </div>
    </div>
  `
} )
export class SymInlineEdit implements OnInit, AfterViewInit, OnDestroy {
  @Input() value : string;
  @Input() label : string;
  @Input() name : string;
  @Input() id : string;
  @Input() type : string      = 'text';
  @Input() placeholder : string;
  @Input() model : object     = {}; // type any object as this allows developer to pass any object needed in callback
  @Input() styleClass : string;
  @Input() style : object;
  @Input() isValid : boolean  = false;
  @Input() errorMsg : string;
  @Input() maxlength : number = 50;
  @Input() disabled : boolean;

  originalValue : string;
  editMode : boolean = false;
  inputElem : HTMLElement;

  private _isProcessing : boolean;

  @Input() get isProcessing () : boolean {
    return this._isProcessing;
  }

  set isProcessing ( val : boolean ) {
    this._isProcessing = val;
    if ( !this._isProcessing && this.isValid ) {
      this.editMode = false;
    } else {
      this.editMode = true;
    }
  }

  @Output() onValidate : EventEmitter<EditableItem> = new EventEmitter();
  @Output() onSave : EventEmitter<EditableItem>     = new EventEmitter();
  @Output() onEdit : EventEmitter<EditableItem>     = new EventEmitter();
  @Output() isValidChange : EventEmitter<boolean> = new EventEmitter();

  ngOnInit () {
    this.isValid       = true;
    this.originalValue = this.value;
    this.id            = this.id || Utils.generateUUID();
    this.inlineEditService.add(this);
    this.isValidChange.emit(this.isValid);
  }

  ngAfterViewInit () {
    this.inputElem     = this.el.nativeElement.querySelector( '.sym-inline-edit__field-input' );
  }
  constructor ( private el : ElementRef, private inlineEditService : InlineEditService
  ) {
  }

  onChange () {
    this.onValidate.emit( this.getInlineEditObj() );
  }

  save () {
    if ( this.isValid ) {
      this.onSave.emit( this.getInlineEditObj() );
    }
  }

  cancel () {
    this.value    = this.originalValue;
    this.editMode = false;
    this.isValid  = true;
    this.isValidChange.emit(this.isValid);
  }

  edit () {
    if ( this.editMode === false ) {
      this.editMode      = true;
      this.originalValue = this.value;
      this.onEdit.emit( this.getInlineEditObj() );
      setTimeout( () => {
          this.inputElem.focus();
      }, 100 );
    }
  }

  clearValue () {
    if ( this.value !== '' ) {
      this.value = '';
    }
    this.onChange();
  }

  getInlineEditObj () {
    return {
      value    : this.value,
      oldValue : this.originalValue,
      model    : this.model
    };
  }

  ngOnDestroy(): void {
      this.inlineEditService.remove(this.id);
  }
}

@NgModule( {
  imports      : [CommonModule, SharedModule, InputTextModule, InputTextareaModule, FormsModule, SymInputTextHelperModule],
  exports      : [SymInlineEdit, SharedModule, InputTextModule, InputTextareaModule, FormsModule, SymInputTextHelperModule],
  declarations : [SymInlineEdit]
} )
export class SymInlineEditModule {
}
