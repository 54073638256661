import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgModule,
  Output,
  TemplateRef
}                                             from '@angular/core';
import { Utils }                              from '../common/utils';
import { CommonModule }                       from '@angular/common';
import { SharedModule }                       from '../common/shared';
import {
  SymDashboardWidgets,
  SymDashboardWidgetsModule
}                                             from './dashboard-widgets';
import { SymDashboardGridModule }             from './dashboard-grid';
import { SymDashboardMenuModule }             from './dashboard-menu';
import {
  SymDashboardModel,
  SymDashboardMenuModel,
  SymDashboardWidgetSectionModel
}                                             from './dashboard.interface';
import { DialogService }                      from '../common/api';
import { CheckboxModule }                     from '../checkbox/checkbox';
import { InputTextModule }                    from '../input-text/input-text';
import { ButtonModule }                       from '../button/button';
import { SymAlertModal, SymAlertModalModule } from '../sym-alert-modal/sym-alert-modal';
import { DynamicDialogModule }                from '../dynamic-dialog/dynamic-dialog';

@Component( {
  selector : 'sym-dashboard',
  template : `
    <div class="sym-dashboard__progress-spinner" *ngIf="isLoading"><svg class="sym-smbl__progress-spinner sym-smbl--medium"><use href="#sym-smbl__progress-spinner"></use></svg></div>
    <div *ngIf="!isLoading" class="sym-dashboard" id="sym-dashboard__dashboard-{{dashboardCopy ? dashboardCopy.id : 'empty'}}"
         [ngClass]="{'is--edit-mode': editMode,
                     'is--empty': !dashboardCopy && !isLoading}">
      <div class="sym-dashboard__header sym__flex-container"
           *ngIf="!editMode">
        <div class="sym-dashboard__header-left sym__flex-fill">
          <span *ngIf="dashboardCopy" class="sym-dashboard__header-title">{{dashboardCopy.name}}</span>
          <sym-dashboard-menu
                  *ngIf="menu && menu.length > 0"
                  [(model)]="menu"
                  [l10n]="l10n"
                  [defaultId]='defaultDashboardId'
                  (onSelect)="selectDashboard($event)"
                  (onDefaultChange)="onDefaultChange($event)"
                  [parentElem]='el.nativeElement'
          >
          </sym-dashboard-menu>
        </div>
        <div class="sym-dashboard__actions sym__flex-container">
          <div class="sym-dashboard__action sym-dashboard__action-create is--link sym__flex-container"
               (click)="create()">
            <span class="sym__icon">
              <svg class="sym-smbl--action-large sym-smbl--action-blue"><use xlink:href="#sym-smbl__action-add"></use></svg>
            </span>
            <span>{{l10n.create}}</span>
          </div>
          <div *ngIf="dashboardCopy" class="sym-dashboard__action sym-dashboard__action-edit is--link sym__flex-container"
               (click)="edit()">
            <span class="sym__icon">
              <svg class="sym-smbl--action-large sym-smbl--action-blue"><use xlink:href="#sym-smbl__action-edit"></use></svg>
            </span>
            <span>{{l10n.edit}}</span>
          </div>
          <div *ngIf="dashboardCopy && !dashboardCopy.hideDelete && menu.length > 1" class="sym-dashboard__action sym-dashboard__action-delete is--link sym__flex-container"
               (click)="delete()">
            <span class="sym__icon">
              <span class="icon__actions-trash"></span>
            </span>
            <span>{{l10n.delete}}</span>
          </div>
        </div>
      </div>
      <div class="sym-dashboard__header sym__flex-container"
           *ngIf="dashboardCopy && editMode">
        <div class="sym-dashboard__header-title sym__flex-fill">
          <sym-input-text-helper [value]="dashboardCopy.name"
                                 class="sym-dashboard__title-input"
                                 (onClear)="clearDashboardName()"
                                 [isValid]="isNameValid"
                                 [errorMsg]="l10n.dashboardNameErrorMsg"
          >
            <input id="input" type="text" size="20" pInputText
                   placeholder="{{l10n.dashboardTitlePlaceholder}}"
                   [(ngModel)]="dashboardCopy.name"
                   (input)=inputValidator($event)>
          </sym-input-text-helper>

          <p-checkbox class="sym-dashboard__action-set-default"
                      [(ngModel)]="isDefaultDashboard" binary="true"
                      (onChange)="onChangeDefault()"
                      label="{{l10n.setAsDefault}}"></p-checkbox>
          <div class="sym-dashboard__action sym-dashboard__action-add-widgets is--link sym__flex-container"
               (click)="showWidgetModal()">
               <span class="sym__icon">
                 <svg class="sym-smbl--action-large sym-smbl--action-blue"><use xlink:href="#sym-smbl__action-add"></use></svg>
               </span>
            <span>{{l10n.addWidget}}
               </span>
          </div>
        </div>
        <div class="sym-dashboard__actions">
          <sym-button (click)="cancel()"
                      [disabled]="isProcessing"
                      label="{{l10n.cancel}}"
                      styleClass="ui-button-secondary sym-dashboard__action-cancel">
          </sym-button>
          <sym-button (click)="save()"
                      [isProcessing]="isProcessing"
                      [disabled]="isProcessing || !changed || !isValid || !isNameValid || this.dashboardCopy.name.trim() === '' || dashboardCopy.items.length === 0 " label="{{l10n.save}}"
                      styleClass="sym-dashboard__action-save">
          </sym-button>
        </div>
       </div>
       <div *ngIf="dashboardCopy" class="sym-dashboard__grid">
          <sym-dashboard-grid
                  [(model)]='dashboardCopy.items'
                  [editMode]='editMode'
                  [height]='gridsterHeight'
                  (onChange)="onGridChange($event)">
          </sym-dashboard-grid>
        </div>
        <div class="sym-dashboard__overlay" *ngIf="isProcessing"></div>

      </div>
  `
} )
export class SymDashboard {
  private _menu: Array<SymDashboardMenuModel> = [];

  @Input() get menu () : Array<SymDashboardMenuModel> {
    return this._menu;
  }

  set menu ( val : Array<SymDashboardMenuModel> ) {
    //in case menu is set after dashboard
    if (val) {
      this._menu = val.map((m) => {
        if (m.id === this.defaultDashboardId) {
          m.isSelected = true;
        }
        else {
          m.isSelected = false;
        }
        return m;
      });
      this.menuChange.emit( this._menu );
    }
    else {
      this._menu = [];
    }

  }

  // @Input() menu : Array<SymDashboardMenuModel> = [];
  @Output() menuChange = new EventEmitter<SymDashboardMenuModel[]>(); // 2way binding

  @Input() isValid : boolean        = true;
  @Input() isNameValid : boolean;
  @Input() l10n : any;
  @Input() gridsterHeight : number  = 400;
  @Input() isLoading : boolean = false;
  @Output() onSave                  = new EventEmitter<any>();
  @Output() onSelectDashboard       = new EventEmitter<any>();
  @Output() onValidateDashboardName = new EventEmitter<any>();
  @Output() onDelete                = new EventEmitter<SymDashboardModel>();
  @Output() onDefaultDashboardChange= new EventEmitter<SymDashboardMenuModel>();

  @Output() isNameValidChange = new EventEmitter<boolean>();

  itemTemplate : TemplateRef<any>;
  widgetClosed = false; //for some reason ref.onClose is getting called twice
  // this keep track to make sure the second onClose call is ignored.

  isDefaultDashboard : boolean;
  emptyDashboardName = 'DASHBOARD_ID_PLACEHOLDER';

  _defaultDashboardId : string;
  changed : boolean;

  @Output()
  defaultDashboardIdChange = new EventEmitter<string>();

  @Input()
  get defaultDashboardId () {
    return this._defaultDashboardId;
  }

  set defaultDashboardId ( val ) {
    this._defaultDashboardId = val;
    this.defaultDashboardIdChange.emit( this._defaultDashboardId );
    if ( this.dashboardCopy ) {
      this.isDefaultDashboard = val === this.dashboardCopy.id;
    }

    this._menu = this.menu.map((m) => {
      if (m.id === val) {
        m.isDefault = true;
      }
      else {
        m.isDefault = false;
      }
      return m;
    });

    this.menuChange.emit( this._menu );

  }

  private _widgets : Array<SymDashboardWidgetSectionModel>;

  @Input() get widgets () : Array<SymDashboardWidgetSectionModel> {
    return this._widgets;
  }

  set widgets ( val : Array<SymDashboardWidgetSectionModel> ) {
    this._widgets = val;
    // NOTE: we used to have an extra function here
    // - which is why I was using set/get.
    // Keeping this just in case.
  }

  @Output() editModeChange = new EventEmitter<boolean>();

  private _editMode : boolean;

  @Input() get editMode () : boolean {
    return this._editMode;
  }

  set editMode ( val : boolean ) {
    this._editMode = val;
  }

  @Output() isProcessingChange = new EventEmitter<boolean>();

  private _isProcessing : boolean;

  @Input() get isProcessing () : boolean {
    return this._isProcessing;
  }

  set isProcessing ( val : boolean ) {
    this._isProcessing = val;
  }

  dashboardCopy : SymDashboardModel;
  originalDashboard : SymDashboardModel;
  previousDashboard : SymDashboardModel;

  @Output()
  dashboardChange = new EventEmitter<SymDashboardModel>();

  //used to revert to the previous dashboad when creating a new dashboard is cancelled
  private _dashboard : SymDashboardModel;

  @Input() get dashboard () : SymDashboardModel {
    return this._dashboard;
  }

  set dashboard ( val : SymDashboardModel ) {
    this._dashboard    = val;
    if ( !val ) {
      this.dashboardCopy = null;
      return;
    }
    this.dashboardCopy = Utils.deepCopy( val );
    //Need to work with copy until the user saves it.
    if ( val && val.id !== this.emptyDashboardName ) {
      this.previousDashboard = null;
      this._menu = this.menu.map((m) => {
        if (m.id === val.id) {
          m.isSelected = true;
        }
        else {
          m.isSelected = false;
        }
        return m;
      });
      this.menuChange.emit( this._menu );
    }
    else {
      //empty dashboard, so name isn't valid yet
      this.isNameValid = false;
      this.isNameValidChange.emit( this.isNameValid );
    }

    this.isValid = true;
    this.changed = false;

    this.isDefaultDashboard = this.defaultDashboardId === val.id;
    if ( this.dashboardCopy.name.trim() !== '') {
      this.isNameValid = true;
      this.isNameValidChange.emit( this.isNameValid ); //this emit is for 2 way binding
    }
  }

  constructor ( public el : ElementRef, public dialogService : DialogService ) {
  }

  save () {
    if ( !this.changed || !this.isValid || !this.isNameValid || this.dashboardCopy.name.trim() === '' || this.isProcessing || this.dashboardCopy.items.length === 0) {
      return;
    }

    if ( this.isDefaultDashboard ) {
      this.defaultDashboardId = this.dashboardCopy.id;
    } else {
      if ( this.defaultDashboardId === this.dashboardCopy.id ) {
        this.defaultDashboardId = '';
      }
    }

    this.onSave.emit( {
      dashboard : this.dashboardCopy,
      isDefault : this.isDefaultDashboard,
      isNew     : !!this.previousDashboard
    } );

    this.isProcessing = true;
    this.isProcessingChange.emit(this.isProcessing);

    // this.previousDashboard = null;
    // this.dashboard         = this.dashboardCopy;
    //
    // //NOTE: Need to wait for API to get updated -
    // // Maybe page refresh?
    // this.editMode = false;

  }

  create () {
    this.previousDashboard = Utils.deepCopy( this.dashboard );

    this.dashboard = {
      id    : this.emptyDashboardName,
      name  : '',
      items : []
    };

    this.dashboardChange.emit( this.dashboard );
    this.showWidgetModal();
  }

  edit () {
    this.editMode = true;
    this.editModeChange.emit( this.editMode );
    this.originalDashboard = Utils.deepCopy( this.dashboard );
  }

  delete () {
    if (this.menu.length < 2) { //at least one dashboard needs to be defined
      return;
    }
    let message                = this.l10n.deleteModal.message.replace( '{{dashboardName}}', this.dashboardCopy.name );
    const deleteModalReference = this.dialogService.open( SymAlertModal, {

      data       : {
        modalData     : { bar : 'foo' },
        iconClassName : 'icon__event is--warning is--medium',
        message       : '<p>' + this.l10n.deleteModal.warning + '</p><p>' + message + '</p>',
        acceptLabel   : this.l10n.deleteModal.acceptLabel,
        acceptAction  : 'OK',
        rejectLabel   : this.l10n.deleteModal.rejectLabel,
        rejectAction  : 'CANCEL'
      },
      showHeader : false,
      width      : '600px',
      baseZIndex : 1201
    } );

    deleteModalReference.onClose.subscribe( ( response ) => {

      if ( response && response.action === 'OK' ) {
        this.onDelete.emit( this.dashboardCopy );
      }

    } );
  }

  cancel () {
    if ( this.previousDashboard ) {
      this.dashboard = this.previousDashboard;
      this.previousDashboard = null;
    }
    else if ( this.originalDashboard ) {
      this.dashboard = this.originalDashboard;
      this.originalDashboard = null;
    }
    this.dashboardChange.emit( this.dashboard );
    this.editMode = false;
    this.editModeChange.emit( this.editMode );

  }

  public inputValidator ( event : any ) {
    let dashboardNameObj = {
      name         : this.dashboardCopy.name,
      orignialName : !this.previousDashboard ? this.dashboard.name : '',
      isNew        : !!this.previousDashboard
    };
    this.onValidateDashboardName.emit( dashboardNameObj );

    if ( this.dashboard.name && this.dashboardCopy.name !== this.dashboard.name ) {
      this.changed = true;
    }
    //developer needs to update isNameValid to true/false in this function
  }

  selectDashboard ( item : SymDashboardMenuModel ) {
    this.onSelectDashboard.emit( item );
  }

  clearDashboardName () {
    this.dashboardCopy.name = '';
    this.inputValidator(null);
    this.changed = true;
  }

  onChangeDefault () {
    this.changed = true;
  }

  onGridChange (ev: any) {
    this.changed = true;
  }

  onDefaultChange (ev: SymDashboardMenuModel ) {
    this.onDefaultDashboardChange.emit(ev);
  }

  showWidgetModal () {
    this.widgetClosed = false;
    const ref         = this.dialogService.open( SymDashboardWidgets, {
      data         : {
        widgets   : this.widgets,
        dashboard : this.dashboardCopy,
        l10n      : this.l10n.widgetsModal
      },
      header       : this.l10n.widgetsModal.header,
      width        : '90%',
      contentStyle : { 'height' : '85%', 'overflow' : 'hidden' },
      styleClass   : 'sym-dashboard__dialog'
    } );

    if (!ref) {
      return;
    }
    //Widget Modal onClose:
    ref.onClose.subscribe( ( data : any ) => {
      // TODO: remove this this.widgetClosed when digalog service is fixed.
      if ( this.widgetClosed ) {
        return;
      }

      if ( data && data.widgets ) {
        if ( data.isUpdated ) {
          this.dashboardCopy.items = []; // This is necessary so widgets won't show up on top of each other...
          setTimeout( () => {
            this.dashboardCopy.items = data.widgets;
          }, 100 );
        }
        this.changed  = true;
        this.editMode = true;
        this.editModeChange.emit( this.editMode );

      } else if ( this.previousDashboard ) {// If no widgets data were added and this.previousDashboard is defined,
        // then abort creating a new dashboard and
        // revert to the previous dashboard.
        this.dashboard = this.previousDashboard;
        this.dashboardChange.emit( this.dashboard );
      }

      this.widgetClosed = true;

    } );
  }
}

@NgModule( {
  imports         : [CommonModule, SharedModule, SymDashboardWidgetsModule, SymDashboardGridModule, CheckboxModule, InputTextModule, ButtonModule, SymDashboardMenuModule, SymAlertModalModule, DynamicDialogModule],
  exports         : [SymDashboard, SharedModule, SymDashboardWidgetsModule, SymDashboardGridModule, CheckboxModule, InputTextModule, ButtonModule, SymDashboardMenuModule, SymAlertModalModule, DynamicDialogModule],
  declarations    : [SymDashboard],
  entryComponents : [SymDashboardWidgets]
} )
export class SymDashboardModule {
}
