import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()

export class SymIconService {

    constructor(private http: HttpClient) {
    }

    icons: any[];
    version: string;
    selectedIcon: any;
    apiUrl = 'resources/toolkit/json/icons.json';

    getIcons() {
        return this.http.get(this.apiUrl).pipe(map((response: any) => {
            this.icons = response.icons;
			this.version = response.version;
			return { 'icons' : this.icons, 'version' : this.version };
        }));
    }

    getIcon(name: any) {
        if (this.icons) {
            this.selectedIcon = this.icons.find(x => x.name === name) as Object;
            return this.selectedIcon;
        }
    }

}
