/**
 * A stream consuming characters one at a time over it's input.
 *
 * @author romelus
 */
import {NumberUtils} from '../../components/utils/number.utils';

export class Stream {
  index: number;
  data: string;

  /**
   * Default constructor.
   *
   * @param data value to stream over
   */
  constructor(data : string) {
    this.index = 0;
    this.data = data;
  }

  get position() : number {
    return this.index;
  }

  get hasNext() : boolean {
    return this.peek() !== undefined;
  }

  get input() : string {
    return this.data;
  }

  // Returns the character at the current position in the stream
  peek() : string {
    const indexInRange = NumberUtils.inRange(this.index, 0, this.data.length);
    return (indexInRange && this.input) ? this.input[this.index] : undefined;
  }

  // Returns the previous character before the current position in the stream
  peekPrev() : string {
    const indexInRange = NumberUtils.inRange(this.index - 1, 0, this.data.length);
    return (indexInRange && this.input) ? this.input[this.index - 1] : undefined;
  }

  // Returns the character at the current position in the stream and auto-advances to the next character
  next() : string {
    const indexInRange = NumberUtils.inRange(this.index, 0, this.data.length);
    return (indexInRange && this.hasNext) ? this.input[this.index++] : undefined;
  }

  reset(location: number) : Stream {
    this.index = location;
    return this;
  }
}
