import { Component, Input, Output, EventEmitter, NgModule, OnInit } from '@angular/core';
import { CommonModule }                                             from '@angular/common';
import { FormsModule }                                              from '@angular/forms';
import { HighlightSearchModule }                                    from '../common/highlight-match.pipe';
import { InputTextModule }                                          from '../input-text/input-text';
import { OverlayPanelModule, OverlayPanel }                         from '../overlay-panel/overlay-panel';
import { ButtonModule }                                             from '../button/button';
import { SymDomainSwitcherModel }                                   from './sym-domain-switcher-model';
import { AutoFocusModule }                                          from '../auto-focus/auto-focus';

@Component( {
  selector : 'sym-domain-switcher',
  template : `
    <p-overlayPanel #symDomainSwitcher styleClass="ui-overlaypanel-no-padding sym__domain-switcher-overlaypanel" (onShow)="doOnShowEvent()" (onHide)="doOnHideEvent()" [offset]="offset" arrowPosition="right">
      <div class="sym__domain-switcher-overlaypanel-header ui-helper-clearfix">
        <span class="sym__domain-switcher-overlaypanel-header-title"><strong>{{model.titleTxt}}</strong> ({{filterItems.length}})</span>
        <input pInputText id="domains" type="text" size="24" placeholder="{{model.searchTxt}}" [(ngModel)]="searchTerm" [autoFocus]="isAutoFocused">
      </div>
      <ul class="sym__domain-switcher-overlaypanel-items">
        <li *ngFor="let item of filterItems">
          <a href="" (click)="itemClicked( $event, item, model, symDomainSwitcher )" [ngClass]="{ 'is--selected' : item.isSelected }" [innerHTML]="item.message | highlight : searchTerm"></a>
        </li>
      </ul>
      <div class="sym__domain-switcher-overlaypanel-footer">
        <sym-button [label]="model.button.primary[ 'label' ]" (click)="buttonClicked( 'primary', $event, model, symDomainSwitcher )"></sym-button>
        <sym-button [label]="model.button.secondary[ 'label' ]" (click)="buttonClicked( 'secondary', $event, model, symDomainSwitcher )" styleClass="ui-button-secondary"></sym-button>
      </div>
    </p-overlayPanel>
    <div class="sym__domain-switcher" (mouseover)="onHover()" (mouseleave)="onLeave()" (click)="symDomainSwitcher.toggle( $event )">
      <svg class="sym-smbl__header-domain"><use href="#sym-smbl__header-domain"></use></svg>
      <span class="current-domain">{{model.currentDomain}}</span>
      <svg class="sym-smbl--arrow-small sym-smbl--black-80"><use href="#sym-smbl__arrow-chevron"></use></svg>
    </div>`
} )

export class SymDomainSwitcher implements OnInit {
  @Input() model : SymDomainSwitcherModel;

  @Input() offset : { top? : number, left? : number } = { top : 0, left : 0 };

  @Output() onShow : EventEmitter<any> = new EventEmitter();

  @Output() onHide : EventEmitter<any> = new EventEmitter();

  isDomainSwitcherMenuVisible : boolean = false;
  isHover : boolean                     = false;
  isAutoFocused : boolean               = false;
  filterItems;
  private _searchTerm : string          = '';

  get searchTerm () : string {
    return this._searchTerm;
  }

  set searchTerm ( val : string ) {
    this._searchTerm = val;
    this.filterItems = this._searchTerm ? this.performSearch( this._searchTerm ) : this.model.items;
  }

  constructor () {
  }

  performSearch ( searchString : string ) {
    searchString = searchString.toLocaleLowerCase();
    return this.model.items.filter( ( domain ) =>
      domain.message.toLocaleLowerCase().indexOf( searchString ) !== -1 );
  }

  doOnShowEvent () {
    this.onMenuToggle( true );
    this.isAutoFocused = true;
    this.onShow.emit( null );
  }

  doOnHideEvent () {
    this.onMenuToggle( false );
    this.isAutoFocused = false;
    this.onHide.emit( null );
  }

  onMenuToggle ( isVisible : boolean ) {
    this.isDomainSwitcherMenuVisible = isVisible;
  }

  onHover () {
    this.isHover = true;
  }

  onLeave () {
    this.isHover = false;
  }

  itemClicked ( event, item, model, overlaypanel : OverlayPanel ) {
    if ( event.preventDefault ) {
      event.preventDefault();
    }

    if ( item ) {
      for ( let modelItem of model.items ) {
        modelItem.isSelected = false;
      }

      model.currentDomain = item.message;
      item.isSelected     = true;

      item.command( event, item );
    }

    overlaypanel.hide();
  }

  buttonClicked ( type : string, event, model, overlaypanel : OverlayPanel ) {
    overlaypanel.hide();
    model.button[ type ].command( event );
  }

  ngOnInit () {
    for ( let modelItem of this.model.items ) {
      if ( modelItem.isSelected ) {
        this.model.currentDomain = modelItem.message;
        break;
      }
    }

    this.filterItems = this.model.items;
  }
}

@NgModule( {
  imports      : [ CommonModule, OverlayPanelModule, FormsModule, ButtonModule, InputTextModule, AutoFocusModule, HighlightSearchModule ],
  exports      : [ SymDomainSwitcher, FormsModule ],
  declarations : [ SymDomainSwitcher ]
} )

export class SymDomainSwitcherModule {
}
