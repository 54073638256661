import {NgModule, Pipe, PipeTransform} from '@angular/core';

@Pipe( {
  name : 'symTranslate',
  pure: false
} )

// translate string with {{key}}
export class SymTranslate implements PipeTransform {
    transform(value: string, args: object = {}): any {
        let translateKeys = Object.keys(args);
        if (translateKeys.length > 0) {
          for (let i = 0; i < translateKeys.length; i++) {
            let replaceString = '{{' + translateKeys[i] + '}}';
            let re = new RegExp(replaceString, 'g');
            value = value.replace(re, args[translateKeys[i]]);
          }
        }
        return value;
    }
}

@NgModule( {
  imports      : [ ],
  exports      : [ SymTranslate ],
  declarations : [ SymTranslate ]
} )

export class SymTranslateModule {
}
