import {
  Component,
  Input,
  NgModule,
  ViewChildren,
  QueryList,
  Output,
  EventEmitter
}                       from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../common/shared';
import { Utils }        from '../common/utils';
import { ArrayUtils }   from '../utils/array.utils';

import {
  GridsterModule,
  GridsterConfig,
  GridsterItem,
  GridType,
  DisplayGrid
}                                             from 'angular-gridster2';
import { DynamicModule }                      from 'ng-dynamic-component';
import { SymInlineEditModule, SymInlineEdit } from '../inline-edit/inline-edit';

@Component( {
  selector : 'sym-dashboard-grid',
  template : `
    <gridster [options]="options">
      <gridster-item class="gridster-item"
                     [item]="item"
                     *ngFor="let item of model; index as i;">
        <div class="gridster-item__container">
          <div class="gridster-item__content" *ngIf="item.component">
            <!-- gridster-item__content disables moving by touching content  -->
            <ng-template
                    [ngComponentOutlet]="item.component"
                    [ndcDynamicInputs]="item.inputs"
                    [ndcDynamicOutputs]="item.outputs"
            ></ng-template>
          </div>
          <div class="gridster-item-overlay" *ngIf="editMode">
          </div>
          <div class="gridster-item-reorder" *ngIf="editMode">
            <sym-inline-edit #inlineEdit
                             class="gridster-item-reorder__inline-edit"
                             [value]="i+1"
                             [errorMsg]="inlineEditErrorMsg"
                             [isValid]="inlineEditIsValid"
                             [isProcessing]="inlineEditIsProcessing"
                             [type]="inlineEditType"
                             [style]="inlineEditStyle"
                             (onEdit)="onInlineEdit($event)"
                             (onValidate)="onInlineValidate($event)"
                             (onSave)="onInlineSave($event)">
              {{ i + 1 }}
            </sym-inline-edit>
          </div>
          <div class="icon__uhs-close gridster-item-remove"
               *ngIf="editMode"
               (mousedown)="removeItem($event, item)"
               (touchstart)="removeItem($event, item)">
          </div>
        </div>
      </gridster-item>
    </gridster>
  `
} )
export class SymDashboardGrid {

  @Input() model : Array<GridsterItem>;

  @Output() modelChange = new EventEmitter<Array<GridsterItem>>();

  @Input() style : any;

  @Input() styleClass : string;

  @Output() onChange = new EventEmitter<boolean>();

  @ViewChildren( 'inlineEdit' ) inlineEditComponents : QueryList<SymInlineEdit>;

  options : GridsterConfig = {
    minCols                       : 4,
    margin                        : 15,
    maxCols                       : 4,
    fixedRowHeight                : this.height || 400,
    itemChangeCallback            : this.itemChange.bind( this ),
    gridType                      : GridType.VerticalFixed,
    enableEmptyCellDrop           : true,
    displayGrid                   : DisplayGrid.None,
    outerMargin                   : true,
    pushItems                     : true,
    disableAutoPositionOnConflict : false,
    draggable                     : {
      enabled : true
    },
    resizable                     : {
      enabled : false
    },
    useTransformPositioning: false
  };

  inlineEditErrorMsg : string      = '';
  inlineEditIsValid : boolean      = true;
  inlineEditIsProcessing : boolean = false;
  inlineEditType : string          = 'number';
  inlineEditStyle : object         = { 'width' : '150px' };

  onInlineEdit ( obj ) {
    // Keeping this just in case we need to do something.
  }

  onInlineValidate ( obj ) {
    // As this is number, no validation needed for now.
    this.inlineEditIsValid = obj.value !== '' && obj.value >= 0;
  }

  onInlineSave ( obj ) {
    // if number is empty, then don't do anything.
    if ( obj.value === '' ) {
      return;
    }
    let newIndex      = parseInt( obj.value ) - 1;
    let previousIndex = parseInt( obj.oldValue ) - 1;
    let dashboardCopy = Utils.deepCopy( this.model );
    ArrayUtils.move( dashboardCopy, previousIndex, newIndex );
    dashboardCopy.forEach( ( m ) => {
      m.x = 0;
      m.y = 0;
    } );
    this.model = dashboardCopy;
    this.modelChange.emit( this.model );
    this.onChange.emit(null);
  }

  private _editMode : boolean;

  @Input() get editMode () : boolean {
    return this._editMode;
  }

  set editMode ( val : boolean ) {
    this._editMode = val;
    if ( !this._editMode ) {
      this.options.displayGrid       = DisplayGrid.None;
      this.options.draggable.enabled = false;
    } else {
      this.options.displayGrid       = DisplayGrid.Always;
      this.options.draggable.enabled = true;
    }
    this.changedOptions();

  }

  private _height : number;

  @Input() get height () : number {
    return this._height;
  }

  set height ( val : number ) {
    this.options.fixedRowHeight = val;
    this.changedOptions();
  }

  constructor () {
  }

  itemChange ( item, itemComponent ) {
    setTimeout( () => {
      this.model.sort( ( a, b ) => {
        if ( a.y === b.y ) {
          return a.x - b.x;
        }
        return a.y - b.y;
      } );
      this.onChange.emit(null);
      this.modelChange.emit( this.model );

    }, 500 );
  }

  changedOptions () {
    if ( this.options.api && this.options.api.optionsChanged ) {
      this.options.api.optionsChanged();
    }
  }

  removeItem ( $event : MouseEvent | TouchEvent, item : GridsterItem ) : void {
    $event.preventDefault();
    $event.stopPropagation();
    this.model.splice( this.model.indexOf( item ), 1 );
    this.onChange.emit(null);
    this.modelChange.emit( this.model );
  }

  getDashboard () {
    return this.model;
  }
}

@NgModule( {
  imports      : [CommonModule, SharedModule, GridsterModule, DynamicModule, SymInlineEditModule],
  exports      : [SymDashboardGrid, SharedModule, GridsterModule, DynamicModule, SymInlineEditModule],
  declarations : [SymDashboardGrid]
} )
export class SymDashboardGridModule {
}
