// Why @dynamic? See https://github.com/angular/angular/issues/19698#issuecomment-349359036
// @dynamic
export class StringUtils {
  // Removes trailing whitespace or specified characters from string
  static trimStart = (str: string, chars?: string): string => {
    if (chars && chars.length) {
      return str.replace(new RegExp('^[' + chars + ']+'), '');
    } else {
      return str.replace(/^\s+/, '');
    }
  }

  // Removes trailing whitespace or specified characters from string
  // TODO: remove if not used
  static trimEnd = (str: string, chars?: string): string => {
    if (chars && chars.length) {
      return str.replace(new RegExp('[' + chars + ']+$'), '');
    } else {
      return str.replace(/\s+$/, '');
    }
  }
}
