import {
  NgModule,
  Component,
  ElementRef,
  Input,
  Renderer2,
  ContentChild,
  Output,
  EventEmitter,
  OnInit,
  ViewChild
} from '@angular/core';
import { CommonModule }                           from '@angular/common';
import { FormsModule}                             from '@angular/forms';
import { RouterModule }                           from '@angular/router';
import { DomHandler }                             from '../dom/dom-handler';
import { ISearchModel, QueryChangeValue }         from "./i-search-model";
import { InputTextModule }                        from "../input-text/input-text";
import { AutoCompleteModule }                     from "../auto-complete/auto-complete";
import {IParser, IParserAutoCompleteSuggestion, ParserQueryOperator} from "../common/parser";
import { StringUtils }                            from "../utils/string.utils";
import { SearchToken, SearchTokenType }                            from "../../services/parser-service/search-token";
import { CalendarModule }                         from "../calendar/calendar";
import { OverlayPanelModule }                     from "../overlay-panel/overlay-panel";
import { OverlayPanel }                           from "../overlay-panel/overlay-panel";

@Component( {
  selector  : 'sym-search',
  template  : `
      <div [ngClass]="{ 'ui-search ui-widget ui-widget-content' : true }" [class]="styleClass" [ngStyle]="style" id="{{ symSearchId }}" style="position:relative;">
          <div *ngIf="!customContent">
              <p-autoComplete
                  [appendTo]="appendTo"
                  [disableAbsoluteAdjustment]="disableAbsoluteAdjustment"
                  (completeMethod)="autoCompleteSearch($event)"
                  [emptyMessage]="autoCompleteNoMatchesMsg"
                  field="display"
                  [hidden]="model.useViewToggle && !model.showInputView"
                  [inputId]="model.inputId || 'symSearchInput'"
                  [(ngModel)]="model.query"
                  (ngModelChange)="queryChange($event)"
                  (onBlur)="toggleView($event)"
                  (onKeyUp)="onInputChange($event)"
                  (onSelect)="autoCompleteSelect($event)"
                  (onEnter)="enterTextarea($event)"
                  [placeholder]="model.placeholder"
                  skipModelChangeOnSelectItem="true"
                  [suggestions]="autoCompleteSuggestions"
                  [textarea]="model.textarea"
                  (mouseup)="showCalendarForRegistered($event)"
          ></p-autoComplete>
              <div *ngIf="model.useViewToggle" [hidden]="model.showInputView" (click)="toggleView($event)">
                  <p *ngIf="!customAlternateView" class="symSearchAlternateViewContent">{{model.query}}</p>
                  <ng-content select=".symSearchAlternateViewContent"></ng-content>
              </div>
          </div>
          <ng-content></ng-content>
          <p-overlayPanel #calendarOverlayPanel class="sym-overlay__calendarOverlayPanel" [showCloseIcon]="true" [dismissable]="false" [disableAbsoluteAdjustment]="true">
              <div>
                  <p-calendar id="calendar" class="sym-calendar__searchCalendar" [(ngModel)]="searchCalendarRangeDates" selectionMode="range" [showTime]="true" hourFormat="12" [readonlyInput]="true" showOnFocus="true" [inline]="true" [search]="true"></p-calendar>
                  <div>
                      <sym-button type="text" styleClass="ui-button-transparent" label="Cancel" (click)="hideCalendarOverlayPanel()"> </sym-button>
                      <sym-button type="text" label="Apply" (click)="addRangeDates()"></sym-button>
                  </div>
              </div>
          </p-overlayPanel>
      </div>
  `,
  styles: [`
      /* Parser field config is set on the client side, so won't ever need to go to the server to get it, so no need for spinner */
      ::ng-deep .ui-search .ui-autocomplete-loader { display: none; }
  `],
  providers : [ DomHandler ]
} )
export class Search implements OnInit {
  @Input() model: ISearchModel;

  @Input() parserService: IParser;

  @Input() appendTo: any;

  @Input() autoCompleteNoMatchesMsg: string;

  @Input() style : any;

  @Input() styleClass : string;

  @ContentChild('symSearchContent') customContent: ElementRef;
  @ContentChild('symSearchAlternateViewContent') customAlternateView: ElementRef;

  @Output() onBlur: EventEmitter<FocusEvent> = new EventEmitter();
  @Output() onChange: EventEmitter<FocusEvent> = new EventEmitter();
  @Output() onEnter: EventEmitter<any> = new EventEmitter();
  // @Output() changedToken: EventEmitter<any> = new EventEmitter();
  // @Output() onClick: EventEmitter<any> = new EventEmitter();

  autoCompleteInput: any;

  autoCompleteSuggestions: IParserAutoCompleteSuggestion[];

  tokens: SearchToken[];

  disableAbsoluteAdjustment = true; //for autocomplete

  constructor (
    public el : ElementRef,
    public renderer : Renderer2
  ) {
  }

  ngOnInit () {
    if (!this.model || typeof this.model !== 'object') {
      throw new Error('Sym-search model should be an object');
    }

    if (this.model && this.model.queryChange && typeof this.model.queryChange !== 'function') {
      throw new Error('Sym-search model.queryChange should be a function');
    }

    if (this.model.useViewToggle) {
      // If no showInputValue is provided, default to the alternate view only if a query is pre-defined
      this.model.showInputView = this.model.showInputView === undefined ? !Boolean(this.model.query) : this.model.showInputView;
    } else {
      this.model.showInputView = this.model.showInputView === undefined ? true : this.model.showInputView;
    }

    // Avoid complicating query change logic with testing if query is defined or not
    this.model.query = this.model.query || '';

    // Avoid complicating template with testing if callback function is defined or not
    this.model.queryChange = this.model.queryChange || function () {};
  }

  toggleView (event) {
    console.log('Search: toggleView', 'focus?', event instanceof FocusEvent, 'event:', event);
    if (this.model.useViewToggle) {
      this.model.showInputView = !this.model.showInputView;

      // Let Angular process the `showInputView` change, possibly triggering `blur` events
      setTimeout(() => {
        if (!this.autoCompleteInput) {   // Lazy init
          this.autoCompleteInput = this.el.nativeElement.querySelector('#' + this.model.inputId);
        }

        if (this.model.showInputView) {
          this.autoCompleteInput.focus();
        } else {
          this.onBlur.emit(event);
        }
      });
    } else if (event instanceof FocusEvent && event.type === 'blur') {
      this.onBlur.emit(event);
    }
  }


    @ViewChild( OverlayPanel, {static : true} ) calendarOverlayPanel : OverlayPanel;

    queryChange(value: string) {
      console.info('Search: queryChange', value);
      const change: QueryChangeValue = { value: value };
      if (this.parserService) {
        const isValid = this.parserService.setQuery(value).isValid();
        console.log('Search: send queryChange: valid:', isValid, 'errors:', this.parserService.getErrors());

        change.parserInfo = {
          expandedQuery: this.parserService.getExpandedQuery(),
          isValid: isValid
        }

      }
      console.log('Search: send queryChange:', change, 'parserService?', Boolean(this.parserService), '#tokens:', (this.parserService ? this.parserService.getTokens() || [] : []).length);
      this.model.queryChange(change);

      this.tokens = this.parserService.getTokens();
          if ( this.isRegisteredAtEndOfQueryString() ) {
              this.updateSearchCalendarRangeDates();
              this.showCalendarOverlayPanel();
          }

    }


    searchCalendarRangeDates : Date[];
    symSearchId              : string = 'symSearchId-' + Math.floor( ( Math.random() * 1000000 ) + 1 );
    selectionStart           : number = 0;
    selectionEnd             : number = 0;
    selectedToken            : any    = { name: '', dateRange: '', index: 0 };

    isRegisteredAtEndOfQueryString() {
        if ( this.tokens && this.tokens.length === 1 && this.tokens[0].token === 'registered:' ) {
            return true;
        } else if ( this.tokens && this.tokens.length && this.tokens[this.tokens.length - 1].token === 'registered:' ) {
            return true;
        } else {
            return false;
        }
    }

    setSelection( event ) {
        let target          = ( event )  ? event.target          : '';
        this.selectionStart = ( target ) ? target.selectionStart : this.selectionStart,
        this.selectionEnd   = ( target ) ? target.selectionEnd   : this.selectionEnd;
    }

    setSelectedToken() {
        if ( this.tokens ) {
            this.tokens.forEach( ( token, index, array ) => {
                let tokenStart = token.meta.token.start,
                    tokenEnd   = token.meta.token.end;
                if ( ( this.selectionStart >= tokenStart ) && ( this.selectionEnd <= ( tokenEnd - 1 ) ) ) {
                    this.selectedToken.name      = token.displayName;
                    this.selectedToken.dateRange = token.displayValue;
                    this.selectedToken.index     = index;
                }
            });
        }
    }

    resetSelectedToken() {
        this.selectedToken.name  = '';
        this.selectedToken.index = 0;
    }

    isSelectedTokenRegistered( override? : '' ) {
        let isRegistered = ( this.selectedToken.name ) ? ( this.selectedToken.name.toLowerCase() === 'registered' ) : '';
        isRegistered = ( !isRegistered ) ? override : isRegistered; // This is an override to help with unit testing.
        return isRegistered;
    }

    updateSearchCalendarRangeDates(){
        if ( this.selectedToken.dateRange ) {
            let dates         = this.selectedToken.dateRange,
                datesArray    = [],
                newDatesRange = [];
            datesArray = dates.replace( '[', '' ).replace( ']', '' ).split( ' TO ' );
            newDatesRange = datesArray.map( ( date, index, array ) => {
                let x: number = parseInt( date );
                return new Date( x );
            } );
            this.searchCalendarRangeDates = newDatesRange;
        }  else {
            this.searchCalendarRangeDates = [ new Date() ];
        }
    }

    showCalendarForRegistered( event, o? ) {
        this.resetSelectedToken();
        this.setSelection( event );
        this.setSelectedToken();
        if ( this.isSelectedTokenRegistered( o ) ) {
          this.updateSearchCalendarRangeDates();
          this.showCalendarOverlayPanel();
        } else {
          this.hideCalendarOverlayPanel();
        }
    }

    showCalendarOverlayPanel() {
        this.calendarOverlayPanel.show( { type : 'click' }, document.getElementById( this.symSearchId ) );
    }

    hideCalendarOverlayPanel() {
        this.calendarOverlayPanel.hide();
        this.searchCalendarRangeDates = [ new Date() ];
    }

    getDatesQuery() {
        let date_01           = ( this.searchCalendarRangeDates[0] ) ? this.searchCalendarRangeDates[0].toString() : '',
            date_02           = ( this.searchCalendarRangeDates[1] ) ? this.searchCalendarRangeDates[1].toString() : '',
            dateTime_01       = ( date_01 ) ? new Date( date_01 ).getTime() : '',
            dateTime_02       = ( date_02 ) ? new Date( date_02 ).getTime() : '',
            registered        = 'registered:',
            searchStringValue = '[' + ( ( date_01 ) ? dateTime_01 : '' ) + ( ( date_02 ) ? ' TO ' + dateTime_02 : '' ) + ']';
        return { 'registered' : registered, 'searchStringValue' : searchStringValue };
    }

    addRangeDates() {
        let query            = this.getDatesQuery(),
            newQuery: string = '',
            space: string    = ' ';
        if ( !this.tokens.length ) {
            this.model.query = query.registered + query.searchStringValue;
        } else if ( this.tokens.length > 0 && this.isRegisteredAtEndOfQueryString() ) {
            this.tokens.forEach( ( token, i, arr ) => {
                space = ( i !== arr.length - 1 ) ? ' ' : '';
                if ( i === ( arr.length - 1 ) ) {
                    newQuery += query.registered + query.searchStringValue + space;
                } else {
                    newQuery += token.token + space;
                }
            } );
            this.model.query = newQuery;
        } else {
            this.tokens.forEach( ( token, i, arr ) => {
                space = ( i !== arr.length - 1 ) ? ' ' : '';
                if ( token.displayName === 'Registered' && this.selectedToken.index === i ) {   // TODO: replace with token.type === Date check?
                    newQuery += query.registered + query.searchStringValue + space;
                } else {
                    newQuery += token.token + space;
                }
            } );
            this.model.query = newQuery;
        }
        this.hideCalendarOverlayPanel();
    }

  // TODO: ideally this gets called based on cursor position, i.e. "abc|" and "abc|def" both return everything starting with "abc"
  autoCompleteSearch(event) {
    console.log('Search: AutoComplete: search callback:', event);

    // Capture input elt for use in autoCompleteSelect later on
    if (!this.autoCompleteInput) {   // Lazy init
      this.autoCompleteInput = event.originalEvent.target;
    }

    // Making distinction between text selections and no selection only for debugging purposes
    // Likely the user does not want to replace (accidental?) text selection with chosen token.
    // if (this.autoCompleteInput.selectionStart === this.autoCompleteInput.selectionEnd) {
    //   console.log('Search: AutoComplete: Cursor at index ' + this.autoCompleteInput.selectionStart);
    // } else {
    //   console.log('Search: AutoComplete: Text selection from index ' + this.autoCompleteInput.selectionStart + ' to ' + this.autoCompleteInput.selectionEnd);
    // }

    const tokens = this.parserService.getTokens() || [];
    const found = tokens.some(token => {
      const start = token.meta.token.start;
      const end = token.meta.token.end;
      const isLastToken = token === tokens[tokens.length - 1];
      if (this.autoCompleteInput.selectionStart > start && (this.autoCompleteInput.selectionEnd <= end || (isLastToken && this.autoCompleteInput.selectionEnd <= this.parserService.getQuery().length))) {
        // `token` is the token being worked on
        const typedTokenString = this.model.query.substring(start, this.autoCompleteInput.selectionStart);
        console.log('Search: AutoComplete: Token worked on:', typedTokenString, token);
        const typed = this.parserService.getFieldNameAndValuePair(typedTokenString);
        if (typed[1] === undefined) {   // No value specified, so match on name
          if (token.type === SearchTokenType.LogicalOperator || token.type === SearchTokenType.Parenthesis) {
            // TODO: need operator suggestions
            // this.autoCompleteSuggestions = [
            //   {actual:' AND ', display: 'AND '},
            //   {actual:' OR ', display: 'OR '}
            // ]
          }
          else {
            this.autoCompleteSuggestions = this.parserService.getMatchingFields(typed[0]);   // Pass up to cursor/selection start, not token.meta.token
            //console.log('Search: Autocomplete: match on name:', typed[0], 'matches:', this.autoCompleteSuggestions);
          }
        } else {   // Separator was specified, so match on value
          this.autoCompleteSuggestions = this.parserService.getMatchingValues(this.parserService.createTokenString(typed[0].trim(), StringUtils.trimStart(typed[1]), ParserQueryOperator.EQUALS));
          //console.log('Search: Autocomplete: match on value:', StringUtils.trimStart(typed[1]), 'matches:', this.autoCompleteSuggestions);
        }

        return true;
      }
    });

    // When the user is typing something that's not yet a recognized field or value, the continuous showing of
    // the "No Matches" indicator is annoying. Suppress it.
    // (This is perhaps more of an issue with the display string parser than with the Lucene parser, but be consistent and do for all)
    if (!found || (this.autoCompleteSuggestions || []).length === 0) {
      this.autoCompleteSuggestions = undefined;
    }
  }

  autoCompleteSelect(item: IParserAutoCompleteSuggestion) {
    const tokens = this.parserService.getTokens() || [];
    console.log('Search: AutoComplete: onSelect callback:', item, tokens);

    tokens.some(token => {
      const start = token.meta.token.start;
      const end = token.meta.token.end;
      const isLastToken = token === tokens[tokens.length - 1];
      if (this.autoCompleteInput.selectionStart > start && (this.autoCompleteInput.selectionEnd <= end || (isLastToken && this.autoCompleteInput.selectionEnd <= this.parserService.getQuery().length))) {
        // `token` is the token being worked on
        let cursorPos;
        let newTokenStr;
        const tokenStringEnteredByUser = this.model.query.substring(start, this.autoCompleteInput.selectionStart);
        console.log('Search: AutoComplete: Token worked on:', tokenStringEnteredByUser, token);
        const fieldAndValueEnteredByUser = this.parserService.getFieldNameAndValuePair(tokenStringEnteredByUser);
        if (fieldAndValueEnteredByUser[1] === undefined) {
          // "Friendly" token -> database token is difficult if a display field name is entered or chosen but the
          // field/value separator is not there yet. The underlying database parser will treat the field name as freeform.
          // Only once the separator is there will it know it's a query.
          // Unfortunately, once it's a freeform, there's no way back to it being interpreted as a query.
          // And so--also to be helpful--add the separator immediately so this issue does not occur... at least not
          // with auto-select... it still happens with direct typing :(
          // TODO: above comment is not implemented. If needed, make input option so it can be done only for displaystringparser
          console.log('Search: AutoComplete: Replacing field name');
          newTokenStr = this.parserService.replaceFieldName(token.meta.token.rawToken, item.actual);
          cursorPos = start + item.actual.length;   // Stay at end of field name, not end of token (matters if field of a token that already has a value is edited)
        } else {
          console.log('Search: AutoComplete: Replacing field value', tokenStringEnteredByUser, '-->', item.actual);
          newTokenStr = this.parserService.replaceFieldValue(token.meta.token.rawToken, item.actual);
          console.log('Search: AutoComplete: old cursorPos', cursorPos, 'start', start, 'newTokenStr', newTokenStr, 'new cursorPos', (start + newTokenStr.length));
          cursorPos = start + newTokenStr.length;
        }

        this.model.query = this.model.query.substring(0, start) + newTokenStr + this.model.query.substring(end);

        // Let query change handler run, then restore cursor position
        setTimeout(() => {
          this.autoCompleteInput.setSelectionRange(cursorPos, cursorPos);
        });

        return true;
      }
    });
  }

  enterTextarea (event) {
    this.onEnter.emit(event);
    event.preventDefault();
  }

  onInputChange (event) {
    this.onChange.emit(event);
  }
}

@NgModule( {
  imports: [ CommonModule, RouterModule, FormsModule, InputTextModule, AutoCompleteModule, CalendarModule, OverlayPanelModule ],
  exports      : [ Search, RouterModule, FormsModule, CalendarModule, OverlayPanelModule ],
  declarations : [ Search ]
} )
export class SearchModule {
}
