import {Injectable} from '@angular/core';
import {ParserToken} from './parser-token';
import {BinaryNode} from './binary-node';
import {
  IParserAutoCompleteSuggestion, IParserConfiguration,
  IParserError,
  IParserFieldConfiguration,
  IParserFieldLookup,
  IParserFieldValueConfiguration,
  IParserMacroConfiguration,
  ParserFieldName,
  ParserFieldSimpleValue,
  ParserQueryOperator
} from '../../components/common/parser';
import {SearchToken, SearchTokenType} from './search-token';
import {ValidationErrors} from '@angular/forms';
import {Utils} from "../../components/common/utils";
import {LuceneParserService} from "./lucene-parser.service";

/**
 * A parser recognizing display name/value pairs.
 *
 * Each component instance that uses this parser should use its own unique parser instance. Since by default, Angular
 * dependency injection re-uses a shared instance (see https://angular.io/guide/architecture-services), make sure to
 * configure the component like so:
 * ```
 * @Component( {
 *   providers: [ DisplayStringParserService ]
 * } )
 * ```
 *
 * Problem
 * =======
 *
 * Parsing a query consisting of display strings is hard. Consider these fields:
 * * { displayName: 'Field', displayValues: [ 'One', 'One or Another' ] }
 * * { displayName: 'Another Field', displayValues: [ 'One' ] }
 * How to parse the query: 'Field:One or Another Field: One'?  Is the "or" an operator (query is valid) or part of a value (query is invalid)?
 * And the query: 'Field:Two or Another Field: One'?  Is there an invalid value "Two" or is the invalid value "Two or Another" and there's a missing operator?
 *
 * Challenges
 * ==========
 *
 * * Parsing a query string into tokens: how to determine token boundaries
 *   Easy when field names and values cannot contain spaces, hard to very hard otherwise
 *   Things get especially tricky when there are:
 *   * Operators within display strings
 *   * Key/value separator character within display strings
 *   * Special characters within display strings, especially those normally signifying token boundaries (e.g. parenthesis)
 *   * Identical display field names or values for different fields
 *   * Field display names or values that are the start or end of another field's display name or value
 *
 * * Autocomplete requires knowing which token(s) the user is operating on: map cursor/selection to token(s)
 *   Easy when there's ever only one parser, harder if there are multiple, e.g. a database parser and a friendly display string parser
 *   In the end, this likely means one parser cannot drive autocomplete behavior of another
 *
 * Algorithm
 * =========
 *
 * The least restrictive algorithm, in terms of what characters are allowed in display strings, could work like so:
 * * Find the single words directly in front of and directly after each key:value separator
 * * Walk backwards and forwards, adding more words until the next token is reached or an operator separating two tokens is reached,
 *   keeping track of the longest field/value that was valid
 * This requires a large amount of string comparisons (every possible key:value combination, multiple times), and will be slow.
 * There is still a chance that multiple field/value matches are possible, so parsing may not be correct.
 *
 * Performance should be better by placing some restrictions on what character sequences are allowed within display strings.
 * If operators and parenthesis are not allowed in display strings, determining where tokens end becomes much easier.
 * If the key:value separator is not allowed in display strings, determining where keys and values are becomes much easier.
 * This is what the current algorithm does, with one exception: operators have to be specified in all uppercase.
 * This allows for the words "or" and "and" (all lowercase) to be part of display strings.
 *
 * Note that:
 * - all terms must be unquoted key/value pairs, using the specified separator (no freeform queries here)
 * - all terms must use operators (use "k1:v1 OR k2:v2", not "k1:v1 k2:v2")
 * - operators are case sensitive (to avoid treating "and" or "or" in a display string as an operator token)
 * - there's no NOT operator (not even "-")
 * - any Lucene special character in display strings must be escaped using a backslash
 *   (this is weird as display strings have nothing to do with Lucene syntax, but this is so that queries can be parsed correctly)
 * - there's no Lucene syntax support (e.g. +/-, ranges)
 *
 * Restrictions of display parser:
 * * All specified field names and hardcoded values must have a display variant (as rules for database field name/values are different and could mess up display string query parsing)
 * * If a display string parser is used, the database input must also be limited (otherwise how to convert syntax allowed in database query to display string query?)
 * * Operators are required between terms (otherwise cannot parse if one or more words of a display value === one or more words of a display name -- possible solution: don't allow this in config)
 * * No key/value separator in display string (must be able to split on separator char, and if there are !== 2 parts, that means no value or no operator)
 *
 * These restrictions could possibly be overcome by moving restrictions to the database parser, or avoiding issues by not allowing configs that lead to such issues.
 *
 * Alternatives tried:
 * * Forward any key typed by the user to the database parser & relying on database parser for tokenization
 *   This won't work as tokenization rules can be way different (field display name consisting of two words end up two freeform tokens instead of the field name of an incomplete key/value pair)
 *
 * @author maarten
 */
@Injectable({ providedIn : 'root' })
export class DisplayStringParserService extends LuceneParserService {
  // TODO: do auto-escape of special chars
  // TODO: shouldn't specialChars be a subset for DisplayStringParser? Don't care about Lucene here, only about parsablility...

  protected negatePrefix = 'NOT ';

  protected displayNameConfigLookup: { [displayName: string]: IParserFieldConfiguration } = {};

  constructor() {
    super();

    // For the parsing process only (ignoring validity according to parser config), anything goes for either
    // a key or a value, but first char and last char cannot be the separator or a space.
    // One exception: string can end in the separator only if it's escaped.
    // To debug the keyOrValuePattern regex, see https://regex101.com/r/nDj1nK/1
    const separator = this.escapeRegexChars(this.fieldNameAndValueSeparator);
    const keyOrValuePattern = '([^' + separator + '\\s]|' +                       // Any single character that is not the separator or space
      this.escapeRegexChars(this.escapeChar) + separator + '|' +                                 // Only 2-char string that can end in separator is "\:"
      '[^' + separator + '\\s].*' + this.escapeRegexChars(this.escapeChar) + separator + '|' +   // Any 3+ character strings that does not start with separator or space and ends in "\:"
      '[^' + separator + '\\s].*[^' + separator + '\\s])';                        // Any 2+ character string that does not start with or ends in separator or space

    this.queryRegex = new RegExp('^' + keyOrValuePattern + '\\s*' + separator + '\\s*' + keyOrValuePattern + '$', 'm');
  }

  configure(config: IParserConfiguration): this {
    this.displayNameConfigLookup = {};
    return super.configure(config);
  }

  private assertDisplayNameUniquenessAcrossAllFieldsAndMacros(config: IParserFieldConfiguration[], item: IParserFieldConfiguration, displayName: string, prefix: string) {
    (config || []).forEach(i => {
      // Note: fields/macros with an ID must have identical name and displayName, so don't flag those (2nd clause takes care of that)
      if (i !== item && i.name !== item.name && i.displayName === displayName) {
        throw new Error(prefix + ' ' + item.name + ' must have a unique ' + (item.displayName ? '' : 'fallback ') + 'displayName: ' + displayName);
      }
    });
  }

  protected validateConfiguration(item: IParserFieldConfiguration | IParserMacroConfiguration, prefix: string): void {
    super.validateConfiguration(item, prefix);

    // If no displayName is present, fallback will be to the database name, which is required for the lookup by
    // displayName to work. See `configureLookupItem`.
    // Note that the fallback `item.name` is already validated to use escaping for Lucene special characters, so this is fine.
    const displayName = item.displayName ? item.displayName as string : item.name;

    // Display name should contain valid characters
    this.validateDisplayName(item.name, displayName, prefix);

    // Display name should be unique among all fields/macros, otherwise cannot parse "display strings only" plain-text query
    this.assertDisplayNameUniquenessAcrossAllFieldsAndMacros(this.config.fields, item, displayName, prefix);
    this.assertDisplayNameUniquenessAcrossAllFieldsAndMacros(this.config.macros, item, displayName, prefix);

    (item.values || []).forEach(value => {
      // Display value cannot contain special character unless it's escaped
      // Note that although the fallback `value` is already validated to use escaping for Lucene special characters,
      // any leading and trailing quotes--which are fine for field values--are problematic for display values and will
      // either need to be escaped or removed. Since they're just a way of grouping and not part of the actual value,
      // remove them.
      const displayValue = this.isParserFieldValueConfiguration(value) ?
          (value as IParserFieldValueConfiguration).displayValue :
          typeof value === 'string' ? String(value).replace(/^"|"$/g, '') : undefined;
      if (displayValue) {
        this.validateDisplayValue(item.name, displayValue, prefix);
      }
    });
  }

  protected configureLookupItem(item: IParserFieldConfiguration, addMethod, fieldLookup: IParserFieldLookup, prefix: string) {
    super.configureLookupItem(item, addMethod, fieldLookup, prefix);

    // Add display string lookup (across fields and macros) (and aliases if there is no displayName)
    // Note: fields/macros with an ID must have identical name and displayName, so aggregate values for similar items
    //       (validation of these kinds of configs is already done, so just do the aggregation here, no error checking)
    if (item.displayName) {
      if (!this.displayNameConfigLookup[item.displayName as string]) {
        // To avoid possibly changing the original item later (see storeInDisplayNameConfigLogic), store a copy of the config item
        item = Utils.deepCopy(item);
      }
      this.storeInDisplayNameConfigLookup(item.displayName as string, item);
    } else {
      if (!this.displayNameConfigLookup[item.name]) {
        // To avoid possibly changing the original item later (see storeInDisplayNameConfigLogic), store a copy of the config item
        item = Utils.deepCopy(item);
      }
      this.storeInDisplayNameConfigLookup(item.name, item);
      // Use the same item reference for the aliases, so if one gets changed later, they all pick it up
      (item.aliases || []).forEach(alias => {
        this.storeInDisplayNameConfigLookup(alias, item);
      });
    }
  }

  private storeInDisplayNameConfigLookup(key: string, item: IParserFieldConfiguration) {
    let stored = this.displayNameConfigLookup[key];
    if (stored) {
      if (item.values) {
        // Append to existing config if present (this is so when fields with same name but different IDs were configured)
        stored.values = [...(stored.values || []), ...item.values];
      }
    } else {
      this.displayNameConfigLookup[key] = item;
    }
  }

  peekWord(): string {
    const resetLocation = this.stream.position;
    // Skip to beginning of next word
    while (this.stream.hasNext && !this._isWhiteSpace(this.stream.peek())) {
      this.stream.next();
    }
    this._skipWhiteSpace();
    const start = this.stream.position;
    while (this.stream.hasNext && !this._isWhiteSpace(this.stream.peek())) {
      this.stream.next();
    }
    const retVal = this.stream.input.substring(start, this.stream.position);
    this.stream.reset(resetLocation);
    return retVal || undefined;
  }
  peekNonWhitespace(): string {
    const resetLocation = this.stream.position;
    this._skipWhiteSpace();
    const retVal = this.stream.peek();
    this.stream.reset(resetLocation);
    return retVal;
  }

  protected _extractToken(): ParserToken {
    let token: ParserToken;
    let slice = '';
    let start = this.stream.position;
    let waitingForClosingBracket = false;

    do {
      // Skip leading whitespace, if any
      if (slice.length === 0 && this._skipWhiteSpace()) {
        start = this.stream.position;
      }

      // Skip whitespace before field/value separator (always) and after field/value separator (only if next char/word is not a terminal)
      // The terminal check is there to avoid treating a large sub-query as a field value if the field value is missing / being edited,
      // e.g. "Client Version:  OR Status: Compromised", adding a space after "Client Version:" should insert autocomplete value, not replace the "OR Status: Compromised" following it.
      let nextCharIsTerminal =
        (this._anyEqual([this.oParen, this.cParen, undefined], this.peekNonWhitespace()) &&                                              // IF next non-whitespace char is a parenthesis
         !this._isEscape(this.stream.peekPrev()) && this._anyEqual([this.oParen, this.cParen, undefined], this.stream.peek())) ||        //    (except if immediately preceded by the escape char)
        (this._isWhiteSpace(this.stream.peek()) && !waitingForClosingBracket && (this._isLogOp(slice) || this._isLogOp(this.peekWord())));   // OR IF (offset by spacing) current slice is an operator or next word is an operator

      // Ignore space around key:value separator
      if (slice && slice.charAt(slice.length - 1) === this.fieldNameAndValueSeparator && !this._isEscape(slice.charAt(slice.length - 2)) && !nextCharIsTerminal ||   // Last char was (non-escaped) separator, so skip trailing space
          this.peekNonWhitespace() === this.fieldNameAndValueSeparator && !this._isEscape(this.stream.peekPrev())) {                                                 // OR (non-escaped) separator comes up next, so skip leading space
        this._skipWhiteSpace();
      }

      const curr = this.stream.next();   // Returns the current char in the stream and auto-advances to the next character...
      let next = this.stream.peek();     // ...so doing a peek now (after having done stream.next) will in effect return the "next" character without going past it
      if (curr === void 0) {
        break;
        // TODO: remove below + else below that
        // if (!slice.trim()) {
        //   // Nothing left, done
        //   break;
        // }   // Else let slice be handled by if statements below
      } else {
        slice += curr;
      }

      let skipCurrCheck = false;
      if (this._isEscape(curr) && this._anyEqual(this.specialChars, next)) {
        // Avoid next char to be interpreted as having special meaning
        slice += next;
        console.log('saw escape', curr, next, slice);
        this.reset(this.stream.position + 1);

        // TODO: check if nextCharIsTerminal and same logic as below... ignore curr though
        next = this.stream.peek();
        skipCurrCheck = true;
      } else {
        if (this._isBracket(curr)) {
          waitingForClosingBracket = !waitingForClosingBracket;
        }
      }

      // TODO: the nextCharIsTerminal determination won't work for invalid queries, e.g. `Client Version:12.1 OR (Client Version:14.2  Status:Secure AND (Status:Compromised OR Status:Secure))`
      nextCharIsTerminal = this._anyEqual([this.oParen, this.cParen, undefined], this.peekNonWhitespace()) || (this._isWhiteSpace(next) && !waitingForClosingBracket && (this._isLogOp(slice) || this._isLogOp(this.peekWord())));   // Next non-whitespace char is a paren or current slice is an operator or next word is an operator
      //console.log('input', this.stream.input, 'slice', slice, 'nextCharIsTerminal', nextCharIsTerminal);
      if (!skipCurrCheck && this._anyEqual([this.oParen, this.cParen], curr)) {
        //console.log('Found grouping: ' + (curr === this.oParen ? 'open' : 'close') + ' parenthesis');
        token = new ParserToken(SearchTokenType.Parenthesis, curr, this.stream.input.substring(start, this.stream.position), start, this.stream.position);
        slice = ''; // reset slice
        break;
      } else if (nextCharIsTerminal && this._isLogOp(slice)) {
        //console.log('Found operator: ' + slice);
        const rawToken = this.stream.input.substring(start, this.stream.position).trim();
        token = new ParserToken(SearchTokenType.LogicalOperator, slice, rawToken, start, start + rawToken.length);
        slice = ''; // reset slice
        break;
      } else if (nextCharIsTerminal && this._isQuery(slice)) {
        //console.log('Found query: ' + slice);
        const rawToken = this.stream.input.substring(start, this.stream.position);
        token = new ParserToken(SearchTokenType.Query, slice, rawToken, start, start + rawToken.length);
        slice = ''; // reset slice
        break;
      }
    } while (this.stream.hasNext);

    // Anything left was unrecognized, and therefore invalid
    if (slice.trim()) {
      //console.log('Found invalid: ' + slice);
      const rawToken = this.stream.input.substring(start, this.stream.position);
      token = new ParserToken(SearchTokenType.Invalid, slice.trim(), rawToken, start, start + rawToken.length);
    }

    return token;
  }

  protected _isLogOp(string: string) : boolean {
    // Note Lucene parser is more lenient and matches both lower-, upper- and mixed-case operators.
    // Not so for the DisplayStringParser.
    // To avoid having to disallow the words "or" or "and" in lowercase, a case-sensitive comparison is done here
    return this.logOps.some(lop => string === lop);
  }

  protected _validateSimpleExpression(node: ParserToken, expression: string, errors: IParserError[], onlyNode?: boolean) : void {
    if (!node || errors.length > 0) {
      return;
    }
    if (onlyNode && !~[SearchTokenType.Query].indexOf(node.type)) {
      errors.push({ code: 'INCOMPLETE_QUERY' });
    } else if (node.type === SearchTokenType.Query) {   // Only queries have key/value pairs
      const displayPair = this.getFieldNameAndValuePair(node.token);
      if (!this.isAllowedField(displayPair[0])) {
        errors.push({ code: 'INVALID_FIELD_NAME', expression: displayPair[0] });
      } else if (!displayPair[1] || displayPair[1].length ===  0) {
        // Value must exist
        errors.push({ code: 'NO_FIELD_VALUE', expression: displayPair[0] + ': ' + displayPair[1] });
      } else {
        const errs = this.isAllowedFieldValue(displayPair[0], displayPair[1]);
        if (errs !== null) {
          console.log('validateSimple: invalid field value:', displayPair[0], displayPair[1], errs);
          errors.push({ code: 'INVALID_FIELD_VALUE', expression: displayPair[0] + ': ' + displayPair[1], validationErrors: errs });
        }
      }
      const config = this.displayNameConfigLookup[displayPair[0]];
      if (config && config.hasOwnProperty('query')) {   // Only macro has query (and is required)
        node.type = SearchTokenType.Macro;
      }
    }
  }

  private pushQueryOrInvalidToken(token: ParserToken, tokens: SearchToken[]): void {
    if (token.type === SearchTokenType.Query) {
      let displayPair = this.getFieldNameAndValuePair(token.token);
      const configs = [ this.displayNameConfigLookup[displayPair[0]] ];
      displayPair = this.removeEscapeCharFromDisplayPair(displayPair);
      tokens.push(new SearchToken(SearchTokenType.Query, token.token, displayPair[0], displayPair[1], true, token.token.startsWith(this.negatePrefix), { config: configs, token: token }));
    } else {
      tokens.push(new SearchToken(SearchTokenType.Invalid, token.token, undefined, undefined, true, token.token.startsWith(this.negatePrefix), { token: token }));
    }
  }

  // Get all valid-only search tokens
  protected getSearchTokens(node: BinaryNode | ParserToken, tokens: SearchToken[]): void {
    if (!node) {
      return;
    }

    if (node instanceof BinaryNode) { // In-order traversal
      if (node.leftParen) {
        tokens.push(new SearchToken(SearchTokenType.Parenthesis, node.leftParen.token, undefined, undefined, undefined, undefined, { pairIsLeft: true, token: node.leftParen, matchedPair: node.rightParen }));
      } // left-paren
      this.getSearchTokens(node.left, tokens); // left
      if (node.op) {
        tokens.push(new SearchToken(SearchTokenType.LogicalOperator, node.op.token, node.op.token.toUpperCase(), undefined, undefined, undefined, { token: node.op }));
      } // op
      this.getSearchTokens(node.right, tokens); // right
      if (node.rightParen) {
        tokens.push(new SearchToken(SearchTokenType.Parenthesis, node.rightParen.token, undefined, undefined, undefined, undefined, { pairIsLeft: false, token: node.rightParen, matchedPair: node.leftParen }));
      } // right-paren
    } else {
      if (node.type === SearchTokenType.Macro) {
        let displayPair = this.getFieldNameAndValuePair(node.token);
        const configs = [ this.displayNameConfigLookup[displayPair[0]] ];
        displayPair = this.removeEscapeCharFromDisplayPair(displayPair);
        tokens.push(new SearchToken(SearchTokenType.Macro, node.token, displayPair[0], displayPair[1], true, node.token.startsWith(this.negatePrefix), { config: configs, token: node }));
      } else {
        this.pushQueryOrInvalidToken(node, tokens);
      }
    }
  }

  // Get all search tokens, valid or not
  protected showAllSearchTokens(tokens: SearchToken[]): void {
    this.reset(0);
    let curr;
    while (curr = this.next()) {
      let displayPair = this.getFieldNameAndValuePair(curr.token);
      const config = this.displayNameConfigLookup[displayPair[0]];
      if (config && config.hasOwnProperty('query')) {   // Only macro has query (and is required)
        const configs = [ config ];
        displayPair = this.removeEscapeCharFromDisplayPair(displayPair);
        tokens.push(new SearchToken(SearchTokenType.Macro, curr.token, displayPair[0], displayPair[1], true, curr.token.startsWith(this.negatePrefix), { config: configs, token: curr }));
      } else if (curr.type === SearchTokenType.Parenthesis) {
        tokens.push(new SearchToken(SearchTokenType.Parenthesis, curr.token, undefined, undefined, undefined, undefined, { token: curr }));
      } else if (curr.type === SearchTokenType.LogicalOperator) {
        tokens.push(new SearchToken(SearchTokenType.LogicalOperator, curr.token, curr.token.toUpperCase(), undefined, undefined, undefined, { token: curr }));
      } else {
        this.pushQueryOrInvalidToken(curr, tokens);
      }
    }
  }

  isAllowedField(field: ParserFieldName): boolean {
    if (!field || field.length ===  0) {
      return false;                                            // Field must exist
    }

    return field === '_exists_'                                // Special keyword
      || !this.hasFieldConfig                                  // No field limitations in effect
      || this.displayNameConfigLookup.hasOwnProperty(field);   // Known field or macro
  }


  // To avoid double work, it's assumed field is already validated
  isAllowedFieldValue(field: ParserFieldName, value: string): ValidationErrors {
    console.log('isAllowedFieldValue: hasFieldConfig?', this.hasFieldConfig, 'hasMacroConfig?', this.hasMacroConfig, 'field:', field, 'value:', value);

    let errors = null;

    // Deal with ranges and sets
    let values = null;
    if (/^[\[{](.+)\s+TO\s+(.+)[\]}]$/.test(value)) {
      // Range
      values = [RegExp.$1, RegExp.$2];
    } else if (/^\[.+(\s+OR\s+.+)+]$/.test(value)) {
      // Set
      value = value.substring(1, value.length - 1);
      values = value.split(/\s+OR\s+/);
    }
    if (values) {
      values.some(value => {
        if (errors === null && value !== '*') {
          errors = this.isAllowedFieldValue(field, value);
        }
        if (errors !== null) {
          return true;
        }
      });
      return errors;
    }

    if (this.displayNameConfigLookup.hasOwnProperty(field)) {
      errors = this.isFieldValueInConfig(this.displayNameConfigLookup[field], value);
    }

    // If there are no errors, there are no field/macro limitations in effect

    return errors;
  }

  // Renamed to avoid conflict with LuceneParser's `isFieldValueInLookup`
  private isFieldValueInConfig(config: IParserFieldConfiguration, value: string): ValidationErrors {
    if (!config.values) {
      return null;   // No value limitations in effect
    }

    let errors: ValidationErrors = null;
    let found = false;
    if (config.values.length > 0) {
      // One of the hardcoded values must match
      found = config.values.some(v => v === value || this.isParserFieldValueConfiguration(v) && (v as IParserFieldValueConfiguration).displayValue === value);
    }
    if (!found && !errors) {
      errors = { invalidParserFieldValue: { invalidParserFieldValue: value } };
    }
    return errors;
  }

  getFieldNameAndValuePair(token: string): string[] {   // Argument `token` is `ParserToken.token`
    // Always keep surrounding quotes in display values. They have no special Lucene grouping meaning, they are part of the actual value.
    return super.getFieldNameAndValuePair(token, true);
  }

  // TODO: refactor with LuceneParser
  createTokenString(field: string, value: ParserFieldSimpleValue, operator: ParserQueryOperator, addSpaceAfterSeparator?: boolean): string {
    // Gather list of values
    value = String(value);

    let values: string[];
    switch (operator) {
      // Note: EXISTS and DOES_NOT_EXIST are missing as they do not require a value
      case ParserQueryOperator.CONTAINS:
      case ParserQueryOperator.EQUALS:
      case ParserQueryOperator.MATCHES:
      case ParserQueryOperator.WILDCARD:
      case ParserQueryOperator.GREATER_THAN:
      case ParserQueryOperator.GREATER_THAN_EQUAL_TO:
      case ParserQueryOperator.LESS_THAN:
      case ParserQueryOperator.LESS_THAN_EQUAL_TO:
      case ParserQueryOperator.NOT_EQUALS:
        values = [value];
        break;

      case ParserQueryOperator.IS_BETWEEN:
      case ParserQueryOperator.IS_NOT_BETWEEN:
        values = value.split('-');
        break;

      case ParserQueryOperator.IS_NOT_ONE_OF:
      case ParserQueryOperator.IS_ONE_OF:
        values = value.split(',');
        break;
    }

    let result;
    switch (operator) {
      // Syntax: field:value
      case ParserQueryOperator.CONTAINS:   // Contains or equals is determined by indexation, so there's no specific syntax to force one or the other
      case ParserQueryOperator.EQUALS:     // Contains or equals is determined by indexation, so there's no specific syntax to force one or the other
      case ParserQueryOperator.MATCHES:    // E.g. field:/Th(3|e).*expression$/
      case ParserQueryOperator.WILDCARD:   // E.g. field:Some?Wild.ard*
        result = field + this.fieldNameAndValueSeparator + (addSpaceAfterSeparator ? ' ' : '') + values[0];
        break;

      // Syntax: NOT _exists_:field
      case ParserQueryOperator.DOES_NOT_EXIST:
        result = this.negatePrefix + '_exists_' + this.fieldNameAndValueSeparator + (addSpaceAfterSeparator ? ' ' : '') + field;
        break;

      // Syntax: _exists_:field
      case ParserQueryOperator.EXISTS:
        result = '_exists_' + this.fieldNameAndValueSeparator + (addSpaceAfterSeparator ? ' ' : '') + field;
        break;

      // Syntax: field:{value, *} (field:>value is not supported by the parser)
      case ParserQueryOperator.GREATER_THAN:
        result = field + this.fieldNameAndValueSeparator + (addSpaceAfterSeparator ? ' ' : '') + '{' + values[0] + ' TO *}';
        break;

      // Syntax: field:[value, *} (field:>=value is not supported by the parser)
      case ParserQueryOperator.GREATER_THAN_EQUAL_TO:
        result = field + this.fieldNameAndValueSeparator + (addSpaceAfterSeparator ? ' ' : '') + '[' + values[0] + ' TO *}';
        break;

      // Syntax: field:[val1 TO val2], value should be specified as val1-val2
      case ParserQueryOperator.IS_BETWEEN:
        result = field + this.fieldNameAndValueSeparator + (addSpaceAfterSeparator ? ' ' : '') + '[' + values.join(' TO ') + ']';
        break;

      // Syntax: NOT field:[val1 TO val2]
      case ParserQueryOperator.IS_NOT_BETWEEN:
        result = this.negatePrefix + field + this.fieldNameAndValueSeparator + (addSpaceAfterSeparator ? ' ' : '') + '[' + values.join(' TO ') + ']';
        break;

      // Syntax: NOT field:[val1 OR val2 OR ...]
      case ParserQueryOperator.IS_NOT_ONE_OF:
        result = this.negatePrefix + field + this.fieldNameAndValueSeparator + (addSpaceAfterSeparator ? ' ' : '') + '[' + values.join(' OR ') + ']';
        break;

      // Syntax: field:[val1 OR val2 OR ...]
      case ParserQueryOperator.IS_ONE_OF:
        result = field + this.fieldNameAndValueSeparator + (addSpaceAfterSeparator ? ' ' : '') + '[' + values.join(' OR ') + ']';
        break;

      // Syntax: field:{*, value} (field:<value is not supported by the parser)
      case ParserQueryOperator.LESS_THAN:
        result = field + this.fieldNameAndValueSeparator + (addSpaceAfterSeparator ? ' ' : '') + '{* TO ' + values[0] + '}';
        break;

      // Syntax: field:{*, value] (field:<=value is not supported by the parser)
      case ParserQueryOperator.LESS_THAN_EQUAL_TO:
        result = field + this.fieldNameAndValueSeparator + (addSpaceAfterSeparator ? ' ' : '') + '{* TO ' + values[0] + ']';
        break;

      // Syntax: NOT field:value
      case ParserQueryOperator.NOT_EQUALS:   // Contains or equals is determined by indexation, so there's no specific syntax to force one or the other
        result = this.negatePrefix + field + this.fieldNameAndValueSeparator + (addSpaceAfterSeparator ? ' ' : '') + values[0];
        break;
    }
    return result;
  }

  replaceNegation(token: string, newNegation: string, addSpaceAfterNewNegation?: boolean): string {
    throw 'Not expected to be called for display string parser';
  }

  // Note: this method processes a raw token, as entered by the user, so be aware of spacing
  public replaceDisplayStrings(token: string): string {
    let leadingSpaces = '';
    let trailingSpaces = '';
    token = token.replace(/^(\s*)(.*?)(\s*)$/, (match: string, lead: string, nonSpace: string, trail: string) => {
      leadingSpaces = lead;
      trailingSpaces = trail;
      return nonSpace;
    });

    const displayPair = this.getFieldNameAndValuePair(token);
    const config = this.displayNameConfigLookup[displayPair[0].trim()];

    if (config) {
      // Replace displayName
      token = this.replaceFieldName(token, config.name);

      // Replace displayValue, if defined
      let displayValue = displayPair[1];
      if (displayValue) {
        displayValue = displayValue.trim();
        (config.values || []).some(v => {
          if (this.isParserFieldValueConfiguration(v)) {
            const vc = (v as IParserFieldValueConfiguration);
            if (vc.displayValue === displayValue) {
              token = this.replaceFieldValue(token, String(vc.value));
              return true;
            }
          }
        });
      }
    }   // Else invalid field name was entered

    return leadingSpaces + token + trailingSpaces;
  }

  protected ignoreSurroundingQuotesInDisplayStrings() {
    return false;
  }

  protected processMatchingFieldItem(lookup: IParserFieldLookup, name: string, matcher: Function, matches: IParserAutoCompleteSuggestion[], prefix: string): void {
    lookup[name].forEach(config => {
      const displayName = config.displayName ? config.displayName as string : config.name;
      if (matcher(displayName, prefix)) {
        // Avoid dupe displayNames in the list by not doing this for aliases
        if (name === config.name) {
          matches.push({ actual: displayName || name, display: displayName || name } as IParserAutoCompleteSuggestion);
        }
      }
    });
  }

  protected getMatchingValuesMatcher(prefix: string): { matcher: Function, prefix: string } {
    // Quotes have no special meaning (see super's implementation), so always do "contains"
    const matcher = (value, sub) => value.toLowerCase().indexOf(sub.toLowerCase()) >= 0;
    return { matcher, prefix };
  }

  protected processMatchingValues(field: string, matcher: Function, matches: IParserAutoCompleteSuggestion[], prefix: string) {
    if (this.displayNameConfigLookup.hasOwnProperty(field)) {
      this.processMatchingValuesForItem([ this.displayNameConfigLookup[field] ], matcher, matches, prefix);
    }
  }

  protected processMatchingValueItemAsDisplayValue(): boolean {
    return true;
  }
}
