import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgModule,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import {Utils} from '../common/utils';
import {ObjectUtils} from '../utils/object-utils';
import {CommonModule} from '@angular/common';
import * as d3 from 'd3';
import {CHART} from '../common/chart';
import {WorldMapJson} from '../common/world-map';
import {ChartUtils} from '../utils/chart.utils'

@Component({
    selector: 'sym-chart-world-map',
    template: `
        <div class = "sym-chart-world-map__container" [ngStyle]="style" [ngClass]="[customStyleClass]">
            <div [id]="mapChartId" class="sym-chart-world-map" [hidden]="noData || chartLoading">
            </div>
            <div class="center-content-container" [hidden]="!chartLoading && !noData">
                <div *ngIf="!noData" class="progress-spinner-container">
                    <svg class="sym-smbl__progress-spinner sym-smbl--medium">
                        <use href="#sym-smbl__progress-spinner"></use>
                    </svg>
                </div>
                <div *ngIf="noData" class="sym-chart__no-data">
                    <span class="error-icon"><svg class="sym-smbl__dashboard-error"><use
                            href="#sym-smbl__dashboard-no-data"></use></svg></span>
                    <span>{{ noDataText }}</span>
                </div>
            </div>
        </div>`,
    encapsulation: ViewEncapsulation.None,
    providers: [ObjectUtils, Utils]
})
export class SymChartWorldMap implements OnInit, OnChanges, OnDestroy {


    @Input() data: any[];
    @Input() options: any;
    @Input() style: any;
    @Input() styleClass: string;
    @Output() coordinatesClick: EventEmitter<any> = new EventEmitter<any>();

    noData = false;
    chartLoading = true;
    noDataText;
    customStyleClass;
    toolTipLabel;
    elmChartContainer;
    mapChartId;
    hostElement;
    containerElement;
    width;
    height;
    svg;
    geojson;
    projection;
    geoGenerator = d3.geoPath()
        .projection(this.projection);

    constructor(private elRef: ElementRef) {
        this.hostElement = this.elRef.nativeElement;
    }

    ngOnInit(): void {
        this.mapChartId = `world-map-chart-${Utils.generateUUID()}`;
        this.geojson = WorldMapJson;
        this.customStyleClass = this.styleClass || '';
        this.removeExistingChartFromParent();
        this.setChartDimensions();
        this.update();
    }

    ngOnChanges() {
        this.noData = false;
        this.chartLoading = true;
        this.noDataText = (this.options && this.options.l10n && this.options.l10n.noData) ? this.options.l10n.noData : CHART.NO_DATA;
        this.toolTipLabel = (this.options && this.options.l10n && this.options.l10n.toolTipLabel) ? this.options.l10n.toolTipLabel : CHART.COUNT;
        if (!this.data) {
            return;
        }

        if (this.data.length > 0) {
            this.noData = false;
        } else {
            this.noData = true;
        }

        if (this.svg) {
            this.renderData();
        }
    }

    private update() {
        // Update projection
        this.projection = d3.geoEquirectangular();
        this.projection.fitExtent([[0, 0], [this.width, this.height]], this.geojson);
        this.geoGenerator.projection(this.projection);

        // Update world map
        const path = d3.select('g.map')
            .selectAll('path')
            .data(this.geojson.features);

        path.enter()
            .append('path')
            .merge(this.svg.selectAll('path'))
            .attr('d', this.geoGenerator);

        if (this.data) {
            this.renderData();
        }
    }

    private renderData() {
        this.chartLoading = false;
        const parent = this.containerElement;
        const that = this;
        this.svg.selectAll('.coordinates__container').remove();
        const circleGroup = this.svg
            .insert('g')
            .attr('class', 'coordinates__container');

        circleGroup.selectAll('circle')
            .data(this.data)
            .enter()
            .append('circle')
            .attr('class', 'coordinates')
            .attr('stroke', '#b22a2a')
            .attr('fill', '#cf8788')
            .style('cursor', 'pointer')
            .attr('cx', (d: any) => {
                const coordinates = d.value.split(',');
                return this.projection([coordinates[1], coordinates[0]])[0];
            })
            .attr('cy', (d: any) => {
                const coordinates = d.value.split(',');
                return this.projection([coordinates[1], coordinates[0]])[1];
            })
            .attr('r', 2.5)
            .on('mouseover', function(d, i) {
                d3.select('.world-map-tooltip').remove();
                d3.select(parent).append('span')
                    .attr('class', 'world-map-tooltip')
                    .style('left', d3.select(this).attr('cx') + 'px')
                    .style('top', d3.select(this).attr('cy') + 'px')
                    .style('transform', 'translateX(-50%) translateY(16px)')
                    .html(() => {
                        return that.renderToolTip(d);
                    })
                    .on('click', () => {
                        that.chartClick(d);
                    });
            })
            .on('mouseout', (d, i) => {
                d3.select('.world-map-tooltip').transition().delay(1000).remove();
            })
            .on('click', (d) => {
                this.chartClick(d);
            });
    }

    private setChartDimensions() {
        const parent = this.hostElement.querySelectorAll('.sym-chart-world-map')[0];
        this.width = Math.max(parent.clientWidth, 720);
        this.height = Math.max(parent.clientHeight, 340);
        this.containerElement = parent;
        this.svg = d3.select(parent).append('svg')
            .attr('width', '100%')
            .attr('height', '100%')
            .attr('viewBox', `0 0 ${this.width} ${this.height}`);

        this.svg.append('g')
            .attr('class', 'map');
    }

    private removeExistingChartFromParent() {
        d3.select(this.hostElement).select('svg').remove();
    }

    private chartClick(data) {
        this.coordinatesClick.emit(data);
    }

    private renderToolTip(data) {
        if (this.options && this.options.htmlChartToolTip) {
            return ChartUtils.interpolateD3tooltipHTML(this.options.htmlChartToolTip, data);
        }
        return `<span class='count'>${this.toolTipLabel} : ${data.count}</span>`;
    }

    ngOnDestroy(): void {
        this.elmChartContainer = null;
    }
}


@NgModule({
    imports: [CommonModule],
    exports: [SymChartWorldMap],
    declarations: [SymChartWorldMap]
})
export class SymChartWorldMapModule {

}