import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ThemeService {

  constructor ( private http : HttpClient ) {
  }

  getTheme () {
    return this.http.get<any>( 'assets/showcase/data/theme.json' )
    .toPromise()
    .then( res => res.data )
    .then( data => {
      return data;
    } );
  }
}
