import { NgModule, Component, Input, OnInit } from '@angular/core';
import { CommonModule }                       from '@angular/common';
import { SharedModule }                       from '../common/shared';
import { MenuItem }                           from '../common/menu-item';
import { UIModel }                            from '../common/ui-model';
import { MenuModule }                         from '../menu/menu';

@Component( {
  selector : 'sym-navaccount',
  template : `
    <p-menu #menu
           [popup]="true"
           [model]="items"
           (menuToggle)='onMenuToggle($event)'
           styleClass="sym-accountnav__menu"
           appendTo="body"
           [offset]="offset"></p-menu>
    <div class="sym-nav__account-container" (mouseover)="onHover()" (mouseleave)="onLeave()" (click)="menu.toggle($event)">
      <svg class="sym-nav--icon-user sym-smbl__header-user-dark"><use href="#sym-smbl__header-user-dark"></use></svg> 
      <span class="sym-nav--text">{{uiModel.displayName}}</span>
      <svg class="sym-nav--icon-chevron sym-smbl--arrow-nav sym-smbl--black-80"><use href="#sym-smbl__arrow-chevron"></use></svg>
    </div>
  `
} )

export class NavAccount implements OnInit {
  @Input() uiModel : UIModel;

  @Input() items : MenuItem[];

  @Input() offset : { top? : number, left? : number } = { top : 0, left : 0 };

  isMenuVisible : boolean = false;
  isHover : boolean       = false;


  constructor () {
  }

  onMenuToggle ( isVisible : boolean ) {
    this.isMenuVisible = isVisible ? true : false;
  }

  onHover () {
    this.isHover = true;
  }

  onLeave () {
    this.isHover = false;
  }

  ngOnInit () {
  }
}

@NgModule( {
  imports      : [ CommonModule, MenuModule ],
  exports      : [ NavAccount, SharedModule ],
  declarations : [ NavAccount ]
} )

export class NavAccountModule {
}
