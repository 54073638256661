import { NgModule, Component, Input, OnInit } from '@angular/core';
import { CommonModule }                       from '@angular/common';
import { SharedModule }                       from '../common/shared';
import { TopMenuItem }                        from '../common/top-menu-item';

@Component( {
  selector : 'sym-topnav',
  styleUrls: ['./top-nav.scss'],
  template : `
    <ul class="sym-topnav__container">
      <li class="sym-topnav__link"
          *ngFor="let item of items;"
          [ngClass]="{ 'is--selected': item.isSelected }"
          (click)="itemClick( $event, item )">
        <span *ngIf="item.iconClass && !item.iconSVG" class="sym-topnav__icon {{ item.iconClass }}"></span>
        <svg *ngIf="item.iconSVG" class="sym-smbl__logo sym-smbl--switcher"><use [attr.xlink:href]="item.iconSVG"></use></svg>
        <span class="sym-topnav__text">{{ item.displayName }}</span>
      </li>
    </ul>`
} )

export class TopNav implements OnInit {
  @Input() items : TopMenuItem[];

  constructor () {
  }

  itemClick ( event, item ) {
    if ( item.command ) {
      item.command( {
        originalEvent : event,
        item          : item
      } );
    }
  }

  ngOnInit () {
  }
}

@NgModule( {
  imports      : [ CommonModule ],
  exports      : [ TopNav, SharedModule ],
  declarations : [ TopNav ]
} )

export class TopNavModule {
}
