export enum ChartColors {
    COLOR_CHART_01_100 = '#F6511D',
    COLOR_CHART_02_100 = '#0294FF',
    COLOR_CHART_03_100 = '#7DB500',
    COLOR_CHART_04_100 = '#BD10E0',
    COLOR_CHART_05_100 = '#F5A623',
    COLOR_CHART_06_100 = '#FF0B81',
    COLOR_CHART_07_100 = '#EDDD23',
    COLOR_CHART_08_100 = '#B83514',
    COLOR_CHART_09_100 = '#20C7DC',
    COLOR_CHART_10_100 = '#741DF6',
    COLOR_CHART_11_100 = '#82E956',
    COLOR_CHART_12_100 = '#87A0EE'
}

export const CHART = {
    NO_DATA: 'No Data Available',
    COUNT: 'Count'
};
