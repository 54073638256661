import { PipeTransform, Pipe, NgModule } from "@angular/core";
import { IQuickFilter } from "./i-search-filter";

@Pipe({ name: 'quickFiltersPipe' })
export class QuickFiltersPipe implements PipeTransform {

  transform(quickFilters: IQuickFilter[], args?: any) {
    return quickFilters.filter( qf => {
        let searchFound = false;
        if (!qf.originalItems && qf.items && qf.items.length > 0) {
          qf.originalItems = qf.items.slice();
        }
        qf.items = qf.originalItems.filter ((item) => {
          let name = item.displayName.toString();
          let val = item.displayValue ? item.displayValue.toString() : "";
          let searchResult = name.toLowerCase().indexOf(args.toLowerCase()) > -1 || val.toLowerCase().indexOf(args.toLowerCase()) > -1;
          if (searchResult) {
            searchFound = true;
          }
          return searchResult;
        });
        if (args && searchFound) {
          qf.isVisible = true;
        }
        else {
          qf.isVisible = false;
        }
        return qf;
    });

  }
}

@NgModule( {
  exports      : [QuickFiltersPipe],
  declarations : [QuickFiltersPipe]
} )
export class QuickFiltersPipeModule {
}
