import { NgModule, Component, ElementRef, AfterViewInit, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { CommonModule }                                                                                       from '@angular/common';
import * as d3                                                                                                from 'd3';
import { ObjectUtils }                                                                                        from '../utils/object-utils';
import { Utils }                                                                                              from '../common/utils';
import { CHART }                                                                                              from '../common/chart';
import { ChartUtils }                                                                                         from '../utils/chart.utils';

@Component( {
  selector  : 'sym-chart-bar',
  template  : `
    <div [ngStyle]="style" [ngClass]="[ 'sym-chart__container', customStyleClass]">
      <h3 *ngIf="header">{{header}}</h3>
      <div [hidden]="isChartHidden">
        <figure [id]="barChartId" class="sym-chart__data"></figure>
        <div *ngIf="showLegend" class="sym-chart__legend-container is--circle">
          <span class="sym-chart__legend-item header__item" *ngFor="let legend of legendData | keyvalue">
            <span class="sym-chart__legend-color" [ngStyle]="{ 'border-color': legend.value.color }"></span>
            <span class="sym-type__item-description">{{legend.value.label}}</span>
          </span>
        </div>
      </div>
      <div [hidden]="!isChartHidden" class="center-content-container">
        <div [hidden]="isLoadingHidden" class="progress-spinner-container"><svg class="sym-smbl__progress-spinner sym-smbl--medium"><use href="#sym-smbl__progress-spinner"></use></svg></div>
        <div [hidden]="!isLoadingHidden" class="sym-chart__no-data">
          <span class="error-icon"><svg class="sym-smbl__dashboard-error"><use href="#sym-smbl__dashboard-no-data"></use></svg></span>
          <span>{{ noData }}</span>
        </div>
      </div>
    </div>
  `,
  providers : [ ObjectUtils, Utils ]
} )
export class SymChartBar implements AfterViewInit, OnInit {
  _data : any;
  barChartId : string;
  chartHeight : number      = 260;
  customStyleClass : string = '';
  header : string           = '';
  isChartHidden : boolean   = true;
  legendData                = null;
  noData : string           = CHART.NO_DATA;
  showLegend : boolean      = false;

  @Input() type : string;

  @Input() options : any;

  @Input() style : any;

  @Input() styleClass : string;

  @Input() get data () : any {
    return this._data;
  }

  @Output() onSelect : EventEmitter<any> = new EventEmitter();

  isLoadingHidden : boolean = true;

  set data ( val : any ) {
    this._data = val;

    const isDataArray = Array.isArray( this._data );

    this.isChartHidden   = !isDataArray;
    this.isLoadingHidden = isDataArray;

    if ( this.isLoadingHidden && this._data.length === 0 ) {
      this.isChartHidden = true;
    }

    if ( this.options ) {
      this.header = this.options[ 'header' ] || '';
      this.noData = this.options[ 'noData' ] || this.noData;
    }

    this.customStyleClass = this.styleClass || '';
  }

  constructor (
    public el : ElementRef,
    private objectUtils : ObjectUtils,
    private utils : Utils
  ) {
  }

  private svg;
  private DEFAULT_CHART_COLOR : string = 'COLOR_CHART_02_100';

  private onClick = ( data, index, elements ) : void => {
    this.onSelect.emit( { event : d3.event, data : data, index : index, elements : elements } );
  };

  private buildSvg ( data : any[] ) : void {
    const elmChartContainer = document.querySelectorAll( '.sym-chart__container' );
    const margin            = {
      top    : 20,
      right  : 20,
      bottom : 20,
      left   : 20
    };
    const width : number    = ( elmChartContainer && elmChartContainer[ 0 ] && elmChartContainer[ 0 ].clientWidth || 500 ) - margin.left - margin.right;
    const height : number   = ( data.length * 50 ) + 10;

    if ( data.length !== 0 ) {
      this.chartHeight = height;
    }

    // Reset
    if ( document.getElementById( this.barChartId ) ) {
      document.getElementById( this.barChartId ).innerHTML = '';
    }

    // insert the svg object to the element id
    this.svg = d3.select( 'figure#' + this.barChartId )
    .append( 'svg' )
    .attr( 'class', 'sym-canvas' )
    .attr( 'width', width )
    .attr( 'height', height )
    .append( 'g' )
    .attr( 'class', 'sym-chart-container' )
    .attr( 'transform', 'translate(' + margin.left + ',' + margin.top + ')' )
    .append( 'g' )
    .attr( 'class', 'sym-chart-bar' )
    .attr( 'transform', 'translate(0, 0)' );

    const maxRange = d3.max( data, function ( d ) {
      return parseInt( d.value, 10 );
    } );

    // X Axis
    const x = d3.scaleLinear()
    .domain( [ 0, maxRange ] )
    .range( [ 0, width - 100 ] );

    // Y Axis
    const y = d3.scaleBand()
    .range( [ 0, height ] )
    .domain( data.map( d => d.label ) );

    // Bars
    this.svg.selectAll( 'myRect' )
    .data( data )
    .enter()
    .append( 'rect' )
    .attr( 'x', x( 0 ) )
    .attr( 'y', function ( d, i ) {
      return i * 48;
    } )
    .attr( 'width', function ( d ) {
      let result = 0;

      if ( d.value > 0 ){
        result = x( d.value );
      }

      return result;
    } )
    .attr( 'height', 10 )
    .attr( 'rx', 5 )
    .attr( 'ry', 5 )
    .attr( 'fill', (d) => {
      return ChartUtils.convertColor( d.color );
    } )
    .style( 'cursor', 'pointer' )
    .on( 'click', this.onClick );

    // X Axis Values
    this.svg.selectAll( 'myRect' )
    .data( data )
    .enter()
    .append( 'text' )
    .attr( 'class', 'sym-bar__actual__value' )
    .attr( 'dx', function ( d ) {
      if ( d < 10 ) {
        return 6;
      } else {
        return 0;
      }
    } )
    .attr( 'x', function ( d ) {
      let result = 0;

      if ( d.value > 0 ){
        result = x( d.value ) + 10;
      }

      return result;
    } )
    .attr( 'y', function ( d, i ) {
      return ( i * 48 ) + 10;
    } )
    .attr( 'fill', '#333333' )
    .attr( 'opacity', 0 )
    .style( 'font-size', '14px' )
    .style( 'cursor', function ( d ) {
      return d.value > 0 ? 'pointer' : 'default';
    })
    .style( 'opacity', '1' )
    .on( 'click', this.onClick )
    .text( ( d, i ) => {
      return d3.format( ',' )( parseInt( d.value, 10 ) );
    } );

    // Y Axis Labels
    this.svg.selectAll( 'myRect' )
    .data( data )
    .enter()
    .append( 'text' )
    .attr( 'class', 'sym-type__item-description' )
    .attr( 'x', function ( d ) {
      return 0;
    } )
    .attr( 'y', function ( d, i ) {
      return ( i * 48 ) + 22;
    } )
    .attr( 'text-anchor', 'start' )
    .attr( 'fill', '#999999' )
    .style( 'font-size', '9px' )
    .style( 'cursor', function ( d ) {
      return d.value > 0 ? 'pointer' : 'default';
    })
    .style( 'text-transform', 'uppercase' )
    .on( 'click', this.onClick )
    .text( function ( d, i ) {
      return d.label;
    } )
    .attr( 'width', width );
  }

  private buildLegend ( data ) : void {
    let legend = {};

    for ( let p in data ) {
      if ( data[ p ].isType ) {
        if ( !legend[ data[ p ].isType ] ) {
          legend[ data[ p ].isType ] = {
            color : ChartUtils.convertColor( data[ p ].color ),
            label : data[ p ].isType
          };
        }
      }
    }

    if ( this.options && this.options[ 'hideLegend' ] ) {
      this.showLegend = false;
    } else {
      this.showLegend = this.objectUtils.getLength( legend ) !== 0;
    }

    this.legendData = legend;
  }

  private buildChart ( data : any ) : void {
    if ( data ) {
      this.buildSvg( data );
      this.buildLegend( data );
    }
  }

  ngOnChanges ( changes : SimpleChanges ) {
    this.buildChart( changes.data.currentValue );
  }

  ngAfterViewInit () : void {
    this.buildChart( this.data );
  }

  ngOnInit () : void {
    this.barChartId = `bar-${Utils.generateUUID()}`;
  }
}

@NgModule( {
  imports      : [ CommonModule ],
  exports      : [ SymChartBar ],
  declarations : [ SymChartBar ]
} )
export class SymChartBarModule {
}
