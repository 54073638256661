import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SymJourneyLineService {

    private nextStep = new Subject();
    private previousStep = new Subject();
    private journeyLineStep = new Subject();

    public  goNext = this.nextStep.asObservable();
    public  goBack = this.previousStep.asObservable();
    public  goTo = this.journeyLineStep.asObservable();

    goToNextStep() {
        this.nextStep.next(true);
    }

    goToPreviousStep() {
        this.previousStep.next(true);
    }

    goToStep(stepIndex: Number) {
        this.journeyLineStep.next(stepIndex);
    }
}
