import { Directive, Input, TemplateRef, ViewContainerRef, OnInit, Inject, NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule }                                                                                   from '@angular/common';

export enum ENVIRONMENT_LEVEL {
  NONE  = 0,
  DEV   = 1,
  STAGE = 10,
  PROD  = 100
}

export interface FeaturesModel {
  [ name : string ] : {
    enviromentLevel : number,
    description : string,
    dateCreated : string,
    jira : string | string[]
  }
}

export interface FeaturesModuleConfig {
  features : FeaturesModel;
  enviroment : string;
}

@Directive( {
  selector : '[featureToggle]'
} )
export class FeatureToggleDirective implements OnInit {
  @Input() featureToggle : string = '';

  constructor (
    private templateRef : TemplateRef<any>,
    private viewContainer : ViewContainerRef,
    @Inject( 'UI_FEATURES' ) private FEATURES : Object = {},
    @Inject( 'UI_ENV' ) private ENV : string           = ''
  ) {
  }

  ngOnInit () {
    if ( this.isEnabled() ) {
      this.viewContainer.createEmbeddedView( this.templateRef );
    } else {
      this.viewContainer.clear();
    }
  }

  isEnabled () {
    const features = this.FEATURES;
    const env      = this.ENV;
    const envLevl  = ENVIRONMENT_LEVEL;

    let thisFeature;
    let result           = false;
    let environmentLevel = ENVIRONMENT_LEVEL[ env ];

    thisFeature = features[ this.featureToggle ];

    if ( thisFeature ) {
      result = !!( thisFeature.enviromentLevel && thisFeature.enviromentLevel >= environmentLevel ); // !! use here to covert value to boolean. Also allowing 0 or undefined to be false
    }

    return result;
  }
}

@NgModule( {
  imports      : [ CommonModule ],
  exports      : [ FeatureToggleDirective ],
  declarations : [ FeatureToggleDirective ]
} )
export class FeatureToggleModule {
  static forRoot ( config : FeaturesModuleConfig = { features : {}, enviroment : '' } ) : ModuleWithProviders<FeatureToggleModule> {

    return {
      ngModule  : FeatureToggleModule,
      providers : [
        { provide : 'UI_FEATURES', useValue : config.features },
        { provide : 'UI_ENV', useValue : config.enviroment }
      ]
    };
  }
}
