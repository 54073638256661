import {
    ChangeDetectorRef,
    Component, ComponentFactoryResolver, ComponentRef,
    EventEmitter,
    Input,
    NgModule,
    OnChanges, OnDestroy,
    OnInit,
    Output,
    SimpleChanges, ViewChild, ViewContainerRef
} from '@angular/core';
import {SymJourneyLineModel} from './journey-line-model';
import { CommonModule } from '@angular/common';
import {SharedModule, SymIcon} from '../common/shared';
import {ButtonModule} from '../button/button';
import {SymAddStep} from './sym-add-step.directive';
import {SymJourneyLineService} from '../../services/journey-line.service';

// @ts-ignore
@Component({
    // tslint:disable-next-line:component-selector
    selector: 'sym-journey-line',
    styleUrls: ['./journey-line.scss'],
    templateUrl: './journey-line.html',
    providers: []
})
export class SymJourneyLineComponent implements OnInit, OnChanges, OnDestroy {

    @Input() model: SymJourneyLineModel;
    @Input() activeIndex: Number;

    componentRef: ComponentRef<any>;

    @ViewChild(SymAddStep, {static: true}) adStep: SymAddStep;

    // tslint:disable-next-line:no-output-on-prefix
    @Output() onNext: EventEmitter<any> = new EventEmitter();
    // tslint:disable-next-line:no-output-on-prefix
    @Output() onBack: EventEmitter<any> = new EventEmitter();

    public currentIndex: number;

    private subscriptOnNext;
    private subscriptOnBack;
    private subscriptOnStep;

    stepTemplate: any;

    constructor ( private viewContainerRef: ViewContainerRef,
                  private componentFactoryResolver: ComponentFactoryResolver ,
                  private cd: ChangeDetectorRef,
                  public symJourneyLineService: SymJourneyLineService) {

        this.subscriptOnNext = this.symJourneyLineService.goNext.subscribe(() => {
            this.model.currentStepIndex++;
        });

        this.subscriptOnBack = this.symJourneyLineService.goBack.subscribe(() => {
            this.model.currentStepIndex--;
        });

        this.subscriptOnStep = this.symJourneyLineService.goTo.subscribe((stepNumber: number) => {
            this.model.currentStepIndex = stepNumber;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setView();
    }

    ngOnInit(): void {

        this.model.currentStepIndex = this.model.currentStepIndex ? this.model.currentStepIndex : 0;
        this.setView();

    }

    loadStep() {

        // tslint:disable-next-line:max-line-length
        if (this.model.steps[this.model.currentStepIndex].component !== null || this.model.steps[this.model.currentStepIndex].component !== undefined ) {
            const adItem = this.model.steps[this.model.currentStepIndex].component;
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(adItem);

            const viewContainerRef = this.adStep.viewContainerRef;
            viewContainerRef.clear();

            this.componentRef = viewContainerRef.createComponent(<any>(componentFactory));
        }
    }

    goBack(ev) {
        this.model.currentStepIndex = this.model.currentStepIndex > 0 ? this.model.currentStepIndex - 1 : 0;
        this.onBack.emit({
            ev: ev,
            step: this.model.steps[this.model.currentStepIndex],
            idx: this.model.currentStepIndex
        });
        this.setView();
    }

    goNext(ev) {

        // tslint:disable-next-line:max-line-length
        this.model.currentStepIndex = (this.model.currentStepIndex < (this.model.steps.length - 1)) ? this.model.currentStepIndex + 1 : this.model.currentStepIndex;
        this.onNext.emit({
            ev: ev,
            step: this.model.steps[this.model.currentStepIndex],
            idx: this.model.currentStepIndex
        });
        this.setView();
    }

    setView() {

        if (this.model.steps[this.model.currentStepIndex].hasOwnProperty('component') ) {
            this.loadStep();
        } else if (this.model.steps[this.model.currentStepIndex].hasOwnProperty('template') ) {
            this.stepTemplate = this.model.steps[this.model.currentStepIndex].template;
        }

    }

    goToStep(ev, idx: number) {
        this.model.currentStepIndex = idx;
        this.onNext.emit({
            ev: ev,
            step: this.model.steps[this.model.currentStepIndex],
            idx: this.model.currentStepIndex
        });
        this.setView();
    }

    ngOnDestroy(): void {

        this.subscriptOnNext.unsubscribe();
        this.subscriptOnBack.unsubscribe();
        this.subscriptOnStep.unsubscribe();
    }


}

// @ts-ignore
@NgModule({
    imports: [CommonModule, ButtonModule],
    exports: [SymJourneyLineComponent, SharedModule],
    declarations: [SymJourneyLineComponent, SymAddStep],
    providers: [SymJourneyLineService]
})
export class SymJourneyLineModule { }
