import {
  NgModule,
  Component,
  OnInit,
  AfterContentInit,
  Input,
  Output,
  EventEmitter,
  ElementRef
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { DomHandler }   from '../dom/dom-handler';
import { SharedModule } from '../common/shared';

@Component( {
  selector  : 'sym-input-text-helper',
  template  : `
    <div class="sym-input-text-helper {{styleClass}}"
      [ngClass]="{'is--invalid' : !isValid && !isPristine }">
      <div class="sym-input-text-helper__input">
        <ng-content></ng-content>
        <div [hidden]="value === '' || value === null"
             class="sym-input-text-helper__delete icon__actions-menu-delete"
             (click)="clear()"></div>
      </div>
      <div class="input__warning" [hidden]="isPristine || isValid">{{errorMsg}}</div>
    </div>
  `,
  providers : [DomHandler]
} )
export class SymInputTextHelper implements OnInit, AfterContentInit {
  @Input() styleClass : string;
  @Input() isValid : boolean = true;
  @Input() autofocus : boolean = false;
  @Input() errorMsg : string;
  @Input() submitButtonClass : string;

  @Output() onClear : EventEmitter<any> = new EventEmitter();

  _value : string;
  isPristine = true; //this is so that error won't show on load

  @Input()
  get value () : string {
    return this._value;
  }

  set value ( val : string) {
    this._value = val;
    if (this.isPristine && typeof val !== 'undefined' && val !== '') {
      this.isPristine = false;
    }
  }

  inputElem : HTMLElement;

  ngOnInit () {
    if (typeof this.value === 'undefined') {
      this.value = '';
    }

    this.inputElem = this.el.nativeElement.querySelector( 'input' );
  }

  ngAfterContentInit () {
    // input attribute autofocus isn't working on Chrome so adding this
    if (this.autofocus) {
      setTimeout( () => {
          this.inputElem.focus();
      }, 100 );
    }
  }
  constructor ( private el : ElementRef
  ) {
  }

  clear () {
    this.value   = '';

    this.onClear.emit();
    // this will make the execution after the above boolean has changed
    if ( this.inputElem ) {
      setTimeout( () => {
          this.inputElem.focus();
      }, 100 );
    }
  }
}

@NgModule( {
  imports      : [CommonModule, SharedModule],
  exports      : [SymInputTextHelper, SharedModule],
  declarations : [SymInputTextHelper]
} )
export class SymInputTextHelperModule {
}
