import {
  NgModule,
  Component,
  ElementRef,
  Input,
  Renderer2,
  OnDestroy,
  ChangeDetectorRef,
  ContentChild,
  OnInit,
  Output,
  EventEmitter,
  TemplateRef
}                                          from '@angular/core';
import { CommonModule }                    from '@angular/common';
import { FormsModule }                     from '@angular/forms';
import { RouterModule }                    from '@angular/router';
import { DomHandler }                      from '../dom/dom-handler';
import { MenuItem }                        from '../common/menu-item';
import { SymColumnSelectorDropdownModule } from '../sym-column-selector-dropdown/sym-column-selector-dropdown';
import { ActionBarModel }                  from './action-bar-model';
import { TooltipModule }                   from '../tooltip/tooltip';
import { SymTranslateModule }              from '../common/translate.pipe';
import { DialogModule }                    from '../dialog/dialog';
import { ButtonModule }                    from '../button/button';
import { SymColumnItem }                   from '../common/column.interface';

@Component( {
  selector  : 'sym-action-bar-sub',
  template  : `
    <ng-content></ng-content>
    <ul *ngIf="!content" [ngClass]="{'ui-action-bar-root-list':root, 'ui-widget-content ui-corner-all ui-submenu-list ui-shadow':!root}"
        (click)="listClick($event)">
      <ng-template ngFor let-child [ngForOf]="(root ? item : item.items)">
        <li *ngIf="child.separator" class="ui-menu-separator ui-widget-content" [ngClass]="{'ui-helper-hidden': child.visible === false}">
        <li *ngIf="!child.separator" #listItem [ngClass]="{'ui-menuitem ui-corner-all':true,
                        'ui-menu-parent':child.items,'ui-menuitem-active':listItem==activeItem,'ui-helper-hidden': child.visible === false}"
            (mouseenter)="onItemMouseEnter($event,listItem,child)" (mouseleave)="onItemMouseLeave($event)" (click)="onItemMenuClick($event, listItem, child)">
          <a *ngIf="!child.routerLink" [href]="child.url||'#'" [attr.data-automationid]="child.automationId" [attr.target]="child.target" [attr.title]="child.title" [attr.id]="child.id" (click)="itemClick($event, child)"
             [ngClass]="{'ui-menuitem-link ui-corner-all':true,'ui-state-disabled':child.disabled}" [ngStyle]="child.style" [class]="child.styleClass">
            <span class="ui-menuitem-icon" *ngIf="child.icon" [ngClass]="child.icon"></span>
            <span class="ui-menuitem-text">{{child.label}}</span>
            <span class="ui-submenu-icon pi pi-fw" *ngIf="child.items" [ngClass]="{'pi-caret-down':root,'pi-caret-right':!root}"></span>
          </a>
          <a *ngIf="child.routerLink" [routerLink]="child.routerLink" [attr.data-automationid]="child.automationId" [queryParams]="child.queryParams" [routerLinkActive]="'ui-state-active'" [routerLinkActiveOptions]="child.routerLinkActiveOptions||{exact:false}"
             [attr.target]="child.target" [attr.title]="child.title" [attr.id]="child.id"
             (click)="itemClick($event, child)" [ngClass]="{'ui-menuitem-link ui-corner-all':true,'ui-state-disabled':child.disabled}" [ngStyle]="child.style" [class]="child.styleClass">
            <span class="ui-menuitem-icon" *ngIf="child.icon" [ngClass]="child.icon"></span>
            <span class="ui-menuitem-text">{{child.label}}</span>
            <span class="ui-submenu-icon pi pi-fw" *ngIf="child.items" [ngClass]="{'pi-caret-down':root,'pi-caret-right':!root}"></span>
          </a>
          <sym-action-bar-sub class="ui-submenu" [item]="child" *ngIf="child.items" [autoDisplay]="true"></sym-action-bar-sub>
        </li>
      </ng-template>
    </ul>
  `,
  providers : [DomHandler]
} )
export class ActionBarSub implements OnDestroy {

  @Input() item : MenuItem;

  @Input() root : boolean;

  @Input() autoDisplay : boolean;

  @Input() autoZIndex : boolean = true;

  @Input() baseZIndex : number = 0;

  @ContentChild( 'symActionBarSubContent' ) content : ElementRef;

  documentClickListener : any;

  menuClick : boolean;

  menuHoverActive : boolean = false;

  activeItem : any;

  hideTimeout : any;

  activeMenu : any;


  constructor (
    public domHandler : DomHandler,
    public renderer : Renderer2,
    private cd : ChangeDetectorRef
  ) {
  }

  onItemMenuClick ( event : Event, item : HTMLLIElement, menuitem : MenuItem ) {
    if ( !this.autoDisplay ) {

      if ( menuitem.disabled ) {
        return;
      }

      this.activeItem = this.activeMenu ? ( this.activeMenu.isEqualNode( item ) ? null : item ) : item;
      let nextElement = <HTMLLIElement>item.children[ 0 ].nextElementSibling;
      if ( nextElement ) {
        let sublist = <HTMLUListElement>nextElement.children[ 0 ];
        if ( this.autoZIndex ) {
          sublist.style.zIndex = String( this.baseZIndex + ( ++DomHandler.zindex ) );
        }

        if ( this.root ) {
          sublist.style.top  = this.domHandler.getOuterHeight( item.children[ 0 ] ) + 'px';
          sublist.style.left = '0';
        } else {
          sublist.style.top  = '0';
          sublist.style.left = this.domHandler.getOuterWidth( item.children[ 0 ] ) + 'px';
        }
      }

      this.menuClick       = true;
      this.menuHoverActive = this.activeMenu ? ( !this.activeMenu.isEqualNode( item ) ) : true;
      this.activeMenu      = this.activeMenu ? ( this.activeMenu.isEqualNode( item ) ? null : item ) : item;
      this.bindEventListener();
    }
  }

  bindEventListener () {
    if ( !this.documentClickListener ) {
      this.documentClickListener = this.renderer.listen( 'document', 'click', ( event ) => {
        if ( !this.menuClick ) {
          this.activeItem      = null;
          this.menuHoverActive = false;
        }
        this.menuClick = false;
      } );
    }
  }

  onItemMouseEnter ( event : Event, item : HTMLLIElement, menuitem : MenuItem ) {
    if ( this.autoDisplay || ( !this.autoDisplay && this.root && this.menuHoverActive ) ) {
      if ( menuitem.disabled ) {
        return;
      }

      if ( this.hideTimeout ) {
        clearTimeout( this.hideTimeout );
        this.hideTimeout = null;
      }

      this.activeItem = this.activeItem ? ( this.activeItem.isEqualNode( item ) ? null : item ) : item;
      let nextElement = <HTMLLIElement>item.children[ 0 ].nextElementSibling;
      if ( nextElement ) {
        let sublist          = <HTMLUListElement>nextElement.children[ 0 ];
        sublist.style.zIndex = String( ++DomHandler.zindex );

        if ( this.root ) {
          sublist.style.top  = this.domHandler.getOuterHeight( item.children[ 0 ] ) + 'px';
          sublist.style.left = '0';
        } else {
          sublist.style.top  = '0';
          sublist.style.left = this.domHandler.getOuterWidth( item.children[ 0 ] ) + 'px';
        }
      }

      this.activeMenu = this.activeMenu ? ( this.activeMenu.isEqualNode( item ) ? null : item ) : item;
    }
  }

  onItemMouseLeave ( event : Event ) {
    if ( this.autoDisplay ) {
      this.hideTimeout = setTimeout( () => {
        this.activeItem = null;
        this.cd.markForCheck();
      }, 250 );
    }
  }

  itemClick ( event, item : MenuItem ) {
    if ( item.disabled ) {
      event.preventDefault();
      return;
    }

    if ( !item.url ) {
      event.preventDefault();
    }

    if ( item.command ) {
      item.command( {
        originalEvent : event,
        item          : item
      } );
    }

    this.activeItem = null;
  }

  listClick ( event ) {
    if ( this.autoDisplay ) {
      this.activeItem = null;
    }
  }

  ngOnDestroy () {
    if ( this.documentClickListener ) {
      this.documentClickListener();
      this.documentClickListener = null;
    }
  }
}

@Component( {
  selector  : 'sym-action-bar',
  template  : `
    <div [ngClass]="{ 'ui-action-bar ui-widget ui-widget-content' : true }" [class]="styleClass" [ngStyle]="style">
      <ng-container ngIf="headerTemplate"
                    *ngTemplateOutlet="headerTemplate"></ng-container>
      <div *ngIf="!headerTemplate && model && model.title" class="ui-action-bar-title" [innerHTML]="model.title"></div>
      <span *ngIf="model && model.title && htmlToolTip" class="icon__help-small" style="display:inline-block; vertical-align:text-bottom;" [pTooltip]="htmlToolTip" [tooltipDisabled]="!htmlToolTip" tooltipPosition="top" placeholder="Top" [escape]="false" hideDelay="3000"></span>
      <div class="ui-action-bar-right-container">
        <div class="ui-action-bar-actions" [ngClass]="{ 'has--separator' : ( model && model.items && model.items.length ) && ( model.columnToggle && model.columnToggle.selectedColumns ) }">
          <sym-action-bar-sub [item]="model ? model.items : []" root="root" [autoDisplay]="autoDisplay" [baseZIndex]="baseZIndex" [autoZIndex]="autoZIndex">
            <div *ngIf="content" #symActionBarSubContent>
              <ng-content></ng-content>
            </div>
          </sym-action-bar-sub>
        </div>
        <div *ngIf="model && model.columnToggle && model.columnToggle.isModal"
             [ngClass]="{'sym-action-bar__column-selector-button ui-widget ui-state-default ui-corner-all':true,'sym-column-selector-dropdown__open':columnSelectorModalVisible}"
             (click)="showDialog()">
          <div class="sym-column-selector-dropdown__label-container">
            <label class="sym-column-selector-dropdown__label ui-corner-all">
              <div [innerHTML]="(model.columnToggle && model.columnToggle.l10n && model.columnToggle.l10n.tableColumnCount ? model.columnToggle.l10n.tableColumnCount : tableColumnCountDefault) | symTranslate: {selectedCounts: model.columnToggle.selectedColumns.length, totalCounts: model.columnToggle.cols.length}"></div>
            </label>
            <div [ngClass]="{'sym-column-selector-dropdown__trigger ui-state-default ui-corner-right is--link':true}">
              <div class="sym-column-selector-dropdown__trigger-icon">
                <sym-icon *ngIf="!columnSelectorModalVisible"
                          styleClass="sym-smbl--arrow-small sym-smbl--black-80"
                          svgId="sym-smbl__arrow-chevron"></sym-icon>
                <sym-icon *ngIf="columnSelectorModalVisible"
                          styleClass="sym-smbl--arrow-small sym-smbl--black-80 sym-smbl--arrow-up"
                          svgId="sym-smbl__arrow-chevron"></sym-icon>
              </div>
            </div>
          </div>
        </div>

        <p-dialog *ngIf="model && model.columnToggle && model.columnToggle.isModal" [header]="model.columnToggle.l10n.modalHeader" [(visible)]="columnSelectorModalVisible" [modal]="true"
                  [style]="{width: '500px', minWidth: '300px'}" [minY]="100" [baseZIndex]="10000">
          <div class="sym-action-bar__column-selector-modal">
            <div class="ui-dialog-inner-content">
              <sym-column-selector *ngIf="model.columnToggle && columnSelectorModalVisible"
                                   [visible]="columnSelectorModalVisible"
                                   [options]="columnSelectorModalCols"
                                   [(ngModel)]="columnSelectorModalSelectedCols"
                                   [originalOptions]="originalCols"
                                   [originalSelections]="selectedColumns"
                                   optionLabel="header"
                                   dataKey="field"
                                   (onChange)="columnUpdated($event)"
                                   (onReorder)="columnReorder($event)"
                                   (onReset)="columnReorderReset($event)"
                                   [enableReorder]="model.columnToggle.reorderableColumns"
                                   [l10n]="model.columnToggle.l10n"
                                   [showToggleAll]="model.columnToggle.showToggleAll"
              >
              </sym-column-selector>
            </div>
          </div>

          <p-footer>
            <sym-button type="button" (click)="applyColumnChange()" label="Apply"></sym-button>
            <sym-button type="button" (click)="columnSelectorModalVisible=false" label="Cancel" styleClass="ui-button-secondary"></sym-button>
          </p-footer>
        </p-dialog>

        <div *ngIf="model && model.columnToggle && !model.columnToggle.isModal && model.columnToggle.selectedColumns" class="ui-action-bar-column-toggle">
          <sym-column-selector-dropdown
                  [(overlayVisible)]="overlayVisible"
                  [panelStyle]="dropdownStyle"
                  [(ngModel)]="model.columnToggle.selectedColumns"
                  dropdownUpIcon="sym-smbl--arrow-small sym-smbl--white sym-smbl--arrow-up"
                  [offset]="{ top : 12, right: -10}"
                  [isResizable]="model.columnToggle.isResizable">
            <ng-template let-value pTemplate="selectedItems">
              <div class="ui-multiselected-item-token ui-corner-all">
                <div [innerHTML]="(model.columnToggle && model.columnToggle.l10n && model.columnToggle.l10n.tableColumnCount ? model.columnToggle.l10n.tableColumnCount : tableColumnCountDefault) | symTranslate: {selectedCounts: model.columnToggle.selectedColumns.length, totalCounts: model.columnToggle.cols.length}"></div>
              </div>
            </ng-template>

            <sym-main-content>
              <sym-column-selector
                      [panelStyle]="{width: '200px'}"
                      [visible]="overlayVisible"
                      [options]="model.columnToggle.cols"
                      [(ngModel)]="model.columnToggle.selectedColumns"
                      [originalOptions]="originalCols"
                      [originalSelections]="selectedColumns"
                      [resetFilterOnHide]="true"
                      optionLabel="header"
                      dataKey="field"
                      (onChange)="columnUpdated($event)"
                      (onReorder)="columnReorder($event)"
                      (onReset)="columnReorderReset($event)"
                      [enableReorder]="model.columnToggle.reorderableColumns"
                      [l10n]="model.columnToggle.l10n"
              >
              </sym-column-selector>
            </sym-main-content>
          </sym-column-selector-dropdown>
        </div>
      </div>
    </div>
  `,
  providers : [DomHandler]
} )
export class ActionBar implements OnInit {

  // <div [innerHTML]="(model.columnToggle && model.columnToggle.l10n && model.columnToggle.l10n.tableColumnCount ? model.columnToggle.l10n.tableColumnCount : tableColumnCountDefault) | symTranslate: {selectedCounts: model.columnToggle.selectedColumns.length, totalCounts: model.columnToggle.cols.length}" ></div>

  @Input() style : any;

  @Input() styleClass : string;

  @Input() autoDisplay : boolean = true;

  @Input() autoZIndex : boolean = true;

  @Input() baseZIndex : number = 0;

  @Input() headerTemplate : TemplateRef<any>;

  @Input() originalCols : SymColumnItem[];

  @Input() selectedColumns : SymColumnItem[];

  @Input() isColumnSelectorResizable = false;

  @Output() onColumnSelectionChange : EventEmitter<any[]> = new EventEmitter(); //user defined columns objects, so any

  @Output() onColumnReorder : EventEmitter<any[]> = new EventEmitter(); //user defined columns objects, so any

  @Output() onColumnReset : EventEmitter<boolean> = new EventEmitter();

  @ContentChild( 'symActionBarContent' ) content : ElementRef;

  overlayVisible = false;

  htmlToolTip : any;

  tableColumnCountDefault = '<b>{{selectedCounts}}</b> of <b>{{totalCounts}}</b> Columns Selected';

  columnSelectorModalVisible = false;

  columnSelectorModalCols : SymColumnItem[];

  columnSelectorModalSelectedCols : SymColumnItem[];

  dropdownStyle : any;

  _model : ActionBarModel;
  @Input() get model () : ActionBarModel {
    return this._model;
  }

  set model ( val ) {
    if ( val ) {
      this._model = val;
      //need to limit the height for large number of columns.
      this.dropdownStyle = val.columnToggle && val.columnToggle.dropdownStyle ? val.columnToggle.dropdownStyle : null;
      if ( val.columnToggle && !val.columnToggle.dropdownStyle && val.columnToggle.cols && val.columnToggle.cols.length > 6 ) {
        this.dropdownStyle = { height : '300px' };
      }
    }
  }

  constructor (
    public el : ElementRef,
    public domHandler : DomHandler,
    public renderer : Renderer2
  ) {
  }

  ngOnInit () {

    this.htmlToolTip = ( this && this.model && this.model.tooltip ) ? this.model.tooltip : '';
  }

  columnReorderReset ( reset ) {
    if ( !this.columnSelectorModalVisible ) {
      this.onColumnReset.emit( reset );
    }
  }

  columnUpdated ( obj ) {
    if ( !this.columnSelectorModalVisible ) {
      this.onColumnSelectionChange.emit( obj.value );
    }
  }

  columnReorder ( obj ) {
    let selectedColumnFields : string[];
    let reorderedSelectedColumns : SymColumnItem[];

    if ( !this.columnSelectorModalVisible ) {
      selectedColumnFields = this.model.columnToggle.selectedColumns.map( ( column ) => {
        return column.field;
      } );

      reorderedSelectedColumns = obj.value.filter( ( v ) => {
        return selectedColumnFields.indexOf( v.field ) > -1;
      } );

      this.model.columnToggle.selectedColumns = reorderedSelectedColumns;
      this.model.columnToggle.cols            = obj.value;
      this.onColumnReorder.emit( this.model.columnToggle.cols );
    } else {
      if ( !this.columnSelectorModalSelectedCols || !this.columnSelectorModalSelectedCols ) {
        this.columnSelectorModalCols         = this.model.columnToggle.cols.slice();
        this.columnSelectorModalSelectedCols = this.model.columnToggle.selectedColumns.slice();
      }

      selectedColumnFields = this.columnSelectorModalSelectedCols.map( ( column ) => {
        return column.field;
      } );

      reorderedSelectedColumns = obj.value.filter( ( v ) => {
        return selectedColumnFields.indexOf( v.field ) > -1;
      } );

      this.columnSelectorModalSelectedCols = reorderedSelectedColumns;
      this.columnSelectorModalCols         = obj.value;
    }
  }

  showDialog () {
    this.columnSelectorModalVisible      = true;
    this.columnSelectorModalCols         = this.model.columnToggle.cols.slice();
    this.columnSelectorModalSelectedCols = this.model.columnToggle.selectedColumns.slice();
  }

  hideDialog () {
    this.columnSelectorModalVisible = false;
  }

  applyColumnChange () {
    this.model.columnToggle.selectedColumns = this.columnSelectorModalSelectedCols;
    this.model.columnToggle.cols            = this.columnSelectorModalCols;
    this.onColumnSelectionChange.emit( this.model.columnToggle.selectedColumns );

    this.hideDialog();
  }
}

@NgModule( {
  imports      : [CommonModule, RouterModule, FormsModule, SymColumnSelectorDropdownModule, TooltipModule, SymTranslateModule, DialogModule, ButtonModule],
  exports      : [ActionBar, RouterModule, FormsModule, SymColumnSelectorDropdownModule, SymTranslateModule, DialogModule, ButtonModule],
  declarations : [ActionBar, ActionBarSub]
} )
export class ActionBarModule {
}
