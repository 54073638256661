import {FormControl, FormGroup} from "@angular/forms";

// Provide a way for steps to access data from other steps.
export interface IMultiStepFormSubStepDataGetter {
  getControlValue(formGroupName: string, controlName: string): any;
  getControl(formGroupName: string): any;
}

// Provide a way for steps to set form-wide messages.
export interface IMultiStepFormSubStepMessenger {
  setErrorMessage(errorMessage: string): void;
  setWarningMessage(warningMessage: string): void;
}

// Provide a way for steps to set "busy" mode.
export interface IMultiStepFormSubStepBusyIndicator {
  setBusy(busy: boolean): void;
}

export abstract class BaseMultiStepFormStep {
  protected stepDataGetter: IMultiStepFormSubStepDataGetter;   // Internal use only, so not public
  protected stepMessenger: IMultiStepFormSubStepMessenger;   // Internal use only, so not public
  protected stepBusyIndicator: IMultiStepFormSubStepBusyIndicator;   // Internal use only, so not public

  // Override only if a `FormGroup` is used.
  // By default, `null` is returned to indicate no `FormGroup` is being used.
  // If there are any user input controls on a step, define one top-level `FormGroup` to contain all controls
  // and return that outer-most `FormGroup` here.
  getFormGroup(): FormGroup {
    return null;
  }

  // Override only if a `FormGroup` is used.
  // By default, `null` is returned to indicate no `FormGroup` is being used.
  // Return the name for a step's `FormGroup`; the name must be unique among all steps.
  getFormGroupName(): string {
    return null;
  };

  // Must provide a step title, used as in-dialog step heading or Journey-line step title
  abstract getStepTitle(): string;

  // Override if a subset of the total steps model is being used, e.g. for an edit flow
  isFirstStep(stepIndex: number, stepCount: number): boolean {
    return stepIndex === 0;
  }

  // Override if a subset of the total steps model is being used, e.g. for an edit flow
  isLastStep(stepIndex: number, stepCount: number): boolean {
    return stepIndex === stepCount - 1;
  }

  // Override only if "Next" is based on conditions other than form validity.
  // This--in addition to form validity--will determine the disabled state of the "Next" button.
  // By default, "Next" is always allowed.
  // If "Next" is disallowed, any messaging in the UI must be done by the step implementation itself
  allowNext(): boolean {
    return true;
  }

  // Override only if custom work needs to be done when "Next" is clicked,
  // e.g. to persist the form state on the server before moving on to the next step.
  // (Note: this does not affect the disabled state of the "Next" button.)
  // By default, no custom work is expected and "Next" is always allowed.
  // Shouldn't be needed on the last step as the `onSubmit` callback can be used to process the entire form.
  // Return a promise that resolves to a boolean indicating if "Next" should be allowed (`true`) or not (`false`)
  // Until the promise is resolved, a "busy" indicator will be shown in the UI
  // If "Next" ends up being disallowed, any messaging in the UI must be done by the step implementation itself
  tryNext(): Promise<boolean> {
    return Promise.resolve(true);
  }

  // Override only if custom work needs to be done before the step is shown, for example to pull new data from another step.
  // Note this is *after* the previous step, if any, is done processing (so this method is called *after* `tryNext`).
  // By default, this is a no-op.
  beforeShowStep() {
    // No-op.
  }

  // Called only by MultiStepForm to facilitate `getStepFormGroup` and `getStepFormGroupControlValue`.
  // Individual steps should not call this setter method.
  setStepDataGetter(stepDataGetter: IMultiStepFormSubStepDataGetter) {
    this.stepDataGetter = stepDataGetter;
  }

  // Use to retrieve data from other steps
  getStepFormGroupControlValue(formGroupName: string, controlName: string) {
    return this.stepDataGetter.getControlValue(formGroupName, controlName);
  }

  // Use to retrieve a form group reference from other steps--careful, this returns the actual instance
  // If only a specific data point is needed, use getStepFormGroupControlValue() instead
  getStepFormGroup(formGroupName: string) {
    return this.stepDataGetter.getControl(formGroupName);
  }

  // Called only by MultiStepForm to facilitate `setBusy`.
  // Individual steps should not call this setter method.
  setStepBusyIndicator(stepBusyIndicator: IMultiStepFormSubStepBusyIndicator) {
    this.stepBusyIndicator = stepBusyIndicator;
  }

  // Use to set "busy" mode.
  setBusy(busy: boolean) {
    this.stepBusyIndicator.setBusy(busy);
  }

  // Called only by MultiStepForm to facilitate `setErrorMessage` and `setWarningMessage`.
  // Individual steps should not call this setter method.
  setStepMessenger(stepMessenger: IMultiStepFormSubStepMessenger) {
    this.stepMessenger = stepMessenger;
  }

  // Use to set a form-wide error message.
  setErrorMessage(errorMessage: string) {
    this.stepMessenger.setErrorMessage(errorMessage);
  }

  // Use to set a form-wide warning message.
  setWarningMessage(warningMessage: string) {
    this.stepMessenger.setWarningMessage(warningMessage);
  }

  // Use to merge data coming from the server back into `formGroup`, e.g. after saving the form
  // Some fields like passwords may come back as null and likely user-entered value should not be overwritten in that
  // case; still you can allow overwrite by passing `true` for `allowNull`
  mergeServerDataIntoFormGroup(data, formGroup, allowNull = false) {
    Object.keys(data).forEach(key => {
      if (allowNull || (data[key] !== null && data[key] !== undefined)) {
        if (typeof data[key] === 'object' && !(data[key] instanceof Array) && Object.keys(data[key]).length > 0) {
          this.mergeServerDataIntoFormGroup(data[key], formGroup.get(key));
        } else {
          let control = formGroup.get(key);
          if (!control) {
            // New property now originally configured on the UI-side
            control = new FormControl();
            formGroup.addControl(key, control);
          }
          control.setValue(data[key]);
        }
      }
    });
  }

  // Override only if custom work needs to be done when the multi-step flow is cancelled.
  // By default, this is a no-op.
  cancel() {
    // No-op.
  }
}
