import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef,
    EventEmitter,
    Input,
    NgModule,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    Type,
    ViewChildren
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {MultiStepFormContent} from "./multi-step-form-content";
import {ButtonModule} from "../button/button";
import {
    BaseMultiStepFormStep, IMultiStepFormSubStepBusyIndicator,
    IMultiStepFormSubStepDataGetter,
    IMultiStepFormSubStepMessenger
} from "./multi-step-form-step";
import {StepsModule} from "../steps/steps";
import {MenuItem} from "../common/menu-item";
import {IMultiStepFormL10n} from "./multi-step-form-l10n";
import {DynamicDialogConfig} from "../dynamic-dialog/dynamic-dialog-config";
import {ProgressSpinnerModule} from "../progress-spinner/progress-spinner";
import {BlockUIModule} from "../block-ui/block-ui";
import {PanelModule} from "../panel/panel";
import {BlockableUI} from "../common/blockable-ui";

@Component({
    selector: 'multi-step-form',
    template: `
        <p-steps *ngIf="hasJourney" [model]="journeySteps" [activeIndex]="currentStep" [readonly]="false"></p-steps>
        <form [formGroup]="formGroup">
            <div *ngFor="let step of model; let i = index" [ngClass]="{ 'ui-dialog-inner-content': !hasJourney }"
                 [ngStyle]="{ 'min-height': '250px', 'display': currentStep === i ? 'block' : 'none' }">
                <ng-template pMultiStepFormContent></ng-template>
            </div>

            <p *ngIf="errorMessage" class="ui-messages ui-messages-error" [ngStyle]="{ 'position': 'static' }">{{ errorMessage }}</p>
            <p *ngIf="warningMessage" class="ui-messages ui-messages-warning" [ngStyle]="{ 'position': 'static' }">{{ warningMessage }}</p>

            <div
              [ngClass]="{ 
                'ui-dialog-footer': !hasJourney, 
                'is--enabled': (isLastStep() ? formGroup.valid : isCurrentStepFormValid()) && allowNext(),
                'is--disabled': !(isLastStep() ? formGroup.valid : isCurrentStepFormValid()) || !allowNext() 
              }">
                <sym-button *ngIf="!hasJourney" type="button" (click)="onCancel()" label="{{ (l10n || { cancel: 'Cancel' }).cancel }}" [style]="{ 'color': '#fff' }" styleClass="ui-button-transparent"></sym-button>
                <sym-button *ngIf="!isFirstStep()" type="button" (click)="onPrevious()"
                            label="{{ (l10n || { previous: 'Previous' }).previous }}"
                            styleClass="ui-button-secondary"></sym-button>
                <sym-button *ngIf="!isLastStep()" type="button" (click)="onNext()"
                            label="{{ (l10n || { next: 'Next' }).next }}"
                            [ngClass]="{ 'ui-state-disabled': !isCurrentStepFormValid() || !allowNext() }"></sym-button>
                <sym-button *ngIf="isLastStep()" type="submit" (click)="onSubmit()"
                            label="{{ (l10n || { submit: 'Submit' }).submit }}"
                            [ngClass]="{ 'ui-state-disabled': !formGroup.valid || !allowNext()}"></sym-button>
            </div>
        </form>

        <p-blockUI [target]="busyElement" [blocked]="busy">
            <i class="fa fa-lock fa-5x" style="position:absolute;top:50%;left:50%"></i>
        </p-blockUI>
    `
})
// TODO: dialog doesn't scroll well when there's not enough vertical space, header & footer get cut off. Should work like UXTv1
// TODO: clickable journeyline steps should follow same rules as Prev/Next (also update docs once addressed)
// TODO: show "* = required field"?
// TODO: show step M of N"?
// TODO: need clear button?
// TODO: busyArea needs to be refined: should block only multi-step-form for journeyline usage, but should cover entire dialog content for dialog usage
export class MultiStepForm implements AfterViewInit, BlockableUI, IMultiStepFormSubStepBusyIndicator, IMultiStepFormSubStepDataGetter, IMultiStepFormSubStepMessenger, OnDestroy, OnInit  {
    // When `tryNext()` is called, delay showing the spinner until it's clear the operation may take a while
    // This is to prevent a brief showing of the spinner if the result is instant (which is the default implementation)
    private static BUSY_DISPLAY_DELAY = 100;

    @Input() model: Type<any>[];

    @Input() index: number;

    @Input() l10n: IMultiStepFormL10n;

    @Input() hasJourney: boolean;

    @Input() busyElement: HTMLElement;

    @Output() submit: EventEmitter<any> = new EventEmitter();

    @ViewChildren(MultiStepFormContent) insertionPoints: QueryList<MultiStepFormContent>;

    private componentRefs: ComponentRef<BaseMultiStepFormStep>[];

    formGroup = new FormGroup({});
    currentStep: number;
    stepCount: number;
    journeySteps: MenuItem[];
    busy: boolean;
    errorMessage: string;
    warningMessage: string;

    constructor(
      private element: ElementRef,
      private componentFactoryResolver: ComponentFactoryResolver,
      private config: DynamicDialogConfig
    ) {}

    ngOnInit() {
        this.currentStep = this.index || 0;
        this.stepCount = this.model.length;
    }

    // @ViewChild(ren) cannot be used until after ngOnInit, so use ngAfterViewInit
    ngAfterViewInit () {
        // Without the timeout, ExpressionChangedAfterItHasBeenCheckedError is thrown ...
        // Without the extra function wrapping "this" gets lost...
        setTimeout(() => {
            this.ngAfterViewInitImpl();
        });
    }

    private ngAfterViewInitImpl() {
        this.componentRefs = [];
        this.journeySteps = [];
        this.insertionPoints.forEach((ip, index) => {
            // Dynamically create step instance
            let componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.model[index]);
            ip.viewContainerRef.clear();
            this.componentRefs[index] = ip.viewContainerRef.createComponent(componentFactory);

            // Allow steps to access data from other steps (do this before detectChanges() below)
            this.componentRefs[index].instance.setStepDataGetter(this);

            // Allow steps to set busy mode (do this before detectChanges() below)
            // TODO: use <ng-container [ngTemplateOutletContext]> to pass busy ref to template?
            this.componentRefs[index].instance.setStepBusyIndicator(this);

            // Allow steps to set form-wide messages (do this before detectChanges() below)
            // TODO: use <ng-container [ngTemplateOutletContext]> to pass error/warningMessage refs to template?
            this.componentRefs[index].instance.setStepMessenger(this);

            // Make sure step's ngOnInit() is run so BaseMultiStepFormStep methods have a proper return value,
            // see https://stackoverflow.com/a/56427908
            this.componentRefs[index].changeDetectorRef.detectChanges();

            // Register step with main form
            const formStepCompRef = this.getStepComponentRef(index);
            const formStepFormGroup = formStepCompRef.getFormGroup();
            if (formStepFormGroup) {
                this.formGroup.registerControl(formStepCompRef.getFormGroupName(), formStepFormGroup);

                // Run step's validations (without showing error messages in UI) so main form state is updated
                this.formGroup.updateValueAndValidity();
            }

            if (this.hasJourney) {
                this.journeySteps[index] = {
                    label: formStepCompRef.getStepTitle(),
                    command: () => {
                        if (this.isCurrentStepFormValid()) {
                            this.showStep(index);
                        } else {
                            // Make sure any errors show up
                            this.validateCurrentStepForm();
                        }
                    }
                };
            }
        });

        this.showStep(this.currentStep);
    }

    // Returns `null` if componentRef[index] does not exist, which is likely due to ngAfterViewInit not having run yet
    private getStepComponentRef(index: number): BaseMultiStepFormStep {
        if (!this.componentRefs || !this.componentRefs.length) {
            return null;
        } else {
            return this.componentRefs[index].instance;
        }
    }

    private validateCurrentStepForm() {
        const controls = this.getStepComponentRef(this.currentStep).getFormGroup().controls;
        Object.keys(controls).forEach(key => {
            controls[key].markAsDirty();   // Shows error and colors input field border
        });
    }

    private showStep(step: number) {
        // Allow step a way to update itself (e.g. with new data from other steps) before being shown
        if (step !== this.currentStep) {   // Could be equal initially if `[index]` is used in the template
            this.getStepComponentRef(step).beforeShowStep();
        }

        // Clear previous form messaging
        this.errorMessage = '';
        this.warningMessage = '';

        this.currentStep = step;
        this.config.header = this.getStepComponentRef(this.currentStep).getStepTitle();
    }

    getControlValue(formGroupName: string, controlName: string): any {
        return this.formGroup.controls[formGroupName].value[controlName];
    }

    getControl(formGroupName: string): any {
        return this.formGroup.controls[formGroupName];
    }

    setBusy(busy: boolean) {
        this.busy = busy;
    }

    setErrorMessage(errorMessage: string) {
        this.errorMessage = errorMessage;
    }

    setWarningMessage(warningMessage: string) {
        this.warningMessage = warningMessage;
    }

    isCurrentStepFormValid() {
        const componentRef = this.getStepComponentRef(this.currentStep);
        if (componentRef) {
            const formGroup = componentRef.getFormGroup();
            if (formGroup) {
                return formGroup.valid;
            } else {
                // Step's function ngOnInit hasn't executed yet
                return false;
            }
        } else {
            // Function ngAfterViewInit hasn't executed yet
            return false;
        }
    }

    getBlockableElement(): HTMLElement {
        return this.element.nativeElement;
    }

    onCancel() {
        this.getStepComponentRef(this.currentStep).cancel()
        this.submit.emit();
    }

    onPrevious() {
        // Always allow going back ("Wait, what did I just enter on the previous step?")
        this.showStep(this.currentStep - 1);
    }

    isFirstStep() {
        const componentRef = this.getStepComponentRef(this.currentStep);
        if (componentRef) {
            return componentRef.isFirstStep(this.currentStep, this.model.length);
        } else {
            // Function ngAfterViewInit hasn't executed yet
            return false;
        }
    }

    isLastStep() {
        const componentRef = this.getStepComponentRef(this.currentStep);
        if (componentRef) {
            return componentRef.isLastStep(this.currentStep, this.model.length);
        } else {
            // Function ngAfterViewInit hasn't executed yet
            return false;
        }
    }

    allowNext(): boolean {
        const componentRef = this.getStepComponentRef(this.currentStep);
        if (componentRef) {
            return componentRef.allowNext();
        } else {
            // Function ngAfterViewInit hasn't executed yet
            return false;
        }
    }

    private tryNext(callbackIfAllowed) {
        // Next step (next step or submit) must be allowed, so wait for decision
        // If not allowed, any error messaging is assumed to be done by the step implementation
        let promiseResolved = false;
        this.getStepComponentRef(this.currentStep).tryNext().then(
          (allowed: boolean) => {
              promiseResolved = true;
              this.busy = false;
              if (allowed) {
                  callbackIfAllowed();
              }
          },
          () => {
              promiseResolved = true;
              this.busy = false;
          }
        );

        // Show progress spinner only if it looks like it could take a while
        this.busy = false;
        setTimeout(() => {
            if (!promiseResolved) {
                this.busy = true;
            }
        }, MultiStepForm.BUSY_DISPLAY_DELAY);
    }

    onNext() {
        // Current step form must be valid before moving on
        if (this.isCurrentStepFormValid() && this.allowNext()) {
            this.tryNext(() => {
                this.showStep(this.currentStep + 1);
            });
        } else {
            // Make sure any errors show up
            this.validateCurrentStepForm();
        }
    }

    onSubmit() {
        // *All* step forms need to be valid
        if (this.formGroup.valid && this.allowNext()) {
            this.tryNext(() => {
                this.submit.emit(this.formGroup.getRawValue());   // Includes values for disabled controls
            });
        } else {
            // Make sure any errors show up
            this.validateCurrentStepForm();
        }

        // Avoid "normal" form submit event
        return false;
    }

    ngOnDestroy () {
        if (this.componentRefs) {
            this.componentRefs.forEach(componentRef => {
                componentRef.destroy();
            });
        }
    }
}

@NgModule({
    imports: [CommonModule, RouterModule, ReactiveFormsModule, ButtonModule, StepsModule, ProgressSpinnerModule, BlockUIModule, PanelModule],
    exports: [MultiStepForm],
    declarations: [MultiStepForm, MultiStepFormContent]
})
export class MultiStepFormModule { }
