import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  NgModule,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  Directive,
  NgZone,
  HostListener,
  Host,
  SkipSelf
}                                                                     from '@angular/core';
import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { CommonModule }                                               from '@angular/common';
import { SelectItem }                                                 from '../common/select-item';
import { DomHandler }                                                 from '../dom/dom-handler';
import { ObjectUtils }                                                from '../utils/object-utils';
import { ControlValueAccessor, NG_VALUE_ACCESSOR }                    from '@angular/forms';
import { Footer, PrimeTemplate, SharedModule }                        from '../common/shared';
import { ReorderEvent }                                               from '../common/reorder.interface';
import { SymInlineEditModule, SymInlineEdit }                         from '../inline-edit/inline-edit';
import { SymColumnSelectorModule }                                    from '../sym-column-selector/sym-column-selector';
import { InlineEditService }                                          from '../inline-edit/inline-edit.service';
import { MenuModule }                                                 from '../menu/menu';
import { SymColumnItem }                                              from '../common/column.interface';

export const COLUMN_SELECTOR_DROPDOWN_VALUE_ACCESSOR : any = {
  provide     : NG_VALUE_ACCESSOR,
  useExisting : forwardRef( () => SymColumnSelectorDropdown ),
  multi       : true
};

@Component( {
  selector   : 'sym-column-selector-dropdown',
  template   : `
    <div #container
         [ngClass]="{'sym-column-selector-dropdown ui-widget ui-state-default ui-corner-all':true,'sym-column-selector-dropdown__open':overlayVisible,'ui-state-focus':focus,'ui-state-disabled': disabled}"
         [ngStyle]="style" [class]="styleClass"
         (click)="onMouseclick($event,in)">
      <div class="ui-helper-hidden-accessible">
        <input #in type="text" readonly="readonly" [attr.id]="inputId" [attr.name]="name"
               (focus)="onInputFocus($event)" (blur)="onInputBlur($event)"
               [disabled]="disabled" [attr.tabindex]="tabindex"
               (keydown)="onKeydown($event)">
      </div>
      <div class="sym-column-selector-dropdown__label-container" [title]="valuesAsString">
        <label class="sym-column-selector-dropdown__label ui-corner-all">
          <ng-container *ngIf="!selectedItemsTemplate">{{valuesAsString}}</ng-container>
          <ng-container
                  *ngTemplateOutlet="selectedItemsTemplate;"></ng-container>
        </label>
      </div>
      <div [ngClass]="{'sym-column-selector-dropdown__trigger ui-state-default ui-corner-right is--link':true}">
        <div class="sym-column-selector-dropdown__trigger-icon">
          <sym-icon *ngIf="!overlayVisible"
                    [styleClass]="dropdownIcon"
                    svgId="sym-smbl__arrow-chevron"></sym-icon>
          <sym-icon *ngIf="overlayVisible"
                    [styleClass]="dropdownUpIcon"
                    svgId="sym-smbl__arrow-chevron"></sym-icon>
        </div>
      </div>
      <div *ngIf="overlayVisible"
           #columnResizable
           [ngClass]="['sym-column-selector-dropdown__panel ui-widget ui-widget-content ui-corner-all ui-shadow']"
           [@overlayAnimation]="{value: 'visible', params: {showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions}}"
           (@overlayAnimation.start)="onOverlayAnimationStart($event)"
           [ngStyle]="panelStyle" [class]="panelStyleClass" (click)="panelClick=true">
        <ng-content select="sym-main-content"></ng-content>
        <div class="sym-column-selector-dropdown__footer ui-widget-content" *ngIf="footerFacet">
          <ng-content select="p-footer"></ng-content>
        </div>
        <div *ngIf="isResizable" #columnResizerHandle class="sym-column-selector-dropdown__resizer-handle">
          <sym-icon
                  styleClass="sym-smbl__resize-handle-left-bottom-corner"
                  svgId="sym-smbl__resize-handle-left-bottom-corner"></sym-icon>
        </div>
        <a class="sym-column-selector-dropdown__close ui-corner-all" tabindex="0"
           (click)="close($event)" (keydown.enter)="close($event)">
          <span class="icon__global-close-x sym__icon"></span>
        </a>
      </div>

    </div>
  `,
  animations : [
    trigger( 'overlayAnimation', [
      state( 'void', style( {
        transform : 'translateY(5%)',
        opacity   : 0
      } ) ),
      state( 'visible', style( {
        transform : 'translateY(0)',
        opacity   : 1
      } ) ),
      transition( 'void => visible', animate( '{{showTransitionParams}}' ) ),
      transition( 'visible => void', animate( '{{hideTransitionParams}}' ) )
    ] )
  ],
  host       : {
    '[class.ui-inputwrapper-filled]' : 'filled',
    '[class.ui-inputwrapper-focus]'  : 'focus'
  },
  providers  : [DomHandler, ObjectUtils, COLUMN_SELECTOR_DROPDOWN_VALUE_ACCESSOR]
} )
export class SymColumnSelectorDropdown implements OnInit, AfterViewInit, AfterContentInit, AfterViewChecked, OnDestroy, ControlValueAccessor {

  @Input() style : any;

  @Input() styleClass : string;

  @Input() panelStyle : any;

  @Input() panelStyleClass : string;

  @Input() inputId : string;

  @Input() disabled : boolean;

  @Input() readonly : boolean;

  @Input() filter : boolean = true;

  @Input() filterPlaceHolder : string;

  @Input() overlayVisible : boolean;

  @Input() tabindex : number;

  @Input() dataKey : string;

  @Input() name : string;

  @Input() displaySelectedLabel : boolean = true;

  @Input() maxSelectedLabels : number = 3;

  @Input() selectionLimit : number;

  @Input() selectionMinimum : number = 1;

  @Input() selectedItemsLabel : string = '{0} items selected';

  @Input() showToggleAll : boolean = false;

  @Input() resetFilterOnHide : boolean = false;

  @Input() dropdownIcon : string = 'sym-smbl--arrow-small sym-smbl--black-80';

  @Input() dropdownUpIcon : string = 'sym-smbl--arrow-small sym-smbl--black-80 sym-smbl--arrow-up';

  @Input() optionLabel : string;

  @Input() showHeader : boolean = true;

  @Input() autoZIndex : boolean = true;

  @Input() baseZIndex : number = 0;

  @Input() filterBy : string = 'label';

  @Input() virtualScroll : boolean;

  @Input() itemSize : number;

  @Input() showTransitionOptions : string = '225ms ease-out';

  @Input() hideTransitionOptions : string = '195ms ease-in';

  @Input() enableReorder : boolean = false;

  @Input() isResizable : boolean = false;

  @Input() offset = { top : 12 };

  @Input() originalSelections : any[]; //developer defined, so can't add type

  @Input() minHeight = 300;

  @Input() maxHeight = 600;

  @Input() minWidth = 240;

  @Input() maxWidth = 600;

  @ViewChild( 'container', { static : false } ) containerViewChild : ElementRef;

  @ViewChild( 'columnResizable', { static : false } ) columnResizable : ElementRef;

  @ViewChild( 'columnResizerHandle', { static : false } ) columnResizerHandle : ElementRef;

  @ContentChild( Footer, { static : false } ) footerFacet;

  @ContentChildren( PrimeTemplate ) templates : QueryList<any>;

  @Output() onChange : EventEmitter<any> = new EventEmitter();

  @Output() onFocus : EventEmitter<any> = new EventEmitter();

  @Output() onBlur : EventEmitter<any> = new EventEmitter();

  @Output() onPanelShow : EventEmitter<any> = new EventEmitter();

  @Output() onPanelHide : EventEmitter<any> = new EventEmitter();

  @Output() onReorder : EventEmitter<ReorderEvent> = new EventEmitter();

  @Output() onReset : EventEmitter<boolean> = new EventEmitter();

  @Output() overlayVisibleChange : EventEmitter<boolean> = new EventEmitter();

  showResetButton   = false;
  showCompileButton = false;
  showUndoButton    = false;

  resized = false;

  previousOptions : any[]; //developer defined, so can't add type
  previousSelections : any[]; //developer defined, so can't add type

  undoTimeout : ReturnType<typeof setTimeout> = setTimeout( () => '', 500 );

  public value : any[];

  public onModelChange : Function = () => {
  };

  public onModelTouched : Function = () => {
  };

  overlay : HTMLDivElement;

  public valuesAsString : string;

  public focus : boolean;

  filled : boolean;

  public documentClickListener : any;

  public selfClick : boolean;

  public panelClick : boolean;

  public filterValue : string;

  public visibleOptions : SelectItem[];

  public filtered : boolean;

  public itemTemplate : TemplateRef<any>;

  public selectedItemsTemplate : TemplateRef<any>;

  public headerCheckboxFocus : boolean;

  maxSelectionLimitReached : boolean;

  documentResizeListener : any;

  resizableResizeListener : any;

  draggedRowIndex : number;

  droppedRowIndex : number;

  previousDraggedIndex : number;

  rowDragging : boolean;

  dropPosition : number;

  originalDisabledKeys : string[] = [];

  columnResizableElm : HTMLElement;
  columnResizerHeight = 400;
  columnResizerWidth  = 200;
  columnResizerMouseX : number;
  columnResizerMouseY : number;
  columnResizerDiffX : number;
  columnResizerDiffY : number;

  columnResizerHandleElm : HTMLElement;

  constructor ( public el : ElementRef, public domHandler : DomHandler, public renderer : Renderer2, public objectUtils : ObjectUtils, private cd : ChangeDetectorRef, public zone : NgZone, private inlineEditService : InlineEditService ) {

  }

  ngOnInit () {
  }

  close ( event ) {
    this.hide();
    event.preventDefault();
    event.stopPropagation();
  }


  onMouseclick ( event, input ) {
    if ( this.disabled || this.readonly ) {
      return;
    }

    if ( !this.panelClick ) {
      if ( this.overlayVisible ) {
        this.hide();
      } else {
        input.focus();
        this.show();
      }
    }

    this.selfClick = true;
  }

  onInputFocus ( event ) {
    this.focus = true;
    this.onFocus.emit( { originalEvent : event } );
  }

  onInputBlur ( event ) {
    this.focus = false;
    this.onBlur.emit( { originalEvent : event } );
    this.onModelTouched();
  }

  onKeydown ( event : KeyboardEvent ) {
    switch ( event.which ) {
      //down // doesn't work
      case 40:
        if ( !this.overlayVisible && event.altKey ) {
          this.show();
        }
        break;

      //space
      case 32:
        if ( !this.overlayVisible ) {
          this.show();
          event.preventDefault();
        }
        break;

      //escape // doesn't work
      case 27:
        this.hide();
        break;
    }
  }

  writeValue ( value : any ) : void {
    this.value = value;
  }

  ngAfterContentInit () {
    this.templates.forEach( ( item ) => {
      switch ( item.getType() ) {
        case 'item':
          this.itemTemplate = item.template;
          break;

        case 'selectedItems':
          this.selectedItemsTemplate = item.template;
          break;

        default:
          this.itemTemplate = item.template;
          break;
      }
    } );
  }

  ngAfterViewInit () {
    if ( this.overlayVisible ) {
      this.show();
    }
  }

  ngAfterViewChecked () {
    if ( this.filtered ) {
      this.alignOverlay();

      this.filtered = false;
    }
  }

  startResize ( evt ) {
    this.columnResizerMouseX = evt.screenX;
    this.columnResizerMouseY = evt.screenY;
    this.renderer.addClass( document.body, 'is--resizing' );
  }

  resize ( evt ) {
    this.resized            = true;
    this.columnResizerDiffX = evt.screenX - this.columnResizerMouseX;
    this.columnResizerDiffY = evt.screenY - this.columnResizerMouseY;

    this.columnResizerMouseX = evt.screenX;
    this.columnResizerMouseY = evt.screenY;
    if ( ( this.columnResizerWidth - this.columnResizerDiffX ) < this.minWidth ) {
      this.columnResizerWidth = this.minWidth;
    } else if ( ( this.columnResizerWidth - this.columnResizerDiffX ) > this.maxWidth ) {
      this.columnResizerWidth = this.maxWidth;
    } else {
      this.columnResizerWidth -= this.columnResizerDiffX;
    }

    if ( ( this.columnResizerHeight + this.columnResizerDiffY ) < this.minHeight ) {
      this.columnResizerHeight = this.minHeight;
    } else if ( ( this.columnResizerHeight + this.columnResizerDiffY ) > this.maxHeight ) {
      this.columnResizerHeight = this.maxHeight;
    } else {
      this.columnResizerHeight += this.columnResizerDiffY;
    }

    this.columnResizableElm.style.width  = this.columnResizerWidth + 'px';
    this.columnResizableElm.style.height = this.columnResizerHeight + 'px';
  }


  registerOnChange ( fn : Function ) : void {
    this.onModelChange = fn;
  }

  registerOnTouched ( fn : Function ) : void {
    this.onModelTouched = fn;
  }


  show () {
    if ( !this.overlayVisible ) {
      this.overlayVisible = true;
      this.overlayVisibleChange.emit( this.overlayVisible );
    }

    if ( this.isResizable ) {
      setTimeout( () => {
        this.columnResizableElm     = this.columnResizable.nativeElement;
        this.columnResizerHandleElm = this.columnResizerHandle.nativeElement;

        this.resizableResizeListener = this.resize.bind( this );

        if ( this.resized ) {
          this.columnResizableElm.style.width  = this.columnResizerWidth + 'px';
          this.columnResizableElm.style.height = this.columnResizerHeight + 'px';
        } else {
          this.columnResizerWidth  = this.columnResizableElm.clientWidth;
          this.columnResizerHeight = this.columnResizableElm.clientHeight;
        }

        this.columnResizerHandleElm.addEventListener( 'mousedown', evt => {
          this.startResize( evt );
          document.body.addEventListener( 'mousemove', this.resizableResizeListener );
          document.body.addEventListener( 'mouseup', () => {
            this.renderer.removeClass( document.body, 'is--resizing' );
            document.body.removeEventListener( 'mousemove', this.resizableResizeListener );
          } );
        } );
      }, 10 );

    }


    this.bindDocumentClickListener();

  }

  onOverlayAnimationStart ( event : AnimationEvent ) {
    switch ( event.toState ) {
      case 'visible':
        this.overlay = event.element;
        if ( this.autoZIndex ) {
          this.overlay.style.zIndex = String( this.baseZIndex + ( ++DomHandler.zindex ) );
        }
        this.alignOverlay();
        this.bindDocumentClickListener();
        this.bindDocumentResizeListener();
        this.onPanelShow.emit();
        break;

      case 'void':
        this.onOverlayHide();
        break;
    }
  }

  alignOverlay () {
    if ( this.overlay ) {
      this.domHandler.relativePosition( this.overlay, this.containerViewChild.nativeElement, this.offset );
    }
  }

  hide () {
    this.overlayVisible = false;
    this.overlayVisibleChange.emit( this.overlayVisible );
    this.unbindDocumentClickListener();
    this.onPanelHide.emit();
  }

  bindDocumentClickListener () {
    if ( !this.documentClickListener ) {
      this.documentClickListener = this.renderer.listen( 'document', 'click', () => {
        if ( !this.selfClick && !this.panelClick && this.overlayVisible ) {
          this.hide();
        }

        this.selfClick  = false;
        this.panelClick = false;
        this.cd.markForCheck();
      } );
    }
  }

  unbindDocumentClickListener () {
    if ( this.documentClickListener ) {
      this.documentClickListener();
      this.documentClickListener = null;
    }
  }

  bindDocumentResizeListener () {
    this.documentResizeListener = this.onWindowResize.bind( this );
    window.addEventListener( 'resize', this.documentResizeListener );
  }

  unbindDocumentResizeListener () {
    if ( this.documentResizeListener ) {
      window.removeEventListener( 'resize', this.documentResizeListener );
      this.documentResizeListener = null;
    }
  }

  onWindowResize () {
    this.hide();
  }

  onOverlayHide () {
    this.unbindDocumentClickListener();
    this.unbindDocumentResizeListener();
    this.overlay = null;
  }

  ngOnDestroy () {
    this.onOverlayHide();
  }
}

@NgModule( {
  imports      : [CommonModule, SharedModule, SymInlineEditModule, MenuModule, SymColumnSelectorModule],
  exports      : [SymColumnSelectorDropdown, SharedModule, MenuModule, SymColumnSelectorModule],
  declarations : [SymColumnSelectorDropdown]
} )
export class SymColumnSelectorDropdownModule {
}
