import {
    AfterContentInit,
    AfterViewChecked,
    AfterViewInit,
    Component,
    ContentChildren,
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Injectable,
    Input,
    NgModule,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Column, PrimeTemplate, SharedModule } from '../common/shared';
import { ActionBarModule } from '../action-bar/action-bar';
import { ActionBarModel } from "../action-bar/action-bar-model";
import { SearchModule } from '../search/search';
import { ISearchModel } from "../search/i-search-model";

import { PaginatorModule } from '../paginator/paginator';
import { DomHandler } from '../dom/dom-handler';
import { ObjectUtils } from '../utils/object-utils';
import { MenuItem, MenuItemSelectionMode, SelectionMenuItem } from '../common/api';
import { SortMeta } from '../common/sort-meta';
import { TableState } from '../common/table-state';
import { FilterMetadata } from '../common/filter-metadata';
import { BlockableUI } from '../common/blockable-ui';
import { Subject, Subscription } from 'rxjs';

@Injectable()
export class TableService {

    private sortSource = new Subject<SortMeta | SortMeta[]>();
    private selectionSource = new Subject();
    private contextMenuSource = new Subject<any>();
    private valueSource = new Subject<any>();
    private totalRecordsSource = new Subject<any>();
    private columnsSource = new Subject();

    sortSource$ = this.sortSource.asObservable();
    selectionSource$ = this.selectionSource.asObservable();
    contextMenuSource$ = this.contextMenuSource.asObservable();
    valueSource$ = this.valueSource.asObservable();
    totalRecordsSource$ = this.totalRecordsSource.asObservable();
    columnsSource$ = this.columnsSource.asObservable();

    onSort(sortMeta: SortMeta | SortMeta[]) {
        this.sortSource.next(sortMeta);
    }

    onSelectionChange() {
        this.selectionSource.next();
    }

    onContextMenu(data: any) {
        this.contextMenuSource.next(data);
    }

    onValueChange(value: any) {
        this.valueSource.next(value);
    }

    onTotalRecordsChange(value: number) {
        this.totalRecordsSource.next(value);
    }

    onColumnsChange(columns: any[]) {
        this.columnsSource.next(columns);
    }
}

// TODO: <sym-search> below should use SearchFilter's tokenized view as alternate view
@Component({
    selector: 'p-table',
    template: `
        <sym-search [model]="search" *ngIf="search"></sym-search>
        <sym-action-bar [model]="actionBar" *ngIf="actionBar"
          [headerTemplate]="actionbarHeaderTemplate"
          [originalCols]="originalCols" [selectedColumns]="originalSelectedColumns"
        (onColumnSelectionChange)="onActionBarColumnSelectionChange($event)" (onColumnReorder)="onColumnReorder($event)"
        (onColumnReset)="onColumnReset($event)">
        <ng-template let-message pTemplate="message">
            <div></div>
        </ng-template>
        </sym-action-bar>
        <div #container [ngStyle]="style" [class]="styleClass"
             [ngClass]="{'ui-table ui-sym-table ui-widget': true, 'ui-table-responsive': responsive, 'ui-table-resizable': resizableColumns,
                'ui-table-resizable-fit': (resizableColumns && columnResizeMode === 'fit'),
                'ui-table-hoverable-rows': (rowHover||selectionMode), 'ui-table-auto-layout': autoLayout}">
            <div *ngIf="captionTemplate" class="ui-table-caption ui-widget-header">
                <ng-container *ngTemplateOutlet="captionTemplate"></ng-container>
            </div>
            <p-paginator [rows]="rows" [first]="first" [totalRecords]="totalRecords"
                         [pageLinkSize]="pageLinks" styleClass="ui-paginator-top"
                         [alwaysShow]="alwaysShowPaginator"
                         (onPageChange)="onPageChange($event)"
                         [rowsPerPageOptions]="rowsPerPageOptions"
                         *ngIf="paginator && (paginatorPosition === 'top' || paginatorPosition =='both')"
                         [templateLeft]="paginatorLeftTemplate"
                         [templateRight]="paginatorRightTemplate"
                         [dropdownAppendTo]="paginatorDropdownAppendTo"></p-paginator>

            <div class="ui-table-wrapper" *ngIf="!scrollable">

                <table #table [ngClass]="tableStyleClass" [ngStyle]="tableStyle">
                    <ng-container
                            *ngTemplateOutlet="colGroupTemplate; context {$implicit: columns}"></ng-container>
                    <thead class="ui-table-thead">
                    <ng-container
                            *ngTemplateOutlet="headerTemplate; context: {$implicit: columns}"></ng-container>
                    </thead>
                    <tfoot class="ui-table-tfoot">
                    <ng-container
                            *ngTemplateOutlet="footerTemplate; context {$implicit: columns}"></ng-container>
                    </tfoot>
                    <tbody class="ui-table-progress-spinner" *ngIf="loading">
                        <tr>
                            <td>
                                <svg class="sym-smbl__progress-spinner sym-smbl--medium"><use href="#sym-smbl__progress-spinner"></use></svg>
                            </td>
                        </tr>
                    </tbody>
                    <tbody class="ui-table-tbody" [pTableBody]="columns" [pTableBodyTemplate]="bodyTemplate"></tbody>
                </table>

            </div>

            <div class="ui-table-scrollable-wrapper" *ngIf="scrollable">
                <div class="ui-table-scrollable-view ui-table-frozen-view"
                     *ngIf="frozenColumns||frozenBodyTemplate" [pScrollableView]="frozenColumns"
                     [frozen]="true" [ngStyle]="{width: frozenWidth}"
                     [scrollHeight]="scrollHeight"></div>
                <div class="ui-table-scrollable-view" [pScrollableView]="columns" [frozen]="false"
                     [scrollHeight]="scrollHeight"
                     [ngStyle]="{left: frozenWidth, width: 'calc(100% - '+frozenWidth+')'}"></div>
            </div>

            <p-paginator [rows]="rows" [first]="first" [totalRecords]="totalRecords"
                         [pageLinkSize]="pageLinks" styleClass="ui-paginator-bottom"
                         [alwaysShow]="alwaysShowPaginator"
                         (onPageChange)="onPageChange($event)"
                         [rowsPerPageOptions]="rowsPerPageOptions"
                         *ngIf="paginator && (paginatorPosition === 'bottom' || paginatorPosition =='both')"
                         [templateLeft]="paginatorLeftTemplate"
                         [templateRight]="paginatorRightTemplate"
                         [dropdownAppendTo]="paginatorDropdownAppendTo"></p-paginator>
            <div *ngIf="summaryTemplate" class="ui-table-summary ui-widget-header">
                <ng-container *ngTemplateOutlet="summaryTemplate"></ng-container>
            </div>

            <div #resizeHelper class="ui-column-resizer-helper ui-state-highlight"
                 style="display:none" *ngIf="resizableColumns"></div>

        </div>
    `,
    providers: [DomHandler, ObjectUtils, TableService]
})
export class Table implements OnInit, AfterViewInit, AfterContentInit, BlockableUI {

    @Input() frozenColumns: any[];

    @Input() frozenValue: any[];

    @Input() style: any;

    @Input() styleClass: string;

    @Input() tableStyle: any;

    @Input() tableStyleClass: string;

    @Input() paginator: boolean;

    @Input() id: string;

    @Input() rows: number;

    @Input() first: number = 0;

    @Input() pageLinks: number = 5;

    @Input() rowsPerPageOptions: number[];

    @Input() alwaysShowPaginator: boolean = true;

    @Input() paginatorPosition: string = 'bottom';

    @Input() paginatorDropdownAppendTo: any;

    @Input() defaultSortOrder: number = 1;

    @Input() sortMode: string = 'single';

    @Input() resetPageOnSort: boolean = true;

    @Input() selectionMode: string;

    @Output() selectionChange: EventEmitter<any> = new EventEmitter();

    @Input() contextMenuSelection: any;

    @Output() contextMenuSelectionChange: EventEmitter<any> = new EventEmitter();

    @Input() contextMenuSelectionMode: string = 'separate';

    @Input() dataKey: string;

    @Input() metaKeySelection: boolean;

    @Input() rowTrackBy: Function = (index: number, item: any) => item;

    @Input() lazy: boolean = false;

    @Input() lazyLoadOnInit: boolean = true;

    @Input() loadOnPage: boolean = false;

    @Input() compareSelectionBy: string = 'deepEquals';

    @Input() csvSeparator: string = ',';

    @Input() exportFilename: string = 'download';

    @Input() filters: { [s: string]: FilterMetadata; } = {};

    @Input() globalFilterFields: string[];

    @Input() filterDelay: number = 300;

    @Input() expandedRowKeys: { [s: string]: number; } = {};

    @Input() rowExpandMode: string = 'multiple';

    @Input() scrollable: boolean;

    @Input() scrollHeight: string;

    @Input() virtualScroll: boolean;

    @Input() virtualScrollDelay: number = 500;

    @Input() virtualRowHeight: number = 28;

    @Input() frozenWidth: string;

    @Input() responsive: boolean;

    @Input() contextMenu: any;

    @Input() resizableColumns: boolean;

    @Input() columnResizeMode: string = 'fit';

    @Input() reorderableColumns: boolean; //TODO: Remove this at some point as we replaced this with action bar multiselect for column reordering

    @Input() loading: boolean;

    @Input() loadingIcon: string = 'pi pi-spinner';

    @Input() rowHover: boolean;

    @Input() customSort: boolean;

    @Input() autoLayout: boolean;

    @Input() enableActiveRow: boolean;

    @Input() exportFunction;

    @Input() stateKey: string;

    @Input() stateStorage: string = 'session';

    @Input() enableRowSelectionState = false;

    @Input() resetState: boolean = false;

    @Output() onRowSelect: EventEmitter<any> = new EventEmitter();

    @Output() onRowUnselect: EventEmitter<any> = new EventEmitter();

    @Output() onRowActive: EventEmitter<any> = new EventEmitter();

    @Output() onPage: EventEmitter<any> = new EventEmitter();

    @Output() onSort: EventEmitter<any> = new EventEmitter();

    @Output() onFilter: EventEmitter<any> = new EventEmitter();

    @Output() onLazyLoad: EventEmitter<any> = new EventEmitter();

    @Output() onRowExpand: EventEmitter<any> = new EventEmitter();

    @Output() onRowCollapse: EventEmitter<any> = new EventEmitter();

    @Output() onContextMenuSelect: EventEmitter<any> = new EventEmitter();

    @Output() onColResize: EventEmitter<any> = new EventEmitter();

    @Output() onColReorder: EventEmitter<any> = new EventEmitter();

    @Output() onRowReorder: EventEmitter<any> = new EventEmitter();

    @Output() onHeaderCheckboxToggle: EventEmitter<any> = new EventEmitter();

    @Output() onColumnSelectionChange: EventEmitter<any> = new EventEmitter();

    @Output() sortFunction: EventEmitter<any> = new EventEmitter();

    @ViewChild('container', { static: false }) containerViewChild: ElementRef;

    @ViewChild('resizeHelper', { static: false }) resizeHelperViewChild: ElementRef;

    @ViewChild('table', { static: false }) tableViewChild: ElementRef;

    @ContentChildren(PrimeTemplate) templates: QueryList<PrimeTemplate>;

    @Input() actionbarHeaderTemplate: TemplateRef<any>;

    @Input() activeRowId: string;

    @Output() activeRowIdChange: EventEmitter<string> = new EventEmitter();

    _value: any[] = [];

    _columns: any[];

    _actionBar: ActionBarModel;

    _search: ISearchModel;

    _icons: any[];

    _totalRecords: number = 0;

    filteredValue: any[];

    headerTemplate: TemplateRef<any>;

    bodyTemplate: TemplateRef<any>;

    iconTemplate: TemplateRef<any>;

    captionTemplate: TemplateRef<any>;

    frozenRowsTemplate: TemplateRef<any>;

    footerTemplate: TemplateRef<any>;

    summaryTemplate: TemplateRef<any>;

    colGroupTemplate: TemplateRef<any>;

    expandedRowTemplate: TemplateRef<any>;

    frozenHeaderTemplate: TemplateRef<any>;

    frozenBodyTemplate: TemplateRef<any>;

    frozenFooterTemplate: TemplateRef<any>;

    frozenColGroupTemplate: TemplateRef<any>;

    emptyMessageTemplate: TemplateRef<any>;

    paginatorLeftTemplate: TemplateRef<any>;

    paginatorRightTemplate: TemplateRef<any>;

    selectionKeys: any = {};

    lastResizerHelperX: number;

    reorderIconWidth: number;

    reorderIconHeight: number;

    draggedColumn: any;

    draggedRowIndex: number;

    droppedRowIndex: number;

    rowDragging: boolean;

    dropPosition: number;

    _multiSortMeta: SortMeta[];

    _sortField: string;

    _sortOrder: number = 1;

    virtualScrollTimer: any;

    virtualScrollCallback: Function;

    preventSelectionSetterPropagation: boolean;

    _selection: any;

    anchorRowIndex: number;

    rangeRowIndex: number;

    filterTimeout: any;

    initialized: boolean;

    rowTouched: boolean;

    restoringSort: boolean;

    restoringFilter: boolean;

    stateRestored: boolean;

    columnOrderStateRestored: boolean;

    actionBarColumnStateRestored: boolean;

    columnWidthsState: string;

    tableWidthState: string;

    originalCols: {field?: string, header?: string}[]; //ActionBarModel.cols - keep track of the original for reset function

    originalSelectedColumns: {}[]; //ActionBarModel.selectedColumns  - keep track of the original for reset function

    columnReordered = false;



    constructor(public el: ElementRef, public domHandler: DomHandler, public objectUtils: ObjectUtils, public zone: NgZone, public tableService: TableService) {
    }

    ngOnInit() {
       if (this.stateKey && this.resetState) {
          this.clearState();
       }
       else if (this.isStateful() && !this.stateRestored) {
         this.restoreState();
       }

       if (!this.lazy && this.loadOnPage) {
         this.onPage.emit({
             first: this.first,
             rows: this.rows
         });
       }

        if (this.lazy && this.lazyLoadOnInit) {
            this.onLazyLoad.emit(this.createLazyLoadMetadata());
        }

        this.initialized = true;
    }

    ngAfterContentInit() {
        this.templates.forEach((item) => {
            switch (item.getType()) {
                case 'caption':
                    this.captionTemplate = item.template;
                    break;

                case 'header':
                    this.headerTemplate = item.template;
                    break;

                case 'body':
                    this.bodyTemplate = item.template;
                    break;

                case 'icon':
                    this.iconTemplate = item.template;
                    break;

                case 'footer':
                    this.footerTemplate = item.template;
                    break;

                case 'summary':
                    this.summaryTemplate = item.template;
                    break;

                case 'colgroup':
                    this.colGroupTemplate = item.template;
                    break;

                case 'rowexpansion':
                    this.expandedRowTemplate = item.template;
                    break;

                case 'frozenrows':
                    this.frozenRowsTemplate = item.template;
                    break;

                case 'frozenheader':
                    this.frozenHeaderTemplate = item.template;
                    break;

                case 'frozenbody':
                    this.frozenBodyTemplate = item.template;
                    break;

                case 'frozenfooter':
                    this.frozenFooterTemplate = item.template;
                    break;

                case 'frozencolgroup':
                    this.frozenColGroupTemplate = item.template;
                    break;

                case 'emptymessage':
                    this.emptyMessageTemplate = item.template;
                    break;

                case 'paginatorleft':
                    this.paginatorLeftTemplate = item.template;
                    break;

                case 'paginatorright':
                    this.paginatorRightTemplate = item.template;
                    break;
            }
        });

        if (this.actionBar) {
            // Determine initial enabled state
            this.updateActionBarState();
        }
    }

    ngAfterViewInit() {
        if (this.isStateful() && this.resizableColumns) {
            this.restoreColumnWidths();
        }
    }

    @Input() get value(): any[] {
        return this._value;
    }

    set value(val: any[]) {
        if (this.isStateful() && !this.stateRestored) {
            this.restoreState();
        }

        this._value = val;

        if (!this.lazy) {
            this.totalRecords = (this._value ? this._value.length : 0);

            if (this.sortMode == 'single' && this.sortField)
                this.sortSingle();
            else if (this.sortMode == 'multiple' && this.multiSortMeta)
                this.sortMultiple();
            else if (this.hasFilter())       //sort already filters
                this._filter();
        }

        if (this.virtualScroll && this.virtualScrollCallback) {
            this.virtualScrollCallback();
        }

        this.tableService.onValueChange(val);
    }

    @Input() get columns(): any[] {
        return this._columns;
    }

    set columns(cols: any[]) {
        if (!this.initialized && this.stateKey && this.resetState) {
          this.clearState();
        }

        this._columns = cols;
        this.tableService.onColumnsChange(cols);
    }

    @Input() get actionBar() {
        return this._actionBar;
    }

    set actionBar(model: ActionBarModel) {
        this._actionBar = model;
        this.updateActionBarState();
    }

    @Input() get search() {
        return this._search;
    }

    set search(model: ISearchModel) {
        this._search = model;
    }

    @Input() get icons(): any[] {
        return this._icons;
    }

    set icons(icons: any[]) {
        this._icons = icons;
    }

    @Input() get totalRecords(): number {
        return this._totalRecords;
    }

    set totalRecords(val: number) {
        this._totalRecords = val;
        this.tableService.onTotalRecordsChange(this._totalRecords);
    }

    @Input() get sortField(): string {
        return this._sortField;
    }

    set sortField(val: string) {
        this._sortField = val;

        //avoid triggering lazy load prior to lazy initialization at onInit
        if (!this.lazy || this.initialized) {
            if (this.sortMode === 'single') {
                this.sortSingle();
            }
        }
    }

    @Input() get sortOrder(): number {
        return this._sortOrder;
    }

    set sortOrder(val: number) {
        this._sortOrder = val;

        //avoid triggering lazy load prior to lazy initialization at onInit
        if (!this.lazy || this.initialized) {
            if (this.sortMode === 'single') {
                this.sortSingle();
            }
        }
    }

    @Input() get multiSortMeta(): SortMeta[] {
        return this._multiSortMeta;
    }

    set multiSortMeta(val: SortMeta[]) {
        this._multiSortMeta = val;
        if (this.sortMode === 'multiple') {
            this.sortMultiple();
        }
    }

    @Input() get selection(): any {
        return this._selection;
    }

    set selection(val: any) {
        this._selection = val;

        if (!this.preventSelectionSetterPropagation) {
            this.updateSelectionKeys();
            this.tableService.onSelectionChange();

            if (this.actionBar) {
                // Re-determine enabled state
                this.updateActionBarState();
            }
        }
        this.preventSelectionSetterPropagation = false;
    }

    updateSelectionKeys() {
        if (this.dataKey && this._selection) {
            this.selectionKeys = {};
            if (Array.isArray(this._selection)) {
                for (let data of this._selection) {
                    this.selectionKeys[String(this.objectUtils.resolveFieldData(data, this.dataKey))] = 1;
                }
            } else {
                this.selectionKeys[String(this.objectUtils.resolveFieldData(this._selection, this.dataKey))] = 1;
            }
        }
    }

    onPageChange(event) {
        this.first = event.first;
        this.rows = event.rows;

        if (this.lazy) {
            this.onLazyLoad.emit(this.createLazyLoadMetadata());
        }

        this.onPage.emit({
            first: this.first,
            rows: this.rows
        });

        this.tableService.onValueChange(this.value);

        if (this.isStateful()) {
            this.saveState();
        }
    }

    sort(event) {
        let originalEvent = event.originalEvent;

        if (this.sortMode === 'single') {
            this._sortOrder = (this.sortField === event.field) ? this.sortOrder * -1 : this.defaultSortOrder;
            this._sortField = event.field;
            this.sortSingle();
        }
        if (this.sortMode === 'multiple') {
            let metaKey = originalEvent.metaKey || originalEvent.ctrlKey;
            let sortMeta = this.getSortMeta(event.field);

            if (sortMeta) {
                if (!metaKey) {
                    this._multiSortMeta = [{ field: event.field, order: sortMeta.order * -1 }];
                } else {
                    sortMeta.order = sortMeta.order * -1;
                }
            } else {
                if (!metaKey || !this.multiSortMeta) {
                    this._multiSortMeta = [];
                }
                this.multiSortMeta.push({ field: event.field, order: this.defaultSortOrder });
            }

            this.sortMultiple();
        }

        if (this.isStateful()) {
            this.saveState();
        }
    }

    sortSingle() {
        if (this.sortField && this.sortOrder) {
            if (this.restoringSort)
                this.restoringSort = false;
            else if (this.resetPageOnSort)
                this.first = 0;

            if (this.lazy) {
                this.onLazyLoad.emit(this.createLazyLoadMetadata());
            } else if (this.value) {
                if (this.customSort) {
                    this.sortFunction.emit({
                        data: this.value,
                        mode: this.sortMode,
                        field: this.sortField,
                        order: this.sortOrder
                    });
                } else {
                    this.value.sort((data1, data2) => {
                        let value1 = this.objectUtils.resolveFieldData(data1, this.sortField);
                        let value2 = this.objectUtils.resolveFieldData(data2, this.sortField);
                        let result = null;

                        if (value1 == null && value2 != null)
                            result = -1;
                        else if (value1 != null && value2 == null)
                            result = 1;
                        else if (value1 == null && value2 == null)
                            result = 0;
                        else if (typeof value1 === 'string' && typeof value2 === 'string')
                            result = value1.localeCompare(value2);
                        else
                            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

                        return (this.sortOrder * result);
                    });
                }

                if (this.hasFilter()) {
                    this._filter();
                }
            }

            let sortMeta: SortMeta = {
                field: this.sortField,
                order: this.sortOrder
            };

            this.onSort.emit(sortMeta);
            this.tableService.onSort(sortMeta);
        }
    }

    sortMultiple() {
        if (this.multiSortMeta) {
            if (this.lazy) {
                this.onLazyLoad.emit(this.createLazyLoadMetadata());
            } else if (this.value) {
                if (this.customSort) {
                    this.sortFunction.emit({
                        data: this.value,
                        mode: this.sortMode,
                        multiSortMeta: this.multiSortMeta
                    });
                } else {
                    this.value.sort((data1, data2) => {
                        return this.multisortField(data1, data2, this.multiSortMeta, 0);
                    });
                }

                if (this.hasFilter()) {
                    this._filter();
                }
            }

            this.onSort.emit({
                multisortmeta: this.multiSortMeta
            });
            this.tableService.onSort(this.multiSortMeta);
        }
    }

    multisortField(data1, data2, multiSortMeta, index) {
        let value1 = this.objectUtils.resolveFieldData(data1, multiSortMeta[index].field);
        let value2 = this.objectUtils.resolveFieldData(data2, multiSortMeta[index].field);
        let result = null;

        if (value1 == null && value2 != null)
            result = -1;
        else if (value1 != null && value2 == null)
            result = 1;
        else if (value1 == null && value2 == null)
            result = 0;
        if (typeof value1 == 'string' || value1 instanceof String) {
            if (value1.localeCompare && (value1 != value2)) {
                return (multiSortMeta[index].order * value1.localeCompare(value2));
            }
        } else {
            result = (value1 < value2) ? -1 : 1;
        }

        if (value1 == value2) {
            return (multiSortMeta.length - 1) > (index) ? (this.multisortField(data1, data2, multiSortMeta, index + 1)) : 0;
        }

        return (multiSortMeta[index].order * result);
    }

    getSortMeta(field: string) {
        if (this.multiSortMeta && this.multiSortMeta.length) {
            for (let i = 0; i < this.multiSortMeta.length; i++) {
                if (this.multiSortMeta[i].field === field) {
                    return this.multiSortMeta[i];
                }
            }
        }

        return null;
    }

    isSorted(field: string) {
        if (this.sortMode === 'single') {
            return (this.sortField && this.sortField === field);
        } else if (this.sortMode === 'multiple') {
            let sorted = false;
            if (this.multiSortMeta) {
                for (let i = 0; i < this.multiSortMeta.length; i++) {
                    if (this.multiSortMeta[i].field == field) {
                        sorted = true;
                        break;
                    }
                }
            }
            return sorted;
        }
    }

    updateActionBarStateImpl(menu: MenuItem[], count: number) {
        for (let item of menu) {
            if ((<SelectionMenuItem>item).selectionMode) {
                switch ((<SelectionMenuItem>item).selectionMode) {
                    case MenuItemSelectionMode.None:
                        item.disabled = count !== 0;
                        item.visible  = !(count !== 0);
                        break;
                    case MenuItemSelectionMode.One:
                        item.disabled = count !== 1;
                        item.visible  = !(count !== 1);
                        break;
                    case MenuItemSelectionMode.OneOrMore:
                        item.disabled = count < 1;
                        item.visible  = !(count < 1);
                        break;
                    case MenuItemSelectionMode.TwoOrMore:
                        item.disabled = count < 2;
                        item.visible  = !(count < 2);
                        break;
                }
            }

            if (item.items) {
                // Avoid this error by casting to MenuItem[]:
                // TS2345: Argument of type 'MenuItem[] | MenuItem[][]' is not assignable to parameter of type 'MenuItem[]'.
                this.updateActionBarStateImpl(<MenuItem[]>item.items, count);
            }
        }
    }

    updateActionBarState() {
        if (typeof this.actionBar !== 'object' ) {
            return;
        }

        let count = this._selection ? this._selection.length : 0;

        if (this.actionBar.items && this.actionBar.items.length) {
          this.updateActionBarStateImpl(this.actionBar.items, count);
        }

        const storage = this.getStorage();
        const stateString = storage.getItem(this.stateKey);
        let state: TableState = JSON.parse(stateString);

        // As the implementation code is using [columns]="actionBar.columnToggle.selectedColumns"
        // we need to add setTimout to wait for the change to finish. (otherwise error about change in progress.)
        setTimeout(() => {
          this.restoreColumnState();
          this.restoreColumnWidths();
        })

    }

    handleRowClick(event) {
        let target = (<HTMLElement>event.originalEvent.target);
        let targetNode = target.nodeName;
        let parentNode = target.parentElement.nodeName;
        if (targetNode == 'INPUT' || targetNode == 'BUTTON' || targetNode == 'A' ||
            parentNode == 'INPUT' || parentNode == 'BUTTON' || parentNode == 'A' ||
            ( this.domHandler.hasClass(event.originalEvent.target, 'ui-clickable') && !this.domHandler.hasClass(event.originalEvent.target, 'ui-chkbox-icon') ) ) {
            return;
        }

        if (event.clickType === 'active' && this.enableActiveRow && this.dataKey && event.rowData && event.rowData[this.dataKey]) {
            this.activeRowId = event.rowData[this.dataKey];
            this.activeRowIdChange.emit(this.activeRowId);
            this.onRowActive.emit({
                originalEvent: event.originalEvent,
                data: event.rowData,
                type: 'row',
                index: event.rowIndex
            });
            return;
        }

        if (this.selectionMode && (!this.enableActiveRow || event.clickType === 'checkbox')) {
            this.preventSelectionSetterPropagation = true;
            if (this.isMultipleSelectionMode() && event.originalEvent.shiftKey && this.anchorRowIndex != null) {
                this.domHandler.clearSelection();
                if (this.rangeRowIndex != null) {
                    this.clearSelectionRange(event.originalEvent);
                }

                this.rangeRowIndex = event.rowIndex;
                this.selectRange(event.originalEvent, event.rowIndex);
            } else {
                let rowData = event.rowData;
                let selected = this.isSelected(rowData);
                let metaSelection = this.rowTouched ? false : this.metaKeySelection;
                let dataKeyValue = this.dataKey ? String(this.objectUtils.resolveFieldData(rowData, this.dataKey)) : null;
                this.anchorRowIndex = event.rowIndex;
                this.rangeRowIndex = event.rowIndex;

                if (metaSelection) {
                    let metaKey = event.originalEvent.metaKey || event.originalEvent.ctrlKey;

                    if (selected && metaKey) {
                        if (this.isSingleSelectionMode()) {
                            this._selection = null;
                            this.selectionKeys = {};
                            this.selectionChange.emit(null);
                        } else {
                            let selectionIndex = this.findIndexInSelection(rowData);
                            this._selection = this.selection.filter((val, i) => i != selectionIndex);
                            this.selectionChange.emit(this.selection);
                            if (dataKeyValue) {
                                delete this.selectionKeys[dataKeyValue];
                            }
                        }

                        this.onRowUnselect.emit({
                            originalEvent: event.originalEvent,
                            data: rowData,
                            type: 'row'
                        });
                    } else {
                        if (this.isSingleSelectionMode()) {
                            this._selection = rowData;
                            this.selectionChange.emit(rowData);
                            if (dataKeyValue) {
                                this.selectionKeys = {};
                                this.selectionKeys[dataKeyValue] = 1;
                            }
                        } else if (this.isMultipleSelectionMode()) {
                            if (metaKey) {
                                this._selection = this.selection || [];
                            } else {
                                this._selection = [];
                                this.selectionKeys = {};
                            }

                            this._selection = [...this.selection, rowData];
                            this.selectionChange.emit(this.selection);
                            if (dataKeyValue) {
                                this.selectionKeys[dataKeyValue] = 1;
                            }
                        }

                        this.onRowSelect.emit({
                            originalEvent: event.originalEvent,
                            data: rowData,
                            type: 'row',
                            index: event.rowIndex
                        });
                    }
                } else {
                    if (this.selectionMode === 'single') {
                        if (selected) {
                            this._selection = null;
                            this.selectionKeys = {};
                            this.selectionChange.emit(this.selection);
                            this.onRowUnselect.emit({
                                originalEvent: event.originalEvent,
                                data: rowData,
                                type: 'row'
                            });
                        } else {
                            this._selection = rowData;
                            this.selectionChange.emit(this.selection);
                            this.onRowSelect.emit({
                                originalEvent: event.originalEvent,
                                data: rowData,
                                type: 'row',
                                index: event.rowIndex
                            });
                            if (dataKeyValue) {
                                this.selectionKeys = {};
                                this.selectionKeys[dataKeyValue] = 1;
                            }
                        }
                    } else if (this.selectionMode === 'multiple') {
                        if (selected) {
                            let selectionIndex = this.findIndexInSelection(rowData);
                            this._selection = this.selection.filter((val, i) => i != selectionIndex);
                            this.selectionChange.emit(this.selection);
                            this.onRowUnselect.emit({
                                originalEvent: event.originalEvent,
                                data: rowData,
                                type: 'row'
                            });
                            if (dataKeyValue) {
                                delete this.selectionKeys[dataKeyValue];
                            }
                        } else {
                            this._selection = this.selection ? [...this.selection, rowData] : [rowData];
                            this.selectionChange.emit(this.selection);
                            this.onRowSelect.emit({
                                originalEvent: event.originalEvent,
                                data: rowData,
                                type: 'row',
                                index: event.rowIndex
                            });
                            if (dataKeyValue) {
                                this.selectionKeys[dataKeyValue] = 1;
                            }
                        }
                    }
                }
            }

            this.tableService.onSelectionChange();

            if (this.actionBar) {
                // Re-determine enabled state
                this.updateActionBarState();
            }

            if (this.isStateful()) {
                this.saveState();
            }
        }

        this.rowTouched = false;
    }

    handleRowTouchEnd(event) {
        this.rowTouched = true;
    }

    handleRowRightClick(event) {
        if (this.contextMenu) {
            const rowData = event.rowData;

            if (this.contextMenuSelectionMode === 'separate') {
                this.contextMenuSelection = rowData;
                this.contextMenuSelectionChange.emit(rowData);
                this.onContextMenuSelect.emit({
                    originalEvent: event.originalEvent,
                    data: rowData
                });
                this.contextMenu.show(event.originalEvent);
                this.tableService.onContextMenu(rowData);
            } else if (this.contextMenuSelectionMode === 'joint') {
                this.preventSelectionSetterPropagation = true;
                let selected = this.isSelected(rowData);
                let dataKeyValue = this.dataKey ? String(this.objectUtils.resolveFieldData(rowData, this.dataKey)) : null;

                if (!selected) {
                    if (this.isSingleSelectionMode()) {
                        this.selection = rowData;
                        this.selectionChange.emit(rowData);
                    } else if (this.isMultipleSelectionMode()) {
                        this.selection = [rowData];
                        this.selectionChange.emit(this.selection);
                    }

                    if (dataKeyValue) {
                        this.selectionKeys[dataKeyValue] = 1;
                    }
                }

                this.contextMenu.show(event.originalEvent);
                this.onContextMenuSelect.emit({ originalEvent: event, data: rowData });
            }
        }
    }

    selectRange(event: MouseEvent, rowIndex: number) {
        let rangeStart, rangeEnd;

        if (this.anchorRowIndex > rowIndex) {
            rangeStart = rowIndex;
            rangeEnd = this.anchorRowIndex;
        } else if (this.anchorRowIndex < rowIndex) {
            rangeStart = this.anchorRowIndex;
            rangeEnd = rowIndex;
        } else {
            rangeStart = rowIndex;
            rangeEnd = rowIndex;
        }

        for (let i = rangeStart; i <= rangeEnd; i++) {
            let rangeRowData = this.filteredValue ? this.filteredValue[i] : this.value[i];
            if (!this.isSelected(rangeRowData)) {
                this._selection = [...this.selection, rangeRowData];
                let dataKeyValue: string = this.dataKey ? String(this.objectUtils.resolveFieldData(rangeRowData, this.dataKey)) : null;
                if (dataKeyValue) {
                    this.selectionKeys[dataKeyValue] = 1;
                }
                this.onRowSelect.emit({ originalEvent: event, data: rangeRowData, type: 'row' });
            }
        }

        this.selectionChange.emit(this.selection);
    }

    clearSelectionRange(event: MouseEvent) {
        let rangeStart, rangeEnd;

        if (this.rangeRowIndex > this.anchorRowIndex) {
            rangeStart = this.anchorRowIndex;
            rangeEnd = this.rangeRowIndex;
        } else if (this.rangeRowIndex < this.anchorRowIndex) {
            rangeStart = this.rangeRowIndex;
            rangeEnd = this.anchorRowIndex;
        } else {
            rangeStart = this.rangeRowIndex;
            rangeEnd = this.rangeRowIndex;
        }

        for (let i = rangeStart; i <= rangeEnd; i++) {
            let rangeRowData = this.value[i];
            let selectionIndex = this.findIndexInSelection(rangeRowData);
            this._selection = this.selection.filter((val, i) => i != selectionIndex);
            let dataKeyValue: string = this.dataKey ? String(this.objectUtils.resolveFieldData(rangeRowData, this.dataKey)) : null;
            if (dataKeyValue) {
                delete this.selectionKeys[dataKeyValue];
            }
            this.onRowUnselect.emit({ originalEvent: event, data: rangeRowData, type: 'row' });
        }
    }

    isSelected(rowData) {
        if (rowData && this.selection) {
            if (this.dataKey) {
                return this.selectionKeys[this.objectUtils.resolveFieldData(rowData, this.dataKey)] !== undefined;
            } else {
                if (this.selection instanceof Array)
                    return this.findIndexInSelection(rowData) > -1;
                else
                    return this.equals(rowData, this.selection);
            }
        }

        return false;
    }

    findIndexInSelection(rowData: any) {
        let index: number = -1;
        if (this.selection && this.selection.length) {
            for (let i = 0; i < this.selection.length; i++) {
                if (this.equals(rowData, this.selection[i])) {
                    index = i;
                    break;
                }
            }
        }

        return index;
    }

    toggleRowWithRadio(event: any, rowData: any) {
        this.preventSelectionSetterPropagation = true;

        if (this.selection != rowData) {
            this._selection = rowData;
            this.selectionChange.emit(this.selection);
            this.onRowSelect.emit({
                originalEvent: event.originalEvent,
                index: event.rowIndex,
                data: rowData,
                type: 'radiobutton'
            });

            if (this.dataKey) {
                this.selectionKeys = {};
                this.selectionKeys[String(this.objectUtils.resolveFieldData(rowData, this.dataKey))] = 1;
            }
        } else {
            this._selection = null;
            this.selectionChange.emit(this.selection);
            this.onRowUnselect.emit({
                originalEvent: event.originalEvent,
                index: event.rowIndex,
                data: rowData,
                type: 'radiobutton'
            });
        }

        this.tableService.onSelectionChange();

        if (this.actionBar) {
            // Re-determine enabled state
            this.updateActionBarState();
        }

        if (this.isStateful()) {
            this.saveState();
        }
    }

    toggleRowWithCheckbox(event, rowData: any) {

        this.selection = this.selection || [];
        let selected = this.isSelected(rowData);
        let dataKeyValue = this.dataKey ? String(this.objectUtils.resolveFieldData(rowData, this.dataKey)) : null;
        this.preventSelectionSetterPropagation = true;

        if (selected) {
            let selectionIndex = this.findIndexInSelection(rowData);
            this._selection = this.selection.filter((val, i) => i != selectionIndex);
            this.selectionChange.emit(this.selection);
            this.onRowUnselect.emit({
                originalEvent: event.originalEvent,
                index: event.rowIndex,
                data: rowData,
                type: 'checkbox'
            });
            if (dataKeyValue) {
                delete this.selectionKeys[dataKeyValue];
            }
        } else {
            this._selection = this.selection ? [...this.selection, rowData] : [rowData];
            this.selectionChange.emit(this.selection);
            this.onRowSelect.emit({
                originalEvent: event.originalEvent,
                index: event.rowIndex,
                data: rowData,
                type: 'checkbox'
            });
            if (dataKeyValue) {
                this.selectionKeys[dataKeyValue] = 1;
            }
        }

        this.tableService.onSelectionChange();

        if (this.actionBar) {
            // Re-determine enabled state
            this.updateActionBarState();
        }

        if (this.isStateful()) {
            this.saveState();
        }
    }

    toggleRowsWithCheckbox(event: Event, check: boolean) {
        this._selection = check ? this.filteredValue ? this.filteredValue.slice() : this.value.slice() : [];
        this.preventSelectionSetterPropagation = true;
        this.updateSelectionKeys();
        this.selectionChange.emit(this._selection);
        this.tableService.onSelectionChange();
        this.onHeaderCheckboxToggle.emit({ originalEvent: event, checked: check });

        if (this.actionBar) {
            // Re-determine enabled state
            this.updateActionBarState();
        }

        if (this.isStateful()) {
            this.saveState();
        }
    }

    equals(data1, data2) {
        return this.compareSelectionBy === 'equals' ? (data1 === data2) : this.objectUtils.equals(data1, data2, this.dataKey);
    }

    filter(value, field, matchMode) {
        if (this.filterTimeout) {
            clearTimeout(this.filterTimeout);
        }

        if (!this.isFilterBlank(value)) {
            this.filters[field] = { value: value, matchMode: matchMode };
        } else if (this.filters[field]) {
            delete this.filters[field];
        }

        this.filterTimeout = setTimeout(() => {
            this._filter();
            this.filterTimeout = null;
        }, this.filterDelay);
    }

    filterGlobal(value, matchMode) {
        this.filter(value, 'global', matchMode);
    }

    isFilterBlank(filter: any): boolean {
        if (filter !== null && filter !== undefined) {
            if ((typeof filter === 'string' && filter.trim().length == 0) || (filter instanceof Array && filter.length == 0))
                return true;
            else
                return false;
        }
        return true;
    }

    _filter() {
        if (this.lazy) {
            this.onLazyLoad.emit(this.createLazyLoadMetadata());
        } else {
            if (!this.value) {
                return;
            }

            if (!this.hasFilter()) {
                this.filteredValue = null;
                if (this.paginator) {
                    this.totalRecords = this.value ? this.value.length : 0;
                }
            } else {
                let globalFilterFieldsArray;
                if (this.filters['global']) {
                    if (!this.columns && !this.globalFilterFields)
                        throw new Error('Global filtering requires dynamic columns or globalFilterFields to be defined.');
                    else
                        globalFilterFieldsArray = this.globalFilterFields || this.columns;
                }

                this.filteredValue = [];

                for (let i = 0; i < this.value.length; i++) {
                    let localMatch = true;
                    let globalMatch = false;
                    let localFiltered = false;

                    for (let prop in this.filters) {
                        if (this.filters.hasOwnProperty(prop) && prop !== 'global') {
                            localFiltered = true;
                            let filterMeta = this.filters[prop];
                            let filterField = prop;
                            let filterValue = filterMeta.value;
                            let filterMatchMode = filterMeta.matchMode || 'startsWith';
                            let dataFieldValue = this.objectUtils.resolveFieldData(this.value[i], filterField);
                            let filterConstraint = this.filterConstraints[filterMatchMode];

                            if (!filterConstraint(dataFieldValue, filterValue)) {
                                localMatch = false;
                            }

                            if (!localMatch) {
                                break;
                            }
                        }
                    }

                    if (this.filters['global'] && !globalMatch && globalFilterFieldsArray) {
                        for (let j = 0; j < globalFilterFieldsArray.length; j++) {
                            let globalFilterField = globalFilterFieldsArray[j].field || globalFilterFieldsArray[j];
                            globalMatch = this.filterConstraints[this.filters['global'].matchMode](this.objectUtils.resolveFieldData(this.value[i], globalFilterField), this.filters['global'].value);

                            if (globalMatch) {
                                break;
                            }
                        }
                    }

                    let matches: boolean;
                    if (this.filters['global']) {
                        matches = localFiltered ? (localFiltered && localMatch && globalMatch) : globalMatch;
                    } else {
                        matches = localFiltered && localMatch;
                    }

                    if (matches) {
                        this.filteredValue.push(this.value[i]);
                    }
                }

                if (this.filteredValue.length === this.value.length) {
                    this.filteredValue = null;
                }

                if (this.paginator) {
                    this.totalRecords = this.filteredValue ? this.filteredValue.length : this.value ? this.value.length : 0;
                }
            }
        }

        this.onFilter.emit({
            filters: this.filters,
            filteredValue: this.filteredValue || this.value
        });

        this.tableService.onValueChange(this.value);

        if (this.isStateful() && !this.restoringFilter) {
            this.saveState();
        }

        if (this.restoringFilter)
            this.restoringFilter = false;
        else
            this.first = 0;

    }

    hasFilter() {
        let empty = true;
        for (let prop in this.filters) {
            if (this.filters.hasOwnProperty(prop)) {
                empty = false;
                break;
            }
        }

        return !empty;
    }

    onColumnReorder (obj) {
      if (this.isStateful()) {
        setTimeout(() => {
          this.saveState();
        }, 100); //wait for the UI to change so column header is updated
      }
    }

  onActionBarColumnSelectionChange (obj) {
      // // update column width to accomodate
      // Not used, but keeping this in case we need to adjust column width...
      // TODO: delete after February 28th, if this is not needed.
      // let columnWidthsArr = this.columnWidthsState.split(',');
      // if (obj.value.length !== columnWidthsArr.length) {
      //   let dataKey = this.dataKey || 'field';
      //   //find where the new item was added
      //   let dataKeyValue = String(this.objectUtils.resolveFieldData(obj.itemValue, dataKey));
      //   if (obj.value.length > columnWidthsArr.length) {
      //     const indexElem = (val) => val[dataKey] === dataKeyValue;
      //     console.log(obj.value.findIndex(indexElem));
      //     let newColumnIndex = obj.value.findIndex(indexElem);
      //     console.log(columnWidthsArr);
      //     //add default width for this new item
      //     let newColumnWidth = 100;
      //       // recalculate the other columnWidth
      //     let adjustmentWidth = Math.round(newColumnWidth/columnWidthsArr.length);
      //     let adjustedColumnWidthArr = columnWidthsArr.map((c) => {
      //       return parseInt(c) - adjustmentWidth;
      //     });
      //
      //     adjustedColumnWidthArr.splice(newColumnIndex, 0, newColumnWidth);
      //     this.columnWidthsState = adjustedColumnWidthArr.join(',');
      //     this.restoreColumnWidths();
      //     //update HTML header width with the new columnWidth
      //   }
      //   else {
      //     const indexElem = (val) => val[dataKey] === dataKeyValue;
      //     console.log(obj.value.findIndex(indexElem));
      //     let oldColumnIndex = obj.originalValue.findIndex(indexElem);
      //     console.log(columnWidthsArr);
      //     //add default width for this new item
      //     let oldColumnWidth = parseInt(columnWidthsArr[oldColumnIndex]);
      //       // recalculate the other columnWidth
      //     let adjustmentWidth = Math.round(oldColumnWidth/(columnWidthsArr.length - 1));
      //     columnWidthsArr.splice(oldColumnWidth, 1);
      //
      //     let adjustedColumnWidthArr = columnWidthsArr.map((c) => {
      //       return parseInt(c) - adjustmentWidth;
      //     });
      //
      //     this.columnWidthsState = adjustedColumnWidthArr.join(',');
      //
      //   }
      // }

      this.onColumnSelectionChange.emit(obj);

      if (this.isStateful()) {
        setTimeout(() => {
          this.resetColumnWidths();
          // this.restoreColumnWidths();
          this.saveState();
        }, 100);
      }
    }

    filterConstraints = {

        startsWith(value, filter): boolean {
            if (filter === undefined || filter === null || filter.trim() === '') {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            let filterValue = filter.toLowerCase();
            return value.toString().toLowerCase().slice(0, filterValue.length) === filterValue;
        },

        contains(value, filter): boolean {
            if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            return value.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1;
        },

        endsWith(value, filter): boolean {
            if (filter === undefined || filter === null || filter.trim() === '') {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            let filterValue = filter.toString().toLowerCase();
            return value.toString().toLowerCase().indexOf(filterValue, value.toString().length - filterValue.length) !== -1;
        },

        equals(value, filter): boolean {
            if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            if (value.getTime && filter.getTime)
                return value.getTime() === filter.getTime();
            else
                return value.toString().toLowerCase() == filter.toString().toLowerCase();
        },

        notEquals(value, filter): boolean {
            if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
              return false;
            }

            if (value === undefined || value === null) {
                return true;
            }

            if (value.getTime && filter.getTime)
                return value.getTime() !== filter.getTime();
            else
                return value.toString().toLowerCase() != filter.toString().toLowerCase();
        },

        in(value, filter: any[]): boolean {
            if (filter === undefined || filter === null || filter.length === 0) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            for (let i = 0; i < filter.length; i++) {
                if (filter[i] === value || (value.getTime && filter[i].getTime && value.getTime() === filter[i].getTime())) {
                    return true;
                }
            }

            return false;
        },

        lt(value, filter): boolean {
            if (filter === undefined || filter === null) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            if (value.getTime && filter.getTime)
                return value.getTime() < filter.getTime();
            else
                return value < filter;
        },

        lte(value, filter): boolean {
            if (filter === undefined || filter === null) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            if (value.getTime && filter.getTime)
                return value.getTime() <= filter.getTime();
            else
                return value <= filter;
        },

        gt(value, filter): boolean {
            if (filter === undefined || filter === null) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            if (value.getTime && filter.getTime)
                return value.getTime() > filter.getTime();
            else
                return value > filter;
        },

        gte(value, filter): boolean {
            if (filter === undefined || filter === null) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            if (value.getTime && filter.getTime)
                return value.getTime() >= filter.getTime();
            else
                return value >= filter;
        }
    };

    createLazyLoadMetadata(): any {
        return {
            first: this.first,
            rows: this.virtualScroll ? this.rows * 2 : this.rows,
            sortField: this.sortField,
            sortOrder: this.sortOrder,
            filters: this.filters,
            globalFilter: this.filters && this.filters['global'] ? this.filters['global'].value : null,
            multiSortMeta: this.multiSortMeta,
            selectedColumns: this.actionBar && this.actionBar.columnToggle ? this.actionBar.columnToggle.selectedColumns : '',
            reorderedColumns: this.actionBar && this.actionBar.columnToggle ? this.actionBar.columnToggle.cols : ''
        };
    }

    public reset() {
        this.actionBar.columnToggle.selectedColumns = this.originalSelectedColumns;
        this.actionBar.columnToggle.cols = this.originalCols;
        this._sortField = null;
        this._sortOrder = this.defaultSortOrder;
        this._multiSortMeta = null;
        this.tableService.onSort(null);

        this.filteredValue = null;
        this.filters = {};

        this.first = 0;
        this.saveState();

        if (this.lazy) {
            this.onLazyLoad.emit(this.createLazyLoadMetadata());
        } else {
            this.totalRecords = (this._value ? this._value.length : 0);
        }
    }

//TODO: Delete this.
// As we have our own export logic
// Deleted example for reference.
// <p-table #dt [columns]="cols" [value]="cars" selectionMode="multiple" [(selection)]="selectedCars">
//     <ng-template pTemplate="caption">
//         <div class="ui-helper-clearfix">
//             <button type="button" symButton icon="fa fa-file-o" iconPos="left" label="All Data" (click)="dt.exportCSV()" style="float:left"></button>
//             <button type="button" symButton icon="fa fa-file" iconPos="left" label="Selection Only" (click)="dt.exportCSV({selectionOnly:true})" style="float:right"></button>
//         </div>
//     </ng-template>

    // public exportCSV(options?: any) {
    //     let data = this.filteredValue || this.value;
    //     let csv = '\ufeff';
    //
    //     if (options && options.selectionOnly) {
    //         data = this.selection || [];
    //     }
    //
    //     //headers
    //     for (let i = 0; i < this.columns.length; i++) {
    //         let column = this.columns[i];
    //         if (column.exportable !== false && column.field) {
    //             csv += '"' + (column.header || column.field) + '"';
    //
    //             if (i < (this.columns.length - 1)) {
    //                 csv += this.csvSeparator;
    //             }
    //         }
    //     }
    //
    //     //body
    //     data.forEach((record, i) => {
    //         csv += '\n';
    //         for (let i = 0; i < this.columns.length; i++) {
    //             let column = this.columns[i];
    //             if (column.exportable !== false && column.field) {
    //                 let cellData = this.objectUtils.resolveFieldData(record, column.field);
    //
    //                 if (cellData != null) {
    //                     if (this.exportFunction) {
    //                         cellData = this.exportFunction({
    //                             data: cellData,
    //                             field: column.field
    //                         });
    //                     } else
    //                         cellData = String(cellData).replace(/"/g, '""');
    //                 } else
    //                     cellData = '';
    //
    //
    //                 csv += '"' + cellData + '"';
    //
    //                 if (i < (this.columns.length - 1)) {
    //                     csv += this.csvSeparator;
    //                 }
    //             }
    //         }
    //     });
    //
    //     let blob = new Blob([csv], {
    //         type: 'text/csv;charset=utf-8;'
    //     });
    //
    //     if (window.navigator.msSaveOrOpenBlob) {
    //         navigator.msSaveOrOpenBlob(blob, this.exportFilename + '.csv');
    //     } else {
    //         let link = document.createElement('a');
    //         link.style.display = 'none';
    //         document.body.appendChild(link);
    //         if (link.download !== undefined) {
    //             link.setAttribute('href', URL.createObjectURL(blob));
    //             link.setAttribute('download', this.exportFilename + '.csv');
    //             link.click();
    //         } else {
    //             csv = 'data:text/csv;charset=utf-8,' + csv;
    //             window.open(encodeURI(csv));
    //         }
    //         document.body.removeChild(link);
    //     }
    // }

    toggleRow(rowData: any, event?: Event) {
        if (!this.dataKey) {
            throw new Error('dataKey must be defined to use row expansion');
        }

        let dataKeyValue = String(this.objectUtils.resolveFieldData(rowData, this.dataKey));

        if (this.expandedRowKeys[dataKeyValue] != null) {
            delete this.expandedRowKeys[dataKeyValue];
            this.onRowCollapse.emit({
                originalEvent: event,
                data: rowData
            });
        } else {
            if (this.rowExpandMode === 'single') {
                this.expandedRowKeys = {};
            }

            this.expandedRowKeys[dataKeyValue] = 1;
            this.onRowExpand.emit({
                originalEvent: event,
                data: rowData
            });
        }

        if (event) {
            event.preventDefault();
        }

        if (this.isStateful()) {
            this.saveState();
        }
    }

    isRowExpanded(rowData: any): boolean {
        return this.expandedRowKeys[String(this.objectUtils.resolveFieldData(rowData, this.dataKey))] === 1;
    }

    isSingleSelectionMode() {
        return this.selectionMode === 'single';
    }

    isMultipleSelectionMode() {
        return this.selectionMode === 'multiple';
    }

    onColumnResizeBegin(event) {
        let containerLeft = this.domHandler.getOffset(this.containerViewChild.nativeElement).left;
        this.lastResizerHelperX = (event.pageX - containerLeft + this.containerViewChild.nativeElement.scrollLeft);
        event.preventDefault();
    }

    onColumnResize(event) {
        let containerLeft = this.domHandler.getOffset(this.containerViewChild.nativeElement).left;
        this.domHandler.addClass(this.containerViewChild.nativeElement, 'ui-unselectable-text');
        this.resizeHelperViewChild.nativeElement.style.height = this.containerViewChild.nativeElement.offsetHeight + 'px';
        this.resizeHelperViewChild.nativeElement.style.top = 0 + 'px';
        this.resizeHelperViewChild.nativeElement.style.left = (event.pageX - containerLeft + this.containerViewChild.nativeElement.scrollLeft) + 'px';

        this.resizeHelperViewChild.nativeElement.style.display = 'block';
    }

    onColumnResizeEnd(event, column) {
        let delta = this.resizeHelperViewChild.nativeElement.offsetLeft - this.lastResizerHelperX;
        let columnWidth = column.offsetWidth;
        let minWidth = parseInt(column.style.minWidth || 15);

        if (columnWidth + delta < minWidth) {
            delta = minWidth - columnWidth;
        }

        const newColumnWidth = columnWidth + delta;

        if (newColumnWidth >= minWidth) {
            let scrollableView, scrollableBodyTable, scrollableHeaderTable, scrollableFooterTable, resizeColumnIndex;

            if (this.columnResizeMode === 'fit') {
                let nextColumn = column.nextElementSibling;
                while (!nextColumn.offsetParent) {
                    nextColumn = nextColumn.nextElementSibling;
                }

                if (nextColumn) {
                    let nextColumnWidth = nextColumn.offsetWidth - delta;
                    let nextColumnMinWidth = nextColumn.style.minWidth || 15;

                    if (newColumnWidth > 15 && nextColumnWidth > parseInt(nextColumnMinWidth)) {
                        if (this.scrollable) {
                            scrollableView = this.findParentScrollableView(column);
                            scrollableBodyTable = this.domHandler.findSingle(scrollableView, 'table.ui-table-scrollable-body-table');
                            scrollableHeaderTable = this.domHandler.findSingle(scrollableView, 'table.ui-table-scrollable-header-table');
                            scrollableFooterTable = this.domHandler.findSingle(scrollableView, 'table.ui-table-scrollable-footer-table');
                            resizeColumnIndex = this.domHandler.index(column);

                            this.resizeColGroup(scrollableHeaderTable, resizeColumnIndex, newColumnWidth, nextColumnWidth);
                            this.resizeColGroup(scrollableBodyTable, resizeColumnIndex, newColumnWidth, nextColumnWidth);
                            this.resizeColGroup(scrollableFooterTable, resizeColumnIndex, newColumnWidth, nextColumnWidth);
                        } else {
                            column.style.width = newColumnWidth + 'px';
                            if (nextColumn) {
                                nextColumn.style.width = nextColumnWidth + 'px';
                            }
                        }
                    }
                }
            } else if (this.columnResizeMode === 'expand') {
                if (this.scrollable) {
                    scrollableView = this.findParentScrollableView(column);
                    scrollableBodyTable = this.domHandler.findSingle(scrollableView, 'table.ui-table-scrollable-body-table');
                    scrollableHeaderTable = this.domHandler.findSingle(scrollableView, 'table.ui-table-scrollable-header-table');
                    scrollableFooterTable = this.domHandler.findSingle(scrollableView, 'table.ui-table-scrollable-footer-table');
                    scrollableBodyTable.style.width = scrollableBodyTable.offsetWidth + delta + 'px';
                    scrollableHeaderTable.style.width = scrollableHeaderTable.offsetWidth + delta + 'px';
                    if (scrollableFooterTable) {
                        scrollableFooterTable.style.width = scrollableHeaderTable.offsetWidth + delta + 'px';
                    }
                    resizeColumnIndex = this.domHandler.index(column);

                    this.resizeColGroup(scrollableHeaderTable, resizeColumnIndex, newColumnWidth, null);
                    this.resizeColGroup(scrollableBodyTable, resizeColumnIndex, newColumnWidth, null);
                    this.resizeColGroup(scrollableFooterTable, resizeColumnIndex, newColumnWidth, null);
                } else {
                    this.tableViewChild.nativeElement.style.width = this.tableViewChild.nativeElement.offsetWidth + delta + 'px';
                    column.style.width = newColumnWidth + 'px';
                    let containerWidth = this.tableViewChild.nativeElement.style.width;
                    this.containerViewChild.nativeElement.style.width = containerWidth + 'px';
                }
            }

            this.onColResize.emit({
                element: column,
                delta: delta
            });

            if (this.isStateful()) {
              this.saveState();
            }
        }

        this.resizeHelperViewChild.nativeElement.style.display = 'none';
        this.domHandler.removeClass(this.containerViewChild.nativeElement, 'ui-unselectable-text');
    }

    findParentScrollableView(column) {
        if (column) {
            let parent = column.parentElement;
            while (parent && !this.domHandler.hasClass(parent, 'ui-table-scrollable-view')) {
                parent = parent.parentElement;
            }

            return parent;
        } else {
            return null;
        }
    }

    resizeColGroup(table, resizeColumnIndex, newColumnWidth, nextColumnWidth) {
        if (table) {
            let colGroup = table.children[0].nodeName === 'COLGROUP' ? table.children[0] : null;

            if (colGroup) {
                let col = colGroup.children[resizeColumnIndex];
                let nextCol = col.nextElementSibling;
                col.style.width = newColumnWidth + 'px';

                if (nextCol && nextColumnWidth) {
                    nextCol.style.width = nextColumnWidth + 'px';
                }
            } else {
                throw 'Scrollable tables require a colgroup to support resizable columns';
            }
        }
    }

    onRowDragStart(event, index) {
        this.rowDragging = true;
        this.draggedRowIndex = index;
        event.dataTransfer.setData('text', 'b');    // For firefox
    }

    onRowDragOver(event, index, rowElement) {
        if (this.rowDragging && this.draggedRowIndex !== index) {
            let rowY = this.domHandler.getOffset(rowElement).top + this.domHandler.getWindowScrollTop();
            let pageY = event.pageY;
            let rowMidY = rowY + this.domHandler.getOuterHeight(rowElement) / 2;
            let prevRowElement = rowElement.previousElementSibling;

            if (pageY < rowMidY) {
                this.domHandler.removeClass(rowElement, 'ui-table-dragpoint-bottom');

                this.droppedRowIndex = index;
                if (prevRowElement)
                    this.domHandler.addClass(prevRowElement, 'ui-table-dragpoint-bottom');
                else
                    this.domHandler.addClass(rowElement, 'ui-table-dragpoint-top');
            } else {
                if (prevRowElement)
                    this.domHandler.removeClass(prevRowElement, 'ui-table-dragpoint-bottom');
                else
                    this.domHandler.addClass(rowElement, 'ui-table-dragpoint-top');

                this.droppedRowIndex = index + 1;
                this.domHandler.addClass(rowElement, 'ui-table-dragpoint-bottom');
            }
        }
    }

    onRowDragLeave(event, rowElement) {
        let prevRowElement = rowElement.previousElementSibling;
        if (prevRowElement) {
            this.domHandler.removeClass(prevRowElement, 'ui-table-dragpoint-bottom');
        }

        this.domHandler.removeClass(rowElement, 'ui-table-dragpoint-bottom');
        this.domHandler.removeClass(rowElement, 'ui-table-dragpoint-top');
    }

    onRowDragEnd(event) {
        this.rowDragging = false;
        this.draggedRowIndex = null;
        this.droppedRowIndex = null;
    }

    onRowDrop(event, rowElement) {
        if (this.droppedRowIndex != null) {
            let dropIndex = (this.draggedRowIndex > this.droppedRowIndex) ? this.droppedRowIndex : (this.droppedRowIndex === 0) ? 0 : this.droppedRowIndex - 1;
            this.objectUtils.reorderArray(this.value, this.draggedRowIndex, dropIndex);

            this.onRowReorder.emit({
                dragIndex: this.draggedRowIndex,
                dropIndex: this.droppedRowIndex
            });
        }
        //cleanup
        this.onRowDragLeave(event, rowElement);
        this.onRowDragEnd(event);
    }

    handleVirtualScroll(event) {
        this.first = (event.page - 1) * this.rows;
        this.virtualScrollCallback = event.callback;

        this.zone.run(() => {
            if (this.virtualScrollTimer) {
                clearTimeout(this.virtualScrollTimer);
            }

            this.virtualScrollTimer = setTimeout(() => {
                this.onLazyLoad.emit(this.createLazyLoadMetadata());
            }, this.virtualScrollDelay);
        });
    }

    isEmpty() {
        let data = this.filteredValue || this.value;
        return data == null || data.length == 0;
    }

    getBlockableElement(): HTMLElement {
        return this.el.nativeElement.children[0];
    }

    getStorage() {
        switch (this.stateStorage) {
            case 'local':
                return window.localStorage;

            case 'session':
                return window.sessionStorage;

            default:
                throw new Error(this.stateStorage + ' is not a valid value for the state storage, supported values are "local" and "session".');
        }
    }

    isStateful() {
        return this.stateKey != null;
    }

    saveState() {
        const storage = this.getStorage();
        let state: TableState = {};

        if (this.paginator) {
            state.rows = this.rows;
        }

        if (this.sortField) {
            state.sortField = this.sortField;
            state.sortOrder = this.sortOrder;
        }

        if (this.multiSortMeta) {
            state.multiSortMeta = this.multiSortMeta;
        }

        if (this.hasFilter()) {
            state.filters = this.filters;
        }

        if (this.resizableColumns) {
            this.saveColumnWidths(state);
        }

        if (this.enableRowSelectionState && this.selection) {
            state.selection = this.selection;
        }

        if (this.actionBar && this.actionBar.columnToggle) {
          if (this.actionBar.columnToggle.selectedColumns) {
              state.selectedColumns = this.actionBar.columnToggle.selectedColumns;
          }

          if (this.actionBar.columnToggle.cols) {
              state.reorderedColumns = this.actionBar.columnToggle.cols;
          }
        }

        if (Object.keys(this.expandedRowKeys).length) {
            state.expandedRowKeys = this.expandedRowKeys;
        }

        if (Object.keys(state).length) {
            storage.setItem(this.stateKey, JSON.stringify(state));
        }
    }

    clearState() {
        if (!this.stateKey) {
          return;
        }
        const storage = this.getStorage();
        storage.removeItem(this.stateKey);
    }

    restoreState() {
        const storage = this.getStorage();
        const stateString = storage.getItem(this.stateKey);

        if (stateString) {
            let state: TableState = JSON.parse(stateString);

            if (this.paginator) {
                this.rows = state.rows;
            }

            if (state.sortField) {
                this.restoringSort = true;
                this._sortField = state.sortField;
                this._sortOrder = state.sortOrder;
            }

            if (state.multiSortMeta) {
                this.restoringSort = true;
                this._multiSortMeta = state.multiSortMeta;
            }

            if (state.filters) {
                this.restoringFilter = true;
                this.filters = state.filters;
            }

            if (this.resizableColumns) {
                this.columnWidthsState = state.columnWidths;
                this.tableWidthState = state.tableWidth;
                this.restoreColumnWidths();
            }

            if (state.expandedRowKeys) {
                this.expandedRowKeys = state.expandedRowKeys;
            }

            if (this.enableRowSelectionState && state.selection) {
                this.selection = state.selection;
            }

            setTimeout(() => {
              this.restoreColumnState();
            }, 10);

            this.stateRestored = true;
        }
    }

    saveColumnWidths(state) {
        let widths = [];
        let headers = this.domHandler.find(this.containerViewChild.nativeElement, '.ui-table-thead > tr:first-child > th');
        headers.map(header => widths.push(this.domHandler.getOuterWidth(header)));
        state.columnWidths = widths.join(',');

        if (this.columnResizeMode === 'expand') {
            state.tableWidth = this.scrollable ? this.domHandler.findSingle(this.containerViewChild.nativeElement, '.ui-table-scrollable-header-table').style.width :
                this.domHandler.getOuterWidth(this.tableViewChild.nativeElement) + 'px';
        }
    }

    restoreColumnWidths() {
        if (this.columnWidthsState && this.tableViewChild) {
            let widths = this.columnWidthsState.split(',');

            if (this.columnResizeMode === 'expand' && this.tableWidthState) {
                if (this.scrollable) {
                    let scrollableBodyTable = this.domHandler.findSingle(this.containerViewChild.nativeElement, '.ui-table-scrollable-body-table');
                    let scrollableHeaderTable = this.domHandler.findSingle(this.containerViewChild.nativeElement, '.ui-table-scrollable-header-table');
                    let scrollableFooterTable = this.domHandler.findSingle(this.containerViewChild.nativeElement, '.ui-table-scrollable-footer-table');
                    scrollableBodyTable.style.width = this.tableWidthState;
                    scrollableHeaderTable.style.width = this.tableWidthState;

                    if (scrollableFooterTable) {
                        scrollableFooterTable.style.width = this.tableWidthState;
                    }
                } else {
                    this.tableViewChild.nativeElement.style.width = this.tableWidthState;
                    this.containerViewChild.nativeElement.style.width = this.tableWidthState;
                }
            }

            if (this.scrollable) {
                let headerCols = this.domHandler.find(this.containerViewChild.nativeElement, '.ui-table-scrollable-header-table > colgroup > col');
                let bodyCols = this.domHandler.find(this.containerViewChild.nativeElement, '.ui-table-scrollable-body-table > colgroup > col');

                headerCols.map((col, index) => col.style.width = widths[index] + 'px');
                bodyCols.map((col, index) => col.style.width = widths[index] + 'px');
            } else {
                let headers = this.domHandler.find(this.tableViewChild.nativeElement, '.ui-table-thead > tr:first-child > th');
                headers.map((header, index) => header.style.width = widths[index] + 'px');
            }
        }
    }


    resetColumnWidths() {
        if ( this.tableViewChild) {

            if (this.columnResizeMode === 'expand' && this.tableWidthState) { //think of this case...

            }

            if (this.scrollable) {
                let headerCols = this.domHandler.find(this.containerViewChild.nativeElement, '.ui-table-scrollable-header-table > colgroup > col');
                let bodyCols = this.domHandler.find(this.containerViewChild.nativeElement, '.ui-table-scrollable-body-table > colgroup > col');

                headerCols.map((col, index) => col.style.width = '');
                bodyCols.map((col, index) => col.style.width = '');
            } else {
                let headers = this.domHandler.find(this.tableViewChild.nativeElement, '.ui-table-thead > tr:first-child > th');
                headers.map((header, index) => header.style.width = '');
            }
        }
    }

    findColumnByKey(key) {
        if (this.columns) {
            for (let col of this.columns) {
                if (col.key === key || col.field === key)
                    return col;
                else
                    continue;
            }
        } else {
            return null;
        }
    }

    restoreColumnState () {
      if (!this.actionBar || !this.actionBar.columnToggle) {
        return;
      }

      this.originalCols = this.originalCols || this.actionBar.columnToggle.cols.slice();
      this.originalSelectedColumns = this.originalSelectedColumns || this.actionBar.columnToggle.selectedColumns.slice();

      if (!this.isStateful()) {
        return;
      }

      const storage = this.getStorage();
      const stateString = storage.getItem(this.stateKey);
      let state: TableState = JSON.parse(stateString);

      this.columnWidthsState = state && state.columnWidths;

      if (state && !this.actionBarColumnStateRestored && this.actionBar && this.actionBar.columnToggle) {

        let previousReorderedColumns = state.reorderedColumns ? state.reorderedColumns.slice() : null;

        let previousOriginalColKeys = previousReorderedColumns ? previousReorderedColumns.map((col) => {
          return col.field;
        }) : null;
        let originalColKeys = this.originalCols.map((col) => {
          return col.field;
        });


        state.originalColKeys = originalColKeys;
        storage.setItem(this.stateKey, JSON.stringify(state));

        // check to see if we need to revert to original column:
        if (previousOriginalColKeys) {
          let keyChanged = false;

          if (originalColKeys.length !== previousOriginalColKeys.length) {
            keyChanged = true; //new column key must have been added or removed
          }
          else {
            for (let colKey of previousOriginalColKeys) {
              if (originalColKeys.indexOf(colKey) ===  -1) {
                keyChanged = true;
                break;
              }
            }
          }

          if (keyChanged) {
            state.selectedColumns = null;
            state.reorderedColumns = null;
            state.columnWidths = null;
            state.originalColKeys = null;
            storage.setItem(this.stateKey, JSON.stringify(state));

            this.actionBarColumnStateRestored = true;
            return;
          }
        }
        if (state && state.reorderedColumns) {
          this.actionBar.columnToggle.cols = state.reorderedColumns;
        }

        if (state && state.selectedColumns ) {
          // selected columns needs to be reordered
          let selectedColumnReordered = this.actionBar.columnToggle.cols.filter(c => {
             return this.objectUtils.findObjectIndex(c, state.selectedColumns, 'field') > -1;
          });

          this.actionBar.columnToggle.selectedColumns = selectedColumnReordered;
        }
        this.actionBarColumnStateRestored = true;

      }
    }

    onColumnReset(ev) {
      const storage = this.getStorage();
      const stateString = storage.getItem(this.stateKey);
      this.resetColumnWidths();

      if (stateString) {
          let state: TableState = JSON.parse(stateString);
          if (state) {
            state.originalColKeys = null;
            state.selectedColumns = null;
            state.reorderedColumns = null;
            state.columnWidths = null;
            storage.setItem(this.stateKey, JSON.stringify(state));
          }
      }
      this.actionBar.columnToggle.selectedColumns = this.originalSelectedColumns;
      this.actionBar.columnToggle.cols = this.originalCols;
    }
}

@Component({
    selector: '[pTableBody]',
    template: `
        <ng-container *ngIf="!dt.expandedRowTemplate">
            <ng-template ngFor let-rowData let-rowIndex="index"
                         [ngForOf]="(dt.paginator && !dt.lazy) ? ((dt.filteredValue||dt.value) | slice:dt.first:(dt.first + dt.rows)) : (dt.filteredValue||dt.value)"
                         [ngForTrackBy]="dt.rowTrackBy">
                <ng-container
                        *ngTemplateOutlet="template; context: {$implicit: rowData, rowIndex: dt.paginator ? (dt.first + rowIndex) : rowIndex, columns: columns}"></ng-container>
            </ng-template>
        </ng-container>
        <ng-container *ngIf="dt.expandedRowTemplate">
            <ng-template ngFor let-rowData let-rowIndex="index"
                         [ngForOf]="(dt.paginator && !dt.lazy) ? ((dt.filteredValue||dt.value) | slice:dt.first:(dt.first + dt.rows)) : (dt.filteredValue||dt.value)"
                         [ngForTrackBy]="dt.rowTrackBy">
                <ng-container
                        *ngTemplateOutlet="template; context: {$implicit: rowData, rowIndex: dt.paginator ? (dt.first + rowIndex) : rowIndex, columns: columns, expanded: dt.isRowExpanded(rowData)}"></ng-container>
                <ng-container *ngIf="dt.isRowExpanded(rowData)">
                    <ng-container
                            *ngTemplateOutlet="dt.expandedRowTemplate; context: {$implicit: rowData, rowIndex: dt.paginator ? (dt.first + rowIndex) : rowIndex, columns: columns}"></ng-container>
                </ng-container>
            </ng-template>
        </ng-container>
        <ng-container *ngIf="dt.isEmpty()">
            <ng-container
                    *ngTemplateOutlet="dt.emptyMessageTemplate; context: {$implicit: columns}"></ng-container>
        </ng-container>
    `
})
export class TableBody {

    @Input('pTableBody') columns: Column[];

    @Input('pTableBodyTemplate') template: TemplateRef<any>;

    constructor(public dt: Table) {
    }
}

@Component({
    selector: '[pTableIcon]',
    template: `
        <ng-container *ngIf="!dt.expandedRowTemplate">
            <ng-template ngFor let-rowData let-rowIndex="index"
                         [ngForOf]="(dt.paginator && !dt.lazy) ? ((dt.filteredValue||dt.value) | slice:dt.first:(dt.first + dt.rows)) : (dt.filteredValue||dt.value)"
                         [ngForTrackBy]="dt.rowTrackBy">
                <ng-container
                        *ngTemplateOutlet="template; context: {$implicit: rowData, rowIndex: dt.paginator ? (dt.first + rowIndex) : rowIndex, columns: columns}"></ng-container>
            </ng-template>
        </ng-container>
    `
})
export class TableIcon {

    @Input('pTableIcon') columns: Column[];

    @Input('pTableIconTemplate') template: TemplateRef<any>;

    constructor(public dt: Table) {
    }
}

@Component({
    selector: '[pScrollableView]',
    template: `
        <div #scrollHeader class="ui-table-scrollable-header ui-widget-header">
            <div #scrollHeaderBox class="ui-table-scrollable-header-box">
                <table class="ui-table-scrollable-header-table" [ngClass]="dt.tableStyleClass"
                       [ngStyle]="dt.tableStyle">
                    <ng-container
                            *ngTemplateOutlet="frozen ? dt.frozenColGroupTemplate||dt.colGroupTemplate : dt.colGroupTemplate; context {$implicit: columns}"></ng-container>
                    <thead class="ui-table-thead">
                    <ng-container
                            *ngTemplateOutlet="frozen ? dt.frozenHeaderTemplate||dt.headerTemplate : dt.headerTemplate; context {$implicit: columns}"></ng-container>
                    </thead>
                    <tbody class="ui-table-tbody">
                    <ng-template ngFor let-rowData let-rowIndex="index" [ngForOf]="dt.frozenValue"
                                 [ngForTrackBy]="dt.rowTrackBy">
                        <ng-container
                                *ngTemplateOutlet="dt.frozenRowsTemplate; context: {$implicit: rowData, rowIndex: rowIndex, columns: columns}"></ng-container>
                    </ng-template>
                    </tbody>
                </table>
            </div>
        </div>
        <div #scrollBody class="ui-table-scrollable-body">
            <table #scrollTable
                   [ngClass]="{'ui-table-scrollable-body-table': true, 'ui-table-virtual-table': dt.virtualScroll}"
                   [class]="dt.tableStyleClass" [ngStyle]="dt.tableStyle">
                <ng-container
                        *ngTemplateOutlet="frozen ? dt.frozenColGroupTemplate||dt.colGroupTemplate : dt.colGroupTemplate; context {$implicit: columns}"></ng-container>
                <tbody class="ui-table-tbody" [pTableBody]="columns"
                       [pTableBodyTemplate]="frozen ? dt.frozenBodyTemplate||dt.bodyTemplate : dt.bodyTemplate"></tbody>
                <tbody class="ui-table-icon" [pTableIcon]="columns"></tbody>
            </table>
            <div #virtualScroller class="ui-table-virtual-scroller"></div>
        </div>
        <div #scrollFooter *ngIf="dt.footerTemplate"
             class="ui-table-scrollable-footer ui-widget-header">
            <div #scrollFooterBox class="ui-table-scrollable-footer-box">
                <table class="ui-table-scrollable-footer-table" [ngClass]="dt.tableStyleClass"
                       [ngStyle]="dt.tableStyle">
                    <ng-container
                            *ngTemplateOutlet="frozen ? dt.frozenColGroupTemplate||dt.colGroupTemplate : dt.colGroupTemplate; context {$implicit: columns}"></ng-container>
                    <tfoot class="ui-table-tfoot">
                    <ng-container
                            *ngTemplateOutlet="frozen ? dt.frozenFooterTemplate||dt.footerTemplate : dt.footerTemplate; context {$implicit: columns}"></ng-container>
                    </tfoot>
                </table>
            </div>
        </div>
    `
})
export class ScrollableView implements AfterViewInit, OnDestroy, AfterViewChecked {

    @Input('pScrollableView') columns: Column[];

    @Input() frozen: boolean;

    @ViewChild('scrollHeader', { static: false }) scrollHeaderViewChild: ElementRef;

    @ViewChild('scrollHeaderBox', { static: false }) scrollHeaderBoxViewChild: ElementRef;

    @ViewChild('scrollBody', { static: false }) scrollBodyViewChild: ElementRef;

    @ViewChild('scrollTable', { static: false }) scrollTableViewChild: ElementRef;

    @ViewChild('scrollFooter', { static: false }) scrollFooterViewChild: ElementRef;

    @ViewChild('scrollFooterBox', { static: false }) scrollFooterBoxViewChild: ElementRef;

    @ViewChild('virtualScroller', { static: false }) virtualScrollerViewChild: ElementRef;

    headerScrollListener: Function;

    bodyScrollListener: Function;

    footerScrollListener: Function;

    frozenSiblingBody: Element;

    scrollableSiblingBody: Element;

    _scrollHeight: string;

    subscription: Subscription;

    totalRecordsSubscription: Subscription;

    columnsSubscription: Subscription;

    initialized: boolean;

    constructor(public dt: Table, public el: ElementRef, public domHandler: DomHandler, public zone: NgZone) {
        this.subscription = this.dt.tableService.valueSource$.subscribe(() => {
            this.zone.runOutsideAngular(() => {
                setTimeout(() => {
                    this.alignScrollBar();
                }, 50);
            });
        });

        if (this.dt.virtualScroll) {
            this.totalRecordsSubscription = this.dt.tableService.totalRecordsSource$.subscribe(() => {
                this.zone.runOutsideAngular(() => {
                    setTimeout(() => {
                        this.setVirtualScrollerHeight();
                    }, 50);
                });
            });
        }

        this.initialized = false;
    }

    @Input() get scrollHeight(): string {
        return this._scrollHeight;
    }

    set scrollHeight(val: string) {
        this._scrollHeight = val;
        this.setScrollHeight();
    }

    ngAfterViewChecked() {
        if (!this.initialized && this.el.nativeElement.offsetParent) {
            this.alignScrollBar();
            this.setScrollHeight();
            this.initialized = true;
        }
    }

    ngAfterViewInit() {
        if (!this.frozen) {
            if (this.dt.frozenColumns || this.dt.frozenBodyTemplate) {
                this.domHandler.addClass(this.el.nativeElement, 'ui-table-unfrozen-view');
            }

            let frozenView = this.el.nativeElement.previousElementSibling;
            if (frozenView) {
                this.frozenSiblingBody = this.domHandler.findSingle(frozenView, '.ui-table-scrollable-body');
            }
        } else {
            this.scrollBodyViewChild.nativeElement.style.marginBottom = this.domHandler.calculateScrollbarWidth() + 'px';
            let scrollableView = this.el.nativeElement.nextElementSibling;
            if (scrollableView) {
                this.scrollableSiblingBody = this.domHandler.findSingle(scrollableView, '.ui-table-scrollable-body');
            }
        }

        this.bindEvents();
        this.setScrollHeight();
        this.alignScrollBar();

        if (this.frozen) {
            this.columnsSubscription = this.dt.tableService.columnsSource$.subscribe(() => {
                this.zone.runOutsideAngular(() => {
                    setTimeout(() => {
                        this.setScrollHeight();
                    }, 50);
                });
            });
        }

        if (this.dt.virtualScroll) {
            this.setVirtualScrollerHeight();
        }
    }

    bindEvents() {
        this.zone.runOutsideAngular(() => {
            if (this.scrollHeaderViewChild && this.scrollHeaderViewChild.nativeElement) {
                this.headerScrollListener = this.onHeaderScroll.bind(this);
                this.scrollHeaderBoxViewChild.nativeElement.addEventListener('scroll', this.headerScrollListener);
            }

            if (this.scrollFooterViewChild && this.scrollFooterViewChild.nativeElement) {
                this.footerScrollListener = this.onFooterScroll.bind(this);
                this.scrollFooterViewChild.nativeElement.addEventListener('scroll', this.footerScrollListener);
            }

            if (!this.frozen) {
                this.bodyScrollListener = this.onBodyScroll.bind(this);
                this.scrollBodyViewChild.nativeElement.addEventListener('scroll', this.bodyScrollListener);
            }
        });
    }

    unbindEvents() {
        if (this.scrollHeaderViewChild && this.scrollHeaderViewChild.nativeElement) {
            this.scrollHeaderBoxViewChild.nativeElement.removeEventListener('scroll', this.headerScrollListener);
        }

        if (this.scrollFooterViewChild && this.scrollFooterViewChild.nativeElement) {
            this.scrollFooterViewChild.nativeElement.removeEventListener('scroll', this.footerScrollListener);
        }

        this.scrollBodyViewChild.nativeElement.addEventListener('scroll', this.bodyScrollListener);
    }

    onHeaderScroll(event) {
        this.scrollHeaderViewChild.nativeElement.scrollLeft = 0;
    }

    onFooterScroll(event) {
        this.scrollFooterViewChild.nativeElement.scrollLeft = 0;
    }

    onBodyScroll(event) {
        if (this.scrollHeaderViewChild && this.scrollHeaderViewChild.nativeElement) {
            this.scrollHeaderBoxViewChild.nativeElement.style.marginLeft = -1 * this.scrollBodyViewChild.nativeElement.scrollLeft + 'px';
        }

        if (this.scrollFooterViewChild && this.scrollFooterViewChild.nativeElement) {
            this.scrollFooterBoxViewChild.nativeElement.style.marginLeft = -1 * this.scrollBodyViewChild.nativeElement.scrollLeft + 'px';
        }

        if (this.frozenSiblingBody) {
            this.frozenSiblingBody.scrollTop = this.scrollBodyViewChild.nativeElement.scrollTop;
        }

        if (this.dt.virtualScroll) {
            let viewport = this.domHandler.getOuterHeight(this.scrollBodyViewChild.nativeElement);
            let tableHeight = this.domHandler.getOuterHeight(this.scrollTableViewChild.nativeElement);
            let pageHeight = this.dt.virtualRowHeight * this.dt.rows;
            let virtualTableHeight = this.domHandler.getOuterHeight(this.virtualScrollerViewChild.nativeElement);
            let pageCount = (virtualTableHeight / pageHeight) || 1;
            let scrollBodyTop = this.scrollTableViewChild.nativeElement.style.top || '0';

            if ((this.scrollBodyViewChild.nativeElement.scrollTop + viewport > parseFloat(scrollBodyTop) + tableHeight) || (this.scrollBodyViewChild.nativeElement.scrollTop < parseFloat(scrollBodyTop))) {
                let page = Math.floor((this.scrollBodyViewChild.nativeElement.scrollTop * pageCount) / (this.scrollBodyViewChild.nativeElement.scrollHeight)) + 1;
                this.dt.handleVirtualScroll({
                    page: page,
                    callback: () => {
                        this.scrollTableViewChild.nativeElement.style.top = ((page - 1) * pageHeight) + 'px';

                        if (this.frozenSiblingBody) {
                            (<HTMLElement>this.frozenSiblingBody.children[0]).style.top = this.scrollTableViewChild.nativeElement.style.top;
                        }
                    }
                });
            }
        }
    }

    setScrollHeight() {
        if (this.scrollHeight && this.scrollBodyViewChild && this.scrollBodyViewChild.nativeElement) {
            if (this.scrollHeight.indexOf('%') !== -1) {
                let relativeHeight;
                this.scrollBodyViewChild.nativeElement.style.visibility = 'hidden';
                this.scrollBodyViewChild.nativeElement.style.height = '100px';     //temporary height to calculate static height
                let containerHeight = this.domHandler.getOuterHeight(this.dt.el.nativeElement.children[0]);

                if (this.scrollHeight.includes('calc')) {
                    let percentHeight = parseInt(this.scrollHeight.slice(this.scrollHeight.indexOf('(') + 1, this.scrollHeight.indexOf('%')));
                    let diffValue = parseInt(this.scrollHeight.slice(this.scrollHeight.indexOf('-') + 1, this.scrollHeight.indexOf(')')));
                    relativeHeight = (this.domHandler.getOuterHeight(this.dt.el.nativeElement.parentElement) * percentHeight / 100) - diffValue;
                } else {
                    relativeHeight = this.domHandler.getOuterHeight(this.dt.el.nativeElement.parentElement) * parseInt(this.scrollHeight) / 100;
                }

                let staticHeight = containerHeight - 100;   //total height of headers, footers, paginators
                let scrollBodyHeight = (relativeHeight - staticHeight);

                if (this.frozen) {
                    scrollBodyHeight -= this.domHandler.calculateScrollbarWidth();
                }

                this.scrollBodyViewChild.nativeElement.style.height = 'auto';
                this.scrollBodyViewChild.nativeElement.style.maxHeight = scrollBodyHeight + 'px';
                this.scrollBodyViewChild.nativeElement.style.visibility = 'visible';
            } else {
                if (this.frozen && this.scrollableSiblingBody && this.domHandler.getOuterWidth(this.scrollableSiblingBody) < this.domHandler.getOuterWidth(this.scrollableSiblingBody.children[0]))
                    this.scrollBodyViewChild.nativeElement.style.maxHeight = (parseInt(this.scrollHeight) - this.domHandler.calculateScrollbarWidth()) + 'px';
                else
                    this.scrollBodyViewChild.nativeElement.style.maxHeight = this.scrollHeight;
            }
        }
    }

    setVirtualScrollerHeight() {
        if (this.virtualScrollerViewChild.nativeElement) {
            this.virtualScrollerViewChild.nativeElement.style.height = this.dt.totalRecords * this.dt.virtualRowHeight + 'px';
        }
    }

    hasVerticalOverflow() {
        return this.domHandler.getOuterHeight(this.scrollTableViewChild.nativeElement) > this.domHandler.getOuterHeight(this.scrollBodyViewChild.nativeElement);
    }

    alignScrollBar() {
        if (!this.frozen) {
            let scrollBarWidth = this.hasVerticalOverflow() ? this.domHandler.calculateScrollbarWidth() : 0;
            this.scrollHeaderBoxViewChild.nativeElement.style.marginRight = scrollBarWidth + 'px';

            if (this.scrollFooterBoxViewChild && this.scrollFooterBoxViewChild.nativeElement) {
                this.scrollFooterBoxViewChild.nativeElement.style.marginRight = scrollBarWidth + 'px';
            }
        }
        this.initialized = false;
    }

    ngOnDestroy() {
        this.unbindEvents();

        this.frozenSiblingBody = null;

        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        if (this.totalRecordsSubscription) {
            this.totalRecordsSubscription.unsubscribe();
        }

        if (this.columnsSubscription) {
            this.columnsSubscription.unsubscribe();
        }

        this.initialized = false;
    }
}

@Directive({
    selector: '[pSortableColumn]',
    providers: [DomHandler],
    host: {
        '[class.ui-sortable-column]': 'isEnabled()',
        '[class.ui-state-highlight]': 'sorted',
        '[attr.tabindex]': 'isEnabled() ? "0" : null'
    }
})
export class SortableColumn implements OnInit, OnDestroy {

    @Input('pSortableColumn') field: string;

    @Input() pSortableColumnDisabled: boolean;

    sorted: boolean;

    subscription: Subscription;

    constructor(public dt: Table, public domHandler: DomHandler) {
        if (this.isEnabled()) {
            this.subscription = this.dt.tableService.sortSource$.subscribe(sortMeta => {
                this.updateSortState();
            });
        }
    }

    ngOnInit() {
        if (this.isEnabled()) {
            this.updateSortState();
        }
    }

    updateSortState() {
        this.sorted = this.dt.isSorted(this.field);
    }

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent) {
        if (this.isEnabled()) {
            this.updateSortState();
            this.dt.sort({
                originalEvent: event,
                field: this.field
            });

            this.domHandler.clearSelection();
        }
    }

    @HostListener('keydown.enter', ['$event'])
    onEnterKey(event: MouseEvent) {
        this.onClick(event);
    }

    isEnabled() {
        return this.pSortableColumnDisabled !== true;
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

}


@Component({
    selector: 'p-sortIcon',
    template: `
        <i class="ui-sortable-column-icon pi pi-fw"
           [ngClass]="{'pi-sort-up': sortOrder === 1, 'pi-sort-down': sortOrder === -1, 'pi-sort': sortOrder === 0}"></i>
    `
})
export class SortIcon implements OnInit, OnDestroy {

    @Input() field: string;

    @Input() ariaLabel: string;

    @Input() ariaLabelDesc: string;

    @Input() ariaLabelAsc: string;

    subscription: Subscription;

    sortOrder: number;

    constructor(public dt: Table) {
        this.subscription = this.dt.tableService.sortSource$.subscribe(sortMeta => {
            this.updateSortState();
        });
    }

    ngOnInit() {
        this.updateSortState();
    }

    onClick(event) {
        event.preventDefault();
    }

    updateSortState() {
        if (this.dt.sortMode === 'single') {
            this.sortOrder = this.dt.isSorted(this.field) ? this.dt.sortOrder : 0;
        } else if (this.dt.sortMode === 'multiple') {
            let sortMeta = this.dt.getSortMeta(this.field);
            this.sortOrder = sortMeta ? sortMeta.order : 0;
        }
    }

    get ariaText(): string {
        let text: string;

        switch (this.sortOrder) {
            case 1:
                text = this.ariaLabelAsc;
                break;

            case -1:
                text = this.ariaLabelDesc;
                break;

            default:
                text = this.ariaLabel;
                break;
        }

        return text;
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}

@Directive({
    selector: '[pSelectableRow]',
    providers: [DomHandler],
    host: {
        '[class.ui-selectable-row]': 'isEnabled()',
        '[class.ui-state-highlight]': 'selected',
        '[attr.tabindex]': 'isEnabled() ? 0 : undefined'
    }
})
export class SelectableRow implements OnInit, OnDestroy {

    @Input('pSelectableRow') data: any;

    @Input('pSelectableRowIndex') index: number;

    @Input() pSelectableRowDisabled: boolean;

    selected: boolean;

    subscription: Subscription;

    constructor(el: ElementRef, public dt: Table, public domHandler: DomHandler, public tableService: TableService) {
        if (this.isEnabled()) {
            this.subscription = this.dt.tableService.selectionSource$.subscribe(() => {
                this.selected = this.dt.isSelected(this.data);
            });
        }
    }

    ngOnInit() {
        if (this.isEnabled()) {
            this.selected = this.dt.isSelected(this.data);
        }
    }

    @HostListener('click', ['$event'])
    onClick(event: Event) {
        if (this.isEnabled()) {
            this.dt.handleRowClick({
                originalEvent: event,
                rowData: this.data,
                rowIndex: this.index
            });
        }
    }

    @HostListener('touchend', ['$event'])
    onTouchEnd(event: Event) {
        if (this.isEnabled()) {
            this.dt.handleRowTouchEnd(event);
        }
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (this.isEnabled()) {
            const row = <HTMLTableRowElement>event.target;

            switch (event.which) {
                //down arrow
                case 40:
                    let nextRow = this.findNextSelectableRow(row);
                    if (nextRow) {
                        nextRow.focus();
                    }

                    event.preventDefault();
                    break;

                //up arrow
                case 38:
                    let prevRow = this.findPrevSelectableRow(row);
                    if (prevRow) {
                        prevRow.focus();
                    }

                    event.preventDefault();
                    break;

                //enter
                case 13:
                    this.dt.handleRowClick({
                        originalEvent: event,
                        rowData: this.data,
                        rowIndex: this.index
                    });
                    break;

                default:
                    //no op
                    break;
            }
        }
    }

    findNextSelectableRow(row: HTMLTableRowElement): HTMLTableRowElement {
        let nextRow = <HTMLTableRowElement>row.nextElementSibling;
        if (nextRow) {
            if (this.domHandler.hasClass(nextRow, 'ui-selectable-row'))
                return nextRow;
            else
                return this.findNextSelectableRow(nextRow);
        } else {
            return null;
        }
    }

    findPrevSelectableRow(row: HTMLTableRowElement): HTMLTableRowElement {
        let prevRow = <HTMLTableRowElement>row.previousElementSibling;
        if (prevRow) {
            if (this.domHandler.hasClass(prevRow, 'ui-selectable-row'))
                return prevRow;
            else
                return this.findPrevSelectableRow(prevRow);
        } else {
            return null;
        }
    }

    isEnabled() {
        return this.pSelectableRowDisabled !== true;
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

}


@Directive({
    selector: '[pActiveRow]',
    providers: [DomHandler],
    host: {
        '[class.ui-state-active]': 'dt.activeRowId === data[dt.dataKey]'
       }
})
export class TableActiveRow implements OnInit, OnDestroy {

    @Input('pActiveRow') data: any;

    @Input('pActiveRowIndex') index: number;

    constructor(public dt: Table, public domHandler: DomHandler) {
    }

    ngOnInit() {
    }

    @HostListener('click', ['$event'])
    onClick(event: Event) {
      this.dt.handleRowClick({
          originalEvent: event,
          rowData: this.data,
          rowIndex: this.index,
          clickType: 'active'
      });
    }

    @HostListener('touchend', ['$event'])
    onTouchEnd(event: Event) {
        this.dt.handleRowTouchEnd(event);
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
      const row = <HTMLTableRowElement>event.target;

      switch (event.which) {
          //down arrow
          case 40:
              let nextRow = this.findNextRow(row);
              if (nextRow) {
                  nextRow.focus();
              }

              event.preventDefault();
              break;

          //up arrow
          case 38:
              let prevRow = this.findPrevRow(row);
              if (prevRow) {
                  prevRow.focus();
              }

              event.preventDefault();
              break;

          //enter
          case 13:
              this.dt.handleRowClick({
                  originalEvent: event,
                  rowData: this.data,
                  rowIndex: this.index,
                  clickType: 'active'
              });
              break;

          default:
              //no op
              break;
      }
    }

    findNextRow(row: HTMLTableRowElement): HTMLTableRowElement {
        return <HTMLTableRowElement>row.nextElementSibling;
    }

    findPrevRow(row: HTMLTableRowElement): HTMLTableRowElement {
        return <HTMLTableRowElement>row.previousElementSibling;
    }

    ngOnDestroy() {

    }

}

@Directive({
    selector: '[pSelectableRowDblClick]',
    providers: [DomHandler],
    host: {
        '[class.ui-state-highlight]': 'selected'
    }
})
export class SelectableRowDblClick implements OnInit, OnDestroy {

    @Input('pSelectableRowDblClick') data: any;

    @Input('pSelectableRowIndex') index: number;

    @Input() pSelectableRowDisabled: boolean;

    selected: boolean;

    subscription: Subscription;

    constructor(public dt: Table, public domHandler: DomHandler, public tableService: TableService) {
        if (this.isEnabled()) {
            this.subscription = this.dt.tableService.selectionSource$.subscribe(() => {
                this.selected = this.dt.isSelected(this.data);
            });
        }
    }

    ngOnInit() {
        if (this.isEnabled()) {
            this.selected = this.dt.isSelected(this.data);
        }
    }

    @HostListener('dblclick', ['$event'])
    onClick(event: Event) {
        if (this.isEnabled()) {
            this.dt.handleRowClick({
                originalEvent: event,
                rowData: this.data,
                rowIndex: this.index
            });
        }
    }

    isEnabled() {
        return this.pSelectableRowDisabled !== true;
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

}

@Directive({
    selector: '[pContextMenuRow]',
    host: {
        '[class.ui-contextmenu-selected]': 'selected'
    }
})
export class ContextMenuRow {

    @Input('pContextMenuRow') data: any;

    @Input() pContextMenuRowDisabled: boolean;

    selected: boolean;

    subscription: Subscription;

    constructor(public dt: Table, public tableService: TableService) {
        if (this.isEnabled()) {
            this.subscription = this.dt.tableService.contextMenuSource$.subscribe((data) => {
                this.selected = this.dt.equals(this.data, data);
            });
        }
    }

    @HostListener('contextmenu', ['$event'])
    onContextMenu(event: Event) {
        if (this.isEnabled()) {
            this.dt.handleRowRightClick({
                originalEvent: event,
                rowData: this.data
            });

            event.preventDefault();
        }
    }

    isEnabled() {
        return this.pContextMenuRowDisabled !== true;
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

}

@Directive({
    selector: '[pRowToggler]'
})
export class RowToggler {

    @Input('pRowToggler') data: any;

    @Input() pRowTogglerDisabled: boolean;

    constructor(public dt: Table) {
    }

    @HostListener('click', ['$event'])
    onClick(event: Event) {
        if (this.isEnabled()) {
            this.dt.toggleRow(this.data, event);
            event.preventDefault();
        }
    }

    isEnabled() {
        return this.pRowTogglerDisabled !== true;
    }
}

@Directive({
    selector: '[pResizableColumn]'
})
export class ResizableColumn implements AfterViewInit, OnDestroy {

    @Input() pResizableColumnDisabled: boolean;

    resizer: HTMLSpanElement;

    resizerMouseDownListener: any;

    documentMouseMoveListener: any;

    documentMouseUpListener: any;

    constructor(public dt: Table, public el: ElementRef, public domHandler: DomHandler, public zone: NgZone) {
    }

    ngAfterViewInit() {
        if (this.isEnabled()) {
            this.domHandler.addClass(this.el.nativeElement, 'ui-resizable-column');
            this.resizer = document.createElement('span');
            this.resizer.className = 'ui-column-resizer ui-clickable';
            this.el.nativeElement.appendChild(this.resizer);

            this.zone.runOutsideAngular(() => {
                this.resizerMouseDownListener = this.onMouseDown.bind(this);
                this.resizer.addEventListener('mousedown', this.resizerMouseDownListener);
            });
        }
    }

    bindDocumentEvents() {
        this.zone.runOutsideAngular(() => {
            this.documentMouseMoveListener = this.onDocumentMouseMove.bind(this);
            document.addEventListener('mousemove', this.documentMouseMoveListener);

            this.documentMouseUpListener = this.onDocumentMouseUp.bind(this);
            document.addEventListener('mouseup', this.documentMouseUpListener);
        });
    }

    unbindDocumentEvents() {
        if (this.documentMouseMoveListener) {
            document.removeEventListener('mousemove', this.documentMouseMoveListener);
            this.documentMouseMoveListener = null;
        }

        if (this.documentMouseUpListener) {
            document.removeEventListener('mouseup', this.documentMouseUpListener);
            this.documentMouseUpListener = null;
        }
    }

    onMouseDown(event: Event) {
        this.dt.onColumnResizeBegin(event);
        this.bindDocumentEvents();
    }

    onDocumentMouseMove(event: Event) {
        this.dt.onColumnResize(event);
    }

    onDocumentMouseUp(event: Event) {
        this.dt.onColumnResizeEnd(event, this.el.nativeElement);
        this.unbindDocumentEvents();
    }

    isEnabled() {
        return this.pResizableColumnDisabled !== true;
    }

    ngOnDestroy() {
        if (this.resizerMouseDownListener) {
            this.resizer.removeEventListener('mousedown', this.resizerMouseDownListener);
        }

        this.unbindDocumentEvents();
    }
}

@Component({
    selector: 'p-tableRadioButton',
    template: `
        <div class="ui-radiobutton ui-widget" (click)="onClick($event)">
            <div class="ui-helper-hidden-accessible">
                <input type="radio" [checked]="checked" (focus)="onFocus()" (blur)="onBlur()"
                       [disabled]="disabled">
            </div>
            <div #box [ngClass]="{'ui-radiobutton-box ui-widget ui-state-default':true,
                'ui-state-active':checked, 'ui-state-disabled':disabled}">
                <svg class="sym-smbl__form-radio" [ngClass]="{ 'sym-smbl--checked':checked,'sym-smbl--disabled':disabled }"><use href="#sym-smbl__form-radio"></use></svg>
            </div>
        </div>
    `
})
export class TableRadioButton {

    @Input() disabled: boolean;

    @Input() value: any;

    @Input() index: number;

    @ViewChild('box', { static: false }) boxViewChild: ElementRef;

    checked: boolean;

    subscription: Subscription;

    constructor(public dt: Table, public domHandler: DomHandler, public tableService: TableService) {
        this.subscription = this.dt.tableService.selectionSource$.subscribe(() => {
            this.checked = this.dt.isSelected(this.value);
        });
    }

    ngOnInit() {
        this.checked = this.dt.isSelected(this.value);
    }

    onClick(event: Event) {
        event.stopPropagation(); //prevent double clicking
        if (!this.disabled) {
            this.dt.toggleRowWithRadio({
                originalEvent: event,
                rowIndex: this.index
            }, this.value);
        }
        this.domHandler.clearSelection();
    }

    onFocus() {
        this.domHandler.addClass(this.boxViewChild.nativeElement, 'ui-state-focus');
    }

    onBlur() {
        this.domHandler.removeClass(this.boxViewChild.nativeElement, 'ui-state-focus');
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

}

@Component({
    selector: 'p-tableCheckbox',
    template: `
        <div class="ui-chkbox ui-widget" (click)="onClick($event)">
            <div class="ui-helper-hidden-accessible">
                <input type="checkbox" [checked]="checked" (focus)="onFocus()" (blur)="onBlur()"
                       [disabled]="disabled">
            </div>
            <div #box [ngClass]="{'ui-chkbox-box ui-widget ui-state-default':true,
                'ui-state-active':checked, 'ui-state-disabled':disabled}">
                <svg class="sym-smbl__form-checkbox" [ngClass]="{ 'sym-smbl--checked':checked,'sym-smbl--disabled':disabled }"><use href="#sym-smbl__form-checkbox"></use></svg>
            </div>
        </div>
    `
})
export class TableCheckbox {

    @Input() disabled: boolean;

    @Input() value: any;

    @Input() index: number;

    @ViewChild('box', { static: false }) boxViewChild: ElementRef;

    checked: boolean;

    subscription: Subscription;

    constructor(public dt: Table, public domHandler: DomHandler, public tableService: TableService) {
        this.subscription = this.dt.tableService.selectionSource$.subscribe(() => {
            this.checked = this.dt.isSelected(this.value);
        });
    }

    ngOnInit() {
        this.checked = this.dt.isSelected(this.value);
    }

    onClick(event: Event) {

      if (this.dt.enableActiveRow) {
        event.stopPropagation();
        this.dt.handleRowClick({
            originalEvent: event,
            rowData: this.value,
            rowIndex: this.index,
            clickType: 'checkbox'
        });
      }

    }

    onFocus() {
        this.domHandler.addClass(this.boxViewChild.nativeElement, 'ui-state-focus');
    }

    onBlur() {
        this.domHandler.removeClass(this.boxViewChild.nativeElement, 'ui-state-focus');
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

}

@Component({
    selector: 'p-tableHeaderCheckbox',
    template: `
        <div class="ui-chkbox ui-widget" (click)="onClick($event, cb.checked)">
            <div class="ui-helper-hidden-accessible">
                <input #cb type="checkbox" [checked]="checked" (focus)="onFocus()" (blur)="onBlur()"
                       [disabled]="isDisabled()">
            </div>
            <div #box [ngClass]="{'ui-chkbox-box ui-widget ui-state-default':true,
                'ui-state-active':checked, 'ui-state-disabled': isDisabled()}">
                <svg class="sym-smbl__form-checkbox" [ngClass]="{ 'sym-smbl--checked':checked,'sym-smbl--disabled':disabled }"><use href="#sym-smbl__form-checkbox"></use></svg>
            </div>
        </div>
    `
})
export class TableHeaderCheckbox {

    @ViewChild('box', { static: false }) boxViewChild: ElementRef;

    @Input() disabled: boolean;

    checked: boolean;

    selectionChangeSubscription: Subscription;

    valueChangeSubscription: Subscription;

    constructor(public dt: Table, public domHandler: DomHandler, public tableService: TableService) {
        this.valueChangeSubscription = this.dt.tableService.valueSource$.subscribe(() => {
            this.checked = this.updateCheckedState();
        });

        this.selectionChangeSubscription = this.dt.tableService.selectionSource$.subscribe(() => {
            this.checked = this.updateCheckedState();
        });
    }

    ngOnInit() {
        this.checked = this.updateCheckedState();
    }

    onClick(event: Event, checked) {
        if (!this.disabled) {
            if (this.dt.value && this.dt.value.length > 0) {
                this.dt.toggleRowsWithCheckbox(event, !checked);
            }
        }

        this.domHandler.clearSelection();
    }

    onFocus() {
        this.domHandler.addClass(this.boxViewChild.nativeElement, 'ui-state-focus');
    }

    onBlur() {
        this.domHandler.removeClass(this.boxViewChild.nativeElement, 'ui-state-focus');
    }

    isDisabled() {
        return this.disabled || !this.dt.value || !this.dt.value.length;
    }

    ngOnDestroy() {
        if (this.selectionChangeSubscription) {
            this.selectionChangeSubscription.unsubscribe();
        }

        if (this.valueChangeSubscription) {
            this.valueChangeSubscription.unsubscribe();
        }
    }

    updateCheckedState() {
        const val = this.dt.filteredValue || this.dt.value;
        return (val && val.length > 0 && this.dt.selection && this.dt.selection.length > 0 && this.dt.selection.length === val.length);
    }

}

@Component({
    selector: 'p-tableColumnIconCheckbox',
    template: `
        <div [ngClass]="{ 'ui-table-toggle-icon-checkbox' : true, 'ui-table-is-checkbox-view' : selection && selection.length }">
          <span class="ui-table-row-checkbox">
            <p-tableCheckbox [value]="rowData" ></p-tableCheckbox>
          </span>
          <span class="ui-table-row-icon">
            <sym-icon [svgId]="svgId" [svgClass]="svgClass">
            </sym-icon>
          </span>
        </div>
    `
})
export class TableColumnIconCheckbox {

    @Input() rowData: any;

    @Input() svgId: string;

    @Input() svgClass = "sym-smbl__category sym-smbl--small";

    @Input() selection: any;

}

@Component({
    selector: 'p-rowActions',
    template: `
      <div class="ui-table-row-actions sym-smbl--hover is--link">
        <sym-icon
                styleClass="sym-smbl__grid-more sym-smbl--gray"
                svgId="sym-smbl__grid-more">
        </sym-icon>
      </div>
    `
})

export class TableRowActions {
}


@Component({
    selector: 'p-tableCopyText',
    template: `
      <span class="ui-table-copy-text-container">
        <span class="ui-table-copy-text-content"><ng-content></ng-content></span>
        <div class="ui-table-copy-text-hidden">
          <input #copyTemp type="text" class="grid__copy-temp" value="{{copyText}}">
        </div>
        <div class="ui-table-copy-text-action-container">
          <div class="ui-table-copy-text-action">
            <i class="ui-table-copy-text-action-icon icon__clipboard is--white"
               (click)="copy($event)"
               ></i>
             <span class="ui-table-copy-text-copied-container"
             [ngClass]="{'is--active': isActive}">
               <span class="ui-table-copy-text-copied">
                 {{copiedText}}
               </span>
             </span>
           </div>
        </div>
       </span>
    `
})

export class TableTextCopy {
  @Input() copyText: string;
  @Input() copiedText = "copied";
  @ViewChild('copyTemp') copyTemp;

  isActive = false;

  copy (evt) {
    evt.stopPropagation();
    try {
      this.copyTemp.nativeElement.select();
      document.execCommand( 'copy' );
      this.copyTemp.nativeElement.blur();
      this.isActive = true;
      setTimeout(() => {
        this.isActive = false;
      }, 1000);
    }
    catch ( err ) {
      //alert?
    }
  }
}

@Directive({
    selector: '[pReorderableRowHandle]'
})
export class ReorderableRowHandle implements AfterViewInit {

    @Input('pReorderableRowHandle') index: number;

    constructor(public el: ElementRef, public domHandler: DomHandler) {
    }

    ngAfterViewInit() {
        this.domHandler.addClass(this.el.nativeElement, 'ui-table-reorderablerow-handle');
    }
}

@Directive({
    selector: '[pReorderableRow]'
})
export class ReorderableRow implements AfterViewInit {

    @Input('pReorderableRow') index: number;

    @Input() pReorderableRowDisabled: boolean;

    mouseDownListener: any;

    dragStartListener: any;

    dragEndListener: any;

    dragOverListener: any;

    dragLeaveListener: any;

    dropListener: any;

    constructor(public dt: Table, public el: ElementRef, public domHandler: DomHandler, public zone: NgZone) {
    }

    ngAfterViewInit() {
        if (this.isEnabled()) {
            this.el.nativeElement.droppable = true;
            this.bindEvents();
        }
    }

    bindEvents() {
        this.zone.runOutsideAngular(() => {
            this.mouseDownListener = this.onMouseDown.bind(this);
            this.el.nativeElement.addEventListener('mousedown', this.mouseDownListener);

            this.dragStartListener = this.onDragStart.bind(this);
            this.el.nativeElement.addEventListener('dragstart', this.dragStartListener);

            this.dragEndListener = this.onDragEnd.bind(this);
            this.el.nativeElement.addEventListener('dragend', this.dragEndListener);

            this.dragOverListener = this.onDragOver.bind(this);
            this.el.nativeElement.addEventListener('dragover', this.dragOverListener);

            this.dragLeaveListener = this.onDragLeave.bind(this);
            this.el.nativeElement.addEventListener('dragleave', this.dragLeaveListener);
        });
    }

    unbindEvents() {
        if (this.mouseDownListener) {
            document.removeEventListener('mousedown', this.mouseDownListener);
            this.mouseDownListener = null;
        }

        if (this.dragStartListener) {
            document.removeEventListener('dragstart', this.dragStartListener);
            this.dragStartListener = null;
        }

        if (this.dragEndListener) {
            document.removeEventListener('dragend', this.dragEndListener);
            this.dragEndListener = null;
        }

        if (this.dragOverListener) {
            document.removeEventListener('dragover', this.dragOverListener);
            this.dragOverListener = null;
        }

        if (this.dragLeaveListener) {
            document.removeEventListener('dragleave', this.dragLeaveListener);
            this.dragLeaveListener = null;
        }
    }

    onMouseDown(event) {
        if (this.domHandler.hasClass(event.target, 'ui-table-reorderablerow-handle'))
            this.el.nativeElement.draggable = true;
        else
            this.el.nativeElement.draggable = false;
    }

    onDragStart(event) {
        this.dt.onRowDragStart(event, this.index);
    }

    onDragEnd(event) {
        this.dt.onRowDragEnd(event);
        this.el.nativeElement.draggable = false;
    }

    onDragOver(event) {
        this.dt.onRowDragOver(event, this.index, this.el.nativeElement);
        event.preventDefault();
    }

    onDragLeave(event) {
        this.dt.onRowDragLeave(event, this.el.nativeElement);
    }

    isEnabled() {
        return this.pReorderableRowDisabled !== true;
    }

    @HostListener('drop', ['$event'])
    onDrop(event) {
        if (this.isEnabled() && this.dt.rowDragging) {
            this.dt.onRowDrop(event, this.el.nativeElement);
        }

        event.preventDefault();
    }
}

@NgModule({
    imports: [CommonModule, PaginatorModule, ActionBarModule, SearchModule ],
    exports: [Table, SharedModule, SortableColumn, SelectableRow, RowToggler, ContextMenuRow, ResizableColumn, SortIcon, TableRadioButton, TableCheckbox, TableHeaderCheckbox, ReorderableRowHandle, ReorderableRow, SelectableRowDblClick, TableColumnIconCheckbox, TableRowActions, TableTextCopy, TableActiveRow],
    declarations: [Table, SortableColumn, SelectableRow, RowToggler, ContextMenuRow, ResizableColumn, TableBody, TableIcon, ScrollableView, SortIcon, TableRadioButton, TableCheckbox, TableHeaderCheckbox, ReorderableRowHandle, ReorderableRow, SelectableRowDblClick, TableColumnIconCheckbox, TableRowActions, TableTextCopy, TableActiveRow]
})
export class TableModule {
}
