import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  NgModule,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  Directive,
  NgZone,
  HostListener,
  Host,
  SkipSelf
}                                                  from '@angular/core';
import { CommonModule }                            from '@angular/common';
import { SelectItem }                              from '../common/select-item';
import { DomHandler }                              from '../dom/dom-handler';
import { ObjectUtils }                             from '../utils/object-utils';
import { Footer, PrimeTemplate, SharedModule }     from '../common/shared';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReorderEvent }                            from '../common/reorder.interface';
import { SymInlineEditModule, SymInlineEdit }      from '../inline-edit/inline-edit';
import { InlineEditService }                       from '../inline-edit/inline-edit.service';
import { MenuModule }                              from '../menu/menu';
import { ArrayUtils }                              from '../utils/array.utils';

export const TABLECOLUMN_VALUE_ACCESSOR : any = {
  provide     : NG_VALUE_ACCESSOR,
  useExisting : forwardRef( () => SymColumnSelector ),
  multi       : true
};

@Component( {
  selector  : 'sym-column-selector',
  template  : `
    <div class="sym-column-selector" [ngClass]="{ 'is--reorderable': enableReorder }">
      <div class="ui-widget-header ui-corner-all sym-column-selector__header ui-helper-clearfix "
           [ngClass]="{'sym-column-selector__header-no-toggleall': !showToggleAll}"
           *ngIf="showHeader">
        <ng-content select="p-header"></ng-content>
        <div class="sym-column-selector__filter-container " *ngIf="filter">
          <sym-input-text-helper [value]="filterValue" (onClear)="clearSearch()" class="sym-column-selector__filter-search">
            <input #filterInput type="text" role="textbox" [value]="filterValue||''"
                   (input)="onFilter()"
                   class="ui-inputtext ui-widget ui-state-default ui-corner-all"
                   [attr.placeholder]="l10n.filterPlaceHolder">
            <sym-icon
                    [hidden]="filterValue || filterValue !==''"
                    svgClass="sym-column-selector__filter-icon sym-smbl__action-search-input sym-smbl--black-40"
                    svgId="sym-smbl__action-search-input"></sym-icon>
          </sym-input-text-helper>
        </div>
        <div class="sym__flex-container">
          <div class="sym-column-selector__select-all-checkbox-container" *ngIf="showToggleAll && !selectionLimit">
            <div class="ui-chkbox ui-widget">
              <div class="ui-helper-hidden-accessible">
                <input type="checkbox" readonly="readonly" [checked]="isAllChecked()"
                       (focus)="onHeaderCheckboxFocus()" (blur)="onHeaderCheckboxBlur()"
                       (keydown.space)="toggleAll($event)">
              </div>
              <div [ngClass]="{'ui-chkbox-box ui-widget ui-state-default':true,
                                    'ui-state-active':isAllChecked(), 'ui-state-focus': headerCheckboxFocus}"
                   (click)="toggleAll($event)">
                <sym-icon
                        [styleClass]="{ 'sym-smbl--checked':isAllChecked() }"
                        svgClass="sym-smbl__form-checkbox"
                        svgId="sym-smbl__form-checkbox"></sym-icon>

              </div>
            </div>
            <span class="is--link" (click)="toggleAll($event)">{{l10n.selectAll}}</span>
          </div>
          <div *ngIf="enableReorder && !filterValue" class="sym-column-selector__items-actions sym__flex-fill">
            <div *ngIf="showCompileButton" class="sym-column-selector__reorder-complile is--link" (click)="compileSelection()">
              <sym-icon svgClass="sym-smbl__action-compile-selection" svgId="sym-smbl__action-compile-selection"></sym-icon>
              <span>{{l10n.compileSelectionLabel}}</span>
            </div>
            <div *ngIf="showUndoButton" class="sym-column-selector__reorder-undo is--link" (click)="undo()">
              <sym-icon svgClass="sym-smbl__action-undo sym-smbl--blue" svgId="sym-smbl__action-undo"></sym-icon>
              <span>{{l10n.undoLabel}}</span>
            </div>
            <div *ngIf="showResetButton" class="sym-column-selector__items-menu-container is--link">
              <p-menu #actionMenu [popup]="true" [relative]="true" [model]="actionMenuItems"
                      [offset]="{ top : 0, right: -10}"></p-menu>
              <div class="sym-column-selector__items-menu-more" (click)="showMoreMenu( $event, actionMenu )">
                <div class="icon__actions-menu-more"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sym-column-selector__items-wrapper">
        <ul class="sym-column-selector__items sym-column-selector__list ui-widget-content ui-widget ui-corner-all ui-helper-reset">
          <ng-container>
            <ng-template ngFor let-option let-i="index" [ngForOf]="options">
              <sym-column-selector-item [option]="option"
                                        [index]="i"
                                        [disabled]="option.disabled"
                                        [reorderDisabled]="filterValue || !isSelected(option.value) || value && (value.length - originalDisabledKeys.length) < 2 || option.key && originalDisabledKeys.indexOf(option.key) > -1 || !option.key && option.value && originalDisabledKeys.indexOf(option.value) > -1"
                                        [order]="getSelectedOrder(option.value)"
                                        [selected]="isSelected(option.value)"
                                        (onClick)="onOptionClick($event)"
                                        (onKeydown)="onOptionKeydown($event)"
                                        (onOrderChange)="onOrderChange($event)"
                                        (onInlineEditOpen)="onInlineEditOpen($event)"
                                        [maxSelectionLimitReached]="maxSelectionLimitReached"
                                        [visible]="isItemVisible(option)"
                                        [enableReorder]="enableReorder"
                                        [template]="itemTemplate"></sym-column-selector-item>
            </ng-template>
          </ng-container>
        </ul>
      </div>
    </div>

  `,
  host      : {
    '[class.ui-inputwrapper-filled]' : 'filled',
    '[class.ui-inputwrapper-focus]'  : 'focus'
  },
  providers : [DomHandler, ObjectUtils, TABLECOLUMN_VALUE_ACCESSOR]
} )
export class SymColumnSelector implements OnInit, AfterViewInit, AfterContentInit, AfterViewChecked, OnDestroy, ControlValueAccessor {

  _defaultLabel : string = 'Choose';

  @Input() set defaultLabel ( val : string ) {
    this._defaultLabel = val;
    this.updateLabel();
  }

  get defaultLabel () : string {
    return this._defaultLabel;
  }

  @Input() style : any;

  @Input() styleClass : string;

  @Input() panelStyle : any = { height : '300px' };

  @Input() panelStyleClass : string;

  @Input() inputId : string;

  @Input() disabled : boolean;

  @Input() readonly : boolean;

  @Input() filter : boolean = true;

  @Input() tabindex : number;

  @Input() dataKey : string;

  @Input() name : string;

  @Input() displaySelectedLabel : boolean = true;

  @Input() maxSelectedLabels : number = 3;

  @Input() selectionLimit : number;

  @Input() selectionMinimum : number = 1;

  @Input() selectedItemsLabel : string = '{0} items selected';

  @Input() showToggleAll : boolean = false;

  @Input() resetFilterOnHide : boolean = false;

  @Input() dropdownIcon : string = 'sym-smbl--arrow-small sym-smbl--black-80';

  @Input() dropdownUpIcon : string = 'sym-smbl--arrow-small sym-smbl--black-80 sym-smbl--arrow-up';

  @Input() optionLabel : string;

  @Input() showHeader : boolean = true;

  @Input() autoZIndex : boolean = true;

  @Input() baseZIndex : number = 0;

  @Input() filterBy : string = 'label';

  @Input() itemSize : number;

  @Input() showTransitionOptions : string = '225ms ease-out';

  @Input() hideTransitionOptions : string = '195ms ease-in';

  @Input() enableReorder : boolean = false;

  @Input() isResizable : boolean = false;

  @Input() offset = { top : 12 };

  @Input() originalSelections : any[]; //developer defined, so can't add type

  @Input() minHeight = 300;

  @Input() maxHeight = 600;

  @Input() minWidth = 240;

  @Input() maxWidth = 600;

  @ViewChild( 'container', { static : false } ) containerViewChild : ElementRef;

  @ViewChild( 'filterInput', { static : false } ) filterInputChild : ElementRef;

  @ViewChild( 'columnResizable', { static : false } ) columnResizable : ElementRef;

  @ViewChild( 'columnResizerHandle', { static : false } ) columnResizerHandle : ElementRef;

  @ContentChild( Footer, { static : false } ) footerFacet;

  @ContentChildren( PrimeTemplate ) templates : QueryList<any>;

  @Output() onChange : EventEmitter<any> = new EventEmitter();

  @Output() onReorder : EventEmitter<ReorderEvent> = new EventEmitter();

  @Output() onReset : EventEmitter<boolean> = new EventEmitter();

  showResetButton   = false;
  showCompileButton = false;
  showUndoButton    = false;

  resized = false;

  previousOptions : any[]; //developer defined, so can't add type
  previousSelections : any[]; //developer defined, so can't add type

  actionMenuItems = [
    {
      label   : 'Reset',
      icon    : 'sym-smbl__action-reset sym-smbl--blue',
      iconSVG : '#sym-smbl__action-reset',
      command : ( evt ) => {
        this.reset();
      }
    }
  ];

  undoTimeout : ReturnType<typeof setTimeout> = setTimeout( () => '', 500 );

  public value : any[];

  public onModelChange : Function = () => {
  };

  public onModelTouched : Function = () => {
  };

  overlay : HTMLDivElement;

  public valuesAsString : string;

  public focus : boolean;

  filled : boolean;

  public documentClickListener : any;

  public selfClick : boolean;

  public panelClick : boolean;

  public filterValue : string = '';

  public visibleOptions : SelectItem[];

  public filtered : boolean;

  public itemTemplate : TemplateRef<any>;

  public selectedItemsTemplate : TemplateRef<any>;

  public headerCheckboxFocus : boolean;

  _options : any[]; //developer defined, so can't add type

  _originalOptions : any[]; //developer defined, so can't add type

  _l10n = {
    selectedItemsLabel    : '{0} items selected',
    compileSelectionLabel : 'Compile Selection',
    resetLabel            : 'Reset to Factory Default',
    undoLabel             : 'Undo Reset',
    selectAll             : 'Select All'
  };

  maxSelectionLimitReached : boolean;

  documentResizeListener : any;

  resizableResizeListener : any;

  draggedRowIndex : number;

  droppedRowIndex : number;

  previousDraggedIndex : number;

  rowDragging : boolean;

  dropPosition : number;

  originalDisabledKeys : string[] = [];

  constructor ( public el : ElementRef, public domHandler : DomHandler, public renderer : Renderer2, public objectUtils : ObjectUtils, private cd : ChangeDetectorRef, public zone : NgZone, private inlineEditService : InlineEditService ) {

  }

  @Input() get l10n () : any {
    return this._l10n;
  }

  set l10n ( val ) {
    if ( val ) {
      this._l10n = val;
    }

    this.actionMenuItems = [
      {
        label   : this.l10n.resetLabel,
        icon    : 'sym-smbl__action-reset sym-smbl--blue',
        iconSVG : '#sym-smbl__action-reset',
        command : ( evt ) => {
          this.reset();
        }
      }
    ];
  }

  // options are all the available columns selections and gets reordered in this component (which is why originalOptions is needed to reset)
  @Input() get options () : any[] {
    return this._options;
  }

  set options ( val : any[] ) { //developer defined, so can't add type
    if ( !val ) {
      return;
    }
    let opts = this.optionLabel ? this.objectUtils.generateSelectItems( val, this.optionLabel, this.dataKey ) : val;
    val.forEach( ( v, index ) => {
      if ( v.disabled ) {
        opts[ index ].disabled = v.disabled;
      }
    } );
    this._options = opts;
    this.checkShowResetButton();
    this.updateLabel();
  }

  originalOptionsFormatted : any[];

  // originalOptions keeps track of the original order of all options
  @Input() get originalOptions () : any[] {
    return this._originalOptions;
  }

  set originalOptions ( val : any[] ) { //developer defined, so can't add type
    if ( !val ) {
      return;
    }
    let opts = this.optionLabel ? this.objectUtils.generateSelectItems( val, this.optionLabel, this.dataKey ) : val;
    val.forEach( ( v, index ) => {
      if ( v.disabled ) {
        opts[ index ].disabled = v.disabled;
        if ( opts[ index ].key && this.originalDisabledKeys.indexOf( opts[ index ].key ) === -1 ) {
          this.originalDisabledKeys.push( opts[ index ].key );
        } else if ( !opts[ index ].key && this.originalDisabledKeys.indexOf( v.value ) === -1 ) {
          this.originalDisabledKeys.push( v.value );
        }
      }

    } );
    this._originalOptions = opts;
    this.checkShowResetButton();
  }

  _visible = false; //turn this to true, when column selector is shown by dropdown or modal
  //NOTE: This column selector componet will always render and visible.
  @Input() get visible () : boolean {
    return this._visible;
  }

  set visible ( val : boolean ) {
    this._visible = val;
    if ( val ) {
      this.checkShowResetButton();
      setTimeout( () => {
        this.filterInputChild.nativeElement.focus();
      }, 500 ); //for modal, it take s longer, so changing from 200 to 500

    } else {
      if ( this.resetFilterOnHide ) {
        setTimeout( () => {
          this.filterInputChild.nativeElement.value = '';
          this.onFilter();
        }, 200 ); //Adding timeout so it won't flicker as dropdown is closing

      }
    }
  }

  ngOnInit () {
    this.updateLabel();
  }

  ngAfterContentInit () {
    this.templates.forEach( ( item ) => {
      switch ( item.getType() ) {
        case 'item':
          this.itemTemplate = item.template;
          break;

        case 'selectedItems':
          this.selectedItemsTemplate = item.template;
          break;

        default:
          this.itemTemplate = item.template;
          break;
      }
    } );
  }

  ngAfterViewInit () {
    this.checkShowResetButton();
  }

  ngAfterViewChecked () {
    if ( this.filtered ) {
      this.filtered = false;
    }
  }


  writeValue ( value : any ) : void {
    this.value = value;
    this.updateLabel();
    this.updateFilledState();
    this.cd.markForCheck();
  }

  updateFilledState () {
    this.filled = ( this.valuesAsString != null && this.valuesAsString.length > 0 );
  }

  registerOnChange ( fn : Function ) : void {
    this.onModelChange = fn;
  }

  registerOnTouched ( fn : Function ) : void {
    this.onModelTouched = fn;
  }

  setDisabledState ( val : boolean ) : void {
    this.disabled = val;
  }

  checkShowResetButton () {
    if ( this.originalOptions && this.options ) {
      // check if it was reordered
      let optionsKeys        = this.options.map( ( o ) => {
        if ( this.dataKey ) {
          return o.key;
        }
        return o.value;

      } );
      let originalOptions    = this.originalOptions.map( ( o ) => {
        if ( this.dataKey ) {
          return o.key;
        }
        return o.value;
      } );
      let valueKeys : string[];
      let originalSelectedKeys : string[];
      this.showCompileButton = false;
      // check if selections were changed
      if ( this.value ) {
        valueKeys = this.value.map( ( o ) => {
          if ( this.dataKey ) {
            return o[ this.dataKey ];
          }
          return o;
        } );
        //find if there are any breaks between the order of options and selected items
        for ( let i = 0; i < optionsKeys.length; i++ ) {
          if ( valueKeys.indexOf( optionsKeys[ i ] ) === -1 && i < valueKeys.length ) {
            this.showCompileButton = true;
          }
        }
        if ( this.originalSelections ) {
          originalSelectedKeys = this.originalSelections.map( ( o ) => {
            if ( this.dataKey ) {
              return o[ this.dataKey ];
            }
            return o;
          } );
        }
      }

      if ( !ArrayUtils.equals( optionsKeys, originalOptions ) || valueKeys && originalSelectedKeys && !ArrayUtils.equals( valueKeys, originalSelectedKeys ) ) {
        this.showResetButton = true;
      } else {
        this.showResetButton = false;
      }
    } else {
      this.showResetButton = false;
    }
  }

  onOptionClick ( event ) {

    let option = event.option;

    if ( option.disabled ) {
      return;
    }

    if ( this.undoTimeout ) {
      clearTimeout( this.undoTimeout );
      this.showUndoButton = false;
    }

    const value        = option.value;
    let selectionIndex = this.findSelectionIndex( value );
    if ( !this.value ) {
      this.value = [];
    }
    let originalValue = this.value.slice();

    // onDeselect
    if ( selectionIndex != -1 ) {
      this.value = this.value.filter( ( val, i ) => i != selectionIndex );

      if ( this.selectionLimit ) {
        this.maxSelectionLimitReached = false;
      }

      if ( this.value.length <= this.selectionMinimum ) {
        for ( let i = 0; i < this.options.length; i += 1 ) {
          if ( this.value[ 0 ] && this.options[ i ].label === this.value[ 0 ].header ) {
            this.options[ i ].disabled = true;
            continue;
          }
        }
      }

      // onSelect
    } else {

      if ( !this.selectionLimit || ( this.value.length < this.selectionLimit ) ) {
        this.value = [...this.value || [], value];
      }

      if ( this.enableReorder ) {
        this.value = this.reorderedSelectedColumns( this.value );
      }

      if ( this.selectionLimit && this.value.length === this.selectionLimit ) {
        this.maxSelectionLimitReached = true;
      }

      if ( this.value.length > this.selectionMinimum ) {
        for ( let i = 0; i < this.options.length; i += 1 ) {
          if ( this.dataKey && this.options[ i ].key && this.originalDisabledKeys.indexOf( this.options[ i ].key ) > -1 ||
            !this.dataKey && this.originalDisabledKeys.indexOf( this.options[ i ].value ) > -1 ) {
            this.options[ i ].disabled = true;
          } else {
            this.options[ i ].disabled = false;
          }
        }
      }
    }

    this.onModelChange( this.value );
    this.onChange.emit( {
      originalEvent : event.originalEvent,
      value         : this.value,
      originalValue : originalValue,
      itemValue     : value
    } );
    this.updateLabel();
    this.updateFilledState();
    this.checkShowResetButton();
  }

  isSelected ( value ) {
    return this.findSelectionIndex( value ) != -1;
  }

  reorderedSelectedColumns ( values ) {
    let updatedValues = this.options.filter( o => {
      return this.findSelectionIndex( o.value ) !== -1;
    } );
    return updatedValues.map( v => {
      return v.value;
    } );
  }

  getSelectedOrder ( value ) {
    return this.findSelectionIndex( value );
  }

  findSelectionIndex ( val : any ) : number {
    let index = -1;

    if ( this.value ) {
      for ( let i = 0; i < this.value.length; i++ ) {
        if ( this.objectUtils.equals( this.value[ i ], val, this.dataKey ) ) {
          index = i;
          break;
        }
      }
    }

    return index;
  }


  findOptionsIndex ( val : any ) : number {
    let index = -1;

    if ( this.options ) {
      for ( let i = 0; i < this.options.length; i++ ) {
        if ( this.objectUtils.equals( this.options[ i ].value, val, this.dataKey ) ) {
          index = i;
          break;
        }
      }
    }

    return index;
  }

  toggleAll ( event ) {
    let opts     = this.getVisibleOptions();
    let selected = [];
    if ( this.isAllChecked() ) {
      //keep the disabled and selected
      if ( opts ) {
        for ( let i = 0; i < opts.length; i++ ) {
          let option = opts[ i ];
          if ( option.disabled && this.isSelected( option.value ) ) {
            selected.push( option.value );
          }
        }
      }
    } else {
      if ( opts ) {
        for ( let i = 0; i < opts.length; i++ ) {
          let option = opts[ i ];
          if ( !option.disabled || ( option.disabled && this.isSelected( option.value ) ) ) {
            selected.push( option.value );
          }
        }
      }
    }
    this.value = selected;

    this.onModelChange( this.value );
    this.onChange.emit( { originalEvent : event, value : this.value } );
    this.updateLabel();
  }

  isAllChecked () {
    if ( this.filterValue && this.filterValue.trim().length ) {
      return this.value && this.visibleOptions && this.visibleOptions.length && ( this.value.length == this.visibleOptions.length );
    } else {
      let optionCount = this.getEnabledOptionCount();

      return this.value && this.options && ( this.value.length > 0 && this.value.length == optionCount );
    }
  }

  getEnabledOptionCount () : number {
    if ( this.options ) {
      let count = 0;
      for ( let opt of this.options ) {
        if ( !opt.disabled || opt.disabled && this.isSelected( opt.value ) ) {
          count++;
        }
      }

      return count;
    } else {
      return 0;
    }
  }

  onOptionKeydown ( event ) {
    if ( this.readonly ) {
      return;
    }

    let item = <HTMLLIElement>event.originalEvent.currentTarget;

    switch ( event.which ) {
      //down
      case 40:
        let nextItem = this.findNextItem( item );
        if ( nextItem ) {
          nextItem.focus();
        }

        event.preventDefault();
        break;

      //up
      case 38:
        let prevItem = this.findPrevItem( item );
        if ( prevItem ) {
          prevItem.focus();
        }

        event.preventDefault();
        break;

      //enter
      case 13:
        this.onOptionClick( event );
        event.preventDefault();
        break;
    }
  }

  findNextItem ( item ) {
    let nextItem = item.nextElementSibling;

    if ( nextItem )
      return this.domHandler.hasClass( nextItem, 'ui-state-disabled' ) || this.domHandler.isHidden( nextItem ) ? this.findNextItem( nextItem ) : nextItem;
    else
      return null;
  }

  findPrevItem ( item ) {
    let prevItem = item.previousElementSibling;

    if ( prevItem )
      return this.domHandler.hasClass( prevItem, 'ui-state-disabled' ) || this.domHandler.isHidden( prevItem ) ? this.findPrevItem( prevItem ) : prevItem;
    else
      return null;
  }

  updateLabel () {
    if ( this.value && this.options && this.value.length && this.displaySelectedLabel ) {
      let label = '';
      for ( let i = 0; i < this.value.length; i++ ) {
        let itemLabel = this.findLabelByValue( this.value[ i ] );
        if ( itemLabel ) {
          if ( label.length > 0 ) {
            label = label + ', ';
          }
          label = label + itemLabel;
        }
      }

      if ( this.value.length <= this.maxSelectedLabels ) {
        this.valuesAsString = label;
      } else {
        let pattern = /{(.*?)}/;
        if ( pattern.test( this.selectedItemsLabel ) ) {
          this.valuesAsString = this.selectedItemsLabel.replace( this.selectedItemsLabel.match( pattern )[ 0 ], this.value.length + '' );
        }
      }
    } else {
      this.valuesAsString = this.defaultLabel;
    }
  }

  findLabelByValue ( val : any ) : string {
    let label = null;
    for ( let i = 0; i < this.options.length; i++ ) {
      let option = this.options[ i ];
      if ( val == null && option.value == null || this.objectUtils.equals( val, option.value, this.dataKey ) ) {
        label = option.label;
        break;
      }
    }
    return label;
  }

  onFilter () {
    let inputValue = this.filterInputChild.nativeElement.value;
    if ( inputValue && inputValue.length ) {
      this.filterValue    = inputValue;
      this.visibleOptions = [];
      this.activateFilter();
    } else {
      this.filterValue    = null;
      this.visibleOptions = this.options;
      this.filtered       = false;
    }
  }

  activateFilter () {
    if ( this.options && this.options.length ) {
      let searchFields : string[] = this.filterBy.split( ',' );
      this.visibleOptions         = this.objectUtils.filter( this.options, searchFields, this.filterValue );
      this.filtered               = true;
    }
  }

  isItemVisible ( option : SelectItem ) : boolean {
    if ( this.filterValue && this.filterValue.trim().length ) {
      for ( let i = 0; i < this.visibleOptions.length; i++ ) {
        if ( this.visibleOptions[ i ].value == option.value ) {
          return true;
        }
      }
    } else {
      return true;
    }
  }

  getVisibleOptions () : SelectItem[] {
    if ( this.visibleOptions && this.visibleOptions.length ) {
      return this.visibleOptions;
    } else {
      return this.options;
    }
  }

  onHeaderCheckboxFocus () {
    this.headerCheckboxFocus = true;
  }

  onHeaderCheckboxBlur () {
    this.headerCheckboxFocus = false;
  }

  onWindowResize () {
    // this.hide();
  }

  hideUndoButton () {
    if ( this.undoTimeout ) {
      clearTimeout( this.undoTimeout );
    }
    this.showUndoButton = false;
    this.checkShowResetButton();
  }

  onRowDragStart ( event, index ) {
    if ( this.undoTimeout ) {
      clearTimeout( this.undoTimeout );
      this.showUndoButton = false;
      this.checkShowResetButton();
    }
    this.rowDragging     = true;
    this.draggedRowIndex = index;
    this.domHandler.addClass( this.el.nativeElement, 'is--dragging' );

    event.dataTransfer.setData( 'text', 'b' );    // For firefox
  }

  onRowDragOver ( event, index, rowElement ) {
    if ( this.rowDragging && this.draggedRowIndex !== index && this.previousDraggedIndex !== index ) {
      this.previousDraggedIndex = index;
      let rowY                  = this.domHandler.getOffset( rowElement ).top + this.domHandler.getWindowScrollTop();
      let pageY                 = event.pageY;
      let rowMidY               = rowY + this.domHandler.getOuterHeight( rowElement ) / 2;
      let prevRowElement        = rowElement.previousElementSibling;
      if ( pageY < rowMidY ) {
        this.domHandler.removeClass( rowElement, 'sym-column-selector-item__dragpoint-bottom' );

        this.droppedRowIndex = index;
        if ( prevRowElement )
          this.domHandler.addClass( prevRowElement, 'sym-column-selector-item__dragpoint-bottom' );
        else
          this.domHandler.addClass( rowElement, 'sym-column-selector-item__dragpoint-top' );
      } else {
        if ( prevRowElement )
          this.domHandler.removeClass( prevRowElement, 'sym-column-selector-item__dragpoint-bottom' );
        else
          this.domHandler.addClass( rowElement, 'sym-column-selector-item__dragpoint-top' );

        this.droppedRowIndex = index + 1;
        this.domHandler.addClass( rowElement, 'sym-column-selector-item__dragpoint-bottom' );
      }
    }
  }

  onRowDragLeave ( event, rowElement ) {
    this.previousDraggedIndex = null;
    let prevRowElement        = rowElement.previousElementSibling;
    if ( prevRowElement ) {
      this.domHandler.removeClass( prevRowElement, 'sym-column-selector-item__dragpoint-bottom' );
    }

    this.domHandler.removeClass( rowElement, 'sym-column-selector-item__dragpoint-bottom' );
    this.domHandler.removeClass( rowElement, 'sym-column-selector-item__dragpoint-top' );
  }

  onRowDragEnd ( event ) {
    this.rowDragging = false;
    this.domHandler.removeClass( this.el.nativeElement, 'is--dragging' );

    this.draggedRowIndex = null;
    this.droppedRowIndex = null;
  }

  onRowDrop ( event, rowElement ) {

    if ( this.droppedRowIndex != null ) {
      let dropIndex = ( this.draggedRowIndex > this.droppedRowIndex ) ? this.droppedRowIndex : ( this.droppedRowIndex === 0 ) ? 0 : this.droppedRowIndex - 1;
      this.objectUtils.reorderArray( this.options, this.draggedRowIndex, dropIndex );
      this.emitReorder();

    }
    //cleanup
    this.onRowDragLeave( event, rowElement );
    this.onRowDragEnd( event );
  }

  onOrderChange ( itemObj : { item : SelectItem, order : number, newOrder : number, index : number } ) {
    //find the item exists at newOrder
    if ( ( this.value.length - 1 ) < itemObj.newOrder ) {
      itemObj.newOrder = this.value.length - 1;
    } else if ( itemObj.newOrder < 0 ) {
      itemObj.newOrder = 0;
    }
    let currentNewOrderItem = this.value[ itemObj.newOrder ];
    // Check to see which the first item that is not disabled. (usually only first time or two are disabled.)
    if ( currentNewOrderItem.disabled ) {
      for ( let i = 0; i < this.value.length; i++ ) {
        if ( !this.value[ i ].disabled ) {
          currentNewOrderItem = this.value[ i ];
          itemObj.newOrder    = i;
          break;
        }
      }
    }

    let currentNewOrderItemIndex = this.findOptionsIndex( currentNewOrderItem );

    this.options.splice( currentNewOrderItemIndex, 0, this.options.splice( itemObj.index, 1 )[ 0 ] );
    this.emitReorder();
    this.checkShowResetButton();
  }

  compileSelection () {
    let selectedOptions   = this.options.filter( s => this.isSelected( s.value ) );
    let unselectedOptions = this.options.filter( s => !this.isSelected( s.value ) );
    this._options         = [...selectedOptions, ...unselectedOptions];
    this.emitReorder();
    this.hideUndoButton();
  }

  onInlineEditOpen ( item : TableColumnItem ) {
    // close all other inline edit items
    this.inlineEditService.cancelOthers( item.inlineEdit.id );
  }

  emitReorder () {
    let unformattedOptions = this.options.map( ( o ) => {
      return o.value;
    } );

    this.onReorder.emit( {
      dragIndex : null,
      dropIndex : null,
      value     : unformattedOptions
    } );
  }

  undo () {

    if ( this.previousOptions && this.previousSelections ) {
      this._options           = this.previousOptions;
      this.value              = this.previousSelections;
      this.previousOptions    = null;
      this.previousSelections = null;
      this.emitReorder();
    }
    this.hideUndoButton();

  }

  reset () {
    if ( this.undoTimeout ) {
      clearTimeout( this.undoTimeout );
    }
    this.previousOptions    = this.options.slice();
    this.previousSelections = this.value.slice();
    this._options           = this.originalOptions;
    this.value              = this.originalSelections;

    this.undoTimeout    = setTimeout( () => {
      this.showUndoButton = false;
      this.checkShowResetButton();
    }, 5000 );
    this.showUndoButton = true;
    this.checkShowResetButton();
    this.onReset.emit( true );
  }

  showMoreMenu ( event, menuReference ) : void {
    menuReference.toggle( event );
  }

  clearSearch () {
    this.filterValue = '';
  }

  ngOnDestroy () {

  }
}


@Component( {
  selector : 'sym-column-selector-item',
  template : `
    <li class="sym-column-selector-item ui-corner-all"
        (keydown)="onOptionKeydown($event)"
        [attr.tabindex]="option.disabled ? null : '0'" [ngStyle]="{'height': itemSize + 'px'}"
        [ngClass]="{'ui-state-hidden': !visible, 'ui-state-highlight': selected,
            'ui-state-disable-reorderable': enableReorder && (disabled || reorderDisabled),
            'ui-state-reorderable':enableReorder && !disabled && !reorderDisabled,
            'ui-state-disabled': (option.disabled || ( maxSelectionLimitReached && !selected)) }">
      <div class="sym-column-selector-item__overlay"></div>
      <span *ngIf="enableReorder" class="sym-column-selector-item__reorder">
              <sym-inline-edit *ngIf="order > -1"
                               #inlineEdit
                               [disabled]="reorderDisabled"
                               class="sym-column-selector-item__reorder-inline-edit"
                               [value]="order + 1"
                               [errorMsg]="inlineEditErrorMsg"
                               [isValid]="inlineEditIsValid"
                               [isProcessing]="inlineEditIsProcessing"
                               [type]="inlineEditType"
                               [style]="inlineEditStyle"
                               (onEdit)="onInlineEdit($event)"
                               (onValidate)="onInlineValidate($event)"
                               (onSave)="onInlineSave($event)">
                {{ order + 1 }}
              </sym-inline-edit>
            </span>
      <div class="sym-column-selector-item__container" (click)="onOptionClick($event)">

              <span *ngIf="enableReorder && !disabled && !reorderDisabled" (onMouseDown)="reorderHandleMouseDown()"
                    (onMouseUp)="reorderHandleMouseDown()"
                    class="icon__reorder-handle sym__icon sym-column-selector-item__reorderable-handle" pSelectReorderableRowHandle></span>
        <div class="ui-chkbox ui-widget">
          <div class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"
               [ngClass]="{'ui-state-active': selected}">
            <sym-icon svgClass="sym-smbl__form-checkbox" [styleClass]="{'sym-smbl--checked':selected,'sym-smbl--disabled':disabled }"
                      styleId="sym-smbl__form-checkbox"></sym-icon>
          </div>
        </div>

        <label class="is--ellipsis" *ngIf="!template">{{option.label}}</label>
        <ng-container *ngTemplateOutlet="template; context: {$implicit: option}"></ng-container>
      </div>
    </li>
  `
} )
export class TableColumnItem implements OnInit, OnDestroy {

  @Input() option : SelectItem;

  @Input() selected : boolean;

  @Input() disabled : boolean;

  @Input() visible : boolean;

  @Input() itemSize : number;

  @Input() template : TemplateRef<any>;

  @Input() index : number;

  @Input() order : number;

  @Input() maxSelectionLimitReached : boolean;

  @Input() enableReorder : boolean = false;

  @Input() reorderDisabled : boolean = false;

  @Output() onClick : EventEmitter<any> = new EventEmitter();

  @Output() onKeydown : EventEmitter<any> = new EventEmitter();

  @Output() onOrderChange : EventEmitter<any> = new EventEmitter();

  @Output() onInlineEditOpen : EventEmitter<any> = new EventEmitter();


  @ViewChild( 'inlineEdit', { static : false } ) inlineEdit : SymInlineEdit;

  dragStartListener : any;

  dragOverListener : any;

  dragEnterListener : any;

  dragLeaveListener : any;

  mouseDownListener : any;

  dragEndListener : any;

  inlineEditIsValid = true;

  inlineEditType = 'number';

  inlineEditStyle = '';

  inlineEditErrorMsg = '';

  inlineEditIsProcessing = false;

  reorderHandleInProgress = false;

  constructor ( public el : ElementRef, public zone : NgZone, public domHandler : DomHandler, public objectUtils : ObjectUtils, @Host() @SkipSelf() private columnSelector : SymColumnSelector ) {

  }

  ngOnInit () {
    if ( this.enableReorder && !this.option.disabled ) {
      this.el.nativeElement.droppable = true;
      this.bindEvents();
    }
  }

  reorderHandleMouseDown () {
    this.reorderHandleInProgress = true;
  }

  reorderHandleMouseUp () {
    this.reorderHandleInProgress = false;
  }

  onOptionClick ( event : Event ) {
    if ( !this.reorderHandleInProgress ) {
      this.onClick.emit( {
        originalEvent : event,
        option        : this.option
      } );
    }

  }

  onOptionKeydown ( event : Event ) {
    this.onKeydown.emit( {
      originalEvent : event,
      option        : this.option
    } );
  }

  @HostListener( 'drop', ['$event'] )
  onDrop ( event ) {
    if ( this.enableReorder && this.columnSelector.rowDragging ) {
      this.columnSelector.onRowDrop( event, this.el.nativeElement );
    }

    event.preventDefault();
  }

  bindEvents () {

    this.zone.runOutsideAngular( () => {
      this.mouseDownListener = this.onMouseDown.bind( this );
      this.el.nativeElement.addEventListener( 'mousedown', this.mouseDownListener );

      this.dragStartListener = this.onDragStart.bind( this );
      this.el.nativeElement.addEventListener( 'dragstart', this.dragStartListener );

      this.dragEndListener = this.onDragEnd.bind( this );
      this.el.nativeElement.addEventListener( 'dragend', this.dragEndListener );

      this.dragOverListener = this.onDragOver.bind( this );
      this.el.nativeElement.addEventListener( 'dragover', this.dragOverListener );

      this.dragLeaveListener = this.onDragLeave.bind( this );
      this.el.nativeElement.addEventListener( 'dragleave', this.dragLeaveListener );
    } );
  }

  unbindEvents () {
    if ( this.mouseDownListener ) {
      document.removeEventListener( 'mousedown', this.mouseDownListener );
      this.mouseDownListener = null;
    }

    if ( this.dragStartListener ) {
      document.removeEventListener( 'dragstart', this.dragStartListener );
      this.dragStartListener = null;
    }

    if ( this.dragEndListener ) {
      document.removeEventListener( 'dragend', this.dragEndListener );
      this.dragEndListener = null;
    }

    if ( this.dragOverListener ) {
      document.removeEventListener( 'dragover', this.dragOverListener );
      this.dragOverListener = null;
    }

    if ( this.dragLeaveListener ) {
      document.removeEventListener( 'dragleave', this.dragLeaveListener );
      this.dragLeaveListener = null;
    }
  }

  onMouseDown ( event : Event ) {
    if ( this.domHandler.hasClass( event.target, 'sym-column-selector-item__reorderable-handle' ) )
      this.el.nativeElement.draggable = true;
    else
      this.el.nativeElement.draggable = false;
  }

  onDragStart ( event : Event ) {
    this.columnSelector.onRowDragStart( event, this.index );
  }

  onDragEnd ( event : Event ) {
    this.columnSelector.onRowDragEnd( event );
    this.el.nativeElement.draggable = false;
  }

  onDragOver ( event : Event ) {
    this.columnSelector.onRowDragOver( event, this.index, this.el.nativeElement );
    event.preventDefault();
  }

  onDragLeave ( event : Event ) {
    this.columnSelector.onRowDragLeave( event, this.el.nativeElement );
  }

  onInlineEdit ( obj ) {
    this.onInlineEditOpen.emit( this );
  }

  onInlineValidate ( obj : { value : string | number; } ) {
    // As this is number, no validation needed for now.
    this.inlineEditIsValid = obj.value !== '' && obj.value >= 0;
  }

  onInlineSave ( obj ) {
    // if number is empty, then don't do anything.
    if ( obj.value === '' ) {
      return;
    }
    let item = {
      item     : this.option,
      order    : this.order,
      newOrder : obj.value - 1,
      index    : this.index
    };
    this.onOrderChange.emit( item );
    this.inlineEdit.editMode = false;
  }

  ngOnDestroy () {
    this.unbindEvents();
  }
}


@NgModule( {
  imports      : [CommonModule, SharedModule, SymInlineEditModule, MenuModule],
  exports      : [SymColumnSelector, SharedModule, MenuModule],
  declarations : [SymColumnSelector, TableColumnItem]
} )
export class SymColumnSelectorModule {
}
