import { Component, OnInit } from '@angular/core';
import { Router }            from '@angular/router';
import { COMMON }            from '@showcase/constants/common';

@Component( {
  templateUrl : './toolkit.component.html',
  styleUrls   : [ './toolkit.component.css' ]
} )
export class ToolkitComponent implements OnInit {
  darkDemoStyle : HTMLStyleElement;
  menuActive : boolean;
  activeMenuId : string;
  searchText : string;
  version : string               = COMMON.VERSION;
  routes : Array<string>         = [];
  filteredRoutes : Array<string> = [];

  constructor (
    private router : Router
  ) {
  }

  ngOnInit () {
    let routes = this.router.config;

    // Toolkit
    for ( let route of routes[ 0 ].children ) {
      if ( route.path )
        this.routes.push( this.setFirstLetterUpperCase( route.path ) );
    }

    // Globalnav
    this.routes.push( this.setFirstLetterUpperCase( routes[ 1 ].path ) );

  }

  setFirstLetterUpperCase ( path ) : string {
    return path.charAt( 0 ).toUpperCase() + path.substr( 1 );
  }

  selectRoute ( routeName ) {
    this.router.navigate( [ '/' + routeName.toLowerCase() ] );
    this.filteredRoutes = [];
    this.searchText     = '';
  }

  filterRoutes ( event ) {
    let query           = event.query;
    this.filteredRoutes = this.routes.filter( route => {
      return route.toLowerCase().includes( query.toLowerCase() );
    } );
  }

  changeTheme ( event : Event, theme : string, dark : boolean ) {
    let themeLink : HTMLLinkElement = <HTMLLinkElement> document.getElementById( 'theme-css' );
    themeLink.href                  = 'assets/components/themes/' + theme + '/theme.css';

    if ( dark ) {
      if ( !this.darkDemoStyle ) {
        this.darkDemoStyle           = document.createElement( 'style' );
        this.darkDemoStyle.type      = 'text/css';
        this.darkDemoStyle.innerHTML = '.implementation { background-color: #3f3f3f; color: #dedede} .implementation > h3, .implementation > h4{ color: #dedede}';
        document.body.appendChild( this.darkDemoStyle );
      }
    } else if ( this.darkDemoStyle ) {
      document.body.removeChild( this.darkDemoStyle );
      this.darkDemoStyle = null;
    }

    event.preventDefault();
  }

  onMenuButtonClick ( event : Event ) {
    this.menuActive = !this.menuActive;
    event.preventDefault();
  }
}
