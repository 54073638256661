import {FormGroup} from '@angular/forms';
import {SubSink} from 'subsink';
import {OnDestroy} from '@angular/core';
import {Observable} from "rxjs";
import {BaseMultiStepFormStep} from "./multi-step-form-step";
import {DynamicDialogConfig} from "../dynamic-dialog/dynamic-dialog-config";

// Defines the basic flow all steps need to adhere to
export abstract class BaseMultiStepFormStepFlow extends BaseMultiStepFormStep implements OnDestroy {
  protected subs = new SubSink();

  // Each step has a form group to contain all its controls
  form: FormGroup;   // Leave publicly accessible for easy access within step template

  // Whether the first field on the form should be auto-focused. Also see note where this value is set.
  isAutoFocused: boolean;   // Leave publicly accessible for easy access within step template

  protected constructor() {
    super();
  }

  getFormGroup() {   // Needs to be publicly accessible as MultiStepForm invokes it
    return this.form;
  }

  protected init() {
    this.createFormGroupWithDefaults();
    if (this.isEdit()) {
      if (this.isFormGroupDataInConfigData()) {
        this.populateFormGroupFromConfigData();
        this.updateFormGroupControlsEnabledState();
      } else {
        this.populateFormGroupFromServerData().then(result => {
          if (result) {
            this.updateFormGroupControlsEnabledState();
          }
        });
      }
    } else {
      this.updateFormGroupControlsEnabledState();
    }

    // Since all field contents are selected on focus, meaning any existing field value is auto-selected,
    // only do this for "new" flow, not "edit" so the user doesn't overwrite previous value when starting to type
    this.isAutoFocused = !this.isEdit();
  }

  // Only data that is entered in the UI and/or is to be sent to the server should be configured here,
  // handle everything else outside of the form group.
  protected abstract createFormGroupWithDefaults(): void;

  // Make sure this works for both new and edit flows and prev/next step navigation.
  protected abstract isEdit(): boolean;

  // Override to return `true` if the `configData` object always contains all step data,
  // or `false` if fresh data is to be retrieved from the server.
  // The default implementation will fetch fresh server data for the "edit" flow, but rely on configData for the "new" flow.
  protected isFormGroupDataInConfigData(): boolean {
    return !this.isEdit();
  }

  // Override if steps rely on step data being contained in the `configData` object.
  protected populateFormGroupFromConfigData(): void {
    // No-op.
  }

  // Override if steps always retrieve fresh data from the server.
  // The promise should return `true` to continue with the `init()` flow, or `false` to interrupt it.
  // Make sure to use `setBusy` to control the busy state if a server call is needed.
  protected populateFormGroupFromServerData(): Promise<boolean> {
    // No-op.
    return Promise.resolve(true);
  }

  // TODO: automate this one with a default implementation
  protected abstract mergeFormGroupDataIntoConfigData(): void;

  protected mergeFormGroupDataValueIntoConfigData(configData: DynamicDialogConfig, key: string, subKey?: string, rowDataKey?: string, getRawValue?: boolean) {
    let value = this.form.get(key);
    if (subKey) {
      value = value.get(subKey);
    }
    value = getRawValue ? (value as FormGroup).getRawValue() : value.value;
    if (value !== undefined && value !== null) {
      if (subKey) {
        if (!configData.data.rowData[key]) {
          configData.data.rowData[key] = {};
        }
        configData.data.rowData[key][rowDataKey || subKey] = value;
      } else {
        configData.data.rowData[rowDataKey || key] = value;
      }
    }
  }

  protected abstract updateFormGroupControlsEnabledState(): void;

  // Invoke the API method to save the form data (payload).
  protected abstract callSaveApi(payload: any): Observable<any>;

  protected getSaveApiPayload(): any {
    return this.form.value;
  }

  protected abstract getSaveApiErrorMessage(): string;

  beforeShowStep() {
    // Each step is initialized (ngDoInit) before the first step is shown, so because the previous step may have
    // changed configData, re-eval.
    if (this.isFormGroupDataInConfigData()) {
      this.populateFormGroupFromConfigData();
      this.updateFormGroupControlsEnabledState();
    }
  }

  protected isDataSavedOnNext() {
    return true;
  }

  tryNext(): Promise<boolean> {   // Needs to be publicly accessible as MultiStepForm calls it
    if (this.isDataSavedOnNext()) {
      return new Promise<boolean>(resolve => {
        const payload = this.getSaveApiPayload();

        console.log('formGroup', this.form, 'formData', payload);

        this.subs.add(this.callSaveApi(payload).subscribe(
          response => {
            // Merge data from the server back into this.form
            // Note: depending on the `isEdit()` implementation, this may switch steps into edit mode automatically
            this.mergeServerDataIntoFormGroup(response, this.form);

            // Merge data from this.form into configData, as that may be the source of truth for any next step
            this.mergeFormGroupDataIntoConfigData();

            // State may have changed, so revisit enabled-ness
            this.updateFormGroupControlsEnabledState();

            resolve(true);
          },
          error => {
            const errMsg = this.getSaveApiErrorMessage();
            console.log(errMsg, error);
            this.setErrorMessage(errMsg);

            resolve(false);
          }
        ));
      });
    } else {
      // Merge data from this.form into configData, as that may be the source of truth for any next step
      this.mergeFormGroupDataIntoConfigData();

      return Promise.resolve(true);
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
