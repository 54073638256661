// Angular
import { NgModule, Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { CommonModule }                                                          from '@angular/common';
import { FormsModule }                                                           from '@angular/forms';

// Project
import { DropdownModule } from '../dropdown/dropdown';
import { SelectItem }     from '../common/select-item';
import { SharedModule }   from '../common/shared';

@Component( {
  selector : 'p-paginator',
  template : `
    <div [class]="styleClass" [ngStyle]="style" [ngClass]="'ui-paginator ui-widget ui-widget-header ui-unselectable-text ui-helper-clearfix'"
         *ngIf="alwaysShow ? true : ( rows > 0 && totalRecords > rows ) || (  rows > 0 && totalRecords > 0 && rowsPerPageItems && rowsPerPageItems[0] && totalRecords > rowsPerPageItems[0].value ) || ( pageLinks && pageLinks.length > 1 )">
      <div class="ui-paginator-left-content" *ngIf="templateLeft">
        <ng-container *ngTemplateOutlet="templateLeft; context: {$implicit: paginatorState}"></ng-container>
      </div>
      <a [attr.tabindex]="isFirstPage() ? null : '0'" class="ui-paginator-first ui-paginator-element ui-state-default ui-corner-all"
         (click)="changePageToFirst($event)" (keydown.enter)="changePageToFirst($event)" [ngClass]="{'ui-state-disabled':isFirstPage()}" [tabindex]="isFirstPage() ? -1 : null">
        <span class="ui-paginator-icon pi pi-step-backward"></span>
      </a>
      <a tabindex="0" [attr.tabindex]="isFirstPage() ? null : '0'" class="ui-paginator-prev ui-paginator-element ui-state-default ui-corner-all"
         (click)="changePageToPrev($event)" (keydown.enter)="changePageToPrev($event)" [ngClass]="{'ui-state-disabled':isFirstPage()}" [tabindex]="isFirstPage() ? -1 : null">
        <span class="ui-paginator-icon pi pi-caret-left"></span>
      </a>
      <span class="ui-paginator-pages">
        <a tabindex="0" *ngFor="let pageLink of pageLinks" class="ui-paginator-page ui-paginator-element ui-state-default ui-corner-all" (click)="onPageLinkClick($event, pageLink - 1)" (keydown.enter)="onPageLinkClick($event, pageLink - 1)" [ngClass]="{'ui-state-active': (pageLink-1 == getPage())}">{{pageLink}}</a>
      </span>
      <a [attr.tabindex]="isLastPage() ? null : '0'" class="ui-paginator-next ui-paginator-element ui-state-default ui-corner-all"
         (click)="changePageToNext($event)" (keydown.enter)="changePageToNext($event)" [ngClass]="{'ui-state-disabled':isLastPage()}" [tabindex]="isLastPage() ? -1 : null">
        <span class="ui-paginator-icon pi pi-caret-right"></span>
      </a>
      <a [attr.tabindex]="isLastPage() ? null : '0'" class="ui-paginator-last ui-paginator-element ui-state-default ui-corner-all"
         (click)="changePageToLast($event)" (keydown.enter)="changePageToLast($event)" [ngClass]="{'ui-state-disabled':isLastPage()}" [tabindex]="isLastPage() ? -1 : null">
        <span class="ui-paginator-icon pi pi-step-forward"></span>
      </a>
      <p-dropdown [options]="rowsPerPageItems" [(ngModel)]="rows" *ngIf="rowsPerPageOptions"
                  (onChange)="onRppChange($event)" [autoWidth]="false" [appendTo]="dropdownAppendTo"></p-dropdown>
      <div class="ui-paginator-right-content" *ngIf="templateRight">
        <ng-container *ngTemplateOutlet="templateRight; context: {$implicit: paginatorState}"></ng-container>
      </div>
    </div>
  `
} )
export class Paginator implements OnInit {

  @Input() pageLinkSize : number = 5;

  @Output() onPageChange : EventEmitter<any> = new EventEmitter();

  @Input() style : any;

  @Input() styleClass : string;

  @Input() alwaysShow : boolean = true;

  @Input() templateLeft : TemplateRef<any>;

  @Input() templateRight : TemplateRef<any>;

  @Input() dropdownAppendTo : any;

  pageLinks : number[];

  _totalRecords : number = 0;

  _first : number = 0;

  _rows : number = 0;

  _rowsPerPageOptions : number[];

  rowsPerPageItems : SelectItem[];

  paginatorState : any;

  ngOnInit () {
    this.updatePaginatorState();
  }

  @Input() get totalRecords () : number {
    return this._totalRecords;
  }

  set totalRecords ( val : number ) {
    this._totalRecords = val;
    this.updatePageLinks();
    this.updatePaginatorState();
  }

  @Input() get first () : number {
    return this._first;
  }

  set first ( val : number ) {
    this._first = val;
    this.updatePageLinks();
    this.updatePaginatorState();
  }

  @Input() get rows () : number {
    return this._rows;
  }

  set rows ( val : number ) {
    this._rows = val;
    this.updatePageLinks();
    this.updatePaginatorState();
  }

  @Input() get rowsPerPageOptions () : number[] {
    return this._rowsPerPageOptions;
  }

  set rowsPerPageOptions ( val : number[] ) {
    this._rowsPerPageOptions = val;
    if ( this._rowsPerPageOptions ) {
      this.rowsPerPageItems = [];
      for ( let opt of this._rowsPerPageOptions ) {
        this.rowsPerPageItems.push( { label : String( opt ), value : opt } );
      }
    }
  }

  isFirstPage () {
    return this.getPage() === 0;
  }

  isLastPage () {
    return this.getPage() === this.getPageCount() - 1;
  }

  getPageCount () {
    return Math.ceil( this.totalRecords / this.rows ) || 1;
  }

  calculatePageLinkBoundaries () {
    let numberOfPages = this.getPageCount(),
        visiblePages  = Math.min( this.pageLinkSize, numberOfPages );

    //calculate range, keep current in middle if necessary
    let start = Math.max( 0, Math.ceil( this.getPage() - ( ( visiblePages ) / 2 ) ) ),
        end   = Math.min( numberOfPages - 1, start + visiblePages - 1 );

    //check when approaching to last page
    var delta = this.pageLinkSize - ( end - start + 1 );
    start     = Math.max( 0, start - delta );

    return [ start, end ];
  }

  updatePageLinks () {
    this.pageLinks = [];
    let boundaries = this.calculatePageLinkBoundaries(),
        start      = boundaries[ 0 ],
        end        = boundaries[ 1 ];

    for ( let i = start; i <= end; i++ ) {
      this.pageLinks.push( i + 1 );
    }
  }

  changePage ( p : number ) {
    var pc = this.getPageCount();

    if ( p >= 0 && p < pc ) {
      this.first = this.rows * p;
      var state  = {
        page      : p,
        first     : this.first,
        rows      : this.rows,
        pageCount : pc
      };
      this.updatePageLinks();

      this.onPageChange.emit( state );
      this.updatePaginatorState();
    }
  }

  getPage () : number {
    return Math.floor( this.first / this.rows );
  }

  changePageToFirst ( event ) {
    if ( !this.isFirstPage() ) {
      this.changePage( 0 );
    }

    event.preventDefault();
  }

  changePageToPrev ( event ) {
    this.changePage( this.getPage() - 1 );
    event.preventDefault();
  }

  changePageToNext ( event ) {
    this.changePage( this.getPage() + 1 );
    event.preventDefault();
  }

  changePageToLast ( event ) {
    if ( !this.isLastPage() ) {
      this.changePage( this.getPageCount() - 1 );
    }

    event.preventDefault();
  }

  onPageLinkClick ( event, page ) {
    this.changePage( page );
    event.preventDefault();
  }

  onRppChange ( event ) {
    this.changePage( this.getPage() );
  }

  updatePaginatorState () {
    this.paginatorState = {
      page         : this.getPage(),
      rows         : this.rows,
      first        : this.first,
      totalRecords : this.totalRecords
    };
  }
}

@NgModule( {
  imports      : [ CommonModule, DropdownModule, FormsModule, SharedModule ],
  exports      : [ Paginator, DropdownModule, FormsModule, SharedModule ],
  declarations : [ Paginator ]
} )
export class PaginatorModule {
}
