import {SearchTokenType} from './search-token';

/**
 * A token object.
 */
export class ParserToken {
  type: SearchTokenType;
  token: string;      // The token stripped of unnecessary whitespace
  rawToken: string;   // The token exactly how the user entered it
  start: number;      // The rawToken's starting index position
  end: number;        // The rawToken's ending index position

  /**
   * Default constructor.
   *
   * @param type the identifier for the token class
   * @param token the token value, stripped of unnecessary whitespace
   * @param rawToken the token value, exactly how the user entered it
   * @param start the rawToken's starting index position
   * @param end the rawToken's ending index position
   */
  constructor(type: SearchTokenType, token: string, rawToken: string, start: number, end: number) {
    this.type = type;
    this.token = token;
    this.rawToken = rawToken;
    this.start = start;
    this.end = end;
  }
}
