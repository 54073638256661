import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Injectable,
  Input,
  NgModule,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild
}                                      from '@angular/core';
import { CommonModule }                from '@angular/common';
import { TreeNode }                    from '../common/tree-node';
import { Subject, Subscription }       from 'rxjs';
import { DomHandler }                  from '../dom/dom-handler';
import { PaginatorModule }             from '../paginator/paginator';
import { PrimeTemplate, SharedModule } from '../common/shared';
import { SortMeta }                    from '../common/sort-meta';
import { BlockableUI }                 from '../common/blockable-ui';
import { ObjectUtils }                 from '../utils/object-utils';

@Injectable()
export class TreeTableService {

  private sortSource        = new Subject<SortMeta | SortMeta[]>();
  private selectionSource   = new Subject();
  private contextMenuSource = new Subject<any>();
  private uiUpdateSource    = new Subject<any>();

  sortSource$        = this.sortSource.asObservable();
  selectionSource$   = this.selectionSource.asObservable();
  contextMenuSource$ = this.contextMenuSource.asObservable();
  uiUpdateSource$    = this.uiUpdateSource.asObservable();

  onSort ( sortMeta : SortMeta | SortMeta[] ) {
    this.sortSource.next( sortMeta );
  }

  onSelectionChange () {
    this.selectionSource.next();
  }

  onContextMenu ( node : any ) {
    this.contextMenuSource.next( node );
  }

  onUIUpdate ( value : any ) {
    this.uiUpdateSource.next( value );
  }
}

@Component( {
  selector  : 'p-treeTable',
  template  : `
    <div #container [ngStyle]="style" [class]="styleClass"
         [ngClass]="{'ui-treetable ui-widget': true, 'ui-treetable-auto-layout': autoLayout, 'ui-treetable-hoverable-rows': (rowHover||(selectionMode === 'single' || selectionMode === 'multiple')),
                'ui-treetable-resizable': resizableColumns, 'ui-treetable-resizable-fit': (resizableColumns && columnResizeMode === 'fit')}">
      <div class="ui-treetable-loading ui-widget-overlay" *ngIf="loading"></div>
      <div class="ui-treetable-loading-content" *ngIf="loading">
        <svg class="sym-smbl__progress-spinner sym-smbl--medium">
          <use href="#sym-smbl__progress-spinner"></use>
        </svg>
      </div>
      <div *ngIf="captionTemplate" class="ui-treetable-caption ui-widget-header">
        <ng-container *ngTemplateOutlet="captionTemplate"></ng-container>
      </div>
      <p-paginator [rows]="rows" [first]="first" [totalRecords]="totalRecords"
                   [pageLinkSize]="pageLinks" styleClass="ui-paginator-top"
                   [alwaysShow]="alwaysShowPaginator"
                   (onPageChange)="onPageChange($event)"
                   [rowsPerPageOptions]="rowsPerPageOptions"
                   *ngIf="paginator && (paginatorPosition === 'top' || paginatorPosition =='both')"
                   [templateLeft]="paginatorLeftTemplate"
                   [templateRight]="paginatorRightTemplate"
                   [dropdownAppendTo]="paginatorDropdownAppendTo"></p-paginator>

      <div class="ui-treetable-wrapper" *ngIf="!scrollable">
        <table #table class="ui-treetable-table">
          <ng-container *ngTemplateOutlet="colGroupTemplate; context {$implicit: columns}"></ng-container>
          <thead class="ui-treetable-thead">
          <ng-container *ngTemplateOutlet="headerTemplate; context: {$implicit: columns}"></ng-container>
          </thead>
          <tfoot class="ui-treetable-tfoot">
          <ng-container *ngTemplateOutlet="footerTemplate; context {$implicit: columns}"></ng-container>
          </tfoot>
          <tbody *ngIf="loading">
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          </tbody>
          <tbody class="ui-treetable-tbody" [pTreeTableBody]="columns" [pTreeTableBodyTemplate]="bodyTemplate"></tbody>
        </table>
      </div>

      <div class="ui-treetable-scrollable-wrapper" *ngIf="scrollable">
        <div class="ui-treetable-scrollable-view ui-treetable-frozen-view"
             *ngIf="frozenColumns||frozenBodyTemplate" [ttScrollableView]="frozenColumns"
             [frozen]="true" [ngStyle]="{width: frozenWidth}"
             [scrollHeight]="scrollHeight"></div>
        <div class="ui-treetable-scrollable-view" [ttScrollableView]="columns"
             [frozen]="false" [scrollHeight]="scrollHeight"></div>
      </div>

      <p-paginator [rows]="rows" [first]="first" [totalRecords]="totalRecords"
                   [pageLinkSize]="pageLinks" styleClass="ui-paginator-bottom"
                   [alwaysShow]="alwaysShowPaginator"
                   (onPageChange)="onPageChange($event)"
                   [rowsPerPageOptions]="rowsPerPageOptions"
                   *ngIf="paginator && (paginatorPosition === 'bottom' || paginatorPosition =='both')"
                   [templateLeft]="paginatorLeftTemplate"
                   [templateRight]="paginatorRightTemplate"
                   [dropdownAppendTo]="paginatorDropdownAppendTo"></p-paginator>
      <div *ngIf="summaryTemplate" class="ui-treetable-summary ui-widget-header">
        <ng-container *ngTemplateOutlet="summaryTemplate"></ng-container>
      </div>

      <div #resizeHelper class="ui-column-resizer-helper ui-state-highlight"
           style="display:none" *ngIf="resizableColumns"></div>

      <span #reorderIndicatorUp class="pi pi-arrow-down ui-table-reorder-indicator-up"
            *ngIf="reorderableColumns"></span>
      <span #reorderIndicatorDown class="pi pi-arrow-up ui-table-reorder-indicator-down"
            *ngIf="reorderableColumns"></span>
    </div>
  `,
  providers : [ DomHandler, ObjectUtils, TreeTableService ]
} )
export class TreeTable implements AfterContentInit, OnInit, BlockableUI {

  @Input() columns : any[];

  @Input() style : any;

  @Input() styleClass : string;

  @Input() autoLayout : boolean;

  @Input() lazy : boolean = false;

  @Input() paginator : boolean;

  @Input() rows : number;

  @Input() first : number = 0;

  @Input() totalRecords : number = 0;

  @Input() pageLinks : number = 5;

  @Input() rowsPerPageOptions : number[];

  @Input() alwaysShowPaginator : boolean = true;

  @Input() paginatorPosition : string = 'bottom';

  @Input() paginatorDropdownAppendTo : any;

  @Input() defaultSortOrder : number = 1;

  @Input() sortMode : string = 'single';

  @Input() resetPageOnSort : boolean = true;

  @Input() customSort : boolean;

  @Input() selectionMode : string;

  @Output() selectionChange : EventEmitter<any> = new EventEmitter();

  @Input() contextMenuSelection : any;

  @Output() contextMenuSelectionChange : EventEmitter<any> = new EventEmitter();

  @Input() contextMenuSelectionMode : string = 'separate';

  @Input() dataKey : string;

  @Input() metaKeySelection : boolean;

  @Input() compareSelectionBy : string = 'deepEquals';

  @Input() rowHover : boolean;

  @Input() loading : boolean;

  @Input() loadingIcon : string = 'pi pi-spinner';

  @Input() scrollable : boolean;

  @Input() scrollHeight : string;

  @Input() frozenWidth : string;

  @Input() frozenColumns : any[];

  @Input() resizableColumns : boolean;

  @Input() columnResizeMode : string = 'fit';

  @Input() reorderableColumns : boolean;

  @Input() contextMenu : any;

  @Input() rowTrackBy : Function = ( index : number, item : any ) => item;

  @Output() onNodeExpand : EventEmitter<any> = new EventEmitter();

  @Output() onNodeCollapse : EventEmitter<any> = new EventEmitter();

  @Output() onPage : EventEmitter<any> = new EventEmitter();

  @Output() onSort : EventEmitter<any> = new EventEmitter();

  @Output() onLazyLoad : EventEmitter<any> = new EventEmitter();

  @Output() sortFunction : EventEmitter<any> = new EventEmitter();

  @Output() onColResize : EventEmitter<any> = new EventEmitter();

  @Output() onColReorder : EventEmitter<any> = new EventEmitter();

  @Output() onNodeSelect : EventEmitter<any> = new EventEmitter();

  @Output() onNodeUnselect : EventEmitter<any> = new EventEmitter();

  @Output() onContextMenuSelect : EventEmitter<any> = new EventEmitter();

  @Output() onHeaderCheckboxToggle : EventEmitter<any> = new EventEmitter();

  @ViewChild( 'container', { static : false } ) containerViewChild : ElementRef;

  @ViewChild( 'resizeHelper', { static : false } ) resizeHelperViewChild : ElementRef;

  @ViewChild( 'reorderIndicatorUp', { static : false } ) reorderIndicatorUpViewChild : ElementRef;

  @ViewChild( 'reorderIndicatorDown', { static : false } ) reorderIndicatorDownViewChild : ElementRef;

  @ViewChild( 'table', { static : false } ) tableViewChild : ElementRef;

  @ContentChildren( PrimeTemplate ) templates : QueryList<PrimeTemplate>;

  _value : TreeNode[] = [];

  serializedValue : any[];

  _multiSortMeta : SortMeta[];

  _sortField : string;

  _sortOrder : number = 1;

  colGroupTemplate : TemplateRef<any>;

  captionTemplate : TemplateRef<any>;

  headerTemplate : TemplateRef<any>;

  bodyTemplate : TemplateRef<any>;

  footerTemplate : TemplateRef<any>;

  summaryTemplate : TemplateRef<any>;

  emptyMessageTemplate : TemplateRef<any>;

  paginatorLeftTemplate : TemplateRef<any>;

  paginatorRightTemplate : TemplateRef<any>;

  frozenHeaderTemplate : TemplateRef<any>;

  frozenBodyTemplate : TemplateRef<any>;

  frozenFooterTemplate : TemplateRef<any>;

  frozenColGroupTemplate : TemplateRef<any>;

  lastResizerHelperX : number;

  reorderIconWidth : number;

  reorderIconHeight : number;

  draggedColumn : any;

  dropPosition : number;

  preventSelectionSetterPropagation : boolean;

  _selection : any;

  selectionKeys : any = {};

  rowTouched : boolean;

  initialized : boolean;

  toggleRowIndex : number;

  ngOnInit () {
    if ( this.lazy ) {
      this.onLazyLoad.emit( this.createLazyLoadMetadata() );
    }
    this.initialized = true;
  }

  ngAfterContentInit () {
    this.templates.forEach( ( item ) => {
      switch ( item.getType() ) {
        case 'caption':
          this.captionTemplate = item.template;
          break;

        case 'header':
          this.headerTemplate = item.template;
          break;

        case 'body':
          this.bodyTemplate = item.template;
          break;

        case 'footer':
          this.footerTemplate = item.template;
          break;

        case 'summary':
          this.summaryTemplate = item.template;
          break;

        case 'colgroup':
          this.colGroupTemplate = item.template;
          break;

        case 'emptymessage':
          this.emptyMessageTemplate = item.template;
          break;

        case 'paginatorleft':
          this.paginatorLeftTemplate = item.template;
          break;

        case 'paginatorright':
          this.paginatorRightTemplate = item.template;
          break;

        case 'frozenheader':
          this.frozenHeaderTemplate = item.template;
          break;

        case 'frozenbody':
          this.frozenBodyTemplate = item.template;
          break;

        case 'frozenfooter':
          this.frozenFooterTemplate = item.template;
          break;

        case 'frozencolgroup':
          this.frozenColGroupTemplate = item.template;
          break;
      }
    } );
  }

  constructor ( public el : ElementRef, public domHandler : DomHandler, public objectUtils : ObjectUtils, public zone : NgZone, public tableService : TreeTableService ) {
  }

  @Input() get value () : any[] {
    return this._value;
  }

  set value ( val : any[] ) {
    this._value = val;

    if ( !this.lazy ) {
      this.totalRecords = ( this._value ? this._value.length : 0 );

      if ( this.sortMode == 'single' && this.sortField )
        this.sortSingle();
      else if ( this.sortMode == 'multiple' && this.multiSortMeta )
        this.sortMultiple();
    }

    this.updateSerializedValue();
    this.tableService.onUIUpdate( this.value );
  }

  updateSerializedValue () {
    this.serializedValue = [];

    if ( this.paginator )
      this.serializePageNodes();
    else
      this.serializeNodes( null, this.value, 0, true );
  }

  serializeNodes ( parent, nodes, level, visible ) {
    if ( nodes && nodes.length ) {
      for ( let node of nodes ) {
        node.parent   = parent;
        const rowNode = {
          node    : node,
          parent  : parent,
          level   : level,
          visible : visible && ( parent ? parent.expanded : true )
        };
        this.serializedValue.push( rowNode );

        this.serializeNodes( node, node.children, level + 1, rowNode.visible );
      }
    }
  }

  serializePageNodes () {
    this.serializedValue = [];
    if ( this.value && this.value.length ) {
      const first = this.lazy ? 0 : this.first;

      for ( let i = first; i < ( first + this.rows ); i++ ) {
        let node = this.value[ i ];
        if ( node ) {
          this.serializedValue.push( {
            node    : node,
            parent  : null,
            level   : 0,
            visible : true
          } );

          this.serializeNodes( node, node.children, 1, true );
        }
      }
    }
  }

  @Input() get sortField () : string {
    return this._sortField;
  }

  set sortField ( val : string ) {
    this._sortField = val;

    //avoid triggering lazy load prior to lazy initialization at onInit
    if ( !this.lazy || this.initialized ) {
      if ( this.sortMode === 'single' ) {
        this.sortSingle();
      }
    }
  }

  @Input() get sortOrder () : number {
    return this._sortOrder;
  }

  set sortOrder ( val : number ) {
    this._sortOrder = val;

    //avoid triggering lazy load prior to lazy initialization at onInit
    if ( !this.lazy || this.initialized ) {
      if ( this.sortMode === 'single' ) {
        this.sortSingle();
      }
    }
  }

  @Input() get multiSortMeta () : SortMeta[] {
    return this._multiSortMeta;
  }

  set multiSortMeta ( val : SortMeta[] ) {
    this._multiSortMeta = val;
    if ( this.sortMode === 'multiple' ) {
      this.sortMultiple();
    }
  }

  @Input() get selection () : any {
    return this._selection;
  }

  set selection ( val : any ) {
    this._selection = val;

    if ( !this.preventSelectionSetterPropagation ) {
      this.updateSelectionKeys();
      this.tableService.onSelectionChange();
    }
    this.preventSelectionSetterPropagation = false;
  }

  updateSelectionKeys () {
    if ( this.dataKey && this._selection ) {
      this.selectionKeys = {};
      if ( Array.isArray( this._selection ) ) {
        for ( let node of this._selection ) {
          this.selectionKeys[ String( this.objectUtils.resolveFieldData( node.data, this.dataKey ) ) ] = 1;
        }
      } else {
        this.selectionKeys[ String( this.objectUtils.resolveFieldData( this._selection.data, this.dataKey ) ) ] = 1;
      }
    }
  }

  onPageChange ( event ) {
    this.first = event.first;
    this.rows  = event.rows;

    if ( this.lazy )
      this.onLazyLoad.emit( this.createLazyLoadMetadata() );
    else
      this.serializePageNodes();

    this.onPage.emit( {
      first : this.first,
      rows  : this.rows
    } );

    this.tableService.onUIUpdate( this.value );
  }

  sort ( event ) {
    let originalEvent = event.originalEvent;

    if ( this.sortMode === 'single' ) {
      this._sortOrder = ( this.sortField === event.field ) ? this.sortOrder * -1 : this.defaultSortOrder;
      this._sortField = event.field;
      this.sortSingle();
    }
    if ( this.sortMode === 'multiple' ) {
      let metaKey  = originalEvent.metaKey || originalEvent.ctrlKey;
      let sortMeta = this.getSortMeta( event.field );

      if ( sortMeta ) {
        if ( !metaKey ) {
          this._multiSortMeta = [ { field : event.field, order : sortMeta.order * -1 } ];
        } else {
          sortMeta.order = sortMeta.order * -1;
        }
      } else {
        if ( !metaKey || !this.multiSortMeta ) {
          this._multiSortMeta = [];
        }
        this.multiSortMeta.push( { field : event.field, order : this.defaultSortOrder } );
      }

      this.sortMultiple();
    }
  }

  sortSingle () {
    if ( this.sortField && this.sortOrder ) {
      if ( this.resetPageOnSort ) {
        this.first = 0;
      }

      if ( this.lazy ) {
        this.onLazyLoad.emit( this.createLazyLoadMetadata() );
      } else if ( this.value ) {
        this.sortNodes( this.value );
      }

      let sortMeta : SortMeta = {
        field : this.sortField,
        order : this.sortOrder
      };

      this.onSort.emit( sortMeta );
      this.tableService.onSort( sortMeta );
      this.updateSerializedValue();
    }
  }

  sortNodes ( nodes ) {
    if ( !nodes || nodes.length === 0 ) {
      return;
    }

    if ( this.customSort ) {
      this.sortFunction.emit( {
        data  : nodes,
        mode  : this.sortMode,
        field : this.sortField,
        order : this.sortOrder
      } );
    } else {
      nodes.sort( ( node1, node2 ) => {
        let value1 = this.objectUtils.resolveFieldData( node1.data, this.sortField );
        let value2 = this.objectUtils.resolveFieldData( node2.data, this.sortField );
        let result = null;

        if ( value1 == null && value2 != null )
          result = -1;
        else if ( value1 != null && value2 == null )
          result = 1;
        else if ( value1 == null && value2 == null )
          result = 0;
        else if ( typeof value1 === 'string' && typeof value2 === 'string' )
          result = value1.localeCompare( value2, undefined, { numeric : true } );
        else
          result = ( value1 < value2 ) ? -1 : ( value1 > value2 ) ? 1 : 0;

        return ( this.sortOrder * result );
      } );
    }

    for ( let node of nodes ) {
      this.sortNodes( node.children );
    }
  }

  sortMultiple () {
    if ( this.multiSortMeta ) {
      if ( this.lazy ) {
        this.onLazyLoad.emit( this.createLazyLoadMetadata() );
      } else if ( this.value ) {
        this.sortMultipleNodes( this.value );
      }

      this.onSort.emit( {
        multisortmeta : this.multiSortMeta
      } );
      this.tableService.onSort( this.multiSortMeta );
      this.updateSerializedValue();
    }
  }

  sortMultipleNodes ( nodes ) {
    if ( !nodes || nodes.length === 0 ) {
      return;
    }

    if ( this.customSort ) {
      this.sortFunction.emit( {
        data          : this.value,
        mode          : this.sortMode,
        multiSortMeta : this.multiSortMeta
      } );
    } else {
      this.value.sort( ( node1, node2 ) => {
        return this.multisortField( node1, node2, this.multiSortMeta, 0 );
      } );
    }

    for ( let node of nodes ) {
      this.sortMultipleNodes( node.children );
    }
  }

  multisortField ( node1, node2, multiSortMeta, index ) {
    let value1 = this.objectUtils.resolveFieldData( node1.data, multiSortMeta[ index ].field );
    let value2 = this.objectUtils.resolveFieldData( node2.data, multiSortMeta[ index ].field );
    let result = null;

    if ( value1 == null && value2 != null )
      result = -1;
    else if ( value1 != null && value2 == null )
      result = 1;
    else if ( value1 == null && value2 == null )
      result = 0;
    if ( typeof value1 == 'string' || value1 instanceof String ) {
      if ( value1.localeCompare && ( value1 != value2 ) ) {
        return ( multiSortMeta[ index ].order * value1.localeCompare( value2, undefined, { numeric : true } ) );
      }
    } else {
      result = ( value1 < value2 ) ? -1 : 1;
    }

    if ( value1 == value2 ) {
      return ( multiSortMeta.length - 1 ) > ( index ) ? ( this.multisortField( node1, node2, multiSortMeta, index + 1 ) ) : 0;
    }

    return ( multiSortMeta[ index ].order * result );
  }

  getSortMeta ( field : string ) {
    if ( this.multiSortMeta && this.multiSortMeta.length ) {
      for ( let i = 0; i < this.multiSortMeta.length; i++ ) {
        if ( this.multiSortMeta[ i ].field === field ) {
          return this.multiSortMeta[ i ];
        }
      }
    }

    return null;
  }

  isSorted ( field : string ) {
    if ( this.sortMode === 'single' ) {
      return ( this.sortField && this.sortField === field );
    } else if ( this.sortMode === 'multiple' ) {
      let sorted = false;
      if ( this.multiSortMeta ) {
        for ( let i = 0; i < this.multiSortMeta.length; i++ ) {
          if ( this.multiSortMeta[ i ].field == field ) {
            sorted = true;
            break;
          }
        }
      }
      return sorted;
    }
  }

  createLazyLoadMetadata () : any {
    return {
      first         : this.first,
      rows          : this.rows,
      sortField     : this.sortField,
      sortOrder     : this.sortOrder,
      multiSortMeta : this.multiSortMeta
    };
  }

  isEmpty () {
    return this.value == null || this.value.length == 0;
  }

  getBlockableElement () : HTMLElement {
    return this.el.nativeElement.children[ 0 ];
  }

  onColumnResizeBegin ( event ) {
    let containerLeft       = this.domHandler.getOffset( this.containerViewChild.nativeElement ).left;
    this.lastResizerHelperX = ( event.pageX - containerLeft + this.containerViewChild.nativeElement.scrollLeft );
    event.preventDefault();
  }

  onColumnResize ( event ) {
    let containerLeft = this.domHandler.getOffset( this.containerViewChild.nativeElement ).left;
    this.domHandler.addClass( this.containerViewChild.nativeElement, 'ui-unselectable-text' );
    this.resizeHelperViewChild.nativeElement.style.height = this.containerViewChild.nativeElement.offsetHeight + 'px';
    this.resizeHelperViewChild.nativeElement.style.top    = 0 + 'px';
    this.resizeHelperViewChild.nativeElement.style.left   = ( event.pageX - containerLeft + this.containerViewChild.nativeElement.scrollLeft ) + 'px';

    this.resizeHelperViewChild.nativeElement.style.display = 'block';
  }

  onColumnResizeEnd ( event, column ) {
    let delta          = this.resizeHelperViewChild.nativeElement.offsetLeft - this.lastResizerHelperX;
    let columnWidth    = column.offsetWidth;
    let newColumnWidth = columnWidth + delta;
    let minWidth       = column.style.minWidth || 15;
    let scrollableView, scrollableBodyTable, scrollableHeaderTable, scrollableFooterTable, resizeColumnIndex;

    if ( columnWidth + delta > parseInt( minWidth ) ) {
      if ( this.columnResizeMode === 'fit' ) {
        let nextColumn = column.nextElementSibling;
        while ( !nextColumn.offsetParent ) {
          nextColumn = nextColumn.nextElementSibling;
        }

        if ( nextColumn ) {
          let nextColumnWidth    = nextColumn.offsetWidth - delta;
          let nextColumnMinWidth = nextColumn.style.minWidth || 15;

          if ( newColumnWidth > 15 && nextColumnWidth > parseInt( nextColumnMinWidth ) ) {
            if ( this.scrollable ) {
              scrollableView        = this.findParentScrollableView( column );
              scrollableBodyTable   = this.domHandler.findSingle( scrollableView, 'table.ui-treetable-scrollable-body-table' );
              scrollableHeaderTable = this.domHandler.findSingle( scrollableView, 'table.ui-treetable-scrollable-header-table' );
              scrollableFooterTable = this.domHandler.findSingle( scrollableView, 'table.ui-treetable-scrollable-footer-table' );
              resizeColumnIndex     = this.domHandler.index( column );

              this.resizeColGroup( scrollableHeaderTable, resizeColumnIndex, newColumnWidth, nextColumnWidth );
              this.resizeColGroup( scrollableBodyTable, resizeColumnIndex, newColumnWidth, nextColumnWidth );
              this.resizeColGroup( scrollableFooterTable, resizeColumnIndex, newColumnWidth, nextColumnWidth );
            } else {
              column.style.width = newColumnWidth + 'px';
              if ( nextColumn ) {
                nextColumn.style.width = nextColumnWidth + 'px';
              }
            }
          }
        }
      } else if ( this.columnResizeMode === 'expand' ) {
        if ( this.scrollable ) {
          scrollableView                    = this.findParentScrollableView( column );
          scrollableBodyTable               = this.domHandler.findSingle( scrollableView, 'table.ui-treetable-scrollable-body-table' );
          scrollableHeaderTable             = this.domHandler.findSingle( scrollableView, 'table.ui-treetable-scrollable-header-table' );
          scrollableFooterTable             = this.domHandler.findSingle( scrollableView, 'table.ui-treetable-scrollable-footer-table' );
          scrollableBodyTable.style.width   = scrollableBodyTable.offsetWidth + delta + 'px';
          scrollableHeaderTable.style.width = scrollableHeaderTable.offsetWidth + delta + 'px';
          if ( scrollableFooterTable ) {
            scrollableFooterTable.style.width = scrollableHeaderTable.offsetWidth + delta + 'px';
          }
          resizeColumnIndex = this.domHandler.index( column );

          this.resizeColGroup( scrollableHeaderTable, resizeColumnIndex, newColumnWidth, null );
          this.resizeColGroup( scrollableBodyTable, resizeColumnIndex, newColumnWidth, null );
          this.resizeColGroup( scrollableFooterTable, resizeColumnIndex, newColumnWidth, null );
        } else {
          this.tableViewChild.nativeElement.style.width     = this.tableViewChild.nativeElement.offsetWidth + delta + 'px';
          column.style.width                                = newColumnWidth + 'px';
          let containerWidth                                = this.tableViewChild.nativeElement.style.width;
          this.containerViewChild.nativeElement.style.width = containerWidth + 'px';
        }
      }

      this.onColResize.emit( {
        element : column,
        delta   : delta
      } );
    }

    this.resizeHelperViewChild.nativeElement.style.display = 'none';
    this.domHandler.removeClass( this.containerViewChild.nativeElement, 'ui-unselectable-text' );
  }

  findParentScrollableView ( column ) {
    if ( column ) {
      let parent = column.parentElement;
      while ( parent && !this.domHandler.hasClass( parent, 'ui-treetable-scrollable-view' ) ) {
        parent = parent.parentElement;
      }

      return parent;
    } else {
      return null;
    }
  }

  resizeColGroup ( table, resizeColumnIndex, newColumnWidth, nextColumnWidth ) {
    if ( table ) {
      let colGroup = table.children[ 0 ].nodeName === 'COLGROUP' ? table.children[ 0 ] : null;

      if ( colGroup ) {
        let col         = colGroup.children[ resizeColumnIndex ];
        let nextCol     = col.nextElementSibling;
        col.style.width = newColumnWidth + 'px';

        if ( nextCol && nextColumnWidth ) {
          nextCol.style.width = nextColumnWidth + 'px';
        }
      } else {
        throw 'Scrollable tables require a colgroup to support resizable columns';
      }
    }
  }

  onColumnDragStart ( event, columnElement ) {
    this.reorderIconWidth  = this.domHandler.getHiddenElementOuterWidth( this.reorderIndicatorUpViewChild.nativeElement );
    this.reorderIconHeight = this.domHandler.getHiddenElementOuterHeight( this.reorderIndicatorDownViewChild.nativeElement );
    this.draggedColumn     = columnElement;
    event.dataTransfer.setData( 'text', 'b' );    // For firefox
  }

  onColumnDragEnter ( event, dropHeader ) {
    if ( this.reorderableColumns && this.draggedColumn && dropHeader ) {
      event.preventDefault();
      let containerOffset  = this.domHandler.getOffset( this.containerViewChild.nativeElement );
      let dropHeaderOffset = this.domHandler.getOffset( dropHeader );

      if ( this.draggedColumn != dropHeader ) {
        let targetLeft   = dropHeaderOffset.left - containerOffset.left;
        let columnCenter = dropHeaderOffset.left + dropHeader.offsetWidth / 2;

        this.reorderIndicatorUpViewChild.nativeElement.style.top   = dropHeaderOffset.top - containerOffset.top - ( this.reorderIconHeight - 1 ) + 'px';
        this.reorderIndicatorDownViewChild.nativeElement.style.top = dropHeaderOffset.top - containerOffset.top + dropHeader.offsetHeight + 'px';

        if ( event.pageX > columnCenter ) {
          this.reorderIndicatorUpViewChild.nativeElement.style.left   = ( targetLeft + dropHeader.offsetWidth - Math.ceil( this.reorderIconWidth / 2 ) ) + 'px';
          this.reorderIndicatorDownViewChild.nativeElement.style.left = ( targetLeft + dropHeader.offsetWidth - Math.ceil( this.reorderIconWidth / 2 ) ) + 'px';
          this.dropPosition                                           = 1;
        } else {
          this.reorderIndicatorUpViewChild.nativeElement.style.left   = ( targetLeft - Math.ceil( this.reorderIconWidth / 2 ) ) + 'px';
          this.reorderIndicatorDownViewChild.nativeElement.style.left = ( targetLeft - Math.ceil( this.reorderIconWidth / 2 ) ) + 'px';
          this.dropPosition                                           = -1;
        }

        this.reorderIndicatorUpViewChild.nativeElement.style.display   = 'block';
        this.reorderIndicatorDownViewChild.nativeElement.style.display = 'block';
      } else {
        event.dataTransfer.dropEffect = 'none';
      }
    }
  }

  onColumnDragLeave ( event ) {
    if ( this.reorderableColumns && this.draggedColumn ) {
      event.preventDefault();
      this.reorderIndicatorUpViewChild.nativeElement.style.display   = 'none';
      this.reorderIndicatorDownViewChild.nativeElement.style.display = 'none';
    }
  }

  onColumnDrop ( event, dropColumn ) {
    event.preventDefault();
    if ( this.draggedColumn ) {
      let dragIndex = this.domHandler.indexWithinGroup( this.draggedColumn, 'ttreorderablecolumn' );
      let dropIndex = this.domHandler.indexWithinGroup( dropColumn, 'ttreorderablecolumn' );
      let allowDrop = ( dragIndex != dropIndex );
      if ( allowDrop && ( ( dropIndex - dragIndex == 1 && this.dropPosition === -1 ) || ( dragIndex - dropIndex == 1 && this.dropPosition === 1 ) ) ) {
        allowDrop = false;
      }

      if ( allowDrop ) {
        this.objectUtils.reorderArray( this.columns, dragIndex, dropIndex );

        this.onColReorder.emit( {
          dragIndex : dragIndex,
          dropIndex : dropIndex,
          columns   : this.columns
        } );
      }

      this.reorderIndicatorUpViewChild.nativeElement.style.display   = 'none';
      this.reorderIndicatorDownViewChild.nativeElement.style.display = 'none';
      this.draggedColumn.draggable                                   = false;
      this.draggedColumn                                             = null;
      this.dropPosition                                              = null;
    }
  }

  handleRowClick ( event ) {
    let targetNode = ( <HTMLElement>event.originalEvent.target ).nodeName;
    if ( targetNode == 'INPUT' || targetNode == 'BUTTON' || targetNode == 'A' || ( this.domHandler.hasClass( event.originalEvent.target, 'ui-clickable' ) ) ) {
      return;
    }

    if ( this.selectionMode ) {
      this.preventSelectionSetterPropagation = true;
      let rowNode                            = event.rowNode;
      let selected                           = this.isSelected( rowNode.node );
      let metaSelection                      = this.rowTouched ? false : this.metaKeySelection;
      let dataKeyValue                       = this.dataKey ? String( this.objectUtils.resolveFieldData( rowNode.node.data, this.dataKey ) ) : null;

      if ( metaSelection ) {
        let metaKey = event.originalEvent.metaKey || event.originalEvent.ctrlKey;

        if ( selected && metaKey ) {
          if ( this.isSingleSelectionMode() ) {
            this._selection    = null;
            this.selectionKeys = {};
            this.selectionChange.emit( null );
          } else {
            let selectionIndex = this.findIndexInSelection( rowNode.node );
            this._selection    = this.selection.filter( ( val, i ) => i != selectionIndex );
            this.selectionChange.emit( this.selection );
            if ( dataKeyValue ) {
              delete this.selectionKeys[ dataKeyValue ];
            }
          }

          this.onNodeUnselect.emit( {
            originalEvent : event.originalEvent,
            node          : rowNode.node,
            type          : 'row'
          } );
        } else {
          if ( this.isSingleSelectionMode() ) {
            this._selection = rowNode.node;
            this.selectionChange.emit( rowNode.node );
            if ( dataKeyValue ) {
              this.selectionKeys                 = {};
              this.selectionKeys[ dataKeyValue ] = 1;
            }
          } else if ( this.isMultipleSelectionMode() ) {
            if ( metaKey ) {
              this._selection = this.selection || [];
            } else {
              this._selection    = [];
              this.selectionKeys = {};
            }

            this._selection = [ ...this.selection, rowNode.node ];
            this.selectionChange.emit( this.selection );
            if ( dataKeyValue ) {
              this.selectionKeys[ dataKeyValue ] = 1;
            }
          }

          this.onNodeSelect.emit( {
            originalEvent : event.originalEvent,
            node          : rowNode.node,
            type          : 'row',
            index         : event.rowIndex
          } );
        }
      } else {
        if ( this.selectionMode === 'single' ) {
          if ( selected ) {
            this._selection    = null;
            this.selectionKeys = {};
            this.selectionChange.emit( this.selection );
            this.onNodeUnselect.emit( {
              originalEvent : event.originalEvent,
              node          : rowNode.node,
              type          : 'row'
            } );
          } else {
            this._selection = rowNode.node;
            this.selectionChange.emit( this.selection );
            this.onNodeSelect.emit( {
              originalEvent : event.originalEvent,
              node          : rowNode.node,
              type          : 'row',
              index         : event.rowIndex
            } );
            if ( dataKeyValue ) {
              this.selectionKeys                 = {};
              this.selectionKeys[ dataKeyValue ] = 1;
            }
          }
        } else if ( this.selectionMode === 'multiple' ) {
          if ( selected ) {
            let selectionIndex = this.findIndexInSelection( rowNode.node );
            this._selection    = this.selection.filter( ( val, i ) => i != selectionIndex );
            this.selectionChange.emit( this.selection );
            this.onNodeUnselect.emit( {
              originalEvent : event.originalEvent,
              node          : rowNode.node,
              type          : 'row'
            } );
            if ( dataKeyValue ) {
              delete this.selectionKeys[ dataKeyValue ];
            }
          } else {
            this._selection = this.selection ? [ ...this.selection, rowNode.node ] : [ rowNode.node ];
            this.selectionChange.emit( this.selection );
            this.onNodeSelect.emit( {
              originalEvent : event.originalEvent,
              node          : rowNode.node,
              type          : 'row',
              index         : event.rowIndex
            } );
            if ( dataKeyValue ) {
              this.selectionKeys[ dataKeyValue ] = 1;
            }
          }
        }
      }

      this.tableService.onSelectionChange();
    }

    this.rowTouched = false;
  }

  handleRowTouchEnd ( event ) {
    this.rowTouched = true;
  }

  handleRowRightClick ( event ) {
    if ( this.contextMenu ) {
      const node = event.rowNode.node;

      if ( this.contextMenuSelectionMode === 'separate' ) {
        this.contextMenuSelection = node;
        this.contextMenuSelectionChange.emit( node );
        this.onContextMenuSelect.emit( { originalEvent : event.originalEvent, node : node } );
        this.contextMenu.show( event.originalEvent );
        this.tableService.onContextMenu( node );
      } else if ( this.contextMenuSelectionMode === 'joint' ) {
        this.preventSelectionSetterPropagation = true;
        let selected                           = this.isSelected( node );
        let dataKeyValue                       = this.dataKey ? String( this.objectUtils.resolveFieldData( node.data, this.dataKey ) ) : null;

        if ( !selected ) {
          if ( this.isSingleSelectionMode() ) {
            this.selection = node;
            this.selectionChange.emit( node );
          } else if ( this.isMultipleSelectionMode() ) {
            this.selection = [ node ];
            this.selectionChange.emit( this.selection );
          }

          if ( dataKeyValue ) {
            this.selectionKeys[ dataKeyValue ] = 1;
          }
        }

        this.contextMenu.show( event.originalEvent );
        this.onContextMenuSelect.emit( { originalEvent : event.originalEvent, node : node } );
      }
    }
  }

  toggleNodeWithCheckbox ( event ) {
    this.preventSelectionSetterPropagation = true;
    let node                               = event.rowNode.node;
    let selected                           = this.isSelected( node );

    if ( selected ) {
      this.propagateSelectionDown( node, false );
      if ( event.rowNode.parent ) {
        this.propagateSelectionUp( node.parent, false );
      }
      this.selectionChange.emit( this.selection );
      this.onNodeUnselect.emit( { originalEvent : event, node : node } );
    } else {
      this.propagateSelectionDown( node, true );
      if ( event.rowNode.parent ) {
        this.propagateSelectionUp( node.parent, true );
      }
      this.selectionChange.emit( this.selection );
      this.onNodeSelect.emit( { originalEvent : event, node : node } );
    }

    this.tableService.onSelectionChange();
  }

  toggleNodesWithCheckbox ( event : Event, check : boolean ) {
    if ( check ) {
      if ( this.value && this.value.length ) {
        for ( let node of this.value ) {
          this.propagateSelectionDown( node, true );
        }
      }
    } else {
      this._selection    = [];
      this.selectionKeys = {};
    }

    this.preventSelectionSetterPropagation = true;
    this.selectionChange.emit( this._selection );
    this.tableService.onSelectionChange();
    this.onHeaderCheckboxToggle.emit( { originalEvent : event, checked : check } );
  }

  propagateSelectionUp ( node : TreeNode, select : boolean ) {
    if ( node.children && node.children.length ) {
      let selectedChildCount : number    = 0;
      let childPartialSelected : boolean = false;
      let dataKeyValue                   = this.dataKey ? String( this.objectUtils.resolveFieldData( node.data, this.dataKey ) ) : null;

      for ( let child of node.children ) {
        if ( this.isSelected( child ) )
          selectedChildCount++;
        else if ( child.partialSelected )
          childPartialSelected = true;
      }

      if ( select && selectedChildCount == node.children.length ) {
        this._selection      = [ ...this.selection || [], node ];
        node.partialSelected = false;
        if ( dataKeyValue ) {
          this.selectionKeys[ dataKeyValue ] = 1;
        }
      } else {
        if ( !select ) {
          let index = this.findIndexInSelection( node );
          if ( index >= 0 ) {
            this._selection = this.selection.filter( ( val, i ) => i != index );

            if ( dataKeyValue ) {
              delete this.selectionKeys[ dataKeyValue ];
            }
          }
        }

        if ( childPartialSelected || selectedChildCount > 0 && selectedChildCount != node.children.length )
          node.partialSelected = true;
        else
          node.partialSelected = false;
      }
    }

    let parent = node.parent;
    if ( parent ) {
      this.propagateSelectionUp( parent, select );
    }
  }

  propagateSelectionDown ( node : TreeNode, select : boolean ) {
    let index        = this.findIndexInSelection( node );
    let dataKeyValue = this.dataKey ? String( this.objectUtils.resolveFieldData( node.data, this.dataKey ) ) : null;

    if ( select && index == -1 ) {
      this._selection = [ ...this.selection || [], node ];
      if ( dataKeyValue ) {
        this.selectionKeys[ dataKeyValue ] = 1;
      }
    } else if ( !select && index > -1 ) {
      this._selection = this.selection.filter( ( val, i ) => i != index );
      if ( dataKeyValue ) {
        delete this.selectionKeys[ dataKeyValue ];
      }
    }

    node.partialSelected = false;

    if ( node.children && node.children.length ) {
      for ( let child of node.children ) {
        this.propagateSelectionDown( child, select );
      }
    }
  }

  isSelected ( node ) {
    if ( node && this.selection ) {
      if ( this.dataKey ) {
        return this.selectionKeys[ this.objectUtils.resolveFieldData( node.data, this.dataKey ) ] !== undefined;
      } else {
        if ( this.selection instanceof Array )
          return this.findIndexInSelection( node ) > -1;
        else
          return this.equals( node, this.selection );
      }
    }

    return false;
  }

  findIndexInSelection ( node : any ) {
    let index : number = -1;
    if ( this.selection && this.selection.length ) {
      for ( let i = 0; i < this.selection.length; i++ ) {
        if ( this.equals( node, this.selection[ i ] ) ) {
          index = i;
          break;
        }
      }
    }

    return index;
  }

  isSingleSelectionMode () {
    return this.selectionMode === 'single';
  }

  isMultipleSelectionMode () {
    return this.selectionMode === 'multiple';
  }

  equals ( node1, node2 ) {
    return this.compareSelectionBy === 'equals' ? ( node1 === node2 ) : this.objectUtils.equals( node1.data, node2.data, this.dataKey );
  }

  public reset () {
    this._sortField     = null;
    this._sortOrder     = 1;
    this._multiSortMeta = null;
    this.tableService.onSort( null );

    this.first = 0;

    if ( this.lazy ) {
      this.onLazyLoad.emit( this.createLazyLoadMetadata() );
    } else {
      this.totalRecords = ( this._value ? this._value.length : 0 );
    }
  }
}

@Component( {
  selector : '[pTreeTableBody]',
  template : `
    <ng-template ngFor let-serializedNode let-rowIndex="index" [ngForOf]="tt.serializedValue"
                 [ngForTrackBy]="tt.rowTrackBy">
      <ng-container *ngIf="serializedNode.visible">
        <ng-container
                *ngTemplateOutlet="template; context: {$implicit: serializedNode, node: serializedNode.node, rowData: serializedNode.node.data, columns: columns}"></ng-container>
      </ng-container>
    </ng-template>
    <ng-container *ngIf="tt.isEmpty()">
      <ng-container
              *ngTemplateOutlet="tt.emptyMessageTemplate; context: {$implicit: columns}"></ng-container>
    </ng-container>
  `
} )
export class TTBody {

  @Input( 'pTreeTableBody' ) columns : any[];

  @Input( 'pTreeTableBodyTemplate' ) template : TemplateRef<any>;

  constructor ( public tt : TreeTable ) {
  }
}

@Component( {
  selector : '[ttScrollableView]',
  template : `
    <div #scrollHeader class="ui-treetable-scrollable-header ui-widget-header">
      <div #scrollHeaderBox class="ui-treetable-scrollable-header-box">
        <table class="ui-treetable-scrollable-header-table">
          <ng-container
                  *ngTemplateOutlet="frozen ? tt.frozenColGroupTemplate||tt.colGroupTemplate : tt.colGroupTemplate; context {$implicit: columns}"></ng-container>
          <thead class="ui-treetable-thead">
          <ng-container
                  *ngTemplateOutlet="frozen ? tt.frozenHeaderTemplate||tt.headerTemplate : tt.headerTemplate; context {$implicit: columns}"></ng-container>
          </thead>
        </table>
      </div>
    </div>
    <div #scrollBody class="ui-treetable-scrollable-body">
      <table #scrollTable class="ui-treetable-scrollable-body-table">
        <ng-container
                *ngTemplateOutlet="frozen ? tt.frozenColGroupTemplate||tt.colGroupTemplate : tt.colGroupTemplate; context {$implicit: columns}"></ng-container>
        <tbody class="ui-treetable-tbody" [pTreeTableBody]="columns"
               [pTreeTableBodyTemplate]="frozen ? tt.frozenBodyTemplate||tt.bodyTemplate : tt.bodyTemplate"></tbody>
      </table>
    </div>
    <div #scrollFooter *ngIf="tt.footerTemplate"
         class="ui-treetable-scrollable-footer ui-widget-header">
      <div #scrollFooterBox class="ui-treetable-scrollable-footer-box">
        <table class="ui-treetable-scrollable-footer-table">
          <ng-container
                  *ngTemplateOutlet="frozen ? tt.frozenColGroupTemplate||tt.colGroupTemplate : tt.colGroupTemplate; context {$implicit: columns}"></ng-container>
          <tfoot class="ui-treetable-tfoot">
          <ng-container
                  *ngTemplateOutlet="frozen ? tt.frozenFooterTemplate||tt.footerTemplate : tt.footerTemplate; context {$implicit: columns}"></ng-container>
          </tfoot>
        </table>
      </div>
    </div>
  `
} )
export class TTScrollableView implements AfterViewInit, OnDestroy, AfterViewChecked {

  @Input( 'ttScrollableView' ) columns : any[];

  @Input() frozen : boolean;

  @ViewChild( 'scrollHeader', { static : false } ) scrollHeaderViewChild : ElementRef;

  @ViewChild( 'scrollHeaderBox', { static : false } ) scrollHeaderBoxViewChild : ElementRef;

  @ViewChild( 'scrollBody', { static : false } ) scrollBodyViewChild : ElementRef;

  @ViewChild( 'scrollTable', { static : false } ) scrollTableViewChild : ElementRef;

  @ViewChild( 'scrollFooter', { static : false } ) scrollFooterViewChild : ElementRef;

  @ViewChild( 'scrollFooterBox', { static : false } ) scrollFooterBoxViewChild : ElementRef;

  headerScrollListener : Function;

  bodyScrollListener : Function;

  footerScrollListener : Function;

  frozenSiblingBody : Element;

  _scrollHeight : string;

  subscription : Subscription;

  initialized : boolean;

  constructor ( public tt : TreeTable, public el : ElementRef, public domHandler : DomHandler, public zone : NgZone ) {
    this.subscription = this.tt.tableService.uiUpdateSource$.subscribe( () => {
      this.zone.runOutsideAngular( () => {
        setTimeout( () => {
          this.alignScrollBar();
        }, 50 );
      } );
    } );

    this.initialized = false;
  }

  @Input() get scrollHeight () : string {
    return this._scrollHeight;
  }

  set scrollHeight ( val : string ) {
    this._scrollHeight = val;
    this.setScrollHeight();
  }

  ngAfterViewChecked () {
    if ( !this.initialized && this.el.nativeElement.offsetParent ) {
      this.alignScrollBar();
      this.initialized = true;
    }
  }

  ngAfterViewInit () {
    this.bindEvents();
    this.setScrollHeight();
    this.alignScrollBar();

    if ( !this.frozen ) {
      if ( this.tt.frozenColumns || this.tt.frozenBodyTemplate ) {
        this.domHandler.addClass( this.el.nativeElement, 'ui-treetable-unfrozen-view' );
      }

      if ( this.tt.frozenWidth ) {
        this.el.nativeElement.style.left  = this.tt.frozenWidth;
        this.el.nativeElement.style.width = 'calc(100% - ' + this.tt.frozenWidth + ')';
      }

      let frozenView = this.el.nativeElement.previousElementSibling;
      if ( frozenView ) {
        this.frozenSiblingBody = this.domHandler.findSingle( frozenView, '.ui-treetable-scrollable-body' );
      }
    } else {
      this.scrollBodyViewChild.nativeElement.style.paddingBottom = this.domHandler.calculateScrollbarWidth() + 'px';
    }
  }

  bindEvents () {
    this.zone.runOutsideAngular( () => {
      if ( this.scrollHeaderViewChild && this.scrollHeaderViewChild.nativeElement ) {
        this.headerScrollListener = this.onHeaderScroll.bind( this );
        this.scrollHeaderBoxViewChild.nativeElement.addEventListener( 'scroll', this.headerScrollListener );
      }

      if ( this.scrollFooterViewChild && this.scrollFooterViewChild.nativeElement ) {
        this.footerScrollListener = this.onFooterScroll.bind( this );
        this.scrollFooterViewChild.nativeElement.addEventListener( 'scroll', this.footerScrollListener );
      }

      if ( !this.frozen ) {
        this.bodyScrollListener = this.onBodyScroll.bind( this );
        this.scrollBodyViewChild.nativeElement.addEventListener( 'scroll', this.bodyScrollListener );
      }
    } );
  }

  unbindEvents () {
    if ( this.scrollHeaderViewChild && this.scrollHeaderViewChild.nativeElement ) {
      this.scrollHeaderBoxViewChild.nativeElement.removeEventListener( 'scroll', this.headerScrollListener );
    }

    if ( this.scrollFooterViewChild && this.scrollFooterViewChild.nativeElement ) {
      this.scrollFooterViewChild.nativeElement.removeEventListener( 'scroll', this.footerScrollListener );
    }

    this.scrollBodyViewChild.nativeElement.addEventListener( 'scroll', this.bodyScrollListener );
  }

  onHeaderScroll ( event ) {
    this.scrollHeaderViewChild.nativeElement.scrollLeft = 0;
  }

  onFooterScroll ( event ) {
    this.scrollFooterViewChild.nativeElement.scrollLeft = 0;
  }

  onBodyScroll ( event ) {
    if ( this.scrollHeaderViewChild && this.scrollHeaderViewChild.nativeElement ) {
      this.scrollHeaderBoxViewChild.nativeElement.style.marginLeft = -1 * this.scrollBodyViewChild.nativeElement.scrollLeft + 'px';
    }

    if ( this.scrollFooterViewChild && this.scrollFooterViewChild.nativeElement ) {
      this.scrollFooterBoxViewChild.nativeElement.style.marginLeft = -1 * this.scrollBodyViewChild.nativeElement.scrollLeft + 'px';
    }

    if ( this.frozenSiblingBody ) {
      this.frozenSiblingBody.scrollTop = this.scrollBodyViewChild.nativeElement.scrollTop;
    }
  }

  setScrollHeight () {
    if ( this.scrollHeight && this.scrollBodyViewChild && this.scrollBodyViewChild.nativeElement ) {
      if ( this.scrollHeight.indexOf( '%' ) !== -1 ) {
        let relativeHeight;
        this.scrollBodyViewChild.nativeElement.style.visibility = 'hidden';
        this.scrollBodyViewChild.nativeElement.style.height     = '100px';     //temporary height to calculate static height
        let containerHeight                                     = this.domHandler.getOuterHeight( this.tt.el.nativeElement.children[ 0 ] );

        if ( this.scrollHeight.includes( 'calc' ) ) {
          let percentHeight = parseInt( this.scrollHeight.slice( this.scrollHeight.indexOf( '(' ) + 1, this.scrollHeight.indexOf( '%' ) ) );
          let diffValue     = parseInt( this.scrollHeight.slice( this.scrollHeight.indexOf( '-' ) + 1, this.scrollHeight.indexOf( ')' ) ) );
          relativeHeight    = ( this.domHandler.getOuterHeight( this.tt.el.nativeElement.parentElement ) * percentHeight / 100 ) - diffValue;
        } else {
          relativeHeight = this.domHandler.getOuterHeight( this.tt.el.nativeElement.parentElement ) * parseInt( this.scrollHeight ) / 100;
        }

        let staticHeight     = containerHeight - 100;   //total height of headers, footers, paginators
        let scrollBodyHeight = ( relativeHeight - staticHeight );

        if ( this.frozen ) {
          scrollBodyHeight -= this.domHandler.calculateScrollbarWidth();
        }

        this.scrollBodyViewChild.nativeElement.style.height     = 'auto';
        this.scrollBodyViewChild.nativeElement.style.maxHeight  = scrollBodyHeight + 'px';
        this.scrollBodyViewChild.nativeElement.style.visibility = 'visible';
      } else {
        if ( this.frozen )
          this.scrollBodyViewChild.nativeElement.style.maxHeight = ( parseInt( this.scrollHeight ) - this.domHandler.calculateScrollbarWidth() ) + 'px';
        else
          this.scrollBodyViewChild.nativeElement.style.maxHeight = this.scrollHeight;
      }
    }
  }

  hasVerticalOverflow () {
    return this.domHandler.getOuterHeight( this.scrollTableViewChild.nativeElement ) > this.domHandler.getOuterHeight( this.scrollBodyViewChild.nativeElement );
  }

  alignScrollBar () {
    if ( !this.frozen ) {
      let scrollBarWidth                                            = this.hasVerticalOverflow() ? this.domHandler.calculateScrollbarWidth() : 0;
      this.scrollHeaderBoxViewChild.nativeElement.style.marginRight = scrollBarWidth + 'px';

      if ( this.scrollFooterBoxViewChild && this.scrollFooterBoxViewChild.nativeElement ) {
        this.scrollFooterBoxViewChild.nativeElement.style.marginRight = scrollBarWidth + 'px';
      }
    }
    this.initialized = false;
  }

  ngOnDestroy () {
    this.unbindEvents();

    this.frozenSiblingBody = null;

    if ( this.subscription ) {
      this.subscription.unsubscribe();
    }

    this.initialized = false;
  }
}

@Directive( {
  selector  : '[ttSortableColumn]',
  providers : [ DomHandler ],
  host      : {
    '[class.ui-sortable-column]' : 'isEnabled()',
    '[class.ui-state-highlight]' : 'sorted',
    '[attr.tabindex]'            : 'isEnabled() ? "0" : null'
  }
} )
export class TTSortableColumn implements OnInit, OnDestroy {

  @Input( 'ttSortableColumn' ) field : string;

  @Input() ttSortableColumnDisabled : boolean;

  sorted : boolean;

  subscription : Subscription;

  constructor ( public tt : TreeTable, public domHandler : DomHandler ) {
    if ( this.isEnabled() ) {
      this.subscription = this.tt.tableService.sortSource$.subscribe( sortMeta => {
        this.updateSortState();
      } );
    }
  }

  ngOnInit () {
    if ( this.isEnabled() ) {
      this.updateSortState();
    }
  }

  updateSortState () {
    this.sorted = this.tt.isSorted( this.field );
  }

  @HostListener( 'click', [ '$event' ] )
  onClick ( event : MouseEvent ) {
    if ( this.isEnabled() ) {
      this.updateSortState();
      this.tt.sort( {
        originalEvent : event,
        field         : this.field
      } );

      this.domHandler.clearSelection();
    }
  }

  @HostListener( 'keydown.enter', [ '$event' ] )
  onEnterKey ( event : MouseEvent ) {
    this.onClick( event );
  }

  isEnabled () {
    return this.ttSortableColumnDisabled !== true;
  }

  ngOnDestroy () {
    if ( this.subscription ) {
      this.subscription.unsubscribe();
    }
  }
}

@Component( {
  selector : 'p-treeTableSortIcon',
  template : `
    <i class="ui-sortable-column-icon pi pi-fw"
       [ngClass]="{'pi-sort-up': sortOrder === 1, 'pi-sort-down': sortOrder === -1, 'pi-sort': sortOrder === 0}"></i>
  `
} )
export class TTSortIcon implements OnInit, OnDestroy {

  @Input() field : string;

  @Input() ariaLabelDesc : string;

  @Input() ariaLabelAsc : string;

  subscription : Subscription;

  sortOrder : number;

  constructor ( public tt : TreeTable ) {
    this.subscription = this.tt.tableService.sortSource$.subscribe( sortMeta => {
      this.updateSortState();
    } );
  }

  ngOnInit () {
    this.updateSortState();
  }

  onClick ( event ) {
    event.preventDefault();
  }

  updateSortState () {
    if ( this.tt.sortMode === 'single' ) {
      this.sortOrder = this.tt.isSorted( this.field ) ? this.tt.sortOrder : 0;
    } else if ( this.tt.sortMode === 'multiple' ) {
      let sortMeta   = this.tt.getSortMeta( this.field );
      this.sortOrder = sortMeta ? sortMeta.order : 0;
    }
  }

  ngOnDestroy () {
    if ( this.subscription ) {
      this.subscription.unsubscribe();
    }
  }
}

@Directive( {
  selector : '[ttResizableColumn]'
} )
export class TTResizableColumn implements AfterViewInit, OnDestroy {

  @Input() ttResizableColumnDisabled : boolean;

  resizer : HTMLSpanElement;

  resizerMouseDownListener : any;

  documentMouseMoveListener : any;

  documentMouseUpListener : any;

  constructor ( public tt : TreeTable, public el : ElementRef, public domHandler : DomHandler, public zone : NgZone ) {
  }

  ngAfterViewInit () {
    if ( this.isEnabled() ) {
      this.domHandler.addClass( this.el.nativeElement, 'ui-resizable-column' );
      this.resizer           = document.createElement( 'span' );
      this.resizer.className = 'ui-column-resizer ui-clickable';
      this.el.nativeElement.appendChild( this.resizer );

      this.zone.runOutsideAngular( () => {
        this.resizerMouseDownListener = this.onMouseDown.bind( this );
        this.resizer.addEventListener( 'mousedown', this.resizerMouseDownListener );
      } );
    }
  }

  bindDocumentEvents () {
    this.zone.runOutsideAngular( () => {
      this.documentMouseMoveListener = this.onDocumentMouseMove.bind( this );
      document.addEventListener( 'mousemove', this.documentMouseMoveListener );

      this.documentMouseUpListener = this.onDocumentMouseUp.bind( this );
      document.addEventListener( 'mouseup', this.documentMouseUpListener );
    } );
  }

  unbindDocumentEvents () {
    if ( this.documentMouseMoveListener ) {
      document.removeEventListener( 'mousemove', this.documentMouseMoveListener );
      this.documentMouseMoveListener = null;
    }

    if ( this.documentMouseUpListener ) {
      document.removeEventListener( 'mouseup', this.documentMouseUpListener );
      this.documentMouseUpListener = null;
    }
  }

  onMouseDown ( event : Event ) {
    this.tt.onColumnResizeBegin( event );
    this.bindDocumentEvents();
  }

  onDocumentMouseMove ( event : Event ) {
    this.tt.onColumnResize( event );
  }

  onDocumentMouseUp ( event : Event ) {
    this.tt.onColumnResizeEnd( event, this.el.nativeElement );
    this.unbindDocumentEvents();
  }

  isEnabled () {
    return this.ttResizableColumnDisabled !== true;
  }

  ngOnDestroy () {
    if ( this.resizerMouseDownListener ) {
      this.resizer.removeEventListener( 'mousedown', this.resizerMouseDownListener );
    }

    this.unbindDocumentEvents();
  }
}

@Directive( {
  selector : '[ttReorderableColumn]'
} )
export class TTReorderableColumn implements AfterViewInit, OnDestroy {

  @Input() ttReorderableColumnDisabled : boolean;

  dragStartListener : any;

  dragOverListener : any;

  dragEnterListener : any;

  dragLeaveListener : any;

  mouseDownListener : any;

  constructor ( public tt : TreeTable, public el : ElementRef, public domHandler : DomHandler, public zone : NgZone ) {
  }

  ngAfterViewInit () {
    if ( this.isEnabled() ) {
      this.bindEvents();
    }
  }

  bindEvents () {
    this.zone.runOutsideAngular( () => {
      this.mouseDownListener = this.onMouseDown.bind( this );
      this.el.nativeElement.addEventListener( 'mousedown', this.mouseDownListener );

      this.dragStartListener = this.onDragStart.bind( this );
      this.el.nativeElement.addEventListener( 'dragstart', this.dragStartListener );

      this.dragOverListener = this.onDragEnter.bind( this );
      this.el.nativeElement.addEventListener( 'dragover', this.dragOverListener );

      this.dragEnterListener = this.onDragEnter.bind( this );
      this.el.nativeElement.addEventListener( 'dragenter', this.dragEnterListener );

      this.dragLeaveListener = this.onDragLeave.bind( this );
      this.el.nativeElement.addEventListener( 'dragleave', this.dragLeaveListener );
    } );
  }

  unbindEvents () {
    if ( this.mouseDownListener ) {
      document.removeEventListener( 'mousedown', this.mouseDownListener );
      this.mouseDownListener = null;
    }

    if ( this.dragOverListener ) {
      document.removeEventListener( 'dragover', this.dragOverListener );
      this.dragOverListener = null;
    }

    if ( this.dragEnterListener ) {
      document.removeEventListener( 'dragenter', this.dragEnterListener );
      this.dragEnterListener = null;
    }

    if ( this.dragEnterListener ) {
      document.removeEventListener( 'dragenter', this.dragEnterListener );
      this.dragEnterListener = null;
    }

    if ( this.dragLeaveListener ) {
      document.removeEventListener( 'dragleave', this.dragLeaveListener );
      this.dragLeaveListener = null;
    }
  }

  onMouseDown ( event ) {
    if ( event.target.nodeName === 'INPUT' || this.domHandler.hasClass( event.target, 'ui-column-resizer' ) )
      this.el.nativeElement.draggable = false;
    else
      this.el.nativeElement.draggable = true;
  }

  onDragStart ( event ) {
    this.tt.onColumnDragStart( event, this.el.nativeElement );
  }

  onDragOver ( event ) {
    event.preventDefault();
  }

  onDragEnter ( event ) {
    this.tt.onColumnDragEnter( event, this.el.nativeElement );
  }

  onDragLeave ( event ) {
    this.tt.onColumnDragLeave( event );
  }

  @HostListener( 'drop', [ '$event' ] )
  onDrop ( event ) {
    if ( this.isEnabled() ) {
      this.tt.onColumnDrop( event, this.el.nativeElement );
    }
  }

  isEnabled () {
    return this.ttReorderableColumnDisabled !== true;
  }

  ngOnDestroy () {
    this.unbindEvents();
  }

}

@Directive( {
  selector  : '[ttSelectableRow]',
  providers : [ DomHandler ],
  host      : {
    '[class.ui-state-highlight]' : 'selected'
  }
} )
export class TTSelectableRow implements OnInit, OnDestroy {

  @Input( 'ttSelectableRow' ) rowNode : any;

  @Input() ttSelectableRowDisabled : boolean;

  selected : boolean;

  subscription : Subscription;

  constructor ( public tt : TreeTable, public domHandler : DomHandler, public tableService : TreeTableService ) {
    if ( this.isEnabled() ) {
      this.subscription = this.tt.tableService.selectionSource$.subscribe( () => {
        this.selected = this.tt.isSelected( this.rowNode.node );
      } );
    }
  }

  ngOnInit () {
    if ( this.isEnabled() ) {
      this.selected = this.tt.isSelected( this.rowNode.node );
    }
  }

  @HostListener( 'click', [ '$event' ] )
  onClick ( event : Event ) {
    if ( this.isEnabled() ) {
      this.tt.handleRowClick( {
        originalEvent : event,
        rowNode       : this.rowNode
      } );
    }
  }

  @HostListener( 'keydown.enter', [ '$event' ] )
  onEnterKey ( event : Event ) {
    this.onClick( event );
  }

  @HostListener( 'touchend', [ '$event' ] )
  onTouchEnd ( event : Event ) {
    if ( this.isEnabled() ) {
      this.tt.handleRowTouchEnd( event );
    }
  }

  isEnabled () {
    return this.ttSelectableRowDisabled !== true;
  }

  ngOnDestroy () {
    if ( this.subscription ) {
      this.subscription.unsubscribe();
    }
  }

}

@Directive( {
  selector  : '[ttSelectableRowDblClick]',
  providers : [ DomHandler ],
  host      : {
    '[class.ui-state-highlight]' : 'selected'
  }
} )
export class TTSelectableRowDblClick implements OnInit, OnDestroy {

  @Input( 'ttSelectableRowDblClick' ) rowNode : any;

  @Input() ttSelectableRowDisabled : boolean;

  selected : boolean;

  subscription : Subscription;

  constructor ( public tt : TreeTable, public domHandler : DomHandler, public tableService : TreeTableService ) {
    if ( this.isEnabled() ) {
      this.subscription = this.tt.tableService.selectionSource$.subscribe( () => {
        this.selected = this.tt.isSelected( this.rowNode.node );
      } );
    }
  }

  ngOnInit () {
    if ( this.isEnabled() ) {
      this.selected = this.tt.isSelected( this.rowNode.node );
    }
  }

  @HostListener( 'dblclick', [ '$event' ] )
  onClick ( event : Event ) {
    if ( this.isEnabled() ) {
      this.tt.handleRowClick( {
        originalEvent : event,
        rowNode       : this.rowNode
      } );
    }
  }

  isEnabled () {
    return this.ttSelectableRowDisabled !== true;
  }

  ngOnDestroy () {
    if ( this.subscription ) {
      this.subscription.unsubscribe();
    }
  }

}

@Directive( {
  selector : '[ttContextMenuRow]',
  host     : {
    '[class.ui-contextmenu-selected]' : 'selected'
  }
} )
export class TTContextMenuRow {

  @Input( 'ttContextMenuRow' ) rowNode : any;

  @Input() ttContextMenuRowDisabled : boolean;

  selected : boolean;

  subscription : Subscription;

  constructor ( public tt : TreeTable, public tableService : TreeTableService ) {
    if ( this.isEnabled() ) {
      this.subscription = this.tt.tableService.contextMenuSource$.subscribe( ( node ) => {
        this.selected = this.tt.equals( this.rowNode.node, node );
      } );
    }
  }

  @HostListener( 'contextmenu', [ '$event' ] )
  onContextMenu ( event : Event ) {
    if ( this.isEnabled() ) {
      this.tt.handleRowRightClick( {
        originalEvent : event,
        rowNode       : this.rowNode
      } );

      event.preventDefault();
    }
  }

  isEnabled () {
    return this.ttContextMenuRowDisabled !== true;
  }

  ngOnDestroy () {
    if ( this.subscription ) {
      this.subscription.unsubscribe();
    }
  }

}

@Component( {
  selector : 'p-treeTableCheckbox',
  template : `
    <div class="ui-chkbox ui-treetable-chkbox ui-widget" (click)="onClick($event)">
      <div class="ui-helper-hidden-accessible">
        <input type="checkbox" [checked]="checked" (focus)="onFocus()" (blur)="onBlur()">
      </div>
      <div #box [ngClass]="{'ui-chkbox-box ui-widget ui-state-default':true,
                'ui-state-active':checked, 'ui-state-disabled':disabled}">
        <svg class="sym-smbl__form-checkbox" [ngClass]="{ 'sym-smbl--checked':checked,'sym-smbl--disabled':disabled,'sym-smbl--partial':rowNode.node.partialSelected }">
          <use href="#sym-smbl__form-checkbox"></use>
        </svg>
        selected
      </div>
    </div>
  `
} )
export class TTCheckbox {

  @Input() disabled : boolean;

  @Input( 'value' ) rowNode : any;

  @ViewChild( 'box', { static : false } ) boxViewChild : ElementRef;

  checked : boolean;

  subscription : Subscription;

  constructor ( public tt : TreeTable, public domHandler : DomHandler, public tableService : TreeTableService ) {
    this.subscription = this.tt.tableService.selectionSource$.subscribe( () => {
      this.checked = this.tt.isSelected( this.rowNode.node );
    } );
  }

  ngOnInit () {
    this.checked = this.tt.isSelected( this.rowNode.node );
  }

  onClick ( event : Event ) {
    if ( !this.disabled ) {
      this.tt.toggleNodeWithCheckbox( {
        originalEvent : event,
        rowNode       : this.rowNode
      } );
    }
    this.domHandler.clearSelection();
  }

  onFocus () {
    this.domHandler.addClass( this.boxViewChild.nativeElement, 'ui-state-focus' );
  }

  onBlur () {
    this.domHandler.removeClass( this.boxViewChild.nativeElement, 'ui-state-focus' );
  }

  ngOnDestroy () {
    if ( this.subscription ) {
      this.subscription.unsubscribe();
    }
  }

}

@Component( {
  selector : 'p-treeTableHeaderCheckbox',
  template : `
    <div class="ui-chkbox ui-treetable-header-chkbox ui-widget"
         (click)="onClick($event, cb.checked)">
      <div class="ui-helper-hidden-accessible">
        <input #cb type="checkbox" [checked]="checked" (focus)="onFocus()" (blur)="onBlur()"
               [disabled]="!tt.value||tt.value.length === 0">
      </div>
      <div #box [ngClass]="{'ui-chkbox-box ui-widget ui-state-default':true,
                'ui-state-active':checked, 'ui-state-disabled': (!tt.value || tt.value.length === 0)}">
        <svg class="sym-smbl__form-checkbox" [ngClass]="{ 'sym-smbl--checked':checked,'sym-smbl--disabled':disabled }">
          <use href="#sym-smbl__form-checkbox"></use>
        </svg>
      </div>
    </div>
  `
} )
export class TTHeaderCheckbox {

  @ViewChild( 'box', { static : false } ) boxViewChild : ElementRef;

  checked : boolean;

  disabled : boolean;

  selectionChangeSubscription : Subscription;

  valueChangeSubscription : Subscription;

  constructor ( public tt : TreeTable, public domHandler : DomHandler, public tableService : TreeTableService ) {
    this.valueChangeSubscription = this.tt.tableService.uiUpdateSource$.subscribe( () => {
      this.checked = this.updateCheckedState();
    } );

    this.selectionChangeSubscription = this.tt.tableService.selectionSource$.subscribe( () => {
      this.checked = this.updateCheckedState();
    } );
  }

  ngOnInit () {
    this.checked = this.updateCheckedState();
  }

  onClick ( event : Event, checked ) {
    if ( this.tt.value && this.tt.value.length > 0 ) {
      this.tt.toggleNodesWithCheckbox( event, !checked );
    }

    this.domHandler.clearSelection();
  }

  onFocus () {
    this.domHandler.addClass( this.boxViewChild.nativeElement, 'ui-state-focus' );
  }

  onBlur () {
    this.domHandler.removeClass( this.boxViewChild.nativeElement, 'ui-state-focus' );
  }

  ngOnDestroy () {
    if ( this.selectionChangeSubscription ) {
      this.selectionChangeSubscription.unsubscribe();
    }

    if ( this.valueChangeSubscription ) {
      this.valueChangeSubscription.unsubscribe();
    }
  }

  updateCheckedState () {
    let checked : boolean;

    if ( this.tt.value ) {
      for ( let node of this.tt.value ) {
        if ( this.tt.isSelected( node ) ) {
          checked = true;
        } else {
          checked = false;
          break;
        }
      }
    } else {
      checked = false;
    }

    return checked;
  }

}

@Directive( {
  selector  : '[ttRow]',
  host      : {
    '[attr.tabindex]' : '"0"'
  },
  providers : [ DomHandler ]

} )
export class TTRow {

  @Input( 'ttRow' ) rowNode : any;

  constructor ( public tt : TreeTable, public el : ElementRef, public domHandler : DomHandler, public zone : NgZone ) {
  }

  @HostListener( 'keydown', [ '$event' ] )
  onKeyDown ( event : KeyboardEvent ) {
    switch ( event.which ) {
      //down arrow
      case 40:
        let nextRow = this.el.nativeElement.nextElementSibling;
        if ( nextRow ) {
          nextRow.focus();
        }

        event.preventDefault();
        break;

      //down arrow
      case 38:
        let prevRow = this.el.nativeElement.previousElementSibling;
        if ( prevRow ) {
          prevRow.focus();
        }

        event.preventDefault();
        break;

      //left arrow
      case 37:
        if ( this.rowNode.node.expanded ) {
          this.tt.toggleRowIndex     = this.domHandler.index( this.el.nativeElement );
          this.rowNode.node.expanded = false;

          this.tt.onNodeCollapse.emit( {
            originalEvent : event,
            node          : this.rowNode.node
          } );

          this.tt.updateSerializedValue();
          this.tt.tableService.onUIUpdate( this.tt.value );
          this.restoreFocus();
        }
        break;

      //right arrow
      case 39:
        if ( !this.rowNode.node.expanded ) {
          this.tt.toggleRowIndex     = this.domHandler.index( this.el.nativeElement );
          this.rowNode.node.expanded = true;

          this.tt.onNodeExpand.emit( {
            originalEvent : event,
            node          : this.rowNode.node
          } );

          this.tt.updateSerializedValue();
          this.tt.tableService.onUIUpdate( this.tt.value );
          this.restoreFocus();
        }
        break;
    }
  }

  restoreFocus () {
    this.zone.runOutsideAngular( () => {
      setTimeout( () => {
        let row = this.domHandler.findSingle( this.tt.containerViewChild.nativeElement, '.ui-treetable-tbody' ).children[ this.tt.toggleRowIndex ];
        if ( row ) {
          row.focus();
        }
      }, 25 );
    } );
  }
}

@Component( {
  selector : 'p-treeTableToggler',
  template : `
    <a class="ui-treetable-toggler ui-unselectable-text"
       *ngIf="rowNode.node.leaf === false || rowNode.level !== 0 || rowNode.node.children && rowNode.node.children.length"
       (click)="onClick($event)"
       [style.visibility]="rowNode.node.leaf === false || (rowNode.node.children && rowNode.node.children.length) ? 'visible' : 'hidden'"
       [style.marginLeft]="rowNode.level * 16 + 'px'">
      <i [ngClass]="rowNode.node.expanded ? 'pi pi-fw pi-chevron-down' : 'pi pi-fw pi-chevron-right'"></i>
    </a>
  `
} )
export class TreeTableToggler {

  @Input() rowNode : any;

  constructor ( public tt : TreeTable ) {
  }

  onClick ( event : Event ) {
    this.rowNode.node.expanded = !this.rowNode.node.expanded;

    if ( this.rowNode.node.expanded ) {
      this.tt.onNodeExpand.emit( {
        originalEvent : event,
        node          : this.rowNode.node
      } );
    } else {
      this.tt.onNodeCollapse.emit( {
        originalEvent : event,
        node          : this.rowNode.node
      } );
    }

    this.tt.updateSerializedValue();
    this.tt.tableService.onUIUpdate( this.tt.value );

    event.preventDefault();
  }
}

@NgModule( {
  imports      : [ CommonModule, PaginatorModule ],
  exports      : [ TreeTable, SharedModule, TreeTableToggler, TTSortableColumn, TTSortIcon, TTResizableColumn, TTRow, TTReorderableColumn, TTSelectableRow, TTSelectableRowDblClick, TTContextMenuRow, TTCheckbox, TTHeaderCheckbox ],
  declarations : [ TreeTable, TreeTableToggler, TTScrollableView, TTBody, TTSortableColumn, TTSortIcon, TTResizableColumn, TTRow, TTReorderableColumn, TTSelectableRow, TTSelectableRowDblClick, TTContextMenuRow, TTCheckbox, TTHeaderCheckbox ]
} )
export class TreeTableModule {
}
