import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[sym-add-step]',
})
// tslint:disable-next-line:directive-class-suffix
export class SymAddStep {
    constructor(public viewContainerRef: ViewContainerRef) {
        // tslint:disable-next-line:no-unused-expression
        viewContainerRef.constructor.name === 'ViewContainerRef_'; // true
    }
}
