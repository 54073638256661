import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgModule,
    OnChanges,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import * as d3 from 'd3';
import {ObjectUtils} from '../utils/object-utils';
import {Utils} from '../common/utils';
import {CommonModule} from '@angular/common';

@Component({
    selector: 'sym-chart-donut',
    template: `
        <div class="sym-chart-donut__container" [ngClass]="[(metaData && metaData.customClass) ? metaData.customClass : '']">
            <div [hidden]="chartLoading || noData" class="sym-chart-donut__chart-container">
                <h3 *ngIf="showHeader" class="sym-chart-donut__center-text">
                    <span>{{metaData.title}}</span>
                </h3>
                <figure [id]="donutChartId" class="sym-chart__data"></figure>
            </div>
            <div class="tooltip-container"></div>
            <div [hidden]="chartLoading || noData" class="sym-chart-donut__legends_container">
                <div class="sym-chart-donut__legends">
                    <ul class="legends-items-list">
                        <li class="legend-item" *ngFor="let legend of data" (click)="chartLegendClick(legend)">
                            <span class="legend-icon"
                                  [ngStyle]="{'background-color': legend.color}"></span>
                            <span class="legend-count">{{legend.count}}</span>
                            <span class="legend-label" [attr.title]="legend.label">{{legend.label}}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="center-content-container" [hidden]="!chartLoading">
                <div *ngIf="!noData">
                    <svg class="sym-smbl__progress-spinner sym-smbl--medium">
                        <use href="#sym-smbl__progress-spinner"></use>
                    </svg>
                </div>
                <div *ngIf="noData" class="sym-chart__no-data">
                    <span class="error-icon"><svg class="sym-smbl__dashboard-error"><use href="#sym-smbl__dashboard-no-data"></use></svg></span>
                    <span>{{ noDataText }}</span>
                </div>
            </div>
        </div>`,
    providers: [ObjectUtils, Utils],
    encapsulation: ViewEncapsulation.None
})
export class SymChartDonut implements OnInit, OnChanges {

    readonly INNER_RADIUS: number = 66; // Inner radius of donut chart
    readonly OUTER_RADIUS: number = 72; // Outer radius of donut chart
    readonly VIEWBOX_WIDTH: number = 166; // Viewbox width
    readonly VIEWBOX_HEIGHT: number = 166; // Viewbox height

    @Input() data: any[];
    @Input() metaData: any;
    @Output() legendClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() arcClick: EventEmitter<any> = new EventEmitter<any>();

    hostElement; // Native element hosting the SVG container
    svg; // Top level SVG element
    g; // SVG Group element
    arc; // D3 Arc generator
    slices; // Donut chart slice elements
    total: number; // Total of chart values
    colorScale; // D3 color provider
    colors = d3.scaleOrdinal(d3.schemeCategory10);

    showLegendIcon: boolean;
    noData = false;
    chartLoading = true;
    showHeader = false;
    noDataText: string;
    donutChartId: string;

    pie = d3.pie()
        .startAngle(0)
        .endAngle(2 * Math.PI)
        .sort(null)
        .value((d: any) => d.count);

    constructor(private elRef: ElementRef) {
        this.hostElement = this.elRef.nativeElement;
    }

    ngOnInit(): void {
        this.donutChartId = `donut-${Utils.generateUUID()}`;
        this.setChartData();
    }

    ngOnChanges(): void {
        this.setChartData();
    }

    private setChartData () {
        this.noData = false;
        this.chartLoading = true;
        this.noDataText = (this.metaData && this.metaData.l10n && this.metaData.l10n.noData) ? this.metaData.l10n.noData : 'No Data Available';
        this.showLegendIcon = (this.metaData && this.metaData.showLegendIcon);
        this.removeExistingChartFromParent();

        if(!this.data) {
            this.showHeader = false;
            return;
        }

        if (this.data.length > 0) {
            this.showHeader = (this.metaData && this.metaData.title);
            this.noData = false;
            this.chartLoading = false;
            this.createChart();
        } else {
            this.showHeader = false;
            this.chartLoading = true;
            this.noData = true;
        }
    }
    private createChart() {
        this.setChartDimensions();
        this.setColorScale();
        this.addGraphicsElement();
        this.setupArcGenerator();
        this.addSlicesToTheDonut();
    }

    private setChartDimensions() {
        this.svg = d3.select(this.hostElement.querySelectorAll('.sym-chart-donut__chart-container figure')[0]).append('svg')
            .attr('width', '100%')
            .attr('height', '100%')
            .attr('viewBox', '0 0 ' + this.VIEWBOX_WIDTH + ' ' + this.VIEWBOX_HEIGHT);
    }

    private addGraphicsElement() {
        this.g = this.svg.append('g')
            .attr('transform', `translate(${this.VIEWBOX_WIDTH / 2},${this.VIEWBOX_HEIGHT / 2})`);
    }

    private setColorScale() {
        this.colorScale = d3.scaleOrdinal(d3.schemeCategory10);
    }


    private setupArcGenerator() {
        this.arc = d3.arc()
            .innerRadius(this.INNER_RADIUS)
            .outerRadius(this.OUTER_RADIUS);
    }

    private addSlicesToTheDonut() {
        const parentWidth = 200;
        const parentHeight = 120;
        const tooltipContainer = d3.select(this.hostElement.querySelectorAll('.tooltip-container')[0])
            .append('div')
            .attr('class', 'pie-tooltip sym-chart-donut__tooltip hidden');

        this.slices = this.g.selectAll('allSlices')
            .data(this.pie(this.data))
            .enter()
            .append('path')
            .attr('d', this.arc)
            .attr('fill', (datum, index) => {
                return datum.data.color || this.colorScale(index);
            })
            .on('click', (data) => {
                this.arcClick.emit(data.data);
            })
            .style('opacity', 1)
            .style('cursor', 'pointer')
            .on('mouseover', function (d) {
                const cord = d3.mouse(this);
                tooltipContainer
                    .style('left', `${cord[0] + parentWidth / 2}px`)
                    .style('top', `${cord[1] + parentHeight / 2}px`)
                    .style('opacity', 1)
                    .html(`<div class='tooltip-content'>
                                <span class='tooltip_count'><strong>${d.data.label}</strong>
                                    <br/>${d.value}
                                </span>
                          </div>`);
            })
            .on('mousemove', function (d) {
                const cord = d3.mouse(this);
                tooltipContainer
                    .style('left', `${cord[0] + parentWidth / 2}px`)
                    .style('top', `${cord[1] + parentHeight / 2}px`);
            })
            .on('mouseout', () => {
                // Hide the tooltip
                tooltipContainer
                    .style('opacity', 0)
                    .style('left', '0px')
                    .style('top', '0px');
            });
    }

    private removeExistingChartFromParent() {
        d3.select(this.hostElement).select('svg').remove();
    }


    public chartLegendClick = (legend) => {
        this.legendClick.emit(legend);
    }
}

@NgModule({
    imports: [CommonModule],
    exports: [SymChartDonut],
    declarations: [SymChartDonut]
})
export class SymChartDonutModule {
}
