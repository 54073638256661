import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class InlineEditService {
    private inlineEdits: any[] = [];

    add(inlineEdit: any) {
        // add inlineEdit to array of active inlineEdits
        this.inlineEdits.push(inlineEdit);
    }

    remove(id: string) {
        // remove inlineEdit from array of active inlineEdits
        this.inlineEdits = this.inlineEdits.filter(x => x.id !== id);
    }

    edit(id: string) {
        // open inlineEdit specified by id
        const dropdown = this.inlineEdits.find(x => x.id === id);
        dropdown.edit();
    }

    cancel(id: string) {
        // close inlineEdit specified by id
        const dropdown = this.inlineEdits.find(x => x.id === id);
        dropdown.cancel();
    }

    cancelOthers(id: string) {
        // close inlineEdit specified by id
        this.inlineEdits.forEach( x => {
          if (id !== x.id) {
            x.cancel();
          }
        });
    }
}
