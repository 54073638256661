export interface SymRuleBuilderItem {
  id : string;
  title? : string;
  items? : SymRuleBuilderItems;
  type? : SymRuleBuilderItemType;
  groupId? : string;
  operator? : string;
  dropdownType? : SymRuleBuilderDropdownType;
  isInvalid? : boolean;
  parentIds? : string[];
  parentItem? : SymRuleBuilderItem;
  originalModel? : any;
  isLast? : boolean;
  order? : number;
  disableDelete? : boolean;
  disableAdd? : boolean;
}

export interface SymRuleBuilderConfig {
  itemModel : SymRuleBuilderItemModel,
  rootDropdownType : string
}

export interface SymRuleBuilderItems {
  [ key : string ] : SymRuleBuilderItem;
}

export enum SymRuleBuilderItemType {
  FOLDER = 'folder',
  ITEM   = 'item',
  SCOPE  = 'scope'
}

export enum SymRuleBuilderDropdownType {
  OPERATORS = 'operators',
  ITEMS     = 'items'
}


export const SymRuleBuilderContant = {
  ROOT_ID : 'root'
};

export interface SymRuleBuilderItemModel {
  items : any,
  id : string,
  title : string,
  type : string,
  groupId? : string,
  operator? : string
}
