import { NgModule, Directive, Component, ElementRef, EventEmitter, AfterViewInit, Output, OnDestroy, HostBinding, HostListener, Input, OnChanges } from '@angular/core';

import { CommonModule } from '@angular/common';
import { DomHandler }   from '../dom/dom-handler';

@Directive( {
  selector  : '[symButton]',
  providers : [ DomHandler ],
  host      : {
    '[class.ui-button-spinner-proccessing]' : 'isProcessing'
  }
} )
export class ButtonDirective implements AfterViewInit, OnChanges, OnDestroy {

  @Input() iconPos : string = 'left';

  @Input() cornerStyleClass : string = 'ui-corner-all';

  @Input() disabled : boolean;
  // 6/8/20 yuko - removing this as it was not reflecting update disabled on input change.

  @Input() isProcessing : boolean;

  public _label : string;

  public _icon : string;

  public initialized : boolean;

  spinnerProcessingCls : string = ' ui-button-spinner-proccessing';
  spinnerCls : string           = ' ui-button-spinner';

  constructor (
    public el : ElementRef,
    public domHandler : DomHandler
  ) {
  }

  ngOnChanges () {
    if ( this.el.nativeElement.className.indexOf( this.spinnerProcessingCls ) !== -1 ) {
      for ( let elm = 0; elm < this.el.nativeElement.children.length; elm += 1 ) {

        if ( this.el.nativeElement.children[ elm ].className.indexOf( this.spinnerCls ) !== -1 ) {
          this.el.nativeElement.children[ elm ].remove();
        }
      }
    }

    if ( this.isProcessing ) {
      this.addSpinnerElm();
    }


 // adding this as ngClass  'ui-state-disabled': disabled` is not adding the class on change in input `disabled`
    if ( this.disabled ) {
      this.domHandler.addClass(this.el.nativeElement, 'ui-state-disabled');
      this.el.nativeElement.setAttribute( 'disabled', true );
    }
    else {
      this.domHandler.removeClass(this.el.nativeElement, 'ui-state-disabled');
      this.el.nativeElement.removeAttribute( 'disabled' );
    }

  }

  addSpinnerElm () {
    if ( this.isProcessing ) {
      let spinnerElement       = document.createElement( 'i' );
      spinnerElement.className = 'pi pi-spin pi-spinner' + this.spinnerCls;

      this.el.nativeElement.appendChild( spinnerElement );
    }
  }

  ngAfterViewInit () {
    this.domHandler.addMultipleClasses( this.el.nativeElement, this.getStyleClass() );

    if ( this.icon ) {
      let iconElement = document.createElement( 'span' );
      iconElement.setAttribute( 'aria-hidden', 'true' );
      let iconPosClass      = ( this.iconPos == 'right' ) ? 'ui-button-icon-right' : 'ui-button-icon-left';
      iconElement.className = iconPosClass + ' ui-clickable ' + this.icon;

      this.el.nativeElement.appendChild( iconElement );
    }

    this.addSpinnerElm();

    let labelElement       = document.createElement( 'span' );
    labelElement.className = 'ui-button-text ui-clickable';

    labelElement.appendChild( document.createTextNode( this.label || 'ui-btn' ) );
    this.el.nativeElement.appendChild( labelElement );
    this.initialized = true;
  }

  getStyleClass () : string {
    let styleClass = 'ui-button ui-widget ui-state-default ' + this.cornerStyleClass;
    if ( this.icon ) {
      if ( this.label != null && this.label != undefined ) {
        if ( this.iconPos == 'left' ) {
          styleClass = styleClass + ' ui-button-text-icon-left';
        } else {
          styleClass = styleClass + ' ui-button-text-icon-right';
        }
      } else {
        styleClass = styleClass + ' ui-button-icon-only';
      }
    } else {
      if ( this.label ) {
        styleClass = styleClass + ' ui-button-text-only';
      } else {
        styleClass = styleClass + ' ui-button-text-empty';
      }
    }

    if ( this.isProcessing ) {
      styleClass += this.spinnerProcessingCls;
    }
    if ( this.disabled ) {
      styleClass += ' ui-state-disabled';
      this.el.nativeElement.setAttribute( 'disabled', true );
    }


    return styleClass;
  }

  @Input() get label () : string {
    return this._label;
  }

  set label ( val : string ) {
    this._label = val;

    if ( this.initialized ) {
      this.domHandler.findSingle( this.el.nativeElement, '.ui-button-text' ).textContent = this._label;

      if ( !this.icon ) {
        if ( this._label ) {
          this.domHandler.addClass( this.el.nativeElement, 'ui-button-text-only' );
        } else {
          this.domHandler.removeClass( this.el.nativeElement, 'ui-button-text-only' );
        }
      }
    }
  }

  @Input() get icon () : string {
    return this._icon;
  }

  set icon ( val : string ) {
    this._icon = val;

    if ( this.initialized ) {
      let iconPosClass = ( this.iconPos == 'right' ) ? 'ui-button-icon-right' : 'ui-button-icon-left';

      this.domHandler.findSingle( this.el.nativeElement, '.ui-clickable' ).className =
        iconPosClass + ' ui-clickable ' + this.icon;
    }
  }

  ngOnDestroy () {
    while ( this.el.nativeElement.hasChildNodes() ) {
      this.el.nativeElement.removeChild( this.el.nativeElement.lastChild );
    }

    this.initialized = false;
  }
}

@Component( {
  selector : 'sym-button',
  template : `
    <button [attr.type]="type" [class]="styleClass" [ngStyle]="style" [disabled]="disabled"
            [ngClass]="{
              'ui-button ui-widget ui-state-default ui-corner-all' : true,
              'ui-button-icon-only'                                : ( icon && !label ),
              'ui-button-text-icon-left'                           : ( icon && label && iconPos === 'left' ),
              'ui-button-text-icon-right'                          : ( icon && label && iconPos === 'right' ),
              'ui-button-text-only'                                : ( !icon && label ),
              'ui-state-disabled'                                  : disabled,
              'ui-button-spinner-proccessing'                      : isProcessing
             }"
            (click)="click($event)" (focus)="focus($event)" (blur)="blur($event)">
      
      <ng-content></ng-content>

      <svg *ngIf="isProcessing" class="sym-smbl__progress-spinner sym-smbl--small ui-button-spinner"><use href="#sym-smbl__progress-spinner"></use></svg>
      
      <span *ngIf="icon && !svgId" [ngClass]="{ 'ui-clickable':true,'ui-button-icon-left':(iconPos === 'left'),'ui-button-icon-right':(iconPos === 'right') }" [class]="icon"></span>
      
      <svg *ngIf="svgId && (iconPos === 'left')" [ngClass]="{ 'ui-clickable' : true, 'svg-on-left' : true }" [class]="svgClasses"><use attr.xlink:href="#{{svgId}}"></use></svg>
      
      <span *ngIf="label" class="ui-button-text ui-clickable">{{ label || 'ui-btn' }}</span>
 
      <svg *ngIf="svgId && (iconPos === 'right')" [ngClass]="{ 'ui-clickable' : true, 'svg-on-right' : true }" [class]="svgClasses"><use attr.xlink:href="#{{svgId}}"></use></svg>
    
    </button>
  `
} )
export class Button {

  @Input() type : string;

  @Input() iconPos : string = 'left';

  @Input() icon : string = 'svg';

  @Input() svgClasses : string;

  @Input() svgId : string;

  @Input() label : string;

  @Input() disabled : boolean;

  @Input() isProcessing : boolean;

  @Input() style : any;

  @Input() styleClass : string;

  @Output() onClick : EventEmitter<any> = new EventEmitter();

  @Output() onFocus : EventEmitter<any> = new EventEmitter();

  @Output() onBlur : EventEmitter<any> = new EventEmitter();

  click ($event : Event) {
    //NOTE: as button element is disabled, this check is redundant, but just in case.
    if (!this.disabled) {
      this.onClick.emit($event)
    }
  }

  focus ($event : Event) {
    //NOTE: as button element is disabled, this check is redundant, but just in case.
    if (!this.disabled) {
      this.onFocus.emit($event)
    }
  }

  blur ($event : Event) {
    //NOTE: as button element is disabled, this check is redundant, but just in case.
    if (!this.disabled) {
      this.onBlur.emit($event)
    }
  }

}

@NgModule( {
  imports      : [ CommonModule ],
  exports      : [ ButtonDirective, Button ],
  declarations : [ ButtonDirective, Button ]
} )
export class ButtonModule {
}
