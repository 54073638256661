import { Component, Input, NgModule, OnInit } from '@angular/core';
import { CommonModule }                       from '@angular/common';
import { RouterModule }                       from '@angular/router';
import { DetectScrollModule }                 from '../detect-scroll/detect-scroll';
import { ObjectUtils }                        from '../utils/object-utils';
import { SymSecondaryNavigationModel }        from './sym-secondary-navigation-model';

@Component( {
  selector : 'sym-secondary-navigation',
  template : `
    <div class="sym-secondary-navigation__container">
      <div *ngIf="model.heading"  class="sym-secondary-navigation-heading">
        <h3 class="ui-helper-ellipsis-content">
          <span class="icon__global-back-arrow" (click)="doCommandHeading( $event )"></span>
          {{ model.heading.headingText }}
        </h3>
        <p class="sym-secondary-navigation-subheading ui-helper-ellipsis-content">{{ model.heading.subheadingText }}</p>
      </div>
      <ul class="sym-secondary-navigation-items" detect-scroll (onScroll)="doCommandScrollStatus( $event )" [bottomOffset]="bottomOffset" [topOffset]="topOffset" [debounceDelay]="debounceDelay">
        <li *ngFor="let item of model.items" [routerLinkActive]="item.disabled ? '' : 'ui-state-active-parent'" [routerLinkActiveOptions]="{ exact : item.routerLinkActiveOptionsExact }">
          <div class="sym-secondary-navigation-item-container">
            <a [routerLink]="item.disabled ? null : item.routerLink" [routerLinkActive]="item.disabled ? '' : 'ui-state-active'" [routerLinkActiveOptions]="{ exact : item.routerLinkActiveOptionsExact }" [attr.title]="item.title" class="sym-secondary-navigation-item ui-helper-ellipsis-content" [ngClass]="{'ui-state-disabled':item.disabled}">
              <span class="sym-secondary-navigation-item-label">{{ item.label }}</span>
            </a>
          </div>
        </li>
      </ul>
    </div>
  `,
  providers : [ ObjectUtils ]
} )

export class SymSecondaryNavigation implements OnInit {
  @Input() model : SymSecondaryNavigationModel;

  bottomOffset : number;
  topOffset : number;
  debounceDelay : number;

  constructor (
    private objectUtil : ObjectUtils
  ) {
  }

  doCommandHeading ( event ) {
    if ( this.model.heading && this.model.heading.command ) {
      this.invokeCommand( this.model.heading.command, event );
    }
  }

  doCommandScrollStatus ( event ) {
    if ( this.model.infiniteScroll && this.model.infiniteScroll.command ) {
      this.invokeCommand( this.model.infiniteScroll.command, event );
    }
  }

  private invokeCommand ( command, event ) {
    if ( this.objectUtil.isFunction( command ) ) {
      command( event );
    }
  }

  ngOnInit () {
    if ( this.model.infiniteScroll ) {
      this.bottomOffset  = this.model.infiniteScroll.scrollBottomOffset || 100;
      this.topOffset     = this.model.infiniteScroll.scrollTopOffset || 100;
      this.debounceDelay = this.model.infiniteScroll.debounceDelay || 500;
    }
  }
}

@NgModule( {
  imports      : [ CommonModule, RouterModule, DetectScrollModule ],
  exports      : [ SymSecondaryNavigation, RouterModule ],
  declarations : [ SymSecondaryNavigation ]
} )

export class SymSecondaryNavigationModule {
}
