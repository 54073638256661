export class Utils {
  //NOTE: Can't move it to globals as it will cause circular dependency.
  static generateUUID ( digits? : number ) {
    let d    = new Date().getTime();
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace( /[xy]/g, function ( c ) {
      let r = ( d + Math.random() * 16 ) % 16 | 0;
      d     = Math.floor( d / 16 );
      return ( c == 'x' ? r : ( r & 0x3 | 0x8 ) ).toString( 16 );
    } );
    if ( digits < 16 ) {
      uuid = uuid.substring( 0, digits );
    }
    return uuid;
  }

  static isDefined ( value : any ) {
    return value !== undefined && value !== null;
  }

  static numberWithCommas ( value : number ) : string  {
    // when returning directly, I got "Expression form not supported"
    // error from npm run-script build-ui
    let updateValue = value.toString().replace( /(\d)(?=(\d{3})+(?!\d))/g, '$1,' );
    return updateValue;
  }

  static replaceDashWithUnderscore ( value : string ) : string  {
    // when returning directly, I got "Expression form not supported"
    // error from npm run-script build-ui
    let updateValue = value.toUpperCase().replace( /\-/g, '_' );
    return updateValue;
  }

  static deepCopy(obj) {
  //https://stackoverflow.com/questions/34688517/whats-alternative-to-angular-copy-in-angular
     // return value is input is not an Object or Array.
     if (typeof(obj) !== 'object' || obj === null) {
       return obj;
     }

     let clone;

     if(Array.isArray(obj)) {
       clone = obj.slice();  // unlink Array reference.
     } else {
       clone = Object.assign({}, obj); // Unlink Object reference.
     }

     let keys = Object.keys(clone);

     for (let i=0; i<keys.length; i++) {
       clone[keys[i]] = Utils.deepCopy(clone[keys[i]]); // recursively unlink reference to nested objects.
     }

     return clone; // return unlinked clone.
  }

  static getCumulativeOffset (el) {
     let xPos = 0;
     let yPos = 0;

     while (el) {
       if (el.tagName == "BODY") {
         // deal with browser quirks with body/window/document and page scroll
         let xScroll = el.scrollLeft || document.documentElement.scrollLeft;
         let yScroll = el.scrollTop || document.documentElement.scrollTop;

         xPos += (el.offsetLeft - xScroll + el.clientLeft);
         yPos += (el.offsetTop - yScroll + el.clientTop);
       } else {
         // for all other non-BODY elements
         xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
         yPos += (el.offsetTop - el.scrollTop + el.clientTop);
       }

       el = el.offsetParent;
     }
     return {
       x: xPos,
       y: yPos
     };
  };

  static getOffsetRect(elem) {
    if (!elem) {
      return null;
    }
    let box = elem.getBoundingClientRect();

    let body = document.body;
    let docElem = document.documentElement;

    let scrollTop = window.pageYOffset || docElem.scrollTop || body.scrollTop;
    let scrollLeft = window.pageXOffset || docElem.scrollLeft || body.scrollLeft;

    let clientTop = docElem.clientTop || body.clientTop || 0;
    let clientLeft = docElem.clientLeft || body.clientLeft || 0;

    let top  = box.top +  scrollTop - clientTop;
    let left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left), width: box.width, height: box.height };
  };

  static getClosestByClass (el, clazz) {
    // Traverse the DOM up with a while loop
    while (!el.classList.contains(clazz)) {
        // Increment the loop to the parent node
        el = el.parentNode;
        if (!el || !el.classList) {
            return null;
        }
    }
    // At this point, the while loop has stopped and `el` represents the element that has
    // the class you specified in the second parameter of the function `clazz`

    // Then return the matched element
    return el;
};

}
