import { NgModule, Component, Input, OnInit } from '@angular/core';
import { CommonModule }                       from '@angular/common';

@Component({
    selector: 'sym-progressSpinner',
    template: `
        <div class="ui-progress-spinner" [ngStyle]="style" [ngClass]="styleClass">
            <svg class="sym-smbl__progress-spinner sym-smbl--large"><use href="#sym-smbl__progress-spinner"></use></svg>
            <p *ngIf="config.header" class="ui-progress-spinner-header">{{ config.header }}</p>
            <p class="ui-progress-spinner-msg" [ngClass]="{ 'show' : showMessage }">{{ message }}</p>
        </div>
    `
})
export class ProgressSpinner {

    @Input() style: any;
    @Input() styleClass: string;    
    @Input() config: any;
    public message: string = "&nbsp;";
    public showMessage: boolean = false;
    private anim: any;
    private animationSpeed: number = 1;

    constructor ( ) {

    }

    handleAnimation ( anim: any ) {
        this.anim = anim;
    }   

    messageDelayTimer = ( msg, delay ) => {
        let delayTimer: any;
        let lifeTimer : any;
        delayTimer = setTimeout( () => {
            this.message = msg; 
            this.setShowMessage(true); 
            lifeTimer = setTimeout( () => {
                this.setShowMessage(false);
                clearTimeout( lifeTimer );
            }, 5000 );
            clearTimeout( delayTimer );
        }, 1000 * delay );
    }

    startMessaging () {
        if ( this.config.messages ) {
            let messages: object[] = this.config.messages;
            if ( messages.length > 0 ) {
                let delays: number[] = ( this.config.delays > 0 ) ? this.config.delays : [ 1, 10, 30, 60, 90 ];
                for ( let i in messages ) {
                    let msg: string = messages[i]['msg'];
                    let delay: number = ( parseInt( i ) < 5 ) ? delays[ i ] : 90;
                    this.messageDelayTimer( msg, delay );
                }
            }
        }
    };

    setShowMessage ( val ) {
        this.showMessage = val;       
    }

    ngOnInit(  ) {

        // Default setting if config was omitted.
        if ( typeof (this.config) === 'undefined' ) {
            this.config = {
                header : '',
                messages : [],
                delays: []
            }
        }

        this.startMessaging();
    }

}

@NgModule({
    imports      : [ CommonModule ],
    exports      : [ ProgressSpinner ],
    declarations : [ ProgressSpinner ]
})

export class ProgressSpinnerModule { }