import { Component, ElementRef, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../common/shared';

let idx : number = 0;

@Component( {
  selector : 'sym-globalnav',
  template : `
    <div class="global__nav-container">
      <div [attr.id]="id" class="ui-global-nav" [ngClass]="{ 'is--transparent': isTransparent, 'is--hidden': isHidden, 'is--active': isActive }">
        <div class="global__nav-content">
          <ng-content></ng-content>
        </div>
      </div>
      <div class="toggle__nav" [ngClass]="{ 'is--active': isActive }">
        <span class="icon__chevron is--white" [ngClass]="{ 'toggle__nav-close': isActive,'toggle__nav-open': !isActive }" (click)="toggleNav()"></span>
      </div>
    </div>
  `
} )
export class GlobalNav {
  isActive : boolean = false;
  id : string        = `ui-global-nav-${idx++}`;

  @Input() isTransparent : boolean = false;

  @Input() isHidden : boolean = false;

  @Output() status = new EventEmitter<boolean>( this.isActive );

  constructor (
    private el : ElementRef
  ) {
  }

  toggleNav () {
    this.isActive = !this.isActive;
    this.status.emit( this.isActive );
  }
}

@NgModule( {
  imports      : [ CommonModule ],
  exports      : [ GlobalNav, SharedModule ],
  declarations : [ GlobalNav ]
} )
export class GlobalNavModule {
}
