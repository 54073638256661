import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  ViewEncapsulation
}                                                       from '@angular/core';
import * as d3                                          from 'd3';
import { Utils }                                        from '../common/utils';
import { CommonModule }                                 from '@angular/common';
import { ChartUtils }                                   from '../utils/chart.utils';
import { SunburstChartData, ChartOptions, ChartLegend } from '../common/chart.interface';

@Component( {
  selector      : 'sym-chart-sunburst',
  template      : `
    <div class="sym-chart-sunburst__container {{styleClass}}">
      <h3 *ngIf="options.l10n.title">
        <span>{{options.l10n.title}}</span>
      </h3>
      <div [hidden]="chartLoading" class="sym-sunburst-chart__sequence-container">
        <div class="sym-sunburst-chart__sequence"></div>
      </div>
      <div [hidden]="chartLoading" class="sym-chart-sunbrust__inner-container" [ngClass]="{'sym__flex-container': options.metaData && options.metaData.legendPosition !== 'bottom'}">
        <div class="sym-chart-sunbrust__chart-container">
          <figure [id]="id" class="sym-chart__data"></figure>
        </div>
        <div [hidden]="chartLoading || noData" class="sym-chart-sunburst__legends">
          <ul class="legends-items-list">
            <li class="legend-item" *ngFor="let legend of chartLegends; let i = index; " (click)="chartLegendClick($event,legend)">
              <span class="legend-icon" [ngStyle]="{'background-color': legend.color}"></span>
              <span class="legend-label" [attr.title]="legend.displayName">{{legend.displayName}}</span>
              <ul *ngIf="legend.children" class="legends-items-list__children">
                <li class="legend-child-item" *ngFor="let childLegend of legend.children; let j = index; " (click)="chartLegendClick($event, childLegend)">
                  <span class="legend-icon" [ngStyle]="{'background-color': childLegend.color}"></span>
                  <span class="legend-label" [attr.title]="childLegend.displayName">{{childLegend.displayName}}</span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>  
      <div class="center-content-container" [hidden]="!chartLoading">
          <div *ngIf="!noData">
              <svg class="sym-smbl__progress-spinner sym-smbl--medium">
                  <use href="#sym-smbl__progress-spinner"></use>
              </svg>
          </div>
          <div *ngIf="noData" class="sym-chart__no-data">
            <span class="error-icon"><svg class="sym-smbl__dashboard-error"><use href="#sym-smbl__dashboard-no-data"></use></svg></span>
            <span>{{ noDataText }}</span>
          </div>
      </div>     
    </div>`,
  providers     : [Utils],
  encapsulation : ViewEncapsulation.None
} )

export class SymChartSunburst implements OnInit, OnChanges {

  readonly VIEWBOX_LENGTH : number       = 150; // Viewbox width and height (166 was used in donut chart, but 150 works better for this chart.)
  readonly DISPLAY_TEXT_DEFAULT : string = 'default';
  readonly OPACITY_MOUSEOUT : number     = 0.3;
  readonly SUBTITLE_COLOR : string  = '#6E7070';

  @Input() data : SunburstChartData;
  @Input() options : ChartOptions;
  @Input() styleClass : string;

  @Output() legendClick : EventEmitter<SunburstChartData> = new EventEmitter<SunburstChartData>();
  @Output() arcClick : EventEmitter<SunburstChartData>    = new EventEmitter<SunburstChartData>();

  hostElement : HTMLElement; // Native element hosting the SVG container
  svg; // Top level SVG element
  arc; // D3 Arc generator
  chartGroup; // contains radial chart paths
  radius            = 100; // radius of the chart
  innerCircleRadius = 40; //
  outerCircleRadius = 48; //
  arcWidth          = 9;
  breadcrumb        = {
    width        : 110,
    height       : 18,
    spacing      : 3,
    tipTailWidth : 10
  };
  centerLabelContainer;
  noData          = false;
  chartLoading    = true;
  noDataText : string;
  id : string     = `sym-chart-sunbrust-${ Utils.generateUUID() }`;
  colors : object = {};

  partition; // arc partition
  sequenceContainer; // container for breadcrumb sequence
  chartContainer; // chart container
  chartLegends : Array<ChartLegend>; // legend object
  hasChildLegends: boolean = false;

  constructor ( private elRef : ElementRef ) {
    this.hostElement = this.elRef.nativeElement;
  }

  ngOnInit () : void {
    this.setChartData();
  }

  ngOnChanges () : void {
    this.setChartData();
  }

  private setChartData () {
    this.noData       = false;
    this.chartLoading = true;
    this.id           = this.options.metaData && this.options.metaData.id ? this.options.metaData.id : this.id;
    this.noDataText   = ( this.options.l10n && this.options.l10n && this.options.l10n.noData ) ? this.options.l10n.noData : 'No Data Available';
    this.removeExistingChartFromParent();

    if ( !this.data ) {
      this.chartLoading = true;
      return;
    }

    if ( Object.keys( this.data ).length > 0 ) {
      this.noData       = false;
      this.chartLoading = false;
      this.createChart();
    } else {
      this.noData       = true;
      this.chartLoading = true;
    }
  }

  private createChart () {
    this.sequenceContainer = this.hostElement.querySelector( '.sym-sunburst-chart__sequence-container' );

    this.chartContainer = this.hostElement.querySelector( '.sym-chart-sunbrust__chart-container' );
    this.setChartDimensions();
    this.setupLegends();
    this.initializeBreadcrumbTrail();
    this.addChart();
    this.addCenterText();
    this.updateLabels( this.data.count, this.options.metaData.displayText[ this.DISPLAY_TEXT_DEFAULT ] );

  }

  private setupLegends () {
    let legendItem;
    this.chartLegends = [];
    for ( let legend in this.options.metaData.legendData ) {
      if ( typeof this.options.metaData.legendData[legend] === 'string') {
        legendItem = {
          id          : legend,
          color       : ChartUtils.convertColor( this.options.metaData.color[ legend ] ),
          displayName : this.options.metaData.legendData[ legend ]
        };
        this.chartLegends.push( legendItem );
      }
      else {
        let item = this.options.metaData.legendData[ legend ];
        legendItem = {
          id          : legend,
          color       : ChartUtils.convertColor( this.options.metaData.color[ legend ] ),
          displayName : item.displayName,
          children    : []
        };
        if (item.children) {
          this.hasChildLegends = true;
          for (let child in item.children) {
            let childItem = {
              id          : child,
              color       : ChartUtils.convertColor( this.options.metaData.color[ child ] ),
              displayName : item.children[child]
            };
            legendItem.children.push(childItem);
          }
        }
        this.chartLegends.push( legendItem );
      }
    }
  }

  private setChartDimensions () {

    this.svg = d3.select( this.chartContainer.querySelector( 'figure' ) ).append( 'svg' )
    .attr( 'width', '100%' )
    .attr( 'height', '100%' )
    .attr( 'viewBox', '0 0 ' + this.VIEWBOX_LENGTH + ' ' + this.VIEWBOX_LENGTH );

    this.chartGroup = this.svg.append( 'g' )
    .attr( 'class', 'sym__chart-group' )
    .attr( 'transform', 'translate(' + this.VIEWBOX_LENGTH / 2 + ',' + this.VIEWBOX_LENGTH / 2 + ')' );
  }


  private addChart () {
    // Got how to create sunburst chart in D3 v5 from here:
    //https://bl.ocks.org/denjn5/e1cdbbe586ac31747b4a304f8f86efa5
    this.partition = d3.partition() //The partition command is a special tool that will help organize our data into the sunburst pattern,
    .size( [2 * Math.PI, this.radius] );
    //2 * Math.PI tells d3 the number of radians our sunburst will consume. Remember from middle-school geometry that a circle has a circumference of 2πr (2 * pi * r). This coordinate tells d3 how big our sunburst is in "radiuses". The answer is that it's 2π radiuses (or radians). So it's a full circle.
    var root       = d3.hierarchy( this.data )  // <-- 1
    .sum( function ( d ) {
      return d.size;
    } );  // <-- 2
    //sum() iterates through each node in our data and adds a "value" attribute to each one.
    // If the current node has no size attribute of its own, but it has 2 children, each size = 4, then .sum() will create a "value = 8" attribute for this node.
    this.partition( root );

    this.arc = d3.arc()
    .padAngle( .01 ) // padding betweeen arc
    .startAngle( function ( d : any ) {
      return d.x0;
    } )
    .endAngle( function ( d : any ) {
      return d.x1;
    } )
    .innerRadius( ( d : any ) => {
      return this.innerCircleRadius + ( d.depth * this.arcWidth );
    } )
    .outerRadius( ( d : any ) => {
      return this.outerCircleRadius + ( d.depth * this.arcWidth );
    } );

    this.chartGroup.selectAll( 'path' )
    .data( root.descendants() )
    .enter()
    .append( 'path' )
    .style( 'opacity', ( d : any ) => {
      return d.depth === 1 ? 1 : this.OPACITY_MOUSEOUT;
    } )
    .attr( 'display', ( d : any ) => {
      return d.depth ? null : 'none';
    } )
    .attr( 'd', this.arc )
    .style( 'stroke', '#FFF' )
    .style( 'fill', ( d ) => {
      if ( d.depth === 0 ) {
        return 'transparent';
      }
      var nameKey = d.data.type;
      return ChartUtils.convertColor( this.options.metaData.color[ nameKey ] );
    } )
    .on( 'mouseover', ( d ) => {
      this.mouseover( d );
    } )
    .on( 'click', ( d ) => {
      this.click( d );
    } );

    // Add the mouseLeave handler to the bounding circle.
    this.chartGroup.on( 'mouseleave', ( d ) => {
      this.mouseLeave( d );
    } );

  }

  // Fade all but the current sequence, and show it in the breadcrumb trail.
  private mouseover ( d ) {

    let displayKey = d.data.type;
    if ( !displayKey || !d.depth ) {
      displayKey = this.DISPLAY_TEXT_DEFAULT;
    }

    this.updateLabels( Number.isNaN(d.data.count) ? this.data.count : d.data.count, this.options.metaData.displayText[ displayKey ] );

    // Fade all the segments.
    this.chartGroup.selectAll( 'path' )
    .attr( 'display', function ( d ) {
      return d.depth > 0 ? 'block' : 'block';
    } )
    .style( 'opacity', function ( d ) {
      return 0.3;
    } );

    let childrenArray = ( d.depth !== 0 ) ? this.getAllChildren( d ) : [];

    // Then highlight only those that are an ancestor of the current segment.
    this.chartGroup.selectAll( 'path' )
    .filter( function ( node ) {
      return ( childrenArray.indexOf( node ) >= 0 );
    } )
    .attr( 'display', 'block' )
    .style( 'opacity', 1 );

    let sequenceArray = ( d.depth !== 0 ) ? this.getAncestors( d ) : [];

    this.updateBreadcrumbs( sequenceArray, this.data.count ? d.data.count : 0 );

    // Then highlight only those that are an ancestor of the current segment.
    this.chartGroup.selectAll( 'path' )
    .filter( function ( node ) {
      return ( sequenceArray.indexOf( node ) >= 0 );
    } )
    .attr( 'display', 'block' )
    .style( 'opacity', 1 );
  }

  // Given a node in a partition layout, return an array of all of its ancestor
  // nodes, highest first, but excluding the root.
  private getAncestors ( node ) {
    var path    = [];
    var current = node;
    while ( current.parent ) {
      path.unshift( current );
      current = current.parent;
    }
    return path;
  }

  private getAllChildren ( node ) {
    var allChildren = [];
    var current     = node;
    allChildren.unshift( current );
    if ( current.children ) {
      for ( var currentChildren = 0; currentChildren < current.children.length; currentChildren++ ) {
        allChildren = allChildren.concat( this.getAllChildren( current.children[ currentChildren ] ) );
      }
    }
    return allChildren;
  }

  // Restore everything to full opacity when moving off the visualization.
  private mouseLeave ( d ) {
    // Hide the breadcrumb trail
    d3.select( this.sequenceContainer ).select( '.sym-sunburst-chart__trail' )
    .style( 'visibility', 'hidden' );

    // Deactivate all segments during transition.
    this.chartGroup.selectAll( 'path' ).on( 'mouseover', null );

    // Transition each segment to full opacity and then reactivate it.
    this.chartGroup.selectAll( 'path' )
    .transition()
    .duration( 100 )
    .attr( 'display', function ( d ) {
      return d.depth > 0 ? 'block' : 'block';
    } )
    .style( 'opacity', function ( d ) {
      return d.depth === 1 ? 1 : 0.1;
    } );

    //reset the mouseover function again
    //(When I chanined it to above function or using each, it didn't work, so reselecting the path)
    this.chartGroup.selectAll( 'path' ).on( 'mouseover', ( d ) => {
      this.mouseover( d );
    } );

    this.updateLabels( this.data.count, this.options.metaData.displayText[ this.DISPLAY_TEXT_DEFAULT ] );
  }

  private addCenterText () {
    let txt = this.options.metaData.displayText[ this.DISPLAY_TEXT_DEFAULT ];
    let centerTextWrapWidth = this.options.metaData.centerTextWrapWidth || 80;
    let centerTitle    = this.options && this.options.l10n && this.options.l10n.centerTitle ? this.options.l10n.centerTitle : this.data.count;
    let centerSubtitle = this.options && this.options.l10n && this.options.l10n.centerSubtitle ? this.options.l10n.centerSubtitle.replace( '{{displayText}}', txt ) : txt;

    // add center text
    let centerTextContainer = this.chartGroup.append( 'g' )
    .attr( 'class', 'sym-chart-sunburst__center-text' );
    centerTextContainer.append( 'text' )
    .text( centerTitle )
    .attr("y", -5);

    this.centerLabelContainer = this.chartGroup.append( 'g' )
    .attr( 'class', 'sym-chart-sunburst__center-label' )
    .attr( 'transform', 'translate(0, 20)' );
    this.centerLabelContainer.append( 'text' )
    .text( centerSubtitle )
    .style( 'fill', this.SUBTITLE_COLOR )
    .attr("x", 0)
    .attr("y", -2)
    .attr("dy", 0)
    .call(ChartUtils.textWrap, centerTextWrapWidth);
  }

  private updateLabels ( cnt, txt ) {
    cnt          = this.options.l10n.centerTitle || cnt;
    let subtitle = this.options.l10n.centerSubtitle ? this.options.l10n.centerSubtitle.replace( '{{displayText}}', txt ) : txt;

    d3.select( this.chartContainer ).select( '.sym-chart-sunburst__center-text text' ).text( cnt );
    this.centerLabelContainer.remove('text');
    let centerTextWrapWidth = this.options.metaData.centerTextWrapWidth || 90;

    this.centerLabelContainer = this.chartGroup.append( 'g' )
    .attr( 'class', 'sym-chart-sunburst__center-label' )
    .attr( 'transform', 'translate(0, 20)' );
    this.centerLabelContainer.append( 'text' )
    .text( subtitle.trim() )
    .style( 'fill', this.SUBTITLE_COLOR )
    .attr("x", 0)
    .attr("y", -2)
    .attr("dy", 0)
    .call(ChartUtils.textWrap, centerTextWrapWidth);
  }

  private click ( d ) {
    this.arcClick.emit( d.data );
  }


  private removeExistingChartFromParent () {
    d3.select( this.hostElement ).select( '.sym-sunburst-chart__sequence-container svg' ).remove();
    d3.select( this.hostElement ).select( '.sym-chart-sunbrust__inner-container svg' ).remove();
  }

  public chartLegendClick = ( event, legendData: ChartLegend ) => {
    //legend data is not the original data.
    if ( event ) {
      event.stopPropagation(); //so that the parent legend won't be clicked when child legend was clicked.
    }
    this.legendClick.emit( legendData );

  };

  private initializeBreadcrumbTrail () {
    if ( this.options.metaData && this.options.metaData.breadcrumb ) {
      this.breadcrumb = {
        width        : this.options.metaData.breadcrumb.width || this.breadcrumb.width,
        height       : this.options.metaData.breadcrumb.height || this.breadcrumb.height,
        spacing      : this.options.metaData.breadcrumb.spacing || this.breadcrumb.spacing,
        tipTailWidth : this.options.metaData.breadcrumb.tipTailWidth || this.breadcrumb.tipTailWidth
      };
    }
    // Add the svg area.
    var trail = d3.select( this.sequenceContainer ).select( '.sym-sunburst-chart__sequence' ).append( 'svg:svg' )
    .attr( 'width', 600 )
    .attr( 'height', 20 )
    .attr( 'class', 'sym-sunburst-chart__trail' );
    // Add the label at the end, for the percentage.
    trail.append( 'svg:text' )
    .attr( 'class', 'sym-sunburst-chart__end-label' )
    .style( 'fill', '#000' );
  }

  // Generate a string that describes the points of a breadcrumb polygon.
  private breadcrumbPoints ( d, i ) {
    var points = [];
    points.push( '0,0' );
    points.push( this.breadcrumb.width + ',0' );
    points.push( this.breadcrumb.width + this.breadcrumb.tipTailWidth + ',' + ( this.breadcrumb.height / 2 ) );
    points.push( this.breadcrumb.width + ',' + this.breadcrumb.height );
    points.push( '0,' + this.breadcrumb.height );
    if ( i > 0 ) { // Leftmost breadcrumb; don't include 6th vertex.
      points.push( this.breadcrumb.tipTailWidth + ',' + ( this.breadcrumb.height / 2 ) );
    }
    return points.join( ' ' );
  }

  // Update the breadcrumb trail to show the current sequence and percentage.
  private updateBreadcrumbs ( nodeArray, countString ) {
    var trailElem = sequence = d3.select( this.sequenceContainer ).select( '.sym-sunburst-chart__trail' );
    if ( nodeArray.length <= 0 ) {
      // Hide the breadcrumb trail & return
      trailElem.style( 'visibility', 'hidden' );
      return;
    }

    trailElem.selectAll( 'g' ).remove();
    // Data join; key function combines name and depth (= position in sequence).
    var sequence = trailElem
    .selectAll( 'g' )
    .data( nodeArray, function ( d : any ) {
      var nameKey = d.data.type;
      return nameKey + d.depth;
    } );

    // Add breadcrumb and label for entering nodes.
    var entering = sequence.enter().append( 'svg:g' );

    entering.append( 'svg:polygon' )
    .attr( 'points', ( d, i ) => {
      return this.breadcrumbPoints( d, i );
    } )
    .style( 'fill', ( d : any ) => {
      var nameKey = d.data.type;
      return ChartUtils.convertColor( this.options.metaData.color[ nameKey ] );
    } );

    entering.append( 'svg:text' )
    .attr( 'x', ( this.breadcrumb.width + this.breadcrumb.tipTailWidth ) / 2 )
    .attr( 'y', this.breadcrumb.height / 2 )
    .attr( 'dy', '0.35em' )
    .attr( 'text-anchor', 'middle' )
    .text( ( d : any ) => {
      let displayKey = d.data.type || this.DISPLAY_TEXT_DEFAULT;
      return this.options.metaData.displayText[ displayKey ];
    } );

    // Set position for entering and updating nodes.
    d3.select( this.sequenceContainer ).select( '.sym-sunburst-chart__trail' )
    .selectAll( 'g' )
    .attr( 'transform', ( d, i ) => {
      return 'translate(' + i * ( this.breadcrumb.width + this.breadcrumb.spacing ) + ', 0)';
    } );

    // Remove exiting nodes.
    sequence.exit().remove();

    // Now move and update the percentage at the end.
    d3.select( this.sequenceContainer ).select( '.sym-sunburst-chart__trail' ).select( '.sym-sunburst-chart__end-label' )
    .attr( 'x', ( nodeArray.length + 0.5 ) * ( this.breadcrumb.width + this.breadcrumb.spacing ) - 10 )
    .attr( 'y', this.breadcrumb.height / 2 )
    .attr( 'dy', '0.35em' )
    .attr( 'text-anchor', 'middle' )
    .text( countString )
    .style( 'font-size', '1.1em' )
    .style( 'fill', 'darkgray' );

    // Make the breadcrumb trail visible, if it's hidden.
    d3.select( this.sequenceContainer ).select( '.sym-sunburst-chart__trail' )
    .style( 'visibility', '' );

  }
}

@NgModule( {
  imports      : [CommonModule],
  exports      : [SymChartSunburst],
  declarations : [SymChartSunburst]
} )
export class SymChartSunburstModule {
}
