import {
    AfterContentInit,
    Component,
    ContentChild,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Input,
    NgModule,
    Output,
    QueryList,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Footer, Header, PrimeTemplate, SharedModule } from '../common/shared';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ObjectUtils } from '../utils/object-utils';
import { BlockableUI } from '../common/blockable-ui';

@Component({
    selector: 'p-virtualScroller',
    template: `
        <div [ngClass]="'ui-virtualscroller ui-widget'" [ngStyle]="style" [class]="styleClass">
            <div class="ui-virtualscroller-header ui-widget-header ui-corner-top" *ngIf="header">
                <ng-content select="p-header"></ng-content>
            </div>
            <div #content class="ui-virtualscroller-content ui-widget-content">
                <ul class="ui-virtualscroller-list">
                    <cdk-virtual-scroll-viewport #viewport [ngStyle]="{'height': scrollHeight}"
                                                 [itemSize]="itemSize"
                                                 (scrolledIndexChange)="onScrollIndexChange($event)">
                        <ng-container
                                *cdkVirtualFor="let item of value; trackBy: trackBy; let i = index; let c = count; let f = first; let l = last; let e = even; let o = odd; ">
                            <li [ngStyle]="{'height': itemSize + 'px'}">
                                <ng-container
                                        *ngTemplateOutlet="item ? itemTemplate : loadingItemTemplate; context: {$implicit: item, index: i, count: c, first: f, last: l, even: e, odd: o}"></ng-container>
                            </li>
                        </ng-container>
                    </cdk-virtual-scroll-viewport>
                </ul>
            </div>
            <div class="ui-virtualscroller-footer ui-widget-header ui-corner-bottom" *ngIf="footer">
                <ng-content select="p-footer"></ng-content>
            </div>
        </div>
    `,
    providers: [ObjectUtils]
})
export class VirtualScroller implements AfterContentInit, BlockableUI {

    @Input() itemSize: number;

    @Input() style: any;

    @Input() styleClass: string;

    @Input() scrollHeight: any;

    @Input() lazy: boolean;

    @Input() cache: boolean = true;

    @Input() rows: number;

    @Input() first: number = 0;

    @Input() trackBy: Function = (index: number, item: any) => item;

    @ContentChild(Header, { static: false }) header;

    @ContentChild(Footer, { static: false }) footer;

    @ContentChildren(PrimeTemplate) templates: QueryList<any>;

    @ViewChild('viewport', { static: false }) viewPortViewChild: ElementRef;

    @Output() onLazyLoad: EventEmitter<any> = new EventEmitter();

    itemTemplate: TemplateRef<any>;

    loadingItemTemplate: TemplateRef<any>;

    _totalRecords: number = 0;

    _value: any[];

    lazyValue: any[] = [];

    page: number = 0;

    constructor(public el: ElementRef, public objectUtils: ObjectUtils) {
    }

    @Input() get totalRecords(): number {
        return this._totalRecords;
    }

    set totalRecords(val: number) {
        this._totalRecords = val;
        this.lazyValue = Array.from({ length: this._totalRecords });
        this.onLazyLoad.emit(this.createLazyLoadMetadata());
        this.first = 0;
        this.scrollTo(0);
    }

    @Input() get value(): any[] {
        return this.lazy ? this.lazyValue : this._value;
    }

    set value(val: any[]) {
        if (this.lazy) {
            if (val) {
                let arr = this.cache ? [...this.lazyValue] : Array.from({ length: this._totalRecords });
                for (let i = this.first, j = 0; i < (this.first + this.rows); i++, j++) {
                    arr[i] = val[j];
                }
                this.lazyValue = arr;
            }
        } else {
            this._value = val;
        }
    }

    ngAfterContentInit() {
        this.templates.forEach((item) => {
            switch (item.getType()) {
                case 'item':
                    this.itemTemplate = item.template;
                    break;

                case 'loadingItem':
                    this.loadingItemTemplate = item.template;
                    break;

                default:
                    this.itemTemplate = item.template;
                    break;
            }
        });
    }

    onScrollIndexChange(index: number) {
        let p = Math.floor(index / this.rows);
        if (p !== this.page) {
            this.page = p;
            this.first = this.page * this.rows;
            this.onLazyLoad.emit(this.createLazyLoadMetadata());
        }
    }

    createLazyLoadMetadata(): any {
        return {
            first: this.first,
            rows: this.rows
        };
    }

    getBlockableElement(): HTMLElement {
        return this.el.nativeElement.children[0];
    }

    scrollTo(index: number): void {
        if (this.viewPortViewChild && this.viewPortViewChild['elementRef'] && this.viewPortViewChild['elementRef'].nativeElement) {
            this.viewPortViewChild['elementRef'].nativeElement.scrollTop = index * this.itemSize;
        }
    }

}

@NgModule({
    imports: [CommonModule, ScrollingModule],
    exports: [VirtualScroller, SharedModule, ScrollingModule],
    declarations: [VirtualScroller]
})
export class VirtualScrollerModule {
}

