import { NgModule, Component, Input, OnInit } from '@angular/core';
import { CommonModule }                       from '@angular/common';
import { SharedModule }                       from '../common/shared';
import { MenuItem }                           from '../common/menu-item';
import { UIModel }                            from '../common/ui-model';
import { MenuModule }                         from '../menu/menu';

@Component( {
  selector : 'sym-accountnav',
  template : `
    <p-menu #menu
           [popup]="true"
           [model]="items"
           (menuToggle)='onMenuToggle($event)'
           styleClass="sym-accountnav__menu"
           appendTo="body"
           [offset]="offset"></p-menu>
    <div class="sym-accountnav__container" (mouseover)="onHover()" (mouseleave)="onLeave()" (click)="menu.toggle($event)">
      <div class="sym-accountnav__content">
        <svg class="sym-smbl__header-user-dark" [ngClass]="{'hover':isHover}"><use href="#sym-smbl__header-user-dark"></use></svg>
        <span class="sym-accountnav__text">{{uiModel.displayName}}</span>
      </div>
      <svg class="sym-smbl--arrow-small sym-smbl--black-80"><use href="#sym-smbl__arrow-chevron"></use></svg>
    </div>
  `
} )

export class AccountNav implements OnInit {
  @Input() uiModel: UIModel;

  @Input() items: MenuItem[];

  @Input() offset: { top?: number, left?: number } = { top : 0, left : 0 };

  isMenuVisible = false;
  isHover       = false;


  constructor () {
  }

  onMenuToggle ( isVisible: boolean ) {
    this.isMenuVisible = isVisible ? true : false;
  }

  onHover () {
    this.isHover = true;
  }

  onLeave () {
    this.isHover = false;
  }

  ngOnInit () {
  }
}

@NgModule( {
  imports      : [ CommonModule, MenuModule ],
  exports      : [ AccountNav, SharedModule ],
  declarations : [ AccountNav ]
} )

export class AccountNavModule {
}
