import { Component, Input, Output, ViewChild, NgModule, EventEmitter } from '@angular/core';
import { CommonModule }                                                from '@angular/common';
import { OverlayPanelModule, OverlayPanel }                            from '../overlay-panel/overlay-panel';
import { SymNotificationModel }                                        from './sym-notification-model';

@Component( {
  selector : 'sym-notification',
  template : `
    <p-overlayPanel #symNotification styleClass="ui-overlaypanel-no-padding" (onShow)="doOnShowEvent()" (onHide)="doOnHideEvent()" [arrowPosition]="model.arrowPosition" [offset]="model.offset">
      <ul *ngIf="model.items.length" class="sym__notifications">
        <li *ngFor="let item of model.items" (click)="item.command( symNotification, $event, item )" [ngClass]="{ 'is--unread' : ! item.isSelected }">
          <span [ngClass]="[ 'sym__notification__icon', item.status || '', item.iconClass || '' ]"></span>
          <span class="sym__notification__item">{{ item.message }}</span>
          <span class="sym__notification__date-time">{{ item.timestamp | date: 'MM/dd/yyyy h:mm a' }}</span>
        </li>
      </ul>
      <ul *ngIf="! model.items.length" class="sym__notifications">
        <li class="is--emptymessage">{{ model.emptymessage }}</li>
      </ul>
      <a *ngIf="model.footer && model.footer.text" href="" (click)="model.footer.command( symNotification, $event )" class="sym__notification__footer">{{ model.footer.text }}</a>
    </p-overlayPanel>`
} )

export class SymNotification {
  @Input() model : SymNotificationModel;

  @ViewChild( 'symNotification', { static : false } ) symNotification : OverlayPanel;

  @Output() onShow : EventEmitter<any> = new EventEmitter();

  @Output() onHide : EventEmitter<any> = new EventEmitter();

  constructor () {
  }

  doOnShowEvent () {
    this.onShow.emit( null );
  }

  doOnHideEvent () {
    this.onHide.emit( null );
  }

  toggle ( event, target? ) {
    this.symNotification.toggle( event, target );
  }

  show ( event, target? ) {
    this.symNotification.show( event, target );
  }

  hide () {
    this.symNotification.hide();
  }
}

@NgModule( {
  imports      : [ CommonModule, OverlayPanelModule ],
  exports      : [ SymNotification ],
  declarations : [ SymNotification ]
} )

export class SymNotificationModule {
}
