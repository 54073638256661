// Angular
import { NgModule, Component, ElementRef, AfterViewInit, OnInit, OnDestroy, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { CommonModule }                                                                                                  from '@angular/common';

// Third Party
import * as d3 from 'd3';

// Project
import { WidgetLoaderModule } from '../widget-loader/widget-loader';
import { CHART }              from '../common/chart';
import { Utils }              from '../common/utils';
import { ChartUtils }         from '../utils/chart.utils';
import { DateUtils }          from '../utils/date.utils';
import { ObjectUtils }        from '../utils/object-utils';

@Component( {
  selector  : 'sym-chart-vertical-bar',
  template  : `
    <div [ngStyle]="style" [ngClass]="[ 'sym-chart-vertical-bar__container', customStyleClass]">
      <h3 *ngIf="header">{{header}}</h3>
      <div [hidden]="isChartHidden">
        <div *ngIf="showSelector" class="ui-helper-clearfix">
          <ul class="sym-chart-vertical-bar__range-selector">
            <li *ngFor="let item of rangeItems; let i = index"
                [ngClass]="{'is--selected': item.isSelected }"
                (click)="onSelectRange($event, item, i)">{{item.name}}
            </li>
          </ul>
        </div>
        <div class="sym-chart-vertical-bar">
          <figure [id]="barChartId" class="sym-chart-vertical-bar__data"></figure>
          <div class="sym-chart__tooltip-container"></div>
        </div>
      </div>
      <div class="center-content-container" style="height:{{chartHeight}}px;" [hidden]="!isChartHidden">
        <div [hidden]="isLoadingHidden" class="progress-spinner-container">
          <svg class="sym-smbl__progress-spinner sym-smbl--medium">
            <use href="#sym-smbl__progress-spinner"></use>
          </svg>
        </div>
        <div [hidden]="!isLoadingHidden" class="sym-chart__no-data">
          <span class="error-icon"><svg class="sym-smbl__dashboard-error"><use href="#sym-smbl__dashboard-no-data"></use></svg></span>
          <span>{{ noData }}</span>
        </div>
      </div>
    </div>
  `,
  providers : [ ObjectUtils, Utils ]
} )
export class SymChartVerticalBar implements AfterViewInit, OnInit, OnDestroy {
  TOOLTIP_ARROW_HEIGHT : number = 5;
  TOOLTIP_DEFAULT_HTML : string = '{dateTime_timeFormat(%m/%d/%y)}<br>{count}';

  _data : any;
  barChartId : string;
  chartHeight : number       = 220;
  chartWidth : number        = 500;
  customStyleClass : string  = '';
  elmChartContainer;
  header : string            = '';
  isChartHidden : boolean    = true;
  isLoadingHidden : boolean  = true;
  noData : string            = CHART.NO_DATA;
  rangeItems;
  showLegend : boolean       = false;
  showSelector : boolean     = false;
  tooltip;
  tooltipHTML : string       = this.TOOLTIP_DEFAULT_HTML;
  tooltipOffsetLeft : number = 2;
  tooltipOffsetTop : number  = 0;
  hostElement; // Native element hosting the SVG container

  @Input() type : string;

  @Input() options : any;

  @Input() selector : any;

  @Input() slider : any;

  @Input() style : any;

  @Input() styleClass : string;

  @Input() get data () : any {
    return this._data;
  }

  set data ( val : any ) {
    this._data = val;

    const isDataArray = Array.isArray( this._data );

    this.isChartHidden   = !isDataArray;
    this.isLoadingHidden = isDataArray;

    if ( this.isLoadingHidden && this._data.length === 0 ) {
      this.isChartHidden = true;
    }

    if ( this.options ) {
      this.header = this.options[ 'header' ] || '';
      this.noData = this.options[ 'noData' ] || this.noData;
    }

    this.customStyleClass = this.styleClass || '';
  }

  @Output() onSelect : EventEmitter<any>     = new EventEmitter();
  @Output() onMouseEnter : EventEmitter<any> = new EventEmitter();

  constructor (
    private objectUtils : ObjectUtils,
    private elRef : ElementRef
  ) {
    this.hostElement = this.elRef.nativeElement;
  }

  onSelectRange ( event, item, i ) : void {
    this.rangeItems.forEach( ( item, idx ) => {
      item.isSelected = false;

      if ( !DateUtils.isDate( this.rangeItems[ idx ].min ) ) {
        this.rangeItems[ idx ].min = new Date( this.rangeItems[ idx ].min );
      }

      if ( !DateUtils.isDate( this.rangeItems[ idx ].max ) ) {
        this.rangeItems[ idx ].max = new Date( this.rangeItems[ idx ].max );
      }
    } );

    this.rangeItems[ i ].isSelected = true;

    this.selector.onSelect( event, item );
  };

  private svg;

  private onClick = ( data, index, elements ) : void => {
    this.onSelect.emit( { data : data, index : index, elements : elements } );
  };

  private buildSvg ( data : any[] ) : void {
    const LABEL_WIDTH : number                  = 30;
    const LABEL_AXIS1_WIDTH : number            = 20;
    const LEFT_MARGIN_ADJUSTMENT : number       = 20;
    const AXIS1_WIDTH_ADJUSTMENT : number       = 70;
    const AXIS2_LEFT_MARGIN_ADJUSTMENT : number = 10;
    const BAR_X_ADJUSTMENT : number             = 25;

    this.elmChartContainer = document.querySelectorAll( '.sym-chart-vertical-bar__container' );

    const elmWidth = ( this.elmChartContainer && this.elmChartContainer[ 0 ] && this.elmChartContainer[ 0 ].clientWidth ) || 0;

    const width : number    = ( elmWidth || 500 );
    const height : number   = 220;
    const margin            = { top : 20, right : 20, bottom : 30, left : 20 };
    const duration : number = Math.round( Math.abs( ( this.options.selected.min.getTime() - this.options.selected.max.getTime() ) / ( DateUtils.MS.DAY ) ) );
    const isMaxBarsInDay    = this.options.maxBarsInDay > 0;

    let labelWidth : number;
    let labelWidthAxis1 : number;

    this.chartWidth = ( elmWidth - 30 || 500 );

    // Reset
    if ( document.getElementById( this.barChartId ) ) {
      document.getElementById( this.barChartId ).innerHTML = '';
    }

    /* Since dayDivision was inital only used for the 7 Day case
     * Added additional property to allow for 30 and 365 Day cases
     * When maxBarsInDay is set - overwrite dayDivision
     */
    if ( isMaxBarsInDay ) {
      this.options.dayDivision = this.options.maxBarsInDay;
    }

    if ( this.options.axis.y.label ) {
      margin.left += LABEL_WIDTH; //for label to show up, add extra margin
      this.chartWidth -= LABEL_WIDTH;
      labelWidth      = LABEL_WIDTH;
      labelWidthAxis1 = LABEL_AXIS1_WIDTH;
    } else {
      labelWidth      = 0;
      labelWidthAxis1 = 0;
    }

    let yLabelClass = '';

    //See if we need to update margin.left in case of large y-axis number
    if ( this.options.axis.y.max.toString().length > 7 ) {
      yLabelClass = 'is--large';
      margin.left = this.options.axis.y.label ? 100 : 80;
    } else if ( this.options.axis.y.max.toString().length > 4 ) {
      yLabelClass = 'is--medium';
      margin.left = this.options.axis.y.label ? 80 : 60;
    }

    // Insert the svg element(chart) to the element id
    this.svg = d3.select( 'figure#' + this.barChartId )
    .append( 'svg' )
    .attr( 'class', 'sym-canvas' )
    .attr( 'width', this.chartWidth + margin.right + margin.left )
    .attr( 'height', height + margin.top + margin.bottom )
    .append( 'g' )
    .attr( 'class', 'sym-chart-container' )
    .attr( 'transform', `translate( 0, 10 )` )
    .append( 'g' )
    .attr( 'class', 'sym-chart-bar' )
    .attr( 'transform', 'translate( 0, 0 )' );

    //--[ X Axis Scale ] ------------------//
    const xAxisScale = d3.scaleTime()
    .domain( [ this.options.selected.min, this.options.selected.max ] )
    .rangeRound( [ 0, this.chartWidth - 50 ] )
    .nice(); //nice() is to show the first and last ticks

    //--[ Y Axis Scale ] ------------------//
    const yAxisScale = d3.scaleLinear()
    .range( [ 0, height ] )
    .domain( [ this.options.axis.y.max, this.options.axis.y.min ] );

    //--[ Format Time ] ------------------//
    let formatTime = this.options.dateFormat || '';
    if ( !formatTime.length ) {
      formatTime = duration <= 1 ? 'HH:mm a' : 'MM/dd/yy';
    }

    //--[ Tick Actual ] ------------------//
    let tickActual = 7;
    if ( window.innerWidth < 1919 ) {
      tickActual = 3;
    }

    //--[ Time Format Actual ] ------------------//
    let timeFormatActual : string = this.options.axis.x && this.options.axis.x.timeFormat || '';
    if ( !timeFormatActual.length ) {
      if ( duration <= 1 ) {
        timeFormatActual = '%I:%M %p';
      } else if ( duration === 7 && this.options.dayDivision ) { //for 7 days
        timeFormatActual = '%I:%M';
      } else if ( duration > 300 ) {
        timeFormatActual = '%m/%y';
      } else if ( duration < 300 && duration > 1 ) {
        timeFormatActual = '%m/%d';
      }
    }

    //--[ X Axis ] ------------------//
    let xAxis;
    let xAxis2 = null;

    if ( duration > 300 ) {
      xAxis = d3.axisBottom( xAxisScale )
      .tickFormat( d3.timeFormat( timeFormatActual ) )
      .tickSize( 0 )
      .ticks( tickActual )
      .tickPadding( 5 );
    } else if ( duration === 7 && this.options.dayDivision ) {
      formatTime = 'MM/dd/yy HH:mm';

      xAxis2 = d3.axisBottom( xAxisScale )
      .tickFormat( d3.timeFormat( '%m/%d' ) )
      .tickSize( 0 )
      .ticks( 7 )
      .tickPadding( 5 );

      //This is for time (not used, but just in case we want to add it.)
      xAxis = d3.axisBottom( xAxisScale )
      .tickFormat( d3.timeFormat( timeFormatActual ) )
      .tickSize( 0 )
      .ticks( this.options.dayDivision * 7 )
      .tickPadding( 5 );
    } else if ( duration > 1 ) {
      xAxis = d3.axisBottom( xAxisScale )
      .tickFormat( d3.timeFormat( timeFormatActual ) )
      .tickSize( 0 )
      .ticks( tickActual )
      .tickPadding( 5 );
    } else {
      xAxis = d3.axisBottom( xAxisScale )
      .tickFormat( d3.timeFormat( timeFormatActual ) )
      .tickSize( 0 )
      .ticks( 7 )
      .tickPadding( 5 );
    }

    let xAxisPaddingLeft;
    const barLeftPadding : number = 5;
    if ( !xAxis2 ) {
      xAxisPaddingLeft = duration === 7 ? this.chartWidth / 7 / 2 + barLeftPadding : barLeftPadding;
      let textAnchor   = duration === 7 ? 'end' : 'start';

      this.svg.append( 'g' )
      .attr( 'class', 'sym-chart-vertical-bar__axis is--x' )
      .attr( 'transform', 'translate(' + Math.round( xAxisPaddingLeft + ( LEFT_MARGIN_ADJUSTMENT + labelWidth ) ) + ',' + ( height + 5 ) + ')' )
      .call( xAxis )
      .attr( 'width', Math.round( this.chartWidth - margin.right - ( AXIS1_WIDTH_ADJUSTMENT - labelWidthAxis1 ) ) )
      .selectAll( 'text' )
      .style( 'text-anchor', textAnchor );
    } else if ( xAxis2 ) {
      xAxisPaddingLeft = this.chartWidth / 7 / 2 + barLeftPadding;

      this.svg.append( 'g' )
      .attr( 'class', 'sym-chart-vertical-bar__axis is--x-2' )
      .attr( 'transform', 'translate(' + ( xAxisPaddingLeft + ( AXIS2_LEFT_MARGIN_ADJUSTMENT + labelWidth ) ) + ',' + ( height + 5 ) + ')' )
      .call( xAxis2 )
      .selectAll( 'text' )
      .style( 'text-anchor', 'middle' );
    }

    //--[ Y Axis ] ------------------//
    let yAxis = d3.axisLeft( yAxisScale )
    .ticks( this.options.axis.y.ticks )
    .tickPadding( 0 )
    .tickSizeInner( -this.chartWidth )
    .tickSizeOuter( 5 );

    this.svg.append( 'g' )
    .attr( 'class', 'sym-chart-vertical-bar__axis is--y' )
    .attr( 'transform', `translate( ${margin.left}, 0 )` )
    .call( yAxis )
    .append( 'text' )
    .attr( 'class', 'axis__label ' + yLabelClass )
    .attr( 'y', 10 )
    .attr( 'dy', '-3.5em' )
    .style( 'text-anchor', 'middle' )
    .text( this.options.axis.y.label );

    //--[ Bars ] ------------------//
    let diff : number = 1;

    if ( duration <= 1 ) {
      if ( this.options.maxBarsSubDay ) {
        diff = this.options.maxBarsSubDay;
      } else {
        diff = this.data.length > 24 ? this.data.length : 24;
      }
    } else if ( duration === 7 && this.options.dayDivision ) {
      diff = ( duration * this.options.dayDivision );
    } else if ( isMaxBarsInDay ) {
      diff = ( duration * this.options.maxBarsInDay );
    } else {
      diff = duration;
    }

    let barWidth   = this.chartWidth / diff;
    let barSpacing = barWidth * 0.3;

    this.svg.selectAll( 'bar' )
    .data( data )
    .enter()
    .append( 'rect' )
    .attr( 'class', 'bar' )
    .style( 'fill', ( d ) => {
      return ChartUtils.convertColor( d.color );
    } )
    .attr( 'x', ( data, i ) => {
      if ( isNaN( xAxisScale( new Date( data.dateTime ) ) ) === false ) {
        let xValue = new Date( data.dateTime );
        return ( xAxisScale( xValue ) + ( BAR_X_ADJUSTMENT + labelWidth ) );
      }
    } )
    .attr( 'y', ( data ) => {
      if ( isNaN( yAxisScale( data.count ) ) === false ) {
        return yAxisScale( data.count );
      }
    } )
    .attr( 'width', barWidth - barSpacing )
    .attr( 'height', ( data ) => {
      return height - yAxisScale( data.count );
    } )
    .on( 'click', ( data, index, element ) => {
      this.tooltip.style( 'visibility', 'hidden' );
      this.onClick( data, index, element );
    } )
    .on( 'mouseenter', ( d, i ) => {
      this.onMouseEnter.emit( { data : d, index : i } );
      let elmTooltip             = d3.select( '.sym-chart-vertical-bar__tooltip.is--bar-chart' );
      let tooltipHeight : number = 80;
      let tooltipWidth : number  = 150;

      if ( elmTooltip && elmTooltip.node() && ( elmTooltip.node() as HTMLElement ).getBoundingClientRect ) {
        let boundingClientRect = ( elmTooltip.node() as HTMLElement ).getBoundingClientRect();
        tooltipHeight          = boundingClientRect.height + this.TOOLTIP_ARROW_HEIGHT;
        tooltipWidth           = boundingClientRect.width / 2;
      }

      if ( this.options.tooltip ) {
        if ( !this.options.tooltip.html && ( this.options.axis && this.options.axis.x && this.options.axis.x.timeFormat ) ) {
          this.tooltipHTML = '{dateTime_timeFormat(' + this.options.axis.x.timeFormat + ')}<br>{count}';
        } else {
          this.tooltipHTML = this.options.tooltip.html ? this.options.tooltip.html : this.TOOLTIP_DEFAULT_HTML;
        }
      }

      const populatedHTML = this.interpolateD3tooltipHTML( this.tooltipHTML, d.count, d.dateTime );

      this.tooltip
      .style( 'left', ( d3.event.target.getAttribute( 'x' ) - tooltipWidth ) + Math.round( ( barWidth - barSpacing ) / 2 ) + this.tooltipOffsetLeft + 'px' )
      .style( 'top', ( d3.event.target.getAttribute( 'y' ) - tooltipHeight ) + this.tooltipOffsetTop + 'px' )
      .style( 'visibility', 'visible' )
      .on( 'click', this.onClick )
      .html( populatedHTML );
    } )
    .on( 'mouseleave', ( d, i ) => {
      this.tooltip.style( 'visibility', 'hidden' );
    } );
  }

  private buildRangeSelector () : void {
    if ( this.selector ) {
      if ( this.selector.items ) {
        this.rangeItems = this.selector.items;
      }

      if ( this.selector.isVisible ) {
        this.showSelector = true;
      }
    }
  }

  private interpolateD3tooltipHTML ( str, count, dateTime ) : string {
    const interpolatedResult = str.replace( /{(?:(count|dateTime)(?:_(timeFormat|format))?)(?:\(([^}]*)\))?}/g, ( match, key, method, specifer ) => {
      const filterValue     = method ? d3[ method ]( `${specifer}` )( dateTime ) : dateTime;
      const result : string = this.objectUtils.supplant( '{' + key + '}', {
        count    : count,
        dateTime : filterValue
      } );

      return result;
    } );

    return interpolatedResult;
  };

  private buildToolTip () : void {
    if ( this.options && this.options.tooltip ) {
      if ( this.options.tooltip.html ) {
        this.tooltipHTML = this.options.tooltip.html;
      }

      if ( this.options.tooltip.offset ) {
        this.tooltipOffsetTop  = this.options.tooltip.offset.top || 0;
        this.tooltipOffsetLeft = this.options.tooltip.offset.left || 2;
      }
    }

    if ( Array.isArray( this._data ) && this._data.length ) {
      const populatedHTML = this.interpolateD3tooltipHTML( this.tooltipHTML, this._data[ 0 ].count, this._data[ 0 ].dateTime );
      const elmTooltip    = this.hostElement.querySelectorAll( '.sym-chart-vertical-bar__tooltip.is--bar-chart' );

      if ( elmTooltip.length ) {
        d3.select( elmTooltip[ 0 ] ).remove();
      }

      this.tooltip = d3.select( this.hostElement.querySelectorAll( '.sym-chart__tooltip-container' )[ 0 ] )
      .append( 'div' )
      .attr( 'class', 'sym-chart-vertical-bar__tooltip is--bar-chart' )
      .style( 'visibility', 'hidden' )
      .html( populatedHTML );
    }
  }

  private buildChart ( data : any ) : void {
    if ( data ) {
      this.buildSvg( data );
    }
  }

  ngOnChanges ( changes : SimpleChanges ) {
    if ( changes.data.currentValue ) {
      this.buildToolTip();
      this.buildChart( changes.data.currentValue );
    }
  }

  ngAfterViewInit () : void {
    this.buildChart( this.data );
    this.buildToolTip();
  }

  ngOnInit () : void {
    this.barChartId = `vertical-bar-${Utils.generateUUID()}`;
    this.buildRangeSelector();
  }

  ngOnDestroy () : void {
    this.elmChartContainer = null;
  }
}

@NgModule( {
  imports      : [ CommonModule, WidgetLoaderModule ],
  exports      : [ SymChartVerticalBar ],
  declarations : [ SymChartVerticalBar ]
} )
export class SymChartVerticalBarModule {
}
