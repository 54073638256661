// Why @dynamic? See https://github.com/angular/angular/issues/19698#issuecomment-349359036
// @dynamic
export class DateUtils {
   static isDate ( date ) : boolean {
    if ( Object.prototype.toString.call( date ) === '[object Date]' ) {
      return true;
    }

    return false;
  };

   static MS = {
    SECOND : 1000,
    MINUTE : 60000,
    HOUR   : 3600000,
    DAY    : 86400000,
    WEEK   : 604800000
  };
}
