import { Component, ElementRef, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../common/shared';

let idx : number = 0;

@Component( {
  selector : 'sym-nav-global',
  template : `
    <div class="global__nav-container-svg">
      <div [attr.id]="id" class="ui-global-nav-svg" [ngClass]="{ 'is--transparent': isTransparent, 'is--hidden': isHidden, 'is--active': isActive }">
        <div class="global__nav-content">
          <ng-content></ng-content>
        </div>
      </div>
      <div class="toggle__nav-svg" [ngClass]="{ 'is--active': isActive }" (click)="toggleNav()">
        <span>
          <svg *ngIf="!isActive" class="sym-smbl--arrow-nav sym-smbl--arrow-right sym-smbl--white"><use href="#sym-smbl__arrow-chevron"></use></svg>
          <svg *ngIf="isActive" class="sym-smbl--arrow-nav sym-smbl--arrow-left sym-smbl--white"><use href="#sym-smbl__arrow-chevron"></use></svg>
        </span>
      </div>
    </div>
  `
} )
export class NavGlobal {
  isActive : boolean = false;
  id : string        = `ui-global-nav-${idx++}`;

  @Input() isTransparent : boolean = false;

  @Input() isHidden : boolean = false;

  @Output() status = new EventEmitter<boolean>( this.isActive );

  constructor (
    private el : ElementRef
  ) {
  }

  toggleNav () {
    this.isActive = !this.isActive;
    this.status.emit( this.isActive );
  }
}

@NgModule( {
  imports      : [ CommonModule ],
  exports      : [ NavGlobal, SharedModule ],
  declarations : [ NavGlobal ]
} )
export class NavGlobalModule {
}
