import { Component, OnInit, NgModule, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { CommonModule }                                                                       from '@angular/common';
import { DynamicDialogConfig }                                                                from '../dynamic-dialog/dynamic-dialog-config';
import { DynamicDialogRef }                                                                   from '../common/api';
import { ButtonModule }                                                                       from '../button/button';

@Component( {
  selector : 'sym-alert-modal',
  template : `
    <form class="ui-form-content">
      <div class="ui-dialog-inner-content ui-helper-clearfix">
        <div class="sym-alert-modal-icon-col">
          <span *ngIf="!iconSvgName" [class]="iconClassName" [ngClass]="{ 'sym-alert-modal-icon' : true }"></span>
          <svg *ngIf="iconSvgName" [ngClass]="styleClass" [class]="iconClassName"><use [attr.xlink:href]="iconSvgName"></use></svg>
        </div>
        <div class="sym-alert-modal-message-col">
          <ng-template #dynamicMessageTemplate></ng-template>
          <div *ngIf="isMessageString" [innerHtml]="message"></div>
        </div>
      </div>

      <!--[ Footer ] -->
      <div class="ui-dialog-footer">
        <sym-button type="button" [label]="rejectLabel" (click)="doRejectAction()" *ngIf="rejectLabel.length !== 0 && rejectVisible" styleClass="ui-button-secondary"></sym-button>
        <sym-button type="submit" [label]="acceptLabel" (click)="doAcceptAction()" *ngIf="acceptVisible"></sym-button>
      </div>
    </form>
  `
} )
export class SymAlertModal implements OnInit {
  isMessageString : boolean = false;
  message : any             = this.dynamicDialogConfig.data ? this.dynamicDialogConfig.data.message : '';
  iconClassName : string    = this.dynamicDialogConfig.data ? this.dynamicDialogConfig.data.iconClassName : '';
  iconSvgName : any         = this.dynamicDialogConfig.data ? this.dynamicDialogConfig.data.iconSvgName : '';
  modalData                 = this.dynamicDialogConfig.data ? this.dynamicDialogConfig.data.modalData : '';
  acceptAction : string     = this.getDataOrDefault( 'acceptAction', 'ACCEPT' );
  acceptLabel : string      = this.getDataOrDefault( 'acceptLabel', 'OK' );
  acceptVisible : boolean   = this.getDataOrDefault( 'acceptVisible', true );
  rejectAction : string     = this.getDataOrDefault( 'rejectAction', 'REJECT' );
  rejectLabel : string      = this.getDataOrDefault( 'rejectLabel', 'Cancel' );
  rejectVisible : boolean   = this.getDataOrDefault( 'rejectVisible', true );
  styleClass = null; //NOTE: [ngClass]="styleClass" is used as a hack to enable [class]="iconClassName" to work within an SVG. PLEASE DO NOT DELETE.

  @ViewChild( 'dynamicMessageTemplate', { static : true, read : ViewContainerRef } ) dynamicMessageTemplate : ViewContainerRef;

  constructor (
    public dynamicDialogRef : DynamicDialogRef,
    public dynamicDialogConfig : DynamicDialogConfig,
    private resolver : ComponentFactoryResolver
  ) {

  }

  getDataOrDefault ( path, defaultVal? ) {
    if ( this.dynamicDialogConfig.data ) {
      return this.getPropertyOrDefault( this.dynamicDialogConfig.data, path, defaultVal );

    } else {
      return defaultVal || '';
    }
  }

  getPropertyOrDefault ( obj, path, defaultVal? ) {
    let stack  = path.split( '.' );
    let i      = 0;
    let len    = stack.length - 1;
    let result = defaultVal;

    for ( i = 0; i < len; i += 1 ) {
      if ( obj[ stack[ i ] ] ) {
        obj = obj[ stack[ i ] ];
      }
    }

    if ( obj.hasOwnProperty( [stack[ i ]] ) ) {
      result = obj[ stack[ i ] ];
    }

    return result;
  };

  doRejectAction () : void {
    this.dynamicDialogRef.close( { action : this.rejectAction, modalData : this.modalData } );
  }

  doAcceptAction () : void {
    this.dynamicDialogRef.close( { action : this.acceptAction, modalData : this.modalData } );
  }

  ngOnInit () {
    const messageType = typeof this.message;

    switch ( messageType ) {
      case  'function' :
        this.dynamicMessageTemplate.clear();

        const factoryComponent             = this.resolver.resolveComponentFactory( this.message );
        const dynamicComponent             = this.dynamicMessageTemplate.createComponent( factoryComponent );
        let dynamicComponentInstance : any = dynamicComponent.instance;
        dynamicComponentInstance.modalData = this.modalData;
        break;

      case 'string' :
        this.isMessageString = true;
        break;
    }
  }
}

@NgModule( {
  imports         : [CommonModule, ButtonModule],
  exports         : [SymAlertModal],
  declarations    : [SymAlertModal],
  entryComponents : [SymAlertModal]
} )

export class SymAlertModalModule {
}
