import {
  NgModule,
  SkipSelf,
  Host,
  HostListener,
  ElementRef,
  OnInit,
  Directive,
  Input,
  Renderer2,
  Output,
  EventEmitter,
  Component
}                       from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule }  from '../common/shared';
import { ResizableItem } from '../common/resizable.interface';

@Directive( {
  selector : '[symResizable]'
} )
export class ResizableDirective implements OnInit {
  _host : HTMLElement;
  _startWidth   = 0;
  _isRightPanel = false;

  @Input( 'symResizable' ) position : string = 'main'; //left, right, main
  @Input() symResizableMinWidth : number     = 100;
  @Input() symResizableMaxWidth : number     = 400;
  @Output() onChange                         = new EventEmitter<ResizableItem>();

  private _width: string;

  @Input() get symResizableWidth () : string {
    return this._width;
  }

  set symResizableWidth ( val : string ) {
    this._width = val;
    if ( this._host && this._host.style ) {
      this._host.style.width = val;
    }
  }

  constructor ( private elm : ElementRef, private renderer : Renderer2 ) {
    this.symResizableWidth = this.symResizableWidth || '200px';
  }

  ngOnInit () {
    this._host         = this.elm.nativeElement;
    this._isRightPanel = this.position === 'right';
    this.position      = this.position || 'left';
    this.renderer.addClass( this.elm.nativeElement, 'sym-resizable__' + this.position );
    this._host.style.width = this.symResizableWidth;
  }

  dragStart () {
    const style      = window.getComputedStyle( this._host, undefined );
    this._startWidth = style.width ? parseInt( style.width, 10 ) : 0;
    this._isRightPanel;
    this.renderer.addClass( this.elm.nativeElement, 'is--resizing' );
  }

  dragging ( diff : number ) {
    var newWidth : number; //NOTE: need this varible inside the below `if function`, so using `var` instead of `let`.
    if ( this._isRightPanel ) {
      newWidth = this._startWidth - diff;
    } else {
      newWidth = this._startWidth + diff;
    }

    if ( newWidth > this.symResizableMaxWidth ) {
      newWidth = this.symResizableMaxWidth;
    } else if ( newWidth < this.symResizableMinWidth ) {
      newWidth = this.symResizableMinWidth;
    }
    this._host.style.width = newWidth + 'px';
  }

  dragEnd () {
    this._startWidth = 0;
    this.renderer.removeClass( this.elm.nativeElement, 'is--resizing' );
    this.onChange.emit( { width : this._host.style.width, position : this.position } );
  }
}


@Directive( {
  selector : '[symResizableGrabber]'
} )
export class GrabberDirective implements OnInit {
  @Input() grabberTop : string = '400px';
  @Input() styleClass: string;

  @HostListener( 'mousedown', ['$event'] ) mousedown = ( e : MouseEvent ) => {
    this._startOffsetX = e.clientX;
    document.addEventListener( 'mousemove', this._boundDragging );
    document.addEventListener( 'mouseup', this._boundDragEnd );
    this.resizable.dragStart();
  };

  _startOffsetX           = 0;
  readonly _boundDragging = ( e ) => this._dragging( e );
  readonly _boundDragEnd  = ( e ) => this._dragEnd( e );
  private container : HTMLElement;
  private grabberIcon : HTMLElement;

  constructor (
    private elm : ElementRef,
    @Host() @SkipSelf() private resizable : ResizableDirective, //SkipSelf to start dependency resolution from the parent injector.
    private renderer : Renderer2
  ) {
    this.container = this.elm.nativeElement;
  }

  ngOnInit () : void {
    this.renderer.addClass( this.container, 'sym-resizable-grabber' );
    this.renderer.addClass( this.container, 'is--' + this.resizable.position );

    this.grabberIcon = this.renderer.createElement( 'div' );
    this.renderer.addClass( this.grabberIcon, 'icon__resize-handle-grip' );
    this.renderer.addClass( this.grabberIcon, 'sym-resizable-grabber__handle' );
    if (this.styleClass) {
      this.renderer.addClass( this.grabberIcon, this.styleClass );
    }
    this.renderer.setStyle( this.grabberIcon, 'top', this.grabberTop );
    this.renderer.appendChild( this.container, this.grabberIcon );
  }

  @HostListener( 'mouseenter', ['$event'] ) mouseover ( eventData : Event ) {
    this.renderer.addClass( this.grabberIcon, 'icon__resize-handle-grip-hover' );
    this.renderer.removeClass( this.grabberIcon, 'icon__resize-handle-grip' );
  }

  @HostListener( 'mouseleave', ['$event'] ) mouseleave ( eventData : Event ) {
    this.renderer.removeClass( this.grabberIcon, 'icon__resize-handle-grip-hover' );
    this.renderer.addClass( this.grabberIcon, 'icon__resize-handle-grip' );
  }

  private _dragging ( e : MouseEvent ) {
    const diff = e.clientX - this._startOffsetX;
    this.resizable.dragging( diff );
  }

  private _dragEnd ( e : MouseEvent ) {
    this._startOffsetX = 0;
    document.removeEventListener( 'mousemove', this._boundDragging );
    document.removeEventListener( 'mouseup', this._boundDragEnd );
    this.resizable.dragEnd();
  }
}


@Component( {
  selector : 'sym-resizable',
  template : `
    <div class="sym-resizable__container"
         >
      <div *ngIf="leftPanel"
           [ngClass]="[leftPanel.styleClass ? leftPanel.styleClass : '']"
           [symResizable]="'left'"
           [symResizableWidth]="leftPanel.width"
           [symResizableMinWidth]="leftPanel.minWidth"
           [symResizableMaxWidth]="leftPanel.maxWidth"
           (onChange)="onResizableChange($event)"
      >
        <div class="sym-resizable__content"
           [ngClass]="{'is--scroll-disabled': leftPanel.isScrollable === false }">
          <ng-content select="sym-left-content"></ng-content>
        </div>
        <div symResizableGrabber [styleClass]="leftPanel.grabberStyleClass" [grabberTop]="leftPanel.grabberTop"></div>
      </div>
      <div [symResizable]="'main'"
           [ngClass]="[mainPanel ? mainPanel.styleClass : '']">
        <div class="sym-resizable__content"
             [ngClass]="{'is--scroll-disabled': mainPanel && mainPanel.isScrollable === false }">
          <ng-content select="sym-main-content"></ng-content>
        </div>
      </div>
      <div *ngIf="rightPanel"
           [ngClass]="[rightPanel.styleClass ? rightPanel.styleClass : '']"
           [symResizable]="'right'"
           [symResizableWidth]="rightPanel.width"
           [symResizableMinWidth]="rightPanel.minWidth"
           [symResizableMaxWidth]="rightPanel.maxWidth"
           (onChange)="onResizableChange($event)">
        <div class="sym-resizable__content"
          [ngClass]="{'is--scroll-disabled': rightPanel.isScrollable === false }">
          <ng-content select="sym-right-content"></ng-content>
        </div>
        <div symResizableGrabber [styleClass]="rightPanel.grabberStyleClass" [grabberTop]="rightPanel.grabberTop"></div>
      </div>
    </div>
  `
} )
export class SymResizable {

  @Input() style : any;
  @Input() styleClass : string;
  @Input() leftPanel : ResizableItem;
  @Input() rightPanel : ResizableItem;
  @Input() mainPanel: ResizableItem;

  @Output() onChange = new EventEmitter<ResizableItem>();

  onResizableChange ( resizedItem : ResizableItem ) : void {
    this.onChange.emit( resizedItem );
  }
}

@NgModule( {
  imports      : [SharedModule, CommonModule],
  exports      : [ResizableDirective, GrabberDirective, SymResizable, SharedModule, CommonModule],
  declarations : [ResizableDirective, GrabberDirective, SymResizable]
} )
export class SymResizableModule {
}
