import { ChartColors } from '../common/chart';
import { Utils }       from '../common/utils';
import * as d3         from 'd3';

export class ChartUtils {

  //convert chart color string into hex color.
  static convertColor ( colorString : string ) {
    let hexColor : string;
    const DEFAULT_CHART_COLOR : string = 'COLOR_CHART_02_100';

    if ( colorString ) {
      // As all of our chart colors are defined as hex colors,
      // Allow a valid hex color value.
      if ( /^\#[A-F\d]{3}([A-F\d]{3})?$/i.test( colorString ) ) {
        hexColor = colorString;
      } else {
        let chartColorName = Utils.replaceDashWithUnderscore( colorString );
        hexColor           = ChartColors[ chartColorName ];
      }
    }

    return hexColor || ChartColors[ DEFAULT_CHART_COLOR ];
  };

  static calculateOffset ( n : number, total : number ) {
    const result = ( n * 100 ) / ( total || 1 );
    return result;
  };

  static Ids () {
    let ids = {};

    return {
      set : function ( key : string ) {
        const id   = Math.random().toString( 16 ).slice( 2 );
        ids[ key ] = {
          rect           : 'rect_id_' + id,
          linearGradient : 'linearGradient_id_' + id
        };
      },
      get : function ( key : string ) {
        return ids[ key ];
      }
    };
  };

  static buildDef ( dataItem : any[], id ) {
    const stackedData   = dataItem[ 'stackedData' ];
    const totalBarCount = dataItem[ 'count' ];

    let no_of_offsets = stackedData.length - 1;
    let os            = 0;
    let offsets       = [];
    let def           = {};
    let finalOffset;

    // First stop
    offsets.push( {
      val : os
    } );

    // Middle stops
    let n = 0;
    for ( n = 0; n < no_of_offsets; n++ ) {
      os += stackedData[ n ].value;

      offsets.push( {
        val : this.calculateOffset( os, totalBarCount )
      } );
    }

    // Last stop
    os += stackedData[ n ].value;
    offsets.push( {
      val : this.calculateOffset( os, totalBarCount )
    } );

    // def
    def = {
      id    : id.linearGradient,
      barId : id.rect,
      stop  : []
    };

    for ( let i = 0; i < offsets.length - 1; i += 1 ) {
      finalOffset = !isNaN( offsets[ i ].val ) ? offsets[ i ].val.toString() : 0;
      def[ 'stop' ].push( {
        'offset'    : finalOffset + '%',
        'stopColor' : this.convertColor( stackedData[ i ].color )
      } );

      finalOffset = !isNaN( offsets[ i + 1 ].val ) ? offsets[ i + 1 ].val.toString() : 0;
      def[ 'stop' ].push( {
        'offset'    : finalOffset + '%',
        'stopColor' : this.convertColor( stackedData[ i ].color )
      } );
    }

    return def;
  };

  static buildLegendData ( dataItem : any[] ) {
    let result = [];

    for ( let legendItem of dataItem ) {
      if ( legendItem[ 'legend' ] ) {
        result.push( {
          color       : this.convertColor( legendItem[ 'color' ] ),
          displayName : legendItem[ 'legend' ],
          isHidden    : false
        } );
      }
    }

    return result;
  }

  static buildChartData ( data : any[] ) {
    const id = this.Ids();

    let defs       = {};
    let legendData = [];

    if ( data.length ) {
      for ( let item of data ) {
        id.set( item.label );
        defs[ item.label ] = this.buildDef( item, id.get( item.label ) );
      }

      legendData = this.buildLegendData( data[ 0 ].stackedData );
    }

    return {
      defs       : defs,
      legendData : legendData
    };
  };

  static textWrap ( text, width ) : void {
    text.each( function () {
      let text       = d3.select( this );
      let words      = text.text().split( /\s+/ ).reverse();
      let word;
      let line       = [];
      let lineNumber = 0;
      let lineHeight = 1.1; // ems
      let y          = text.attr( 'y' );
      let dy         = parseFloat( text.attr( 'dy' ) );
      let tspan      = text.text( null )
      .append( 'tspan' )
      .attr( 'x', 0 )
      .attr( 'y', y )
      .attr( 'dy', dy + 'em' );

      while ( word = words.pop() ) {
        line.push( word );
        tspan.text( line.join( ' ' ) );

        if ( tspan.node().getComputedTextLength() > width ) {
          line.pop();
          tspan.text( line.join( ' ' ) );
          line  = [ word ];
          tspan = text.append( 'tspan' )
          .attr( 'x', 0 )
          .attr( 'y', y )
          .attr( 'dy', ++lineNumber * lineHeight + dy + 'em' )
          .text( word );
        }
      }
    } );
  };

  static interpolateD3tooltipHTML (stringTemplate , data) : string {
      // need to figure out other way
      const interpolatedResult = stringTemplate.replace(/(\{\{.*?\}\})/gi, (match) => {
          const key = match.replace(/(\{\{)|(\}\})/gi, '');
          return data[key];
      });

      return interpolatedResult;
  };
}
