import {IParserMetaData} from "../../components/common/parser";

export enum SearchTokenType {
  // Use string values for easier debugging

  Freeform = 'Freeform',
  Invalid = 'Invalid',
  LogicalOperator = 'LogicalOperator',
  Macro = 'Macro',
  Parenthesis = 'Parenthesis',
  Query = 'Query'
}

export class SearchToken {
  type: SearchTokenType;
  token: string;
  displayName: string;
  displayValue: string;
  hideDisplayName?: boolean;
  negated: boolean;
  enabled: boolean;
  meta: IParserMetaData;

  constructor(type: SearchTokenType, token: string, displayName: string, displayValue: string, enabled: boolean, negated: boolean, meta: IParserMetaData) {
    this.type = type;
    this.token = token;
    this.displayName = displayName;
    this.displayValue = displayValue;
    this.negated = negated === undefined ? false : negated;
    this.enabled = enabled === undefined ? true : enabled;
    this.meta = meta || {} as IParserMetaData;
  }

  toString(): string {
    return JSON.stringify(this);
  }
}
