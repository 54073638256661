import {
  Component,
  ElementRef,
  Input,
  NgModule,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {DropdownModule} from '../dropdown/dropdown';
import {ButtonModule} from '../button/button';
import {SharedModule} from '../common/shared';
import {ITypeahead, ITypeaheadItem} from '../common/i-typeahead';
import {TypeaheadList, TypeaheadListModule} from '../typeahead-list/typeahead-list';
import {InputTextModule} from '../input-text/input-text';
import {MenuItem} from '../common/menu-item';
import {TabMenuModule} from '../tab-menu/tab-menu';
import {ICustomFilter, IQuickFilter, ISearchFilterField} from './i-search-filter';
import {ISearchModel} from "../search/i-search-model";
import { SymSubscriptionService } from '../../services/subscription.service';
import {
  IParser,
  IParserConfiguration,
  ParserFieldSimpleValue, ParserQueryOperator
} from "../common/parser";
import {QuickFiltersPipeModule} from './search-filter-quick-queries.pipe'
import {DisplayStringParserService} from "../../services/parser-service/display-string-parser.service";

@Component( {
  selector : 'sym-search-filter-quick-queries',
  template : `
    <div class="sym-search-filter-quick-queries"
         id="sym-search-filter-quick-queries__{{id}}"
         [class.is--processing]="isProcessing">
        <div class="sym-search-filter__filters">
          <div *ngIf="options && !options.hideOperators"
               id="operatorContainerId" class="filter-operators">
           <h5>{{ l10n.operatorTitle }}</h5>
           <ul class="operator-list">
               <li (click)="appendSearchQuery('(')">
                   (
               </li>
               <li (click)="appendSearchQuery(')')">
                   )
               </li>
               <li (click)="appendSearchQuery('AND')">
                   {{ l10n.operators.and }}
               </li>
               <li (click)="appendSearchQuery('OR')">
                   {{ l10n.operators.or }}
               </li>
               <!-- TODO: This needs more work: NOT should be validated as a logop that requires a search term following it...
                          Currently, it's not a logop as validation fails on 2 logops in a row (e.g. "k:v OR NOT k2:v2" fails validation)
               <li (click)="appendSearchQuery('NOT')">
                   {{ l10n.operators.not }}
               </li>
               -->
           </ul>
          </div>
          <div class="sym-search-filter__bottom">
              <p-tabMenu *ngIf="!options.hideTabs && tabMenuItems && tabMenuItems.length > 0"
                         [organizer]="false" [model]="tabMenuItems" [activeItem]="tabMenuActiveItem"></p-tabMenu>
              <div *ngIf="quickFilters && !options.hideQuickFilters"
                   class="sym-search-filter__quick-filters-container sym-search-filter__tab-content"
                   [hidden]="currentTab !== l10n.tabName.quickFilters">
                 <div *ngIf="l10n.quickFilters.title"
                    class="sym-search-filter__quick-filters-note">{{ l10n.quickFilters.title }}</div>
                    <sym-input-text-helper *ngIf="showQuickFiltersFilter" class="sym-search-filter__quick-filters-filter-container" [value]="filterValue"
                        (onClear)="clearQuickFiltersFilter()">
                      <input #filterInput type="text" role="textbox"
                             [(ngModel)]="filterValue"
                             class="ui-inputtext ui-widget ui-state-default ui-corner-all"
                             attr.placeholder="placeholder">
                      <sym-icon
                              [hidden]="filterValue || filterValue !==''"
                              svgClass="sym-search-filter__filter-icon sym-smbl__action-search-input sym-smbl--black-40"
                              svgId="sym-smbl__action-search-input"></sym-icon>
                  </sym-input-text-helper>
                  <div class="sym-search-filter__quick-filters">
                      <ul class="sym-search-filter__quick-filters-list">
                          <li *ngFor="let filter of (quickFilters | quickFiltersPipe: filterValue) ">
                              <div class="sym-search-filter__filter-name" (click)="filter.isVisible = !filter.isVisible"
                                   id="{{id}}_qf_{{filter.id}}">
                                  <span class="is--extra-small icon__chevron is--blue is--rotate-270"
                                        [ngClass]="{ 'is--rotate-270': !filter.isVisible }"></span>
                                  {{ filter.displayName}}
                              </div>
                              <ul [hidden]="!filter.isVisible" class="sym-search-filter__sub-filter-list">
                                  <li *ngFor="let subFilter of filter.items" (click)="addQuickFilter(subFilter)">
                                      <span class="sym-search-filter__quick-filter-name"
                                            id="{{id}}_qf_{{subFilter.id}}">{{ subFilter.displayValue || subFilter.value }}</span>
                                  </li>
                              </ul>
                          </li>
                      </ul>
                  </div>
              </div>
          </div>


          <div *ngIf="customFilter && !options.hideCustomFilter"
            class="sym-search-filter__custom-filter-container sym-search-filter__tab-content"
            [hidden]="currentTab !== l10n.tabName.customFilter">
            <div class="sym-search-filter__custom-filter-label"> {{l10n.customeFilterLabel }} </div>
            <div class="sym-search-filter__custom-filter-typeahead sym-search-filter__custom-filter-typeahead-field">
                <sym-typeahead-list selected-value=""
                                    [value]="customFilter.value"
                                    [model]="customFilter.items"
                                    [(isValid)]="customFilter.isValid"
                                    [errorMsg]="l10n.customFilter.fieldErrorMessage"
                                    [placeholder]="customFilter.placeholder"
                                    [id]="customFilter.id"
                                    (onChange)="onCustomFilterFieldChange($event)"
                                    (onClear)="onCustomFilterFieldClear()"></sym-typeahead-list>
            </div>
            <div *ngIf="options.isDynamicOperator"
                 class="sym-search-filter__custom-filter-typeahead sym-search-filter__custom-filter-operator">
                <sym-typeahead-list
                        [model]="customFilterOperatorList.items"
                        [value]="customFilterOperatorList.value"
                        [id]="customFilterOperatorList.id"
                        [(isValid)]="customFilterOperatorList.isValid"
                        [errorMsg]="customFilterOperatorList.errorMsg"
                        [placeholder]="customFilterOperatorList.placeholder"
                        (onChange)="onCustomFilterOperatorChange($event)"
                        (onClear)="onCustomFilterOperatorClear()"
                ></sym-typeahead-list>
            </div>
            <div *ngIf="!options.isDynamicOperator"
                 class="sym-search-filter__custom-filter-typeahead sym-search-filter__custom-filter-operator sym-search-filter__custom-filter-operator--static">
                <input pInputText disabled type="text" class="is--text-input" value="{{l10n.operators.equals }}">
            </div>
            <div class="sym-search-filter__custom-filter-typeahead sym-search-filter__custom-filter-typeahead-value">
                <sym-typeahead-list
                        [model]="customFilterValueList.items"
                        [value]="customFilterValueList.value"
                        [id]="customFilterValueList.id"
                        [isValid]="customFilterValueList.isValid"
                        [errorMsg]="customFilterValueList.errorMsg"
                        [placeholder]="customFilterValueList.placeholder"
                        (onChange)="onCustomFilterValueChange($event)"
                        (onClear)="onCustomFilterValueClear()"
                ></sym-typeahead-list>
            </div>

            <section>
                <sym-button (click)="addCustomFilter()"
                            label="{{ l10n.customFilter.addCustomeFilterButton }}"
                            [disabled]="!isValidCustomFilter"
                            styleClass="ui-button-secondary"
                            class="sym-search-filter__cust-filter-add-button">
                    <span></span>
                </sym-button>
            </section>
           </div>

        </div>
    </div>
  `
} )

export class SearchFilterQuickQueries implements OnInit {

  @Input() id : string; //REQUIRED. Should be the same ID for SearchFilter

  @Input() quickFilters : IQuickFilter[];

  _customFilter: ICustomFilter;

  @Input() get customFilter(): ICustomFilter {
      return this._customFilter;
  }
  set customFilter(val: ICustomFilter) {
     this._customFilter = val;
     if (val) {
       this.customFilter.isValid = true;
     }
  }

  @Input() set parserService(value: IParser) {
    if (value) {
      this.databaseParserService = value;
      this.friendlyParserService.configure(this.databaseParserService.getConfiguration() || {});

      // Make sure any config is also set on the friendly parser service
      const setConfig = this.databaseParserService.configure;
      this.databaseParserService.configure = (config: IParserConfiguration): IParser => {
        this.friendlyParserService.configure(config);
        return setConfig.call(this.databaseParserService, config);   // Otherwise "this" is lost in unit tests
      }
    }
  }
  get parserService(): IParser {   // Getter is needed for unit test only...
    return this.databaseParserService;
  }

  @Input() l10n : any;
  @Input() hasError : boolean;
  @Input() isProcessing : boolean;
  @Input() isInlineSearch = false;
  @Input() showQuickFiltersFilter = false;

  @ViewChild('searchField', { static: false }) searchFieldElt: ElementRef;
  @ViewChild('autocompleteSuggestions', { static: false }) autocompleteSuggestionsElt: ElementRef;

  @ViewChildren( TypeaheadList ) typeaheadChildren! : QueryList<TypeaheadList>;

  databaseParserService: IParser;      // Database source of truth parser service
  friendlyParserService: IParser = new DisplayStringParserService();   // "Friendly edit" display strings only parser service
  friendlySearchModel: ISearchModel;
  showingFriendlyTextInput = false;
  filterValue : string = '';
  currentTab : string;

  private _options = {
            hideQuickFilters: false,
            hideCustomFilter: false
          };
  private _inited = false;


  @Input() get options () : any {
    return this._options;
  }

  set options ( val : any ) {
    this._options = val;
    if ( this._inited && this._options ) {
      this.setupTabs();
    }   // Otherwise setupTabs will be called in ngOnInit
  }

  isValidCustomFilter : boolean             = false;
  customFilterOperatorList : ITypeahead = {};
  customFilterValueList : ITypeahead    = {};
  tabMenuItems : MenuItem[];
  tabMenuActiveItem : MenuItem;
  operatorMap : any = {
    // Ideally this is `ParserQueryOperator.EQUALS: 'Equals',`, but TypeScript doesn't support it
    'CONTAINS': 'Contains',
    'DOES_NOT_EXIST': 'Does not exist',
    'EQUALS': 'Equals',
    'EXISTS': 'Exists',
    'GREATER_THAN': 'Is greater than',
    'GREATER_THAN_EQUAL_TO': 'Is greater than or equals',
    'IS_BETWEEN': 'Is in between',
    'IS_NOT_BETWEEN': 'Is not in between',
    'IS_NOT_ONE_OF': 'Is not one of',
    'IS_ONE_OF': 'Is one of',
    'LESS_THAN': 'Is less than',
    'LESS_THAN_EQUAL_TO': 'Is less than or equals',
    'MATCHES': 'Matches',
    'NOT_EQUALS': 'Does not equal',
    'WILDCARD': 'Wildcard'
  };


  l10nCustomFilterValuePlaceholder : string;
  l10nCustomFilterOperatorPlaceholder : string;

  customFilterValueListId : string;
  customFilterValueListEnabled = true;
  // Due to the type-ahead type definition, these need to be strings
  operatorsThatDoNotRequireValue = [ ParserQueryOperator.EXISTS.toString(), ParserQueryOperator.DOES_NOT_EXIST.toString() ];


  constructor ( public el : ElementRef, private subscriptionService : SymSubscriptionService) {
  }


  ngOnInit () {
    this.l10nCustomFilterOperatorPlaceholder = this.l10n && this.l10n.customFilter && this.l10n.customFilter.operatorPlaceholder || '';
    this.l10nCustomFilterValuePlaceholder = this.l10n && this.l10n.customFilter && this.l10n.customFilter.valuePlaceholder || '';

    // Avoid operator and value placeholders to be empty initially (otherwise they only get filled out when a field is entered)
    this.customFilterOperatorList.placeholder = this.l10nCustomFilterOperatorPlaceholder;
    this.customFilterValueList.placeholder = this.l10nCustomFilterValuePlaceholder;

    this.setupTabs();

    this._inited = true;
  }

  appendSearchQuery (val) {
    this.subscriptionService.sendEvent<any>( 'search-filter-append-' + this.id, val ); //not needed due to this.symRuleBuilder.setCurrentItem(item);
  }

  addQuickFilter (val) {
    this.subscriptionService.sendEvent<any>( 'search-filter-add-quick-filter-' + this.id, val ); //not needed due to this.symRuleBuilder.setCurrentItem(item);
  }

  setupTabs () {
      if ( this.options && (!this.options.hideQuickFilters || !this.options.hideCustomFilter) ) {

        this.tabMenuItems = [
          {
            id:  this.id + '-tabmenu-quick-filter',
            label   : this.l10n.tabName.quickFilters,
            icon    : '',
            visible : !this.options.hideQuickFilters,
            command : () => {
              this.currentTab = this.l10n.tabName.quickFilters;
            }
          },
          {
            id:  this.id + '-tabmenu-custom-filter',
            label   : this.l10n.tabName.customFilter,
            icon    : '',
            visible : !this.options.hideCustomFilter,
            command : () => {
              this.currentTab = this.l10n.tabName.customFilter;

            }
          }
        ];
        //Set current tab:
        if ( !this.options.hideQuickFilters ) {
          this.tabMenuActiveItem = this.tabMenuItems[ 0 ];
          this.currentTab        = this.l10n.tabName.quickFilters;
        } else {
          this.tabMenuActiveItem = this.tabMenuItems[ 1 ];
          this.currentTab        = this.l10n.tabName.customFilter;
        }
      }
      else {
        this.tabMenuActiveItem = null;
        this.currentTab        = null;
      }

  }

  buildOperandList ( supportedOperators : ParserQueryOperator[] ) {
    const operatorList = supportedOperators.map(
      ( op : string ) => {
        const newOp = {
          displayName   : this.operatorMap[ op ],
          id            : op,
          value         : 'operator'
        };
        return newOp;
      }
    );

    return operatorList.sort(function (a: any, b: any) { return a.displayName < b.displayName ? -1 : a.displayName > b.displayName ? 1 : 0; });
  }

  validateCustomFilter () {
    if ( !this.customFilter.selectedItem ||
      (this.options.isDynamicOperator && !this.customFilterOperatorList.selectedItem) ||
      (this.customFilterValueListEnabled &&
        (
          !this.customFilterValueList.selectedItem ||
          !this.customFilterValueList.selectedItem.displayName ||
          this.customFilterValueList.selectedItem.displayName.trim().length === 0
        )
      )
    ) {
        setTimeout(() => {
          this.isValidCustomFilter = false;
        });
        return;
    }

    let customFilterValidate: (string | boolean) = true;
    if (this.customFilter.selectedItem.onValidate) {
      customFilterValidate = this.customFilter.selectedItem.onValidate(this.customFilterValueList.selectedItem);
    }
    else if (this.customFilter.onValidate) {
      customFilterValidate = this.customFilter.onValidate(this.customFilter.selectedItem, this.customFilterValueList.selectedItem);
    }
    else {
      customFilterValidate = true;
    }

    setTimeout(() => {
      if (customFilterValidate !== true) {
        this.isValidCustomFilter = false;
        this.customFilterValueList.isValid = false;
        this.customFilterValueList.errorMsg = customFilterValidate || this.l10n.customFilter.errorMessage;
      }
      else {
        this.isValidCustomFilter = true;
        this.customFilterValueList.isValid = true;
      }
    }, 100);
  }

  onCustomFilterFieldClear () {
    this.resetCustomFilter();
  }

  onCustomFilterFieldChange ( newField : ISearchFilterField ) {
    if ( !newField.displayName ) {
      this.customFilter.selectedItem = null;
      this.resetCustomFilter();
      return;
    }

    if (!this.customFilterValueListId) {
      this.customFilterValueListId  = this.id + '-customfilter-value';
      this.customFilterOperatorList = {
        id           : this.id + '-customfilter-operator',
        items        : [],
        selectedItem : null,
        placeholder  : this.l10nCustomFilterOperatorPlaceholder,
        isValid      : true
      };

      this.customFilterValueList = {
        id           : this.customFilterValueListId,
        items        : [],
        selectedItem : null,
        value        : '',
        placeholder  : this.l10nCustomFilterValuePlaceholder,
        isValid      : true
      };
    }

    this.customFilter.isValid =  true;
    if (!newField.id) {
      this.customFilter.isValid =  false;
      this.customFilterValueList.items = [];

      // Reset customFilterValueList values
      this.customFilterValueList.placeholder = this.l10nCustomFilterValuePlaceholder;

      // Need to set to a value then back to empty value for the typeahead-list onValueChange to fire and remove the (x) icon
      this.customFilterValueList.value = ' ';
      setTimeout( () => {
        this.customFilterValueList.value = '';
      },50);

      return;
    }

    this.customFilter.selectedItem     = newField;
    this.customFilterValueList.items   = newField.suggestedValues.map((val) => {
      let newItem: ITypeaheadItem = {};
      newItem.id = typeof val.value !== 'string' ? val.value.toString() : val.value;
      newItem.displayName = this.databaseParserService.unescapeSpecialChars(val.displayValue);
      return newItem;
    });

    this.customFilterOperatorList.items = newField && newField.supportedOperators ? this.buildOperandList( newField.supportedOperators ) : null;
  }

  onCustomFilterOperatorChange ( op : ITypeaheadItem ) {
    this.customFilterOperatorList.selectedItem = op;
    this.customFilterValueListEnabled = this.operatorsThatDoNotRequireValue.indexOf(op.id) < 0;
    this.validateCustomFilter();
  }

  onCustomFilterOperatorClear () {
    this.customFilterValueListEnabled = true;
  }

  onCustomFilterValueChange ( val : ITypeaheadItem ) {
    this.customFilterValueList.selectedItem = val;
    this.validateCustomFilter();
  }

  onCustomFilterValueClear () {
    this.customFilterValueList.selectedItem = null;
    this.validateCustomFilter();
  }


  formatSearchQueryToken(field: string, value: ParserFieldSimpleValue, operator = ParserQueryOperator.EQUALS): string {
    return this.databaseParserService.createTokenString(field, value, operator);
  }

  resetCustomFilter () {
    setTimeout( () => {
      // NOTE: just setting this.customFilterValueList.value = "" didn't work
      // (work for the first time clearing only),
      // so calling clearSearch on each typehead component
      this.typeaheadChildren.forEach( element => {
        if ( element.id !== this.customFilter.id ) {
          element.clearItems();
        }
        element.clearValue();
      } );
      this.customFilterOperatorList.selectedItem = null;
      this.customFilterValueListEnabled          = true;
      this.customFilterValueList.selectedItem    = null;
      this.isValidCustomFilter                   = false;
    } );

  }

  addCustomFilter () {
    if ( !this.isValidCustomFilter ) {
      return;
    }
    let value : ITypeaheadItem = this.customFilterValueList.selectedItem;
    let field : ITypeaheadItem = this.customFilter.selectedItem;
    let operator = this.options.isDynamicOperator ? this.customFilterOperatorList.selectedItem : {
      id: 'EQUALS',
      displayName: this.operatorMap.EQUALS
    };

    if ( !field || !operator || (this.customFilterValueListEnabled && !value) ) {
      return;
    }

    let displayName = value.displayName;
    if (!value.id) {
      // Not an auto-complete item. User entered it, so handle escaping of special characters.
      displayName = this.databaseParserService.escapeSpecialChars(displayName);
    }

    let customSearchQuery = this.formatSearchQueryToken(field.id, value ? value.id || displayName : undefined, operator.id);
    this.appendSearchQuery(customSearchQuery);

    this.resetCustomFilter();
  }


  clearQuickFiltersFilter () {
    setTimeout(() => {
      this.filterValue = '';
    }, 100);
  }
}

@NgModule( {
  imports: [CommonModule, DropdownModule, FormsModule, SharedModule, TypeaheadListModule, ButtonModule, InputTextModule, TabMenuModule, QuickFiltersPipeModule],
  exports      : [SearchFilterQuickQueries, CommonModule, DropdownModule, FormsModule, SharedModule, TypeaheadListModule, ButtonModule, InputTextModule, TabMenuModule],
  declarations : [SearchFilterQuickQueries]
} )
export class SearchFilterQuickQueriesModule {
}
