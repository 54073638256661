import { Injectable } from '@angular/core';
import {LuceneParserService} from './lucene-parser.service';
import {SearchToken} from "./search-token";

/**
 * Custom Lucene parser output for policy pages only.
 */
@Injectable({ providedIn : 'root' })
export class PolicyParserService extends LuceneParserService {
  constructor() {
    super();
  }

  expandToken(token: SearchToken): string {
    // Replace separator
    let result = super.expandToken(token).replace(this.fieldNameAndValueSeparator, '=');   // Replaces first occurrence only

    // All values need to be quoted, even single words
    result = result.replace(/=(\s*)(.*)\s*$/g, (match: string, space: string, value: string) => {
      if (/^".*"$/.test(value)) {
        return '=' + space + value;
      } else {
        return '=' + space + '\'' + value.replace('\'', '\\\'') + '\''
      }
    });

    // Replace quotes around value
    // Note: single quotes within value now will need to be escaped, while double quotes no longer need to be escaped
    result = result.replace(/=(\s*)"(.*)"\s*?$/g, (match: string, space: string, value: string) => '=' + space + '\'' + value.replace('\\"', '"').replace('\'', '\\\'') + '\'');

    // Replace operator for policy name field only
    if (token.meta.config && token.meta.config[0].name === 'name') {
      result = result.replace(/name(\s*)=/g, (match: string, space: string) => 'name' + space + '~');
    }

    return result;
  }

  getExpandedQuery(): string {
    let query = super.getExpandedQuery();
    return query ? '(' + query + ')' : query;
  }
}
