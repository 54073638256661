import { Component, Input, Output, NgModule, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomHandler } from '../dom/dom-handler';
import { MenuItem } from '../common/menu-item';
import { RouterModule } from '@angular/router';
import { TooltipModule } from '../tooltip/tooltip';
import { MenuModule } from '../menu/menu';
import { fromEvent, interval } from 'rxjs';
import { debounce } from 'rxjs/operators';
    
@Component({
    selector: 'p-tabMenu',
    template: `
        <div #tabContainer id="tabContainer" [ngClass]="'ui-tabmenu ui-widget ui-widget-content'" [ngStyle]="style"
             [class]="styleClass">
            <ul class="ui-tabmenu-nav ui-helper-reset ui-helper-clearfix ui-widget-header"
                role="tablist">
                <li *ngFor="let item of tabMenuModel"
                    [ngClass]="{'ui-tabmenuitem ui-state-default ui-corner-top':true,'ui-state-disabled':item.disabled,
                        'ui-tabmenuitem-hasicon':item.icon,'ui-state-active':activeItem==item,'ui-helper-hidden': item.visible === false}"
                    [routerLinkActive]="'ui-state-active'"
                    [routerLinkActiveOptions]="item.routerLinkActiveOptions||{exact:false}"
                    [pTooltip]="item.htmlToolTip"
                    [tooltipDisabled]= "!item.htmlToolTip"
                    tooltipPosition="top"
                    placeholder="Top"
                    [escape]="false"
                    hideDelay="1500"
                    showDelay="1000">
                    <a *ngIf="!item.routerLink && !item.dropdownMenu" [href]="item.url||'#'"
                       class="ui-menuitem-link ui-corner-all" (click)="itemClick($event,item)"
                       [attr.target]="item.target" [attr.title]="item.title" [attr.id]="item.id">
                        <span class="ui-menuitem-icon " [ngClass]="item.icon"
                              *ngIf="item.icon"></span>
                        <span class="ui-menuitem-text">{{item.label}}</span>
                    </a>
                    <a *ngIf="item.routerLink && !item.dropdownMenu" [routerLink]="item.routerLink"
                       [queryParams]="item.queryParams" class="ui-menuitem-link ui-corner-all"
                       (click)="itemClick($event,item)"
                       [attr.target]="item.target" [attr.title]="item.title" [attr.id]="item.id">
                        <span class="ui-menuitem-icon " [ngClass]="item.icon"
                              *ngIf="item.icon"></span>
                        <span class="ui-menuitem-text">{{item.label}}</span>
                    </a>
                    <a *ngIf="item.dropdownMenu" class="ui-menuitem-link tab-menu-dropdown-button">
                        <span class="ui-menuitem-text" (click)="menu.toggle($event)">{{item.label}} <svg class="sym-smbl--arrow-nav sym-smbl--black-40"><use href="#sym-smbl__arrow-chevron"></use></svg></span>
                        <p-menu #menu class="tab-menu-dropdown" [appendTo]="tabContainer" [offset]="{top:20,right:-16}" [popup]="true" [model]="dropdownItems"></p-menu>
                    </a>
                </li>
            </ul>
        </div>
    `,
    providers: [DomHandler]
})
export class TabMenu {

    @Input() activeItem: MenuItem;
    @Input() dropdownMenuLabel: string = 'More';
    @Input() popup: boolean;
    @Input() style: any;
    @Input() styleClass: string;
    @Input() organizer: boolean = false;
    @ViewChild('tabContainer') tabContainer:ElementRef;
    dropdownItems: MenuItem[] = [];
    tabMenuModel: any;
    tabMenuModelStored: string = "tabMenuModel-" + Math.floor( ( Math.random() * 1000000 ) + 1 );
    
    private _model : MenuItem[];
    private _inited = false;

    @Input() get model () : MenuItem[] {
        return this._model;
    }
    set model ( val : MenuItem[] ) {
        this._model = val;
        this.tabMenuModel = val;
        if ( ( this.organizer === true ) && this._inited ) {
            sessionStorage.setItem( this.tabMenuModelStored, JSON.stringify( this.tabMenuModel ) );
            setTimeout(() => {
              this.tabsOrganizer();
            });
        } 
    }

    constructor(){ 
        const resize = fromEvent(window, 'resize');
        const result = resize.pipe(debounce(() => interval(300)));
        result.subscribe( event => {
            if ( this.organizer === true ) {
                this.resetTabMenuModel();
                setTimeout(() => { 
                    this.tabsOrganizer();
                });
            }
        });
    }

    resetTabMenuModel(){
        if ( sessionStorage.getItem( this.tabMenuModelStored ) ) { 
            this.tabMenuModel = JSON.parse( sessionStorage.getItem( this.tabMenuModelStored ) );
        }        
    }

    tabsOrganizer(){

        if ( this.organizer === true ) {

            let tabContainer      : HTMLElement = this.tabContainer.nativeElement,
                tabContainerWidth : number      = tabContainer.clientWidth,
                tabs              : any         = tabContainer.getElementsByClassName( 'ui-tabmenuitem' ),
                tabWidths         : number[]    = [],                        
                getTabWidths      : any         = (() => {
                    if ( tabContainerWidth && tabs.length ) {
                        for ( let i = 0; i < tabs.length; i += 1 ) {
                            tabWidths.push( tabs[i].clientWidth );
                        }
                    }
                    return tabWidths;
                })(),
                availableSpace : number = tabContainerWidth - 120,
                visibleTabs    : number = 0,
                getVisibleTabs : any    = (() => {
                    if ( tabContainerWidth && tabWidths.length > 0 ) {
                        let tabWidthsArray    : number[] = [ ...tabWidths ],
                            checkTotal        : any      = ( total, value ) => { return total + value },
                            tabWidthsTotal    : number   = 0,
                            getTabWidthsTotal : any      = () => { return tabWidthsArray.reduce( checkTotal, 0 ); };
                        tabWidthsTotal = getTabWidthsTotal();  
                        if ( tabWidthsTotal > tabContainerWidth ) {
                            while ( tabWidthsTotal > availableSpace ) {
                                tabWidthsArray.pop();
                                tabWidthsTotal = getTabWidthsTotal();
                            }
                        }
                        visibleTabs = tabWidthsArray.length;
                        return visibleTabs;
                    }
                })(),
                dropDownModel    : any[] = [], 
                getDropdownModel : any   = (() => {
                    if ( tabContainerWidth && visibleTabs > 0 && visibleTabs < tabs.length ) {
                        dropDownModel = this.tabMenuModel.splice( visibleTabs, tabs.length )
                        return dropDownModel;
                    }
                })();
            if ( tabContainerWidth && dropDownModel.length ) {
                this.tabMenuModel.push( { label: this.dropdownMenuLabel, dropdownMenu : true } );
                this.dropdownItems = [ ...getDropdownModel ];
            }
        }
    }

    itemClick(event: Event, item: MenuItem) {
        if (item.disabled) {
            event.preventDefault();
            return;
        }

        if (!item.url) {
            event.preventDefault();
        }

        if (item.command) {
            item.command({
                originalEvent: event,
                item: item
            });
        }

        this.activeItem = item;
    }

    ngAfterViewInit() { 
        if ( this.organizer === true ) {
            setTimeout(() => {
                sessionStorage.setItem( this.tabMenuModelStored, JSON.stringify( this.model ) );
                this.tabsOrganizer();
                this._inited = true;
            });
        }
    }

    ngOnDestroy() { 
        sessionStorage.removeItem( this.tabMenuModelStored );
    }

}

@NgModule({
    imports: [ CommonModule, RouterModule, TooltipModule, MenuModule ],
    exports: [ TabMenu, RouterModule ],
    declarations: [ TabMenu ]
})
export class TabMenuModule {
}
