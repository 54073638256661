
export class SymJourneyLineModel {

    id?: string;
    displayName?: string;
    description?: string;
    isCompleted?: boolean;
    preventNext?: boolean;
    preventBack?: boolean;
    buttonLabel?: string;
    submitLabel?: string;
    hideButtons?: boolean;
    disableFooterSeparator?: boolean;
    currentStepIndex?: number;
    disableIconNavigation?: boolean;
    hasCustomLines?: boolean;
    customLinesTemplate?: string;
    steps?: Array<SymJourneyLineStepModel>;
}

export class SymJourneyLineStepModel {

    id?: string;
    displayName?: string;
    iconClass?: string;
    template?;
    component?: any;
    description?: string;
    isCompleted?: boolean;
    isCurrentStep?: boolean;
}


