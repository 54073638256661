import { NgModule, Component, Input, OnInit, OnDestroy, Output, EventEmitter, SimpleChanges, ElementRef } from '@angular/core';
import { CommonModule }                                                                                   from '@angular/common';
import * as d3                                                                                            from 'd3';
import { WidgetLoaderModule }                                                                             from '../widget-loader/widget-loader';
import { ObjectUtils }                                                                                    from '../utils/object-utils';
import { Utils }                                                                                          from '../common/utils';
import { CHART }                                                                                          from '../common/chart';
import { ChartUtils }                                                                                     from '../utils/chart.utils';

@Component( {
  selector  : 'sym-chart-stacked-vertical-bar',
  template  : `
    <div [ngStyle]="style" [ngClass]="[ 'sym-chart-stacked-vertical-bar__container', customStyleClass]">
      <h3 *ngIf="header">{{header}}</h3>
      <div [hidden]="isChartHidden">
        <div class="sym-chart-stacked-vertical-bar">
          <figure [id]="barChartId" class="sym-chart__data"></figure>
          <div class="sym-chart__tooltip-container"></div>
        </div>
        <div *ngIf="showLegend" class="sym-chart__legend-container is--vertical is--circle">
          <span class="sym-chart__legend-item header__item"
                [ngClass]="{ 'sym-chart__is--link' : isSelectableLegend }"
                *ngFor="let legend of legendData"
                (click)="onClickLegend($event, legend)">
            <span class="sym-chart__legend-color" [ngStyle]="{ 'border-color': legend.color }"></span>
            <span class="sym-type__item-description">{{legend.displayName}}</span>
          </span>
        </div>
      </div>
      <div [hidden]="!isChartHidden" style="height:{{chartHeight}}px;" class="center-content-container">
        <div [hidden]="isLoadingHidden" class="progress-spinner-container"><svg class="sym-smbl__progress-spinner sym-smbl--medium"><use href="#sym-smbl__progress-spinner"></use></svg></div>
        <div [hidden]="!isLoadingHidden" class="sym-chart__no-data">
          <span class="error-icon"><svg class="sym-smbl__dashboard-error"><use href="#sym-smbl__dashboard-no-data"></use></svg></span>
          <span>{{ noData }}</span>
        </div>
      </div>
    </div>
  `,
  styles    : [ `

  ` ],
  providers : [ ObjectUtils, Utils ]
} )
export class SymChartStackedVerticalBar implements OnInit, OnDestroy {
  TOOLTIP_ARROW_WIDTH : number  = 5;
  TOOLTIP_DEFAULT_HTML : string = '{displayName}<br>{count}';

  hostElement : HTMLElement; // Native element hosting the SVG container
  _data : any;
  barChartId : string;
  chartHeight : number         = 290;
  elmChartContainer;
  header : string              = '';
  isChartHidden : boolean      = true;
  isLoadingHidden : boolean    = true;
  noData : string              = CHART.NO_DATA;
  customStyleClass : string    = '';
  legendData                   = null;
  showLegend : boolean         = false;
  isSelectableLegend : boolean = false;
  tooltip;
  tooltipHTML : string         = this.TOOLTIP_DEFAULT_HTML;
  tooltipOffsetLeft : number   = 0;
  tooltipOffsetTop : number    = 0;

  @Input() options : any;

  @Input() style : any;

  @Input() styleClass : string;

  @Input() get data () : any {
    return this._data;
  }

  @Output() onSelect : EventEmitter<any> = new EventEmitter();

  set data ( val : any ) {
    this._data = val;

    const isDataArray = Array.isArray( this._data );

    this.isChartHidden   = !isDataArray;
    this.isLoadingHidden = isDataArray;

    if ( this.isLoadingHidden && this._data.length === 0 ) {
      this.isChartHidden = true;
    }

    if ( this.options ) {
      this.header = this.options[ 'header' ] || '';
      this.noData = this.options[ 'noData' ] || this.noData;

      if ( this.options[ 'legend' ] && this.options[ 'legend' ].onSelect ) {
        if ( this.objectUtils.isFunction( this.options[ 'legend' ].onSelect ) ) {
          this.isSelectableLegend = true;
        }
      }
    }

    this.customStyleClass = this.styleClass || '';
  }

  constructor (
    private elRef : ElementRef,
    private objectUtils : ObjectUtils
  ) {
    this.hostElement = this.elRef.nativeElement;
  }

  private svg;

  private onClick = ( event, data, index, stack ) : void => {
    this.onSelect.emit( { event : event, data : data, index : index, stack : stack } );
  };

  public onClickLegend = ( event, data ) : void => {
    if ( this.isSelectableLegend ) {
      this.options[ 'legend' ].onSelect( { event : event, data : data } );
    }
  };

  private buildLegend ( legendData ) : void {
    if ( this.options && this.options[ 'legend' ] && this.options[ 'legend' ].hide ) {
      this.showLegend = false;
    } else {
      this.showLegend = legendData.length !== 0;
    }

    this.legendData = legendData;
  }

  private interpolateD3tooltipHTML ( str, count, displayName ) : string {
    const result : string = this.objectUtils.supplant( str, {
      count       : count,
      displayName : displayName
    } );

    return result;
  };

  private buildToolTip () : void {
    if ( this.options && this.options.tooltip ) {
      if ( this.options.tooltip.html ) {
        this.tooltipHTML = this.options.tooltip.html;
      }

      if ( this.options.tooltip.offset ) {
        this.tooltipOffsetTop  = this.options.tooltip.offset.top || 0;
        this.tooltipOffsetLeft = this.options.tooltip.offset.left || 0;
      }
    }

    if ( Array.isArray( this._data ) && this._data.length ) {
      const populatedHTML = this.interpolateD3tooltipHTML( this.tooltipHTML, this._data[ 0 ].count, this._data[ 0 ].displayName );

      this.tooltip = d3.select( '.sym-chart__tooltip-container' )
      .append( 'div' )
      .attr( 'class', 'sym-chart-stacked-vertical-bar__tooltip is--bar-chart' )
      .style( 'visibility', 'hidden' )
      .html( populatedHTML );
    }
  }

  private buildSvg ( data : any[] ) : void {
    const chartData = ChartUtils.buildChartData( data );

    // Legend
    this.buildLegend( chartData.legendData );

    let marginLeft : number           = 50;
    let barWidth : number             = 20;
    let isXAxisSelectable : boolean   = false;
    let xAxisSelectableClass : string = '';
    let xTickFontSize : number        = 14;
    let isYAxisSelectable : boolean   = false;
    let yAxisSelectableClass : string = '';
    let yTickFontSize : number        = 14;
    let yTicks : number               = 5;

    if ( this.options ) {
      barWidth         = this.options.barWidth || 20;
      this.chartHeight = this.options.height || 300;

      if ( this.options.axis ) {
        if ( this.options.axis.x ) {
          if ( this.options.axis.x.onSelect ) {
            isXAxisSelectable    = true;
            xAxisSelectableClass = ' sym-chart__is--link';
          }

          xTickFontSize = this.options.axis.x.fontSize || 14;
        }

        if ( this.options.axis.y ) {
          if ( this.options.axis.y.onSelect ) {
            isYAxisSelectable    = true;
            yAxisSelectableClass = ' sym-chart__is--link';
          }

          if ( this.options.axis.y.width ) {
            marginLeft = parseInt( this.options.axis.y.width, 10 );
          }

          yTickFontSize = this.options.axis.y.fontSize || 14;
          yTicks        = this.options.axis.y.ticks || 5;
        }
      }
    }

    this.elmChartContainer  = this.hostElement.querySelectorAll( '.sym-chart-stacked-vertical-bar__container' );
    const elmWidth : number = ( this.elmChartContainer && this.elmChartContainer[ 0 ] && this.elmChartContainer[ 0 ].clientWidth ) || 0;
    const width : number    = ( elmWidth || 500 );
    let height : number     = this.chartHeight;
    const margin            = { top : 20, right : 20, bottom : 0, left : marginLeft };

    const innerWidth  = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // Reset
    if ( this.hostElement.querySelector( '#' + this.barChartId ) ) {
      this.hostElement.querySelector( '#' + this.barChartId ).innerHTML = '';
    }

    this.svg = d3.select( 'figure#' + this.barChartId )
    .append( 'svg' )
    .attr( 'class', 'sym-canvas' )
    .attr( 'width', width )
    .attr( 'height', height + margin.top )
    .append( 'g' )
    .attr( 'class', 'sym-chart-container' )
    .attr( 'transform', `translate( ${margin.left}, 0 )` );

    //--[ X Axis Scale ] ------------------//
    const xAxisScale = d3.scaleBand()
    .domain( data.map( ( d ) => d.displayName ) )
    .range( [ 0, innerWidth ] );

    const xAxis = d3.axisBottom( xAxisScale )
    .tickSize( 0 )
    .tickPadding( 10 );

    this.svg.append( 'g' )
    .call( xAxis )
    .attr( 'class', `axis is--x ${xAxisSelectableClass}` )
    .attr( 'transform', `translate( 0, ${innerHeight} )` )
    .style( 'font-size', `${xTickFontSize}px` )
    .style( 'color', '#ccc' )

    .selectAll( '.tick text' )
    .call( ChartUtils.textWrap, xAxisScale.bandwidth() );

    this.svg.selectAll( '.axis.is--x .tick' )
    .on( 'click', ( d, i ) => {
      let selected = {
        index : i,
        data  : {}
      };

      for ( let item of data ) {
        if ( item[ 'displayName' ] === d ) {
          selected.data = item;
          break;
        }
      }

      if ( isXAxisSelectable ) {
        this.options[ 'axis' ].x.onSelect( { event : d3.event, data : selected } );
      }
    } );

    //--[ Y Axis ] ------------------//
    let xMax : number = d3.max( data, layer => parseInt( layer[ 'count' ], 10 ) );

    const yAxisScale = d3.scaleLinear()
    .domain( [ xMax, 0 ] )
    .range( [ 0, innerHeight ] );

    const yAxis = d3.axisLeft( yAxisScale )
    .tickSize( 5 )
    .tickSizeOuter( 0 )
    .ticks( yTicks )
    .tickPadding( 5 );

    this.svg.append( 'g' )
    .attr( 'class', `axis is--y ${yAxisSelectableClass}` )
    .style( 'color', '#ccc' )
    .attr( 'transform', `translate( 0, 0 )` )
    .call( yAxis )
    .selectAll( 'text' )
    .attr( 'y', '0' )
    .style( 'font-size', `${yTickFontSize}px` )
    .style( 'text-anchor', 'right' )
    .on( 'click', ( d, i ) => {
      let selected = {
        index : i,
        data  : d
      };

      if ( isYAxisSelectable ) {
        this.options[ 'axis' ].y.onSelect( { event : d3.event, data : selected } );
      }
    } );

    //--[ Defs ] ------------------//
    for ( let item in chartData.defs ) {
      this.svg.append( 'defs' )
      .append( 'linearGradient' )
      .attr( 'y1', '100%' )
      .attr( 'y2', '0%' )
      .attr( 'x1', '0%' )
      .attr( 'x2', '0%' )
      .attr( 'id', chartData.defs[ item ].id );

      for ( let stop of chartData.defs[ item ].stop ) {
        this.svg.select( '#' + chartData.defs[ item ].id )
        .append( 'stop' )
        .attr( 'offset', stop.offset )
        .attr( 'stop-color', stop.stopColor );
      }
    }

    //--[ Bars ] ------------------//
    let cursorType : string     = this.onSelect ? 'pointer' : 'default';
    let barWidthHalved : number = barWidth / 2;

    this.svg.selectAll( 'bar' )
    .data( data )
    .enter()
    .append( 'rect' )
    .attr( 'class', 'bar' )
    .attr( 'y', ( d ) => {
      return yAxisScale( d.count );
    } )
    .attr( 'x', ( d, i ) => {
      const barSpacing = ( xAxisScale.bandwidth() / 2 ) - ( barWidth / 2 );
      return ( i * xAxisScale.bandwidth() ) + barSpacing;
    } )
    .attr( 'rx', barWidthHalved )
    .attr( 'ry', barWidthHalved )
    .attr( 'id', ( d, i ) => {
      return chartData.defs[ d.label ].barId;
    } )
    .attr( 'fill', ( d, i ) => {
      return 'url(#' + chartData.defs[ d.label ].id + ')';
    } )
    .attr( 'height', ( d ) => {
      let countValue = 0;

      for ( let stack of d.stackedData ) {
        countValue += stack.value;
        stack.rangeHeight = yAxisScale( countValue );
      }

      return innerHeight - yAxisScale( d.count );
    } )
    .attr( 'width', ( d ) => {
      return barWidth;
    } )
    .attr( 'opacity', 1 )
    .on( 'click', ( d, i ) => {
      const mousePosition = d3.mouse( d3.event.target );
      const yPosition     = mousePosition && mousePosition[ 1 ];
      let stackedBarData  = '';

      for ( let stack of d.stackedData ) {
        if ( yPosition >= stack.rangeHeight ) {
          stackedBarData = stack;
          break;
        }
      }

      this.onClick( d3.event, d, i, stackedBarData );
    } )
    .on( 'mouseover', ( d, i ) => {
      this.tooltip.style( 'visibility', 'visible' );
    } )
    .on( 'mousemove', ( d, i ) => {
      if ( !d ) {
        return;
      }

      let elmTooltip             = d3.select( '.sym-chart-stacked-vertical-bar__tooltip.is--bar-chart' );
      let stackedBarData         = '';
      let tooltipHeight : number = 80;
      const mousePosition        = d3.mouse( d3.event.target );
      const yPosition            = mousePosition && mousePosition[ 1 ];

      if ( elmTooltip && elmTooltip.node() && ( elmTooltip.node() as HTMLElement ).getBoundingClientRect ) {
        let boundingClientRect = ( elmTooltip.node() as HTMLElement ).getBoundingClientRect();
        tooltipHeight          = boundingClientRect.height / 2;
      }

      if ( this.options.tooltip ) {
        this.tooltipHTML = this.options.tooltip.html ? this.options.tooltip.html : this.TOOLTIP_DEFAULT_HTML;
      }

      for ( let stack of d.stackedData ) {
        if ( yPosition >= stack.rangeHeight ) {
          stackedBarData = stack;
          break;
        }
      }

      const populatedHTML = this.interpolateD3tooltipHTML( this.tooltipHTML, stackedBarData[ 'value' ], stackedBarData[ 'legend' ] );

      this.tooltip
      .style( 'left', ( parseInt( d3.event.target.getAttribute( 'x' ), 10 ) + ( marginLeft + barWidth + this.TOOLTIP_ARROW_WIDTH ) ) + this.tooltipOffsetLeft + 'px' )
      .style( 'top', ( yPosition - tooltipHeight ) + this.tooltipOffsetTop + 'px' )
      .on( 'click', this.onClick )
      .html( populatedHTML );
    } )
    .on( 'mouseout', ( d, i ) => {
      this.tooltip.style( 'visibility', 'hidden' );
    } )
    .style( 'cursor', cursorType );
  };

  private buildChart ( data : any ) : void {
    if ( data ) {
      this.buildSvg( data );
    }
  }

  ngOnChanges ( changes : SimpleChanges ) {
    this.buildChart( changes.data.currentValue );
  }

  ngAfterViewInit () : void {
    this.buildChart( this.data );
    this.buildToolTip();
  }

  ngOnInit () : void {
    this.barChartId = `sym-chart-stacked-vertical-bar-${Utils.generateUUID()}`;
  }

  ngOnDestroy () : void {
    this.tooltip           = null;
    this.elmChartContainer = null;
  }
}

@NgModule( {
  imports      : [ CommonModule, WidgetLoaderModule ],
  exports      : [ SymChartStackedVerticalBar ],
  declarations : [ SymChartStackedVerticalBar ]
} )
export class SymChartStackedVerticalBarModule {
}
