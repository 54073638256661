import {NgModule,Component,Input,Output,EventEmitter,OnInit} from '@angular/core';
import { FormsModule }   from '@angular/forms';
import {CommonModule} from '@angular/common';
import {InputTextModule} from '../input-text/input-text';
import {ITypeaheadItem} from '../common/i-typeahead';
import { SymInputTextHelperModule } from '../input-text-helper/input-text-helper'

@Component({
    selector: 'sym-typeahead-list',
    template: `
      <div class="sym-typeahead-list" [ngClass]="{'is--invalid' : !isValid }"
           id="sym-typeahead-list__{{id}}">
          <sym-input-text-helper [value]="value" (onClear)="clearValue()"
              [errorMsg]="errorMsg" [isValid]="isValid"
              class="sym-typeahead-list__content">
            <input type="text" pInputText placeholder="{{placeholder}}"
                [attr.list]="id" class="is--text-input" 
                [disabled]="disabled"
                autocomplete="on" (input)="onValueChange()" [(ngModel)]="value" />
          </sym-input-text-helper>
          <datalist id="{{id}}">
              <option *ngFor="let item of model" id="{{item.identifier}}" data-value="{{item.displayName}}">
                  {{item.displayName}}
              </option>
          </datalist>

      </div>
    `
})

export class TypeaheadList implements OnInit  {

    @Input() model: Array<ITypeaheadItem>;

    @Input() isValid = true;

    @Input() disabled = false;

    @Input() errorMsg: string;

    @Input() placeholder: string;

    @Input() id: string;

    @Input() value: string;

    @Input() selectedItem: ITypeaheadItem;

    @Output() onChange = new EventEmitter<ITypeaheadItem>();

    @Output() onClear = new EventEmitter();

    @Output() isValidChange = new EventEmitter<boolean>();

    ngOnInit() {
      //TODO: find a away to remove this. This without this, unit test fails.
      setTimeout(() => {
        if (typeof this.isValid === 'undefined') {
          this.isValid = true;
          this.isValidChange.emit(this.isValid);
        }
      }, 10);
    }

    onValueChange() {
      if (this.model && Array.isArray(this.model) && this.model.length > 0 ) {
        let filteredItems = this.model.filter(
          item => item.displayName === this.value
        );
        this.selectedItem = filteredItems.length ? filteredItems[0] : null;
      }
      else {
        this.selectedItem = null;
      }

      if (!this.selectedItem) {
        this.selectedItem = {
          displayName: this.value
        }
      }
      this.onChange.emit(this.selectedItem);
    }

    clearItems () {
      this.model = [];
    }

    clearValue() {
      this.isValid = true;
      this.isValidChange.emit(this.isValid);
      this.selectedItem = null;
      if (this.value !== '') {
        this.value = "";
        this.onClear.emit();
      }
    }
}

@NgModule({
    imports: [CommonModule, FormsModule, InputTextModule, SymInputTextHelperModule],
    exports: [TypeaheadList, CommonModule, FormsModule, InputTextModule, SymInputTextHelperModule],
    declarations: [TypeaheadList]
})
export class TypeaheadListModule { }
