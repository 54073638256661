// Angular
import { NgModule, Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { CommonModule }                                                                               from '@angular/common';
import { DomSanitizer }                                                                               from '@angular/platform-browser';
import { ControlValueAccessor, NG_VALUE_ACCESSOR }                                                    from '@angular/forms';

// Project
import { ButtonModule }    from '../button/button';
import { DomHandler }      from '../dom/dom-handler';
import { Message }         from '../common/message';
import { SharedModule }    from '../common/shared';
import { BlockableUI }     from '../common/blockable-ui';
import { InputTextModule } from '../input-text/input-text';

@Component( {
  selector  : 'sym-fileUpload',
  template  : `
    <div class="sym-fileUpload ui-fileupload-choose">
      <input pInputText type="text" size="30" [disabled]="disabled" [placeholder]="fileName" [ngClass]="{ 'ng-dirty ng-invalid' : ( msgs && msgs.length > 0 ) }" id="{{msgs && msgs.length > 0}}">
      <sym-button icon="icon__file-upload" styleClass="ui-button-secondary" [disabled]="disabled"></sym-button>
      <input #basicfileinput
             type="file"
             [name]="name"
             [accept]="accept"
             [disabled]="disabled"
             (change)="onFileSelect($event)"
             [attr.title]="titleText">
    </div>
  `,
  providers : [ DomHandler, {
    provide     : NG_VALUE_ACCESSOR,
    useExisting : SymFileUpload,
    multi       : true
  } ]
} )
export class SymFileUpload implements OnInit, OnDestroy, BlockableUI, ControlValueAccessor {

  @Input() accept : string;

  @Input() disabled : boolean;

  @Input() files : File[];

  @Input() maxFileSize : number;

  @Input() multiple : boolean;

  @Input() name : string;

  @Input() invalidFileSizeMessageDetail : string = 'maximum upload size is {0}.';

  @Input() invalidFileSizeMessageSummary : string = '{0}: Invalid file size, ';

  @Input() invalidFileTypeMessageDetail : string = 'allowed file types: {0}.';

  @Input() invalidFileTypeMessageSummary : string = '{0}: Invalid file type, ';

  @Input() titleNoFileMessage : string = 'No file chosen';

  @Input() titleHasFileMessage : string = '';

  @Input() style : any;

  @Input() styleClass : string;

  @Output() onSelect : EventEmitter<any> = new EventEmitter();

  @ViewChild( 'advancedfileinput', { static : false } ) advancedFileInput : ElementRef;

  @ViewChild( 'basicfileinput', { static : false } ) basicFileInput : ElementRef;

  @ViewChild( 'content', { static : false } ) content : ElementRef;

  public dragHighlight : boolean;

  public msgs : Message[];

  duplicateIEEvent : boolean;  // flag to recognize duplicate onchange event for file input

  fileName : string = '';

  titleText : string;

  onChange : Function;

  constructor (
    private el : ElementRef,
    public domHandler : DomHandler,
    public sanitizer : DomSanitizer
  ) {
  }

  onFileSelect ( event ) {
    this.titleText = this.getTitleText();
    this.fileName  = '';

    if ( event.type !== 'drop' && this.isIE11() && this.duplicateIEEvent ) {
      this.duplicateIEEvent = false;
      return;
    }

    this.msgs = [];

    if ( !this.multiple ) {
      this.files = [];
    }

    let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    for ( let i = 0; i < files.length; i++ ) {
      let file = files[ i ];

      if ( !this.isFileSelected( file ) ) {
        if ( this.validate( file ) ) {
          if ( this.isImage( file ) ) {
            file.objectURL = this.sanitizer.bypassSecurityTrustUrl( ( window.URL.createObjectURL( files[ i ] ) ) );
          }
          this.files.push( files[ i ] );
        }
      }
    }

    if ( this.files[ 0 ] ) {
      this.titleText = this.titleHasFileMessage;
      this.fileName  = this.files[ 0 ].name;
    }

    this.onSelect.emit( { originalEvent : event, files : files, errors : this.msgs } );
    if ( this.onChange ) this.onChange( Array.from( files ) );   // Reactive form doesn't like FileList, make sure to pass a proper array

    if ( event.type !== 'drop' && this.isIE11() ) {
      this.clearIEInput();
    } else {
      this.clearInputElement();
    }
  }

  isFileSelected ( file : File ) : boolean {
    for ( let sFile of this.files ) {
      if ( ( sFile.name + sFile.type + sFile.size ) === ( file.name + file.type + file.size ) ) {
        return true;
      }
    }

    return false;
  }

  isIE11 () {
    return !!window[ 'MSInputMethodContext' ] && !!document[ 'documentMode' ];
  }

  validate ( file : File ) : boolean {
    if ( this.accept && !this.isFileTypeValid( file ) ) {
      this.msgs.push( {
        severity : 'error',
        summary  : this.invalidFileTypeMessageSummary.replace( '{0}', file.name ),
        detail   : this.invalidFileTypeMessageDetail.replace( '{0}', this.accept )
      } );
      return false;
    }

    if ( this.maxFileSize && file.size > this.maxFileSize ) {
      this.msgs.push( {
        severity : 'error',
        summary  : this.invalidFileSizeMessageSummary.replace( '{0}', file.name ),
        detail   : this.invalidFileSizeMessageDetail.replace( '{0}', this.formatSize( this.maxFileSize ) )
      } );
      return false;
    }

    return true;
  }

  private isFileTypeValid ( file : File ) : boolean {
    let acceptableTypes = this.accept.split( ',' );
    for ( let type of acceptableTypes ) {
      let acceptable = this.isWildcard( type ) ? this.getTypeClass( file.type ) === this.getTypeClass( type )
        : file.type == type || this.getFileExtension( file ).toLowerCase() === type.toLowerCase();

      if ( acceptable ) {
        return true;
      }
    }

    return false;
  }

  getTypeClass ( fileType : string ) : string {
    return fileType.substring( 0, fileType.indexOf( '/' ) );
  }

  isWildcard ( fileType : string ) : boolean {
    return fileType.indexOf( '*' ) !== -1;
  }

  getFileExtension ( file : File ) : string {
    return '.' + file.name.split( '.' ).pop();
  }

  isImage ( file : File ) : boolean {
    return /^image\//.test( file.type );
  }

  onImageLoad ( img : any ) {
    window.URL.revokeObjectURL( img.src );
  }

  clearInputElement () {
    if ( this.advancedFileInput && this.advancedFileInput.nativeElement ) {
      this.advancedFileInput.nativeElement.value = '';
    }

    if ( this.basicFileInput && this.basicFileInput.nativeElement ) {
      this.basicFileInput.nativeElement.value = '';
    }
  }

  clearIEInput () {
    if ( this.advancedFileInput && this.advancedFileInput.nativeElement ) {
      this.duplicateIEEvent                      = true; //IE11 fix to prevent onFileChange trigger again
      this.advancedFileInput.nativeElement.value = '';
    }
  }

  hasFiles () : boolean {
    return this.files && this.files.length > 0;
  }

  onDragEnter ( e ) {
    if ( !this.disabled ) {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  onDragOver ( e ) {
    if ( !this.disabled ) {
      this.domHandler.addClass( this.content.nativeElement, 'ui-fileupload-highlight' );
      this.dragHighlight = true;
      e.stopPropagation();
      e.preventDefault();
    }
  }

  onDragLeave ( event ) {
    if ( !this.disabled ) {
      this.domHandler.removeClass( this.content.nativeElement, 'ui-fileupload-highlight' );
    }
  }

  onDrop ( event ) {
    if ( !this.disabled ) {
      this.domHandler.removeClass( this.content.nativeElement, 'ui-fileupload-highlight' );
      event.stopPropagation();
      event.preventDefault();

      let files     = event.dataTransfer ? event.dataTransfer.files : event.target.files;
      let allowDrop = this.multiple || ( files && files.length === 1 );

      if ( allowDrop ) {
        this.onFileSelect( event );
      }
    }
  }

  formatSize ( bytes ) {
    if ( bytes == 0 ) {
      return '0 B';
    }
    let k     = 1000,
        dm    = 3,
        sizes = [ 'B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB' ],   // TODO: L10N
        i     = Math.floor( Math.log( bytes ) / Math.log( k ) );

    return parseFloat( ( bytes / Math.pow( k, i ) ).toFixed( dm ) ) + ' ' + sizes[ i ];
  }

  getBlockableElement () : HTMLElement {
    return this.el.nativeElement.children[ 0 ];
  }

  writeValue ( value : null ) {
    // Only called to clear file input (basically, doing "reset"-type stuff from onFileSelect here... doing `this.onFileSelect({})` won't work)
    this.msgs     = [];
    this.files    = [];
    this.fileName = '';
    this.isIE11() ? this.clearIEInput() : this.clearInputElement();
  }

  registerOnChange ( fn : Function ) {
    this.onChange = fn;
  }

  registerOnTouched ( fn : Function ) {
  }

  getTitleText () : string {
    return this.disabled ? '' : this.titleNoFileMessage;
  }

  ngOnInit () {
    this.titleText = this.getTitleText();
    this.files     = [];
  }

  ngOnDestroy () {
    if ( this.content && this.content.nativeElement ) {
      this.content.nativeElement.removeEventListener( 'dragover', this.onDragOver );
    }
  }
}

@NgModule( {
  imports      : [ CommonModule, SharedModule, ButtonModule, InputTextModule ],
  exports      : [ SymFileUpload, SharedModule, ButtonModule, InputTextModule ],
  declarations : [ SymFileUpload ]
} )
export class SymFileUploadModule {
}
