import { NgModule, Component, Input, OnInit, OnDestroy, EventEmitter, Output, Inject, forwardRef, ViewChild, ElementRef } from '@angular/core';
import { CommonModule }                                                                                                                                from '@angular/common';
import { SymSubscriptionService } from '../../services/subscription.service';
import { SymRuleBuilderTreeService }                                                                                                                   from './sym-rule-builder-tree.service';
import { SymRuleBuilderItemType, SymRuleBuilderConfig, SymRuleBuilderItem, SymRuleBuilderItems, SymRuleBuilderDropdownType }                                                                            from './sym-rule-builder.interface';
import { MenuModule, Menu }   from '../menu/menu';
import { Utils }        from '../common/utils';
import { Subscription } from 'rxjs';
import { ToastModule }  from '../toast/toast';
import { MessageService } from '../common/message-service';
import { ProgressSpinnerModule } from '../progress-spinner/progress-spinner';
import { MenuItem } from '../common/menu-item';
//TODO: Clean up customClass in sym-rule-builder HTML

@Component( {
  selector    : 'sym-rule-builder',
  templateUrl : './sym-rule-builder.html',
  providers   : [SymRuleBuilderTreeService]

} )

export class SymRuleBuilder implements OnInit, OnDestroy {

  @Input() style : any;

  @Input() styleClass : string;

  @Input() l10n = {
    deleteButton   : 'Delete',
    addButton      : 'Add',
    title          : 'Custom Requirement Script',
    itemTextTop    : 'Insert Statement below',
    itemTextBottom : 'Pass',
    placeholder    : 'Click Add button to add a condition',
    deleteToastTitle: 'Info',
    deleteToastDetail: 'Delete Successful.',
    deleteToastUndo: 'Undo Delete'
  };

  @Input() currentItemId : string; //Initial Current Item ID

  @Input() currentItem : any = null; //user defined

  @Input() id = Utils.generateUUID();

  currentRuleBuilderItem : SymRuleBuilderItem = null;

  disableExitCurrentItemClass = 'sym-rule-builder__disable-exit-current-item';

  @Input() operatorsMenuItems: MenuItem[];
  // example:
  // [
  //   {
  //     label   : 'AND',
  //     command : ( evt ) => {
  //       console.warn( 'AND selected' );
  //     }
  //   },
  //   {
  //     label   : 'OR',
  //     command : ( evt ) => {
  //       console.warn( 'OR selected' );
  //     }
  //   }
  // ];

  operatorsMenuOffset = { top : 10, right : -13 };


  itemsMenuOffset = { top : 10, right : -13 };

  messageServiceKey = 'sym-rule-builder-toast';

  @Input() itemsMenuItems: MenuItem[];
  // example:
 // [
 //    {
 //      label   : 'If...Then...',
 //      command : ( evt ) => {
 //        console.warn( 'If...Then... selected' );
 //      }
 //    },
 //    {
 //      label   : 'Function',
 //      command : ( evt ) => {
 //        console.warn( 'Function selected' );
 //      }
 //    },
 //    {
 //      label   : 'Return',
 //      command : ( evt ) => {
 //        console.warn( 'Return selected' );
 //      }
 //    },
 //    {
 //      label   : 'Comment',
 //      command : ( evt ) => {
 //        console.warn( 'Comment selected' );
 //      }
 //    }
 //  ];

  treeItems : any;
  treeCopy : any; //keeping copy of the tree to revert back
  currentItemIdCopy : any; //keeping copy of the selected id to revert back (undo delete)
  isInit = false;
  _config : SymRuleBuilderConfig;
  _items = null;
  rootDropdownType: string;
  configReady = false;

  @Output() onAdd    = new EventEmitter<any>(); //sending current Item - user defined
  @Output() onActive = new EventEmitter<any>(); //return user defined item
  @Output() onDelete = new EventEmitter<any>(); //return user defined item
  @Output() onUndoDelete = new EventEmitter<any>(); //return user defined item

  @Output() currentItemChange = new EventEmitter<any>();

  @Input() get config () : SymRuleBuilderConfig {
    return this._config;
  }

  set config ( val : SymRuleBuilderConfig ) {
    this._config = val;
    if ( val ) {
      if ( val.itemModel ) {
        this.ruleBuilderTreeService.setItemMap( val.itemModel );
      }

      if ( val.rootDropdownType ) {
        this.ruleBuilderTreeService.setRootDropdownType( val.rootDropdownType );
      }

      if ( this._items && !this.isInit ) {
        this.updateModel( this._items, this.currentItemId );
      }

      this.configReady = true;
    }
  }

  @Input() get items () : any {
    return this._items;
  }

  set items ( val : any ) { // Initial tree items
    this._items = val;
    if (val && this.configReady && !this.isInit) {
      this.updateModel( val, this.currentItemId );
    }
  }

  @ViewChild('itemsMenu', { static: false }) itemsMenu: Menu;

  @ViewChild('operatorsMenu', { static: false }) operatorsMenu: Menu;

  @Output() itemsChange = new EventEmitter<any>();

  ITEM_TYPE = SymRuleBuilderItemType;

  emptyRoot = {
    root : {
      id           : 'root',
      items        : {},
      operator     : null,
      parentIds    : [],
      parentItem   : null,
      dropdownType : this.config ? this.config.rootDropdownType : SymRuleBuilderDropdownType.ITEMS
    }
  };

  totalTreeDepth             = 0; // Keep track of how many tree levels (not really useful now)
  isLoading                  = true;
  noData                     = true;

  private addItemEvent : Subscription;
  private updateItemEvent : Subscription;

  public trackItem ( index : number, item : SymRuleBuilderItem ) {
    return item.id;
  }

  constructor ( private subscriptionService : SymSubscriptionService, private ruleBuilderTreeService : SymRuleBuilderTreeService, private messageService: MessageService ) {
  }

  ngOnInit () : void {
    this.messageServiceKey = this.id ? this.messageServiceKey + this.id : this.messageServiceKey;
    // Subscribing the event.
    this.addItemEvent = this.subscriptionService.subscribeEvent<any>( 'add-item-' + this.id, ( item ) => {
      this.addItem( item );
    } );

    this.updateItemEvent = this.subscriptionService.subscribeEvent<any>( 'update-item-' + this.id, ( item ) => {
      this.updateItem( item );
    } );
    // NOTE: Not sure if this is necessary, so leaving this out for now.
    // (this unset current item when clicked outside of item element without class defined in disableExitCurrentItemClass)
    // document.body.addEventListener( 'click', this.unsetCurrentItem.bind( this ), false );
  }

  ngOnDestroy () : void {
    this.messageService.clear(this.messageServiceKey);

    // Unsubscribe the event once not needed.
    if (this.addItemEvent) {
      this.addItemEvent.unsubscribe();
    }

    if (this.updateItemEvent) {
      this.updateItemEvent.unsubscribe();
    }

    // NOTE: removed addEventListener for now, so removing this. (see above)
    // document.body.removeEventListener( 'click', this.unsetCurrentItem );
  }

  public addItem ( itemObj ) {
      this.messageService.clear(this.messageServiceKey);
      this.ruleBuilderTreeService.addItem( itemObj.items, itemObj.targetItemId, itemObj.operator, itemObj.replace );

      setTimeout( () => {
        this.treeItems = Utils.deepCopy( this.ruleBuilderTreeService.treeItems );
        if (itemObj.currentItemId) {
          let activeItem = this.ruleBuilderTreeService.getItem( itemObj.currentItemId, this.treeItems );
          this.setCurrentItem( activeItem, true );
        }

        this.updateItemsArray();

      }, 100 );
  }

  //Always run this after this.treeItems is updated
  updateItemsArray () {
    this.items = this.ruleBuilderTreeService.getItemsAsArray(this.treeItems);
    this.itemsChange.emit(this.items);
  }

  public updateItem ( itemObj ) {
    this.messageService.clear(this.messageServiceKey);
    this.ruleBuilderTreeService.updateItem( itemObj.item.id, itemObj.item, true );

    setTimeout( () => {
      this.treeItems = Utils.deepCopy( this.ruleBuilderTreeService.treeItems );
      if (this.currentItem && this.currentItemId === itemObj.item.id) {
        this.currentRuleBuilderItem = this.ruleBuilderTreeService.getItem(itemObj.item.id, this.treeItems);
        this.currentItemId = itemObj.item.id;
        this.currentItem = this.currentRuleBuilderItem.originalModel;
        this.currentItemChange.emit( this.currentItem );
      }
      this.updateItemsArray();

    }, 100 );
  }

  /**
   * Get previous tree structure back
   *
   */
  undoDelete () {
    this.messageService.clear(this.messageServiceKey);
    setTimeout(() => {
      this.currentItemId = this.currentItemIdCopy;
      this.treeItems = this.treeCopy;
      this.treeCopy = {};
      this.ruleBuilderTreeService.treeItems = this.treeItems;

      if (this.currentItemId) {
        this.currentRuleBuilderItem = this.ruleBuilderTreeService.getItem(this.currentItemId, this.treeItems);
        this.currentItem = this.currentRuleBuilderItem.originalModel;
        this.currentItemChange.emit( this.currentItem );
      }
      this.updateItemsArray();
      this.currentItemIdCopy = null;
      this.onUndoDelete.emit(this.currentItem.originalModel);
    }, 100);
  };

  itemCount ( items: SymRuleBuilderItems ) {
    if ( !items ) {
      return 0;
    }
    return Object.keys( items ).length;
  }

  isTreeEmpty () {
    if ( !this.treeItems || !this.treeItems.root || Object.keys( this.treeItems.root.items ).length === 0 ) {
      return true;
    }
    return false;
  };

  updateModel ( rawItems: any, currentItemId?: string ) {
    this.messageService.clear(this.messageServiceKey);
    this.ruleBuilderTreeService.updateModel(rawItems);
    let currentItem = null;
    // let newItems: SymRuleBuilderItems;

    // if ( rawItems && rawItems.length > 0 ) {
    //   newItems = this.ruleBuilderTreeService.mapItem( Utils.deepCopy( rawItems ) );
    //   this.ruleBuilderTreeService.addParentItem( newItems, null );
    // } else {
    //   newItems = {};
    // }
    //
    // if ( newItems.root ) {
    //   this.treeItems = newItems;
    // } else {
    //   if ( !this.treeItems || !this.treeItems.root ) {
    //     this.treeItems = this.emptyRoot;
    //   }
    //   this.treeItems.root.items = newItems;
    // }

    this.treeItems = this.ruleBuilderTreeService.treeItems;

    if (currentItemId) {
      currentItem = currentItemId === 'root' ? 'root' : this.ruleBuilderTreeService.getItem(currentItemId, this.treeItems);
    }
    else {
      currentItem = null;
    }
    this.setCurrentItem(currentItem, true);
    this.isInit = true;
    this.isLoading = false;
  }

  sortBy ( tree: { value: SymRuleBuilderItem } [] ) {
    return tree.sort( ( a, b ) => a.value.order > b.value.order ? 1 : a.value.order === b.value.order ? 0 : -1 );
  }

  setCurrentItem ( item: SymRuleBuilderItem | string, silent?:boolean ) {
    let activeObj, parentId;
    if (this.itemsMenu) {
      this.itemsMenu.hide();
    }
    if (this.operatorsMenu) {
      this.operatorsMenu.hide();
    }
    // Can't move to a new item until the current item is valid
    if ( this.currentRuleBuilderItem && item && typeof item === 'object' && this.currentRuleBuilderItem.id !== item.id && this.currentRuleBuilderItem.isInvalid ) {
      return;
    }

    setTimeout( () => {
      if ( !item ) {
        this.currentItemId = null;
        this.currentRuleBuilderItem  = null;
      } else if ( item === 'root' ) {
        this.currentItemId = 'root';
        this.currentRuleBuilderItem  = null;
      } else {
        this.currentItemId = typeof item === 'object' ? item.id : null;
        this.currentRuleBuilderItem  = typeof item === 'object' ? item : null;
      }

      this.currentItem = this.currentRuleBuilderItem ? this.currentRuleBuilderItem.originalModel : null;
      this.currentItemChange.emit( this.currentItem );

      if ( !silent ) {
        if ( !item || item === 'root' ) {
          activeObj = {
            id       : 'root',
            item     : null,
            parentId : null
          };
          this.onActive.emit( activeObj );
        } else if (this.currentItemId && typeof item === 'object' ) {
          parentId  = item.parentItem ? item.parentItem.id : null;
          activeObj = {
            id       : item.id,
            item     : item.originalModel,
            parentId : parentId
          };
          this.onActive.emit( activeObj );
        }
      }

    }, 100 );
  }

  //TODO: Remove? Not used anywhere for now.
  unsetCurrentItem ( ev?: Event ) {
    if ( this.currentRuleBuilderItem && this.currentRuleBuilderItem.isInvalid ) {
      return;
    }
    if ( ev && ev.target ) {
      let noClickClass = Utils.getClosestByClass( ev.target, this.disableExitCurrentItemClass );
      if ( noClickClass ) {
        return;
      }
    }
    if ( this.currentItemId && this.currentItemId !== 'root' ) {
      setTimeout( () => {
        this.setCurrentItem( null, true );
      }, 100 );
    }
  };


  removeItem ( item: SymRuleBuilderItem ) {
    if (!item || item.disableDelete || item.id === 'root') {
      return;
    }
    this.messageService.clear(this.messageServiceKey);

    this.treeCopy          = Utils.deepCopy( this.treeItems );
    this.currentItemIdCopy = this.currentItemId;
    this.ruleBuilderTreeService.removeItem( item );

    setTimeout( () => {
      this.treeItems = this.ruleBuilderTreeService.treeItems;
      this.setCurrentItem( null, null );
      this.updateItemsArray();

      this.messageService.add({
               key: this.messageServiceKey,
               sticky: true,
               severity: 'info',
               summary: this.l10n.deleteToastTitle,
               detail: this.l10n.deleteToastDetail,
               data: this.l10n.deleteToastUndo
           });
      this.onDelete.emit(item.originalModel);
    }, 100 );
  };

  deleteCurrentItem () {
    if ( this.currentRuleBuilderItem && !this.currentRuleBuilderItem.disableDelete && this.currentItemId !== 'root') {
      setTimeout( () => {
        this.removeItem( this.currentRuleBuilderItem );
      }, 100 );
    }
  };

  toggleMenu ( event: Event ) {
    if ( this.currentRuleBuilderItem && ( this.currentRuleBuilderItem.isInvalid || this.currentRuleBuilderItem.disableAdd ) ) {
      return;
    }
    if ( this.isTreeEmpty()) {
      if ( this.config.rootDropdownType === SymRuleBuilderDropdownType.OPERATORS) {
        this.onAdd.emit( this.currentItem  );
      }
      else if (this.config.rootDropdownType === SymRuleBuilderDropdownType.ITEMS ){
        this.itemsMenu.toggle(event);
      }
    } else {
      let hasChildren = this.currentRuleBuilderItem && this.currentRuleBuilderItem.items ? Object.keys(this.currentRuleBuilderItem.items).length > 0 : false;

      if (!this.currentRuleBuilderItem) {
        //then add to root
        if ( this.config.rootDropdownType === SymRuleBuilderDropdownType.OPERATORS) {
          this.operatorsMenu.toggle(event);
        }
        else if (this.config.rootDropdownType === SymRuleBuilderDropdownType.ITEMS ){
          this.itemsMenu.toggle(event);
        }
      }
      else if (this.currentRuleBuilderItem.dropdownType === SymRuleBuilderDropdownType.OPERATORS) {
        if (this.currentRuleBuilderItem.type === SymRuleBuilderItemType.SCOPE ||
          this.currentRuleBuilderItem.type === SymRuleBuilderItemType.FOLDER ) {
            if ( hasChildren ) {
              this.operatorsMenu.toggle(event);
            }
            else {
              this.onAdd.emit( this.currentItem  );
            }
          }
          else {
            this.operatorsMenu.toggle(event);
          }
      }
      else if ( this.currentRuleBuilderItem.dropdownType === SymRuleBuilderDropdownType.ITEMS) {

        this.itemsMenu.toggle(event);
      }
    }
  }
}

@Component( {
  selector    : 'sym-rule-builder-child',
  templateUrl : './sym-rule-builder-child.html'
} )

export class SymRuleBuilderChild implements OnInit {
  @Input() item : any;
  @Input() currentItem : any;
  @Input() id : string;
  @Output() onSetCurrentItem = new EventEmitter<any>();
  @Input() parentOp: string;

  // currentItemIdChange is for 2 way binding for SymRuleBuilderChild
  @Output() currentItemIdChange = new EventEmitter<string>();

  initialId : any;
  ITEM_TYPE = SymRuleBuilderItemType;

  private _currentItemId : string;

  @Input() get currentItemId () : string {
    return this._currentItemId;
  }

  set currentItemId ( val : string ) {
    this._currentItemId = val;
  }

  constructor ( @Inject( forwardRef( () => SymRuleBuilder ) ) public symRuleBuilder : SymRuleBuilder ) { //using forwardRef as SymRuleBuilder is not ready when SymRuleBuilderChild is initializing.
  }

  ngOnInit () {
    this.initialId = this.item.id;
  }

  itemCount ( items ) {
    if ( !items ) {
      return 0;
    }
    return Object.keys( items ).length;
  }

  public trackItem ( index : number, item : any ) {
    return item.id;
  }

  setCurrentItem ( event: Event, item: SymRuleBuilderItem) {
    // Can't move to a new item until the current item is valid
    let id = typeof item === 'object' && item.id ? item.id : item;
    if ( this.currentItem && (this.currentItem.isInvalid || this.currentItem.id === id))  {
      return;
    }
    event.stopPropagation();
    this.currentItemId = typeof item && item.id ? item.id : null;
    this.currentItemIdChange.emit( this.currentItemId );
    this.symRuleBuilder.setCurrentItem( item );
  }

  sortBy ( tree ) {
    return tree.sort( ( a, b ) => a.value.order > b.value.order ? 1 : a.value.order === b.value.order ? 0 : -1 );
  }
}

@NgModule( {
  imports      : [CommonModule, MenuModule, ToastModule, ProgressSpinnerModule],
  exports      : [SymRuleBuilder, SymRuleBuilderChild, MenuModule, ToastModule, ProgressSpinnerModule],
  declarations : [SymRuleBuilder, SymRuleBuilderChild],
  providers    : [SymSubscriptionService]
} )
export class SymRuleBuilderModule {
}
