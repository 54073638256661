import {
  NgModule,
  Component,
  ElementRef,
  OnDestroy,
  Input,
  Renderer2,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { trigger, state, style, transition, animate, AnimationEvent } from '@angular/animations';
import { RouterModule }                                               from '@angular/router';
import { CommonModule }                                               from '@angular/common';
import { DomHandler }                                                 from '../dom/dom-handler';
import { MenuItem }                                                   from '../common/menu-item';
import { DomSanitizer }                                               from '@angular/platform-browser';

@Component( {
  selector   : 'p-menu',
  template   : `
    <div class="ui-menu-arrow"></div>
    <div #container [ngClass]="{'ui-menu ui-widget ui-widget-content': true, 'ui-menu-dynamic ui-shadow': popup}"
         [class]="styleClass" [ngStyle]="style" (click)="preventDocumentDefault=true" *ngIf="!popup || visible"
         [@overlayAnimation]="{value: 'visible', params: {showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions}}" [@.disabled]="popup !== true" (@overlayAnimation.start)="onOverlayAnimationStart($event)">
      <ul>
        <ng-template ngFor let-submenu [ngForOf]="model" *ngIf="hasSubMenu()">
          <li class="ui-menu-separator ui-widget-content" *ngIf="submenu.separator" [ngClass]="{'ui-helper-hidden': submenu.visible === false}"></li>
          <li class="ui-submenu-header ui-widget-header" [attr.data-automationid]="submenu.automationId" *ngIf="!submenu.separator" [ngClass]="{'ui-helper-hidden': submenu.visible === false}">
            {{submenu.label}}
          </li>
          <ng-template ngFor let-item [ngForOf]="submenu.items">
            <li class="ui-menu-separator ui-widget-content" *ngIf="item.separator" [ngClass]="{'ui-helper-hidden': (item.visible === false || submenu.visible === false)}"></li>
            <li class="ui-menuitem ui-widget" *ngIf="!item.separator" [pMenuItemContent]="item" [ngClass]="{'ui-helper-hidden': (item.visible === false || submenu.visible === false)}" [ngStyle]="item.style" [class]="item.styleClass"></li>
          </ng-template>
        </ng-template>
        <ng-template ngFor let-item [ngForOf]="model" *ngIf="!hasSubMenu()">
          <li class="ui-menu-separator ui-widget-content" *ngIf="item.separator" [ngClass]="{'ui-helper-hidden': item.visible === false}"></li>
          <li class="ui-menuitem ui-widget" *ngIf="!item.separator" [pMenuItemContent]="item" [ngClass]="{'ui-helper-hidden': item.visible === false}" [ngStyle]="item.style" [class]="item.styleClass"></li>
        </ng-template>
      </ul>
    </div>
  `,
  animations : [
    trigger( 'overlayAnimation', [
      state( 'void', style( {
        transform : 'translateY(20px)', //change to 30px to add animation
        opacity   : 0
      } ) ),
      state( 'visible', style( {
        transform : 'translateY(20px)',
        opacity   : 1
      } ) ),
      transition( 'void => visible', animate( '{{showTransitionParams}}' ) ),
      transition( 'visible => void', animate( '{{hideTransitionParams}}' ) )
    ] )
  ],
  providers  : [DomHandler]
} )
export class Menu implements OnDestroy {

  private _model : MenuItem[];

  @Input() get model () : MenuItem[] {
    return this._model;
  }

  set model ( val : MenuItem[] ) {
    if ( val ) {
      this._model = val.map( ( v : MenuItem ) => {
        if ( v.content && typeof v.content === 'string' ) {
          v.content = this.sanitizer.bypassSecurityTrustHtml( v.content );
        }
        return v;
      } );
    } else {
      this.model = [];
    }

  }

  @Input() offset : { top? : number, left? : number, right? : number } = { top : 0, left : 0 };

  @Input() popup : boolean;

  @Input() relative : boolean;

  @Input() style : any;

  @Input() styleClass : string;

  @Input() appendTo : any;

  @Input() autoZIndex : boolean = true;

  @Input() baseZIndex : number = 0;

  @Input() showTransitionOptions : string = '225ms ease-out';

  @Input() hideTransitionOptions : string = '195ms ease-in';

  @ViewChild( 'container', { static : false } ) containerViewChild : ElementRef;

  @Output() menuToggle = new EventEmitter<boolean>();

  container : HTMLDivElement;

  documentClickListener : any;

  documentResizeListener : any;

  preventDocumentDefault : boolean;

  target : any;

  visible : boolean;

  constructor (
    public el : ElementRef,
    public domHandler : DomHandler,
    public renderer : Renderer2,
    private sanitizer : DomSanitizer
  ) {
  }

  toggle ( event, outsideClick? ) {
    if ( this.visible )
      this.hide();
    else
      this.show( event, null, outsideClick );
    if (!outsideClick) {
      this.preventDocumentDefault = true;
    }
  }

  show ( event, currentTarget?, outsideClick? ) {
    this.target                 = currentTarget || event.currentTarget;
    this.visible                = true;
    this.menuToggle.emit( true );
    if (!outsideClick) {
      this.preventDocumentDefault = true;
    }
  }

  onOverlayAnimationStart ( event : AnimationEvent ) {
    switch ( event.toState ) {
      case 'visible':
        if ( this.popup ) {
          this.container = event.element;
          this.moveOnTop();
          this.appendOverlay();
          if (!this.relative && (!this.appendTo || this.appendTo === 'body')) {
            this.domHandler.absolutePosition( this.container, this.target, this.offset, true );
          }
          else {
            this.domHandler.relativePosition( this.container, this.target, this.offset, this.appendTo, 'MENU' );
          }
          this.bindDocumentClickListener();
          this.bindDocumentResizeListener();
        }
        break;

      case 'void':
        this.onOverlayHide();
        break;
    }
  }

  appendOverlay () {
    if ( this.appendTo ) {
      if ( this.appendTo === 'body' )
        document.body.appendChild( this.container );
      else
        this.domHandler.appendChild( this.container, this.appendTo );
    }
  }

  restoreOverlayAppend () {
    if ( this.container && this.appendTo ) {
      this.el.nativeElement.appendChild( this.container );
    }
  }

  moveOnTop () {
    if ( this.autoZIndex ) {
      this.container.style.zIndex = String( this.baseZIndex + ( ++DomHandler.zindex ) );
    }
  }

  hide () {
    this.visible = false;
    this.menuToggle.emit( false );
  }

  onWindowResize () {
    this.hide();
  }

  itemClick ( event, item : MenuItem ) {
    if ( item.disabled ) {
      event.preventDefault();
      return;
    }

    if ( !item.url ) {
      event.preventDefault();
    }

    if ( item.command ) {
      item.command( {
        originalEvent : event,
        item          : item
      } );
    }

    if ( this.popup ) {
      this.hide();
    }
  }

  contentClick ( event, item : MenuItem ) {
    if ( item.contentClick ) {
      event.stopPropagation();
      event.preventDefault();
      item.contentClick( {
        originalEvent : event,
        item          : item
      } );
    }
  }

  bindDocumentClickListener () {
    if ( !this.documentClickListener ) {
      this.documentClickListener = this.renderer.listen( 'document', 'click', () => {
        if ( !this.preventDocumentDefault ) {
          this.hide();
        }

        this.preventDocumentDefault = false;
      } );
    }
  }

  unbindDocumentClickListener () {
    if ( this.documentClickListener ) {
      this.documentClickListener();
      this.documentClickListener = null;
    }
  }

  bindDocumentResizeListener () {
    this.documentResizeListener = this.onWindowResize.bind( this );
    window.addEventListener( 'resize', this.documentResizeListener );
  }

  unbindDocumentResizeListener () {
    if ( this.documentResizeListener ) {
      window.removeEventListener( 'resize', this.documentResizeListener );
      this.documentResizeListener = null;
    }
  }

  onOverlayHide () {
    this.unbindDocumentClickListener();
    this.unbindDocumentResizeListener();
    this.preventDocumentDefault = false;
    this.target                 = null;
  }

  ngOnDestroy () {
    if ( this.popup ) {
      this.restoreOverlayAppend();
      this.onOverlayHide();
    }
  }

  hasSubMenu () : boolean {
    if ( this.model ) {
      for ( let item of this.model ) {
        if ( item.items ) {
          return true;
        }
      }
    }
    return false;
  }
}

@Component( {
  selector : '[pMenuItemContent]',
  template : `
    <a *ngIf="!item.routerLink" [href]="item.url||'#'" class="ui-menuitem-link ui-corner-all" [attr.data-automationid]="item.automationId" [attr.target]="item.target" [attr.title]="item.title" [attr.id]="item.id"
       [ngClass]="{'is--selected': item.isSelected, 'ui-state-disabled':item.disabled, 'has--icon':item.icon || item.iconRight}" (click)="menu.itemClick($event, item)">
      <div *ngIf="item.labelTitle" class="ui-menuitem-title">{{item.labelTitle}}</div>
      <span *ngIf="!item.iconSVG && item.icon" class="ui-menuitem-icon" [ngClass]="item.icon"></span>
      <span *ngIf="item.iconSVG && item.icon" class="ui-menuitem-icon is--svg">
        <svg [ngClass]="item.icon">
          <use [attr.xlink:href]="item.iconSVG"></use>
        </svg>
      </span>
      <span class="ui-menuitem-text">{{item.label}}</span>
      <span *ngIf="!item.iconSVG && item.iconRight" class="ui-menuitem-icon is--right" [ngClass]="item.iconRight"></span>
      <span *ngIf="item.iconSVG &&  item.iconRight" class="ui-menuitem-icon is--right is--svg" [ngClass]="item.iconRight">
        <svg [ngClass]="item.iconRight">
          <use [attr.xlink:href]="item.iconSVG"></use>
        </svg>
      </span>
      <span class="ui-menuitem-content" (click)="menu.contentClick($event, item)" *ngIf="item.content" [innerHTML]="item.content"></span>
    </a>
    <a *ngIf="item.routerLink" [routerLink]="item.routerLink" [attr.data-automationid]="item.automationId" [queryParams]="item.queryParams" [routerLinkActive]="'ui-state-active'"
       [routerLinkActiveOptions]="item.routerLinkActiveOptions||{exact:false}" class="ui-menuitem-link ui-corner-all" [attr.target]="item.target" [attr.id]="item.id"
       [attr.title]="item.title" [ngClass]="{'is--selected': item.isSelected, 'ui-state-disabled':item.disabled, 'has--icon':item.icon || item.iconRight}" (click)="menu.itemClick($event, item)">
      <h6 *ngIf="item.labelTitle" class="ui-menuitem-title">{{item.labelTitle}}</h6>
      <span *ngIf="item.icon" class="ui-menuitem-icon" [ngClass]="item.icon"></span>
      <span *ngIf="item.iconSVG && item.icon" class="ui-menuitem-icon is--svg">
        <svg [ngClass]="item.icon">
          <use [attr.xlink:href]="item.iconSVG"></use>
        </svg>
      </span>
      <span class="ui-menuitem-text">{{item.label}}</span>
      <span *ngIf="item.iconRight" class="ui-menuitem-icon" [ngClass]="item.iconRight"></span>
      <span *ngIf="item.iconSVG &&  item.iconRight" class="ui-menuitem-icon is--right is--svg">
        <svg [ngClass]="item.iconRight">
          <use [attr.xlink:href]="item.iconSVG"></use>
        </svg>
      </span>
      <span class="ui-menuitem-content" (click)="menu.contentClick($event, item)" *ngIf="item.content" [innerHTML]="item.content"> </span>
    </a>
  `
} )
export class MenuItemContent {

  @Input( 'pMenuItemContent' ) item : MenuItem;

  constructor ( @Inject( forwardRef( () => Menu ) ) public menu : Menu ) {
  }
}

@NgModule( {
  imports      : [CommonModule, RouterModule],
  exports      : [Menu, RouterModule],
  declarations : [Menu, MenuItemContent]
} )
export class MenuModule {
}
