import { NgModule, Component, Input, OnInit } from '@angular/core';
import { CommonModule }                       from '@angular/common';
import { HttpClientModule, HttpClient }       from '@angular/common/http';
import { SharedModule }                       from '../common/shared';

@Component( {
  selector : 'sym-svg',
  template : ''
} )

export class SymSvg implements OnInit {

  version = '4.2.5';
  //NOTE: this gets updated by prebuild.js.

	constructor ( public httpClient: HttpClient ) { }
    getSymbols(){
        this.httpClient.get( '/resources/toolkit/svg/symbols-library.min.html?v=' + this.version, { responseType : 'text' } ).subscribe( ( res ) => {
            var fragment = document.createRange().createContextualFragment( res );
            document.body.appendChild( fragment );
        } );
    }

	ngOnInit () {
		this.getSymbols();
  }

}

@NgModule( {
  imports      : [ CommonModule, HttpClientModule ],
  exports      : [ SymSvg, SharedModule ],
  declarations : [ SymSvg ]
} )

export class SymSvgModule {
}
