import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgModule,
    OnChanges,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import * as d3 from 'd3';
import {ObjectUtils} from '../utils/object-utils';
import {Utils} from '../common/utils';
import {CommonModule} from '@angular/common';

@Component({
    selector: 'sym-chart-line',
    template: `
        <div class="sym-chart-line__container">
            <div class="sym-chart-line__chart-container">
                <figure [id]="lineChartId"></figure>
            </div>
            <div *ngIf="!chartLoading && !noData">
                <div class="sym-chart-line__legends">
                    <ul class="legends-items-list">
                        <li class="legend-item" *ngFor="let legend of legends" (click)="chartLegendClick(legend)">
                            <span class="legend-icon" [ngStyle]="{'background-color': legend.color}"></span>
                            <span class="legend-label" [attr.title]="legend.label">{{legend.label}}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="center-content-container loading__container" *ngIf="chartLoading">
                <svg class="sym-smbl__progress-spinner sym-smbl--medium">
                    <use href="#sym-smbl__progress-spinner"></use>
                </svg>
            </div>
            <div class="center-content-container no__data" *ngIf="noData">
                <div class="sym-chart__no-data">
                    <span class="error-icon"><svg class="sym-smbl__dashboard-error"><use
                            href="#sym-smbl__dashboard-no-data"></use></svg></span>
                    <span>{{ noDataText }}</span>
                </div>
            </div>
        </div>`,
    providers: [ObjectUtils, Utils],
    encapsulation: ViewEncapsulation.None
})
export class SymChartLine implements OnInit, OnChanges {
    private TIME_FORMAT = '%m/%d';
    @Input() data: any[];
    @Input() options: any;
    @Output() legendClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() pointClick: EventEmitter<any> = new EventEmitter<any>();

    width;
    height;
    hostElement; // Native element hosting the SVG container
    svg; // Top level SVG element
    g; // SVG Group element
    line; // draw lines
    xScale; // x scale
    yScale; // y scale
    focus; // tooltip container
    overlay; // tooltip
    margin: any = {top: 10, right: 15, bottom: 15, left: 55};
    keys: string[]; // series keys

    xAxis = 'time';
    yAxis = null;
    noData = false;
    chartLoading = true;
    metaData: any;
    legends: any[] = [];
    lineChartId: string;
    noDataText: string;

    constructor(private elRef: ElementRef) {
        this.hostElement = this.elRef.nativeElement;
    }

    ngOnInit(): void {
        this.lineChartId = `line-${Utils.generateUUID()}`;
        this.setChartData();
    }

    ngOnChanges(): void {
        this.setChartData();
    }


    chartLegendClick(legend) {
        this.legendClick.emit(legend);
    }


    // private methods

    private setChartData() {
        this.removeExistingChartFromParent();
        this.noDataText = (this.options && this.options.l10n && this.options.l10n.noData) ? this.options.l10n.noData : 'No Data Available';
        if (!this.data) {
            this.chartLoading = true;
            this.noData = false;
            return;
        }

        if (this.data.length > 0) {
            this.chartLoading = false;
            this.noData = false;
            this.renderFullWidget(this.data);
        } else {
            if (this.data.length === 0) {
                this.noData = true;
                this.chartLoading = false;
            }
        }
    }

    private chartCircleClick(item) {
        this.pointClick.emit(item);
    }

    private renderFullWidget(data) {
        this.processLineMetaData();
        this.setChartDimensions();
        this.renderChart(data);
    }

    private processLineMetaData() {
        if (this.options.metaData) {
            this.legends = [];
            this.metaData = this.options.metaData;
            this.xAxis = this.metaData.x_axis;
            this.yAxis = this.metaData.y_axis;
            this.TIME_FORMAT = this.metaData.time_format;
            this.keys = Object.keys(this.metaData.series);

            // add legends data for rendering
            for (const [key, value] of Object.entries(this.metaData.series)) {
                const val = (value as object);
                const item = {
                    key,
                    ...val
                };
                this.legends.push(item);
            }
        }
    }

    private renderChart(data) {
        data.forEach((d) => {
            d.date = new Date(d[this.xAxis]);
            return d;
        });

        this.xScale = d3.scaleTime()
            .rangeRound([this.margin.left, this.width - this.margin.right])
            .domain(<[Date, Date]>d3.extent(data, (d: any) => d.date));

        this.yScale = d3.scaleLinear()
            .rangeRound([this.height - this.margin.bottom, this.margin.top]);


        this.line = d3.line()
            .curve(d3.curveMonotoneX)
            .x((d: any) => this.xScale(d.date))
            .y((d: any) => this.yScale(d.count));

        this.overlay = this.svg.append('rect')
            .attr('class', 'overlay')
            .attr('x', this.margin.left)
            .attr('width', this.width - this.margin.right - this.margin.left)
            .attr('height', this.height);

        this.focus = this.svg.append('g')
            .attr('class', 'focus')
            .style('opacity', 0)
            .on('mouseover', () => {
                this.focus.transition().duration(200).style('opacity', 1);
            })
            .on('mouseout', () => {
                this.focus.transition().duration(200).style('opacity', 0);
            });

        this.focus.append('line').attr('class', 'line-hover')
            .style('stroke', '#999')
            .attr('stroke-width', 1)
            .style('shape-rendering', 'crispEdges')
            .style('opacity', 0.5)
            .attr('y1', -this.height)
            .attr('y2', 0);

        this.focus.append('text').attr('class', 'line-hover-date')
            .attr('text-anchor', 'middle')
            .attr('font-size', 12);

        this.updateChart(data);
    }

    private updateChart(data) {
        const keys = this.keys;
        const ticks = (this.yAxis && this.yAxis.ticks) ? this.yAxis.ticks : 3;
        const series = keys.map((id) => {
            return {
                id,
                values: data.map(d => {
                    return {
                        date: d.date,
                        count: +d[id]
                    };
                })
            };
        });

        const maxCount = parseInt(d3.max(series, d => d3.max(d.values, (c: any) => c.count)));
        this.yScale.domain([
            0, d3.max([5, maxCount])
        ]).nice();

        // based on data
        this.svg.append('g')
            .attr('class', 'y-axis')
            .attr('transform', 'translate(' + this.margin.left + ',0)')
            .call(d3.axisLeft(this.yScale.domain([
                0, d3.max([4, maxCount])
            ]).nice()).ticks(ticks));


        this.svg.append('g')
            .attr('class', 'x-axis')
            .attr('transform', 'translate(0,' + (this.height - this.margin.bottom) + ')')
            .call(
                d3.axisBottom(this.xScale)
                    .ticks(7)
                    .tickSize(-290)
                    .tickFormat(d3.timeFormat(this.TIME_FORMAT))
            );


        const seriesItem = this.svg.selectAll('.series')
            .data(series);

        seriesItem.exit().remove();

        seriesItem.enter().insert('g', '.overlay').append('path')
            .attr('class', 'line series')
            .attr('id', (d, i) => {
                return `${this.lineChartId}__${i}`;
            })
            .style('stroke', d => this.metaData.series[d.id].color)
            .merge(seriesItem)
            .attr('d', d => this.line(d.values));

        const renderToolTip = (lineItemData) => {
            const that = this;
            const formatDate = d3.timeFormat(this.TIME_FORMAT);
            const bisectDate = d3.bisector((d: any) => d.date).left;
            const formatValue = d3.format(',.0f');
            const labels = this.focus.selectAll('.line-hover-text')
                .data(keys);

            labels.enter().append('text')
                .attr('class', 'line-hover-text')
                .style('fill', d => that.metaData.series[d].color)
                .attr('text-anchor', 'start')
                .attr('font-size', 14)
                .attr('dy', (_, i) => i * 1.2 + 'em')
                .merge(labels);

            const circles = this.focus.selectAll('.line-hover-circle')
                .data(keys);

            circles.enter().append('circle')
                .attr('class', 'line-hover-circle')
                .attr('stroke', d => that.metaData.series[d].color)
                .attr('stroke-width', '2')
                .attr('fill', '#FFFFFF')
                .attr('r', 4)
                .merge(circles);


            this.svg.selectAll('.overlay')
                .on('mouseover', () => {
                    this.focus.transition().duration(200).style('opacity', 1);
                })
                .on('mouseout', () => {
                    this.focus.transition().duration(200).style('opacity', 0);
                })
                .on('mousemove', mousemove);

            function mousemove() {

                const x0 = that.xScale.invert(d3.mouse(this)[0]);
                const i = bisectDate(lineItemData, x0, 1);
                const d0 = lineItemData[i - 1];
                const d1 = lineItemData[i];
                const d = x0 - d0.date > d1.date - x0 ? d1 : d0;

                that.focus.select('.line-hover')
                    .attr('transform', 'translate(' + that.xScale(d.date) + ',' + that.height + ')');

                that.focus.select('.line-hover-date')
                    .attr('transform',
                        'translate(' + that.xScale(d.date) + ',' + (that.height + that.margin.bottom) + ')')
                    .text(formatDate(d.date));

                that.focus.selectAll('.line-hover-circle')
                    .attr('cy', e => that.yScale(d[e]))
                    .attr('cx', that.xScale(d.date))
                    .on('mouseover', () => {
                        that.focus.transition().duration(200).style('opacity', 1);
                    })
                    .on('mouseout', () => {
                        that.focus.transition().duration(200).style('opacity', 0);
                    })
                    .on('click', (currentLineItem) => {
                        that.chartCircleClick({current: currentLineItem, data: d});
                    });

                that.focus.selectAll('.line-hover-text')
                    .attr('transform',
                        'translate(' + (that.xScale(d.date)) + ',' + that.height / 3 + ')')
                    .text(e => that.metaData.series[e].label + ' - ' + formatValue(d[e]))
                    .on('click', (currentLineItem) => {
                        that.chartCircleClick({current: currentLineItem, data: d});
                    });

                that.xScale(d.date) > (that.width - that.width / 4)
                    ? that.focus.selectAll('text.line-hover-text')
                        .attr('text-anchor', 'end')
                        .attr('dx', -10)
                    : that.focus.selectAll('text.line-hover-text')
                        .attr('text-anchor', 'start')
                        .attr('dx', 10);
            }
        };
        renderToolTip(data);
    }

    private setChartDimensions() {
        const parent = this.hostElement.querySelectorAll('.sym-chart-line__chart-container figure')[0];

        this.svg = d3.select(parent).append('svg')
            .attr('width', '100%')
            .attr('height', '100%')
            .attr('viewBox', `0 0 ${parent.clientWidth} ${parent.clientHeight}`);

        this.width = +parent.clientWidth - this.margin.left - this.margin.right;
        this.height = +parent.clientHeight - this.margin.top - this.margin.bottom;

    }

    private removeExistingChartFromParent() {
        d3.select(this.hostElement).select('svg').remove();
    }
}

@NgModule({
    imports: [CommonModule],
    exports: [SymChartLine],
    declarations: [SymChartLine]
})
export class SymChartLineModule {
}
