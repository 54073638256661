import {
  IParser,
  IParserAutoCompleteSuggestion,
  IParserConfiguration,
  IParserError,
  IParserFieldConfiguration,
  IParserFieldLookup,
  IParserFieldValueConfiguration,
  IParserMacroConfiguration,
  IParserMacroLookup,
  ParserFieldDataType,
  ParserFieldName,
  ParserFieldSimpleValue,
  ParserFieldValue, ParserQueryOperator
} from '../../components/common/parser';
import {SearchToken, SearchTokenType} from './search-token';
import {ParserToken} from "./parser-token";
import {ICustomFilter, IQuickFilter} from "../../components/search-filter/i-search-filter";
import {ValidationErrors} from "@angular/forms";

export abstract class BaseParserService implements IParser {
  protected config: IParserConfiguration;
  protected fields: IParserFieldLookup = {};
  protected macros: IParserMacroLookup = {};   // TODO: merge these two and use just one lookup; differentiate field vs macro via the `query` property
  protected expression = '';
  protected hasFieldConfig = false;
  protected hasMacroConfig = false;
  private errors: IParserError[] = [];   // Sub-classes should set this via validate()
  protected tokens: SearchToken[];
  protected abstract computeTokens(): SearchToken[];
  protected nonQuotedSpaceContainingRegex = /^[^"/].*\s.*[^"/]$/;   // Don't apply to regex, e.g. field:/value/   // TODO: move to LuceneParser

  abstract createTokenString(field: string, value: ParserFieldSimpleValue, operator: ParserQueryOperator, addSpaceAfterSeparator?: boolean): string;

  // Argument `token` is usually `ParserToken.token`
  // To distinguish between no value and empty value, return `undefined` for value if there is none
  abstract getFieldNameAndValuePair(token: string, keepQuotes?: boolean): string[];

  abstract containsFieldName(token: string, name: string): boolean;
  abstract containsFieldValue(token: string, value: string): boolean;
  abstract escapeSpecialChars(s: string): string;
  abstract getNegationPrefix(): string;
  abstract replaceFieldName(token: string, newName: string): string;
  abstract replaceFieldValue(token: string, newValue: string): string;
  abstract replaceMacroQuery(token: string, macro: IParserMacroConfiguration, value: string): string;
  abstract replaceNegation(token: string, newNegation: string, addSpaceAfterNewNegation?: boolean): string;
  abstract unescapeSpecialChars(s: string): string;
  abstract validate(): IParserError[];
  abstract validateFieldName(field: ParserFieldName, prefix?: string): void;
  abstract validateFieldValue(field: ParserFieldName, value: string, prefix?: string): void;
  abstract validateDisplayName(field: ParserFieldName, displayName: string, prefix?: string, isFallback?: boolean): void;
  abstract validateDisplayValue(field: ParserFieldName, displayValue: string, prefix?: string): void;
  abstract isAllowedField(field: ParserFieldName): boolean;
  abstract isAllowedFieldValue(field: ParserFieldName, value: string): ValidationErrors;

  protected escapeRegexChars(str: string): string {
    // https://github.com/lodash/lodash/blob/4.1.2-npm-packages/lodash.escaperegexp/index.js#L20
    const reRegExpChar = /[\\^$.*+?()[\]{}|]/g;
    return str.replace(reRegExpChar, '\\$&');
  }

  protected escapeRegexCharacterClassChars(str: string): string {
    const reRegExpCharacterClassChar = /[\-\]\\]/g;
    return str.replace(reRegExpCharacterClassChar, '\\$&');
  }

  protected isParserFieldValueConfiguration(value: ParserFieldValue): boolean {
    // Now that ParserFieldSimpleValue contains Date as well, can no longer just check for type `object`
    return typeof value === 'object' && !(value instanceof Date);
  }

  protected validateConfiguration(item: IParserFieldConfiguration | IParserMacroConfiguration, prefix: string): void {
    // Type is required
    if (item.type === undefined) {
      throw new Error(prefix + ' ' + item.name + ' must specify a type');
    }

    // Type must be an enum value
    switch (item.type) {
      case ParserFieldDataType.Boolean:
      case ParserFieldDataType.Date:
      case ParserFieldDataType.Number:
      case ParserFieldDataType.String:
        break;
      default:
        throw new Error(prefix + ' ' + item.name + ' specifies unknown type: ' + item.type);
    }

    // Name should not have leading/trailing spaces
    if (item.name.trim() !== item.name) {
      throw new Error(prefix + ' ' + item.name + ' has leading and/or trailing spaces in its name, which must be removed: ' + item.name);
    }

    // Name should be a valid identifier
    this.validateFieldName(item.name, prefix);   // Will throw Error if invalid

    // Display name cannot be `true` (only possible boolean value is `false`)
    if (item.displayName === true) {
      throw new Error(prefix + ' ' + item.name + ' must have either a string displayName or the boolean value false: ' + item.displayName);
    }

    (item.values || []).forEach(value => {
      if (this.isParserFieldValueConfiguration(value)) {
        // Value object must have required properties
        let v = value as IParserFieldValueConfiguration;
        if (v.value === undefined || v.displayValue === undefined) {
          throw new Error(prefix + ' ' + item.name + ' value object must specify both value and displayValue properties');
        }
        value = v.value;
      }

      // Value must be of specified type
      let result = false;
      switch (item.type) {
        case ParserFieldDataType.Boolean:
          result = typeof value === 'boolean';
          break;
        case ParserFieldDataType.Date:
          result = value instanceof Date;
          break;
        case ParserFieldDataType.Number:
          result = typeof value === 'number';
          break;
        case ParserFieldDataType.String:
          result = typeof value === 'string';
          break;
      }
      if (!result) {
        throw new Error(prefix + ' ' + item.name + ' specifies a value that is not of type ' + item.type);
      }

      if (typeof value === 'string') {
        // Value should not have leading/trailing spaces
        if (value.trim() !== value) {
          throw new Error(prefix + ' ' + item.name + ' specifies a value that has leading and/or trailing spaces, which must be removed: ' + value);
        }

        // Value must be quoted if it contains spaces
        if (this.nonQuotedSpaceContainingRegex.test(value.trim())) {
          throw new Error(prefix + ' ' + item.name + ' specifies a value that contains one or more spaces and must be quoted: ' + value);
        }

        // Value should be a valid identifier
        this.validateFieldValue(item.name, value, prefix);   // Will throw Error if invalid
      }
    });

    // Macros must define a query
    if (prefix === 'Macro' && !(item as IParserMacroConfiguration).query) {
      throw new Error(prefix + ' ' + item.name + ' must specify a query');
    }
  }

  protected configureLookupItem(item: IParserFieldConfiguration, addMethod, fieldLookup: IParserFieldLookup, prefix: string) {
    this.validateConfiguration(item, prefix);

    // Be nice and add autocomplete suggestions for boolean values if config doesn't specify any
    if (item.type === ParserFieldDataType.Boolean && !item.values) {
      item.values = [ true, false ];
    }

    addMethod.call(this, fieldLookup, item.name, item);
    (item.aliases || []).forEach(alias => {
      addMethod.call(this, fieldLookup, alias, item);
    });
  }

  protected configureLookup(configLookup: IParserFieldConfiguration[], addMethod, fieldLookup: IParserFieldLookup, prefix: string): void {
    configLookup.forEach(item => {
      this.configureLookupItem(item, addMethod, fieldLookup, prefix);
    });
  }

  configure(config: IParserConfiguration): this {
    this.fields = {};
    this.macros = {};
    this.config = config;
    if (config.fields) {
      this.hasFieldConfig = true;
      this.configureLookup(config.fields, this.addFieldLookup, this.fields, 'Field');
    }
    if (config.macros) {
      this.hasMacroConfig = true;
      this.configureLookup(config.macros, this.addFieldLookup, this.macros, 'Macro');
    }

    return this;
  }

  getConfiguration() {
    return this.config;
  }

  private addCustomFilterDataItems(lookup: IParserFieldConfiguration[], filter: ICustomFilter, itemProperties: Object): void {
    lookup.forEach(item => {
      // Append to existing suggestions if present (this is so when fields with same name but different IDs were configured)
      const f = filter.items.find(f => {
        return f.id === item.name;
      });

      const suggestedValues = [];
      (item.values || []).forEach(value => {
        if (this.isParserFieldValueConfiguration(value)) {
          suggestedValues.push(value);
        } else {
          suggestedValues.push({
            value: value,
            displayValue: String(value)
          });
        }
      });

      if (f) {
        f.suggestedValues = [...f.suggestedValues, ...suggestedValues];
      } else {
        filter.items.push(Object.assign({}, {
          displayName: item.displayName ? item.displayName as string : item.name,
          id: item.name,
          valueType: item.type,
          suggestedValues: suggestedValues.length ? suggestedValues : undefined,
          supportedOperators: ['EQUALS', 'NOT_EQUALS', 'IS_ONE_OF', 'IS_NOT_ONE_OF', 'IS_BETWEEN', 'IS_NOT_BETWEEN', 'LESS_THAN', 'LESS_THAN_EQUAL_TO', 'GREATER_THAN', 'GREATER_THAN_EQUAL_TO']   // Add most common operators that work on both numbers and strings
        }, itemProperties[item.name] || {}));
      }
    });
  }

  // Call this method to get all data to feed into `SearchFilter`'s `customFilter` input.
  // By default, each item will get assigned all supported SearchFilter operators and all configured values for that
  // field/macro are added as suggested values. No validators are added.
  // Any overrides or additional properties should be passed in as `itemProperties`.
  getCustomFilterData(itemProperties = {}): ICustomFilter {
    if (!this.config) {
      throw 'Missing parser configuration';
    }

    const result: ICustomFilter = {
      id: 'customfilter-element',
      selectedItem: null,
      items: []
    }

    if (this.hasFieldConfig) {
      this.addCustomFilterDataItems(this.config.fields, result, itemProperties);
    }

    if (this.hasMacroConfig) {
      this.addCustomFilterDataItems(this.config.macros, result, itemProperties);
    }

    return result;
  }

  private addQuickFiltersDataItems(lookup: IParserFieldConfiguration[], filter: IQuickFilter[], type: string): void {
    lookup.forEach(item => {
      // Append to existing filter if present (this is so when fields with same name but different IDs were configured)
      const f = filter.find(f => {
        return f.id === item.name;
      });

      const items = [];
      let id = f ? f.items.length + 1 : 1;
      (item.values || []).forEach(value => {
        const i: any = {
          id: item.name + '-' + id, //used in HTML ID (i.e. qf_clientVersion-1)
          name: item.name, //key-value pair's key
          operator: 'EQUALS',
          value: this.isParserFieldValueConfiguration(value) ? (value as IParserFieldValueConfiguration).value : value,
          type: type
        };
        if (item.displayName !== undefined) {
          i.displayName = item.displayName;
        }
        if (this.isParserFieldValueConfiguration(value)) {
          i.displayValue = (value as IParserFieldValueConfiguration).displayValue;
        }
        items.push(i);
        id++;
      });

      if (f) {
        f.items = [...f.items, ...items];
      } else if (items.length) {
        filter.push({
          id: item.name,
          displayName: item.displayName ? item.displayName as string : item.name,
          items: items
        });
      }
    });
  }

  // Call this method to get all data to feed into `SearchFilter`'s `quickFilters` input
  getQuickFiltersData(): IQuickFilter[] {
    if (!this.config) {
      throw 'Missing parser configuration';
    }

    const result: IQuickFilter[] = [];

    if (this.hasFieldConfig) {
      this.addQuickFiltersDataItems(this.config.fields, result, 'query');
    }

    if (this.hasMacroConfig) {
      this.addQuickFiltersDataItems(this.config.macros, result, 'macro');
    }

    return result;
  }

  private filterDataByTerm(data: Object[], token: SearchToken): Object[] {
    console.log('FILTER BY TERM: run:', token.token, 'on:', data);
    const result = [];
    for (let i = 0; i < data.length; i++) {
      const kvPair = this.getFieldNameAndValuePair(token.token);
      if (token.type === SearchTokenType.Freeform) {
        Object.values(data[i]).some(v => {
          if (v.indexOf(kvPair[0]) >= 0) {
            result.push(data[i]);
            return true;
          }
        });
      } else if (token.type === SearchTokenType.Query) {
        if (data[i][kvPair[0]] === kvPair[1]) {    // TODO: support non-string data types
          result.push(data[i]);
        }
      }
    }

    console.log('FILTER BY TERM: result:', result);
    return result;
  }

  private static filterDataByData(data1: Object[], operator: string, data2: Object[]): Object[] {
    console.log('FILTER BY DATA: data1:', data1, 'op:', operator, 'data2:', data2);

    let i;
    let result = [];
    if (operator.toUpperCase() === 'AND' || operator === '&&') {
      for (i = 0; i < data1.length; i++) {
        if (data2.indexOf(data1[i]) >= 0) {
          result.push(data1[i]);
        }
      }
    } else {
      result = [...result, ...data1];
      for (i = 0; i < data2.length; i++) {
        if (result.indexOf(data2[i]) < 0) {
          result.push(data2[i]);
        }
      }
    }

    console.log('FILTER BY DATA: result:', result);
    return result;
  }

  private filterImpl(data: Object[], tokens: SearchToken[], startIndex: number, endIndex: number): Object[] {
    let result = data;

    let s = '';
    for (let i = startIndex; i < endIndex; i++) {
      s += tokens[i].token + ' ';
    }
    console.log('FILTER IMPL: run:', s, 'on:', data);

    let operator = 'AND';   // TODO: need constants for operator?
    for (let i = startIndex; i < endIndex; i++) {
      if (tokens[i].type === SearchTokenType.Parenthesis && tokens[i].token === '(') {
        // Process subquery first
        let level = 0;
        for (let endIdx = i + 1; endIdx < tokens.length; endIdx++) {
          if (tokens[endIdx].type === SearchTokenType.Parenthesis && tokens[endIdx].token === '(') {
            level++;
          } else if (tokens[endIdx].type === SearchTokenType.Parenthesis && tokens[endIdx].token === ')') {
            if (level > 0) {
              level--;
            } else {
              result = BaseParserService.filterDataByData(result, operator, this.filterImpl(data, tokens, i + 1, endIdx));
              i = endIdx;   // i will be incremented (operator will be skipped) by the for loop
              break;
            }
          }
        }
      } else if (tokens[i].type === SearchTokenType.LogicalOperator) {
        operator = tokens[i].token;
        console.log('FILTER IMPL: change operator to:', operator);
      } else if (tokens[i].type === SearchTokenType.Macro) {
        // TODO: requires processing query in string form as expanded query isn't parsed?
        // TODO: what if there's a parsing error in the expanded query? isValid() should test expanded query and/or two sets of SearchToken[] returned
      } else if (tokens[i].type === SearchTokenType.Freeform || tokens[i].type === SearchTokenType.Query) {
        result = BaseParserService.filterDataByData(result, operator, this.filterDataByTerm(data, tokens[i]));
      }
    }

    return result;
  }

  filter(data: Object[]): Object[] {
    // No need to filter if there is no query or if the query is invalid or if there's nothing to filter
    if (!this.isValid() || this.getQuery().trim().length === 0 || data.length === 0) {
      return data;
    }

    return this.filterImpl(data, this.tokens, 0, this.tokens.length);
  }

  getTokens(): SearchToken[] {
    return this.tokens;
  }

  expandToken(token: SearchToken): string {
    let result = token.token;    // Don't use token.meta.token.rawToken so there are no leading spaces to deal with

    ((this.config || {}).fields || []).some(field => {
      return (field.aliases || []).some(alias => {
        if (this.containsFieldName(result, alias)) {
          result = this.replaceFieldName(result, field.name);
          return true;
        }
      });
    });

    ((this.config || {}).macros || []).forEach(macro => {
      (macro.aliases || []).some(alias => {
        if (this.containsFieldName(result, alias)) {
          result = this.replaceFieldName(result, macro.name);
          return true;
        }
      });
      if (this.containsFieldName(result, macro.name)) {
        if (macro.values) {
          macro.values.some(value => {
            if (this.isParserFieldValueConfiguration(value)) {
              value = (value as IParserFieldValueConfiguration).value;
            }
            if (this.containsFieldValue(result, String(value))) {
              result = this.replaceMacroQuery(result, macro, String(value));
              return true;
            }
          });
        } else {
          // Allows any value
          const parts = this.getFieldNameAndValuePair(result, true);
          result = this.replaceMacroQuery(result, macro, parts[1]);
        }
      }
    });

    return result;
  }

  // Retrieves query with macros, if any, replaced by their respective sub-queries
  // Also minimizes use of spaces
  getExpandedQuery(): string {
    // TODO: should auto-add operators or parens or should be part of macro? E.g. "foo macro:expr bar" --> "foo k:v AND ~k2:v2 bar" may not work...
    // TODO: should also validate expanded query!
    let result: string = '';

    // Since setQuery calls validate(), the pratt tree is built. Rather than regexes, use tokens for utmost precision.
    const tokens = this.getTokens();
    tokens.forEach((token) => {
      if (result) {
        result += ' ';
      }
      if (token.type === SearchTokenType.Query || token.type === SearchTokenType.Macro) {
        result += this.expandToken(token);
      } else {
        result += token.token;
      }
    });

    return result;
  }

  // Retrieves query as the user entered it
  getQuery(): string {
    return this.expression;
  }

  // Sets a new query, as if the user had entered it, with non-expanded macros in place
  setQuery(query: string): this {
    this.expression = query;
    this.errors = this.validate();
    this.tokens = this.computeTokens();   // This is the only place where tokens should be computed

    return this;
  }

  isValid(): boolean {
    return this.errors.length === 0;
  }

  getErrors(): IParserError[] {
    return this.errors;
  }

  private static matchSorter(a: IParserAutoCompleteSuggestion, b: IParserAutoCompleteSuggestion): number {
    const s1 = a.display.toLowerCase();
    const s2 = b.display.toLowerCase();
    return s1 === s2 ? 0 : s1 > s2 ? 1 : -1;
  }

  protected processMatchingFieldItem(lookup: IParserFieldLookup, name: string, matcher: Function, matches: IParserAutoCompleteSuggestion[], prefix: string): void {
    if (matcher(name, prefix)) {
      matches.push({ actual: name, display: name } as IParserAutoCompleteSuggestion);
    }
  }

  protected processMatchingFields(lookup: IParserFieldLookup, matcher: Function, matches: IParserAutoCompleteSuggestion[], prefix: string): void {
    Object.keys(lookup).forEach(name => {
      this.processMatchingFieldItem(lookup, name, matcher, matches, prefix);
    });
  }

  getMatchingFields(token: ParserToken | string): IParserAutoCompleteSuggestion[] {
    const matches: IParserAutoCompleteSuggestion[] = [];
    const pair = this.getFieldNameAndValuePair(token instanceof ParserToken ? token.token : token);
    const prefix = pair[0];
    const matcher = (value, sub) => value.toLowerCase().indexOf(sub.toLowerCase()) >= 0;   // Field name always uses "contains" matching

    if (prefix && this.hasFieldConfig) {
      this.processMatchingFields(this.fields, matcher, matches, prefix);
    }

    if (prefix && this.hasMacroConfig) {
      this.processMatchingFields(this.macros, matcher, matches, prefix);
    }

    return matches.sort(BaseParserService.matchSorter);
  }

  protected processMatchingValueItemAsDisplayValue(): boolean {
    return false;
  }

  protected processMatchingValuesForItem(configs: IParserFieldConfiguration[], matcher: Function, matches: IParserAutoCompleteSuggestion[], prefix: string): void {
    console.log('processMatchingValues');
    configs.forEach(config => {
      if (config && config.values) {
        config.values.forEach(value => {
          let valueAsString;
          // noinspection JSPotentiallyInvalidUsageOfClassThis, as `process` is invoked with correct `this` reference
          if (this.isParserFieldValueConfiguration(value)) {
            const v = value as IParserFieldValueConfiguration;
            valueAsString = String(this.processMatchingValueItemAsDisplayValue() ? v.displayValue : v.value);
          } else {
            valueAsString = String(value);
          }
          if (valueAsString && matcher(valueAsString, prefix)) {
            matches.push({ actual: valueAsString, display: valueAsString } as IParserAutoCompleteSuggestion);
          }
        });
      }
    });
  }

  protected processMatchingValues(field: string, matcher: Function, matches: IParserAutoCompleteSuggestion[], prefix: string) {
    if (this.hasFieldConfig && this.fields[field]) {
      this.processMatchingValuesForItem(this.fields[field], matcher, matches, prefix);
    }

    if (this.hasMacroConfig && this.macros[field]) {
      this.processMatchingValuesForItem(this.macros[field], matcher, matches, prefix);
    }
  }

  protected getMatchingValuesMatcher(prefix: string): { matcher: Function, prefix: string } {
    let matcher: Function;

    if (prefix.indexOf('"') === 0) {
      prefix = prefix.substring(1);
      if (prefix.lastIndexOf('"') === prefix.length - 1) {
        // Fully quoted so use "equals" matching
        prefix = prefix.substring(0, prefix.length - 1);
        matcher = (value, sub) => value.toLowerCase() === sub.toLowerCase();
      } else {
        // Only opening quote, so use "starts with" matching
        matcher = (value, sub) => value.toLowerCase().indexOf(sub.toLowerCase()) === 0;
      }
    } else {
      // No quotes so use "contains" matching
      matcher = (value, sub) => value.toLowerCase().indexOf(sub.toLowerCase()) >= 0;
    }

    return { matcher, prefix };
  }

  getMatchingValues(token: ParserToken | string): IParserAutoCompleteSuggestion[] {
    const matches: IParserAutoCompleteSuggestion[] = [];
    const pair = this.getFieldNameAndValuePair(token instanceof ParserToken ? token.token : token, true);
    if (pair.length > 1) {
      // If no value was entered, but the separator was present, return all values
      const field = pair[0];
      let prefix = pair[1] || '';
      const matcherInfo = this.getMatchingValuesMatcher(prefix);
      const matcher = matcherInfo.matcher;
      prefix = matcherInfo.prefix;
      this.processMatchingValues(field, matcher, matches, prefix);
    }

    console.log('getMatchingValues: ' + pair[0] + ':' + (pair.length > 1 ? pair[1] : ''), token instanceof ParserToken ? token.token : token, pair.length, matches);
    return matches.sort(BaseParserService.matchSorter);
  }

  protected convertToFieldType(value: string, type: ParserFieldDataType): ParserFieldSimpleValue {
    let result: ParserFieldSimpleValue;

    switch (type) {
      case ParserFieldDataType.Boolean:
        result = value === 'true' ? true : value === 'false' ? false : undefined;
        break;
      case ParserFieldDataType.Date:
        let valueAsNumber = Number(value);
        if (!Number.isNaN(valueAsNumber)) {
          result = new Date(valueAsNumber);
        } else {
          result = new Date(value);
        }
        break;
      case ParserFieldDataType.Number:
        result = Number(value);
        break;
      case ParserFieldDataType.String:
        result = value;
        break;
    }

    return result;
  }

  protected static validateTypedValue(typed: ParserFieldSimpleValue, type: ParserFieldDataType): boolean {
    let result = false;
    switch (type) {
      case ParserFieldDataType.Boolean:
        result = typeof typed === 'boolean';
        break;
      case ParserFieldDataType.Date:
        result = !Number.isNaN((typed as Date).getTime());
        break;
      case ParserFieldDataType.Number:
        result = !Number.isNaN(typed);
        break;
      case ParserFieldDataType.String:
        result = typeof typed === 'string';
        break;
    }
    return result;
  }

  protected equalsTypedValue(configuredValue: ParserFieldValue, typedValue: ParserFieldSimpleValue, type: ParserFieldDataType): boolean {
    let result = false;
    switch (type) {
      case ParserFieldDataType.Boolean:
      case ParserFieldDataType.Number:
      case ParserFieldDataType.String:
        result = configuredValue === typedValue || this.isParserFieldValueConfiguration(configuredValue) && (configuredValue as IParserFieldValueConfiguration).value === typedValue;
        break;
      case ParserFieldDataType.Date:
        if (this.isParserFieldValueConfiguration(configuredValue)) {
          result = ((configuredValue as IParserFieldValueConfiguration).value as Date).getTime() === (typedValue as Date).getTime();
        } else {
          result = (configuredValue as Date).getTime() === (typedValue as Date).getTime();
        }
        break;
    }
    return result;
  }

  private addFieldLookup(lookupToAddTo: IParserFieldLookup | IParserMacroLookup, name: ParserFieldName, field: IParserFieldConfiguration): void {
    // ID is expected to be used as a way to avoid the macro query formatter function and to allow multiple
    // macro configurations with the same field name.
    // For the purposes of being able to map a plain-text field name to the config object (e.g. from a raw token)
    // and do value lookups, all configs for such macros are aggregated and stored under the common field name.
    // This means a couple of things:
    // 1. For ease of coding, the field/macro lookup value is always an array, even if there's just one item.
    // 2. When an ID is used and there are multiple configurations with the same field name:
    //    a. Each config *must* define at least one value, as there cannot be a case where one config specifies "any"
    //       or "no" values allowed, while others limit to a set of specific values
    //    b. Values among all configs *must* be unique, so there's no confusion about which config is for which value
    //    c. The `displayName` among all configs *must* be identical, so there's no confusion
    //    d. In effect, *all* field/macro configs will get merged under `this.fields` or `this.macros` depending on
    //       which was seen first
    //       (Since ideally `this.fields` and `this.macros` are merged into a single `this.fields` lookup anyway (see
    //       comment for the `macros` instance variable), so this should be ok)
    let validate = true;
    if (field.id) {
      let error = false;
      let configs = this.fields[name] || this.macros[name];
      if (configs) {
        // Field name was seen before, now ensure the new config and all existing configs have the same displayName,
        // define at least one value and that all values are unique (see big comment above)
        if (!field.values || field.values.length === 0) {
          error = true;
        } else {
          let values = [...field.values];
          configs.some(config => {
            if (field.displayName !== config.displayName) {
              throw new Error('Field definitions with an ID must have identical displayName value among all configs with the same field/macro name.');
            }
            if (!config.values || config.values.length === 0) {
              error = true;
              return true;
            }
            if (config.values.some(v => values.indexOf(v) >= 0)) {
              throw new Error('Field definitions with an ID must have unique values among all configs with the same field/macro name.');
            } else {
              values = [...values, ...config.values];
            }
          });
        }
      }
      if (error) {
        throw new Error('Field definitions with an ID must define at least one value. Remove the ID, or for macros, use a query formatter function instead.');
      } else if (configs) {
        // Just adding to the already existing configs
        validate = false;
        configs.push(field);
      }
    }
    if (validate) {
      if (this.fields[name]) {
        if (lookupToAddTo === this.fields) {
          throw new Error('Duplicate field definition: ' + field.name);
        } else {
          throw new Error('Duplicate macro/field definition: ' + field.name);
        }
      }
      if (this.macros[name]) {
        if (lookupToAddTo === this.fields) {
          throw new Error('Duplicate field/macro definition: ' + field.name);
        } else {
          throw new Error('Duplicate macro definition: ' + field.name);
        }
      }
      lookupToAddTo[name] = [ field ];
    }
  }
}
