// Angular
import { RouterModule } from '@angular/router';
import { NgModule }     from '@angular/core';

// Project
import { HomeComponent }    from '@showcase/components/home/home.component';
import { ToolkitComponent } from '@showcase/components/toolkit/toolkit.component';

@NgModule( {
  imports : [
    RouterModule.forRoot( [
      {
        path     : '', component : ToolkitComponent,
        children : [
          { path : '', component : HomeComponent },
          { path : 'accordion', loadChildren : () => import('./components/accordion/accordion-demo.module').then( m => m.AccordionDemoModule ) },
          { path : 'actionbar', loadChildren : () => import('./components/action-bar/action-bar-demo.module').then( m => m.ActionBarDemoModule ) },
          { path : 'autocomplete', loadChildren : () => import('./components/auto-complete/auto-complete-demo.module').then( m => m.AutoCompleteDemoModule ) },
          { path : 'blockui', loadChildren : () => import('./components/block-ui/block-ui-demo.module').then( m => m.BlockUIDemoModule ) },
          { path : 'breadcrumb', loadChildren : () => import('./components/breadcrumb/breadcrumb-demo.module').then( m => m.BreadcrumbDemoModule ) },
          { path : 'button', loadChildren : () => import('./components/button/button-demo.module').then( m => m.ButtonDemoModule ) },
          { path : 'calendar', loadChildren : () => import('./components/calendar/calendar-demo.module').then( m => m.CalendarDemoModule ) },
          { path : 'card', loadChildren : () => import('./components/card/card-demo.module').then( m => m.CardDemoModule ) },
          { path : 'carousel', loadChildren : () => import('./components/carousel/carousel-demo.module').then( m => m.CarouselDemoModule ) },
          { path : 'chart', loadChildren : () => import('./components/chart/chart-demo.module').then( m => m.ChartDemoModule ) },
          { path : 'checkbox', loadChildren : () => import('./components/checkbox/checkbox-demo.module').then( m => m.CheckboxDemoModule ) },
          { path : 'chips', loadChildren : () => import('./components/chips/chips-demo.module').then( m => m.ChipsDemoModule ) },
          { path : 'codehighlighter', loadChildren : () => import('./components/code-highlighter/code-highlighter-demo.module').then( m => m.CodeHighlighterDemoModule ) },
          { path : 'colorpicker', loadChildren : () => import('./components/color-picker/color-picker-demo.module').then( m => m.ColorPickerDemoModule ) },
          { path : 'confirmdialog', loadChildren : () => import('./components/confirm-dialog/confirm-dialog-demo.module').then( m => m.ConfirmDialogDemoModule ) },
          { path : 'contextmenu', loadChildren : () => import('./components/context-menu/context-menu-demo.module').then( m => m.ContextMenuDemoModule ) },
          { path : 'dashboard', loadChildren : () => import('./components/dashboard/dashboard-demo.module').then( m => m.DashboardDemoModule ) },
          { path : 'dataview', loadChildren : () => import('./components/data-view/data-view-demo.module').then( m => m.DataViewDemoModule ) },
          { path : 'dialog', loadChildren : () => import('./components/dialog/dialog-demo.module').then( m => m.DialogDemoModule ) },
          { path : 'displaystringparser', loadChildren : () => import('./service/parser-service/parser-service-demo.module').then( m => m.ParserServiceDemoModule ) },
          { path : 'dragdrop', loadChildren : () => import('./components/drag-drop/drag-drop-demo.module').then( m => m.DragDropDemoModule ) },
          { path : 'dropdown', loadChildren : () => import('./components/dropdown/dropdown-demo.module').then( m => m.DropdownDemoModule ) },
          { path : 'dynamicdialog', loadChildren : () => import('./components/dynamic-dialog/dynamic-dialog-demo.module').then( m => m.DynamicDialogDemoModule ) },
          { path : 'editor', loadChildren : () => import('./components/editor/editor-demo.module').then( m => m.EditorDemoModule ) },
          { path : 'featuretoggle', loadChildren : () => import('./components/feature-toggle/feature-toggle-demo.module').then( m => m.FeatureToggleDemoModule ) },
          { path : 'fieldset', loadChildren : () => import('./components/fieldset/fieldset-demo.module').then( m => m.FieldsetDemoModule ) },
          { path : 'fonts', loadChildren : () => import('./components/fonts/fonts-demo.module').then( m => m.FontsDemoModule ) },
          { path : 'form', loadChildren : () => import('./components/form/form-demo.module').then( m => m.FormDemoModule ) },
          { path : 'fullcalendar', loadChildren : () => import('./components/full-calendar/full-calendar-demo.module').then( m => m.FullCalendarDemoModule ) },
          { path : 'galleria', loadChildren : () => import('./components/galleria/galleria-demo.module').then( m => m.GalleriaDemoModule ) },
          { path : 'grid', loadChildren : () => import('./components/grid/grid-demo.module').then( m => m.GridDemoModule ) },
          { path : 'inlineedit', loadChildren : () => import('./components/inline-edit/inline-edit-demo.module').then( m => m.SymInlineEditDemoModule ) },
          { path : 'inplace', loadChildren : () => import('./components/inplace/inplace-demo.module').then( m => m.InplaceDemoModule ) },
          { path : 'inputgroup', loadChildren : () => import('./components/inputgroup/inputgroup-demo.module').then( m => m.InputGroupDemoModule ) },
          { path : 'inputmask', loadChildren : () => import('./components/input-mask/input-mask-demo.module').then( m => m.InputMaskDemoModule ) },
          { path : 'inputswitch', loadChildren : () => import('./components/input-switch/input-switch-demo.module').then( m => m.InputSwitchDemoModule ) },
          { path : 'inputtext', loadChildren : () => import('./components/input-text/input-text-demo.module').then( m => m.InputTextDemoModule ) },
          { path : 'inputtextarea', loadChildren : () => import('./components/input-textarea/input-textarea-demo.module').then( m => m.InputTextareaDemoModule ) },
          { path : 'inputtexthelper', loadChildren : () => import('./components/input-text-helper/input-text-helper-demo.module').then( m => m.SymInputTextHelperDemoModule ) },
          { path : 'journeyline', loadChildren : () => import('./components/journey-line/journey-line-demo.module').then( m => m.JourneyLineDemoModule ) },
          { path : 'keyfilter', loadChildren : () => import('./components/key-filter/key-filter-demo.module').then( m => m.KeyFilterDemoModule ) },
          { path : 'lightbox', loadChildren : () => import('./components/lightbox/lightbox-demo.module').then( m => m.LightboxDemoModule ) },
          { path : 'listbox', loadChildren : () => import('./components/list-box/list-box-demo.module').then( m => m.ListboxDemoModule ) },
          { path : 'luceneparser', loadChildren : () => import('./service/parser-service/parser-service-demo.module').then( m => m.ParserServiceDemoModule ) },
          { path : 'megamenu', loadChildren : () => import('./components/mega-menu/mega-menu-demo.module').then( m => m.MegaMenuDemoModule ) },
          { path : 'menu', loadChildren : () => import('./components/menu/menu-demo.module').then( m => m.MenuDemoModule ) },
          { path : 'menumodel', loadChildren : () => import('./components/menumodel/menumodel-demo.module').then( m => m.MenuModelDemoModule ) },
          { path : 'messages', loadChildren : () => import('./components/messages/messages-demo.module').then( m => m.MessagesDemoModule ) },
          { path : 'multiselect', loadChildren : () => import('./components/multi-select/multi-select-demo.module').then( m => m.MultiSelectDemoModule ) },
          { path : 'multistepform', loadChildren : () => import('./components/multi-step-form/multi-step-form-demo.module').then( m => m.MultiStepFormDemoModule ) },
          { path : 'orderlist', loadChildren : () => import('./components/order-list/order-list-demo.module').then( m => m.OrderListDemoModule ) },
          { path : 'overlaypanel', loadChildren : () => import('./components/overlay-panel/overlay-panel-demo.module').then( m => m.OverlayPanelDemoModule ) },
          { path : 'paginator', loadChildren : () => import('./components/paginator/paginator-demo.module').then( m => m.PaginatorDemoModule ) },
          { path : 'panel', loadChildren : () => import('./components/panel/panel-demo.module').then( m => m.PanelDemoModule ) },
          { path : 'panelmenu', loadChildren : () => import('./components/panel-menu/panel-menu-demo.module').then( m => m.PanelMenuDemoModule ) },
          { path : 'password', loadChildren : () => import('./components/password/password-demo.module').then( m => m.PasswordDemoModule ) },
          { path : 'parserservice', loadChildren : () => import('./service/parser-service/parser-service-demo.module').then( m => m.ParserServiceDemoModule ) },
          { path : 'picklist', loadChildren : () => import('./components/pick-list/pick-list-demo.module').then( m => m.PickListDemoModule ) },
          { path : 'progresssbar', loadChildren : () => import('./components/progress-bar/progress-bar-demo.module').then( m => m.ProgressBarDemoModule ) },
          { path : 'progressspinner', loadChildren : () => import('./components/progress-spinner/progress-spinner-demo.module').then( m => m.ProgressSpinnerDemoModule ) },
          { path : 'widgetloader', loadChildren : () => import('./components/widget-loader/widget-loader-demo.module').then( m => m.WidgetLoaderDemoModule ) },
          { path : 'radiobutton', loadChildren : () => import('./components/radio-button/radio-button-demo.module').then( m => m.RadioButtonDemoModule ) },
          { path : 'resizable', loadChildren : () => import('./components/resizable/resizable-demo.module').then( m => m.ResizableDemoModule ) },
          { path : 'responsive', loadChildren : () => import('./components/responsive/responsive-demo.module').then( m => m.ResponsiveDemoModule ) },
          { path : 'rtl', loadChildren : () => import('./components/rtl/rtl-demo.module').then( m => m.RTLDemoModule ) },
          { path : 'scrollpanel', loadChildren : () => import('./components/scroll-panel/scroll-panel-demo.module').then( m => m.ScrollPanelDemoModule ) },
          { path : 'search', loadChildren : () => import('./components/search/search-demo.module').then( m => m.SearchDemoModule ) },
          { path : 'selectbutton', loadChildren : () => import('./components/select-button/select-button-demo.module').then( m => m.SelectButtonDemoModule ) },
          { path : 'setup', loadChildren : () => import('./components/setup/setup.module').then( m => m.SetupModule ) },
          { path : 'sidebar', loadChildren : () => import('./components/sidebar/sidebar-demo.module').then( m => m.SidebarDemoModule ) },
          { path : 'singleselect', loadChildren : () => import('./components/single-select/single-select-demo.module').then( m => m.SingleSelectDemoModule ) },
          { path : 'slider', loadChildren : () => import('./components/slider/slider-demo.module').then( m => m.SliderDemoModule ) },
          { path : 'spinner', loadChildren : () => import('./components/spinner/spinner-demo.module').then( m => m.SpinnerDemoModule ) },
          { path : 'splitbutton', loadChildren : () => import('./components/split-button/split-button-demo.module').then( m => m.SplitButtonDemoModule ) },
          { path : 'steps', loadChildren : () => import('./components/steps/steps-demo.module').then( m => m.StepsDemoModule ) },
          { path : 'support', loadChildren : () => import('./components/support/support.module').then( m => m.SupportModule ) },
          { path : 'symalertmodal', loadChildren : () => import('./components/sym-alert-modal/sym-alert-modal-demo.module').then( m => m.SymAlertModalDemoModule ) },
          { path : 'symcolumnselector', loadChildren : () => import('./components/sym-column-selector/sym-column-selector-demo.module').then( m => m.SymColumnSelectorDemoModule ) },
          { path : 'symcolors', loadChildren : () => import('./components/sym-colors/sym-colors.module').then( m => m.SymColorsModule ) },
          { path : 'symfileupload', loadChildren : () => import('./components/sym-file-upload/sym-file-upload-demo.module').then( m => m.symFileUploadDemoModule ) },
          { path : 'symicons', loadChildren : () => import('./components/sym-icons/sym-icons.module').then( m => m.SymIconsModule ) },
          { path : 'symiconssvg', loadChildren : () => import('./components/sym-icons-svg/sym-icons-svg.module').then( m => m.SymIconsSvgModule ) },
          { path : 'navaccount', loadChildren : () => import('./components/sym-navaccount/nav-account-demo.module').then( m => m.NavAccountDemoModule ) },
          { path : 'navdomainswitcher', loadChildren : () => import('./components/sym-navdomainswitcher/nav-domain-switcher-demo.module').then( m => m.NavDomainSwitcherDemoModule ) },
          { path : 'navglobal', loadChildren : () => import('./components/sym-nav-global/nav-global-demo.module').then( m => m.NavGlobalDemoModule ) },
          { path : 'symnotification', loadChildren : () => import('./components/sym-notification/sym-notification-demo.module').then( m => m.SymNotificationDemoModule ) },
          { path : 'searchfilter', loadChildren : () => import('./components/search-filter/search-filter-demo.module').then( m => m.SearchFilterDemoModule ) },
          { path : 'symsecondarynavigation', loadChildren : () => import('./components/sym-secondary-navigation/sym-secondary-navigation-demo.module').then( m => m.SymSecondaryNavigationDemoModule ) },
          { path : 'table', loadChildren : () => import('./components/table/table-demo.module').then( m => m.TableDemoModule ) },
          { path : 'tabmenu', loadChildren : () => import('./components/tab-menu/tab-menu-demo.module').then( m => m.TabMenuDemoModule ) },
          { path : 'tabview', loadChildren : () => import('./components/tab-view/tab-view-demo.module').then( m => m.TabViewDemoModule ) },
          { path : 'templates', loadChildren : () => import('./components/templates/templates-demo.module').then( m => m.TemplatesDemoModule ) },
          { path : 'terminal', loadChildren : () => import('./components/terminal/terminal-demo.module').then( m => m.TerminalDemoModule ) },
          { path : 'theme', loadChildren : () => import('./components/theme/theme-demo.module').then( m => m.ThemeDemoModule ) },
          { path : 'theming', loadChildren : () => import('./components/theming/theming.module').then( m => m.ThemingModule ) },
          { path : 'tieredmenu', loadChildren : () => import('./components/tiered-menu/tiered-menu-demo.module').then( m => m.TieredMenuDemoModule ) },
          { path : 'toast', loadChildren : () => import('./components/toast/toast-demo.module').then( m => m.ToastDemoModule ) },
          { path : 'togglebutton', loadChildren : () => import('./components/toggle-button/toggle-button-demo.module').then( m => m.ToggleButtonDemoModule ) },
          { path : 'tooltip', loadChildren : () => import('./components/tooltip/tooltip-demo.module').then( m => m.TooltipDemoModule ) },
          { path : 'tree', loadChildren : () => import('./components/tree/tree-demo.module').then( m => m.TreeDemoModule ) },
          { path : 'treetable', loadChildren : () => import('./components/tree-table/tree-table-demo.module').then( m => m.TreeTableDemoModule ) },
          { path : 'tristatecheckbox', loadChildren : () => import('./components/tri-state-checkbox/tri-state-checkbox-demo.module').then( m => m.TriStateCheckboxDemoModule ) },
          { path : 'typeaheadlist', loadChildren : () => import('./components/typeahead-list/typeahead-list-demo.module').then( m => m.TypeaheadListDemoModule ) },
          { path : 'typography', loadChildren : () => import('./components/typography/typography-demo.module').then( m => m.TypographyDemoModule ) },
          { path : 'validation', loadChildren : () => import('./components/validation/validation-demo.module').then( m => m.ValidationDemoModule ) },
          { path : 'virtualscroller', loadChildren : () => import('./components/virtual-scroller/virtual-scroller-demo.module').then( m => m.VirtualScrollerDemoModule ) },
          { path : 'wizard', loadChildren : () => import('./components/multi-step-form/multi-step-form-demo.module').then( m => m.MultiStepFormDemoModule ) },
          { path : 'symflyout', loadChildren : () => import('./components/sym-flyout/sym-flyout-demo.module').then( m => m.SymFlyoutDemoModule ) },
          { path : 'symrulebuilder', loadChildren : () => import('./components/sym-rule-builder/sym-rule-builder-demo.module').then( m => m.SymRuleBuilderDemoModule ) }
        ]
      },
      { path : 'globalnav', loadChildren : () => import('./components/global-nav/global-nav-demo.module').then( m => m.GlobalNavDemoModule ) },
      { path : 'dashboard-full-width', loadChildren : () => import('./components/dashboard/dashboard-demo.module').then( m => m.DashboardDemoModule ) }

    ], { scrollPositionRestoration : 'enabled' } )
  ],
  exports : [ RouterModule ]
} )
export class AppRoutingModule {
}
