import { Subject, Subscription } from 'rxjs';
import { OnDestroy, Injectable } from '@angular/core';
@Injectable({ providedIn : 'root' })

export class SymSubscriptionService implements OnDestroy {

  private eventChannels                   = {};
  private subscriptions$ : Subscription[] = [];

  public sendEvent<T> ( eventName : string, data : T ) {
    this.registerEvent<T>( eventName );
    ( this.eventChannels[ eventName ] as Subject<T> ).next( data );
  }

  public subscribeEvent<T> ( eventName : string, cb : ( data : T ) => any ) : Subscription {
    this.registerEvent<T>( eventName );
    let subscribe = this.eventChannels[ eventName ].subscribe( cb );
    this.subscriptions$.push( subscribe );
    return subscribe;
  }

  ngOnDestroy () {
    this.subscriptions$.forEach( sub => sub.unsubscribe() );
  }

  private registerEvent<T> ( eventName : string ) {
    if ( !( eventName in this.eventChannels ) ) {
      this.eventChannels[ eventName ] = new Subject<T>();
    }
  }

}
