import { 
    NgModule, 
    Component, 
    ElementRef, 
    OnDestroy,
    Input, 
    OnInit, 
    AfterViewChecked 
}                             from '@angular/core';
import { CommonModule }       from '@angular/common';
import { FullCalendarModule } from '@fullcalendar/angular'; 
import { CalendarOptions }    from '@fullcalendar/angular';
import dayGridPlugin          from '@fullcalendar/daygrid'; 
import interactionPlugin      from '@fullcalendar/interaction'; 

FullCalendarModule.registerPlugins([ 
  dayGridPlugin,
  interactionPlugin
]);

@Component({
    selector: 'p-fullCalendar',
    template: '<div [ngStyle]="style" [class]="styleClass"><full-calendar [options]="calendarOptions"></full-calendar></div>'
})
export class FullCalendar implements OnDestroy, OnInit, AfterViewChecked {

    @Input() style: any;

    @Input() styleClass: string;

    initialized: boolean;
 
    calendarOptions: CalendarOptions;

    config: any;

    _options: any;

    _events: any[];

    constructor(public el: ElementRef) {}

    ngOnInit() { }

    ngAfterViewChecked() { }

    @Input() get events(): any {
        return this._events;
    }

    set events(value: any) {
        this._events = value;
    }

    @Input() get options(): any {
        return this.options;
    }

    set options(value: any) {
        this.calendarOptions = value;
    }

    ngOnDestroy() {
        if (this.calendarOptions) {
            this.calendarOptions = null;
        }
    }
}

@NgModule({
    imports: [CommonModule, FullCalendarModule],
    exports: [FullCalendar, FullCalendarModule],
    declarations: [FullCalendar]
})
export class FullCalendarToolkitModule { }
