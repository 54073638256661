import { Directive, ElementRef, Input, NgModule } from '@angular/core';
import { CommonModule }                           from '@angular/common';

@Directive( {
  selector : '[autoFocus]'
} )
export class AutoFocusDirective {
  @Input()
  public set autoFocus ( applyFocus : boolean ) {
    const elm : HTMLInputElement = this.el.nativeElement;

    if ( applyFocus && elm.focus && elm.select ) {
      let timer = setTimeout( () => {
        elm.focus();
        elm.select();
        clearTimeout( timer );
      }, 500 );
    }
  }

  constructor ( public el : ElementRef ) {
  }
}

@NgModule( {
  imports      : [ CommonModule ],
  exports      : [ AutoFocusDirective ],
  declarations : [ AutoFocusDirective ]
} )
export class AutoFocusModule {
}
