import { NgModule, Component, Input, OnDestroy, ElementRef, ViewChild, AfterViewInit, TemplateRef, ViewContainerRef, EventEmitter, Output } from '@angular/core';
import { CommonModule }                                 from '@angular/common';
import { SymResizableModule } from '../resizable/resizable'
import { SharedModule } from '../common/shared';
import { DomHandler } from '../dom/dom-handler';
import { ResizableItem } from '../common/resizable.interface';
import { fromEvent, interval } from 'rxjs';
import { debounce } from 'rxjs/operators';

@Component( {
  selector  : 'sym-flyout',
  template  : `
  <ng-template #contentRef>
   <div class="sym-flyout"
    [class]="styleClass"
    [ngStyle]="style"
    [style.top]="flyoutTop + 'px'"
    [style.height]="'calc(100% - ' + flyoutTop + 'px)'"
    [ngClass]="{'is--resizing':isResizing, 'has--right': showRight}"
    [hidden] = "isHidden"
    symResizable="right"
    [symResizableWidth]="flyoutWidth"
    [symResizableMinWidth]="flyoutMinWidth"
    [symResizableMaxWidth]="flyoutMaxWidth"
    (onChange)="onResizableChange($event)">
    <div class="sym-flyout__container sym__flex-container">
        <div class="sym-flyout__main">

          <div class="sym-flyout__main-header" #mainHeaderDiv
               [ngClass]="{'is--contentHidden': !showMainHeader}">
             <div *ngIf="showMainHeader" class="sym-flyout__main-header-content">
               <ng-content select="sym-main-header"></ng-content>
             </div>

             <div class="sym-flyout__expand-button is--link" (click)="expand()">
               <svg class="sym-smbl--modal-button">
                 <use xlink:href="#sym-smbl__modal-expand-button"></use>
               </svg>
             </div>
             <div class="sym-flyout__collapse-button is--link" (click)="collapse()">
               <svg class="sym-smbl--modal-button">
                 <use xlink:href="#sym-smbl__modal-contract-button"></use>
               </svg>
             </div>
             <div class="sym-flyout__close-button icon__global-close-x is--link" (click)="close()"></div>
          </div>

          <div class="sym-flyout__main-content" #mainContentDiv>
            <ng-content select="sym-main-content"></ng-content>
          </div>

        </div>

        <div class="sym-flyout__right" *ngIf="showRight"
             [style.width]="rightWidth + 'px'">
          <div class="sym-flyout__right-header" #rightHeaderDiv
               [ngClass]="{'is--contentHidden': !showRightHeader}">
            <div *ngIf="showRightHeader" class="sym-flyout__right-header-content">
              <ng-content select="sym-right-header"></ng-content>
            </div>
            <div class="sym-flyout__close-button icon__global-close-x is--link" (click)="closeRight()"></div>
          </div>
          <div class="sym-flyout__right-content" #rightContentDiv>
            <ng-content select="sym-right-content"></ng-content>
          </div>
        </div>
    </div>
    <div symResizableGrabber
         [grabberTop]="grabberTop"></div>
  </div>
  </ng-template>

`,
providers: [DomHandler]
} )
export class SymFlyout implements AfterViewInit, OnDestroy {

  @Input() style : any;
  @Input() styleClass : string;
  @Input() flyoutTop = 200;
  @Input() defaultWidth = "400px";
  @Input() rightWidth = 200;
  @Input() grabberTop = '400px';
  @Input() minWidth = 200;
  @Input() maxWidth = 800;
  @Input() baseZIndex = 10000;

  // showRightChange and  isHiddenChange are for 2 way binding
  @Output() showRightChange = new EventEmitter<boolean>();
  @Output() isHiddenChange = new EventEmitter<boolean>();

  @Output() onExpand = new EventEmitter<boolean>(); //nothing to pass
  @Output() onCollapse = new EventEmitter<boolean>(); //nothing to pass
  @Output() onClose = new EventEmitter<boolean>(); //nothing to pass
  @Output() onCloseRight = new EventEmitter<boolean>(); //nothing to pass
  @Output() onChange = new EventEmitter<ResizableItem>();

  @ViewChild('mainHeaderDiv', { static: false }) mainHeaderDiv: ElementRef;
  @ViewChild('mainContentDiv', { static: false }) mainContentDiv: ElementRef;

  @ViewChild('rightHeaderDiv', { static: false }) rightHeaderDiv: ElementRef;
  @ViewChild('rightContentDiv', { static: false }) rightContentDiv: ElementRef;

  container: HTMLElement;
  flyoutMaxWidth = 800;
  flyoutMinWidth = 200;
  flyoutWidth = '0px';
  isResizing = false; //used for animation transition (.is--resizing, this is the same class applied by symResizable as well.)
  mask: HTMLElement; //background dark color when showing the expanded screen
  isExpanded = false;
  previousWidth: number; //keep track of this for reopening the flyout
  previousRightShown: boolean; //keep track of this for reopening the flyout
  originalDefaultWidth: number; //calculate the default width when flyout first opens
  appendedNode: HTMLElement; // flyout appended to body
  showMainHeader = true;
  showRightHeader = true;

  previousDimension = { //keep track of these for collapse/expand
    width: "0px", //this is possiblty different from previousWidth as previousDimension.width may be percentage.
    top: "0px",
    height: "0px"
  };

  DEFAULT_TRANSITION_TIME = 500;

  flyoutTimer: ReturnType<typeof setTimeout> = setTimeout(() => '', 100);
  updateHeightTimer: ReturnType<typeof setTimeout> = setTimeout(() => '', 100);

  private _isHidden: boolean = true;

  @Input() get isHidden () : any {
    return this._isHidden;
  }

  set isHidden ( val : any ) {
    this._isHidden =  val;

    setTimeout(() => {
      if (!val) {
        if ( this.previousWidth ) {
          if (this.showRight && !this.previousRightShown) {
            this.flyoutWidth = this.previousWidth + this.rightWidth + 'px';
          }
          else {
            this.flyoutWidth = this.previousWidth + 'px';
            this.flyoutMaxWidth = this.maxWidth;
            this.flyoutMinWidth = this.minWidth;
          }
        }
        else {
          if (this.showRight) {
            this.flyoutWidth = this.originalDefaultWidth + this.rightWidth + 'px';
            this.flyoutMaxWidth = this.maxWidth + this.rightWidth;
            this.flyoutMinWidth = this.minWidth + this.rightWidth;
          }
          else {
            this.flyoutWidth = this.defaultWidth;
            this.flyoutMaxWidth = this.maxWidth;
            this.flyoutMinWidth = this.minWidth;
            if (this.appendedNode && !this.originalDefaultWidth) {
              //NOTE: assume that flyout will not have rightWidth open at first.
              this.setOriginalWidth();
            }
          }
        }
        var adjustedWidth = this.getResizedWidth(this.getWidthInt(this.flyoutWidth));
        if (adjustedWidth) {
          this.flyoutMaxWidth = adjustedWidth;
          if(adjustedWidth < this.flyoutMinWidth) {
            this.flyoutMinWidth = adjustedWidth;
          }
        }
        //update this zindex just in case baseZIndex changed
        if (this.appendedNode) {
          this.appendedNode.style.zIndex = String( this.baseZIndex + 1 );
        }
        this.updateHeight(this.DEFAULT_TRANSITION_TIME);
      }
      else {
        if (this.isExpanded) {
          this.collapse();
        }
        this.previousRightShown = this.showRight;
        this.flyoutWidth = '0px';
      }
    }, 100);

  }

  private _showRight: boolean;
  @Input() get showRight () : any {
    return this._showRight;
  }

  set showRight ( val : any ) {

    // as parent container is using flex, need to adjust the parent container width.
    if (this._showRight !== val) {
      this._showRight = val;
      var newWidth: number;
      if (this.appendedNode) {
        var currentRect = this.appendedNode.getBoundingClientRect();

        if (val) {
          //get current width, then update the width of the flyout
          newWidth = currentRect.width + this.rightWidth;
          this.flyoutMaxWidth = this.maxWidth + this.rightWidth;
          this.flyoutMinWidth = this.minWidth + this.rightWidth;
        }
        else {
          newWidth = currentRect.width - this.rightWidth;
          this.flyoutMaxWidth = this.maxWidth;
          this.flyoutMinWidth = this.minWidth;
        }

        if ( !this.isExpanded) {
          this.isResizing = true;
          // in case the browser is small:
          var resizedWidth = this.getResizedWidth(newWidth);
          if ( resizedWidth ) {
            newWidth =  resizedWidth;
          }
          this.appendedNode.style.width = newWidth + 'px';
          this.previousWidth = newWidth; //keep track of this for reopening the flyout

          setTimeout(() => {
            this.isResizing = false;
          }, this.DEFAULT_TRANSITION_TIME); // adding timeout for animation transition
        }
      }
    }
  }

 @ViewChild("contentRef") contentRef: TemplateRef<any>;

  constructor(public el : ElementRef, public viewContainerRef: ViewContainerRef, public domHandler: DomHandler) {
    this.viewContainerRef = viewContainerRef;

    const resize = fromEvent(window, 'resize');
    const result = resize.pipe(debounce(() => interval(300)));
    result.subscribe(event => {
      this.updateHeight(this.DEFAULT_TRANSITION_TIME);
      this.resizeFlyout(event);
    });
  }

  public scrollTopMainContent () : void {
    this.mainContentDiv.nativeElement.scrollTop = 0;
  }

  public scrollTopRightContent () : void {
    this.rightContentDiv.nativeElement.scrollTop = 0;
  }

  ngAfterViewInit () : void {
    var embeddedViewRef = this.viewContainerRef.createEmbeddedView( this.contentRef );
    embeddedViewRef.detectChanges();

		for ( var node of embeddedViewRef.rootNodes ) {
			this.appendedNode = document.body.appendChild( node );

      if (!this.isHidden) {
        this.setOriginalWidth();
      }
		}
    setTimeout(() => {
      this.showMainHeader = !!this.appendedNode.querySelector('sym-main-header');
      this.showRightHeader = !!this.appendedNode.querySelector('sym-right-header');
    }, 10);
  }

  setOriginalWidth () : void {
    //adding timeout to wait for flyout width to get applied frist in isHidden function.
    setTimeout(() => {
      if (this.appendedNode) {
        let currentRect = this.appendedNode.getBoundingClientRect();
        this.originalDefaultWidth = currentRect.width;
      }
    }, this.DEFAULT_TRANSITION_TIME);
  }

  closeRight () : void {
    this.showRight = false;
    this.showRightChange.emit(this.showRight);
    this.onCloseRight.emit(null);
  }

  close() : void {
    if (this.isExpanded) {
      this.collapse();
    }
    this.isHidden = true;
    this.isHiddenChange.emit(this.isHidden);
    this.flyoutWidth = '0px';
    this.onClose.emit(null);
  }

  expand() : void {
    this.mask = document.createElement('div');
    this.domHandler.addMultipleClasses(this.mask, 'sym-flyout__mask ui-widget-overlay ui-dialog-mask');
    document.body.appendChild(this.mask);

    document.body.classList.add('is--flyout-fullscreen');
    this.mask.style.visibility = 'hidden';
    this.mask.style.height = "100%";
    this.mask.style.visibility = 'visible';

    this.mask.style.zIndex = String( this.baseZIndex );

    this.previousDimension.width = this.appendedNode.style.width;
    this.previousDimension.top = this.appendedNode.style.top;
    this.previousDimension.height = this.appendedNode.style.height;

    this.appendedNode.style.width = "90%";
    this.appendedNode.style.top = '40px';
    this.appendedNode.style.height = "90%";
    this.appendedNode.style.right = '5%';
    this.appendedNode.style.zIndex = String( this.baseZIndex + 1 ); //maybe redundant, but just in case

    this.appendedNode.classList.add("is--expanded");
    this.isExpanded = true;
    this.onExpand.emit(null);
    this.updateHeight(this.DEFAULT_TRANSITION_TIME);

  }

  collapse() {
      document.body.classList.remove('is--flyout-fullscreen');
      this.appendedNode.style.width = this.previousDimension.width;
      this.appendedNode.style.top = this.previousDimension.top;
      this.appendedNode.style.height = this.previousDimension.height;
      this.appendedNode.style.left = "auto";
      this.appendedNode.style.right = "0px";

      this.appendedNode.classList.remove("is--expanded");

      if (this.mask) {
          document.body.removeChild(this.mask);
          this.mask = null;
      }
      this.isExpanded = false;
      this.updateHeight(this.DEFAULT_TRANSITION_TIME);
      this.resizeFlyout();
      this.onCollapse.emit(null);
  }

  getWidthInt (width: string) : number {
    return parseInt(width.replace('px', '').trim());
  }

  onResizableChange (obj: ResizableItem) : void {
    if (obj && obj.width) {
      this.previousWidth = this.getWidthInt(obj.width);
      if (!this.isExpanded) {
        this.flyoutWidth = obj.width;
      }
    }

    this.resizeFlyout();
    this.updateHeight(100);
    this.onChange.emit(obj);
  }

  getResizedWidth (width: number) : number {
    var bodyWidth = document.body.clientWidth;
    if (width > bodyWidth) {
      return bodyWidth - 20;
    }
    return null;
  }

  resizeFlyout (event?: Event) {
    var width = this.getResizedWidth(this.getWidthInt(this.appendedNode.style.width));

    if ( width ) {
      if (this.flyoutTimer) {
        clearTimeout(this.flyoutTimer);
      }

      this.flyoutTimer = setTimeout(() => { //requires timeout for UX to reflect the width change.
        this.previousWidth = width; // width reference used in right panel open/close
        var  newWidth = this.previousWidth + 'px';
        this.previousDimension.width = newWidth; // for collapse

        if (!this.isExpanded) {
          this.flyoutWidth = newWidth;
          this.flyoutMaxWidth = width;
          if(width < this.flyoutMinWidth) {
            this.flyoutMinWidth = width;
          }
        }

      }, 100);
    }
  }

  updateHeight (delay: number) {

    if (this.updateHeightTimer) {
      clearTimeout(this.updateHeightTimer);
    }
    // sometimes CSS animation requires delay
    this.updateHeightTimer = setTimeout(() => {
      if (this.mainHeaderDiv) {
        let mainHeaderDivClientRect = this.mainHeaderDiv.nativeElement.getBoundingClientRect();
        this.mainContentDiv.nativeElement.style.height = "calc(100% - " + mainHeaderDivClientRect.height + 'px)';
      }

      if (this.rightHeaderDiv) {
        let rightHeaderDivClientRect = this.rightHeaderDiv.nativeElement.getBoundingClientRect();
        this.rightContentDiv.nativeElement.style.height = "calc(100% - " + rightHeaderDivClientRect.height + 'px)';
      }
    }, delay);
  }

  ngOnDestroy () : void {
    if ( this.appendedNode ) {
      this.appendedNode.remove();
    }

    clearTimeout(this.flyoutTimer);
    clearTimeout(this.updateHeightTimer);

  }
}

@NgModule( {
  imports      : [ CommonModule, SymResizableModule, SharedModule ],
  exports      : [ SymFlyout, SymResizableModule, SharedModule ],
  declarations : [ SymFlyout ]
} )
export class SymFlyoutModule {
}
