import {SearchToken} from '../../services/parser-service/search-token';
import {ParserToken} from "../../services/parser-service/parser-token";
import {ICustomFilter, IQuickFilter} from "../search-filter/i-search-filter";
import {ValidationErrors, ValidatorFn} from "@angular/forms";

export type ParserFieldName = string;
export type ParserFieldSimpleValue = boolean | Date | number | string;
export type ParserFieldValue = ParserFieldSimpleValue | IParserFieldValueConfiguration;
export type ParserErrorCode = string;
export type ParserMacroQueryFormatter = (value: ParserFieldValue) => string;

export interface IParserFieldValueConfiguration {
  value: ParserFieldSimpleValue;
  displayValue: string;
}

export enum ParserFieldDataType {
  // Make sure all ParserFieldSimpleValue types are represented
  // Use string values for easier debugging

  Boolean = 'boolean',
  Date = 'date',
  Number = 'number',
  String = 'string'
}

export interface IParserFieldConfiguration {
  // ID as an alternate way of providing field uniqueness in special cases (next to the name property)
  // Using an ID allows multiple macros with the same field name without needing to use a macro query formatter function
  id?: string;

  // Field name as understood by the database in use
  name: ParserFieldName;

  // Name to be displayed in the graphical token, if different from field name, or `false` to not set a display name
  displayName?: string | boolean;

  // Alternate names that can be used in place of the `name`--still `name` will be sent to the backend
  // and `displayName` will be shown in the UI
  aliases?: ParserFieldName[];

  // Data type for field values
  type: ParserFieldDataType;

  // Hardcoded set of accepted field values
  values?: ParserFieldValue[];

  // Validator(s) for field values--useful if there are no specific value restrictions
  validators?: ValidatorFn[];
}

export interface IParserFieldLookup {
  [name: string]: IParserFieldConfiguration[];   // TODO: replace key type with ParserFieldName once TypeScript supports it
}

export interface IParserMacroLookup {
  [name: string]: IParserMacroConfiguration[];   // TODO: replace key type with ParserFieldName once TypeScript supports it
}

export interface IParserMacroConfiguration extends IParserFieldConfiguration {
  // Query to expand the macro to, either a string or a function for custom formatting based on value
  query: string | ParserMacroQueryFormatter;
}

export interface IParserConfiguration {
  fields?: IParserFieldConfiguration[];
  macros?: IParserMacroConfiguration[];
}

export interface IParserError {
  code: ParserErrorCode;
  expression?: string;
  validationErrors?: ValidationErrors;
}

export interface IParserMetaData {
  config?: IParserFieldConfiguration[] | IParserMacroConfiguration[];
  matchedPair?: ParserToken;
  pairIsLeft?: boolean;
  token: ParserToken;
}

export enum ParserQueryOperator {
  // Primarily for SearchFilter's custom filters
  // Use string values for easier debugging

  CONTAINS = 'CONTAINS',
  DOES_NOT_EXIST = 'DOES_NOT_EXIST',
  EQUALS = 'EQUALS',
  EXISTS = 'EXISTS',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQUAL_TO = 'GREATER_THAN_EQUAL_TO',
  IS_BETWEEN = 'IS_BETWEEN',
  IS_NOT_BETWEEN = 'IS_NOT_BETWEEN',
  IS_NOT_ONE_OF = 'IS_NOT_ONE_OF',
  IS_ONE_OF = 'IS_ONE_OF',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQUAL_TO = 'LESS_THAN_EQUAL_TO',
  MATCHES = 'MATCHES',
  NOT_EQUALS = 'NOT_EQUALS',
  WILDCARD = 'WILDCARD'
}

export interface IParserAutoCompleteSuggestion {
  actual: string;
  display: string;
}

export interface IFriendlyQueryDiff {
  start: number;
  removeEnd: number;
  removeText?: string;
  insertText?: string;
}

export interface IParser {
  configure(config: IParserConfiguration): this;
  containsFieldName(token: string, name: string): boolean;
  containsFieldValue(token: string, value: string): boolean;
  createTokenString(field: string, value: ParserFieldSimpleValue, operator: ParserQueryOperator, addSpaceAfterSeparator?: boolean): string;
  escapeSpecialChars(s: string): string;
  expandToken(token: SearchToken): string;
  getConfiguration(): IParserConfiguration;
  getCustomFilterData(itemProperties?: Object): ICustomFilter;
  getErrors(): IParserError[];
  getExpandedQuery(): string;
  getFieldNameAndValuePair(token: string, keepQuotes?: boolean): string[];
  getMatchingFields(token: ParserToken | string): IParserAutoCompleteSuggestion[];
  getMatchingValues(token: ParserToken | string): IParserAutoCompleteSuggestion[];
  getNegationPrefix(): string;
  getQuery(): string;
  getQuickFiltersData(): IQuickFilter[];
  getTokens(): SearchToken[];
  filter(data: Object[]): Object[];
  isAllowedField(field: ParserFieldName): boolean;
  isAllowedFieldValue(field: ParserFieldName, value: string): ValidationErrors;
  isValid(): boolean;
  replaceFieldName(token: string, newName: string): string;
  replaceFieldValue(token: string, newValue: string): string;
  replaceMacroQuery(token: string, macro: IParserMacroConfiguration, value: string): string;
  replaceNegation(token: string, newNegation: string, addSpaceAfterNewNegation?: boolean): string;
  setQuery(query: string): this;
  unescapeSpecialChars(s: string): string;
  validate(): IParserError[];
  validateFieldName(field: ParserFieldName, prefix?: string): void;   // Should throw Error if not valid
  validateFieldValue(field: ParserFieldName, value: string, prefix?: string): void;   // Should throw Error if not valid
  validateDisplayName(field: ParserFieldName, displayName: string, prefix?: string, isFallback?: boolean): void;   // Should throw Error if not valid
  validateDisplayValue(field: ParserFieldName, displayValue: string, prefix?: string): void;   // Should throw Error if not valid
}
