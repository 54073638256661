import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  ViewEncapsulation
}                                  from '@angular/core';
import * as d3                     from 'd3';
import { Utils }                   from '../common/utils';
import { CommonModule }            from '@angular/common';
import { ChartUtils }              from '../utils/chart.utils';
import { ChartData, ChartOptions } from '../common/chart.interface';

@Component( {
  selector      : 'sym-chart-radial',
  template      : `
    <div class="sym-chart-radial__container {{styleClass}}">
      <h3 *ngIf="options.l10n.title">
        <span>{{options.l10n.title}}</span>
      </h3>
      <div [hidden]="chartLoading || noData" class="sym-chart-radial__inner-container" [ngClass]="{'sym__flex-container': options.metaData && options.metaData.legendPosition !== 'bottom'}">
        <div class="sym-chart-radial__chart-container">
          <figure [id]="id" class="sym-chart__data"></figure>
        </div>
        <div class="tooltip-container"></div>
        <div class="sym-chart-radial__legends_container">
          <div class="sym-chart-radial__legends">
            <ul class="legends-items-list">
              <li class="legend-item" *ngFor="let legend of data; let i = index; " (click)="chartLegendClick(legend)">
                <span class="legend-icon" [ngStyle]="{'background-color': colors[i]}"></span>
                <span class="legend-count">{{legend.count}}</span>
                <span class="legend-label" [attr.title]="legend.label">{{legend.label}}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="center-content-container" *ngIf="chartLoading">
        <div><svg class="sym-smbl__progress-spinner sym-smbl--medium"><use href="#sym-smbl__progress-spinner"></use></svg></div>
      </div>
      <div class="center-content-container" *ngIf="noData">
        <div class="sym-chart__no-data">
          <span class="error-icon"><svg class="sym-smbl__dashboard-error"><use href="#sym-smbl__dashboard-no-data"></use></svg></span>
          <span>{{ noDataText }}</span>
        </div>
      </div>
    </div>`,
  providers     : [Utils],
  encapsulation : ViewEncapsulation.None
} )
export class SymChartRadial implements OnInit, OnChanges {

  readonly VIEWBOX_LENGTH : number   = 150; // Viewbox width and height (166 was used in donut chart, but 150 works better for this chart.)
  readonly EMPTY_ARC_COLOR : string = '#FFF';
  readonly SUBTITLE_COLOR : string  = '#6E7070';

  @Input() data : ChartData[];
  @Input() options : ChartOptions;
  @Input() styleClass : string;

  @Output() legendClick : EventEmitter<ChartData> = new EventEmitter<ChartData>();
  @Output() arcClick : EventEmitter<ChartData>    = new EventEmitter<ChartData>();

  hostElement : HTMLElement; // Native element hosting the SVG container
  svg; // Top level SVG element
  g; // SVG Group element
  arc; // D3 Arc generator
  totalCount : number; // Total of chart values
  dataInput; //Parsed data for d3
  pie; //Pie
  chartGroup; // contains radial chart paths
  p; // radial chart path
  radius; // radius of the chart
  gap         = 5; // gap width between radial chart
  widthCircle = 4; //width of radial charrt

  noData          = false;
  chartLoading    = true;
  noDataText : string;
  id : string     = `sym-chart-radial-${ Utils.generateUUID() }`;
  s;
  colors : object = {};

  constructor ( private elRef : ElementRef ) {
    this.hostElement = this.elRef.nativeElement;
  }

  ngOnInit () : void {
    this.setChartData();
  }

  ngOnChanges () : void {
    this.setChartData();
  }

  private setChartData () {
    this.noData       = false;
    this.chartLoading = true;
    this.id           = this.options.metaData && this.options.metaData.id ? this.options.metaData.id : this.id;
    this.noDataText   = ( this.options.l10n && this.options.l10n && this.options.l10n.noData ) ? this.options.l10n.noData : 'No Data Available';
    this.removeExistingChartFromParent();

    if ( !this.data ) {
      this.chartLoading = true;
      return;
    }

    if ( this.data.length > 0 ) {
      this.noData       = false;
      this.chartLoading = false;
      this.createChart();
    } else {
      this.noData       = true;
      this.chartLoading = false;
    }
  }

  private createChart () {
    this.setupData();
    this.setChartDimensions();
    this.addGraphicsElement();
    this.addRadialChart();
    this.addCenterText();
  }

  //Function to provide color for the chart rings.
  private getColor = function ( index, pathIndex ) {
    if ( index === 1 ) {
      return this.colors[ pathIndex ];
    } else {
      return this.EMPTY_ARC_COLOR;
    }
  };

  private setupData () {

    this.totalCount = this.data.map( el => el.count )
    .reduce( ( accumulator, currentCount ) => {
      return ( accumulator + currentCount );
    } );

    this.dataInput = this.data.map( ( d, i ) => {
      let val = d.count;
      var dataPercent : number, remPercent : number;

      // This calculation is not scientific,
      // did this through various trial and error by different numbers.
      // When one arc's count is extremely large, other arcs look way too small
      // and doesn't look like a chart, so we needed to compensate by these numbers.
      if ( val > this.totalCount * 0.6 ) {
        dataPercent = ( val * 75 ) / this.totalCount * 2.5;
        remPercent  = ( 75 - dataPercent );
      } else {
        // Calculate point % on the scale of 75% and not 100% as the ring doesn't occupy total 100%.
        // Otherwise, the ring looks pathetic :)
        let multiplier = 1.5;
        if ( val < this.totalCount * 0.4 ) {
          multiplier = 2;
        }
        dataPercent = ( val * 75 / this.totalCount ) * multiplier;
        remPercent  = ( 100 - dataPercent );
      }

      this.colors[ i ] = ChartUtils.convertColor( d.color );

      return [{ key : d.property + '0', value : Math.round( remPercent ), index : i },
        { key : d.property, value : Math.round( dataPercent ), originalData : d, index : i, color : this.colors[ i ] }];
    } );
  }

  private setChartDimensions () {
    this.svg = d3.select( this.hostElement.querySelectorAll( '.sym-chart-radial__chart-container figure' )[ 0 ] ).append( 'svg' )
    .attr( 'width', '100%' )
    .attr( 'height', '100%' )
    .attr( 'viewBox', '0 0 ' + this.VIEWBOX_LENGTH + ' ' + this.VIEWBOX_LENGTH );

    this.radius = Math.min( this.VIEWBOX_LENGTH, this.VIEWBOX_LENGTH ) / 2; // Get total radius in avaialbe space
  }

  private addGraphicsElement () {
    this.chartGroup = this.svg.append( 'g' )
    .attr( 'class', 'sym__chart-group' )
    .attr( 'transform', 'translate(' + this.VIEWBOX_LENGTH / 2 + ',' + this.VIEWBOX_LENGTH / 2 + ')' );


    this.g = this.chartGroup.selectAll( '.sym__chart' )
    .data( this.dataInput )
    .enter()
    .append( 'g' )
    .attr( 'class', 'sym__chart' );
  }


  private addRadialChart () {
    this.arc = d3.arc();

    this.pie = d3.pie()
    .sort( null )
    .value( ( d : any ) => d.value );

    const tooltipContainer = d3.select( this.hostElement.querySelectorAll( '.tooltip-container' )[ 0 ] )
    .append( 'div' )
    .attr( 'class', 'pie-tooltip sym-chart-radial__tooltip hidden' );
    const parentWidth      = 200;
    const parentHeight     = 120;

    this.p = this.g.selectAll( 'path' )
    .attr( 'class', 'sym__path' )
    .data( ( d, i ) => {
      return this.pie( d );
    } )
    .enter()
    .append( 'path' )
    .attr( 'fill', ( dat, i ) => {
      return ( this.getColor( i, dat.data.index ) );
    } )
    .attr( 'opacity', 0 )
    .attr( 'd', ( datum, i, j ) => { //when I use d here (instead of datum), d doesn't have data I need
      return this.arc.innerRadius( () => {
        return this.radius - ( ( datum.data.index ) * ( this.gap + this.widthCircle ) ) - this.widthCircle;
      } )
      .outerRadius( () => {
        return this.radius - ( ( datum.data.index ) * ( this.gap + this.widthCircle ) );
      } )
      .cornerRadius( 15 )( datum );

    } )
    .style( 'cursor', 'pointer' )
    .on( 'click', ( data ) => {
      if ( data.data.originalData ) {
        this.arcClick.emit( data.data.originalData );
      }
    } )
    .on( 'mouseover', function ( d ) {
      if ( d.data.originalData ) {
        const cord = d3.mouse( this );

        tooltipContainer
        .style( 'left', cord[ 0 ] + parentWidth / 2 + 'px' )
        .style( 'top', cord[ 1 ] + parentHeight / 2 + 'px' )
        .style( 'opacity', 1 )
        .html( `<div class='tooltip-content'>
                              <span class='tooltip_count'
                                 style='color: ${ d.data.color }'><strong>${ d.data.originalData.label }</strong>
                                  <br/>${ d.data.originalData.count }
                              </span>
                        </div>` );
      }

    } )
    .on( 'mousemove', function ( d ) {
      if ( !d.data.originalData ) {
        return;
      }
      const cord = d3.mouse( this );
      tooltipContainer
      .style( 'left', cord[ 0 ] + parentWidth / 2 + 'px' )
      .style( 'top', cord[ 1 ] + parentHeight / 2 + 'px' );
    } )
    .on( 'mouseout', ( d ) => {
      if ( !d.data.originalData ) {
        return;
      }
      // Hide the tooltip
      tooltipContainer
      .style( 'opacity', 0 )
      .style( 'left', '0px' )
      .style( 'top', '0px' );
    } );

    this.p.transition()
    .delay( 300 )
    .ease( d3.easeQuad )
    .attrTween( 'opacity', function ( d, i ) {
      return d3.interpolate( 0, 1 );
    } );
  }

  private addCenterText () {
    let centerTitle    = this.options && this.options.l10n && this.options.l10n.centerTitle ? this.options.l10n.centerTitle : this.totalCount;
    let centerSubtitle = this.options && this.options.l10n && this.options.l10n.centerSubtitle ? this.options.l10n.centerSubtitle : '';

    // add center text
    let centerTextContainer = this.chartGroup.append( 'g' )
    .attr( 'class', 'sym-chart-radial__center-text' );
    centerTextContainer.append( 'text' )
    .text( centerTitle );
    let centerLabelContainer = this.chartGroup.append( 'g' )
    .attr( 'class', 'sym-chart-radial__center-label' )
    .attr( 'transform', 'translate(0, 20)' );
    centerLabelContainer.append( 'text' )
    .text( centerSubtitle )
    .style( 'fill', this.SUBTITLE_COLOR );
  }

  private removeExistingChartFromParent () {
    d3.select( this.hostElement ).select( 'svg' ).remove();
  }

  public chartLegendClick = ( data : ChartData ) => {
    this.legendClick.emit( data );
  };
}

@NgModule( {
  imports      : [CommonModule],
  exports      : [SymChartRadial],
  declarations : [SymChartRadial]
} )
export class SymChartRadialModule {
}
