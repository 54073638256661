import {
    AfterContentInit,
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ContentChildren,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    NgModule,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    Renderer2,
    TemplateRef,
    ViewChild,
    NgZone,
    Host,
    SkipSelf
} from '@angular/core';
import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { SelectItem } from '../common/select-item';
import { DomHandler } from '../dom/dom-handler';
import { ObjectUtils } from '../utils/object-utils';
import { Footer, PrimeTemplate, SharedModule } from '../common/shared';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SymInlineEditModule } from '../inline-edit/inline-edit';
import { InlineEditService }        from '../inline-edit/inline-edit.service';
import { MenuModule }         from '../menu/menu';

export const MULTISELECT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultiSelect),
    multi: true
};

@Component({
    selector: 'p-multiSelect',
    template: `
        <div #container
             [ngClass]="{'ui-multiselect ui-widget ui-state-default ui-corner-all':true,'ui-multiselect-open':overlayVisible,'ui-state-focus':focus,'ui-state-disabled': disabled}"
             [ngStyle]="style" [class]="styleClass"
             (click)="onMouseclick($event,in)">
            <div class="ui-helper-hidden-accessible">
                <input #in type="text" readonly="readonly" [attr.id]="inputId" [attr.name]="name"
                       (focus)="onInputFocus($event)" (blur)="onInputBlur($event)"
                       [disabled]="disabled" [attr.tabindex]="tabindex"
                       (keydown)="onKeydown($event)">
            </div>
            <div class="ui-multiselect-label-container" [title]="valuesAsString">
                <label class="ui-multiselect-label ui-corner-all">
                    <ng-container *ngIf="!selectedItemsTemplate">{{valuesAsString}}</ng-container>
                    <ng-container
                            *ngTemplateOutlet="selectedItemsTemplate; context: {$implicit: value}"></ng-container>
                </label>
            </div>
            <div [ngClass]="{'ui-multiselect-trigger ui-state-default ui-corner-right is--link':true}">
                <div class="ui-multiselect-trigger-icon">
                  <sym-icon *ngIf="!filterValue && !overlayVisible"
                   [styleClass]="dropdownIcon"
                   svgId="sym-smbl__arrow-chevron"></sym-icon>
                  <sym-icon *ngIf="overlayVisible"
                   [styleClass]="dropdownUpIcon"
                   svgId="sym-smbl__arrow-chevron"></sym-icon>
                </div>
            </div>
            <div *ngIf="overlayVisible"
                 [ngClass]="['ui-multiselect-panel ui-widget ui-widget-content ui-corner-all ui-shadow']"
                 [@overlayAnimation]="{value: 'visible', params: {showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions}}"
                 (@overlayAnimation.start)="onOverlayAnimationStart($event)"
                 [ngStyle]="panelStyle" [class]="panelStyleClass" (click)="panelClick=true">
                <div class="ui-widget-header ui-corner-all ui-multiselect-header ui-helper-clearfix"
                     [ngClass]="{'ui-multiselect-header-no-toggleall': !showToggleAll}"
                     *ngIf="showHeader">
                    <div class="ui-chkbox ui-widget" *ngIf="showToggleAll && !selectionLimit">
                        <div class="ui-helper-hidden-accessible">
                            <input type="checkbox" readonly="readonly" [checked]="isAllChecked()"
                                   (focus)="onHeaderCheckboxFocus()" (blur)="onHeaderCheckboxBlur()"
                                   (keydown.space)="toggleAll($event)">
                        </div>
                        <div class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"
                             [ngClass]="{'ui-state-active':isAllChecked(), 'ui-state-focus': headerCheckboxFocus}"
                             (click)="toggleAll($event)">
                            <span class="ui-chkbox-icon ui-clickable"
                                  [ngClass]="{'pi pi-check':isAllChecked()}"></span>
                        </div>
                    </div>
                    <div class="ui-multiselect-filter-container" *ngIf="filter">
                        <sym-input-text-helper [value]="filterValue" (onClear)="clearSearch()" class="ui-multiselect-filter-search">
                          <input #filterInput type="text" role="textbox" [value]="filterValue||''"
                                 (input)="onFilter()"
                                 class="ui-inputtext ui-widget ui-state-default ui-corner-all"
                                 [attr.placeholder]="filterPlaceHolder">
                          <sym-icon *ngIf="!filterValue" svgClass="ui-multiselect-filter-icon sym-smbl__action-search-input sym-smbl--black-40" svgId="sym-smbl__action-search-input"></sym-icon>
                        </sym-input-text-helper>
                    </div>
                    <a class="ui-multiselect-close ui-corner-all" tabindex="0"
                       (click)="close($event)" (keydown.enter)="close($event)">
                        <span class="icon__global-close-x sym__icon"></span>
                    </a>
                    <ng-content select="p-header"></ng-content>
                </div>
                <div class="ui-multiselect-items-wrapper"
                     [style.max-height]="virtualScroll ? 'auto' : (scrollHeight||'auto')">

                    <ul class="ui-multiselect-items ui-multiselect-list ui-widget-content ui-widget ui-corner-all ui-helper-reset">
                        <ng-container *ngIf="!virtualScroll; else virtualScrollList">
                            <ng-template ngFor let-option let-i="index" [ngForOf]="options">
                                <p-multiSelectItem [option]="option"
                                                   [index]="i"
                                                   [disabled]="option.disabled"
                                                   [selected]="isSelected(option.value)"
                                                   (onClick)="onOptionClick($event)"
                                                   (onKeydown)="onOptionKeydown($event)"
                                                   [selectionLimit]="selectionLimit"
                                                   [maxSelectionLimitReached]="maxSelectionLimitReached"
                                                   [visible]="isItemVisible(option)"
                                                   [template]="itemTemplate"></p-multiSelectItem>
                            </ng-template>
                        </ng-container>
                        <ng-template #virtualScrollList>
                            <cdk-virtual-scroll-viewport #viewport
                                                         [ngStyle]="{'height': scrollHeight}"
                                                         [itemSize]="itemSize"
                                                         *ngIf="virtualScroll">
                                <ng-container
                                        *cdkVirtualFor="let option of options; let i = index; let c = count; let f = first; let l = last; let e = even; let o = odd">
                                    <p-multiSelectItem [option]="option"
                                                       [index]="i"
                                                       [disabled]="option.disabled"
                                                       [selected]="isSelected(option.value)"
                                                       (onClick)="onOptionClick($event)"
                                                       (onKeydown)="onOptionKeydown($event)"
                                                       [selectionLimit]="selectionLimit"
                                                       [maxSelectionLimitReached]="maxSelectionLimitReached"
                                                       [visible]="isItemVisible(option)"
                                                       [template]="itemTemplate"
                                                       [itemSize]="itemSize"></p-multiSelectItem>
                                </ng-container>
                            </cdk-virtual-scroll-viewport>
                        </ng-template>
                    </ul>
                </div>
                <div class="ui-multiselect-footer ui-widget-content" *ngIf="footerFacet">
                    <ng-content select="p-footer"></ng-content>
                </div>
            </div>
        </div>

    `,
    animations: [
        trigger('overlayAnimation', [
            state('void', style({
                transform: 'translateY(5%)',
                opacity: 0
            })),
            state('visible', style({
                transform: 'translateY(0)',
                opacity: 1
            })),
            transition('void => visible', animate('{{showTransitionParams}}')),
            transition('visible => void', animate('{{hideTransitionParams}}'))
        ])
    ],
    host: {
        '[class.ui-inputwrapper-filled]': 'filled',
        '[class.ui-inputwrapper-focus]': 'focus'
    },
    providers: [DomHandler, ObjectUtils, MULTISELECT_VALUE_ACCESSOR]
})
export class MultiSelect implements OnInit, AfterViewInit, AfterContentInit, AfterViewChecked, OnDestroy, ControlValueAccessor {

    @Input() scrollHeight: string = '200px';

    _defaultLabel: string = 'Choose';

    @Input() set defaultLabel(val: string) {
        this._defaultLabel = val;
        this.updateLabel();
    }

    get defaultLabel(): string {
        return this._defaultLabel;
    }

    @Input() style: any;

    @Input() styleClass: string;

    @Input() panelStyle: any;

    @Input() panelStyleClass: string;

    @Input() inputId: string;

    @Input() disabled: boolean;

    @Input() readonly: boolean;

    @Input() filter: boolean = true;

    @Input() filterPlaceHolder: string;

    @Input() overlayVisible: boolean;

    @Input() tabindex: number;

    @Input() appendTo: any;

    @Input() dataKey: string;

    @Input() name: string;

    @Input() displaySelectedLabel: boolean = true;

    @Input() maxSelectedLabels: number = 3;

    @Input() selectionLimit: number;

    @Input() selectionMinimum: number = 1;

    @Input() selectedItemsLabel: string = '{0} items selected';

    @Input() showToggleAll: boolean = false;

    @Input() resetFilterOnHide: boolean = false;

    @Input() dropdownIcon: string = 'sym-smbl--arrow-small sym-smbl--black-80';

    @Input() dropdownUpIcon: string = 'sym-smbl--arrow-small sym-smbl--black-80 sym-smbl--arrow-up';

    @Input() optionLabel: string;

    @Input() showHeader: boolean = true;

    @Input() autoZIndex: boolean = true;

    @Input() baseZIndex: number = 0;

    @Input() filterBy: string = 'label';

    @Input() virtualScroll: boolean;

    @Input() itemSize: number;

    @Input() showTransitionOptions: string = '225ms ease-out';

    @Input() hideTransitionOptions: string = '195ms ease-in';


    @Input() offset = { top : 12 };

    @Input() originalSelections: any[]; //developer defined, so can't add type

    @ViewChild('container', { static: false }) containerViewChild: ElementRef;

    @ViewChild('filterInput', { static: false }) filterInputChild: ElementRef;

    @ContentChild(Footer, { static: false }) footerFacet;

    @ContentChildren(PrimeTemplate) templates: QueryList<any>;

    @Output() onChange: EventEmitter<any> = new EventEmitter();

    @Output() onFocus: EventEmitter<any> = new EventEmitter();

    @Output() onBlur: EventEmitter<any> = new EventEmitter();

    @Output() onPanelShow: EventEmitter<any> = new EventEmitter();

    @Output() onPanelHide: EventEmitter<any> = new EventEmitter();

    @Output() onReset: EventEmitter<boolean> = new EventEmitter();

    showResetButton = false;
    showCompileButton = false;
    showUndoButton = false;

    previousOptions: any[]; //developer defined, so can't add type
    previousSelections: any[]; //developer defined, so can't add type

    undoTimeout: ReturnType<typeof setTimeout> = setTimeout(() => '', 500);

    public value: any[];

    public onModelChange: Function = () => {
    };

    public onModelTouched: Function = () => {
    };

    overlay: HTMLDivElement;

    public valuesAsString: string;

    public focus: boolean;

    filled: boolean;

    public documentClickListener: any;

    public selfClick: boolean;

    public panelClick: boolean;

    public filterValue: string;

    public visibleOptions: SelectItem[];

    public filtered: boolean;

    public itemTemplate: TemplateRef<any>;

    public selectedItemsTemplate: TemplateRef<any>;

    public headerCheckboxFocus: boolean;

    _options: any[]; //developer defined, so can't add type

    _originalOptions: any[]; //developer defined, so can't add type

    maxSelectionLimitReached: boolean;

    documentResizeListener: any;

    draggedRowIndex: number;

    droppedRowIndex: number;

    previousDraggedIndex: number;

    rowDragging: boolean;

    dropPosition: number;

    originalDisabledKeys: string[] = [];

    constructor(public el: ElementRef, public domHandler: DomHandler, public renderer: Renderer2, public objectUtils: ObjectUtils, private cd: ChangeDetectorRef, public zone: NgZone, private inlineEditService: InlineEditService) {

    }

    @Input() get options(): any[] {
        return this._options;
    }

    set options(val: any[]) { //developer defined, so can't add type
        if (!val) {
          return;
        }
        let opts = this.optionLabel ? this.objectUtils.generateSelectItems(val, this.optionLabel, this.dataKey) : val;
        val.forEach((v, index) => {
           if (v.disabled) {
             opts[index].disabled = v.disabled;
           }
        });
        this._options = opts;
        this.updateLabel();
    }


    ngOnInit() {
        this.updateLabel();
    }

    ngAfterContentInit() {
        this.templates.forEach((item) => {
            switch (item.getType()) {
                case 'item':
                    this.itemTemplate = item.template;
                    break;

                case 'selectedItems':
                    this.selectedItemsTemplate = item.template;
                    break;

                default:
                    this.itemTemplate = item.template;
                    break;
            }
        });
    }

    ngAfterViewInit() {
        if (this.overlayVisible) {
            this.show();
        }
    }

    ngAfterViewChecked() {
        if (this.filtered) {
            this.alignOverlay();

            this.filtered = false;
        }
    }

    writeValue(value: any): void {
        this.value = value;
        this.updateLabel();
        this.updateFilledState();
        this.cd.markForCheck();
    }

    updateFilledState() {
        this.filled = (this.valuesAsString != null && this.valuesAsString.length > 0);
    }

    registerOnChange(fn: Function): void {
        this.onModelChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onModelTouched = fn;
    }

    setDisabledState(val: boolean): void {
        this.disabled = val;
    }

    onOptionClick(event) {

        let option = event.option;

        if (option.disabled) {
            return;
        }

        if (this.undoTimeout) {
          clearTimeout(this.undoTimeout);
          this.showUndoButton = false;
        }

        const value = option.value;
        let selectionIndex = this.findSelectionIndex(value);
        if (!this.value) {
          this.value = [];
        }

        // onDeselect
        if (selectionIndex != -1) {
            if (this.selectionLimit !== 1) {
                this.value = this.value.filter( (val, i) => i != selectionIndex );

                if (this.selectionLimit) {
                    this.maxSelectionLimitReached = false;
                }

                if (this.value.length <= this.selectionMinimum) {
                    for ( var i = 0; i < this.options.length; i += 1 ) {
                        if ( this.value[0] && this.options[i].label === this.value[0].header ) {
                            this.options[i].disabled = true;
                            continue;
                        }
                    }
                }
            }
            // Else user clicked on already selected radio button, no changes.

        // onSelect
        } else {
            if (this.selectionLimit === 1) {
                // Simulate radio button behavior: clear selection, add new selection per logic below
                this.value = []
            }

            if (!this.selectionLimit || (this.value.length < this.selectionLimit)) {
              this.value = [...this.value || [], value];
            }

            if (this.selectionLimit && this.value.length === this.selectionLimit) {
                this.maxSelectionLimitReached = true;
            }

            if (this.value.length > this.selectionMinimum) {
                for ( var i = 0; i < this.options.length; i += 1 ) {
                   if (this.dataKey && this.options[i].key && this.originalDisabledKeys.indexOf(this.options[i].key) > -1 ||
                      !this.dataKey &&  this.originalDisabledKeys.indexOf(this.options[i].value) > -1) {
                     this.options[i].disabled = true;
                   }

                   else {
                     this.options[i].disabled = false;
                   }
                }
            }
        }

        this.onModelChange(this.value);
        this.onChange.emit({
            originalEvent: event.originalEvent,
            value: this.value,
            itemValue: value
        });
        this.updateLabel();
        this.updateFilledState();

        if (this.selectionLimit === 1) {
            // Without timeout, selector popup closes but comes back up immediately, weird.
            setTimeout(() => {
                this.hide();   // For single selection mode only: close on selection
            });
        }
    }

    isSelected(value) {
      return this.findSelectionIndex(value) != -1;
    }

    getSelectedOrder(value) {
      return this.findSelectionIndex(value);
    }

    findSelectionIndex(val: any): number {
        let index = -1;

        if (this.value) {
            for (let i = 0; i < this.value.length; i++) {
                if (this.objectUtils.equals(this.value[i], val, this.dataKey)) {
                    index = i;
                    break;
                }
            }
        }

        return index;
    }


    findOptionsIndex(val: any): number {
        let index = -1;

        if (this.options) {
            for (let i = 0; i < this.options.length; i++) {
                if (this.objectUtils.equals(this.options[i].value, val, this.dataKey)) {
                    index = i;
                    break;
                }
            }
        }

        return index;
    }
    toggleAll(event) {
        if (this.isAllChecked()) {
            this.value = [];
        } else {
            let opts = this.getVisibleOptions();
            if (opts) {
                this.value = [];
                for (let i = 0; i < opts.length; i++) {
                    let option = opts[i];

                    if (!option.disabled) {
                        this.value.push(opts[i].value);
                    }
                }
            }
        }

        this.onModelChange(this.value);
        this.onChange.emit({ originalEvent: event, value: this.value });
        this.updateLabel();
    }

    isAllChecked() {
        if (this.filterValue && this.filterValue.trim().length) {
            return this.value && this.visibleOptions && this.visibleOptions.length && (this.value.length == this.visibleOptions.length);
        } else {
            let optionCount = this.getEnabledOptionCount();

            return this.value && this.options && (this.value.length > 0 && this.value.length == optionCount);
        }
    }

    getEnabledOptionCount(): number {
        if (this.options) {
            let count = 0;
            for (let opt of this.options) {
                if (!opt.disabled) {
                    count++;
                }
            }

            return count;
        } else {
            return 0;
        }
    }

    show() {
        if (!this.overlayVisible) {
            this.overlayVisible = true;
        }

        if (this.filter) {
            setTimeout(() => {
                if (this.filterInputChild != undefined) {
                    this.filterInputChild.nativeElement.focus();
                }
            }, 200);
        }
        this.bindDocumentClickListener();

    }

    onOverlayAnimationStart(event: AnimationEvent) {
        switch (event.toState) {
            case 'visible':
                this.overlay = event.element;
                this.appendOverlay();
                if (this.autoZIndex) {
                    this.overlay.style.zIndex = String(this.baseZIndex + (++DomHandler.zindex));
                }
                this.alignOverlay();
                this.bindDocumentClickListener();
                this.bindDocumentResizeListener();
                this.onPanelShow.emit();
                break;

            case 'void':
                this.onOverlayHide();
                break;
        }
    }

    appendOverlay() {
        if (this.appendTo) {
            if (this.appendTo === 'body')
                document.body.appendChild(this.overlay);
            else
                this.domHandler.appendChild(this.overlay, this.appendTo);

            this.overlay.style.minWidth = this.domHandler.getWidth(this.containerViewChild.nativeElement) + 'px';
        }
    }

    restoreOverlayAppend() {
        if (this.overlay && this.appendTo) {
            this.el.nativeElement.appendChild(this.overlay);
        }
    }

    alignOverlay() {
        if (this.overlay) {
            if (this.appendTo)
                this.domHandler.absolutePosition(this.overlay, this.containerViewChild.nativeElement);
            else
                this.domHandler.relativePosition(this.overlay, this.containerViewChild.nativeElement, this.offset);
        }
    }

    hide() {
        this.overlayVisible = false;
        this.unbindDocumentClickListener();
        if (this.resetFilterOnHide) {
            this.filterInputChild.nativeElement.value = '';
            this.onFilter();
        }
        this.onPanelHide.emit();
    }

    close(event) {
        this.hide();
        event.preventDefault();
        event.stopPropagation();
    }

    onMouseclick(event, input) {
        if (this.disabled || this.readonly) {
            return;
        }

        if (!this.panelClick) {
            if (this.overlayVisible) {
                this.hide();
            } else {
                input.focus();
                this.show();
            }
        }

        this.selfClick = true;
    }

    onInputFocus(event) {
        this.focus = true;
        this.onFocus.emit({ originalEvent: event });
    }

    onInputBlur(event) {
        this.focus = false;
        this.onBlur.emit({ originalEvent: event });
        this.onModelTouched();
    }

    onOptionKeydown(event) {
        if (this.readonly) {
            return;
        }

        let item = <HTMLLIElement>event.originalEvent.currentTarget;

        switch (event.which) {
            //down
            case 40:
                var nextItem = this.findNextItem(item);
                if (nextItem) {
                    nextItem.focus();
                }

                event.preventDefault();
                break;

            //up
            case 38:
                var prevItem = this.findPrevItem(item);
                if (prevItem) {
                    prevItem.focus();
                }

                event.preventDefault();
                break;

            //enter
            case 13:
                this.onOptionClick(event);
                event.preventDefault();
                break;
        }
    }

    findNextItem(item) {
        let nextItem = item.nextElementSibling;

        if (nextItem)
            return this.domHandler.hasClass(nextItem, 'ui-state-disabled') || this.domHandler.isHidden(nextItem) ? this.findNextItem(nextItem) : nextItem;
        else
            return null;
    }

    findPrevItem(item) {
        let prevItem = item.previousElementSibling;

        if (prevItem)
            return this.domHandler.hasClass(prevItem, 'ui-state-disabled') || this.domHandler.isHidden(prevItem) ? this.findPrevItem(prevItem) : prevItem;
        else
            return null;
    }

    onKeydown(event: KeyboardEvent) {
        switch (event.which) {
            //down
            case 40:
                if (!this.overlayVisible && event.altKey) {
                    this.show();
                }
                break;

            //space
            case 32:
                if (!this.overlayVisible) {
                    this.show();
                    event.preventDefault();
                }
                break;

            //escape
            case 27:
                this.hide();
                break;
        }
    }

    updateLabel() {
        if (this.value && this.options && this.value.length && this.displaySelectedLabel) {
            let label = '';
            for (let i = 0; i < this.value.length; i++) {
                let itemLabel = this.findLabelByValue(this.value[i]);
                if (itemLabel) {
                    if (label.length > 0) {
                        label = label + ', ';
                    }
                    label = label + itemLabel;
                }
            }

            if (this.value.length <= this.maxSelectedLabels) {
                this.valuesAsString = label;
            } else {
                let pattern = /{(.*?)}/;
                if (pattern.test(this.selectedItemsLabel)) {
                    this.valuesAsString = this.selectedItemsLabel.replace(this.selectedItemsLabel.match(pattern)[0], this.value.length + '');
                }
            }
        } else {
            this.valuesAsString = this.defaultLabel;
        }
    }

    findLabelByValue(val: any): string {
        let label = null;
        for (let i = 0; i < this.options.length; i++) {
            let option = this.options[i];
            if (val == null && option.value == null || this.objectUtils.equals(val, option.value, this.dataKey)) {
                label = option.label;
                break;
            }
        }
        return label;
    }

    onFilter() {
        let inputValue = this.filterInputChild.nativeElement.value;
        if (inputValue && inputValue.length) {
            this.filterValue = inputValue;
            this.visibleOptions = [];
            this.activateFilter();
        } else {
            this.filterValue = null;
            this.visibleOptions = this.options;
            this.filtered = false;
        }
    }

    activateFilter() {
        if (this.options && this.options.length) {
            let searchFields: string[] = this.filterBy.split(',');
            this.visibleOptions = this.objectUtils.filter(this.options, searchFields, this.filterValue);
            this.filtered = true;
        }
    }

    isItemVisible(option: SelectItem): boolean {
        if (this.filterValue && this.filterValue.trim().length) {
            for (let i = 0; i < this.visibleOptions.length; i++) {
                if (this.visibleOptions[i].value == option.value) {
                    return true;
                }
            }
        } else {
            return true;
        }
    }

    getVisibleOptions(): SelectItem[] {
        if (this.visibleOptions && this.visibleOptions.length) {
            return this.visibleOptions;
        } else {
            return this.options;
        }
    }

    onHeaderCheckboxFocus() {
        this.headerCheckboxFocus = true;
    }

    onHeaderCheckboxBlur() {
        this.headerCheckboxFocus = false;
    }

    bindDocumentClickListener() {
        if (!this.documentClickListener) {
            this.documentClickListener = this.renderer.listen('document', 'click', () => {
                if (!this.selfClick && !this.panelClick && this.overlayVisible) {
                    this.hide();
                }

                this.selfClick = false;
                this.panelClick = false;
                this.cd.markForCheck();
            });
        }
    }

    unbindDocumentClickListener() {
        if (this.documentClickListener) {
            this.documentClickListener();
            this.documentClickListener = null;
        }
    }

    bindDocumentResizeListener() {
        this.documentResizeListener = this.onWindowResize.bind(this);
        window.addEventListener('resize', this.documentResizeListener);
    }

    unbindDocumentResizeListener() {
        if (this.documentResizeListener) {
            window.removeEventListener('resize', this.documentResizeListener);
            this.documentResizeListener = null;
        }
    }

    onWindowResize() {
        this.hide();
    }

    onOverlayHide() {
        this.unbindDocumentClickListener();
        this.unbindDocumentResizeListener();
        this.overlay = null;
    }

    ngOnDestroy() {
        this.restoreOverlayAppend();
        this.onOverlayHide();
    }

    hideUndoButton() {
      if (this.undoTimeout) {
        clearTimeout(this.undoTimeout);
      }
      this.showUndoButton = false;
    }

    showMoreMenu(event, menuReference): void {
          menuReference.toggle(event);
    }

    clearSearch () {
      this.filterValue = "";
    }
}


@Component({
    selector: 'p-multiSelectItem',
    template: `
        <li class="ui-multiselect-item ui-corner-all"
            (keydown)="onOptionKeydown($event)"
            [attr.tabindex]="option.disabled ? null : '0'" [ngStyle]="{'height': itemSize + 'px'}"
            [ngClass]="{'ui-state-hidden': !visible, 'ui-state-highlight': selected,
            'ui-state-disabled': (option.disabled || ( selectionLimit > 1 && maxSelectionLimitReached && !selected)) }">
            <div class="ui-multiselect-item__overlay"></div>
            <div class="ui-multiselect-item__container" (click)="onOptionClick($event)">
              <div class="ui-chkbox ui-widget">
                  <div class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"
                       [ngClass]="{'ui-state-active': selected}">
                       <sym-icon svgClass="sym-smbl__form-radio" [styleClass]="{'sym-smbl--checked':selected,'sym-smbl--disabled':disabled }"
                          styleId="sym-smbl__form-radio" *ngIf="selectionLimit === 1"></sym-icon>
                       <sym-icon svgClass="sym-smbl__form-checkbox" [styleClass]="{'sym-smbl--checked':selected,'sym-smbl--disabled':disabled }"
                          styleId="sym-smbl__form-checkbox" *ngIf="!selectionLimit || selectionLimit > 1"></sym-icon>
                  </div>
              </div>

              <label class="is--ellipsis" *ngIf="!template">{{option.label}}</label>
              <ng-container *ngTemplateOutlet="template; context: {$implicit: option}"></ng-container>
            </div>
        </li>
    `
})
export class MultiSelectItem implements OnInit, OnDestroy {

    @Input() option: SelectItem;

    @Input() selected: boolean;

    @Input() disabled: boolean;

    @Input() visible: boolean;

    @Input() itemSize: number;

    @Input() template: TemplateRef<any>;

    @Input() index: number;

    @Input() selectionLimit: number;

    @Input() maxSelectionLimitReached: boolean;

    @Output() onClick: EventEmitter<any> = new EventEmitter();

    @Output() onKeydown: EventEmitter<any> = new EventEmitter();

    constructor(public el: ElementRef, public zone: NgZone, public domHandler: DomHandler, public objectUtils: ObjectUtils, @Host() @SkipSelf() private multiSelect : MultiSelect) {

    }

    ngOnInit() {

    }

    onOptionClick(event: Event) {
        this.onClick.emit({
            originalEvent: event,
            option: this.option
        });
    }

    onOptionKeydown(event: Event) {
        this.onKeydown.emit({
            originalEvent: event,
            option: this.option
        });
    }

    ngOnDestroy() {
    }
}


@NgModule({
    imports: [CommonModule, SharedModule, ScrollingModule, SymInlineEditModule, MenuModule ],
    exports: [MultiSelect, SharedModule, ScrollingModule, MenuModule],
    declarations: [MultiSelect, MultiSelectItem]
})
export class MultiSelectModule {
}
