import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {DropdownModule} from '../dropdown/dropdown';
import {ButtonModule} from '../button/button';
import {SharedModule} from '../common/shared';
import {Utils} from '../common/utils';
import {TypeaheadList, TypeaheadListModule} from '../typeahead-list/typeahead-list';
import {InputTextModule} from '../input-text/input-text';
import {TabMenuModule} from '../tab-menu/tab-menu';
import {ICustomFilter, IQuickFilter, IQuickFilterItem} from './i-search-filter';
import {SearchToken, SearchTokenType} from "../../services/parser-service/search-token";
import {SearchModule} from "../search/search";
import {ISearchModel, QueryChangeValue} from "../search/i-search-model";
import {SymSubscriptionService} from '../../services/subscription.service';
import {SearchFilterQuickQueriesModule} from './search-filter-quick-queries';
import {
  IParser,
  IParserConfiguration,
  IParserError,
  ParserFieldSimpleValue,
  ParserQueryOperator
} from "../common/parser";
import {OverlayPanelModule} from '../overlay-panel/overlay-panel';
import {DisplayStringParserService} from "../../services/parser-service/display-string-parser.service";
import {Subscription} from 'rxjs';
import {QuickFiltersPipeModule} from './search-filter-quick-queries.pipe'

@Component( {
  selector : 'sym-search-filter',
  template : `
    <div class="sym-search-filter"
         id="{{id}}"
         [ngClass]="{'sym-search-filter__inline': isInlineSearch,
                     'sym-search-filter__read-only': readOnly}"
         [class.is--processing]="isProcessing">
        <div class="sym-search-filter__filters">

            <div #searchFilterEl class="sym-search-filter__search-section"
                 [ngClass]="{'has--inline-quick-filter sym__flex-container': isInlineSearch}">
                <div *ngIf="!readOnly && isInlineSearch" [hidden]="options.hideQuickFilters" class="sym-search-filter__inline-quick-filter">
                    <div (click)="SymOverlayFilter.toggle( $event )" class="sym-search-filter__quick-filter-toggle">
                        <span class="sym-search-filter__quick-filter-toggle-text">{{l10n.searchFilterToggle}}</span>
                        <svg class="sym-smbl--arrow-small sym-search-filter__quick-filter-toggle-icon sym-smbl--black-80"
                             [ngClass]="{'sym-smbl--arrow-down': !overlayVisible, 'sym-smbl--arrow-up': overlayVisible}">
                            <use href="#sym-smbl__arrow-chevron"></use>
                        </svg>
                    </div>
                    <p-overlayPanel #SymOverlayFilter
                                    styleClass="ui-overlaypanel-no-padding sym-search-filter__quick-filter-overlay-panel"
                                    [offset]="overlayOffset"
                                    [disableAbsoluteAdjustment]="disableOverlayAdjustment"
                                    (onShow)="onOverlayFilterShow($event)"
                                    (onHide)="onQuickFilterOverlayHide($event)"
                                    [showCloseIcon]="true"
                                    arrowPosition="left">
                        <div class="ui-helper-clearfix">
                          <sym-search-filter-quick-queries
                                  [id]="id"
                                  [quickFilters]="quickFilters"
                                  [options]="overlayQuickQueriesOptions"
                                  [parserService]="parserService"
                                  [l10n]="l10n"
                                  [isProcessing]="isProcessing"
                                  [showQuickFiltersFilter]="showQuickFiltersFilter"
                          ></sym-search-filter-quick-queries>
                        </div>
                    </p-overlayPanel>
                </div>

                <div class="sym-search-filter__search-field-container">
                    <div class="sym-search-filter__search-header"
                         [hidden]="isInlineSearch">
                        <h2 class="sym-search-filter__search-title">
                            {{l10n.searchFilterTitle }}
                        </h2>
                        <div *ngIf="!readOnly" class="sym-search-filter__search-clear is--link"
                             (click)="clearSearch()">
                            {{ l10n.actions.clearSearch }}
                        </div>
                        <div *ngIf="!readOnly && enableDatabaseInput" class="sym-search-filter__search-toggle-view is--link"
                             (click)="toggleDatabaseView()">
                            {{ showingDatabaseTextInput ? l10n.actions.switchToTokenizedView : l10n.actions.switchToRawInput }}
                        </div>
                    </div>
                    <div class="sym-search-filter__search-field"
                         #searchField
                         (click)="onSearchFieldClick($event)"
                         [class.is--invalid]="hasError">
                        <div class="filter-info-text"
                             *ngIf="!showingDatabaseTextInput && !showingFriendlyTextInput && (tokens || []).length === 0"
                             [innerHTML]="l10n.filterInfo">
                        </div>

                        <div class="sym-search-filter__search-raw-input"
                             *ngIf="enableFriendlyInput || enableDatabaseInput"
                             [hidden]="!showingDatabaseTextInput && !showingFriendlyTextInput">
                            <div #searchFilterHiddenEl class="sym-search-filter__search-hidden">
                                {{query}}
                            </div>
                            <sym-search [hidden]="!showingDatabaseTextInput"
                                        [model]="databaseSearchModel"
                                        [parserService]="databaseParserService"
                                        [appendTo]="autocompleteSuggestions"
                                        (onBlur)="onSearchInputBlur()"
                                        (onEnter)="onEnterSearch($event)"
                                        autoCompleteNoMatchesMsg="{{ l10n.search.noMatches }}">
                            </sym-search>
                            <sym-search [hidden]="!showingFriendlyTextInput"
                                        [model]="friendlySearchModel"
                                        [parserService]="friendlyParserService"
                                        [appendTo]="autocompleteSuggestions"
                                        (onBlur)="onSearchInputBlur()"
                                        (onEnter)="onEnterSearch($event)"
                                        autoCompleteNoMatchesMsg="{{ l10n.search.noMatches }}">
                            </sym-search>
                        </div>

                        <ul #tokenSection class="sym-search-filter__tokens"
                            *ngIf="!showingDatabaseTextInput && !showingFriendlyTextInput && (tokens || []).length > 0">
                            <li class="sym-search-filter__token-item"
                                *ngFor="let token of tokens; let indexOfelement=index;">
                                <div class="sym-search-filter__token-actions" title="{{ token.token }}">
                                    <span class="sym-search-filter__token-remove icon__policy-delete"
                                          title="{{l10n.actions.deleteToken}}"
                                          *ngIf="!readOnly"
                                          (click)="removeToken($event,indexOfelement)"></span>
                                </div>

                                <!-- Special handling of NOT "operator", deleting this token will also remove the token it's part of -->
                                <div *ngIf="token.type !== 'LogicalOperator' && token.type !== 'Parenthesis' && token.type !== 'Invalid' && token.negated"
                                     class="sym-search-filter__token is--operator"
                                     (click)="removeToken($event,indexOfelement)">
                                    <span class="sym-search-filter__token-operator"
                                          title="l10n.operators.not">{{ l10n.operators.not }}</span>
                                </div>

                                <!-- All other kinds of tokens -->
                                <div class="sym-search-filter__token"
                                     (click)="removeToken($event,indexOfelement)"
                                     [ngClass]="{'has--field': token.type !== 'LogicalOperator' && token.type !== 'Parenthesis' && !token.hideDisplayName,
                               'is--operator': token.type === 'LogicalOperator',
                               'is--parentheses': token.type === 'Parenthesis',
                               'is--invalid': token.type === 'Invalid',
                               'is--negated': token.token.charAt(0) === '-' || token.token.startsWith('NOT '),
                               'is--last' : indexOfelement === tokens.length - 1 && tokens.length > 1 }">
                                    <!-- Field name -->
                                    <span *ngIf="token.type !== 'LogicalOperator' && token.type !== 'Parenthesis' && token.type !== 'Invalid' && token.type !== 'Freeform' && !token.hideDisplayName"
                                          class="sym-search-filter__token-field"
                                          title="{{ token.displayName }}">{{ token.displayName }}</span>

                                    <!-- Field value -->
                                    <span *ngIf="token.type !== 'LogicalOperator' && token.type !== 'Parenthesis' && token.type !== 'Invalid' && token.type !== 'Freeform' && token.displayValue"
                                          class="sym-search-filter__token-value"
                                          title="{{ token.displayValue }}">{{ token.displayValue }}</span>

                                    <!-- Freeform -->
                                    <span *ngIf="token.type === 'Freeform'"
                                          class="sym-search-filter__token-operator"
                                          title="{{ token.token }}">{{ token.displayName }}</span>

                                    <!-- Operator, Parens or Invalid -->
                                    <span *ngIf="token.type === 'LogicalOperator' || token.type === 'Parenthesis' || token.type === 'Invalid'"
                                          class="sym-search-filter__token-operator"
                                          title="{{ token.token }}">{{ token.token }}</span>
                                </div>
                            </li>

                            <div *ngIf="isInlineSearch && !readOnly" [hidden]="query === ''"
                                 class="sym-search-filter__clear-search-icon is--link"
                                 (click)="clearSearch()">
                                 <sym-icon
                                   styleClass="sym-smbl__filter-item"
                                   svgId="sym-smbl__filter-for"></sym-icon>
                                 </div>
                        </ul>

                    </div>
                    <div #autocompleteSuggestions class="autocompleteSuggestions"></div>
                    <div [hidden]="!hasError" class="sym-search-filter__search-error-message">
                        {{errors}}
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="!isInlineSearch && !readOnly" class="sym-search-filter__bottom">
          <sym-search-filter-quick-queries
                  [id]="id"
                  [quickFilters]="quickFilters"
                  [customFilter]="customFilter"
                  [options]="options"
                  [parserService]="parserService"
                  [l10n]="l10n"
                  [isProcessing]="isProcessing"
                  [showQuickFiltersFilter]="showQuickFiltersFilter"
          ></sym-search-filter-quick-queries>
        </div>
    </div>
  `
} )

export class SearchFilter implements OnInit, AfterViewInit {

  @Input() id : string;

  @Input() quickFilters : IQuickFilter[];

  @Input() customFilter: ICustomFilter;

  @Input() set parserService(value: IParser) {
    this.databaseParserService = value;
    this.friendlyParserService.configure(this.databaseParserService.getConfiguration() || {});

    // Make sure any config is also set on the friendly parser service
    const setConfig = this.databaseParserService.configure;
    this.databaseParserService.configure = (config: IParserConfiguration): IParser => {
      this.friendlyParserService.configure(config);
      return setConfig.call(this.databaseParserService, config);   // Otherwise "this" is lost in unit tests
    }
  }
  get parserService(): IParser {   // Getter is needed for unit test only...
    return this.databaseParserService;
  }


  @Input() l10n : any;

  @Input() hasError : boolean;

  @Input() isProcessing : boolean;

  @Input() readOnly : boolean;

  // Deprecated, use `enableDatabaseInput` instead.
  @Input() enableRawInput = false;   // Database mode using database field names and values only

  @Input() enableDatabaseInput = false;   // Database mode using database field names and values only
  @Input() enableFriendlyInput = true;   // "Friendly edit" mode using display field names and display values, where possible

  @Input() isInlineSearch = false;

  @Input() showQuickFiltersFilter = false;

  @Input() notifyAllAppendQueryChange = false; //queryChange will be called even there is error

  @ViewChild('searchField', { static: false }) searchFieldElt: ElementRef;
  @ViewChild('autocompleteSuggestions', { static: false }) autocompleteSuggestionsElt: ElementRef;

  @ViewChildren( TypeaheadList ) typeaheadChildren! : QueryList<TypeaheadList>;

  @ViewChild('tokenSection') tokenSection: ElementRef;
  @ViewChild('searchFilterHiddenEl') searchFilterHiddenEl: ElementRef;

  filterValue = "";
  databaseParserService: IParser;      // Database source of truth parser service
  databaseSearchModel: ISearchModel;
  showingDatabaseTextInput = false;
  private _databaseSearchInputId: string;

  friendlyParserService: IParser = new DisplayStringParserService();   // "Friendly edit" display strings only parser service
  friendlySearchModel: ISearchModel;
  showingFriendlyTextInput = false;
  private _friendlySearchInputId: string;
  private _friendlyQuery = '';   // For some reason, using friendlySearchModel.query doesn't work well, it's not getting set right?

  currentTab : string;
  tokens: SearchToken[] = [];
  isValidQuery = true;
  expandedQuery: string;
  errors: string[];
  searchQuerySpace = ' ';
  disableOverlayAdjustment = true;
  overlayOffset = { left: 0, top: 20};
  overlayVisible = false;
  ignoreBlur = false;
  queryChangeDueToTokenClick = false;

  textareaHeightAdjustment = 22; //TODO:  22 = 8px bottom padding + 1px top border + 1px bottom border + ?

  private _options = {
            hideQuickFilters: false,
            hideCustomFilter: false
          };
  private _inited = false;

  private _tmpQuery = '';   // Contains query input value in case onNgInit has not run yet
  private _query = '';   // This is the database query, not the "friendly" query

  overlayQuickQueriesOptions = {
    hideTabs: true,
    hideOperators: true,
    hideQuickFilters: false,
    hideCustomFilter: true
  };

  // Sets the raw, unexpanded, database query (not the "friendly" display-strings-only query)
  @Input() set query(value: string) {
    if (!this._inited) {
      // Process this after onNgInit has completed
      this._tmpQuery = value;
      return;
    }

    if (value !== undefined && value !== null && value !== this._query) {   // When component chain loads, autocomplete sets query to null... TODO: fix autocomplete?
      console.log('SearchFilter: apply new value:', '"' + value + '"');
      this._query = value;
      if (this.enableDatabaseInput || this.enableFriendlyInput) {
        console.log('SearchFilter: update databaseSearchModel model:', '"' + value + '"');

        this.databaseSearchModel.query = value;

        // The query change will be triggered by Search (more precise: the underlying AutoComplete component),
        // and may include more info than can be conveyed here. Delay this call until databaseSearchModel.queryChange.
        //this.queryChange.emit({ value: value } as QueryChangeValue);
      } else {
        this.updateTokens(value);
      }
    } else {
      console.log('SearchFilter: value is not different, ignore');
    }
  }
  get query(): string {
    return this._query;
  }
  @Output() queryChange = new EventEmitter<QueryChangeValue>();

  @Input() get options () : any {
    return this._options;
  }

  set options ( val : any ) {
    this._options = val;
  }


  private appendEvent : Subscription;
  private addQuickFilterEvent : Subscription;

  constructor ( public el : ElementRef, private subscriptionService : SymSubscriptionService) {
  }

  ngOnInit () {
    // Subscribing the event.
    this.appendEvent = this.subscriptionService.subscribeEvent<any>( 'search-filter-append-' + this.id, ( item ) => {
      this.appendSearchQuery( item );
    } );

    this.addQuickFilterEvent = this.subscriptionService.subscribeEvent<any>( 'search-filter-add-quick-filter-' + this.id, ( item ) => {
      this.addQuickFilter( item );
    } );

    if ( !this.l10n ) {
      this.l10n = {
        operators          : {
          and    : 'AND',
          or     : 'OR',
          not    : 'NOT',
          equals : 'Equals'
        },
        boolean            : {
          true  : 'TRUE',
          false : 'FALSE'
        },
        customFilter       : {
          title                  : 'Filter',
          fieldPlaceholder       : 'Field*',
          operatorPlaceholder    : this.options.isDynamicOperator ? 'Operator*' : 'Equals',
          valuePlaceholder       : 'Value*',
          addCustomeFilterButton : 'Add',
          fieldErrorMessage: 'Custom Field Error',
          errorMessage: 'Custom Filter Error'
        },
        quickFilters       : {
          toggleTitle: 'Quick Filters',
          title : 'What would you like to filter?'
        },
        tabName            : {
          quickFilters : 'Quick Filters',
          customFilter : 'Custom Filter'
        },
        actions            : {
          clearSearch : 'Clear',
          deleteToken : 'Delete',
          applyButton : 'Apply',
          switchToRawInput: 'Edit database query',
          switchToTokenizedView: 'Show tokenized view'
        },
        operatorTitle      : 'Operators',
        searchFilterTitle  : 'Filter by',
        searchFilterToggle : 'Quick Filters',
        filterInfo         : 'Select your query from below Quick or Custom Filters.',
        search: {
          databasePlaceholder: 'Enter Lucene query',
          friendlyPlaceholder: 'Enter query',
          invalidQuery       : 'Invalid query',
          noMatches          : 'No matches',
          errors: {
            INCOMPLETE_QUERY      : 'Syntax error: your query is not complete',
            INVALID_FIELD_NAME    : 'Invalid field or macro name: {0}',
            INVALID_FIELD_VALUE   : 'Invalid field or macro value: {0}',
            NO_FIELD_VALUE        : 'Missing field or macro value: {0}',
            NO_LEFT_EXPRESSION    : 'Missing an expression to the left of the logical operator: {0}',
            NO_OPERATOR_AFTER     : 'Missing a logical operator after the expression: {0}',
            NO_OPERATOR_IN_BETWEEN: 'Missing a logical operator in between the expressions: {0}',
            NO_QUERY_BODY         : 'Syntax error: missing query body',
            NO_RIGHT_EXPRESSION   : 'Missing an expression to the right of the logical operator: {0}',
            PARSING_ERROR         : 'Syntax error: query could not be parsed',
            UNBALANCED_PARENTHESIS: 'Syntax error: found unbalanced parentheses'
          }
        }
      };
    }



    if ( !this.id ) {
      this.id = 'sym-search-filter__' + Utils.generateUUID( 10 );
    }

    // Deprecation handling
    // TODO: turn this into a `throw new Error` in the near future, then TODO: remove code in the near future
    if (this.enableRawInput) {
      console.error('SearchFilter: input `enableRawInput` has been renamed to `enableDatabaseInput`');
      this.enableDatabaseInput = this.enableRawInput;
    }

    if (this.enableFriendlyInput || this.enableDatabaseInput) {
      this.createSearchModels();
    }

    this._inited = true;

    // Component is properly set up, process whatever query was set, if any
    if (this._tmpQuery) {
      this.query = this._tmpQuery;
      this._tmpQuery = undefined;
    }
  }

  ngAfterViewInit() {
    if ((this.enableFriendlyInput || this.enableDatabaseInput) && !this.isInlineSearch) {
      this.addSearchFieldHeightListener();
      this.onSearchFieldHeightChange();   // Set initial height
    }
  }

  createSearchModels() {
    this._databaseSearchInputId = this.id + '-database-input';
    this.databaseSearchModel = {
      inputId: this._databaseSearchInputId,
      placeholder: this.l10n.search.databasePlaceholder || this.l10n.search.placeholder,   // TODO: remove fallback in future version
      textarea: true,
      query: this.query,
      queryChange: (value: QueryChangeValue) => {
        console.log('Database query change', value.value, this.databaseParserService.getTokens());
        if (!value.parserInfo) {
          throw 'Expected parser info to be present in queryChange event';
        }

        // Update database source of truth
        this._query = value.value;   // Because of 1-way binding, this._query does not get updated automatically

        // Make sure to populate displayName and displayValue for all query search tokens as both field:value are required for proper display string query parsing
        // (Alternatively, SearchFilter's tokenization code should use token.token instead of display strings... but it'd have to split by k:v separator, which would be slow...)
        (this.databaseParserService.getTokens() || []).forEach(token => {
          if (token.type === SearchTokenType.Query || token.type === SearchTokenType.Macro) {
            if (!token.displayName || !token.displayValue) {
              const kvPair = this.databaseParserService.getFieldNameAndValuePair(token.token);
              token.hideDisplayName = kvPair[0] !== '_exists_' && !Boolean(token.displayName);
              token.displayName = token.displayName || kvPair[0];
              token.displayValue = token.displayValue || kvPair[1];
            }
          }
        });

        this.tokens = this.databaseParserService.getTokens();
        this.expandedQuery = this.isValidQuery ? value.parserInfo.expandedQuery : this.l10n.search.invalidQuery;

        if (!this.showingFriendlyTextInput || this.queryChangeDueToTokenClick) {
          // Update "friendly" query, as there are cases where the database query changes without switching edit
          // modes, e.g. the initial query and any action taken on tokens in tokenized mode (like remove token)
          this.friendlySearchModel.query = this.convertToFriendlyQuery(this.databaseParserService.getTokens() || []);
          this._friendlyQuery = this.friendlySearchModel.query;
        }

        if (this.showingDatabaseTextInput) {
          this.isValidQuery = value.parserInfo.isValid;
          this.hasError = !this.isValidQuery;
          // Error display is input mode specific
          // Only show database strings when in database query edit mode, all other areas need to show display strings
          this.setErrors(this.databaseParserService.getErrors());

          // Change was due to user entering text, adjust input field to fit query length
          this.autoExpand();
        }

        // Inform subscribed listeners of the database query change
        // Delay this to allow display-string parser's queryChange to run first--we need to make sure isValidQuery is correct for the current edit mode
        setTimeout(() => {
          if (this.isValidQuery) {
            console.log('SearchFilter: database queryChange: inform outside world about valid query', this._query);
            this.queryChange.emit({
              value: this._query,
              parserInfo: {
                expandedQuery: this.databaseParserService.getExpandedQuery(),
                isValid: this.isValidQuery
              }
            });
          }
        });
      }
    };

    this._friendlySearchInputId = this.id + '-friendly-input';
    this.friendlySearchModel = {
      inputId: this._friendlySearchInputId,
      placeholder: this.l10n.search.friendlyPlaceholder || this.l10n.search.placeholder,   // TODO: remove fallback in future version
      textarea: true,
      query: this._friendlyQuery,
      queryChange: (value: QueryChangeValue) => {

        console.log('Friendly query change: old:', this._friendlyQuery, 'new:', value.value);
        if (!this.showingFriendlyTextInput && !this.queryChangeDueToTokenClick) {
          // Not editing the "friendly" query, so nothing to do. Ignore.
          return;
        }

        if (!value.parserInfo) {
          throw 'Expected parser info to be present in queryChange event';
        }

        // Error display is input mode specific
        // Only show database strings when in database query edit mode, all other areas need to show display strings
        // Only set query validity indicators; tokens, expanded query, etc is only for database query changes
        if (!this.showingDatabaseTextInput) {
          this.isValidQuery = value.parserInfo.isValid;
          this.hasError = !this.isValidQuery;
          this.setErrors(this.friendlyParserService.getErrors());
        }

        if (this.showingFriendlyTextInput) {
          // Update database source of truth
          this.databaseSearchModel.query = this.convertToDatabaseQuery();
          (document.querySelector('#' + this._friendlySearchInputId) as any).focus();
          // Change was due to user entering text, adjust input field to fit query length
          this.autoExpand();
        }

        // Don't triggering another queryChange by avoiding friendlySearchModel.query change
        this._friendlyQuery = value.value;
      }
    };
  }

  onSearchFieldHeightChange() {
    const height = this.searchFieldElt.nativeElement.getBoundingClientRect().height;
    let elt = (document.querySelector('#' + this._databaseSearchInputId) as any);
    if (elt) {   // elt is null when navigating away from the page // TODO: avoid this getting called // TODO: destroy ResizeObserver onDestroy!
      elt.style.height = (height - this.textareaHeightAdjustment) + 'px';   //TODO:  22 = 8px bottom padding + 1px top border + 1px bottom border
    }
    elt = (document.querySelector('#' + this._friendlySearchInputId) as any);
    if (elt) {   // elt is null when navigating away from the page // TODO: avoid this getting called // TODO: destroy ResizeObserver onDestroy!
      elt.style.height = (height - this.textareaHeightAdjustment) + 'px';   //TODO:  22 = 8px bottom padding + 1px top border + 1px bottom border
    }
  }

  onOverlayFilterShow(event) {
    this.overlayVisible = true;
  }
  onQuickFilterOverlayHide(event) {
    this.overlayVisible = false;
  }

  private autoExpandImpl (field: HTMLElement) {
    // Reset field height
    field.style.height = 'inherit';

    let hiddenSearchHeight = window.getComputedStyle(this.searchFilterHiddenEl.nativeElement).height;
    let hiddenSearchHeightNum = parseInt(hiddenSearchHeight.replace('px', ''));
    // Get the computed styles for the element
    const computed = window.getComputedStyle(field);
    let extraPadding = 2;

    if (Number.isNaN(hiddenSearchHeightNum) || hiddenSearchHeightNum < 5) {
      hiddenSearchHeightNum = this.textareaHeightAdjustment;
    }
    // Calculate the height
    const height = parseInt(computed.getPropertyValue('border-top-width'), 10)
      + parseInt(computed.getPropertyValue('padding-top'), 10)
      + hiddenSearchHeightNum
      + parseInt(computed.getPropertyValue('padding-bottom'), 10)
      + parseInt(computed.getPropertyValue('border-bottom-width'), 10)
      + extraPadding;

    field.style.height = height + 'px';
  }
  autoExpand () {
    this.autoExpandImpl(document.querySelector('#' + this._databaseSearchInputId)); //need to specify HTMLElement here or get typescript error that style doesn't exist)
    this.autoExpandImpl(document.querySelector('#' + this._friendlySearchInputId)); //need to specify HTMLElement here or get typescript error that style doesn't exist)
  };

  addSearchFieldHeightListener() {
    // @ts-ignore ResizeObserver usage
    if (typeof window.ResizeObserver !== 'undefined') {
      // @ts-ignore ResizeObserver usage
      new ResizeObserver(() => {   // Use arrow function to avoid "this" from getting lost
        this.onSearchFieldHeightChange();
      }).observe(this.searchFieldElt.nativeElement);
    }
  }

  onSearchFieldClick(event: MouseEvent) {
    if (this.readOnly || (!this.enableFriendlyInput && !this.enableDatabaseInput)) {
      return;
    }

    // Prevent switching when resizing search field and releasing mouse button over the search field
    if (event.eventPhase === 2) {
      return;
    }

    // Only consider cases where the switch to edit mode should occur. The switch back is handled by the onBlur below.
    if (
      (event.target as any).classList.contains('filter-info-text') ||                   // Wants to switch to edit mode (token placeholder text)
      (event.target as any).classList.contains('sym-search-filter__tokens') ||          // Wants to switch to edit mode (whitespace in between tokens)
      (event.target as any).classList.contains('sym-search-filter__token-actions') ||
      !this.showingDatabaseTextInput && !this.showingFriendlyTextInput && event.target === this.searchFieldElt.nativeElement    // Wants to switch to edit mode (whitespace outside tokens)
    ) {
      // Clicking between tokens always moves to friendly edit, database edit is controlled by a dedicated toggle

      // Since "friendly" query is updated on any database query change, no need to convertToFriendlyQuery() here
      // this.friendlySearchModel.query = this.convertToFriendlyQuery(this.databaseParserService.getTokens() || []);
      // this._friendlyQuery = this.friendlySearchModel.query;

      if (this.enableFriendlyInput) {
        this.showingFriendlyTextInput = true;
      } else {
        this.showingDatabaseTextInput = true;
      }

      // Let Angular process ngIf(s) that monitor showing*TextInput
      setTimeout(() => {
        if (this.enableFriendlyInput) {
          (document.querySelector('#' + this._friendlySearchInputId) as any).focus();
        } else {
          (document.querySelector('#' + this._databaseSearchInputId) as any).focus();
        }

        if (this.isInlineSearch) {
          this.autoExpand();
        }
      });
    }
  }

  // TODO: should probably live in displaystringparserservice
  private convertToDatabaseQuery(): string {
    let queryParts = [];
    let lastEnd = 0;
    let skipNextTokenLeadingSpace = false;
    (this.friendlyParserService.getTokens() || []).forEach(token => {
      if (skipNextTokenLeadingSpace) {
        skipNextTokenLeadingSpace = false;
      } else {
        // Add any leading spaces ahead of token, if any
        queryParts.push(' '.repeat(token.meta.token.start - lastEnd));
      }

      let databaseToken = token.meta.token.rawToken;
      if ((token.type === SearchTokenType.Query || token.type === SearchTokenType.Macro) && token.meta.config) {   // Last clause makes sure field/macro is known
        databaseToken = (this.friendlyParserService as DisplayStringParserService).replaceDisplayStrings(databaseToken);
      } else if (token.type === SearchTokenType.LogicalOperator && token.token === 'NOT') {
        databaseToken = this.databaseParserService.getNegationPrefix();
        skipNextTokenLeadingSpace = true;
      }    // Else add "as-is"
      queryParts.push(databaseToken);

      lastEnd = token.meta.token.end;
    });
    // Add any trailing spaces after last token, if any
    queryParts.push(' '.repeat(this.friendlyParserService.getQuery().length - lastEnd));
    console.log('SearchFilter: set database query: ' + queryParts.join(''));
    return queryParts.join('');
  }

  // TODO: should probably live in luceneparserservice
  private convertToFriendlyQuery(tokens: SearchToken[]): string {
    let queryParts = [];
    let lastEnd = 0;
    tokens.forEach(token => {
      // Add any leading spaces ahead of token, if any
      queryParts.push(' '.repeat(token.meta.token.start - lastEnd));

      let displayStringToken = token.meta.token.rawToken;
      if (token.type === SearchTokenType.Query || token.type === SearchTokenType.Macro) {
        if (token.meta.config) {   // This clause makes sure field/macro is known
          // Cannot parse plain-text string without a field display name unless we require all displayValues to be unique
          // across all fields (likely a bad idea), so this is why a displayName is required for all fields/macros.
          // If absolutely needed, it would be possible to fall back to the DB name if there was no displayName.
          // Use databaseParserService rather than friendlyParserService, to keep as much of the database syntax
          // in place, even though it may be invalid in "friendly edit" mode...
          displayStringToken = this.databaseParserService.replaceFieldName(displayStringToken, this.databaseParserService.escapeSpecialChars(token.displayName));   // If fallback is ever needed, add `|| token.meta.config[0].name);`
          displayStringToken = this.databaseParserService.replaceFieldValue(displayStringToken, this.databaseParserService.escapeSpecialChars(token.displayValue));
          // Must be last, otherwise negatePrefix check of previous methods won't work
          displayStringToken = this.databaseParserService.replaceNegation(displayStringToken, 'NOT', true);   // TODO: use friendly parser's this.negatePrefix?
        } else if (/^-_exists_/.test(token.token)) {   // TODO: move "exist" token test to databaseParserService
          // TODO: replace value (the field name) with the display field name. This would also require custom handling in convertToDatabaseQuery
          displayStringToken = this.databaseParserService.replaceNegation(displayStringToken, 'NOT', true);   // TODO: use friendly parser's this.negatePrefix?
        }   // Else add "as-is"
      } else if (token.type === SearchTokenType.Freeform) {
        // Unsupported for DisplayStringParser, but at least handle the negation
        // TODO: support freeform
        displayStringToken = this.databaseParserService.replaceNegation(displayStringToken, 'NOT', true);   // TODO: use friendly parser's this.negatePrefix?
      }
      queryParts.push(displayStringToken);

      lastEnd = token.meta.token.end;
    });
    // Add any trailing spaces after last token, if any
    queryParts.push(' '.repeat(this.databaseParserService.getQuery().length - lastEnd));
    console.log('SearchFilter: set friendly query: "' + queryParts.join('') + '"');
    return queryParts.join('');
  }

  onSearchInputBlur() {
    if (this.ignoreBlur) {
      this.ignoreBlur = false;
      return;
    }

    if (this.enableFriendlyInput && this.hasError) {
      // Parsing bad queries is so much harder in friendly edit mode... don't allow user to exit edit mode unless query is valid
      // User types in friendly edit mode: `Client Version`, this is an invalid query, which DB parser turns into two freeform tokens, messing up a subsequent friendly edit session
      // User types in DB edit mode: `Client Version: 1.6`, where let's say the value is invalid. It's not necessarily obvious in friendly edit mode that this value is invalid
      // (All of this is not an issue if only database edit is enable, so no need to restrict there)
      return;
    }

    if (this.showingDatabaseTextInput) {
      // Switching away from database edit mode, so update "friendly" query
      this.friendlySearchModel.query = this.convertToFriendlyQuery(this.databaseParserService.getTokens() || []);
      this._friendlyQuery = this.friendlySearchModel.query;
    }

    // Back to tokenized view
    this.showingDatabaseTextInput = false;
    this.showingFriendlyTextInput = false;
  }


  addQuickFilter ( selectedFilter : IQuickFilterItem) {
    let fieldId = selectedFilter ? selectedFilter.name : '';
    let value: ParserFieldSimpleValue = selectedFilter ? selectedFilter.value : '';

    if (!fieldId || !value) {
      console.log('UXT: cannot add quick filter as it has a missing name and/or value:', selectedFilter);
      return;
    }

    let newSearchQuery = this.prependOperator(fieldId);
    newSearchQuery = newSearchQuery.concat( this.searchQuerySpace, this.formatSearchQueryToken(fieldId, value) );
    this.appendSearchQuery(newSearchQuery);
  }

  prependOperator (fieldId: string) {
        let newSearchQuery: string = '';
        let queryArray2      = this.tokens.slice(),
            queryArrayLength = ( queryArray2.length ) ? queryArray2.length : 0,
            lastIndex        = queryArrayLength - 1;

        if ( queryArrayLength > 0 ) {

          let last              = queryArray2[ lastIndex ],
              lastType          = last.type,
              lastTokenArr      = last && last.token ? this.databaseParserService.getFieldNameAndValuePair(last.token) : [],
              lastId            = (lastTokenArr.length > 1) ? lastTokenArr[0] : fieldId,
              secondToLast      = ( queryArray2[ lastIndex - 1 ] ) ? queryArray2[ lastIndex - 1 ] : null,
              secondToLastType  = secondToLast ? secondToLast.type : '',
              secondToLastQuery = secondToLast ? secondToLast.token : '';

          if ( lastType !== SearchTokenType.LogicalOperator && lastType !== SearchTokenType.Parenthesis ) {
            if ( secondToLastType === SearchTokenType.LogicalOperator) {
              newSearchQuery = newSearchQuery.concat( this.searchQuerySpace, secondToLastQuery );
            }
            else {
              if ( lastId === fieldId ) {
                newSearchQuery = newSearchQuery.concat( this.searchQuerySpace, 'OR' );
              } else {
                newSearchQuery = newSearchQuery.concat( this.searchQuerySpace, 'AND' );
              }
            }
          }
        }
        return newSearchQuery;
  }

  appendSearchQuery (searchQuery: string) {
    if ( this.isProcessing || this.readOnly ) {
      return;
    }

    this.ignoreBlur = true;
    setTimeout(() => {
      this.ignoreBlur = false;
    }, 200);

    this.queryChangeDueToTokenClick = true;
    this.query += (this.query ? this.searchQuerySpace : '') + searchQuery;
    // Let queryChange handler execute, then reset flag
    setTimeout(() => {
      this.queryChangeDueToTokenClick = false;
    });
  }

  removeToken ( event, index : number ) {
    event.stopPropagation();

    if ( this.isProcessing || this.readOnly ) {
      return;
    }

    if ( index > -1 ) {
      let tokenStart = this.tokens[index].meta.token.start;
      let tokenEnd = this.tokens[index].meta.token.end;

      // Prevent obsolete spacing
      if (this.query.charAt(tokenStart - 1) === this.searchQuerySpace && this.query.charAt(tokenEnd + 1) === this.searchQuerySpace) {   // / token /
        tokenEnd += 1;
      } else if (this.query.charAt(tokenStart - 1) === '' && this.query.charAt(tokenEnd + 1) === this.searchQuerySpace) {               // /^token /
        tokenEnd += 1;
      } else if (this.query.charAt(tokenStart - 1) === this.searchQuerySpace && this.query.charAt(tokenEnd + 1) === '') {               // / token$/
        tokenStart -= 1;
      }

      // TODO: prevent empty parenthesis?

      this.ignoreBlur = true;
      setTimeout(() => {
        this.ignoreBlur = false;
      }, 200);

      // Update database query source of truth, which in turn updates the "friendly" query
      this.queryChangeDueToTokenClick = true;
      this.query = this.query.substring(0, tokenStart) + this.query.substring(tokenEnd);
      // Let queryChange handler execute, then reset flag
      setTimeout(() => {
        this.queryChangeDueToTokenClick = false;
      });
    }
  }

  updateTokens (query: string) {
    if (!this.databaseSearchModel && !this.friendlySearchModel) {
      this.validateQuery(query);
      // `updateTokens` is called from set query only, which is the raw database query, so use databaseParserService
      // Make sure to populate displayName and displayValue for all query search tokens as both field:value are required for proper display string query parsing
      // (Alternatively, SearchFilter's tokenization code should use token.token instead of display strings... but it'd have to split by k:v separator, which would be slow...)
      (this.databaseParserService.getTokens() || []).forEach(token => {
        if (token.type === SearchTokenType.Query || token.type === SearchTokenType.Macro) {
          if (!token.displayName || !token.displayValue) {
            const kvPair = this.databaseParserService.getFieldNameAndValuePair(token.token);
            token.hideDisplayName = kvPair[0] !== '_exists_' && !Boolean(token.displayName);
            token.displayName = token.displayName || kvPair[0];
            token.displayValue = token.displayValue || kvPair[1];
          }
        }
      });
      this.tokens = this.databaseParserService.getTokens();
      console.log('SearchFilter: update tokens', this.hasError, this.tokens);
      if (!this.hasError) {
        // Delay this to avoid search-filter-demo getting ExpressionChangedAfterItHasBeenCheckedError on isProcessing
        console.log('SearchFilter: async queryChange.emit call');
        setTimeout(() => {
          console.log('SearchFilter: updateTokens: inform outside world about valid query', this.query);
          this.queryChange.emit({
            value: query,
            parserInfo: {
              expandedQuery: this.databaseParserService.getExpandedQuery(),
              isValid: this.isValidQuery
            }
          });
        });
      }
    } // Else search model queryChange will inform the parser service later
  }

  validateQuery (searchQuery: string) {
    // `validateQuery` is called from `setTokens`, which is called by set query only, which is the raw database query, so use databaseParserService
    this.isValidQuery = this.databaseParserService.setQuery(searchQuery).isValid();
    this.hasError = !this.isValidQuery;
    this.setErrors(this.databaseParserService.getErrors());
  };

  setErrors (errors: IParserError[]) {
    this.errors = [];
    let l10nKey;
    errors.forEach(error => {
      // Allow specialized validation errors
      if (error.validationErrors) {
        const found = Object.keys(error.validationErrors).some(key => {
          if (this.l10n.search.errors[key]) {
            l10nKey = key;
            return true;
          }
        });
        if (!found) {
          // No validation-specific translations provided, fall back to top-level error code
          l10nKey = error.code;
        }
      } else {
        l10nKey = error.code;
      }

      if (!this.l10n.search.errors[l10nKey]) {
        throw new Error('Missing translation for "' + l10nKey + '"');
      }

      let expr = error.expression;
      this.errors.push(this.l10n.search.errors[l10nKey].replace('{0}', expr));
    });
  }

  toggleDatabaseView () {
    // Avoid blur handler interfering...
    this.ignoreBlur = true;
    setTimeout(() => {
      this.ignoreBlur = false;
    }, 200);

    this.showingDatabaseTextInput = !this.showingDatabaseTextInput;
    this.showingFriendlyTextInput = false;   // Always false, as toggle controls database edit mode only

    if (this.showingDatabaseTextInput) {
      // Let Angular process ngIf(s) that monitor showingDatabaseTextInput
      setTimeout(() => {
        (document.querySelector('#' + this._databaseSearchInputId) as any).focus();
      });
    }
  }

  clearSearch () {
    if ( this.isProcessing ) {
      return;
    }

    // Avoid blur handler interfering...
    this.ignoreBlur = true;
    setTimeout(() => {
      this.ignoreBlur = false;
    }, 200);

    this.queryChangeDueToTokenClick = true;
    this.query = '';
    // Let queryChange handler execute, then reset flag
    setTimeout(() => {
      this.queryChangeDueToTokenClick = false;
    });

    if (this.showingFriendlyTextInput) {
      // Setting `this.query` above won't trigger this, so do it manually
      this.friendlySearchModel.query = '';
      this._friendlyQuery = this.friendlySearchModel.query;

      // Don't lose focus
      (document.querySelector('#' + this._friendlySearchInputId) as any).focus();
    } else if (this.showingDatabaseTextInput) {
      // Don't lose focus
      (document.querySelector('#' + this._databaseSearchInputId) as any).focus();
    }

    if (this.isInlineSearch) {
      this.autoExpand();
    }
  }


  formatSearchQueryToken(field: string, value: ParserFieldSimpleValue, operator = ParserQueryOperator.EQUALS): string {
    return this.databaseParserService.createTokenString(field, value, operator);
  }

  onEnterSearch(event) {
    // Enter key signifies user is done entering text
    this.onSearchInputBlur();
  }


  ngOnDestroy () : void {
    // Unsubscribe the event once not needed.
    if (this.appendEvent) {
      this.appendEvent.unsubscribe();
    }

    if (this.addQuickFilterEvent) {
      this.addQuickFilterEvent.unsubscribe();
    }
  }
}

@NgModule( {
  imports: [CommonModule, DropdownModule, FormsModule, SharedModule, TypeaheadListModule, ButtonModule, InputTextModule, TabMenuModule, SearchModule, OverlayPanelModule, QuickFiltersPipeModule, SearchFilterQuickQueriesModule],
  exports      : [SearchFilter, CommonModule, DropdownModule, FormsModule, SharedModule, TypeaheadListModule, ButtonModule, InputTextModule, TabMenuModule, OverlayPanelModule, SearchFilterQuickQueriesModule],
  declarations : [SearchFilter]
} )
export class SearchFilterModule {
}
