import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  NgModule,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  TemplateRef
}                                      from '@angular/core';
import { CommonModule }                from '@angular/common';
import { TreeNode }                    from '../common/tree-node';
import { PrimeTemplate, SharedModule } from '../common/shared';
import { TreeDragDropService }         from '../common/tree-drag-drop-service';
import { Subscription }                from 'rxjs';
import { BlockableUI }                 from '../common/blockable-ui';
import { DomHandler }                  from '../dom/dom-handler';

@Component( {
  selector : 'p-tree',
  template : `
    <div [ngClass]="{'ui-tree ui-widget ui-widget-content ui-corner-all':true,'ui-tree-selectable':selectionMode,'ui-treenode-dragover':dragHover,'ui-tree-loading': loading}"
         [ngStyle]="style" [class]="styleClass" *ngIf="!horizontal"
         (drop)="onDrop($event)" (dragover)="onDragOver($event)"
         (dragenter)="onDragEnter($event)" (dragleave)="onDragLeave($event)">
      <div class="ui-tree-loading-mask ui-widget-overlay" *ngIf="loading"></div>
      <div class="ui-tree-loading-content" *ngIf="loading">
        <svg class="sym-smbl__progress-spinner sym-smbl--small"><use href="#sym-smbl__progress-spinner"></use></svg>
      </div>
      <ul class="ui-tree-container" *ngIf="value" role="tree" [attr.aria-label]="ariaLabel"
          [attr.aria-labelledby]="ariaLabelledBy">
        <p-treeNode *ngFor="let node of value; let firstChild=first;let lastChild=last; let index=index; trackBy: nodeTrackBy"
          [node]="node"
          [firstChild]="firstChild" [lastChild]="lastChild"
          [index]="index"></p-treeNode>
      </ul>
      <div class="ui-tree-empty-message" *ngIf="!loading && !value">{{emptyMessage}}</div>
    </div>
    <div [ngClass]="{'ui-tree ui-tree-horizontal ui-widget ui-widget-content ui-corner-all':true,'ui-tree-selectable':selectionMode}"
         [ngStyle]="style" [class]="styleClass" *ngIf="horizontal">
      <div class="ui-tree-loading ui-widget-overlay" *ngIf="loading"></div>
      <div class="ui-tree-loading-content" *ngIf="loading">
        <svg class="sym-smbl__progress-spinner sym-smbl--small"><use href="#sym-smbl__progress-spinner"></use></svg>
      </div>
      <table *ngIf="value&&value[0]">
        <p-treeNode [node]="value[0]" [root]="true"></p-treeNode>
      </table>
      <div class="ui-tree-empty-message" *ngIf="!loading && !value">{{emptyMessage}}</div>
    </div>
  `
} )
export class Tree implements OnInit, AfterContentInit, OnDestroy, BlockableUI {
  @Input() selectionMode : string;

  @Input() selection : any;

  @Input() activeNodeId : string;

  @Input() selectedIds : string[]; //only used with non lazy version as we can't keep track of "unselect all/select all in the folder"

  @Input() dataKey = "id";

  @Output() selectionChange : EventEmitter<any> = new EventEmitter();

  @Output() activeNodeIdChange : EventEmitter<string> = new EventEmitter();

  @Output() onNodeSelect : EventEmitter<any> = new EventEmitter();

  @Output() onNodeUnselect : EventEmitter<any> = new EventEmitter();

  @Output() onNodeExpand : EventEmitter<any> = new EventEmitter();

  @Output() onNodeCollapse : EventEmitter<any> = new EventEmitter();

  @Output() onNodeContextMenuSelect : EventEmitter<any> = new EventEmitter();

  @Output() onNodeActive : EventEmitter<any> = new EventEmitter();

  @Output() onNodeDrop : EventEmitter<any> = new EventEmitter();

  @Input() style : any;

  @Input() styleClass : string;

  @Input() contextMenu : any;

  @Input() layout : string = 'vertical';

  @Input() draggableScope : any;

  @Input() droppableScope : any;

  @Input() draggableNodes : boolean;

  @Input() droppableNodes : boolean;

  @Input() metaKeySelection : boolean = true;

  @Input() propagateSelectionUp : boolean = true;

  @Input() propagateSelectionDown : boolean = true;

  @Input() loading : boolean;

  @Input() loadingIcon : string = 'pi pi-spinner';

  @Input() emptyMessage : string = 'No records found';

  @Input() ariaLabel : string;

  @Input() ariaLabelledBy : string;

  @Input() validateDrop : boolean;

  @Input() nodeTrackBy : Function = ( index : number, item : any ) => item;

  @ContentChildren( PrimeTemplate ) templates : QueryList<any>;

  totalCount = 0;
  _value : TreeNode[] = [];

  @Input() get value () : TreeNode[] {
    return this._value;
  }


  set value ( val : TreeNode[] ) {
    if (val) {
      this.totalCount = this.getTotalCount(val);
    }

    if (this.totalCount < 1000 && (this.selectedIds || this.activeNodeId)) {
      this.renderAll = true;
    }
    else {
      this.renderAll = false;
    }

    this._value = val;
  }

  public templateMap : any;

  public nodeTouched : boolean;

  public dragNodeTree : Tree;

  public dragNode : TreeNode;

  public dragNodeSubNodes : TreeNode[];

  public dragNodeIndex : number;

  public dragNodeScope : any;

  public dragHover : boolean;

  public dragStartSubscription : Subscription;

  public dragStopSubscription : Subscription;

  renderAll = false; //if the tree nodes is under 1000 items and preselection/preactive items, check all
  constructor (
    public el : ElementRef, @Optional()
    public dragDropService : TreeDragDropService
  ) {
  }

  ngOnInit () {
    if ( this.droppableNodes ) {
      this.dragStartSubscription = this.dragDropService.dragStart$.subscribe(
        event => {
          this.dragNodeTree     = event.tree;
          this.dragNode         = event.node;
          this.dragNodeSubNodes = event.subNodes;
          this.dragNodeIndex    = event.index;
          this.dragNodeScope    = event.scope;
        } );

      this.dragStopSubscription = this.dragDropService.dragStop$.subscribe(
        event => {
          this.dragNodeTree     = null;
          this.dragNode         = null;
          this.dragNodeSubNodes = null;
          this.dragNodeIndex    = null;
          this.dragNodeScope    = null;
          this.dragHover        = false;
        } );
    }
  }

  get horizontal () : boolean {
    return this.layout == 'horizontal';
  }

  private isToggler ( eventTarget ) : boolean {
    let result = false;

    if ( eventTarget.classList && eventTarget.classList.contains( 'ui-tree-toggler' ) ) {
      result = true;
    } else if ( eventTarget.nodeName ) {
      if ( eventTarget.nodeName.toUpperCase() === 'SVG' && eventTarget.classList.contains( 'sym-smbl--arrow-small' ) || eventTarget.nodeName.toUpperCase() === 'USE' ) {
        result = true;
      }
    }

    return result;
  }

  ngAfterContentInit () {
    if ( this.templates.length ) {
      this.templateMap = {};
    }

    this.templates.forEach( ( item ) => {
      this.templateMap[ item.name ] = item.template;
    } );
  }

  getTotalCount (treeNodeArr: TreeNode[]) {
    let count = treeNodeArr.length;
    treeNodeArr.forEach((treeNode) => {
      if (treeNode.children && treeNode.children.length) {
        count += this.getChildrenCount(treeNode);
      }
    });
    return count;
  }

  getChildrenCount (node: TreeNode) {

    let count = node.children ? node.children.length : 0;
    if (node.children && count) {
      count += node.children.reduce((accumulator, current) => {
        let counts = accumulator + this.getChildrenCount(current);
        return counts;
      }
     , 0);
    }

    return count;
  }

  onCheckboxClick ( event, node : TreeNode) {
    let eventTarget = ( <Element>event.target );
    let index    = this.findIndexInSelection( node );
    let selected = ( index >= 0 );

    if (this.isSingleCheckboxSelectionMode()) {
      if ( selected ) {
        this.selection = null;
        this.onNodeUnselect.emit( { originalEvent : event, node : node } );
      } else {
        this.selection = node;
        this.onNodeSelect.emit( { originalEvent : event, node : node } );
      }
      this.selectionChange.emit( this.selection );
    }
    else {
      if ( selected ) {
        if ( this.propagateSelectionDown ) {
          this.propagateDown( node, false );
        } else {
          this.selection = this.selection.filter( ( val, i ) => i != index );
        }

        if ( this.propagateSelectionUp && node.parent ) {
          this.propagateUp( node.parent, false );
        }

        this.selectionChange.emit( this.selection );
        this.onNodeUnselect.emit( { originalEvent : event, node : node } );
      } else {
        if ( this.propagateSelectionDown ) {
          this.propagateDown( node, true );
        } else {
          this.selection = [ ...this.selection || [], node ];
        }

        if ( this.propagateSelectionUp && node.parent ) {
          this.propagateUp( node.parent, true );
        }

        this.selectionChange.emit( this.selection );
        this.onNodeSelect.emit( { originalEvent : event, node : node } );
      }
    }
  }

  onNodeClick ( event, node : TreeNode ) {

    let eventTarget = ( <Element>event.target );

    if ( this.isToggler( eventTarget ) ) {
      return;
    } else if ( this.selectionMode ) {
      if ( node.selectable === false ) {
        return;
      }
      if ( node.id && (this.isCheckboxSelectionMode() || this.isSingleCheckboxSelectionMode())) {
        this.activeNodeId =  node.id;
        this.activeNodeIdChange.emit( this.activeNodeId );
        this.onNodeActive.emit( { originalEvent : event, node : node } );
        return;
      }

      let index    = this.findIndexInSelection( node );
      let selected = ( index >= 0 );

      if ( this.isCheckboxSelectionMode() ) {
        //We can take this out, as onCheckboxClick is added, but keeping this just in case we want to have this options available later.
        if ( selected ) {
          if ( this.propagateSelectionDown ) {
            this.propagateDown( node, false );
          } else {
            this.selection = this.selection.filter( ( val, i ) => i != index );
          }

          if ( this.propagateSelectionUp && node.parent ) {
            this.propagateUp( node.parent, false );
          }

          this.selectionChange.emit( this.selection );
          this.onNodeUnselect.emit( { originalEvent : event, node : node } );
        } else {
          if ( this.propagateSelectionDown ) {
            this.propagateDown( node, true );
          } else {
            this.selection = [ ...this.selection || [], node ];
          }

          if ( this.propagateSelectionUp && node.parent ) {
            this.propagateUp( node.parent, true );
          }

          this.selectionChange.emit( this.selection );
          this.onNodeSelect.emit( { originalEvent : event, node : node } );
        }
      } else {
        let metaSelection = this.nodeTouched ? false : this.metaKeySelection;

        if ( metaSelection ) {
          let metaKey = ( event.metaKey || event.ctrlKey );

          if ( selected && metaKey ) {
            if ( this.isSingleSelectionMode() ) {
              this.selectionChange.emit( null );
            } else {
              this.selection = this.selection.filter( ( val, i ) => i != index );
              this.selectionChange.emit( this.selection );
            }

            this.onNodeUnselect.emit( { originalEvent : event, node : node } );
          } else {
            if ( this.isSingleSelectionMode() ) {
              this.selectionChange.emit( node );
            } else if ( this.isMultipleSelectionMode() ) {
              this.selection = ( !metaKey ) ? [] : this.selection || [];
              this.selection = [ ...this.selection, node ];
              this.selectionChange.emit( this.selection );
            }

            this.onNodeSelect.emit( { originalEvent : event, node : node } );
          }
        } else {
          if ( this.isSingleSelectionMode() ) {
            if ( selected ) {
              this.selection = null;
              this.onNodeUnselect.emit( { originalEvent : event, node : node } );
            } else {
              this.selection = node;
              this.onNodeSelect.emit( { originalEvent : event, node : node } );
            }
          } else {
            if ( selected ) {
              this.selection = this.selection.filter( ( val, i ) => i != index );
              this.onNodeUnselect.emit( { originalEvent : event, node : node } );
            } else {
              this.selection = [ ...this.selection || [], node ];
              this.onNodeSelect.emit( { originalEvent : event, node : node } );
            }
          }

          this.selectionChange.emit( this.selection );
        }
      }
    }

    this.nodeTouched = false;
  }

  onNodeTouchEnd () {
    this.nodeTouched = true;
  }

  onNodeRightClick ( event : MouseEvent, node : TreeNode ) {
    if ( this.contextMenu ) {
      let eventTarget = ( <Element>event.target );

      if ( this.isToggler( eventTarget ) ) {
        return;
      } else {
        let index    = this.findIndexInSelection( node );
        let selected = ( index >= 0 );

        if ( !selected ) {
          if ( this.isSingleSelectionMode() ) {
            this.selectionChange.emit( node );
          } else {
            this.selectionChange.emit( [ node ] );
          }
        }

        this.contextMenu.show( event );
        this.onNodeContextMenuSelect.emit( { originalEvent : event, node : node } );
      }
    }
  }

  findIndexInSelection ( node : TreeNode ) {
    let index : number = -1;

    if ( this.selectionMode && this.selection ) {
      if ( this.isSingleSelectionMode() || this.isSingleCheckboxSelectionMode()) {
        index = ( this.selection == node ) ? 0 : -1;
      } else {
        for ( let i = 0; i < this.selection.length; i++ ) {
          if ( this.selection[ i ] == node ) {
            index = i;
            break;
          }
        }
      }
    }

    return index;
  }

  propagateUp ( node : TreeNode, select : boolean ) {
    if ( node.children && node.children.length ) {
      let selectedCount : number         = 0;
      let childPartialSelected : boolean = false;
      for ( let child of node.children ) {
        if ( this.isSelected( child ) ) {
          selectedCount++;
        } else if ( child.partialSelected ) {
          childPartialSelected = true;
        }
      }

      if ( select && selectedCount == node.children.length ) {
        this.selection       = [ ...this.selection || [], node ];
        node.partialSelected = false;
      } else {
        if ( !select ) {
          let index = this.findIndexInSelection( node );
          if ( index >= 0 ) {
            this.selection = this.selection.filter( ( val, i ) => i != index );
          }
        }

        if ( childPartialSelected || selectedCount > 0 && selectedCount != node.children.length )
          node.partialSelected = true;
        else
          node.partialSelected = false;
      }
    }

    let parent = node.parent;
    if ( parent ) {
      this.propagateUp( parent, select );
    }
  }

  propagateDown ( node : TreeNode, select : boolean ) {
    let index = this.findIndexInSelection( node );

    if ( select && index == -1 ) {
      this.selection = [ ...this.selection || [], node ];
    } else if ( !select && index > -1 ) {
      this.selection = this.selection.filter( ( val, i ) => i != index );
    }

    node.partialSelected = false;

    if ( node.children && node.children.length ) {
      for ( let child of node.children ) {
        this.propagateDown( child, select );
      }
    }
  }


  updateSelections (node: TreeNode) {
    if (this.dataKey && this.selectedIds) {
        if (this.selectedIds.indexOf(node[this.dataKey]) !== -1) {
          if (this.selectionMode === 'single' || this.selectionMode === 'checkbox-single') {
            setTimeout(() => { //to avoid ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
              this.selection =  node;
            }, 10);
          }
          else {
            let index = this.findIndexInSelection( node );
            if ( index === -1 ) {
              this.selection = [ ...this.selection || [], node ];
            }
            if ( this.propagateSelectionDown || this.propagateSelectionUp ) {
              setTimeout(() => { //to avoid ExpressionChangedAfterItHasBeenCheckedError (NOTE:same error even added in ngAfterViewInit, so setTimeout is required.)
                if ( this.propagateSelectionDown ) {
                  this.propagateDown( node, true );
                }
                if ( this.propagateSelectionUp && node.parent) {
                  this.propagateUp( node.parent, true );
                }
              }, 0); //we want the least timeout ms as there are potentially
            }
          }
        }
    }
  }

  isSelected ( node : TreeNode ) {
    return this.findIndexInSelection( node ) != -1;
  }

  isActive ( node : TreeNode ) {
    if (this.activeNodeId && typeof node.id !== 'undefined') {
      return this.activeNodeId === node.id;
    }
  }


  isSingleSelectionMode () {
    return this.selectionMode && this.selectionMode == 'single';
  }

  isMultipleSelectionMode () {
    return this.selectionMode && this.selectionMode == 'multiple';
  }

  isCheckboxSelectionMode () {
    return this.selectionMode && this.selectionMode == 'checkbox';
  }

  isSingleCheckboxSelectionMode () {
    return this.selectionMode && this.selectionMode == 'checkbox-single';
  }

  getTemplateForNode ( node : TreeNode ) : TemplateRef<any> {
    if ( this.templateMap ) {
      return node.type ? this.templateMap[ node.type ] : this.templateMap[ 'default' ];
    } else {
      return null;
    }
  }

  onDragOver ( event ) {
    if ( this.droppableNodes && ( !this.value || this.value.length === 0 ) ) {
      event.dataTransfer.dropEffect = 'move';
      event.preventDefault();
    }
  }

  onDrop ( event ) {
    if ( this.droppableNodes && ( !this.value || this.value.length === 0 ) ) {
      event.preventDefault();
      let dragNode = this.dragNode;
      if ( this.allowDrop( dragNode, null, this.dragNodeScope ) ) {
        let dragNodeIndex = this.dragNodeIndex;
        this.dragNodeSubNodes.splice( dragNodeIndex, 1 );
        this.value = this.value || [];
        this.value.push( dragNode );

        this.dragDropService.stopDrag( {
          node : dragNode
        } );
      }
    }
  }

  onDragEnter ( event ) {
    if ( this.droppableNodes && this.allowDrop( this.dragNode, null, this.dragNodeScope ) ) {
      this.dragHover = true;
    }
  }

  onDragLeave ( event ) {
    if ( this.droppableNodes ) {
      let rect = event.currentTarget.getBoundingClientRect();
      if ( event.x > rect.left + rect.width || event.x < rect.left || event.y > rect.top + rect.height || event.y < rect.top ) {
        this.dragHover = false;
      }
    }
  }

  allowDrop ( dragNode : TreeNode, dropNode : TreeNode, dragNodeScope : any ) : boolean {
    if ( !dragNode ) {
      //prevent random html elements to be dragged
      return false;
    } else if ( this.isValidDragScope( dragNodeScope ) ) {
      let allow : boolean = true;
      if ( dropNode ) {
        if ( dragNode === dropNode ) {
          allow = false;
        } else {
          let parent = dropNode.parent;
          while ( parent != null ) {
            if ( parent === dragNode ) {
              allow = false;
              break;
            }
            parent = parent.parent;
          }
        }
      }

      return allow;
    } else {
      return false;
    }
  }

  isValidDragScope ( dragScope : any ) : boolean {
    let dropScope = this.droppableScope;

    if ( dropScope ) {
      if ( typeof dropScope === 'string' ) {
        if ( typeof dragScope === 'string' )
          return dropScope === dragScope;
        else if ( dragScope instanceof Array )
          return ( <Array<any>>dragScope ).indexOf( dropScope ) != -1;
      } else if ( dropScope instanceof Array ) {
        if ( typeof dragScope === 'string' ) {
          return ( <Array<any>>dropScope ).indexOf( dragScope ) != -1;
        } else if ( dragScope instanceof Array ) {
          for ( let s of dropScope ) {
            for ( let ds of dragScope ) {
              if ( s === ds ) {
                return true;
              }
            }
          }
        }
      }
      return false;
    } else {
      return true;
    }
  }

  getBlockableElement () : HTMLElement {
    return this.el.nativeElement.children[ 0 ];
  }

  ngOnDestroy () {
    if ( this.dragStartSubscription ) {
      this.dragStartSubscription.unsubscribe();
    }

    if ( this.dragStopSubscription ) {
      this.dragStopSubscription.unsubscribe();
    }
  }
}

@Component( {
  selector  : 'p-treeNode',
  template  : `
    <ng-template [ngIf]="node">
      <li *ngIf="tree.droppableNodes" class="ui-treenode-droppoint"
          [ngClass]="{'ui-treenode-droppoint-active ui-state-highlight':draghoverPrev}"
          (drop)="onDropPoint($event,-1)" (dragover)="onDropPointDragOver($event)"
          (dragenter)="onDropPointDragEnter($event,-1)"
          (dragleave)="onDropPointDragLeave($event)"></li>
      <li *ngIf="!tree.horizontal"
          [ngClass]="['ui-treenode',node.styleClass||'', isLeaf() ? 'ui-treenode-leaf': '', node.id ? 'ui-treenode-'+ node.id : '' ]">
        <div class="ui-treenode-content" role="treeitem" (click)="onNodeClick($event)"
             (contextmenu)="onNodeRightClick($event)" (touchend)="onNodeTouchEnd()"
             (drop)="onDropNode($event)" (dragover)="onDropNodeDragOver($event)"
             (dragenter)="onDropNodeDragEnter($event)"
             (dragleave)="onDropNodeDragLeave($event)"
             [draggable]="tree.draggableNodes" (dragstart)="onDragStart($event)"
             (dragend)="onDragStop($event)" tabIndex="0"
             [ngClass]="{'ui-treenode-selectable':tree.selectionMode && node.selectable !== false,'ui-treenode-dragover':draghoverNode, 'ui-treenode-content-selected':isSelected()}"
             (keydown)="onKeyDown($event)" [attr.aria-posinset]="this.index + 1"
             [attr.aria-expanded]="this.node.expanded" [attr.aria-selected]="isSelected()">

          <!-- [ Arrow icons ] TOOO: Using random icons in the meantime. If or when plus or minus icons are provided by UX, replace these -->
          <span class="ui-tree-toggler" (click)="toggle($event)">
            <svg *ngIf="node.expanded" class="sym-smbl--arrow-small sym-smbl--black-80">
              <use href="#sym-smbl__arrow-chevron"></use>
            </svg>
            <svg *ngIf="!node.expanded" class="sym-smbl--arrow-small sym-smbl--arrow-right sym-smbl--black-80">
              <use href="#sym-smbl__arrow-chevron"></use>
            </svg>
          </span>

          <div class="ui-tree-selectable-area" [ngClass]="{'ui-state-highlight': (tree.selectionMode !== 'checkbox' && tree.selectionMode !== 'checkbox-single' && isSelected()) || ((tree.selectionMode === 'checkbox' || tree.selectionMode === 'checkbox-single') && isActive())}">
            <div class="ui-chkbox" *ngIf="(tree.selectionMode == 'checkbox' || tree.selectionMode == 'checkbox-single') && node.selectable !== false">
              <div class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"
                   (click)="onCheckboxClick($event)">
                <svg class="sym-smbl__form-checkbox" [ngClass]="{ 'sym-smbl--checked':isSelected() || node.partialSelected,'sym-smbl--partial':node.partialSelected }">
                  <use href="#sym-smbl__form-checkbox"></use>
                </svg>
              </div>
            </div>

            <!-- [ User defined SVG Icons ] -->
            <span class="ui-treenode-icon-svg">
              <svg *ngIf="getIconClassName()" [attr.class]="getIconClassName()">
               <use [attr.href]="getIconId()"></use>
              </svg>
            </span>

            <span class="ui-treenode-label ui-corner-all">
              <span *ngIf="!tree.getTemplateForNode(node)">{{node.label}}</span>
              <span *ngIf="tree.getTemplateForNode(node)">
                <ng-container *ngTemplateOutlet="tree.getTemplateForNode(node); context: {$implicit: node}"></ng-container>
              </span>
            </span>
          </div>
        </div>
        <ul class="ui-treenode-children"
            style="display: none;"
            *ngIf="node.children && (tree.renderAll || node.expanded || node.childrenInit)"
            [style.display]="node.expanded ? 'block' : 'none'"
            role="group">
            <p-treeNode *ngFor="let childNode of node.children;let firstChild=first;let lastChild=last; let index=index; trackBy: tree.nodeTrackBy"
              [node]="childNode" [parentNode]="node"
              [firstChild]="firstChild" [lastChild]="lastChild"
              [index]="index">
            </p-treeNode>
        </ul>
      </li>
      <li *ngIf="tree.droppableNodes&&lastChild" class="ui-treenode-droppoint"
          [ngClass]="{'ui-treenode-droppoint-active ui-state-highlight':draghoverNext}"
          (drop)="onDropPoint($event,1)" (dragover)="onDropPointDragOver($event)"
          (dragenter)="onDropPointDragEnter($event,1)"
          (dragleave)="onDropPointDragLeave($event)"></li>
      <table *ngIf="tree.horizontal" [class]="node.styleClass">
        <tbody>
        <tr>
          <td class="ui-treenode-connector" *ngIf="!root">
            <table class="ui-treenode-connector-table">
              <tbody>
              <tr>
                <td [ngClass]="{'ui-treenode-connector-line':!firstChild}"></td>
              </tr>
              <tr>
                <td [ngClass]="{'ui-treenode-connector-line':!lastChild}"></td>
              </tr>
              </tbody>
            </table>
          </td>
          <td class="ui-treenode" [ngClass]="{'ui-treenode-collapsed':!node.expanded}">
            <div class="ui-treenode-content ui-state-default ui-corner-all"
                 [ngClass]="{'ui-treenode-selectable':tree.selectionMode,'ui-state-highlight':isSelected()}"
                 (click)="onNodeClick($event)"
                 (contextmenu)="onNodeRightClick($event)"
                 (touchend)="onNodeTouchEnd()">

              <!-- [ +|- icons ] TOOO: Using random icons in the meantime. If or when plus or minus icons are provided by UX, replace these -->
              <span class="ui-tree-toggler" *ngIf="!isLeaf()" (click)="toggle($event)">
                <svg *ngIf="node.expanded" class="sym-smbl--arrow-small sym-smbl--black-80">
                  <use href="#sym-smbl__arrow-chevron"></use>
                </svg>
                <svg *ngIf="!node.expanded" class="sym-smbl--arrow-small sym-smbl--arrow-right sym-smbl--black-80">
                  <use href="#sym-smbl__arrow-chevron"></use>
                </svg>
              </span>

              <!-- [ User defined icons ]-->
              <span class="ui-treenode-icon-svg">
                <svg *ngIf="getIconClassName()" [attr.class]="getIconClassName()">
                  <use [attr.href]="getIconId()"></use>
                </svg>
              </span>

              <span class="ui-treenode-label ui-corner-all">
                <span *ngIf="!tree.getTemplateForNode(node)">{{node.label}}</span>
                <span *ngIf="tree.getTemplateForNode(node)">
                  <ng-container *ngTemplateOutlet="tree.getTemplateForNode(node); context: {$implicit: node}"></ng-container>
                </span>
              </span>
            </div>
          </td>
          <td class="ui-treenode-children-container"
              *ngIf="node.children && (tree.renderAll || node.expanded ||  node.childrenInit)"
              [style.display]="node.expanded ? 'table-cell' : 'none'">
            <div class="ui-treenode-children">
              <p-treeNode *ngFor="let childNode of node.children;let firstChild=first;let lastChild=last; trackBy: tree.nodeTrackBy"
                [node]="childNode"
                [firstChild]="firstChild"
                [lastChild]="lastChild">
              </p-treeNode>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </ng-template>
  `,
  providers : [ DomHandler ]
} )
export class UITreeNode implements OnInit {

  @Input() node : TreeNode;

  @Input() parentNode : TreeNode;

  @Input() root : boolean;

  @Input() index : number;

  @Input() firstChild : boolean;

  @Input() lastChild : boolean;

  @Input() isCheckbox : boolean;

  constructor (
    @Inject( forwardRef( () => Tree ) ) public tree : Tree,
    public domHandler : DomHandler
  ) {
  }

  draghoverPrev : boolean;

  draghoverNext : boolean;

  draghoverNode : boolean;

  ngOnInit () {
    this.node.parent = this.parentNode;
    if (this.parentNode) {
      // This allows the rendered children node to stay in DOM even after toggle close
      // when it toggled open, it's much faster as it's already initialized.
      this.parentNode.childrenInit = true;
    }
    if (this.tree.dataKey && this.tree.selectedIds && this.tree.selectedIds.indexOf(this.node[this.tree.dataKey]) > -1) {
      this.tree.updateSelections(this.node);
    }
  }

  ngAfterViewInit () {
  }

  getIconClassName () : string {
    return this.getIcon( 'clsName' );
  }

  getIconId () : string {
    return '#' + this.getIcon( 'id' );
  }

  private getIcon ( key ) : string {
    let icon : string;

    if ( this.node.icon && this.node.icon[ key ] ) {
      icon = this.node.icon[ key ];
    } else if ( this.node.expanded && this.node.children && this.node.children.length ) {
      if ( this.node.expandedIcon && this.node.expandedIcon[ key ] ) {
        icon = this.node.expandedIcon[ key ];
      }
    } else {
      if ( this.node.collapsedIcon && this.node.collapsedIcon[ key ] ) {
        icon = this.node.collapsedIcon[ key ];
      }
    }

    return icon;
  }

  isLeaf () {
    return this.node.leaf === false ? false : !( this.node.children && this.node.children.length );
  }

  toggle ( event : Event ) {
    if ( this.node.expanded )
      this.collapse( event );
    else
      this.expand( event );
  }

  expand ( event : Event ) {
    this.node.expanded = true;
    this.tree.onNodeExpand.emit( { originalEvent : event, node : this.node } );
  }

  collapse ( event : Event ) {
    this.node.expanded = false;
    this.tree.onNodeCollapse.emit( { originalEvent : event, node : this.node } );
  }

  onNodeClick ( event : MouseEvent ) {
    this.tree.onNodeClick( event, this.node );
  }

  onCheckboxClick ( event : MouseEvent ) {
    event.stopPropagation();
    this.tree.onCheckboxClick( event, this.node );
  }

  onNodeTouchEnd () {
    this.tree.onNodeTouchEnd();
  }

  onNodeRightClick ( event : MouseEvent ) {
    this.tree.onNodeRightClick( event, this.node );
  }

  isSelected () {
    return this.tree.isSelected( this.node );
  }

  isActive () {
    return this.tree.isActive( this.node );
  }

  onDropPoint ( event : Event, position : number ) {
    event.preventDefault();
    let dragNode              = this.tree.dragNode;
    let dragNodeIndex         = this.tree.dragNodeIndex;
    let dragNodeScope         = this.tree.dragNodeScope;
    let isValidDropPointIndex = this.tree.dragNodeTree === this.tree ? ( position === 1 || dragNodeIndex !== this.index - 1 ) : true;

    if ( this.tree.allowDrop( dragNode, this.node, dragNodeScope ) && isValidDropPointIndex ) {
      if ( this.tree.validateDrop ) {
        this.tree.onNodeDrop.emit( {
          originalEvent : event,
          dragNode      : dragNode,
          dropNode      : this.node,
          dropIndex     : this.index,
          accept        : () => {
            this.processPointDrop( dragNode, dragNodeIndex, position );
          }
        } );
      } else {
        this.processPointDrop( dragNode, dragNodeIndex, position );
        this.tree.onNodeDrop.emit( {
          originalEvent : event,
          dragNode      : dragNode,
          dropNode      : this.node,
          dropIndex     : this.index
        } );
      }
    }

    this.draghoverPrev = false;
    this.draghoverNext = false;
  }

  processPointDrop ( dragNode, dragNodeIndex, position ) {
    let newNodeList = this.node.parent ? this.node.parent.children : this.tree.value;
    this.tree.dragNodeSubNodes.splice( dragNodeIndex, 1 );
    let dropIndex = this.index;

    if ( position < 0 ) {
      dropIndex = ( this.tree.dragNodeSubNodes === newNodeList ) ? ( ( this.tree.dragNodeIndex > this.index ) ? this.index : this.index - 1 ) : this.index;
      newNodeList.splice( dropIndex, 0, dragNode );
    } else {
      dropIndex = newNodeList.length;
      newNodeList.push( dragNode );
    }

    this.tree.dragDropService.stopDrag( {
      node     : dragNode,
      subNodes : this.node.parent ? this.node.parent.children : this.tree.value,
      index    : dragNodeIndex
    } );
  }

  onDropPointDragOver ( event ) {
    event.dataTransfer.dropEffect = 'move';
    event.preventDefault();
  }

  onDropPointDragEnter ( event : Event, position : number ) {
    if ( this.tree.allowDrop( this.tree.dragNode, this.node, this.tree.dragNodeScope ) ) {
      if ( position < 0 ) {
        this.draghoverPrev = true;
      } else {
        this.draghoverNext = true;
      }
    }
  }

  onDropPointDragLeave ( event : Event ) {
    this.draghoverPrev = false;
    this.draghoverNext = false;
  }

  onDragStart ( event ) {
    if ( this.tree.draggableNodes && this.node.draggable !== false ) {
      event.dataTransfer.setData( 'text', 'data' );

      this.tree.dragDropService.startDrag( {
        tree     : this,
        node     : this.node,
        subNodes : this.node.parent ? this.node.parent.children : this.tree.value,
        index    : this.index,
        scope    : this.tree.draggableScope
      } );
    } else {
      event.preventDefault();
    }
  }

  onDragStop ( event ) {
    this.tree.dragDropService.stopDrag( {
      node     : this.node,
      subNodes : this.node.parent ? this.node.parent.children : this.tree.value,
      index    : this.index
    } );
  }

  onDropNodeDragOver ( event ) {
    event.dataTransfer.dropEffect = 'move';
    if ( this.tree.droppableNodes ) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  onDropNode ( event ) {
    if ( this.tree.droppableNodes && this.node.droppable !== false ) {
      event.preventDefault();
      event.stopPropagation();
      let dragNode = this.tree.dragNode;
      if ( this.tree.allowDrop( dragNode, this.node, this.tree.dragNodeScope ) ) {
        if ( this.tree.validateDrop ) {
          this.tree.onNodeDrop.emit( {
            originalEvent : event,
            dragNode      : dragNode,
            dropNode      : this.node,
            index         : this.index,
            accept        : () => {
              this.processNodeDrop( dragNode );
            }
          } );
        } else {
          this.processNodeDrop( dragNode );
          this.tree.onNodeDrop.emit( {
            originalEvent : event,
            dragNode      : dragNode,
            dropNode      : this.node,
            index         : this.index
          } );
        }
      }
    }

    this.draghoverNode = false;
  }

  processNodeDrop ( dragNode ) {
    let dragNodeIndex = this.tree.dragNodeIndex;
    this.tree.dragNodeSubNodes.splice( dragNodeIndex, 1 );

    if ( this.node.children ) {
      this.node.children.push( dragNode );
    } else {
      this.node.children = [ dragNode ];
    }

    this.tree.dragDropService.stopDrag( {
      node     : dragNode,
      subNodes : this.node.parent ? this.node.parent.children : this.tree.value,
      index    : this.tree.dragNodeIndex
    } );
  }

  onDropNodeDragEnter ( event ) {
    if ( this.tree.droppableNodes && this.node.droppable !== false && this.tree.allowDrop( this.tree.dragNode, this.node, this.tree.dragNodeScope ) ) {
      this.draghoverNode = true;
    }
  }

  onDropNodeDragLeave ( event ) {
    if ( this.tree.droppableNodes ) {
      let rect = event.currentTarget.getBoundingClientRect();
      if ( event.x > rect.left + rect.width || event.x < rect.left || event.y >= Math.floor( rect.top + rect.height ) || event.y < rect.top ) {
        this.draghoverNode = false;
      }
    }
  }

  onKeyDown ( event : KeyboardEvent ) {
    const nodeElement = ( <HTMLDivElement>event.target ).parentElement.parentElement;

    switch ( event.which ) {
      //down arrow
      case 40:
        const listElement = nodeElement.children[ 0 ].children[ 1 ];
        if ( listElement ) {
          this.focusNode( listElement.children[ 0 ] );
        } else {
          const nextNodeElement = nodeElement.nextElementSibling;
          if ( nextNodeElement ) {
            this.focusNode( nextNodeElement );
          } else {
            let nextSiblingAncestor = this.findNextSiblingOfAncestor( nodeElement );
            if ( nextSiblingAncestor ) {
              this.focusNode( nextSiblingAncestor );
            }
          }
        }

        event.preventDefault();
        break;

      //up arrow
      case 38:
        if ( nodeElement.previousElementSibling ) {
          this.focusNode( this.findLastVisibleDescendant( nodeElement.previousElementSibling ) );
        } else {
          let parentNodeElement = this.getParentNodeElement( nodeElement );
          if ( parentNodeElement ) {
            this.focusNode( parentNodeElement );
          }
        }

        event.preventDefault();
        break;

      //right arrow
      case 39:
        if ( !this.node.expanded ) {
          this.expand( event );
        }

        event.preventDefault();
        break;

      //left arrow
      case 37:
        if ( this.node.expanded ) {
          this.collapse( event );
        }

        event.preventDefault();
        break;

      //enter
      case 13:
        this.tree.onNodeClick( event, this.node );
        event.preventDefault();
        break;

      default:
        //no op
        break;
    }
  }

  findNextSiblingOfAncestor ( nodeElement ) {
    let parentNodeElement = this.getParentNodeElement( nodeElement );
    if ( parentNodeElement ) {
      if ( parentNodeElement.nextElementSibling ) {
        return parentNodeElement.nextElementSibling;
      } else {
        return this.findNextSiblingOfAncestor( parentNodeElement );
      }
    } else {
      return null;
    }
  }

  findLastVisibleDescendant ( nodeElement ) {
    const childrenListElement = nodeElement.children[ 0 ].children[ 1 ];
    if ( childrenListElement ) {
      const lastChildElement = childrenListElement.children[ childrenListElement.children.length - 1 ];

      return this.findLastVisibleDescendant( lastChildElement );
    } else {
      return nodeElement;
    }
  }

  getParentNodeElement ( nodeElement ) {
    const parentNodeElement = nodeElement.parentElement.parentElement.parentElement;

    return parentNodeElement.tagName === 'P-TREENODE' ? parentNodeElement : null;
  }

  focusNode ( element ) {
    element.children[ 0 ].children[ 0 ].focus();
  }
}

@NgModule( {
  imports      : [ CommonModule ],
  exports      : [ Tree, SharedModule ],
  declarations : [ Tree, UITreeNode ]
} )
export class TreeModule {
}
