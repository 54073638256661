/**
 * A Binary tree for query expressions.
 */
import {ParserToken} from './parser-token';

export class BinaryNode {
  parent?: BinaryNode;
  left?: BinaryNode | ParserToken;
  leftParen?: ParserToken;
  op?: ParserToken;
  right?: BinaryNode | ParserToken;
  rightParen?: ParserToken;

  /**
   * Default constructor.
   *
   * @param left
   * @param op
   * @param right
   */
  constructor(left?: BinaryNode | ParserToken, op?: ParserToken, right?: BinaryNode | ParserToken) {
    this.left = left;
    this.op = op;
    this.right = right;
  }
}
