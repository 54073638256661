// Why @dynamic? See https://github.com/angular/angular/issues/19698#issuecomment-349359036
// @dynamic
export class ArrayUtils {
  // See https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_findindex
  static findIndex = (arr: any[], fn: (item: any) => boolean): number => {
    // Lazily redefine function so `if` statement is only evaluated once
    if (Array.prototype.findIndex) {
      ArrayUtils.findIndex = (arr: any[], fn: (item: any) => boolean): number => {
        return arr.findIndex(fn);
      };
    } else {
      // Fallback for MSIE
      ArrayUtils.findIndex = (arr: any[], fn: (item: any) => boolean): number => {
        for (let i = 0; i < arr.length; i++) {
          if (fn(arr[i])) {
            return i;
          }
        }
        return -1;
      };
    }

    // Return the result for the first invocation
    return ArrayUtils.findIndex(arr, fn);
  }

  // See https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_last
  static last = (arr: any[]): any => {
    if (arr && arr.length > 0) {
      return arr[arr.length - 1];
    } else {
      return undefined;
    }
  }

  // Swap array position from old index to new index
  // This will change the arr passed as using splice
  static move (arr, oldIndex, newIndex) {
      // https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another
      // if newIndex doesn't exist, add to the end.
      if (oldIndex === newIndex) {
        return arr;
      }

      if (newIndex < 0) {
        newIndex = 0;
      }

      if (newIndex > arr.length - 1) {
        newIndex = arr.length;
      }

      arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
      return arr;
  };


//TODO: Add unit test
//https://stackoverflow.com/questions/7837456/how-to-compare-arrays-in-javascript
  static equals (arr1, arr2) {
    // if the other array is a falsy value, return
    if (!arr1 || !arr2)
        return false;

    // compare lengths - can save a lot of time
    if (arr1.length != arr2.length)
        return false;

    for (var i = 0, l=arr1.length; i < l; i++) {
        // Check if we have nested arrays
        if (arr1[i] instanceof Array && arr2[i] instanceof Array) {
            // recurse into the nested arrays
            if (!arr1[i].equals(arr2[i]))
                return false;
        }
        else if (arr1[i] != arr2[i]) {
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false;
        }
    }
    return true;
  }
}
