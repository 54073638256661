import {NgModule, Pipe, PipeTransform, SecurityContext} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe( {
  name : 'highlight'
} )

export class HighlightSearch implements PipeTransform {
  constructor ( private sanitizer : DomSanitizer ) {
  }

  transform ( text : string, search ) : string {
    if ( search && text ) {
      const pattern = search.replace( /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&' );
      const regex = new RegExp( pattern, 'gi' );

      return text.replace( regex, ( match ) => {
        return this.sanitizer.sanitize( SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml( `<mark class="sym-highlight-match">${match}</mark>` ) );
      } );
    } else {
      return text;
    }
  }
}

@NgModule( {
  imports      : [ ],
  exports      : [ HighlightSearch ],
  declarations : [ HighlightSearch ]
} )

export class HighlightSearchModule {
}
