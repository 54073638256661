import {Injectable} from '@angular/core';
import {BaseParserService} from './base-parser.service';
import {Stream} from './stream';
import {ParserToken} from './parser-token';
import {BinaryNode} from './binary-node';
import {
  IParserError,
  IParserFieldLookup,
  IParserFieldValueConfiguration,
  IParserMacroConfiguration,
  IParserMacroLookup,
  ParserFieldDataType,
  ParserFieldName,
  ParserFieldSimpleValue,
  ParserQueryOperator
} from '../../components/common/parser';
import {ArrayUtils} from '../../components/utils/array.utils';
import {SearchToken, SearchTokenType} from './search-token';
import {FormControl, ValidationErrors} from "@angular/forms";

/**
 * A parser recognizing lucene grammar.
 *
 * Each component instance that uses this parser should use its own unique parser instance. Since by default, Angular
 * dependency injection re-uses a shared instance (see https://angular.io/guide/architecture-services), make sure to
 * configure the component like so:
 * ```
 * @Component( {
 *   providers: [ LuceneParserService ]
 * } )
 * ```
 *
 * Built-in restrictions: https://sed-confluence.broadcom.net/display/ATP/Kibana+%28ATP%29+Search+Syntax
 * Note that:
 * - all terms must use operators (use "a OR b", not "a b")
 * - field names cannot contain spaces (use display names instead)
 * - there's no NOT operator (use -)
 *
 * Existing parsers such as the below were evaluated but due to heavy regex usage, were too slow when dealing with complex queries
 * - https://github.com/thoward/lucene-query-parser.js
 *
 * @author romelus
 * @author maarten
 */
@Injectable({ providedIn : 'root' })
export class LuceneParserService extends BaseParserService {
  // See https://lucene.apache.org/core/2_9_4/queryparsersyntax.html for Lucene special characters
  // Added plus '+' and forward slash '/' to this list
  protected specialChars = '+ / - & | ! ( ) { } [ ] ^ " ~ * ? : \\'.split(' ');

  // See https://lucene.apache.org/core/2_9_4/queryparsersyntax.html for Lucene escape character
  protected escapeChar = '\\';

  // For parser configuration field name validation only. Note that field name cannot contain "-".
  protected fieldNameRegex = /[a-zA-Z0-9_.]+/;

  // The character that separates field from value
  protected fieldNameAndValueSeparator = ':';

  // Matches the character sequence needed to escapes a Lucene special character
  protected escapeRegex = new RegExp(this.escapeRegexChars(this.escapeChar));

  // Note: `queryRegex` and `incompleteQueryRegex` ignore `fieldNameRegex` or parser configuration.
  // If `fieldNameRegex` was taken into account, invalid input like "sear\:ch: foo" would end up as two (because the
  // space separates them) freeform searches: "sear\:ch:" and "foo".
  // Why? Because the `incompleteQueryRegex` then incorrectly concludes this is not something that looks like the start
  // of a query, as the ":" is not included in `fieldNameRegex`. (See `Freeform` logic inside of `_extractToken()`.)
  //protected queryRegex = new RegExp('^[-+]?' + this.escapeRegexChars(this.fieldNameRegex.source) + '\\s*' + this.fieldNameAndValueSeparator + '\s*.+$');   // "key:value"
  //protected incompleteQueryRegex = new RegExp('(^\\s*' + this.escapeRegexChars(this.fieldNameAndValueSeparator) + '\\s*$)|(^[-+]?' + this.fieldNameRegex.source + '\\s*' + this.escapeRegexChars(this.fieldNameAndValueSeparator) + '\\s*$)|(^\\s*' + this.escapeRegexChars(this.fieldNameAndValueSeparator) + '.*$)');   // ":", "key:", or ":value"

  // Matches anything that looks like "field:value", regardless of validity
  protected queryRegex = new RegExp('^[-+]?.+\\s*(?<!' + this.escapeRegexChars(this.escapeChar) + ')' + this.escapeRegexChars(this.fieldNameAndValueSeparator) + '\\s*.+$');   // "key:value"

  // Matches anything that looks like ":", "field:", or ":value", regardless of validity
  protected incompleteQueryRegex = new RegExp('(^\\s*' + this.escapeRegexChars(this.fieldNameAndValueSeparator) + '\\s*$)|(^[-+]?.+\\s*(?<!' + this.escapeRegexChars(this.escapeChar) + ')' + this.escapeRegexChars(this.fieldNameAndValueSeparator) + '\\s*$)|(^\\s*(?<!' + this.escapeRegexChars(this.escapeChar) + ')' + this.escapeRegexChars(this.fieldNameAndValueSeparator) + '\\s*.+$)');   // ":", "key:", or ":value"

  // Matches any escaped Lucene special character
  protected escapedSpecialCharRegex = new RegExp(this.escapeRegexChars(this.escapeChar) + '([' + this.escapeRegexCharacterClassChars(this.specialChars.join('')) + '])', 'g');

  // Matches any non-escaped Lucene special character
  protected nonEscapedSpecialCharRegex = new RegExp('(?<!' + this.escapeRegexChars(this.escapeChar) + ')([' + this.escapeRegexCharacterClassChars(this.specialChars.join('')) + '])', 'g');

  // Matches any non-escaped Lucene special character, except a non-escaped Lucene escape character
  protected nonEscapedSpecialCharWithoutEscapeCharRegex;   // Needed for display string validation, initialized in constructor

  // Matches any Lucene escape character that is not used to escape a Lucene special character
  protected escapeCharUsedWithoutSpecialCharRegex;   // Needed for display string validation, initialized in constructor

  // Matches the non-escaped character that separates field from value
  protected nonEscapedSeparatorRegEx = new RegExp('(?<!' + this.escapeRegexChars(this.escapeChar) + ')' + this.escapeRegexChars(this.fieldNameAndValueSeparator));   // Results in /(?<!\\):/ where (?<!\\) is a negative lookaround ("lookaround" meaning not included in the match) asserting the previous character is not our escape character, see https://stackoverflow.com/a/3735908

  protected whitespaceRegex = /\s/;
  protected quoteRegex = /"/;
  protected bracketRegex = /[\[\]{}]/;
  protected slashRegex = /\//;

  // To avoid having to disallow the lowercase words "or" or "and" in display strings (for the DisplayStringParser),
  // keep operators in uppercase
  protected logOps = ['OR', '||', 'AND', '&&'];

  protected oParen = '(';
  protected cParen = ')';
  protected negatePrefix = '-';
  protected precedenceMap = {query: 1, or: 2, and: 3};
  protected stream: Stream;
  protected balancedParenStack: string[] = [];
  protected prattTree: BinaryNode | ParserToken;

  constructor() {
    super();

    // Separator is special, make sure it's part of the set of characters that needs to be escaped if not used as separator
    if (this.specialChars.indexOf(this.fieldNameAndValueSeparator) < 0) {
      this.specialChars.push(this.fieldNameAndValueSeparator);
    }

    const escapeIndex = this.specialChars.indexOf(this.escapeChar);
    if (escapeIndex >= 0) {
      let specialCharsWithoutEscapeChar = [...this.specialChars];
      specialCharsWithoutEscapeChar.splice(escapeIndex, 1);
      this.nonEscapedSpecialCharWithoutEscapeCharRegex = new RegExp('(?<!' + this.escapeRegexChars(this.escapeChar) + ')[' + this.escapeRegexCharacterClassChars(specialCharsWithoutEscapeChar.join('')) + ']');
      this.escapeCharUsedWithoutSpecialCharRegex = new RegExp(this.escapeRegexChars(this.escapeChar) + '(?![' + this.escapeRegexCharacterClassChars(this.specialChars.join('')) + '])');
    }
  }

  get input(): string {
    return this.expression;
  }
  get expressionTree(): any {
    return this.prattTree;
  }
  get hasNext(): boolean {
    return this.peek() !== undefined;
  }
  peek(): ParserToken {
    const resetLocation = this.stream.position;
    const retVal = this._extractToken();
    this.stream.reset(resetLocation);
    return retVal;
  }
  next(): ParserToken {
    return this.stream.hasNext ? this._extractToken() : undefined;
  }
  reset(location: number): LuceneParserService {
    this.stream.reset(location);
    return this;
  }

  protected _extractToken(): ParserToken {
    let token: ParserToken;
    let slice = '';
    let start = this.stream.position;
    let waitingForClosingQuote = false;
    let waitingForClosingBracket = false;
    let waitingForClosingSlash = false;

    do {
      if (!waitingForClosingQuote && !waitingForClosingBracket && !waitingForClosingSlash) {
        let moveStart = false;
        if (slice.length === 0 && this._isWhiteSpace(this.stream.peek())) {
          moveStart = true;
        }
        this._skipWhiteSpace();
        if (moveStart) {
          start = this.stream.position;
        }
      }
      const curr = this.stream.next();
      let next = this.stream.peek();
      if (curr === void 0) {
        break;
      }
      slice += curr;

      let skipCurrCheck = false;
      if (this._isEscape(curr) && this._anyEqual(this.specialChars, next)) {
        // Avoid next char to be interpreted as having special meaning
        slice += next;
        this.reset(this.stream.position + 1);

        // TODO: check if nextCharIsTerminal and same logic as below... ignore curr though
        next = this.stream.peek();
        skipCurrCheck = true;
      } else {
        if (this._isSlash(curr) && !this._anyEqual([waitingForClosingQuote, waitingForClosingBracket], true)) {
          waitingForClosingSlash = !waitingForClosingSlash;
        }
        if (this._isQuote(curr) && !this._anyEqual([waitingForClosingBracket, waitingForClosingSlash], true)) {
          waitingForClosingQuote = !waitingForClosingQuote;
        }
        if (this._isBracket(curr) && !this._anyEqual([waitingForClosingQuote, waitingForClosingSlash], true)) {
          waitingForClosingBracket = !waitingForClosingBracket;
        }
        if (this._anyEqual([waitingForClosingQuote, waitingForClosingBracket, waitingForClosingSlash], true)) {
          continue;
        }
      }

      const nextCharIsTerminal = this._isWhiteSpace(next) || this._anyEqual([this.oParen, this.cParen, undefined], next);
      // console.log('input', this.stream.input, 'slice', slice, 'nextCharIsTerminal', nextCharIsTerminal);
      if (!skipCurrCheck && this._anyEqual([this.oParen, this.cParen], curr)) {
        //console.log('Found grouping: ' + (curr === this.oParen ? 'open' : 'close') + ' parenthesis');
        token = new ParserToken(SearchTokenType.Parenthesis, curr, this.stream.input.substring(start, this.stream.position), start, this.stream.position);
        slice = ''; // reset slice
        break;
      } else if (nextCharIsTerminal && this._isLogOp(slice)) {
        //console.log('Found operator: ' + slice);
        token = new ParserToken(SearchTokenType.LogicalOperator, slice, this.stream.input.substring(start, this.stream.position), start, this.stream.position);
        slice = ''; // reset slice
        break;
      } else if (nextCharIsTerminal && this._isQuery(slice)) {
        //console.log('Found query: ' + slice);
        token = new ParserToken(SearchTokenType.Query, slice, this.stream.input.substring(start, this.stream.position), start, this.stream.position);
        slice = ''; // reset slice
        break;
      } else if (nextCharIsTerminal && slice.length > 0  && !this._isIncompleteQuery(slice)) {
        const nextChar = this._getNextNonWhiteSpace();
        if (this._anyEqual([this.oParen, this.cParen, undefined], nextChar) || (nextChar !== this.fieldNameAndValueSeparator && !this._isQuery(slice + nextChar))) {
          //console.log('Found freeform: ' + slice);
          token = new ParserToken(SearchTokenType.Freeform, slice, this.stream.input.substring(start, this.stream.position), start, this.stream.position);
          slice = ''; // reset slice
          break;
        }
      }
    } while (this.stream.hasNext);

    // TODO: just do if (slice) { Invalid }?
    if (waitingForClosingBracket || waitingForClosingQuote || waitingForClosingSlash) {
      // Case when there is no closing quote, bracket or slash
      token = new ParserToken(SearchTokenType.Invalid, slice, this.stream.input.substring(start, this.stream.position), start, this.stream.position);
    } else if (this._isIncompleteQuery(slice)) {
      // If left with an incomplete query, still consider it a query, albeit an invalid one
      // TODO: this will treat a query with an invalid field name, e.g. "field-2:value", as valid, which is not correct
      //       better would be to use SearchTokenType.Invalid here too, and update any code that tests for SearchTokenType.Query to also handle Invalid
      const rawSlice = this.stream.input.substring(start, this.stream.position);
      //console.log('Found incomplete query: "' + slice + '", raw: "' + rawSlice + '"');
      token = new ParserToken(SearchTokenType.Query, slice, rawSlice.trim(), start, this.stream.position - (rawSlice.length - rawSlice.trim().length));
    }

    return token;
  }
  protected _anyEqual(arr, o) : boolean {
    return arr.some(e => e === o);
  }
  protected _isLogOp(string: string) : boolean {
    // Note Lucene parser is more lenient and matches both lower-, upper- and mixed-case operators.
    // Not so for the DisplayStringParser.
    return this.logOps.some(lop => string.toUpperCase() === lop);
  }
  protected _isQuery(string: string) : boolean {
    return this.queryRegex.test(string);
  }
  protected _isIncompleteQuery(string: string) : boolean {
    return this.incompleteQueryRegex.test(string);
  }
  protected _skipWhiteSpace() : boolean {
    let sawOne = false;
    while (this._isWhiteSpace(this.stream.peek())) {
      sawOne = true;
      this.stream.next();
    }
    return sawOne;
  }
  protected _isWhiteSpace(char: string) : boolean {
    return char && this.whitespaceRegex.test(char);
  }
  protected _isQuote(char: string) : boolean {
    return char && this.quoteRegex.test(char);
  }
  protected _isBracket(char: string) : boolean {
    return char && this.bracketRegex.test(char);
  }
  protected _isSlash(char: string) : boolean {
    return char && this.slashRegex.test(char);
  }
  protected _isEscape(char: string) : boolean {
    return char && this.escapeRegex.test(char);
  }
  protected _getNextNonWhiteSpace() : string {
    let retVal;
    const origLocation = this.stream.position;
    if (this.stream.hasNext) {
      this._skipWhiteSpace(); // move past any whitespaces
      retVal = this.stream.peek();
      this.reset(origLocation); // reset to original location
    }
    return retVal;
  }

  /** @see https://tdop.github.io */
  protected _buildPrattTree(rbp: number, parenStack: string[]): BinaryNode | ParserToken { // right binding power
    let left: BinaryNode | ParserToken = this.next();
    if (!left) {
      return undefined;
    }

    if (left.type === SearchTokenType.Parenthesis) {
      const paren: ParserToken = left;
      const right: BinaryNode | ParserToken = this._buildPrattTree(0, parenStack);
      const node = (right instanceof BinaryNode) ? right : new BinaryNode(right);
      this._consumeParen(node, paren, parenStack);
      const peek: ParserToken = this.peek();
      if (peek && !(peek instanceof BinaryNode) && peek.type === SearchTokenType.Parenthesis) {
        this._consumeParen(node, this.next(), parenStack);
      }
      left = node;
    }

    while (rbp < this._lbp(this.peek())) {
      left = this._led(left, this.next(), parenStack);
    }

    return left;
  }

  protected _led(left: BinaryNode | ParserToken, token: ParserToken, parenStack: string[]): BinaryNode { // left denotation
    const node = new BinaryNode();
    const right = this._buildPrattTree(1, parenStack);
    if (left instanceof BinaryNode) {
      left.parent = node;
    }
    if (right instanceof BinaryNode) {
      right.parent = node;
    }
    if (token.type === SearchTokenType.LogicalOperator) {
      node.op = token;
    }
    if (left && (left instanceof BinaryNode || left.type === SearchTokenType.Query || left.type === SearchTokenType.Freeform)) {
      node.left = left;
    }
    if (right && (right instanceof BinaryNode || right.type === SearchTokenType.Query || right.type === SearchTokenType.Freeform)) {
      node.right = right;
    }
    return node;
  }

  protected _lbp(token: ParserToken): number { // left binding power
    let retVal = 0;
    if (token) {
      switch (token.type) {
        case SearchTokenType.Query:
        case SearchTokenType.Freeform:
          retVal = this.precedenceMap.query;
          break;
        case SearchTokenType.Parenthesis:
          retVal = 0;
          break;
        case SearchTokenType.LogicalOperator:
          retVal = this.precedenceMap[token.token.toLowerCase()];
          break;
      }
    }
    return retVal;
  }

  protected _consumeParen(node, token: ParserToken, stack: string[]): void {
    if (!token) {
      return;
    }
    if (token.token === this.oParen) {
      if (node && node instanceof BinaryNode && !node.leftParen) {
        node.leftParen = token;
      }
      stack.push(token.token);
    } else {
      if (node && node instanceof BinaryNode && !node.rightParen) {
        node.rightParen = token;
      }
      if (stack.length !== 0 && ArrayUtils.last(stack) === this.oParen) {
        stack.pop();
      } else {
        stack.push(token.token);
      }
    }
  }

  protected _validateSimpleExpression(node: ParserToken, expression: string, errors: IParserError[], onlyNode?: boolean) : void {
    if (!node || errors.length > 0) {
      return;
    }
    if (onlyNode && !~[SearchTokenType.Query, SearchTokenType.Freeform].indexOf(node.type)) {
      errors.push({ code: 'INCOMPLETE_QUERY' });
    } else if (node.type === SearchTokenType.Query) {   // Only queries have key/value pairs
      const kvPair = this.getFieldNameAndValuePair(node.token);
      if (!this.isAllowedField(kvPair[0])) {
        errors.push({ code: 'INVALID_FIELD_NAME', expression: kvPair[0] });
      } else if (!kvPair[1] || kvPair[1].length ===  0) {
        // Value must exist
        errors.push({ code: 'NO_FIELD_VALUE', expression: kvPair[0] + ': ' + kvPair[1] });
      } else {
        const errs = this.isAllowedFieldValue(kvPair[0], kvPair[1]);
        if (errs !== null) {
          errors.push({ code: 'INVALID_FIELD_VALUE', expression: kvPair[0] + ': ' + kvPair[1], validationErrors: errs });
        }
      }
      if (this.macros.hasOwnProperty(kvPair[0])) {
        node.type = SearchTokenType.Macro;
      }
    }
  }

  protected _validateComplexExpression(node: BinaryNode | ParserToken, expression: string, errors: IParserError[]) : void {
    if (!node || errors.length > 0) {
      return;
    }
    if (node instanceof BinaryNode) { // Post-Order traversal
      this._validateComplexExpression(node.left, expression, errors);
      this._validateComplexExpression(node.right, expression, errors);
      this._validComplexForm(node, expression, errors);
    } else {
      this._validateSimpleExpression(node, expression, errors);
    }
  }

  protected _validComplexForm(node: BinaryNode, expression: string, errors: IParserError[]) : void {
    if (!node || errors.length > 0) {
      return;
    }
    let end;
    if (!node.left && node.op) {
      errors.push({ code: 'NO_LEFT_EXPRESSION', expression: expression.slice(0, expression.length) });
    } else if (node.op && !node.right) {
      errors.push({ code: 'NO_RIGHT_EXPRESSION', expression: expression.slice(0, expression.length) });
    } else if (node.left && !node.right && !node.op && !(node.leftParen && node.rightParen)) {   // Last expression is to allow a single term within parenthesis, i.e. `( k:v )`
      end = node.left instanceof BinaryNode ? this._getRightMostToken(node.left).end : node.left.end;
      errors.push({ code: 'NO_OPERATOR_AFTER', expression: expression.slice(0, end) });
    } else if (node.left && node.right && !node.op) {
      end = node.left instanceof BinaryNode ? this._getRightMostToken(node.left).end : node.left.end;
      errors.push({ code: 'NO_OPERATOR_IN_BETWEEN', expression: expression.slice(0, end) });
    } else if (!node.left && !node.right) {
      errors.push({ code: 'NO_QUERY_BODY' });
    }
  }

  protected _getRightMostToken(node: BinaryNode): ParserToken {
    let curr: BinaryNode | ParserToken = node;
    while (curr instanceof BinaryNode && curr.right !== undefined) {
      curr = curr.right;
    }
    return curr as ParserToken;
  }

  escapeSpecialChars(s: string): string {
    if (s) {
      s = s.replace(this.nonEscapedSpecialCharRegex, (matches, capture) => this.escapeChar + capture);
    }
    return s;
  }

  unescapeSpecialChars(s: string): string {
    if (s) {
      s = s.replace(this.escapedSpecialCharRegex, (matches, capture) => capture);
    }
    return s;
  }

  protected removeEscapeCharFromDisplayPair(displayPair: string[]): string[] {
    if (displayPair[0]) {
      displayPair[0] = this.unescapeSpecialChars(displayPair[0]);
    }
    if (displayPair[1]) {
      displayPair[1] = this.unescapeSpecialChars(displayPair[1]);
    }
    return displayPair;
  }

  private getDisplayFieldAndValue(lookup: IParserFieldLookup | IParserMacroLookup, token: string): string[] {
    let displayName = undefined;
    let displayValue = undefined;
    const kvPair = this.getFieldNameAndValuePair(token);
    const configs = lookup[kvPair[0]];
    if (configs) {
      // Deal with ranges and sets
      let value = kvPair[1];
      if (/^([\[{])(.+)\s+TO\s+(.+)([\]}])$/.test(value)) {
        // Range
        let displayValueParts = [ RegExp.$1, RegExp.$2, ' TO ', RegExp.$3, RegExp.$4 ];
        let dfv = this.getDisplayFieldAndValue(lookup, this.createTokenString(kvPair[0], displayValueParts[1], ParserQueryOperator.EQUALS));
        displayValueParts[1] = dfv[1];
        displayValueParts[3] = this.getDisplayFieldAndValue(lookup, this.createTokenString(kvPair[0], displayValueParts[3], ParserQueryOperator.EQUALS))[1];
        return [ dfv[0], displayValueParts.join('') ];
      } else if (/^\[.+(\s+OR\s+.+)+]$/.test(value)) {
        // Set
        value = value.substring(1, value.length - 1);
        let values = value.split(/\s+OR\s+/);
        displayValue = '[';
        values.forEach(value => {
          let dfv = this.getDisplayFieldAndValue(lookup, this.createTokenString(kvPair[0], value, ParserQueryOperator.EQUALS));
          if (displayName === undefined) {
            displayName = dfv[0];
          } else {
            displayValue += ' OR ';
          }
          displayValue += dfv[1];
        });
        displayValue += ']';
        return [ displayName, displayValue ];
      }

      configs.some(config => {
        displayName = config.displayName === false ? undefined : config.displayName || config.name;   // name and displayName are guaranteed to be identical for all configs
        displayValue = kvPair[1];
        const found = (config.values || []).some(value => {
          if (this.isParserFieldValueConfiguration(value)) {
            const v = value as IParserFieldValueConfiguration;
            if (displayValue === String(v.value) || this.equalsTypedValue(v.value, this.convertToFieldType(displayValue, config.type), config.type)) {
              displayValue = v.displayValue;
              return true;   // Exit config.values.some()
            }
          }
        });
        if (found) {
          return true;   // Exit configs.some()
        }

        // If there are no pre-defined values, may still need to convert value (captured as `displayValue`) as needed
        if ((config.values || []).length === 0 && config.type === ParserFieldDataType.Date) {
          let date = this.convertToFieldType(displayValue, config.type) as Date;
          if (!Number.isNaN(date.getTime())) {
            displayValue = date.toLocaleDateString() + ' ' + date.toLocaleTimeString();   // TODO: replace by true localized string
            // TODO: support [date TO date}, etc.
            return true;
          }
        }
      });
    }
    return [ displayName, displayValue ];
  }

  private pushQueryOrFreeformToken(token: ParserToken, tokens: SearchToken[]): void {
    if (token.type === SearchTokenType.Query) {
      const configs = this.fields[this.getFieldNameAndValuePair(token.token)[0]];
      const displayPair = this.removeEscapeCharFromDisplayPair(this.getDisplayFieldAndValue(this.fields, token.token));
      tokens.push(new SearchToken(SearchTokenType.Query, token.token, displayPair[0], displayPair[1], true, token.token[0] === this.negatePrefix, { config: configs, token: token }));
    } else {
      const displayPair = this.removeEscapeCharFromDisplayPair([ token.token ]);
      // getDisplayFieldAndValue removes `negatePrefix`; do the same here
      if (displayPair[0].startsWith(this.negatePrefix)) {
        displayPair[0] = displayPair[0].substring(this.negatePrefix.length);
      }
      tokens.push(new SearchToken(SearchTokenType.Freeform, token.token, displayPair[0], undefined, true, token.token[0] === this.negatePrefix, { token: token }));
    }
  }

  // Get all valid-only search tokens
  protected getSearchTokens(node: BinaryNode | ParserToken, tokens: SearchToken[]): void {
    if (!node) {
      return;
    }

    if (node instanceof BinaryNode) { // In-order traversal
      if (node.leftParen) {
        tokens.push(new SearchToken(SearchTokenType.Parenthesis, node.leftParen.token, undefined, undefined, undefined, undefined, { pairIsLeft: true, token: node.leftParen, matchedPair: node.rightParen }));
      } // left-paren
      this.getSearchTokens(node.left, tokens); // left
      if (node.op) {
        tokens.push(new SearchToken(SearchTokenType.LogicalOperator, node.op.token, node.op.token.toUpperCase(), undefined, undefined, undefined, { token: node.op }));
      } // op
      this.getSearchTokens(node.right, tokens); // right
      if (node.rightParen) {
        tokens.push(new SearchToken(SearchTokenType.Parenthesis, node.rightParen.token, undefined, undefined, undefined, undefined, { pairIsLeft: false, token: node.rightParen, matchedPair: node.leftParen }));
      } // right-paren
    } else {
      if (node.type === SearchTokenType.Macro) {
        const configs = this.macros[this.getFieldNameAndValuePair(node.token)[0]];
        const displayPair = this.removeEscapeCharFromDisplayPair(this.getDisplayFieldAndValue(this.macros, node.token));
        tokens.push(new SearchToken(SearchTokenType.Macro, node.token, displayPair[0], displayPair[1], true, node.token[0] === this.negatePrefix, { config: configs, token: node }));
      } else {
        this.pushQueryOrFreeformToken(node, tokens);
      }
    }
  }

  // Get all search tokens, valid or not
  protected showAllSearchTokens(tokens: SearchToken[]): void {
    this.reset(0);
    let curr;
    while (curr = this.next()) {
      const kvPair = this.getFieldNameAndValuePair(curr.token);
      if (this.macros.hasOwnProperty(kvPair[0])) {
        const configs = this.macros[kvPair[0]];
        const displayPair = this.removeEscapeCharFromDisplayPair(this.getDisplayFieldAndValue(this.macros, curr.token));
        tokens.push(new SearchToken(SearchTokenType.Macro, curr.token, displayPair[0], displayPair[1], true, curr.token[0] === this.negatePrefix, { config: configs, token: curr }));
      } else if (curr.type === SearchTokenType.Parenthesis) {
        tokens.push(new SearchToken(SearchTokenType.Parenthesis, curr.token, undefined, undefined, undefined, undefined, { token: curr }));
      } else if (curr.type === SearchTokenType.LogicalOperator) {
        tokens.push(new SearchToken(SearchTokenType.LogicalOperator, curr.token, curr.token.toUpperCase(), undefined, undefined, undefined, { token: curr }));
      } else {
        this.pushQueryOrFreeformToken(curr, tokens);
      }
    }
  }

  validate(): IParserError[] {
    this.stream = new Stream(this.expression);
    this.balancedParenStack = [];
    this.prattTree = this._buildPrattTree(0, this.balancedParenStack);

    console.log('Parsed expr', this.expression, 'prattTree', this.prattTree, 'hasNext', this.hasNext, 'parenStack', this.balancedParenStack);
    const errors: IParserError[] = [];
    if (!this.hasNext) {   // Otherwise tree is likely not built yet
      if (this.balancedParenStack.length === 0) {   // Otherwise unbalanced parenthesis
        if (this.expressionTree instanceof ParserToken) {
          this._validateSimpleExpression(this.expressionTree, this.input, errors, true);
        } else {
          this._validateComplexExpression(this.expressionTree, this.input, errors);
        }
      } else {
        errors.push({ code: 'UNBALANCED_PARENTHESIS' });
      }
    } else {
      errors.push({ code: 'PARSING_ERROR' });
    }

    if (errors.length > 0) {
      console.log('errors', errors);
    }

    return errors;
  }

  protected ignoreSurroundingQuotesInDisplayStrings() {
    return true;
  }

  private validateSpecialCharacters(displayString: string, ignoreSurroundingQuotes: boolean, errorMsg: string) {
    // Display string cannot contain special characters unless escaped

    if (ignoreSurroundingQuotes) {
      displayString = displayString.replace(/^"|"$/g, '');
    }

    // If the Lucene escape sequence is part of the special characters list, that requires special handling...
    // don't want to error about having to escape the escape character!
    const escapeIndex = this.specialChars.indexOf(this.escapeChar);
    if (escapeIndex >= 0) {
      // Test specialChars without escape char
      if (this.nonEscapedSpecialCharWithoutEscapeCharRegex.test(displayString)) {
        // TODO: add unit test testing all special chars
        throw new Error(errorMsg);
      }

      // Make sure escape char is not by itself, meaning it's _not_ used to escape a specialChar
      if (this.escapeCharUsedWithoutSpecialCharRegex.test(displayString)) {
        // TODO: add unit test testing all special chars
        throw new Error(errorMsg);
      }
    } else {
      if (this.nonEscapedSpecialCharRegex.test(displayString)) {
        // TODO: add unit test testing all special chars
        throw new Error(errorMsg);
      }
    }
  }

  validateFieldName(field: ParserFieldName, prefix?: string): void {
    if (!this.fieldNameRegex.test(field)) {
      // TODO: add unit test
      throw new Error(prefix + ' ' + field + ' contains invalid characters in its name, it must match: ' + this.fieldNameRegex);
    }

    // Just in case `fieldNameRegex` becomes more lenient, make sure any Lucene special characters are indeed escaped
    // Surrounding quotes can be ignored for field name and values, with any parser
    this.validateSpecialCharacters(field, true, prefix + ' ' + field + ' cannot contain unescaped special characters (' + this.specialChars.join('') + ') in its name: ' + field);
  }

  validateFieldValue(field: ParserFieldName, value: string, prefix?: string): void {
    // Surrounding quotes can be ignored for field name and values, with any parser
    this.validateSpecialCharacters(value, true, prefix + ' ' + field + ' cannot contain unescaped special characters (' + this.specialChars.join('') + ') in its value: ' + value);
  }

  validateDisplayName(field: ParserFieldName, displayName: string, prefix?: string, isFallback?: boolean): void {
    // Surrounding quotes can be ignored for LuceneParser (just a way of grouping words), but not for DisplayStringParser (quotes are part of the display string)
    this.validateSpecialCharacters(displayName, this.ignoreSurroundingQuotesInDisplayStrings(), prefix + ' ' + field + ' cannot contain unescaped special characters (' + this.specialChars.join('') + ') in its ' + (isFallback ? '' : 'display ') + 'name: ' + displayName);
  }

  validateDisplayValue(field: ParserFieldName, displayValue: string, prefix?: string): void {
    // Surrounding quotes can be ignored for LuceneParser (just a way of grouping words), but not for DisplayStringParser (quotes are part of the display string)
    this.validateSpecialCharacters(displayValue, this.ignoreSurroundingQuotesInDisplayStrings(), prefix + ' ' + field + ' cannot contain unescaped special characters (' + this.specialChars.join('') + ') in its display value: ' + displayValue);
  }

  protected computeTokens(): SearchToken[] {
    this.tokens = [];
    if (this.isValid()) {
      this.getSearchTokens(this.prattTree, this.tokens);
    } else {
      this.showAllSearchTokens(this.tokens);
    }
    return this.tokens;
  }

  isAllowedField(field: ParserFieldName): boolean {
    if (!field || field.length === 0) {
      return false;                           // Field must exist
    }

    return field === '_exists_'               // Special keyword
      || !this.hasFieldConfig                 // No field limitations in effect
      || this.fields.hasOwnProperty(field)    // Known field
      || this.macros.hasOwnProperty(field);   // Known macro
  }

  // To avoid double work, it's assumed field is already validated
  isAllowedFieldValue(field: ParserFieldName, value: string): ValidationErrors {
    console.log('isAllowedFieldValue: hasFieldConfig?', this.hasFieldConfig, 'hasMacroConfig?', this.hasMacroConfig, 'field:', field, 'value:', value);

    let errors = null;

    // Deal with ranges and sets
    let values = null;
    if (/^[\[{](.+)\s+TO\s+(.+)[\]}]$/.test(value)) {
      // Range
      values = [RegExp.$1, RegExp.$2];
    } else if (/^\[.+(\s+OR\s+.+)+]$/.test(value)) {
      // Set
      value = value.substring(1, value.length - 1);
      values = value.split(/\s+OR\s+/);
    }
    if (values) {
      values.some(value => {
        if (errors === null && value !== '*') {
          errors = this.isAllowedFieldValue(field, value);
        }
        if (errors !== null) {
          return true;
        }
      });
      return errors;
    }

    // Original code from when isFieldValueInLookup returned a boolean:
    // return (!this.hasFieldConfig && !this.hasMacroConfig)    // No field/macro limitations in effect
    //   || (this.hasFieldConfig && this.fields.hasOwnProperty(field) && this.isFieldValueInLookup(this.fields, field, value))     // Known field value
    //   || !this.hasFieldConfig    // Already checked valid field value above, if there's no further config, anything goes (not just macros)
    //   || (this.hasMacroConfig && this.macros.hasOwnProperty(field) && this.isFieldValueInLookup(this.macros, field, value));    // Known macro value
    // New code now that isFieldValueInLookup returns ValidationErrors:
    let continueChecking = true;

    if (this.hasFieldConfig && this.fields.hasOwnProperty(field)) {
      errors = this.isFieldValueInLookup(this.fields, field, value);
      // Known & valid field value if there are no errors, otherwise not a valid value for this field, so continue to check if it's a valid macro value
      continueChecking = errors !== null;
    }

    // A field can be in either `this.fields` or `this.macros`; if we got here and `errors` !== null, this following condition will always be false
    if (continueChecking && this.hasMacroConfig && this.macros.hasOwnProperty(field)) {
      errors = this.isFieldValueInLookup(this.macros, field, value);
      // Known & valid macro value if there are no errors
    }

    // If there are no errors, there are no field/macro limitations in effect

    return errors;
  }

  private isFieldValueInLookup(lookup: IParserFieldLookup | IParserMacroLookup, fieldName: ParserFieldName, value: string): ValidationErrors {
    let configs = lookup[fieldName];
    if (!configs) {
      return null;   // No value limitations in effect
    } else {
      let errors: ValidationErrors = null;
      const found = configs.some(config => {
        const typed: ParserFieldSimpleValue = this.convertToFieldType(value, config.type);
        console.log('isFieldValueInLookup: typed:', typed, 'dataType:', config.type, 'typeof', typeof typed);
        if ((config.values || []).length === 0) {   // If this is the case, there is only one config, see big comment at addFieldLookup()
          // No value restrictions but must be of the right type and match any validation rules
          let result = BaseParserService.validateTypedValue(typed, config.type);
          if (result && config.validators) {
            errors = (new FormControl(typed, config.validators)).errors;
            result = errors === null;
          }
          return result;
        } else {
          // One of the hardcoded values must match, so no need to use validators
          // The double-quote tests are to match values that are quoted single words...
          return config.values.some(v => v === value || v === '"' + value + '"' || this.isParserFieldValueConfiguration(v) && (v as IParserFieldValueConfiguration).value === value || this.isParserFieldValueConfiguration(v) && (v as IParserFieldValueConfiguration).value === '"' + value + '"')    // Known value, defined as string
            || config.values.some(v => this.equalsTypedValue(v, typed, config.type));   // Known value, defined as field type
        }
      });
      if (!found && !errors) {
        errors = { invalidParserFieldValue: { invalidParserFieldValue: value } };
      }
      return errors;
    }
  }

  // TODO: repeated code with DisplayString parser, separator should be private, so maybe pass into base fn?
  // TODO: why not always keep quotes around entered value (if present)?
  getFieldNameAndValuePair(token: string, keepQuotes?: boolean): string[] {   // Argument `token` is `ParserToken.token`
    // Skip negatePrefix, if present
    if (token.startsWith(this.negatePrefix)) {
      token = token.substring(this.negatePrefix.length);
    }

    let kvPair = token.split(this.nonEscapedSeparatorRegEx);   // Note: split(str, limit) returns "limit" #elts from resulting array,
    let containsSeparator = kvPair.length > 1;                 //       it does not mean split the string in at most "limit" parts",
                                                               //       so cannot use split(str, 2) here to avoid the join below
    kvPair = [ kvPair.shift(), kvPair.join(this.fieldNameAndValueSeparator) ];   // Join the remainder just in case value contained a fieldNameValueSeparator

    if (keepQuotes === undefined) {
      // Must keep quotes if value contains a space!
      keepQuotes = kvPair[1].trim().indexOf(' ') > 0;
    }
    if (!keepQuotes) {
      kvPair[1] = kvPair[1].replace(/^"?(.*?)"?$/, (matches, capture) => capture);
    }

    if (kvPair[1].length === 0 && !containsSeparator) {
      kvPair.pop();   // Contract says to return `undefined` for value if there is none
    }

    return kvPair;
  }

  createTokenString(field: string, value: ParserFieldSimpleValue, operator: ParserQueryOperator, addSpaceAfterSeparator?: boolean): string {
    // Gather list of values
    value = String(value);

    // For Lucene syntax, quotes are only needed if there are spaces in the value and the value isn't already quoted
    let values: string[];
    switch (operator) {
      // Note: EXISTS and DOES_NOT_EXIST are missing as they do not require a value
      case ParserQueryOperator.CONTAINS:
      case ParserQueryOperator.EQUALS:
      case ParserQueryOperator.MATCHES:
      case ParserQueryOperator.WILDCARD:
      case ParserQueryOperator.GREATER_THAN:
      case ParserQueryOperator.GREATER_THAN_EQUAL_TO:
      case ParserQueryOperator.LESS_THAN:
      case ParserQueryOperator.LESS_THAN_EQUAL_TO:
      case ParserQueryOperator.NOT_EQUALS:
        values = [value];
        break;

      case ParserQueryOperator.IS_BETWEEN:
      case ParserQueryOperator.IS_NOT_BETWEEN:
        values = value.split('-');
        break;

      case ParserQueryOperator.IS_NOT_ONE_OF:
      case ParserQueryOperator.IS_ONE_OF:
        values = value.split(',');
        break;
    }

    // Add quotes around values if needed
    for (let i = 0; i < (values || []).length; i++) {   // Use for loop so values can be edited in place
      values[i] = values[i].trim();
      if (this.nonQuotedSpaceContainingRegex.test(values[i])) {
        values[i] = '"' + values[i] + '"';
      }
    }

    let result;
    switch (operator) {
      // Syntax: field:value
      case ParserQueryOperator.CONTAINS:   // Contains or equals is determined by indexation, so there's no specific syntax to force one or the other
      case ParserQueryOperator.EQUALS:     // Contains or equals is determined by indexation, so there's no specific syntax to force one or the other
      case ParserQueryOperator.MATCHES:    // E.g. field:/Th(3|e).*expression$/
      case ParserQueryOperator.WILDCARD:   // E.g. field:Some?Wild.ard*
        result = field + this.fieldNameAndValueSeparator + (addSpaceAfterSeparator ? ' ' : '') + values[0];
        break;

      // Syntax: -_exists_:field
      case ParserQueryOperator.DOES_NOT_EXIST:
        result = this.negatePrefix + '_exists_' + this.fieldNameAndValueSeparator + (addSpaceAfterSeparator ? ' ' : '') + field;
        break;

      // Syntax: _exists_:field
      case ParserQueryOperator.EXISTS:
        result = '_exists_' + this.fieldNameAndValueSeparator + (addSpaceAfterSeparator ? ' ' : '') + field;
        break;

      // Syntax: field:{value, *} (field:>value is not supported by the parser)
      case ParserQueryOperator.GREATER_THAN:
        result = field + this.fieldNameAndValueSeparator + (addSpaceAfterSeparator ? ' ' : '') + '{' + values[0] + ' TO *}';
        break;

      // Syntax: field:[value, *} (field:>=value is not supported by the parser)
      case ParserQueryOperator.GREATER_THAN_EQUAL_TO:
        result = field + this.fieldNameAndValueSeparator + (addSpaceAfterSeparator ? ' ' : '') + '[' + values[0] + ' TO *}';
        break;

      // Syntax: field:[val1 TO val2], value should be specified as val1-val2
      case ParserQueryOperator.IS_BETWEEN:
        result = field + this.fieldNameAndValueSeparator + (addSpaceAfterSeparator ? ' ' : '') + '[' + values.join(' TO ') + ']';
        break;

      // Syntax: -field:[val1 TO val2]
      case ParserQueryOperator.IS_NOT_BETWEEN:
        result = this.negatePrefix + field + this.fieldNameAndValueSeparator + (addSpaceAfterSeparator ? ' ' : '') + '[' + values.join(' TO ') + ']';
        break;

      // Syntax: -field:[val1 OR val2 OR ...]
      case ParserQueryOperator.IS_NOT_ONE_OF:
        result = this.negatePrefix + field + this.fieldNameAndValueSeparator + (addSpaceAfterSeparator ? ' ' : '') + '[' + values.join(' OR ') + ']';
        break;

      // Syntax: field:[val1 OR val2 OR ...]
      case ParserQueryOperator.IS_ONE_OF:
        result = field + this.fieldNameAndValueSeparator + (addSpaceAfterSeparator ? ' ' : '') + '[' + values.join(' OR ') + ']';
        break;

      // Syntax: field:{*, value} (field:<value is not supported by the parser)
      case ParserQueryOperator.LESS_THAN:
        result = field + this.fieldNameAndValueSeparator + (addSpaceAfterSeparator ? ' ' : '') + '{* TO ' + values[0] + '}';
        break;

      // Syntax: field:{*, value] (field:<=value is not supported by the parser)
      case ParserQueryOperator.LESS_THAN_EQUAL_TO:
        result = field + this.fieldNameAndValueSeparator + (addSpaceAfterSeparator ? ' ' : '') + '{* TO ' + values[0] + ']';
        break;

      // Syntax: -field:value
      case ParserQueryOperator.NOT_EQUALS:   // Contains or equals is determined by indexation, so there's no specific syntax to force one or the other
        result = this.negatePrefix + field + this.fieldNameAndValueSeparator + (addSpaceAfterSeparator ? ' ' : '') + values[0];
        break;
    }
    return result;
  }

  containsFieldName(token: string, name: string): boolean {
    // Skip negatePrefix, if present
    if (token.startsWith(this.negatePrefix)) {
      token = token.substring(this.negatePrefix.length);
    }

    const sepIndex = (this.nonEscapedSeparatorRegEx.exec(token) || { index: -1 }).index;
    if (sepIndex >= 0) {
      return token.substring(0, sepIndex).trim() === name;
    } else {
      return token.trim() === name;
    }
  }

  containsFieldValue(token: string, value: string): boolean {
    // In Lucene, a phrase containing just one word == single term search, e.g. "foo" === foo.
    // Only because the config validator makes sure multi-word values are quoted can quotes be ignored in this test.
    const sepIndex = (this.nonEscapedSeparatorRegEx.exec(token) || { index: -1 }).index;
    const tokenValue = sepIndex >= 0 ? token.substring(sepIndex + this.fieldNameAndValueSeparator.length).trim() : '';
    return tokenValue === value || tokenValue.replace(/^"|"$/g, '') === value;
  }

  getNegationPrefix(): string {
    return this.negatePrefix;
  }

  replaceFieldName(token: string, newName: string): string {
    let result = '';

    if (token.startsWith(this.negatePrefix)) {
      result += this.negatePrefix;
    }

    result += newName;

    const sepIndex = (this.nonEscapedSeparatorRegEx.exec(token) || { index: -1 }).index;
    if (sepIndex >= 0) {
      result += token.substring(sepIndex);
    }
    return result;
  }

  replaceFieldValue(token: string, newValue: string): string {
    const sepIndex = (this.nonEscapedSeparatorRegEx.exec(token) || { index: -1 }).index;
    if (sepIndex >= 0) {
      let valueStart = sepIndex + this.fieldNameAndValueSeparator.length;
      // Be nice and add a space if the user entered it like that (collapsing multiple spaces into one)
      if (' ' === token.substring(valueStart, valueStart + 1)) {
        valueStart += 1;
      }
      return token.substring(0, valueStart) + newValue;
    } else {
      // No value, nothing to replace
      return token;
    }
  }

  replaceMacroQuery(token: string, macro: IParserMacroConfiguration, value: string): string {
    let result = '';

    if (token.startsWith(this.negatePrefix)) {
      result += this.negatePrefix;
    }

    if (typeof macro.query === 'function') {
      result += macro.query(this.convertToFieldType(value, macro.type));
    } else {
      result += macro.query;
    }
    return result;
  }

  replaceNegation(token: string, newNegation: string, addSpaceAfterNewNegation?: boolean): string {
    if (token.startsWith(this.negatePrefix)) {
      let space = (addSpaceAfterNewNegation && token[this.negatePrefix.length] !== ' ' ? ' ' : '');
      token = newNegation + space + token.substring(this.negatePrefix.length);
    }
    return token;
  }
}
