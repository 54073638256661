import {
  Component,
  Input,
  Output,
  NgModule,
  EventEmitter,
  Pipe, PipeTransform
}                                   from '@angular/core';
import { CommonModule }             from '@angular/common';
import { SharedModule }             from '../common/shared';
import { DynamicDialogConfig }      from '../common/api';
import { DynamicDialogRef }         from '../common/api';
import { ButtonModule }             from '../button/button';
import { FormsModule }              from '@angular/forms';
import { InputTextModule }          from '../input-text/input-text';
import { SymInputTextHelperModule } from '../input-text-helper/input-text-helper';
import { SymTranslateModule }       from '../common/translate.pipe';
import { TooltipModule }            from '../tooltip/tooltip';
import { TableModule }              from '../table/table';
import {
  SymDashboardModel,
  SymDashboardWidgetSectionModel,
  SymDashboardWidgetModel
}                                   from './dashboard.interface';


@Pipe( {
  name : 'symDashboardWidgetsFilter',
  pure : false
} )

export class SymDashboardWidgetsFilter implements PipeTransform {
  transform ( items : any[], filter : string ) : any {
    let filteredItems;
    if ( !items ) {
      return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out

    if ( !filter ) {
      filteredItems = items.map( item => {
        if ( item.originalItems ) {
          item.items = Object.assign( [], item.originalItems );
        }
        return item;
      } );

    } else {
      filteredItems = items.map( item => {
          if ( !item.originalItems ) {
            item.originalItems = Object.assign( [], item.items );
          }

          if ( item.originalItems ) {
            let filteredWidgets = [];
            filteredWidgets     = item.originalItems.filter( widget => {
              return widget.name.toUpperCase().indexOf( filter.toUpperCase() ) !== -1;
            } );
            item.items          = filteredWidgets;
          }
          return item;
        }
      );
    }
    return filteredItems;
  }
}

@Component( {
  selector : 'sym-dashboard-widgets',
  template : `
    <div class="sym-dashboard-widgets" [ngClass]="{'is--init': isInit}">
      <div class="sym-dashboard-widgets__left-panel sym__flex-fill">
        <div class="sym-dashboard-widgets__header sym__flex-container">
          <div class="sym-dashboard-widgets__instruction">{{l10n.instruction}}</div>
          <sym-input-text-helper [value]="searchText" (onClear)="clearSearch()" class="sym-dashboard-widgets__search">
            <div class="sym-dashboard-widgets__search-input-border"></div>
            <input id="input" type="text" size="30" class="sym-dashboard-widgets__search-input" pInputText [(ngModel)]="searchText">
            <svg *ngIf="!searchText" class="sym-smbl__action-search-input sym-smbl--black-40">
              <use xlink:href="#sym-smbl__action-search-input"></use>
            </svg>
          </sym-input-text-helper>
        </div>

        <div class="sym-dashboard-widgets__widgets-container">
          <div class="sym-dashboard-widgets__section
                 sym-dashboard-widgets__section-id__{{section.id}}" *ngFor="let section of (widgets | symDashboardWidgetsFilter:searchText)">
            <div class="sym-dashboard-widgets__section-header">
              <div class="is--link sym-dashboard-widgets__section-name-container" (click)="section.expanded = !section.expanded">
              <span class="sym__icon sym-dashboard-widgets__section-toggle">
                <svg *ngIf="section.expanded" class="sym-smbl--arrow-small sym-smbl--black-80">
                  <use xlink:href="#sym-smbl__arrow-chevron"></use>
                </svg>
                <svg *ngIf="!section.expanded" class="sym-smbl--arrow-small sym-smbl--black-80 sym-smbl--arrow-right">
                  <use xlink:href="#sym-smbl__arrow-chevron"></use>
                </svg>
              </span>
                <span class="sym-dashboard-widgets__section-name">{{section.name}}</span>
                <span class="sym-dashboard-widgets__section-count-container">(<span class="sym-dashboard-widgets__section-count">{{section.items.length}}</span>)</span>
              </div>

              <div [hidden]="searchText || howManyAdded(section.items) === section.items.length" class="sym-dashboard-widgets__section-add-all is--link" (click)="addAll(section.items)">{{l10n.addAll}}</div>

              <div class="sym-dashboard-widgets__section-actions sym__flex-fill">
              <span class="sym-dashboard-widgets__section-added-count-container">
                <span class="sym-dashboard-widgets__section-added-count">
                  {{howManyAdded( section.items )}}
                </span>
                <span class="sym-dashboard-widgets__section-added-text">
                  {{l10n.sectionwidgetsSelected}}
                </span>
              </span>
                <span [hidden]="searchText || howManyAdded(section.items) === 0" class="sym-dashboard-widgets__section-remove-all is--link" (click)="removeAll(section.items)">{{l10n.removeAll}}
                </span>
              </div>
            </div>
            <ul class="sym-dashboard-widgets__section-widgets" [hidden]="!section.expanded">
              <li *ngFor="let widget of section.items" class="sym-dashboard-widgets__section-widget
                    sym-dashboard-widgets__widget-id__{{widget.id}}" [pTooltip]="widget.tooltipText" tooltipPosition="bottom" [escape]="false" hideDelay="500" tooltipStyleClass="sym-dashboard-widgets__section-widget-tooltip"
                  [ngClass]="{'is--selected': widget.isSelected }" (click)="onClick(widget); widget.isSelected = !widget.isSelected;"
                  (mouseover)="widget.hover = true; hoverWidget = widget" (mouseout)="widget.hover = false; hoverWidget = null">
                <div class="sym-dashboard-widgets__section-widget-container">
                  <div class="sym-dashboard-widgets__section-widget-content">
                    <div class="sym-dashboard-widgets__thumb">
                      <svg class="sym-smbl__dashboard">
                        <use *ngIf="!widget.hover" attr.xlink:href="#sym-smbl__{{widget.thumbnail}}-unselected"></use>
                        <use *ngIf="widget.hover || widget.isSelected" attr.xlink:href="#sym-smbl__{{widget.thumbnail}}-selected"></use>
                      </svg>
                    </div>
                    <span class="sym-dashboard-widgets__widget-name">{{widget.name}}
                    </span>
                    <span class="sym__icon sym-dashboard-widgets__selected-icon" *ngIf="section.expanded">
                      <svg *ngIf="widget.isSelected" class="sym-smbl--info-solid-small sym-smbl--green">
                        <use xlink:href="#sym-smbl__success"></use>
                      </svg>
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="sym-dashboard-widgets__right-panel">
        <div class="sym-dashboard-widgets__right-top">
          <span class="sym-dashboard-widgets__selected-count"
            [ngClass]="{'sym-dashboard-widgets__selected-count-no-widget': selectedWidgets.length === 0}">
            {{selectedWidgets.length}}
          </span>
            <!-- due to the layout (number is in very large font standing on its own),
                  not using string formatter -->
            <span *ngIf="selectedWidgets.length < 2" class="sym-dashboard-widgets__selected-widget-added">
            {{l10n.widgetSelected}}</span>
            <span *ngIf="selectedWidgets.length > 1" class="sym-dashboard-widgets__selected-widget-added">
            {{l10n.widgetsSelected}}</span>

            <div class="sym-dashboard-widgets__reorder-instruction"
                 *ngIf="selectedWidgets.length > 1">
              <div class="sym-dashboard-widgets__reorder-instruction-icon">
                <sym-icon
                 styleClass="sym-smbl--info-c-xsmall sym-smbl--black-40"
                 svgId="sym-smbl__event-at-risk"></sym-icon>
              </div>
              <div class="sym-dashboard-widgets__reorder-instruction-text">
                {{l10n.reorderInstruction}}
              </div>
            </div>
        </div>


        <div class="sym-dashboard-widgets__right-content">
          <div class="sym-dashboard-widgets__sortable-table sym__flex-container">
            <p-table class="sym-dashboard-widgets__sortable-table-index" [columns]="cols" [value]="selectedWidgets" [reorderableColumns]="true">
              <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
                <tr [pReorderableRow]="index">
                  <td *ngFor="let col of columns">
                    {{index + 1}}
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <p-table class="sym-dashboard-widgets__sortable-table-widget sym__flex-fill" [columns]="cols" [value]="selectedWidgets" [reorderableColumns]="true">
              <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
                <tr [pReorderableRow]="index">
                  <td *ngFor="let col of columns">
                    <div class="sym-dashboard-widgets__sortable-table-item sym__flex-container"
                       [ngClass]="{'is--hover': hoverWidget && hoverWidget.id === rowData.id}">
                      <span class="icon__reorder-handle sym__icon" pReorderableRowHandle></span>
                      <div class="sym-dashboard-widgets__sortable-table-item-name sym__flex-fill is--ellipsis">{{rowData[ col.field ]}}</div>
                    </div>
                  </td>
              </tr>
              </ng-template>
            </p-table>
          </div>
        </div>

        <div class="sym-dashboard-widgets__right-bottom">
          <button type="button" (click)="clearAll()" symButton class="ui-button-secondary sym-dashboard-widgets__button-clear" label="{{l10n.clearAll}}"></button>
          <button type="button" (click)="addWidgets()" symButton class="sym-dashboard-widgets__button-ok" [disabled]="this.selectedWidgets.length === 0" label="{{l10n.ok}}"></button>
        </div>
      </div>
    </div>
  `
} )
export class SymDashboardWidgets {

  @Input() widgets : Array<SymDashboardWidgetSectionModel> = [];
  @Input() dashboard : SymDashboardModel;
  @Input() l10n : any;

  hoverWidget: SymDashboardWidgetModel;
  selectedWidgets : any;
  searchText : string;
  protected toggle : boolean;
  isInit : boolean     = false;
  originalSelectedWidgets : string;
  cols : Array<object> = [
    { field : 'name' }
  ];

  constructor ( public dynamicDialogRef : DynamicDialogRef, public config : DynamicDialogConfig ) {
  }

  ngOnInit () : void {
    if ( this.config.data ) {
      this.dashboard   = this.config.data && this.config.data.dashboard ? this.config.data.dashboard : null;
      let dashboardIds = [];
      if ( this.dashboard && this.dashboard.items ) {
        dashboardIds = this.dashboard.items.map( ( d ) => {
          return d.id;
        } );
      }

      let dashboardItems   = Object.assign( [], this.dashboard.items );
      this.selectedWidgets = dashboardItems;

      let widgets = this.config.data && this.config.data.widgets ? this.config.data.widgets : [];
      //Update selected widgets based on the current dashboard widgets
      widgets.map( ( w : SymDashboardWidgetSectionModel ) => {
        w.items.map( ( i : SymDashboardWidgetModel ) => {
          i.isSelected = dashboardIds.includes( i.id );
        } );
        return w;
      } );

      this.widgets                 = widgets;
      this.originalSelectedWidgets = JSON.stringify( dashboardItems );
      this.l10n                    = this.config.data && this.config.data.l10n ? this.config.data.l10n : {};
    }
  }


  // Method call when toggle button is clicked in navbar
  toggleMenu () : void {
    this.toggle = !this.toggle;
  }

  onClick ( widget : SymDashboardWidgetModel ) {
    if ( !this.selectedWidgets ) {
      this.selectedWidgets = [];
    }
    if ( !widget.isSelected ) {
      this.selectedWidgets.push( widget );
    } else {
      let index = this.selectedWidgets.findIndex( x => x.id == widget.id );
      this.selectedWidgets.splice( index, 1 );
    }
  }

  addWidgets () {
    if ( this.selectedWidgets.length === 0 ) {
      return;
    }

    let isUpdated = false;
    if ( this.originalSelectedWidgets !== JSON.stringify( this.selectedWidgets ) ) {
      //if it was changed, then make sure x and y are reset
      this.selectedWidgets.forEach( w => {
        w.x = 0;
        w.y = 0;
        return w;
      } );

      isUpdated = true;

    }
    this.dynamicDialogRef.close( { widgets : this.selectedWidgets, isUpdated : isUpdated } );
  }

  addAll ( items : Array<SymDashboardWidgetModel> ) {
    let addedWidgets = [];
    items.forEach( i => {
      if ( !i.isSelected ) {
        addedWidgets.push( i );
        i.isSelected = true;
      }
    } );

    this.selectedWidgets = this.selectedWidgets.concat( addedWidgets );
  }

  removeAll ( items : Array<SymDashboardWidgetModel> ) {
    let itemIds = items.map( ( item : SymDashboardWidgetModel ) => {
      return item.id;
    } );
    items.forEach( i => i.isSelected = false );
    let filteredWidget   = this.selectedWidgets.filter( ( widget : SymDashboardWidgetModel ) => {
      if ( itemIds.includes( widget.id ) ) {
        return false;
      }
      return true;
    } );
    this.selectedWidgets = filteredWidget;
  }

  clearAll () {
    this.selectedWidgets = [];
    this.widgets.forEach( w => {
      w.items.forEach( i => {
        i.isSelected = false;
      } );
    } );
  }

  howManyAdded ( items : Array<SymDashboardWidgetModel> ) {
    if ( !items ) {
      return 0;
    }
    let filteredItems = items.filter( ( item : SymDashboardWidgetModel ) => {
      return item.isSelected;
    } );
    return filteredItems.length;
  }

  clearSearch () {
    this.searchText = '';
  }
}

@NgModule( {
  imports      : [CommonModule, SharedModule, ButtonModule, InputTextModule, FormsModule, SymInputTextHelperModule, SymTranslateModule, TooltipModule, TableModule],
  exports      : [SymDashboardWidgets, SharedModule, ButtonModule, InputTextModule, FormsModule, SymInputTextHelperModule, SymTranslateModule, TooltipModule, TableModule],
  declarations : [SymDashboardWidgets, SymDashboardWidgetsFilter]
} )
export class SymDashboardWidgetsModule {
}
