import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  Output
}                             from '@angular/core';
import { CommonModule }       from '@angular/common';
import { SharedModule }       from '../common/shared';
import { OverlayPanelModule } from '../overlay-panel/overlay-panel';
import { MenuModule }         from '../menu/menu';
import { MenuItem }           from '../common/menu-item';

import { SymDashboardMenuModel } from './dashboard.interface';

@Component( {
  selector : 'sym-dashboard-menu',
  styles   : [``],
  template : `
    <div class="sym-dashboard-menu is--link" #dashboardMenu
         (click)="menu.show($event)"
         (mouseover)="hover=true" (mouseleave)="hover=false">
     <sym-icon
          [styleClass]="{'sym-smbl--blue':hover, 'sym-smbl--black-80':!hover}"
          svgClass="sym-smbl--arrow-nav"
          svgId="sym-smbl__arrow-chevron"></sym-icon>
      <p-menu #menu [popup]="true"
              [model]="menuItems"
              [offset]="menuOffset"
              relative="true"
              styleClass="ui-menu-left-arrow sym-dashboard-menu__menu has--separator"
              [appendTo]="dashboardMenu"></p-menu>
    </div>
  `
} )

export class DashboardMenu {
  @Output() onSelect        = new EventEmitter<MenuItem>();
  @Output() onDefaultChange = new EventEmitter<MenuItem>();

  @Input() parentElem : HTMLElement;
  @Input() l10n = {
    makeDefault : 'Make Default'
  };
  menuItems : MenuItem[];
  _defaultId : string;
  hover = false;

  @Input() get defaultId () : string {
    return this._defaultId;
  }

  set defaultId ( val : string ) {
    this._defaultId = val;
    if ( this.menuItems && this.menuItems.length ) {
      this.menuItems.forEach( ( m ) => {
        let icon    = m.id === this.defaultId ? 'sym-smbl__default-indicator' : '';
        m.iconRight = icon;
        return m;
      } );
    }

  }

  _model : Array<SymDashboardMenuModel>;

  @Input() get model () : Array<SymDashboardMenuModel> {
    return this._model;
  }

  set model ( val : Array<SymDashboardMenuModel> ) {
    this._model = val;
    this.menuItems = this._model.map( ( m ) => {
      let icon     = m.id === this.defaultId ? 'sym-smbl__default-indicator' : '';
      let menuItem = {
        id           : m.id,
        label        : m.name,
        isSelected   : m.isSelected,
        iconRight    : icon,
        iconSVG      : '#sym-smbl__default-indicator',
        content      : '<span class="sym-dashboard__menu-icon"><svg class="sym-smbl__default-indicator"><use href="#sym-smbl__default-indicator"></use></svg></span><span class="sym-dashboard__menu-make-default">' + this.l10n.makeDefault + '</span>',
        command      : ( evt ) => {
          this.onSelect.emit( m );
        },
        contentClick : ( evt ) => {
          this.onDefaultChange.emit( m );
        }
      };

      return menuItem;
    }, this );
  }

  menuOffset = { top : 20, left : -17};

  constructor () {
  }

}

@NgModule( {
  imports      : [CommonModule, SharedModule, OverlayPanelModule, MenuModule],
  exports      : [DashboardMenu, SharedModule, OverlayPanelModule, MenuModule],
  declarations : [DashboardMenu]
} )
export class SymDashboardMenuModule {
}
