export function debounce ( delay : number = 500 ) : MethodDecorator {
  return function ( target : any, propertyKey : string, descriptor : PropertyDescriptor ) {
    let timeout  = null;
    let original = descriptor.value;

    descriptor.value = function ( ...args ) {
      clearTimeout( timeout );
      timeout = setTimeout( () => original.apply( this, args ), ( args[ 1 ] || delay ) );
    };

    return descriptor;
  };
}
