import { Utils }                                                                                                                                       from '../common/utils';
import { OnDestroy }                                                                                                                                   from '@angular/core';
import { SymRuleBuilderItems, SymRuleBuilderItem, SymRuleBuilderItemModel, SymRuleBuilderItemType, SymRuleBuilderContant, SymRuleBuilderDropdownType } from './sym-rule-builder.interface';

export class SymRuleBuilderTreeService implements OnDestroy {
  ngOnDestroy () {
  }

  itemMap : SymRuleBuilderItemModel;

  totalTreeDepth             = 0; // Keep track of how many tree levels (not really useful now)
  noData                     = true;
  keys                       = Object.keys;
  treeItems : any;

  rootDropdownType : string;

  emptyRoot = {
    root : {
      id           : SymRuleBuilderContant.ROOT_ID,
      items        : {},
      operator     : null,
      parentIds    : [],
      parentItem   : null,
      dropdownType : this.rootDropdownType,
      type         : SymRuleBuilderItemType.FOLDER
    }
  };

  constructor () {

  }

  isEmptyObj ( obj ) {
    if (!obj || typeof obj !== 'object' || Array.isArray(obj) ) {
      return true;
    }
    return Object.keys( obj ).length === 0;
  }

  setRootDropdownType ( type : string ) {
    this.rootDropdownType = type;
  }

  addParentItem ( items : SymRuleBuilderItems, parentItem : SymRuleBuilderItem ) {
    for ( let itemId in items ) {
      if ( items.hasOwnProperty( itemId ) ) {
        parentItem = Utils.deepCopy( parentItem );
        if ( parentItem ) {
          parentItem.items = null; //don't keep track of child items in parentItem
        }
        items[ itemId ].parentItem = parentItem;
        items[ itemId ].parentIds  =   this.getParentIds( items[ itemId ] );

        if ( items[ itemId ].items && !this.isEmptyObj( items[ itemId ].items ) ) {
          this.addParentItem( items[ itemId ].items, items[ itemId ] );
        }
      }
    }
    return items;
  }

  getParentIds ( item : SymRuleBuilderItem ) {
    let parentIds = [];
    if ( item.parentItem ) {
      let pId = this.getParentIds( item.parentItem );
      if ( pId && pId.indexOf( item.parentItem.id ) < 0 ) {
        parentIds.push( item.parentItem.id );
        parentIds = parentIds.concat( pId );
      }
    }
    if ( parentIds.length > this.totalTreeDepth ) {
      this.totalTreeDepth = parentIds.length;
    }
    return parentIds;
  }

  setItemMap ( itemModel: SymRuleBuilderItemModel ) {
    this.itemMap = itemModel;
  }

  getObjByProp ( obj, prop ) {

    if ( !prop ) {
      return null;
    }

    prop  = prop.replace( /\[(\w+)\]/g, '.$1' ); // convert indexes to properties
    prop  = prop.replace( /^\./, '' );           // strip a leading dot
    let a = prop.split( '.' );
    for ( let i = 0, n = a.length; i < n; ++i ) {
      let k = a[ i ];
      if ( k in obj ) {
        obj = obj[ k ];
      } else {
        return;
      }
    }
    return obj;
  };

  getItemMap ( itemId : string ) {
    return this.itemMap[ itemId ];
  }

  orderIdArray ( items : SymRuleBuilderItems ) {
    let itemsArray = [];
    for ( let itemId in items ) {
      itemsArray.push( items[ itemId ] );
    }
    itemsArray.sort( function ( a, b ) {
      return a.order - b.order;
    } );
    itemsArray = itemsArray.map( function ( item ) {
      return item.id;
    } );
    return itemsArray;
  };

  mapItem ( items : any ) : SymRuleBuilderItems { //these items are user defined
    let itemsObj      = {};
    let order         = 0;
    let lastIndex     = items.length - 1;
    let previousGroupId: string;
    let groupId: string;
    let isGroupMember = false;
    items.forEach( ( val, key ) => {
      let children = this.getObjByProp( val, this.getItemMap( 'items' ) );

      if ( children && Array.isArray( children ) && children.length > 0 ) {
        children = this.mapItem( children );
      }
      else {
        children = {};
      }
      let itemId = this.getObjByProp( val, this.getItemMap( 'id' ) );

      groupId = this.getObjByProp( val, this.getItemMap( 'groupId' ) );
      if ( previousGroupId === groupId ) {
        isGroupMember = true;
      } else {
        isGroupMember = false;
      }
      let originalModel = Utils.deepCopy( val );
      //PUT BACK
      delete originalModel[ this.itemMap.items ];
      // don't keep track of items here as it will get changed by rule builder

      let item = {
        'id'            : itemId,
        'title'         : this.getObjByProp( val, this.getItemMap( 'title' ) ),
        'type'          : this.getObjByProp( val, this.getItemMap( 'type' ) ),
        'operator'      : this.getObjByProp( val, this.getItemMap( 'operator' ) ),
        'items'         : children,
        'groupId'       : groupId,
        'isGroupMember' : isGroupMember, //first group item is not isGroupMember
        'order'         : order,
        'isLast'        : lastIndex === key,
        'customClass'   : this.getObjByProp( val, 'customClass' ),
        'dropdownType'  : this.getObjByProp( val, 'dropdownType' ) || SymRuleBuilderDropdownType.OPERATORS,
        'isInvalid'     : this.getObjByProp( val, 'isInvalid' ),
        'disableDelete' : this.getObjByProp( val, 'disableDelete' ),
        'disableEdit'   : this.getObjByProp( val, 'disableEdit' ),
        'disableAdd'    : this.getObjByProp( val, 'disableAdd' ),
        'isLastRootItem': this.getObjByProp( val, 'isLastRootItem' ),
        'originalModel' : originalModel
      };

      previousGroupId = groupId;
      order++;

      if ( item.groupId === undefined ) {
        item.groupId = null;
      }

      itemsObj[ item.id ] = item;
    } );
    return itemsObj;
  };

  replaceItem ( items : SymRuleBuilderItems, oldItem : SymRuleBuilderItem, newItem : SymRuleBuilderItem ) {
    if ( Object.keys( items ).length > 0 ) {
      for ( let itemId in items ) {
        if ( items.hasOwnProperty( itemId ) ) {
          if ( items[ itemId ].id === oldItem.id ) {
            newItem.parentIds  = Utils.deepCopy( oldItem.parentIds );
            newItem.order      = oldItem.order;
            newItem.parentItem = oldItem.parentItem;
            items[ newItem.id ] = newItem;

            if (newItem.id !== oldItem.id) {
              delete items[itemId];
            }
            if ( items[ newItem.id ].items && !this.isEmptyObj( items[ newItem.id ].items ) ) {
              let copyNewItem = Utils.deepCopy( newItem );
              delete copyNewItem.items;
              for ( let childItemId in items[ newItem.id ].items ) {
                items[ newItem.id ].items[ childItemId ].parentItem = copyNewItem;
              }
            }
          } else if ( items[ itemId ].items && !this.isEmptyObj( items[ itemId ].items ) ) {
            this.replaceItem( items[ itemId ].items, oldItem, newItem );
          }
        }
      }
    }
  }

  addItems ( items, newItems, parentId, replace ) {
    let newItemId: string;
    let lastOrder = 0;
    let itemOrder: number;
    let lastItemId: string;

    if ( !parentId || parentId === SymRuleBuilderContant.ROOT_ID ) {

      if (Object.keys( items ).length  > 0) {
        if (replace ) {
          for ( let itemsId in items ) {
            delete items[ itemsId ];
          }
          // items = newItems; //NOTE: this doesn't work, so deleting the existing objects using delete (above)
          // return;
        }
        else {

          // Find lastOrder in current items
          for ( let itemsId in items ) {
            if (items[ itemsId ].isLastRootItem) {
              items[ itemsId ].isLast = true;
              lastItemId = itemsId;
            }
            else {
              itemOrder = items[ itemsId ].order;

              if ( itemOrder > lastOrder ) {
                lastOrder = itemOrder;
              }
              items[ itemsId ].isLast = false;
            }
          }
          lastOrder++;
        }
      }

      for ( newItemId in newItems ) {

        items[ newItemId ]          = newItems[ newItemId ];
        items[ newItemId ].parentId = null;
        items[ newItemId ].order    = lastOrder === 0 ? newItems[ newItemId ].order : lastOrder + newItems[ newItemId ].order;
        parentId                    = newItemId;

        if (!lastItemId && newItems[ newItemId ].order === Object.keys(newItems).length - 1) {
          items[ newItemId ].isLast = true;
        }

        if ( items[ newItemId ].items && !this.isEmptyObj( items[ newItemId ].items ) ) {
          this.addItems( items[ newItemId ].items, newItems, parentId, replace );
        }
      }

      if (lastItemId) {
        items[ lastItemId ].order  = lastOrder + Object.keys(newItems).length;
      }

      return;
    }

    for ( let itemId in items ) {
      if ( items.hasOwnProperty( itemId ) ) {
        if ( items[ itemId ].id === parentId ) {
          items[ itemId ].isLoading = false;
          let parentIds             = Utils.deepCopy( items[ itemId ].parentIds );
          if ( parentIds.indexOf( parentId ) < 0 ) {
            parentIds.unshift( parentId );
          }
          let parentItem = Utils.deepCopy( items[ itemId ] );

          for ( newItemId in newItems ) {
            if ( newItems.hasOwnProperty( newItemId ) ) {
              newItems[ newItemId ].parentIds  = parentIds;
              newItems[ newItemId ].parentItem = parentItem;
            }
          }

          if ( replace || !items[ itemId ].items || this.isEmptyObj( items[ itemId ].items ) ) {
            items[ itemId ].items = newItems;
          } else {
            //find what the last order number was
            lastOrder = 0;
            for ( let childItemId in items[ itemId ].items ) {
              itemOrder = items[ itemId ].items[ childItemId ].order;
              if ( itemOrder > lastOrder ) {
                lastOrder = itemOrder;
              }
              items[ itemId ].items[ childItemId ].isLast = false;
            }
            lastOrder++;

            for ( newItemId in newItems ) {
              if ( newItems.hasOwnProperty( newItemId ) ) {
                if (items[ itemId ].items[ newItemId ]) {
                  newItems[ newItemId ].order        = items[ itemId ].items[ newItemId ].order;
                } else {
                  newItems[ newItemId ].order   = lastOrder === 0 ? newItems[ newItemId ].order : lastOrder + newItems[ newItemId ].order;
                }
                items[ itemId ].items[ newItemId ] = newItems[ newItemId ];
              }
            }
          }

          return newItems;
        } else if ( items[ itemId ].items && !this.isEmptyObj( items[ itemId ].items ) ) {
          this.addItems( items[ itemId ].items, newItems, parentId, replace );
        }
      }
    }

    return newItems;
  }

  removeTreeItemById ( items : SymRuleBuilderItems, removeItemId : string ) {
    if ( items.hasOwnProperty( removeItemId ) ) {
      let copyRemoveItem = Utils.deepCopy( items[ removeItemId ] );
      let parentId, parentItem;

      delete items[ removeItemId ];
      let orderIdArr = this.orderIdArray( items );

      // If there is only one item remaining in the scope, the scope can be removed.
      if ( orderIdArr.length === 1 && copyRemoveItem.parentIds && copyRemoveItem.parentIds.length > 0 ) {
        parentId             = copyRemoveItem.parentIds[ 0 ];
        parentItem           = this.getItem( parentId, this.treeItems );
        let copyLastItem     = Utils.deepCopy( items[ orderIdArr[ 0 ] ] );

        if ( parentId !== SymRuleBuilderContant.ROOT_ID ) {
          if ( parentItem && parentItem.type !== SymRuleBuilderItemType.FOLDER ) {
            copyLastItem.parentIds  = parentItem.parentIds;
            copyLastItem.parentItem = parentItem.parentItem;
            this.replaceItem( this.treeItems, parentItem, copyLastItem );
          }
          //When the last item remaining in root.items is scope, delete the scope and move the items up to root.items.
          else if ( copyLastItem.type === SymRuleBuilderItemType.SCOPE ) {
            copyLastItem.parentIds.pop();
            copyLastItem.parentItem = Utils.deepCopy(parentItem);
            let removeScopeId = copyLastItem.id;
            let copyParentItem = Utils.deepCopy( parentItem );
            //remove this id from children
            copyLastItem = this.removeParentIdFromItems(copyLastItem, removeScopeId);
            copyParentItem.items = copyLastItem.items;
            copyParentItem.operator = copyLastItem.operator;
            copyParentItem.originalModel.operator = copyLastItem.operator;
            this.replaceItem( this.treeItems, parentItem, copyParentItem );
          }
        } else {
          //When the last item remaining in root.items is scope, delete the scope and move the items up to root.items.
          if ( copyLastItem.type === SymRuleBuilderItemType.SCOPE ) {
            copyLastItem.parentIds  = [];
            copyLastItem.parentItem = null;
            let removeScopeId = copyLastItem.id;
            //remove this id from children
            copyLastItem = this.removeParentIdFromItems(copyLastItem, removeScopeId);
            // this.replaceItem( this.treeItems, this.treeItems.root, copyLastItem );
            this.treeItems.root.items = copyLastItem.items;
            this.treeItems.root.operator = copyLastItem.operator;
            this.treeItems.root.originalModel = copyLastItem.originalModel;
          } else {
            this.treeItems.root.operator      = null;
            this.treeItems.root.originalModel = null;
          }
        }
      }
      // TODO: Remove? Keeping this in case there is an edget case...
      // If the removed item was the last item in 'items' in SCOPE, then need to remove the parent scope.
      // However, this shouldn't happen as scope should never have only one item, but just in case.
      // else if ( orderIdArr.length === 0 && copyRemoveItem.parentIds && copyRemoveItem.parentIds.length > 0 ) {
      //   parentId   = copyRemoveItem.parentIds[ 0 ];
      //   parentItem = this.getItem( parentId, this.treeItems );
      //   if ( parentId !== SymRuleBuilderContant.ROOT_ID && parentItem.type === SymRuleBuilderItemType.SCOPE ) {
      //     this.removeTreeItemById( this.treeItems, parentId );
      //   }
      // }
      this.reorderItems( items, orderIdArr );
      return copyRemoveItem;
    } else {
      //delete inside nested items
      for ( let itemId in items ) {
        if ( items.hasOwnProperty( itemId ) ) {
          if ( items[ itemId ].items && !this.isEmptyObj( items[ itemId ].items ) ) {
            this.removeTreeItemById( items[ itemId ].items, removeItemId );
          }
        }
      }
    }
  }

  removeParentIdFromItems(item, removeScopeId) {
    if (item.items) {
      for ( let itemId in item.items ) {
        if ( item.items[ itemId ].parentIds ) {
          let removeScopeIdIndex = item.items[ itemId ].parentIds.indexOf(removeScopeId);
          item.items[ itemId ].parentIds.splice(removeScopeIdIndex, 1);
        }
        if (item.items[ itemId ].items) {
          item.items[ itemId ] = this.removeParentIdFromItems(item.items[ itemId ], removeScopeId);
        }
      }
    }
    return item;
  }

  removeGroup ( items : SymRuleBuilderItems, groupId : string ) {
    for ( let itemId in items ) {
      if ( items.hasOwnProperty( itemId ) ) {
        if ( items[ itemId ].groupId === groupId ) {
          delete items[ itemId ];
          let orderIdArr = this.orderIdArray( items );
          this.reorderItems( items, orderIdArr );
        } else if ( items[ itemId ].items && !this.isEmptyObj( items[ itemId ].items ) ) {
          this.removeGroup( items[ itemId ].items, groupId );
        }
      }
    }

  }

  getNewScope ( id : string, parentItemOrder : number, op : string ) : SymRuleBuilderItem {
    let newScopeItem = {
      'id'            : id,
      'title'         : '',
      'items'         : {},
      'type'          : SymRuleBuilderItemType.SCOPE,
      'operator'      : op,
      'order'         : parentItemOrder,
      'originalModel' : {},
      'parentIds'     : [],
      'parentItem'    : null,
      'dropdownType'  : SymRuleBuilderDropdownType.OPERATORS
    };

    newScopeItem.originalModel[ this.itemMap.id ]       = id;
    newScopeItem.originalModel[ this.itemMap.operator ] = op;
    newScopeItem.originalModel[ this.itemMap.type ]     = 'scope';

    return newScopeItem;
  }

  getItem ( id : string, treeItems : SymRuleBuilderItems ) {
    let result = null;
    for ( let itemId in treeItems ) {
      if ( itemId === id ) {
        return treeItems[ itemId ];
      }
      if ( treeItems[ itemId ].items instanceof Object ) {
        result = this.getItem( id, treeItems[ itemId ].items );
        if ( result ) {
          break;
        }
      }
    }
    return result;
  }

  reorderItems ( items : SymRuleBuilderItems, orderIdArr : string[] ) {
    for ( let i = 0; i < orderIdArr.length; i++ ) {
      if ( items[ orderIdArr[ i ] ] ) {
        items[ orderIdArr[ i ] ].order  = i;
        items[ orderIdArr[ i ] ].isLast = false;
        if ( i === orderIdArr.length - 1 ) {
          items[ orderIdArr[ i ] ].isLast = true;
        }
      }
    }
    return items;
  }

  updateParentItem ( items? ) {
    items = !items ? this.treeItems : items;
    for ( let parentItemId in items ) {
      let parentItem   = Utils.deepCopy( items[ parentItemId ] );
      parentItem.items = {};

      if ( items[ parentItemId ].items && Object.keys( items[ parentItemId ].items ).length > 0 ) {
        for ( let itemId in items[ parentItemId ].items ) {
          items[ parentItemId ].items[ itemId ].parentItem = parentItem;
        }
        this.updateParentItem( items[ parentItemId ].items );
      }
    }
  }

  updateTreeItem ( item, treeItems ) {
    let result = null;
    for ( let itemId in treeItems ) {
      if ( itemId === item.id ) {

        return Object.assign( treeItems[ itemId ], item );
      }
      if ( treeItems[ itemId ].items instanceof Object ) {
        result = this.updateTreeItem( item, treeItems[ itemId ].items );
        if ( result ) {
          break;
        }
      }
    }
    return result;
  }

  getItemsAsArray ( items ) {
    let orderedArr = this.orderIdArray( items );
    let itemProp   = this.itemMap.items;
    let results    = [];
    for ( let i = 0; i < orderedArr.length; i++ ) {
      let itemId = orderedArr[ i ];
      if ( items[ itemId ].items ) {
        if ( !items[ itemId ].originalModel ) {
          items[ itemId ].originalModel = Utils.deepCopy( items[ itemId ] );
        }
        items[ itemId ].originalModel[ itemProp ] = this.getItemsAsArray( items[ itemId ].items );
      } else {
        items[ itemId ].originalModel[ itemProp ] = [];
      }
      results.push( items[ itemId ].originalModel );
    }
    return results;
  };


  updateModel ( rawItems : any ) {

    let newItems: SymRuleBuilderItems;
    if ( rawItems && rawItems.length > 0 ) {
      newItems = this.mapItem( Utils.deepCopy( rawItems ) );
      this.addParentItem( newItems, null );
    } else {
      newItems = {};
    }

    if ( newItems.root ) {
      this.treeItems = newItems;
    } else {
      if ( !this.treeItems || !this.treeItems.root ) {
        this.treeItems = this.emptyRoot;
      }
      this.treeItems.root.items = newItems;
    }
  }

  addItem ( rawItem : any, parentId : string, op? : string, replace?: boolean ) {
    if ( !parentId ) {
      parentId = SymRuleBuilderContant.ROOT_ID;
    }
    let rawItems = [];

    if ( Array.isArray( rawItem ) ) {
      rawItems = rawItem;
    } else {
      rawItems = [rawItem];
    }

    if ( !this.treeItems || this.treeItems.length === 0 ) {
      this.updateModel( rawItems );
      return;
    }

    this.addGroup( rawItems, parentId, replace, op );
  };

  /**
   * Update item
   */
  updateItem ( itemId, item, isRaw? ) {
    let newItem = {};
    if ( isRaw !== false ) {
      let newItems = [];
      newItems.push( item );
      newItem = this.mapItem( newItems );
    } else {
      newItem[ itemId ] = item;//
      //update originalModel
      if (!newItem[ itemId ].originalModel) {
        newItem[ itemId ].originalModel = {};
      }
      newItem[ itemId ].originalModel[this.getItemMap( 'title' )] = item.title;
      newItem[ itemId ].originalModel[this.getItemMap( 'type' )] = item.type;
      newItem[ itemId ].originalModel[this.getItemMap( 'operator' )] = item.operator;
      newItem[ itemId ].originalModel[this.getItemMap( 'customClass' )] = item.customClass;
      newItem[ itemId ].originalModel[this.getItemMap( 'dropdownType' )] = item.dropdownType;
      newItem[ itemId ].originalModel[this.getItemMap( 'isInvalid' )] = item.isInvalid;
      newItem[ itemId ].originalModel[this.getItemMap( 'disableDelete' )] = item.disableDelete;
      newItem[ itemId ].originalModel[this.getItemMap( 'disableEdit' )] = item.disableEdit;
    }
    // put back `order` and `isLast`from curent item
    let currentItem = this.getItem( itemId, this.treeItems );
    if ( currentItem ) {
      newItem[ itemId ].order  = currentItem.order;
      newItem[ itemId ].isLast = currentItem.isLast;
    }
    this.updateTreeItem( newItem[ itemId ], this.treeItems );
  };

  /**
   * Add items to a group
   */

   addGroup ( rawItems : any, targetItemId : string, replace : boolean, op? : string ) {

     if ( rawItems ) {
       op             = op ? op.toUpperCase() : null;
       let newItems   = this.mapItem( rawItems );
       let newItemIds = Object.keys( newItems );
       if ( !newItemIds.length ) {
         return false;
       }
       let newItems0 = newItems[ newItemIds[ 0 ] ];

       let targetItem: SymRuleBuilderItem;
       let rawTargetItemCopy: any; //user defined
       let newScopeId: string;
       let newScopeItem: SymRuleBuilderItem;
       let targetItemOp: string;
       let targetParentId: string;
       let targetParentItem: SymRuleBuilderItem;
       let isRoot: boolean;
       let orderIdArr: Array<string>;

       if ( targetItemId ) {
         targetItem       = Utils.deepCopy( this.getItem( targetItemId, this.treeItems ) );
         targetItemOp     = targetItem && targetItem.operator ? targetItem.operator.toUpperCase() : null;
         targetParentId   = targetItem && targetItem.parentIds && targetItem.parentIds.length > 0 ? targetItem.parentIds[ 0 ] : null;
         targetParentItem = targetParentId ? this.getItem( targetParentId, this.treeItems ) : null;
         let targetParentItemOrder = targetParentItem && !this.isEmptyObj(targetParentItem.items) ? this.orderIdArray(targetParentItem.items).slice() : [];

         //Case1: If this item is folder, then we need to add the item within.
         // Only need to think about an operator when there are more than one items.
         if ( op && targetItem && targetItem.type === SymRuleBuilderItemType.FOLDER && !this.isEmptyObj(targetItem.items)) {
           // TODO: Remove? Not sure if this is necessary
           // if (Array.isArray(this.treeItems.root.items)) {
           //   this.treeItems.root.items = {};
           // }
           //check operators
           if (!targetItem.operator) {
             targetItem.operator = op;
             this.updateItem (targetItemId, targetItem, false);
           }
           if (op === targetItem.operator) {
             newItems = this.addParentItem( newItems, targetItem );
             this.addItems( this.treeItems.root.items, newItems, targetItemId, replace );
             this.updateParentItem(this.treeItems.root.items);
             return true;
           }
         }
         //Case2: If targetParentItem's operator is the same as the operator added, then add the item to parent's parent item.
         // TODO: not sure targetItem.operator is necessary in this check?
         //!targetItem.operator && this.isEmptyObj(targetItem.items)?
         else if ( targetItem.type !== SymRuleBuilderItemType.FOLDER  && targetParentItem && op && (op === targetParentItem.operator || !targetParentItem.operator || Object.keys(targetParentItem.items).length === 1)) {
           if (!targetParentItem.operator || Object.keys(targetParentItem.items).length === 1) {
             targetParentItem.operator = op;
             this.updateItem (targetParentId, targetParentItem, false);
           }
           newItems = this.addParentItem( newItems, targetParentItem );
           if (Array.isArray(this.treeItems.root.items)) {
             this.treeItems.root.items = {};
           }
           this.addItems( this.treeItems.root.items, newItems, targetParentId, replace );
           this.updateParentItem();
           return true;
         }
         // Keeing these "if" condiitons in case we need to revert this...
         // else if (targetItem.type !== SymRuleBuilderItemType.SCOPE && targetParentItem && targetParentItem.id !== SymRuleBuilderContant.ROOT_ID && op && op !== targetParentItem.operator && !targetItem.operator && this.isEmptyObj(targetItem.items)) {
         else if (targetItem.type !== SymRuleBuilderItemType.SCOPE && targetParentItem && op && op !== targetParentItem.operator && !targetItem.operator && this.isEmptyObj(targetItem.items)) {
           //New cases added here-- but remove it if this is not what we want.
           let newScopeId3         = Utils.generateUUID();
           let newScopeItem3       = this.getNewScope( newScopeId3, targetItem.order, op );
           newScopeItem3.items = newItems;
           let newScopeObj = {};
           newScopeObj[newScopeId3] = newScopeItem3;
           let targetItemObj = {};
           targetItemObj[targetItemId] = targetItem;

           this.replaceItem( this.treeItems.root.items, targetItem, newScopeItem3);
           targetParentItemOrder.splice(targetParentItemOrder.indexOf(targetItemId), 1, newScopeId3);

           let newItemsGroup = {...targetItemObj, ...newItems }
           orderIdArr = this.orderIdArray(newItems);
           orderIdArr.unshift(targetItemId);
           newItemsGroup = this.reorderItems( newItemsGroup, orderIdArr );

           // newItemsGroup[targetItemId] = targetItem;
           this.addItems( this.treeItems.root.items, newItemsGroup, newScopeId3, true );
           // this.addItems( this.treeItems.root.items, newItems, newScopeId3, false );
           return;

         }


         if ( targetItem.type !== SymRuleBuilderItemType.FOLDER ) {
           targetParentId = targetItem.parentIds[ 0 ];

           if ( targetParentId ) {
             targetItemId     = targetParentId;
             targetItem   = Utils.deepCopy( this.getItem( targetItemId, this.treeItems ) );
             targetItemOp = targetItem && targetItem.operator ? targetItem.operator.toUpperCase() : null;

             if ( !targetItemOp && op ) {
               targetItem.operator = op;
               if ( !targetItem.originalModel ) {
                 targetItem.originalModel = Utils.deepCopy( targetItem );
               }
               if ( targetItem.originalModel ) {
                 targetItem.originalModel[ this.itemMap.operator ] = op;
               }
               this.updateTreeItem( targetItem, this.treeItems );
               targetItemOp = op;
             }
           } else {
             isRoot = true;
           }
         }

         if ( isRoot && op && !this.treeItems.root.operator && this.rootDropdownType !== 'item' ) {
           this.treeItems.root.operator = op;
         }
         if ( targetItem && op && (!targetItem.items || this.isEmptyObj(targetItem.items)) && targetItem.type !== SymRuleBuilderItemType.FOLDER ) {            // rawTargetItemCopy = parentItem.originalModel;
           orderIdArr = targetParentItem.items ? this.orderIdArray( targetParentItem.items ) : [];
           this.removeItem( targetItemId );
           let itemIndex = orderIdArr.indexOf( targetItemId );

           newScopeId   = Utils.generateUUID();
           newScopeItem = this.getNewScope( newScopeId, targetItem.order, op );
           this.addItem( newScopeItem, targetParentId );
           targetItemId   = newScopeId;
           targetItem = Utils.deepCopy( targetParentItem );
           orderIdArr.splice( itemIndex, 1, newScopeId );
           targetItemOp = targetItem.operator;
           this.reorderItems( targetParentItem.items, orderIdArr );
         }


         if ( op && ( targetItemOp === 'OR' ||
           targetItemOp === 'AND' ) ) {
           if ( op !== targetItemOp ) {
             //ADD logic to add to child items below?

             if (!targetItem.operator) { //testing this

             }
             // END ADD

             // if no child items, then need to move parentItem into items,
             // this parentItem become a folder
             else {
               targetItem.operator = op;
               if ( targetItem.originalModel ) {
                 targetItem.originalModel[ this.itemMap.operator ] = op;
               }
               // parentItem.title = '';
               this.updateItem( targetItem.id, targetItem, false );
               let parentItemKeys = Object.keys( targetItem.items );
               if ( targetItem.items && parentItemKeys.length > 1 ) {
                 // need to move these item into a folder
                 let copyChildItems      = Utils.deepCopy( targetItem.items );
                 let newScopeId2         = Utils.generateUUID();
                 let newScopeItem2       = this.getNewScope( newScopeId2, 0, targetItemOp ); // *** change 0 to 1 to add the new item on top
                 newScopeItem2.parentIds = targetItem.items[ parentItemKeys[ 0 ] ].parentIds;

                 //update parentIds inside the child items.
                 for ( let copyChildItem in copyChildItems ) {
                   copyChildItems[ copyChildItem ].parentIds.unshift( newScopeId2 );
                   copyChildItems[ copyChildItem ].parentItem = Utils.deepCopy( newItems );
                 }

                 newScopeItem2.items             = copyChildItems;
                 targetItem.items                = {};
                 targetItem.items[ newScopeId2 ] = newScopeItem2;
                 this.addParentItem( newItems, targetItem );
                 newItems0.order                     = Object.keys( copyChildItems ).length;  // *** change this to 0 to add the new item on top
                 targetItem.items[ newItemIds[ 0 ] ] = newItems0;

                 this.updateTreeItem( targetItem, this.treeItems );
                 this.updateParentItem();
                 this.treeItems = JSON.parse(JSON.stringify(this.treeItems));
                 return true;
               }
             }
           }
         } else if ( targetParentItem && op && targetParentItem.operator === op ) {
           targetItem = targetParentItem;
           targetItemId   = targetParentId;
         }

       }
       if ( !targetItem ) {
         targetItem = this.treeItems.root;
       }
       newItems = this.addParentItem( newItems, targetItem );

       if ( rawTargetItemCopy ) {
         this.addItem( rawTargetItemCopy, newScopeId );
       }

       if ( newItems && !this.isEmptyObj( newItems ) ) {
         newItems = this.addItems( this.treeItems.root.items, newItems, targetItemId, replace );
         this.updateParentItem();
       }
       return true;
     }
     return false;
   };

  removeItem ( item ) {
    let removedItem;
    if ( typeof ( item ) !== 'object' ) {
      // item is ID
      item = this.getItem( item, this.treeItems );
    }
    if ( !item || ( item.originalModel && item.originalModel.disableDelete ) || !item.id ) {
      return;
    }

    if ( item.groupId ) {
      removedItem = this.removeGroup( this.treeItems, item.groupId );
    } else {
      this.treeItems = this.treeItems;
      removedItem    = this.removeTreeItemById( this.treeItems, item.id );
    }

    return removedItem;
  };


}
